
<script>
import { sessionManager } from '@/main';

export default {
  name: 'ErrorPage',
  props: {
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Something went wrong!',
          headline: 'Ups, something went wrong!',
          text: 'We appologize for the inconvenience. Please try again later.',
          text_2: 'If the problem persists, please contact us at ',
        },
        de: {
          title: 'Etwas ist schief gelaufen!',
          headline: 'Ups, etwas ist schief gelaufen!',
          text: 'Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuche es später noch einmal.',
          text_2: 'Wenn das Problem weiterhin besteht, kontaktiere uns bitte unter ',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
  }, 
}
</script>

<style>
  .font_white_big {
    color: white;
    font-size: 2.5rem;
  }
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
          <p class="darkest text-v-sm font-pj">{{ strings['text'] }}</p>
          <p class="darkest text-v-sm font-pj">{{ strings['text_2'] }} <a href="mailto:info@valuetion.com">info@valuetion.com</a>.</p>
      </div>      
    </div>
  </div>
</template>


