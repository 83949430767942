/* eslint-disable */
<script>

import { sessionManager } from '@/main';
import { logUserEvent } from '../helper';

export default {
  name: 'LandingPage',
  props: {
    unitOfAccount: {
      type: String,
      required: true,
      default: () => 'USD',
    },
  },
  methods: {
  },
  data() {
    return {
      languageStrings: {
        en: {

          valuetion_headline_a: 'The investment analysis tool', // Discover undervalued stocks', //The ideal tool to discover undervalued stocks',
          valuetion_headline_b: ' for valuing stocks with proven value investing methods',
          valuetion_text: 'Effortlessly discover and evaluate stocks to enable confident and successful investment decisions.',

          beta_a: 'Join our Beta',
          beta_b: ' for instant access to our value investing tools',
          beta_request: 'Create your account',

          guru_a: 'Proven Value Investing methods',
          guru_b: ' of Investing Gurus for solid evaluations and selection criteria',
          guru_text: 'We provide meaningful and tested company evaluations and selection criteria based on the financial fundamentals of ',
          guru_buffett: 'Warren Buffett',
          guru_lynch: 'Peter Lynch',
          guru_town: 'Phil Town',
          guru_graham: 'Benjamin Graham',
          guru_text_end: ', and many more.',

          overview_a: 'Clear company profiles',
          overview_b: ' for quick decisions',
          overview_text: 'With our well-structured overview pages, you can quickly determine whether a company is worth a closer analysis.',
          
          calm_a: 'Overcome market psychology ',
          calm_b: ' and invest rationally and calmly with Value Investing',
          calm_text: 'We focus on the essentials and deliberately avoid distractions, supporting you in suppressing short-term impulses.',

          say_no: 'Say no to distractions and short-term thinking',
          say_no_1: 'No stock prices - just the full company',
          say_no_2: 'No charts - no anchoring effect',
          say_no_3: 'No technical analysis - no noise',
          say_no_4: 'No short-term impulses - no stress',

          say_yes: 'Say yes to focus, reason, and serenity',
          say_yes_1: 'Tested strategies from Value Investing experts',
          say_yes_2: 'Focus on the entire company',
          say_yes_3: 'Thorough analysis of fundamental data',
          say_yes_4: 'Long-term perspective',

          screener_a: 'Find and analyze stocks',
          screener_b: ' with our step-by-step Stock Screener',
          screener_text: 'Discover and analyze undervalued stocks in 4 easy steps based on the quality criteria and evaluations of Value Investing experts.',

          screener_1: '1. Circle of Competence',
          screener_1_text: 'Choose industry, sector, location.',

          screener_2: '2. Define quality criteria for the company',
          screener_2_text: 'Set minimum requirements for size, growth, return on capital, debt, and more.',

          screener_3: '3. Valuation vs. Price',
          screener_3_text: 'Identify undervalued companies and analyze them on the overview page.',

          screener_4: '4. Receive updates when new companies meet your criteria',
          screener_4_text: 'We scour the market for you and notify you when new companies match your search criteria.',

          graphical_a: 'Graphical financial reports',
          graphical_b: ' to understand the financials at a glance',
          graphical_text: 'Easier to understand than tables: Get all the essential information at a glance and immediately grasp a company\'s financial situation.',

          dach_a: 'Stocks from Germany, Switzerland, Austria',
          dach_b: ', as well as Europe and the USA',
          dach_text: 'We provide not only information on the American market but also cover stocks from Germany, Austria, and Switzerland.',

          valuation_a: 'Comprehensive company valuations',
          valuation_b: ' using easy-to-grasp value investing methods',
          valuation_text: 'We have implemented valuation methods from renowned books like \"Invested\", \"The Intelligent Investor\", and \"One Up On Wall Street\" and presented them visually. You can flexibly adjust the assumptions to align with your own forecasts.',
        },
        de: {

          valuetion_headline_a: 'Das Analyse-&shy;Tool&nbsp für fundierte Aktien&shy;bewertungen', //Das ideale Tool, um unter&shy;bewertete Aktien zu entdecken',
          valuetion_headline_b: ' nach bewährten Value Investing Methoden.',

          valuetion_text: 'Entdecke und bewerte Unternehmen und Aktien mühelos und entscheide selbstbewusst und erfolgreich über Deine Investments.',

          beta_a: 'Jetzt für die Beta-Phase anmelden',
          beta_b: ' und Zugang zu unserem Value Investing Tool erhalten.',
          beta_request: 'Konto erstellen',

          guru_a: 'Bewährte Value Investing-Methoden',
          guru_b: ' von Investing-Gurus für fundierte Bewertungen und Auswahl&shy;kriterien',
          guru_text: 'Wir bieten aussagekräftige und erprobte Firmenbewertungen sowie Selektionskriterien basierend auf den finanziellen Fundamentaldaten von ',
          guru_buffett: 'Warren Buffett',
          guru_lynch: 'Peter Lynch',
          guru_town: 'Phil Town',
          guru_graham: 'Benjamin Graham',
          guru_text_end: ' und vielen mehr.',

          overview_a: 'Übersichtliche Profile der börsen&shy;notierten Unternehmen',
          overview_b: ' für schnelle Entscheidung&shy;en',
          overview_text: 'Mit unseren klar strukturierten Übersichtsseiten erkennst Du innerhalb weniger Sekunden, ob ein Unternehmen einer genaueren Analyse wert ist.',

          calm_a: 'Gelassenheit und Rationalität:',
          calm_b: ' Meistere die Markt&shy;psychologie durch Value Investing',
          calm_text: 'Wir fokussieren uns auf das Wesentliche und verzichten bewusst auf Ablenkungen. So unterstützen wir Dich dabei, kurzfristige Impulse zu unterdrücken und entspannt und rational zu entscheiden.',

          say_no: 'Sage Nein zu Ablenkungen und kurzfristigem Denken',
          say_no_1: 'Keine Aktienkurse',
          say_no_2: 'Keine Charts',
          say_no_3: 'Keine Technische Analyse',
          say_no_4: 'Keine kurzfristigen Impulse',

          say_yes: 'Sage Ja zu Fokus, Vernunft und Gelassenheit',
          say_yes_1: 'Erprobte Strategien von Value Investing-Experten',
          say_yes_2: 'Fokus auf das gesamte Unternehmen',
          say_yes_3: 'Fundierte Analyse der Fundamentaldaten',
          say_yes_4: 'Langfristige Perspektive',

          screener_a: 'Finde und analysiere Aktien',
          screener_b: ' mit unserem schrittweisen Stock-Screener',
          screener_text: 'Entdecke und analysiere in 4 einfachen Schritten unterbewertete Aktien basierend auf den Qualitätskriterien und Bewertungen der Value Investing-Experten.',

          screener_1: '1. Kompetenzbereich',
          screener_1_text: 'Wähle Industrie, Branche, Standort.',

          screener_2: '2. Qualitätskriterien für das Unternehmen',
          screener_2_text: 'Definiere Mindestanforderungen für Grösse, Wachstum, Kapitalrendite, Verschuldung und weitere Kriterien.',

          screener_3: '3. Bewertung vs. Preis',
          screener_3_text: 'Ermittle unterbewertete Unternehmen und analysiere sie auf der Übersichtsseite.',

          screener_4: '4. Updates erhalten, wenn neue Unternehmen Deine Kriterien erfüllen.',
          screener_4_text: 'Wir durchforsten den Markt für Dich und benachrichtigen Dich, wenn neue Unternehmen Deinen Suchkriterien entsprechen.',

          graphical_a: 'Mit graphischen Finanz&shy;berichten',
          graphical_b: ' alle wichtigen Informationen auf einen Blick',
          graphical_text: 'Verständlicher als Tabellen: Erhalte alle wichtigen Informationen auf einen Blick und erfasse die Finanzlage eines Unternehmens sofort.',

          dach_a: 'Aktien aus Deutschland, Schweiz, Österreich',
          dach_b: ' sowie Europa und den USA',
          dach_text: 'Wir bieten nicht nur Informationen zum amerikanischen Markt, sondern decken auch Aktien aus Deutschland, Österreich und der Schweiz ab. Alle Inhalte sind in deutscher Sprache verfügbar.',

          valuation_a: 'Umfassende Unternehmens&shy;bewertungen',
          valuation_b: ' mit leicht verständlichen Value Investing-Methoden',
          valuation_text: 'Wir haben Bewertungsmethoden aus renommierten Büchern wie \"Invested\", \"The Intelligent Investor\" und \"One Up On Wall Street\" implementiert und visuell aufbereitet. Du kannst getroffene Annahmen flexibel mit Deinen eigenen Prognosen anpassen.',
        }
      },
      language: '',
    }
  },
  created() {
    this.language = sessionManager.read('language');
    document.title = 'Valuetion';
    logUserEvent('landingPage', sessionManager, this.$vuetify.breakpoint.mobile);
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
    heroImage() {
      return {
        backgroundImage: `url(${require('../assets/background04.jpg')})`
      };
    }
  },
  methods: {
    registerBeta() {
      this.$router.push({ path: '/register'});
    },
  }

}
</script>

<style>


</style>

<template>
<div>
  <section :class="$vuetify.breakpoint.smAndDown ? 'pt-v-md pb-v-2xl px-v-2xl' : 'py-v-3xl px-v-4xl'">
    <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
      <div>
        <div class="flex justify-start items-center">
          <span class="flex-none pr-v-sm"><svg :width="$vuetify.breakpoint.smAndDown ? '44px' : '80px'" :height="$vuetify.breakpoint.smAndDown ? '44px' : '80px'" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
          </span>
          <span class="darkest text-v-4xl font-pj pb-v-md sm:text-v-4xl md:text-v-5xl xl:text-v-6xl"><b>value</b>tion</span>
        </div>
        <div>
          <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
            <b v-html="strings['valuetion_headline_a']"></b><span v-html="strings['valuetion_headline_b']"></span> 
          </div>
          <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl pb-v-xl'">
            {{  strings['valuetion_text'] }}
          </div>      
        </div>
      </div>
      <div>
        <div><img src="../assets/rocket.png" alt="image" style="height: 100%; width: 100%; max-height: 600px; object-fit: contain;" /></div>
      </div>
    </div>  
  </section>

    <!-- beta phase registration -->
    <section v-if="!waitlist" class="bg-lightest py-v-xl">
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl' : 'px-v-4xl'">
      <div class="flex justify-center">
        <div> 
          <div :class="$vuetify.breakpoint.smAndDown ? ' darkest text-v-2xl font-pj text-center pb-v-md' : ' darkest text-v-4xl font-pj text-center pb-v-md'">
            <b v-html="strings['beta_a']"></b><span v-html="strings['beta_b']"></span> 
          </div>        
          <div class="pt-v-md pb-v-md text-center">
            <button type="button" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="registerBeta">
              {{ strings['beta_request'] }}
            </button>
          </div>    
        </div>
      </div>                        
    </div>  
  </section>

  <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-3xl px-v-2xl' : 'py-v-3xl px-v-4xl'">
    <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
      <div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
          <span v-if="language=='de'">
            <b>Investiere rational</b> mit Selbstvertrauen und Gelassenheit.
          </span>
          <span v-else>
            <b>Invest rational</b> with confidence and peace of mind
          </span> 
          
        </div>
          <div  v-if="language=='de'" :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl pb-v-xl'">
            Investiere klar und unbelastet. 
            Wir schaffen Transparenz in Deinen Anlageentscheidungen, indem wir Dir helfen, die Ablenkungen des Marktes zu ignorieren und Dich auf fundierte Bewertungen zu konzentrieren.
          </div>
          <div  v-else :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl pb-v-xl'">
            Embrace focus, reason, and serenity.
            We enhance the transparency of your investment decisions, enabling you to sidestep market distractions and concentrate on solid, well-founded evaluations.
          </div>
        <div>
          <ul v-if="language=='de'" class="list-disc pt-v-md pb-v-sm">
            <li class="darkest text-v-base font-pj"><b>Bewährte Strategien:</b> Nutze das Wissen von Value-Investing-Experten.</li>
            <li class="darkest text-v-base font-pj"><b>Umfassende Einsichten:</b> Konzentriere Dich auf das gesamte Unternehmen, nicht nur auf den Aktienkurs.</li>
            <li class="darkest text-v-base font-pj"><b>Detaillierte Aktien-Analyse:</b> Vertiefe Dich in fundamentale Daten für fundierte Entscheidungen.</li>
            <li class="darkest text-v-base font-pj"><b>Langfristige Perspektive:</b> Baue Vermögen mit Weitblick auf.</li>
          </ul>
          <ul v-else class="list-disc pt-v-md pb-v-sm">
            <li class="darkest text-v-base font-pj"><b>Proven Strategies:</b> Harness the wisdom of Value Investing experts.</li>
            <li class="darkest text-v-base font-pj"><b>Comprehensive Insight:</b> Focus on the entire company, not just the stock price.</li>
            <li class="darkest text-v-base font-pj"><b>Detailed Analysis:</b> Delve deep into fundamental data for informed decisions.</li>
            <li class="darkest text-v-base font-pj"><b>Long-Term Outlook:</b> Build wealth with a forward-looking perspective.</li>
          </ul> 
        </div>
      </div>
      <div>
        <div><img src="../assets/reading.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
      </div>
    </div>  
  </section>
  

  <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-3xl px-v-2xl' : 'py-v-3xl px-v-4xl'">
    <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
      <div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
          <span v-if="language=='de'">
            <b>Überwinde die Marktpsychologie</b> und konzentriere Dich auf das Wesentliche.
          </span>
          <span v-else>
            <b>Overcome market psychology</b> and focus on what truly matters
          </span>          
        </div>
        <div>
          <div  v-if="language=='de'" :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl pb-v-xl'">
            Schluss mit Ablenkungen! Wir helfen Dir, mit Klarheit zu investieren und sagen Nein zu Ablenkungen und kurzfristigem Denken.
          </div>
          <div  v-else :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl pb-v-xl'">
            Stop the distractions! We help you to invest with clarity, and say no to distractions and short-term thinking.
          </div>
          <ul v-if="language=='de'" class="list-disc pt-v-md pb-v-sm">
            <li class="darkest text-v-base font-pj"><b>Keine Aktienkurse:</b> Nur vollständige Unternehmensdaten.</li>
            <li class="darkest text-v-base font-pj"><b>Keine Preis-Charts:</b> Vermeide den Ankereffekt.</li>
            <li class="darkest text-v-base font-pj"><b>Keine technische Analyse:</b> Ignoriere das Rauschen.</li>
            <li class="darkest text-v-base font-pj"><b>Keine kurzfristigen Impulse:</b> Reduziere den Stress.</li>
            <li class="darkest text-v-base font-pj"><b>Kein Aktualitätsbias:</b> Blicke langfristig in die Zukunft.</li>
            <li class="darkest text-v-base font-pj"><b>Kein Stückelungsbias:</b> Erkenne den wahren Wert, unbeeinflusst von Aktiensplits.</li>
          </ul>
          <ul v-else class="list-disc pt-v-md pb-v-sm">
            <li class="darkest text-v-base font-pj"><b>No Stock Prices:</b> Just the full company insight.</li>
            <li class="darkest text-v-base font-pj"><b>No Charts:</b> Avoid the anchoring effect.</li>
            <li class="darkest text-v-base font-pj"><b>No Technical Analysis:</b> Cut through the noise.</li>
            <li class="darkest text-v-base font-pj"><b>No Short-Term Impulses:</b> Eliminate stress.</li>
            <li class="darkest text-v-base font-pj"><b>No Recency Bias:</b> See beyond the immediate.</li>
            <li class="darkest text-v-base font-pj"><b>No Unit Bias:</b> Understand the real value, unaffected by stock splits.</li>
          </ul>
        </div> 
      </div>
      <div>
        <div>
          <div><img src="../assets/stressed_stop.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
        </div>
      </div>
    </div>  
  </section>

  <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-3xl px-v-2xl' : 'py-v-3xl px-v-4xl'">
    <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
      <div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
          <b v-html="strings['overview_a']"></b><span v-html="strings['overview_b']"></span> 
        </div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl pb-v-xl'">
          {{ strings['overview_text'] }}
        </div>
      </div>
      <div>
        <div><img src="../assets/msft_en.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
      </div>
    </div>  
  </section>

  <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-3xl px-v-2xl' : 'py-v-3xl px-v-4xl'">
    <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
      <div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
          <b v-html="strings['guru_a']"></b><span v-html="strings['guru_b']"></span> 
        </div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
          {{ strings['guru_text'] }}<b>{{ strings['guru_buffett'] }}</b>, <b>{{ strings['guru_lynch'] }}</b>, <b>{{ strings['guru_town'] }}</b>, <b>{{ strings['guru_graham'] }}</b>{{ strings['guru_text_end'] }}
        </div>      
      </div>
      <div>
        <div><img src="../assets/guru.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
      </div>
    </div>  
  </section>


 

  <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-3xl px-v-2xl' : 'py-v-3xl px-v-4xl'">
    <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
      <div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
          <b v-html="strings['screener_a']"></b><span v-html="strings['screener_b']"></span> 
        </div>
        <div class="darkest text-v-base font-pj max-w-2xl pb-v-xl">
          {{ strings['screener_text'] }}
        </div>
      </div>
      <div>
        <div><img src="../assets/search.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
      </div>
    </div>  
    <div class="grid grid-cols-1 mt-10 sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-2 md:gap-0 xl:mt-24">
      <div class="pb-v-xl pr-v-xl">
        <div class="flex">
          <div><img src="../assets/circle.png" alt="image" style="height: 100%; width: 100%; max-height: 100px; object-fit: contain;" /></div>
        </div>
        <div>
          <div class="darkest text-v-xl font-bold font-pj">{{strings['screener_1']}}</div>
          <div class="darkest text-v-base font-pj">{{strings['screener_1_text']}}</div>
        </div>
      </div>
      <div class="pb-v-xl pr-v-xl">
        <div class="flex">
          <div><img src="../assets/loupe.png" alt="image" style="height: 100%; width: 100%; max-height: 100px; object-fit: contain;" /></div>
        </div>
        <div>
          <div class="darkest text-v-xl font-bold font-pj">{{strings['screener_2']}}</div>
          <div class="darkest text-v-base font-pj">{{strings['screener_2_text']}}</div>
        </div>
      </div>
      <div class="pb-v-xl pr-v-xl">
        <div class="flex">
          <div><img src="../assets/sale_tag.png" alt="image" style="height: 100%; width: 100%; max-height: 100px; object-fit: contain;" /></div>
        </div>
        <div>
          <div class="darkest text-v-xl font-bold font-pj">{{strings['screener_3']}}</div>
          <div class="darkest text-v-base font-pj">{{strings['screener_3_text']}}</div>
        </div>
      </div>
      <div class="py-v-xl">
        <div class="flex">
          <div><img src="../assets/letter.png" alt="image" style="height: 100%; width: 100%; max-height: 80px; object-fit: contain;" /></div>
        </div>
        <div>
          <div class="darkest text-v-xl font-bold font-pj">{{strings['screener_4']}}</div>
          <div class="darkest text-v-base font-pj">{{strings['screener_4_text']}}</div>
        </div>
      </div>
    </div>
  </section>

  <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-3xl px-v-2xl' : 'py-v-3xl px-v-4xl'">
    <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
      <div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
          <b v-html="strings['graphical_a']"></b><span v-html="strings['graphical_b']"></span> 
        </div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl pb-v-xl'">
          {{strings['graphical_text']}}
        </div>      
      </div>
      <div>
        <div><img src="../assets/graphical.jpg" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
      </div>
    </div>  
  </section>

  <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-3xl px-v-2xl' : 'py-v-3xl px-v-4xl'">
    <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
      <div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
          <b v-html="strings['valuation_a']"></b><span v-html="strings['valuation_b']"></span> 
        </div>

        <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl pb-v-xl'">
          {{strings['valuation_text']}}
        </div>      
      </div>
      <div>
        <div><img src="../assets/valuation_en.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
      </div>
    </div>  
  </section>

</div>
</template>


/* eslint-enable */ 