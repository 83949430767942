/* eslint-disable */

import { Colors } from './colors';
import { formatUnitOfAccount } from './helper';
import { Graph, GraphNode, CreateGridBasedGraph } from './arrow-graph';
import { getTranslation } from './translations';

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the income statement
/---------------------------------------------------------------------------------------------------------*/
export function createCashflowStatementGraph(uniqueId, cashflow, type, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  if(type == 'FMP')
  {
    return createCashflowStatementGraphFMP(uniqueId, cashflow, unitOfAccount, language, svgId, fontSizeScale);
  }
  else
  {
    return createCashflowStatementGraphFH(uniqueId, cashflow, unitOfAccount, language, svgId, fontSizeScale);
  }
  return;
}

//---------------------------------------------------------------------------------------------------------/
// Helper function to search for the maximum value in the cashflow statement
//---------------------------------------------------------------------------------------------------------/
function searchMaxCashflowFH(cashflow)
{
  // we are searching for the largest absolute value in the income statement
  var max = Math.abs(cashflow.netIncomeStartingLine);
  if(Math.abs(cashflow.netIncomeStartingLine) > max)
  {
    max = Math.abs(cashflow.netIncomeStartingLine);
  }
  if(Math.abs(cashflow.depreciationAmortization) > max)
  {
    max = Math.abs(cashflow.depreciationAmortization);
  }
  if(Math.abs(cashflow.deferredTaxesInvestmentTaxCredit) > max)
  {
    max = Math.abs(cashflow.deferredTaxesInvestmentTaxCredit);
  }
  if(Math.abs(cashflow.otherFundsNonCashItems) > max)
  {
    max = Math.abs(cashflow.otherFundsNonCashItems);
  }
  if(Math.abs(cashflow.cashTaxesPaid) > max)
  {
    max = Math.abs(cashflow.cashTaxesPaid);
  }
  if(Math.abs(cashflow.cashInterestPaid) > max)
  {
    max = Math.abs(cashflow.cashInterestPaid);
  }
  if(Math.abs(cashflow.cashNet) > max)
  {
    max = Math.abs(cashflow.cashNet);
  }
  if(Math.abs(cashflow.changesinWorkingCapital) > max)
  {
    max = Math.abs(cashflow.changesinWorkingCapital);
  }
  if(Math.abs(cashflow.netOperatingCashFlow) > max)
  {
    max = Math.abs(cashflow.netOperatingCashFlow);
  }
  if(Math.abs(cashflow.capex) > max)
  {
    max = Math.abs(cashflow.capex);
  }
  if(Math.abs(cashflow.otherInvestingCashFlowItemsTotal) > max)
  {
    max = Math.abs(cashflow.otherInvestingCashFlowItemsTotal);
  }
  if(Math.abs(cashflow.netInvestingCashFlow) > max)
  {
    max = Math.abs(cashflow.netInvestingCashFlow);
  }
  if(Math.abs(cashflow.cashDividendsPaid) > max)
  {
    max = Math.abs(cashflow.cashDividendsPaid);
  }
  if(Math.abs(cashflow.issuanceReductionCapitalStock) > max)
  {
    max = Math.abs(cashflow.issuanceReductionCapitalStock);
  }
  if(Math.abs(cashflow.issuanceReductionDebtNet) > max)
  {
    max = Math.abs(cashflow.issuanceReductionDebtNet);
  }
  if(Math.abs(cashflow.otherFundsFinancingItems) > max)
  {
    max = Math.abs(cashflow.otherFundsFinancingItems);
  }
  if(Math.abs(cashflow.netCashFinancingActivities) > max)
  {
    max = Math.abs(cashflow.netCashFinancingActivities);
  }
  if(Math.abs(cashflow.foreignExchangeEffects) > max)
  {
    max = Math.abs(cashflow.foreignExchangeEffects);
  }
  if(Math.abs(cashflow.changeinCash) > max)
  {
    max = Math.abs(cashflow.changeinCash);
  }
  if(Math.abs(cashflow.fcf) > max)
  {
    max = Math.abs(cashflow.fcf);
  }
  if(Math.abs(cashflow.stockBasedCompensation) > max)
  {
    max = Math.abs(cashflow.stockBasedCompensation);
  }
  return max;
}

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the cashflow statement based on the Finnhub data
/---------------------------------------------------------------------------------------------------------*/
export function createCashflowStatementGraphFH(uniqueId, cashflow, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  var width = 40;
  var height = 100;
  var max = searchMaxCashflowFH(cashflow);
  var scale = height / max;
  let fontSizeSmall = 11 * fontSizeScale;
  let fontSizeBig = 14 * fontSizeScale;
  var graph = new Graph(fontSizeSmall);

  
  // cashflow from operation 
  // netCashProvidedByOperatingActivities = netIncome + depreciationAndAmortization + deferredIncomeTax + stockBasedCompensation + changeInWorkingCapital + otherNonCashItems
  /*---------------------------------------------------------------------------------------------------------*/
  var colorOpPos = Colors.LimeGreen;
  var colorOpNeg = Colors.LimeGreen;

  // netOperatingCashFlow 
  var netOperatingCashFlow = new GraphNode(getTranslation('netOperatingCashFlow', language), formatUnitOfAccount(cashflow.netOperatingCashFlow, unitOfAccount, true), scale * cashflow.netOperatingCashFlow, colorOpPos, colorOpNeg, fontSizeBig, true);
  graph.addNode(netOperatingCashFlow);


  // netIncomeStartingLine / netOperatingCashflow 
  var netIncomeStartingLine = new GraphNode(getTranslation('netIncomeStartingLine', language), formatUnitOfAccount(cashflow.netIncomeStartingLine, unitOfAccount, true), scale * cashflow.netIncomeStartingLine, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(netIncomeStartingLine);
  graph.addLink(netIncomeStartingLine, "right", netOperatingCashFlow, "left", 1);

  // netIncomeStartingLine / depreciationAndAmortization
  var depreciationAmortization = new GraphNode(getTranslation('depreciationAmortization', language), formatUnitOfAccount(cashflow.depreciationAmortization, unitOfAccount, true), scale * cashflow.depreciationAmortization, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(depreciationAmortization);
  graph.addLink(depreciationAmortization, "right", netOperatingCashFlow, "left", 1);

   // netIncomeStartingLine / deferredTaxesInvestmentTaxCredit
  var deferredTaxesInvestmentTaxCredit = new GraphNode(getTranslation('deferredTaxesInvestmentTaxCredit', language), formatUnitOfAccount(cashflow.deferredTaxesInvestmentTaxCredit, unitOfAccount, true), scale * cashflow.deferredTaxesInvestmentTaxCredit, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(deferredTaxesInvestmentTaxCredit);
  graph.addLink(deferredTaxesInvestmentTaxCredit, "right", netOperatingCashFlow, "left", 1);

  // netIncomeStartingLine / otherFundsNonCashItems
  var otherFundsNonCashItems = new GraphNode(getTranslation('otherFundsNonCashItems', language), formatUnitOfAccount(cashflow.otherFundsNonCashItems, unitOfAccount, true), scale * cashflow.otherFundsNonCashItems, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(otherFundsNonCashItems);
  graph.addLink(otherFundsNonCashItems, "right", netOperatingCashFlow, "left", 1);

  // netIncomeStartingLine / stockBasedCompensation
  var stockBasedCompensation = new GraphNode(getTranslation('stockBasedCompensation', language), formatUnitOfAccount(cashflow.stockBasedCompensation, unitOfAccount, true), scale * cashflow.stockBasedCompensation, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(stockBasedCompensation);
  graph.addLink(stockBasedCompensation, "right", netOperatingCashFlow, "left", 1);

  // netIncomeStartingLine / changesinWorkingCapital
  var changesinWorkingCapital = new GraphNode(getTranslation('changesinWorkingCapital', language), formatUnitOfAccount(cashflow.changesinWorkingCapital, unitOfAccount, true), scale * cashflow.changesinWorkingCapital, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(changesinWorkingCapital);
  graph.addLink(changesinWorkingCapital, "right", netOperatingCashFlow, "left", 1);

  // netIncomeStartingLine / otherInvestingCashFlowItemsTotal
  var cashNet = new GraphNode(getTranslation('cashNet', language), formatUnitOfAccount(cashflow.cashNet, unitOfAccount, true), scale * cashflow.cashNet, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(cashNet);
  graph.addLink(cashNet, "right", netOperatingCashFlow, "left", 1);

  // cashflow from investing
  var colorInvestPos = Colors.Orange2;
  var colorInvestNeg = Colors.Orange2;

  // netInvestingCashFlow
  var netInvestingCashFlow = new GraphNode(getTranslation('netInvestingCashFlow', language), formatUnitOfAccount(cashflow.netInvestingCashFlow, unitOfAccount, true), scale * cashflow.netInvestingCashFlow, colorInvestPos, colorInvestNeg, fontSizeBig, true);
  graph.addNode(netInvestingCashFlow);

  // netInvestingCashFlow / capex
  var capex = new GraphNode(getTranslation('capex', language), formatUnitOfAccount(cashflow.capex, unitOfAccount, true), scale * cashflow.capex, colorInvestPos, colorInvestNeg, fontSizeSmall);
  graph.addNode(capex);
  graph.addLink(capex, "right", netInvestingCashFlow, "left", 1);

  // netInvestingCashFlow / otherInvestingCashFlowItemsTotal
  var otherInvestingCashFlowItemsTotal = new GraphNode(getTranslation('otherInvestingCashFlowItemsTotal', language), formatUnitOfAccount(cashflow.otherInvestingCashFlowItemsTotal, unitOfAccount, true), scale * cashflow.otherInvestingCashFlowItemsTotal, colorInvestPos, colorInvestNeg, fontSizeSmall);
  graph.addNode(otherInvestingCashFlowItemsTotal);
  graph.addLink(otherInvestingCashFlowItemsTotal, "right", netInvestingCashFlow, "left", 1);

  // cashflow from financing
  var colorFinPos = Colors.Blue4;
  var colorFinNeg = Colors.Blue4;

  // netCashFinancingActivities
  var netCashFinancingActivities = new GraphNode(getTranslation('netCashFinancingActivities', language), formatUnitOfAccount(cashflow.netCashFinancingActivities, unitOfAccount, true), scale * cashflow.netCashFinancingActivities, colorFinPos, colorFinNeg, fontSizeBig, true);
  graph.addNode(netCashFinancingActivities);

  // netCashFinancingActivities / cashDividendsPaid
  var cashDividendsPaid = new GraphNode(getTranslation('cashDividendsPaid', language), formatUnitOfAccount(cashflow.cashDividendsPaid, unitOfAccount, true), scale * cashflow.cashDividendsPaid, colorFinPos, colorFinNeg, fontSizeSmall);
  graph.addNode(cashDividendsPaid);
  graph.addLink(cashDividendsPaid, "right", netCashFinancingActivities, "left", 1);

  // netCashFinancingActivities / issuanceReductionCapitalStock
  var issuanceReductionCapitalStock = new GraphNode(getTranslation('issuanceReductionCapitalStock', language), formatUnitOfAccount(cashflow.issuanceReductionCapitalStock, unitOfAccount, true), scale * cashflow.issuanceReductionCapitalStock, colorFinPos, colorFinNeg, fontSizeSmall);
  graph.addNode(issuanceReductionCapitalStock);
  graph.addLink(issuanceReductionCapitalStock, "right", netCashFinancingActivities, "left", 1);

  // netCashFinancingActivities / issuanceReductionDebtNet
  var textIssuanceReductionDebtNet = '';
  if(cashflow.issuanceReductionDebtNet > 0)
  {
    textIssuanceReductionDebtNet = getTranslation('issuanceReductionDebtNetPos', language);
  }
  else
  {
    textIssuanceReductionDebtNet = getTranslation('issuanceReductionDebtNetNeg', language);
  }
  var issuanceReductionDebtNet = new GraphNode(textIssuanceReductionDebtNet, formatUnitOfAccount(cashflow.issuanceReductionDebtNet, unitOfAccount, true), scale * cashflow.issuanceReductionDebtNet, colorFinPos, colorFinNeg, fontSizeSmall);
  graph.addNode(issuanceReductionDebtNet);
  graph.addLink(issuanceReductionDebtNet, "right", netCashFinancingActivities, "left", 1);

  // netCashFinancingActivities / otherFundsFinancingItems
  var otherFundsFinancingItems = new GraphNode(getTranslation('otherFundsFinancingItems', language), formatUnitOfAccount(cashflow.otherFundsFinancingItems, unitOfAccount, true), scale * cashflow.otherFundsFinancingItems, colorFinPos, colorFinNeg, fontSizeSmall);
  graph.addNode(otherFundsFinancingItems);
  graph.addLink(otherFundsFinancingItems, "right", netCashFinancingActivities, "left", 1);

  // netIncomeStartingLine / foreignExchangeEffects
  var foreignExchangeEffects = new GraphNode(getTranslation('foreignExchangeEffects', language), formatUnitOfAccount(cashflow.foreignExchangeEffects, unitOfAccount, true), scale * cashflow.foreignExchangeEffects, Colors.Green, Colors.RedOrange, fontSizeSmall);
  graph.addNode(foreignExchangeEffects);

  // change in cash
  var changeinCash = new GraphNode(getTranslation('changeinCash', language), formatUnitOfAccount(cashflow.changeinCash, unitOfAccount, true), scale * cashflow.changeinCash, Colors.Green, Colors.RedOrange, fontSizeBig, true);

  graph.addNode(changeinCash);
  graph.addLink(netOperatingCashFlow, "right", changeinCash, "left", 1);
  graph.addLink(netInvestingCashFlow, "right", changeinCash, "left", 1);
  graph.addLink(netCashFinancingActivities, "right", changeinCash, "left", 1);
  graph.addLink(foreignExchangeEffects, "right", changeinCash, "left", 1);

  graph.transformGraph(scale, unitOfAccount, language);
  
  // create grid based graph
  var name = 'cashflow' + cashflow.period + uniqueId;
  if(cashflow.isTTM)
  {
    name += "TTM";
  }

  var knownOrderedNodes = [];
  knownOrderedNodes.push(netIncomeStartingLine);
  knownOrderedNodes.push(netOperatingCashFlow);

  var gridGraph = CreateGridBasedGraph(name, 500, width, 250, 60, graph, knownOrderedNodes, netIncomeStartingLine, netOperatingCashFlow);
  return gridGraph.toSVG(svgId); 
}

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the income statement based on financial modeling prep data
/---------------------------------------------------------------------------------------------------------*/
export function createCashflowStatementGraphFMP(uniqueId, cashflow, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  var width = 40;
  var height = 100;
  var max = 0;


  if(cashflow.netCashProvidedByOperatingActivities > 0)
  {
    max = cashflow.netCashProvidedByOperatingActivities;
  }
  else
  {
    max = -cashflow.netCashProvidedByOperatingActivities;
  }
  if(cashflow.netCashUsedForInvestingActivites > 0)
  {
    if(max < cashflow.netCashUsedForInvestingActivites)
    {
      max = cashflow.netCashUsedForInvestingActivites;
    }
  }
  else
  {
    if(max < -cashflow.netCashUsedForInvestingActivites)
    {
      max = -cashflow.netCashUsedForInvestingActivites;
    }
  }
  if(cashflow.netCashUsedProvidedByFinancingActivities > 0)
  {
    if(max < cashflow.netCashUsedProvidedByFinancingActivities)
    {
      max = cashflow.netCashUsedProvidedByFinancingActivities;
    }
  }
  else
  {
    if(max < -cashflow.netCashUsedProvidedByFinancingActivities)
    {
      max = -cashflow.netCashUsedProvidedByFinancingActivities;
    }
  }
  var scale = height / max;
  let fontSizeSmall = 11 * fontSizeScale;
  let fontSizeBig = 14 * fontSizeScale;

  var graph = new Graph(fontSizeSmall);

  
  // cashflow from operation 
  // netCashProvidedByOperatingActivities = netIncome + depreciationAndAmortization + deferredIncomeTax + stockBasedCompensation + changeInWorkingCapital + otherNonCashItems
  /*---------------------------------------------------------------------------------------------------------*/
  var colorOpPos = Colors.LimeGreen;
  var colorOpNeg = Colors.LimeGreen;

  var netCashProvidedByOperatingActivities = new GraphNode(language == 'de' ? 'Cashflow aus laufender Geschäftstätigkeit' : 'Cashflow from Operations', formatUnitOfAccount(cashflow.netCashProvidedByOperatingActivities, unitOfAccount, true), scale * cashflow.netCashProvidedByOperatingActivities, colorOpPos, colorOpNeg, fontSizeBig, true);
  graph.addNode(netCashProvidedByOperatingActivities);
  
  var netIncome = new GraphNode(language == 'de' ? 'Nettoeinkommen': 'Net Income', formatUnitOfAccount(cashflow.netIncome, unitOfAccount), scale * cashflow.netIncome, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(netIncome);
  graph.addLink(netIncome, "right", netCashProvidedByOperatingActivities, "left", 1);

  var depreciationAndAmortization = new GraphNode(language == 'de' ? 'Abschreibungen und Amortisation' : 'Depreciation and Amortization', formatUnitOfAccount(cashflow.depreciationAndAmortization, unitOfAccount, true), scale * cashflow.depreciationAndAmortization, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(depreciationAndAmortization);
  graph.addLink(depreciationAndAmortization, "right", netCashProvidedByOperatingActivities, "left", 1);

  var deferredIncomeTax = new GraphNode(language == 'de' ? 'Rückgestellte Einkommensteuer' : 'Deferred Income Tax', formatUnitOfAccount(cashflow.deferredIncomeTax, unitOfAccount, true), scale * cashflow.deferredIncomeTax, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(deferredIncomeTax);
  graph.addLink(deferredIncomeTax, "right", netCashProvidedByOperatingActivities, "left", 1);

  var stockBasedCompensation = new GraphNode(language == 'de' ? 'Aktienbasierte Vergütung' : 'Stock Based Compensation', formatUnitOfAccount(cashflow.stockBasedCompensation, unitOfAccount, true), scale * cashflow.stockBasedCompensation, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(stockBasedCompensation);
  graph.addLink(stockBasedCompensation, "right", netCashProvidedByOperatingActivities, "left", 1);
  
  var changeInWorkingCapital = new GraphNode(language == 'de' ? ' Veränderung des Betriebskapitals' : 'Change in Working Captial', formatUnitOfAccount(cashflow.changeInWorkingCapital, unitOfAccount, true), scale * cashflow.changeInWorkingCapital, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(changeInWorkingCapital);
  graph.addLink(changeInWorkingCapital, "right", netCashProvidedByOperatingActivities, "left", 1);
 
  /*
  var otherNonCashItems = new GraphNode(language == 'de' ? 'Andere nicht liquiditätswirksame Positionen' : 'Other Non Cash Items', formatUnitOfAccount(cashflow.otherNonCashItems, unitOfAccount, true), scale * cashflow.otherNonCashItems, colorOpPos, colorOpNeg, fontSizeSmall);
  graph.addNode(otherNonCashItems);
  graph.addLink(otherNonCashItems, "right", netCashProvidedByOperatingActivities, "left", 1);
  */
  


  // cashflow from investing
  /*---------------------------------------------------------------------------------------------------------*/
  var colorInvestPos = Colors.Orange2;
  var colorInvestNeg = Colors.Orange2;

  var netCashUsedForInvestingActivites = new GraphNode(language == 'de' ? 'Cashflow aus Investitionstätigkeit' : 'Cashflow from Investing', formatUnitOfAccount(cashflow.netCashUsedForInvestingActivites, unitOfAccount, true), scale * cashflow.netCashUsedForInvestingActivites, colorInvestPos, colorInvestNeg, fontSizeBig, true);
  graph.addNode(netCashUsedForInvestingActivites);
  
  
  var investmentsInPropertyPlantAndEquipment = new GraphNode(language == 'de' ? 'Sachanlagen' : 'Property Plant and Equipement', formatUnitOfAccount(cashflow.investmentsInPropertyPlantAndEquipment, unitOfAccount, true), scale * cashflow.investmentsInPropertyPlantAndEquipment, colorInvestPos, colorInvestNeg, fontSizeSmall);
  graph.addNode(investmentsInPropertyPlantAndEquipment);
  graph.addLink(investmentsInPropertyPlantAndEquipment, "right", netCashUsedForInvestingActivites, "left", 1);

  var salesMaturitiesOfInvestments = new GraphNode(language == 'de' ? 'Verkauf von Anlagen im Fälligkeitsjahr' : 'Sales of Maturities', formatUnitOfAccount(cashflow.salesMaturitiesOfInvestments, unitOfAccount, true), scale * cashflow.salesMaturitiesOfInvestments, colorInvestPos, colorInvestNeg, fontSizeSmall);
  graph.addNode(salesMaturitiesOfInvestments);
  graph.addLink(salesMaturitiesOfInvestments, "right", netCashUsedForInvestingActivites, "left", 1);

  var purchasesOfInvestments = new GraphNode(language == 'de' ? 'Kauf von Investitionen' : 'Purchases of Investments', formatUnitOfAccount(cashflow.purchasesOfInvestments, unitOfAccount, true), scale * cashflow.purchasesOfInvestments, colorInvestPos, colorInvestNeg, fontSizeSmall);
  graph.addNode(purchasesOfInvestments);
  graph.addLink(purchasesOfInvestments, "right", netCashUsedForInvestingActivites, "left", 1);

  var acquisitionsNet = new GraphNode(language == 'de' ? 'Akquisitionen' : 'Acquisitions', formatUnitOfAccount(cashflow.acquisitionsNet, unitOfAccount, true), scale * cashflow.acquisitionsNet, colorInvestPos, colorInvestNeg, fontSizeSmall);
  graph.addNode(acquisitionsNet);
  graph.addLink(acquisitionsNet, "right", netCashUsedForInvestingActivites, "left", 1);

  var otherInvestingActivites = new GraphNode(language == 'de' ? 'Andere Investitionstätigkeiten' : 'Other Investing Activities', formatUnitOfAccount(cashflow.otherInvestingActivites, unitOfAccount, true), scale * cashflow.otherInvestingActivites, colorInvestPos, colorInvestNeg, fontSizeSmall);
  graph.addNode(otherInvestingActivites);
  graph.addLink(otherInvestingActivites, "right", netCashUsedForInvestingActivites, "left", 1);
  

  // cashflow from finance
  /*---------------------------------------------------------------------------------------------------------*/
  var colorFinancingPos = Colors.Blue4;
  var colorFinancingNeg = Colors.Blue4;

  var netCashUsedProvidedByFinancingActivities = new GraphNode(language == 'de' ? 'Cashflow aus Finanzierungstätigkeit' : 'Cashflow from Financing', formatUnitOfAccount(cashflow.netCashUsedProvidedByFinancingActivities, unitOfAccount, true), scale * cashflow.netCashUsedProvidedByFinancingActivities, colorFinancingPos, colorFinancingNeg, fontSizeBig, true);
  graph.addNode(netCashUsedProvidedByFinancingActivities);


  var debtRepayment = new GraphNode(language == 'de' ? 'Schuldentilgung' : 'Debt Repayment', formatUnitOfAccount(cashflow.debtRepayment, unitOfAccount, true), scale * cashflow.debtRepayment, colorFinancingPos, colorFinancingNeg, fontSizeSmall);
  graph.addNode(debtRepayment);
  graph.addLink(debtRepayment, "right", netCashUsedProvidedByFinancingActivities, "left", 1);

  var commonStockIssued = new GraphNode(language == 'de' ? 'Ausgegebene Aktien' : 'Stock Issued', formatUnitOfAccount(cashflow.commonStockIssued, unitOfAccount, true), scale * cashflow.commonStockIssued, colorFinancingPos, colorFinancingNeg, fontSizeSmall);
  graph.addNode(commonStockIssued);
  graph.addLink(commonStockIssued, "right", netCashUsedProvidedByFinancingActivities, "left", 1);

  var commonStockRepurchased = new GraphNode(language == 'de' ? 'Zurückgekaufte Aktien' : 'Stock Repurchased', formatUnitOfAccount(cashflow.commonStockRepurchased, unitOfAccount, true), scale * cashflow.commonStockRepurchased, colorFinancingPos, colorFinancingNeg, fontSizeSmall);
  graph.addNode(commonStockRepurchased);
  graph.addLink(commonStockRepurchased, "right", netCashUsedProvidedByFinancingActivities, "left", 1);

  var dividendsPaid = new GraphNode(language == 'de' ? 'Ausgezahlte Dividenden' : 'Dividends Paid', formatUnitOfAccount(cashflow.dividendsPaid, unitOfAccount, true), scale * cashflow.dividendsPaid, colorFinancingPos, colorFinancingNeg, fontSizeSmall);
  graph.addNode(dividendsPaid);
  graph.addLink(dividendsPaid, "right", netCashUsedProvidedByFinancingActivities, "left", 1);

  var otherFinancingActivites = new GraphNode(language == 'de' ? 'Andere finanzielle Aktivitäten' : 'Other Financing Activities', formatUnitOfAccount(cashflow.otherFinancingActivites, unitOfAccount, true), scale * cashflow.otherFinancingActivites, colorFinancingPos, colorFinancingNeg, fontSizeSmall);
  graph.addNode(otherFinancingActivites);
  graph.addLink(otherFinancingActivites, "right", netCashUsedProvidedByFinancingActivities, "left", 1);
  
  

  // cash (beginning & end of period)
  /*---------------------------------------------------------------------------------------------------------*/
  var netChangeInCash = new GraphNode(language == 'de' ? 'Netto-Veränderung der liquiden Mittel' : 'Net Change in Cash', formatUnitOfAccount(cashflow.netChangeInCash, unitOfAccount, true), scale * cashflow.netChangeInCash, Colors.Green, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(netChangeInCash);
  
  var cashAtBeginningOfPeriod = new GraphNode(language == 'de' ? 'Liquidität zu Beginn des Zeitraums' : 'Cash at Beginning of Period', formatUnitOfAccount(cashflow.cashAtBeginningOfPeriod, unitOfAccount, true), scale * cashflow.cashAtBeginningOfPeriod, Colors.Green, Colors.RedOrange, fontSizeSmall);
  graph.addNode(cashAtBeginningOfPeriod);

  var cashAtEndOfPeriod = new GraphNode(language == 'de' ? 'Liquidität am Ende des Zeitraums' : 'Cash at End of Period', formatUnitOfAccount(cashflow.cashAtEndOfPeriod, unitOfAccount, true), scale * cashflow.cashAtEndOfPeriod, Colors.Green, Colors.RedOrange, fontSizeSmall);
  graph.addNode(cashAtEndOfPeriod);

  graph.addLink(cashAtBeginningOfPeriod, "right", cashAtEndOfPeriod, "left", 1);
  graph.addLink(netChangeInCash, "right", cashAtEndOfPeriod, "left", 1);
  graph.addLink(netCashProvidedByOperatingActivities, "right", netChangeInCash, "left", 1);
  graph.addLink(netCashUsedForInvestingActivites, "right", netChangeInCash, "left", 1);
  graph.addLink(netCashUsedProvidedByFinancingActivities, "right", netChangeInCash, "left", 1);

  graph.transformGraph(scale, unitOfAccount, language);
  
  // create grid based graph
  var name = 'cashflow' + cashflow.date + uniqueId;
  if(cashflow.isTTM)
  {
    name += "TTM";
  }
  var gridGraph = CreateGridBasedGraph(name, 500, width, 250, 60, graph);
  return gridGraph.toSVG(svgId); 

}


/* eslint-enable */



