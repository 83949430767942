import { render, staticRenderFns } from "./svg-save.vue?vue&type=template&id=d51db02a"
import script from "./svg-save.vue?vue&type=script&lang=js"
export * from "./svg-save.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports