/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationCombinedValuationInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Combined Valuation',        
            explaination: 'We use many different valuation methods from value investing gurus based on the fundamental data.',
            explaination2: 'The Combined Valuation combines these valuation methods into a single value. ' +
            'To get the most meaningful value, we use the median method, using the median of the better valuations based on earnings, ' +
            'free cash flow and owner earnings as well as book value.',
            explaination3: 'The individual valuations can be found on the company overview pages and are described in more detail there.',
          },
          de: {
            title: 'Kombinierte Bewertung',
            explaination: 'Wir verwenden viele verschiedene Bewertungsmethoden von Value-Investing Gurus basierend auf den Fundamentaldaten.',
            explaination2: 'Die Kombinierte Bewertung verrechnet diese Bewertungsmethoden zu einem einzelnen Wert. ' +
            'Um einen möglichst aussagekräften Wert zu erhalten, verwenden wir die Median-Methode, und zwar so, ' +
            'dass der Median aus den besseren Bewertungen basierend auf Gewinn, Freier Cashflow und Owner Earnings sowie dem Buchwert verwendet wird.',
            explaination3: 'Die Einzelnen Bewertungen sind auf den Übersichtsseiten der Unternehmen zu finden und dort genauer beschrieben.',   
          }
        },
        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>

<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination3'] }}</div>
    </template>
  </PopupInfo>
</template>
  
/* eslint-enable */ 
  