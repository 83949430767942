<script>
/* eslint-disable */

import {
  formatPercent,
  formatUnitOfAccount,
  toUnitOfAccount,
  getUnitOfAccountUnit,
  getInterpolatedColor,
  getColor10Ratio,
  getColorValuationRatio,
  getColorDividendYield,
  getColorDividendYieldSupportedByFreeCashflow,
} from '../helper';


import { sessionManager } from '@/main';

export default {
  name: 'CompanyList',
  props: {
    unitOfAccount: {
      type: String,
      required: true,
      default: () => 'USD',
    },
    companies : {
      type: Array,
      required: true,
      default: () => [],
    },
    serverSideSearch: {
      type: Boolean,
      required: true,
      default: () => false,
    },

  },
  components: {
  },
  data() {
    return {
      language: '',
      languageStrings: {
        en: {         
          following: 'Following',
          unfollow: 'Unfollow',
        },
        de: {
          following: 'Folge ich',
          unfollow: 'Nicht mehr folgen',
        },
      },
      languageHeaders: {
        en: [
          {
            text: '',
            value: 'action',
            width: '30px',
            sortable: false,
            showOnMobile: true,
          },
          {
            text: 'Symbol',
            value: 'symbol',
            width: '20px',
            sortable: true,
            class: 'header-text',
            showOnMobile: true,
          },
          {
            text: 'Company',
            value: 'companyName',
            width: '180px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Valuation',
            value: 'vr',
            width: '40px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Country',
            value: 'country',
            width: '10px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Industry',
            value: 'industry',
            width: '120px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Market Cap',
            value: 'marketCap',
            width: '160px',
            align: 'right',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Price/Earnings',
            value: 'pe',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Price/Free Cash Flow',
            value: 'pfc',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Price/Owner Earnings',
            value: 'poe',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'ROE last 5 years',
            value: 'roe5',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Dividend Yield',
            value: 'dividendYield',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Dividends as % of Free Cashflow',
            value: 'dividendsOfFCF',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Book value + dividends growth last 5 years',
            value: 'bndG5',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Net Debt in years of Operating Cashflow',
            value: 'netDebtInYearsOfOCF',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Ten Cap of Earnings Valuation Ratio',
            value: 'tenCapEVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Ten Cap of Free Cashflow Valuation Ratio',
            value: 'tenCapFCFVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Ten Cap of Owner Earnings Valuation Ratio',
            value: 'tenCapOEVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Discounted Earnings Valuation Ratio',
            value: 'mosVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Payback Time of Free Cashflow Valuation Ratio',
            value: 'pbtVRFCF',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Payback Time of Owner Earnings Valuation Ratio',
            value: 'pbtVROE',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Price/Earnings Growth Ratio',
            value: 'pegRatio',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Graham Number Valuation Ratio',
            value: 'grahamVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Net Current Asset Value Valuation Ratio',
            value: 'ncavVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
        ],
        de: [
          {
            text: '',
            value: 'action',
            width: '30px',
            sortable: false,
            showOnMobile: true,
          },
          {
            text: 'Symbol',
            value: 'symbol',
            width: '20px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Name',
            value: 'companyName',
            width: '180px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Bewertung',
            value: 'vr',
            width: '40px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Land',
            value: 'country',
            width: '10px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Industrie',
            value: 'industry',
            width: '120px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Marktkapitalisierung',
            value: 'marketCap',
            width: '160px',
            align: 'right',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Kurs / Gewinn (KGV)',
            value: 'pe',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / freier Cashflow (P/FC)',
            value: 'pfc',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Owner Earnings (P/OE)',
            value: 'poe',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Eigenkapitalrendite der letzten 5 Jahre',
            value: 'roe5',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Dividendenrendite',
            value: 'dividendYield',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Dividenden als % des freien Cashflow',
            value: 'dividendsOfFCF',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Ø Buchwert- und Dividendenwachstum der letzten 5 Jahre',
            value: 'bndG5',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: '# Jahre für Schulden-Rückzahlung mit operativem Cashflow',
            value: 'netDebtInYearsOfOCF',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / 10x Gewinn',
            value: 'tenCapEVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / 10x Freier Cashflow',
            value: 'tenCapFCFVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / 10x Owner Earnings',
            value: 'tenCapOEVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Diskontierter Gewinn',
            value: 'mosVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Payback-Time Freier Cashflow',
            value: 'pbtVRFCF',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Payback-Time Owner Earnings',
            value: 'pbtVROE',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Kurs-Gewinn-Wachstums-Verhältnis (PEG-Ratio)',
            value: 'pegRatio',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Graham Nummer',
            value: 'grahamVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Liquidationswert',
            value: 'ncavVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },          
        ]
      },
      options_new: {},
      step: 1,
      stepInfo: 1,
      loading: true,
      totalCompanies: 0,
      items: [],
      tab: null,
      items: [
        { tab: 'Company Financials', content: '' },
        { tab: 'Company Location', content: '' },
      ],
      unitOfAccountUnit: '',     
    };
  },
  watch: {
    options_new: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    replaceDotWithUnderscore(str) {
      return str.replace(/\./g, "_");
    },
    gotoPremium(item)
    {
      const url = this.$router.resolve({ path: '/premium' }).href;
      window.open(url, '_blank');  
    },
    fillTable () {
      this.loading = true;
      if(this.companies == null || this.companies.length == 0)
      {
        this.loading = false;
        return;
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options_new;

      // load the data into the items
      let unitOfAccount = this.unitOfAccount;      

      let arr = [];
      const maxRatio = 999;
      let invalid = '-';

      this.companies.forEach(function(c){
        arr.push({
          action: false,
          symbol: c.id,
          companyName: c.companyName,
          currency: c.currency,
          marketCap: c.price != null ? formatUnitOfAccount(c.price, unitOfAccount, true) : 'XXXXXXXXXX',
          country: c.country,
          industry: c.industry,
          roe5: formatPercent(c.roe5, 0),
          bndG5: formatPercent(c.bndG5, 0),
          netDebtInYearsOfOCF: c.netDebt < 0 ? 'no debt' : (c.netDebtInYearsOfOCF < 0) ? '∞' : (c.netDebtInYearsOfOCF).toFixed(1),
          pe: (c.pe > maxRatio || c.pe <= 0.0) ? invalid : (c.pe).toFixed(1),
          pfc: (c.pfc > maxRatio || c.pfc <= 0.0) ? invalid : (c.pfc).toFixed(1),
          poe: (c.poe > maxRatio || c.poe <= 0.0) ? invalid : (c.poe).toFixed(1),
          tenCapEVR: (typeof c.tenCapEVR == "undefined" || c.tenCapEVR > maxRatio || c.tenCapEVR <= 0.0 )? '-' : c.tenCapEVR.toFixed(1),
          tenCapFCFVR: (typeof c.tenCapFCFVR == "undefined" || c.tenCapFCFVR > maxRatio || c.tenCapFCFVR <= 0.0 )? '-' : c.tenCapFCFVR.toFixed(1),
          tenCapOEVR:  (typeof c.tenCapOEVR == "undefined" || c.tenCapOEVR > maxRatio || c.tenCapOEVR <= 0.0 )? '-' : c.tenCapOEVR.toFixed(1),
          mosVR: (c.mosVR > maxRatio || c.mosVR <= 0.0)  ? '-' : c.mosVR.toFixed(1),
          pbtVRFCF: (typeof c.pbtVRFCF == "undefined" || c.pbtVRFCF > maxRatio || c.pbtVRFCF <= 0.0) ? '-' : c.pbtVRFCF.toFixed(1),
          pbtVROE: (typeof c.pbtVROE == "undefined" || c.pbtVROE > maxRatio || c.pbtVROE <= 0.0) ? '-' : c.pbtVROE.toFixed(1),
          pegRatio: (typeof c.pegRatio == "undefined" || c.pegRatio > maxRatio || c.pegRatio <= 0.0) ? '-' : c.pegRatio.toFixed(1),
          grahamVR: (typeof c.grahamNumber == "undefined" || c.grahamNumber > maxRatio || c.grahamNumber <= 0.0) ? '-' : c.grahamNumber.toFixed(1),
          ncavVR: (c.ncavVR > maxRatio || c.ncavVR <= 0.0) ? '-' : c.ncavVR.toFixed(1),
          dividendYield: (typeof c.dividendYield == "undefined") ? '-' : formatPercent(c.dividendYield, 1),
          dividendsOfFCF: (typeof c.dividendsOfFCF == "undefined") ? '-' : formatPercent(c.dividendsOfFCF,0),
          vr: (typeof c.vr == "undefined" || c.vr > maxRatio || c.vr <= 0.0)? '-' : c.vr.toFixed(1),
          canAccess: c.canAccess,
          watchlistHovering: false,
        })         
      });

      this.items = arr;
      this.totalCompanies = arr.length;
      this.loading = false;
    },
    getColorValuationRatio(value) {
      return getColorValuationRatio(value)
    }, 
    getColorDebt(yearsOfCashFlow) {

      let years = yearsOfCashFlow;
      if(years == '-')
      {
        years = 0;
      }

      return getInterpolatedColor(-years, -10, -6, -4, -3, -2, -1, -0.5);
    },    
    convertToNumber(str) {
      // Remove "%" character and white spaces from the string
      str = str.replace(/%/g, '').replace(/\s+/g, '');

      // Convert the string to a number and return the result
      return Number(str);
    },
    getColorGrowth(growth)
    {
      var g = this.convertToNumber(growth);
      return getInterpolatedColor(g/100, -0.2, -0.1, -0.05, 0, 0.05, 0.1, 0.2);
    },
    getColorFactor(factor)
    {
      return getColor10Ratio(factor);
    },
    getColorDividendY(dividendY)
    {
      var v = this.convertToNumber(dividendY)/100;
      return getColorDividendYield(v);
    },
    getColorDividendYieldFCF(dividendYFCF)
    {
      var v = this.convertToNumber(dividendYFCF)/100;
      return getColorDividendYieldSupportedByFreeCashflow(v);
    },
    updateData()
    {
    },
    // remove the company from the watchlist
    async removeFromWaitlist(symbol) {
      // remove from the local watchlist
      const url = '/api/HttpTriggerWatchlist';
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
        body: JSON.stringify({
           action: 'remove', 
           id: symbol, 
        }),
      }).then((response) => {
        if (response.status === 200) {
          // show the popup with the success message
          this.isOnWatchlist = false;

          // remove locally
          this.items = this.items.filter((item) => item.symbol !== symbol);  
        }
      });
    },
  },
  created() {
    this.language = sessionManager.read('language');
    this.unitOfAccountUnit = getUnitOfAccountUnit(this.unitOfAccount);
    this.fillTable();    
  },
  computed: {
    row_classes(item) {
      let classes = 'darkest font-pj text-v-tiny pl-0';
      return classes;
    },
    strings() {
      return this.languageStrings[this.language];
    },
    headers() {
      // filter the headers based on mobile or not
       if (this.$vuetify.breakpoint.mobile) {
        return this.languageHeaders[this.language].filter(h => h.showOnMobile);
      } else {
        return this.languageHeaders[this.language];
      }
    },
  },
  watch: {
    companies: {
      handler(val) {
        this.fillTable();
      }
    },
  }
}
</script>

<style scoped>

  .v-data-table {
      font-family: Plus Jakarta Sans;  
      font-size: 16px !important; 
      font-weight:400;
  }

  .v-data-table >>> th {
      font-family: Plus Jakarta Sans;  
      font-size: 14px !important; 
      font-weight: normal !important;
      vertical-align: baseline !important;    
      padding-left: 0  !important;  
      color:#1d270f !important;     
  }

  .v-data-table >>> td {
      font-family: Plus Jakarta Sans;  
      font-size: 14px !important; 
      font-weight: normal !important;
      padding-left: 0  !important;   
      color:#1d270f !important;  
  }


  .blurred {
    filter: blur(5px);
    cursor: pointer;
  }

</style>

<template>
  <div>
    <!--
    <v-data-table v-if="companies && companies.length > 0"
      dense
      :headers="headers"
      :items="items"
      :items-per-page="100"
      :hide-default-footer="false"
      :footer-props="{'items-per-page-options':[20, 50, 100, 1000]}"
      :options.sync="options_new"
      :server-items-length="serverSideSearch ? totalCompanies : null"
      :loading="loading"
      :item-class="row_classes"
      mobile-breakpoint="0"
      @update:page="updateData"
      @update:sortBy="updateData"
      @update:options="updateData"
    >
    -->
    <v-data-table v-if="companies && companies.length > 0"
      dense
      :headers="headers"
      :items="items"
      :items-per-page="100"
      :hide-default-footer="false"
      :footer-props="{'items-per-page-options':[20, 50, 100, 1000]}"
      :loading="loading"
      :item-class="row_classes"
      mobile-breakpoint="0"
    >
      <template #item.action="{item}">
        <div v-if="item.canAccess">
          <button type="button" 
              class="text-v-tiny flex-none bg-primary border rounded-md border-solid border-very-light-gray regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-very-light-gray" 
              @click="removeFromWaitlist(item.symbol)"
              @mouseover="item.watchlistHovering = true" @mouseleave="item.watchlistHovering = false">
              {{ item.watchlistHovering ? strings['unfollow'] : strings['following'] }}
          </button>
        </div>
        <div v-else class="blurred" @click="gotoPremium(item)"></div>
      </template>

      <template #item.symbol="{item}">
        <div v-if="item.canAccess">
          <router-link :to="{ path: 'profile/' + replaceDotWithUnderscore(item.symbol) }" target="_blank">
            <div>{{ item.symbol }}</div>
          </router-link>
        </div>
        <div v-else class="blurred" @click="gotoPremium(item)">{{ item.symbol }}</div>
      </template>

      <template #item.companyName="{item}">
        <div v-if="item.canAccess">{{ item.companyName }}</div>
        <div v-else class="blurred" @click="gotoPremium(item)">{{ item.companyName }}</div>
      </template>

      <template #item.country="{item}">
        <div v-if="item.canAccess">{{ item.country }}</div>
        <div v-else class="blurred" @click="gotoPremium(item)">{{ item.country }}</div>
      </template>

      <template #item.industry="{item}">
        <div v-if="item.canAccess">{{ item.industry }}</div>
        <div v-else class="blurred" @click="gotoPremium(item)">{{ item.industry }}</div>
      </template>

      <template #item.marketCap="{item}">
        <div v-if="item.canAccess">{{ item.marketCap }}</div>
        <div v-else class="blurred" @click="gotoPremium(item)">{{ item.marketCap }}</div>
      </template>

      <template #item.tenCapEVR="{value, index}">
        <div :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>
      <template #item.tenCapOEVR="{value, index}">
        <div :style="{ 'text-align': 'center','background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>
      <template #item.tenCapFCFVR="{value, index}">
        <div :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>
      <template #item.mosVR="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>
      <template #item.pbtVRFCF="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>
      <template #item.pbtVROE="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>
      <template #item.ncavVR="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>
      <template #item.netDebtInYearsOfOCF="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorDebt(value) }">{{ value }}</div>
      </template>
      <template #item.bndG5="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorGrowth(value) }">{{ value }}</div>
      </template>
      <template #item.roe5="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorGrowth(value) }">{{ value }}</div>
      </template>
      <template #item.pe="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorFactor(value) }">{{ value }}</div>
      </template>
      <template #item.pfc="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorFactor(value) }">{{ value }}</div>
      </template>
      <template #item.poe="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorFactor(value) }">{{ value }}</div>
      </template>
      <template #item.vr="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>        
      <template #item.pegRatio="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>   
      <template #item.grahamVR="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
      </template>   
      <template #item.dividendYield="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorDividendY(value) }">{{ value }}</div>
      </template>
      <template #item.dividendsOfFCF="{value, index}">
        <div  :style="{ 'text-align': 'center', 'background-color': getColorDividendYieldFCF(value) }">{{ value }}</div>
      </template>       

    </v-data-table>
  </div>
</template>

/* eslint-enable */ 