/* eslint-disable */
<script>
export default {
  name: 'Knowledge',
  props: {
  },
  methods: {
  },
}
</script>



<style>
  .v-input--checkbox .v-label {
    font-size: 0.9em;
  }
  p {
    font-size: 0.9em;
  }

  .font_white {
    color: white;
    font-size: 1rem;
  }

  .font_white_small {
    color: white;
    font-size: 2rem;
  }

  .font_white_big {
    color: white;
    font-size: 2.5rem;
  }

  .h2.font_white_small, h2.font_white_small {
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga";
    font-family: FS Kim,Georgia,"Serif";
    font-weight: 500;
    line-height: 1.03;
  }

  .h2.font_white_big, h2.font_white_big {
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga";
    font-family: FS Kim,Georgia,"Serif";
    font-weight: 500;
    line-height: 1.03;
  }

</style>

<template>
  <v-container class="pa-0" fluid>
    <v-container class="pb-0 pl-0 pr-0 pt-4" fluid style="background-image: linear-gradient(#0b275b, #0b275b);">
      <v-row >
        <v-col align="center" justify="center">
          <h2 :class="$vuetify.breakpoint.smAndDown ? 'headline_mobile_white' : 'headline_white'">Knowledge Base</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pt-6">
      <h2><a name="TenCapBuffetOwnerEarnings" style="text-decoration: none; color: inherit;">Ten Cap of Buffett Owner Earnings</a></h2>
      <p>
        Owner earnings is a valuation method detailed by <a href="https://www.berkshirehathaway.com/letters/1986.html">Warren Buffett in Berkshire Hathaway's annual report in 1986</a>. 
        The value of a company is simply the total of the net cash flows (owner earnings) expected to occur over the life of the business, minus any reinvestment of earnings.
      </p>
      <P>
        Buffett defined owner earnings as follows:
      </P>
      <P>
        "These represent (a) reported earnings plus (b) depreciation, depletion, amortization, and certain other non-cash charges ... less (c) the average annual amount of capitalized expenditures for plant and equipment, etc. that the business requires to fully maintain its long-term competitive position and its unit volume ... Our owner-earnings equation does not yield the deceptively precise figures provided by GAAP, since (c) must be a guess - and one sometimes very difficult to make. Despite this problem, we consider the owner earnings figure, not the GAAP figure, to be the relevant item for valuation purposes ... All of this points up the absurdity of the 'cash flow' numbers that are often set forth in Wall Street reports. 
        These numbers routinely include (a) plus (b) - but do not subtract (c)."
      </P>

      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Formula</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Pre-Tax Income</b></td>
              <td>Income of the company excluding taxes</td>
            </tr>
            <tr>
              <td>+ Depreciation and Amortization</td>
              <td>Depreciation and Amortization are arbitrary choices in GAAP accounting</td>
            </tr>
            <tr>
              <td>+ Change in Receivables</td>
              <td>Money that is still owed to the company</td>
            </tr>
            <tr>
              <td>+ Change in Payables</td>
              <td>Money that still needs to be payed by the company</td>
            </tr>
            <tr>
              <td><b>= Money the company has to spend</b></td>
              <td></td>
            </tr>
            <tr>
              <td><b>- Maintenance Capital Expenditure</b></td>
              <td>
                The mandatory investments into plants property and equipement that is needed to maintain the buisness and stay competitive.
              </td>
            </tr>
            <tr>
              <td><b>= Owner Earnings</b></td>
              <td>The money left at the discression of the company to invest in growth, acquisitions or handouts to the shareholders.</td>
            </tr>
            <tr>
              <td><b>Valuation = 10x of Owner Earnings</b></td>
              <td>Paying 10x the Owner Earnings for a company is a resonable price.</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p></p>
      <h3>Capital Expenditure</h3>
      <p>Capital Expenditure consists of two parts:</p>
      <p>
        Maintenance Capex: the mandatory capital expenditure that is needed to mainain the buisness.
      </p>
      <p>
        Growth Capex: the optional capital expenditure that is needed to grow the buiness.
      </p>
      <p>
        For this valuation we need to estimate the amount of Maintenance Capex of the reported Capital Expenditures. Some companies specify the growth and maintenance in their 10K statements. If you know the business well you should be able to estimate Maintenance vs. Growth Capex. For the screener we estimate 70% for maintenance and 30% for growth.
      </p>   
    </v-container>
  </v-container>
</template>

/* eslint-enable */ 