
<script>
import { sessionManager } from '@/main';
import { logUserEvent } from '../helper';

export default {
  name: 'UserWelcome',
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Welcome!',
        },
        de: {
          title: 'Willkommen!',
        },
      },
      language: '',
      name: '',
      surname: '',
      selectedUnitOfAccount: null,
    };
  },
  
  created() {
    this.language = sessionManager.read('language');
    this.name = sessionManager.read('name');
    this.surname = sessionManager.read('surname');
    this.selectedUnitOfAccount = sessionManager.read('unitOfAccount');
    logUserEvent('welcome', sessionManager, this.$vuetify.breakpoint.mobile);
  },
  computed: {
    strings() {
      return this.languageStrings[this.language];
    },
  },
  methods: {
  },
}
</script>

<style scoped>
</style>

<template>
  <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-md flex justify-center' : 'px-v-xl flex justify-center'">
    <div class="max-w-4xl">
      <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h3>
      <div v-if="language == 'de'">
        <div class="text-v-sm darkest font-pj">
          Wir freuen uns, dass Du Valuetion ausprobierst. <br> <br>
          Da wir uns noch in der frühen <b>Beta-Phase</b> befinden, könnten noch einige kleine Hürden oder Fehler auftauchen. <br>
          Wir würden uns riesig über Dein Feedback und Deine Eindrücke von der Plattform freuen - sei es bezüglich Fehler, fehlenden Funktionen oder einfach deiner allgemeinen Nutzererfahrung. 
          Melde Dich unter <a href="mailto:info@valuetion.com">info@valuetion.com</a>.
        </div>
        <div class="text-v-sm darkest font-pj pt-v-xl">
          In der <a class="underline" @click="$router.push({ path: '/search'})"><b>Aktiensuche</b></a> kannst Du nach unterbewerteten Aktien der amerikanischen <b>NYSE</b> und <b>NASDAQ</b> Börse suchen und diese analysieren.
          Es kann also sein, dass wir noch nicht alle Aktien abdecken die Du suchst. Eine breitere Abdeckung ist geplant.
          <div class="p-v-md"> 
            <a @click="$router.push({ path: '/search'})"><img src="../assets/screener.png" alt="image" style="height: 100%; width: 100%; max-width: 480px; object-fit: contain;" /></a>
          </div>
        </div>
        <div class="text-v-sm darkest font-pj pt-v-xl">
          Um auf die <b>Übersichtsseite</b> einer Aktie zu wechseln, gib den Namen in der Titelzeile (unter "Ticker symbol") ein. 
          <div class="p-v-md"> 
            <img src="../assets/symbol_search.gif" alt="image" style="height: 100%; width: 100%; max-width: 480px; object-fit: contain;" />
          </div>
          Auf der Übersichtsseite einer Aktie kannst Du die wichtigsten <b>Kennzahlen</b>, die <b>Bewertungen</b> und die <b>Finanzstatements</b> einsehen. Außerdem kannst Du die Aktie zu Deiner Watchlist hinzufügen, um sie im Auge zu behalten.
          <div class="p-v-md"> 
            <a @click="$router.push({ path: '/profile/MSFT'})"><img src="../assets/overview.gif" alt="image" style="height: 100%; width: 100%; max-width: 480px; object-fit: contain;" /></a>
          </div>
        </div>
        <div class="text-v-sm darkest font-pj pt-v-xl">
          Wir wünschen Dir nun viel Spaß beim Ausprobieren!
        </div>
      </div>
      <div v-else>
        <div class="text-v-sm darkest font-pj">
          We are happy that you are trying out Valuetion. <br> <br>
          We are still in an early <b>beta phase</b>, so please don't expect too much yet. We are working hard to improve Valuetion and appreciate your feedback. 
          You can contact us at <a href="mailto:info@valuetion.com">info@valuetion.com</a>.
        </div>
        <div class="text-v-sm darkest font-pj pt-v-xl">
          In the <a class="underline" @click="$router.push({ path: '/search'})"><b>Screener</b></a> you can search for undervalued stocks listed on the American <b>NYSE</b> and <b>NASDAQ</b> exchange and analyze them. 
          We may not yet cover all the stocks you are looking for, but that should change soon.
          <div class="p-v-md"> 
            <a @click="$router.push({ path: '/search'})"><img src="../assets/screener.png" alt="image" style="height: 100%; width: 100%; max-width: 480px; object-fit: contain;" /></a>
          </div>
        </div>
        <div class="text-v-sm darkest font-pj pt-v-xl">
          To switch to the <b>overview page</b> of a stock, enter the name in the title bar (under "Ticker symbol"). 
          <div class="p-v-md"> 
            <img src="../assets/symbol_search.gif" alt="image" style="height: 100%; width: 100%; max-width: 480px; object-fit: contain;" />
          </div>
          On the overview page of a stock you can view the <b>key figures</b>, the <b>valuations</b> and the <b>financial statements</b>. 
          You can also add the stock to your watchlist to keep an eye on it.
          <div class="p-v-md"> 
            <a @click="$router.push({ path: '/profile/MSFT'})"><img src="../assets/overview.gif" alt="image" style="height: 100%; width: 100%; max-width: 480px; object-fit: contain;" /></a>
          </div>
          <div class="text-v-sm darkest font-pj pt-v-xl">
            We hope you enjoy trying it out!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


