/* eslint-disable */
<script>

  import { formatUnitOfAccount, 
    toUnitOfAccount, 
    getInterpolatedColor,
  } from '../helper';

  import CompanyValuationPeterLynchPegRatioInfo from '@/components/company-valuation-peter-lynch-peg-ratio-info.vue';

  import { Colors, adjustColor, interpolateColors } from '../colors';
  import { sessionManager } from '@/main';
 
  export default {
    name: 'CompanyValuationPeterLynchPEGRatio',
    props: {
      valuation: {
        type: Object,
        required: true,
        default: () => null,
      },
      unitOfAccount: {
        type: String,
        required: true,
        default: () => 'USD',
      },
      marketCap: {
        type: Object,
        required: true,
        default: () => null,
      },
      logo: {
        type: Boolean,
        default: () => false
      },
      logoText: {
        type: String,
        default: () => ''
      },
    },
    components: {
      CompanyValuationPeterLynchPegRatioInfo,
    },
    data() {
      return {
        fontSize: '14px',
        growthRate: 0,
        growthRateSet: false,
        languageStrings: {
          en: {
            title: 'Peter Lynch PEG Ratio',
            subtitle: 'From "One Up On Wall Street" by Peter Lynch',
            earnings: 'Earnings TTM',   
            marketCap: 'Market Cap',
            growth: 'Earnings Growth Rate',
            pegRatio: 'Price to Earnings Growth (PEG) Ratio',
            help: 'More information'
          },
          de: {
            title: 'Peter Lynch Kurs-Gewinn-Wachstums-Verhältnis',
            subtitle: 'Aus "Der Börse einen Schritt voraus" von Peter Lynch',
            earnings: 'Gewinn letzte 12 Monate',   
            marketCap: 'Marktkapitalisierung',
            growth: 'Gewinnwachstum',
            pegRatio: 'Kurs-Gewinn-Wachstums-Verhältnis Verhältnis',
            help: 'Mehr Informationen'
          }
        },
        language: '',
        showPopup: false,
        expanded: true,
      };
    },
    methods: {
      onSetGrowthRate() {
        this.growthRateSet = true;
      },
      closePopup() {
        this.showPopup = false;
      },
    },
    created() {
      this.language = sessionManager.read('language');
      this.growthRate = 100.0 * this.valuation.estimatedGrowthRate;
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
      earningsFormatted()
      {
        return formatUnitOfAccount(this.valuation.netIncome, this.unitOfAccount);
      },
      marketCapFormatted()
      {
        return formatUnitOfAccount(toUnitOfAccount(this.marketCap, this.unitOfAccount), this.unitOfAccount);
      },
      pegRatio()
      {
        var growth = 100.0 * this.valuation.estimatedGrowthRate;
        if(this.growthRateSet)
        {
          growth = this.growthRate;
        }
        var price = toUnitOfAccount(this.marketCap, this.unitOfAccount);
        var earnings = this.valuation.netIncome;
        if(earnings > 0 && growth > 0)
        {
          return (price / (earnings * growth)).toFixed(1);
        }
        else
        {
          return '-';
        }
      },
      pegRatioColor()
      {
        // relative valuation
        if(this.pegRatio != '-' && this.pegRatio > 0)
        {
          let ratio = 1/this.pegRatio;
          let veryBad = 0.25;
          let bad = 0.5;
          let slightlyBad = 0.75;
          let neutral = 1;
          let slightlyGood = 1.5;
          let good = 2;
          let veryGood = 4;

          let colorNeutral = Colors.White;
          return getInterpolatedColor(ratio, veryBad, bad, slightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral);
        }
        else
        {
          return Colors.White;
        }     
      }
    }
  }
</script>
  
  
<template>
  <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <div class="flex">
      <div class="flex-auto">
        <h3 v-if="logo" class="darkest font-pj text-v-xl text-bold">{{ logoText }}</h3>
        <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">{{strings['title']}}</h3>
      </div>
      <div class="flex-none" v-if="!logo">
        <p style="text-align:right;">
          <span class="px-v-sm">
            <v-tooltip bottom color="primary" light>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="showPopup = true">mdi-help-circle-outline</v-icon>
              </template>
              <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
            </v-tooltip>
          </span>
        </p>
      </div>
    </div>
    <p class="darkest font-pj text-v-tiny py-v-sm" @click="showPopup = true">{{ strings['subtitle'] }}</p>
    <v-simple-table v-if="valuation" dense>
      <template v-slot:default>
        <tbody>
          <tr v-if="expanded">
            <td class="darkest font-pj text-v-base pl-0">{{strings['earnings']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right">{{ earningsFormatted }}</td>
          </tr>
          <tr v-if="expanded">
            <td class="darkest font-pj text-v-base pl-0">{{strings['marketCap']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right">{{ marketCapFormatted }}</td>
          </tr>
          <tr v-if="expanded">
            <td class="darkest font-pj text-v-base pl-0" colspan="2">{{strings['growth']}} 
              <v-slider max="50" min="-25" v-model="growthRate" @change="onSetGrowthRate">
                <template v-slot:append>
                  <v-text-field
                    v-model="growthRate"
                    class="mt-0 pt-0"
                    hide-details
                    hide-spin-buttons
                    single-line
                    type="number"
                    suffix="%"
                    style="width: 60px"
                    v-bind:style="{ 'font-size': fontSize }"
                  ></v-text-field>
                </template>
              </v-slider>
            </td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['pegRatio']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': pegRatioColor}">{{ pegRatio }}</td>
          </tr>            
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="logo">
      <div class="flex justify-end">
        <div class="flex-none text-v-base font-pj py-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none text-v-base font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
      </div>
    </div>
    <CompanyValuationPeterLynchPegRatioInfo :onClose="closePopup" :showPopup="showPopup"/>
  </div>
</template>
/* eslint-enable */ 
  