/* eslint-disable */

import { Colors } from './colors';
import { formatUnitOfAccount } from './helper';
import { Graph, GraphNode, CreateGridBasedGraph } from './arrow-graph';
import { getTranslation } from './translations';

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the balance statement
/---------------------------------------------------------------------------------------------------------*/
export function createBalanceStatementGraph(uniqueId, balance, type, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  if(type == 'FMP')
  {
    return createBalanceStatementGraphFMP(uniqueId, balance, unitOfAccount, language, svgId, fontSizeScale);
  }
  else
  {
    return createBalanceStatementGraphFH(uniqueId, balance, unitOfAccount, language, svgId, fontSizeScale);
  }
  return;
}


/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the balance statement
/---------------------------------------------------------------------------------------------------------*/
export function createBalanceStatementGraphFH(uniqueId, balance, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  var width = 40;
  var height = 400;
  var max = balance.totalAssets;
  if(balance.totalLiabilities > balance.totalAssets)
  {
    max = balance.totalLiabilities;
  }
  var scale = height / max;

  var fontSizeSmall = 11 * fontSizeScale;
  var fontSizeBig = 14 * fontSizeScale;
  var graph = new Graph(fontSizeSmall);

  var isInsurance = false;
  var isBank = false;
  var isNormal = false;

  // Check the type of the balance sheet
  if(balance.insurancePolicyLiabilities != 0  || balance.deferredPolicyAcquisitionCosts != 0 || balance.insuranceReceivables != 0)
  {
    // we have an insurance balance sheet
    isInsurance = true;
  }
  else if(balance.cashDueFromBanks != 0 || balance.bankInvestments != 0 || balance.netLoans != 0)
  {
    // we have a bank balance sheet
    isBank = true;
  }
  else
  {
    // we have a normal balance sheet
    isNormal = true;
  }

  // currentAssets
  var currentAssets = null; 
  var totalAssets = new GraphNode(getTranslation('totalAssets', language), formatUnitOfAccount(balance.totalAssets, unitOfAccount, true), scale * balance.totalAssets, Colors.EmeraldGreen, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(totalAssets);

  if(balance.currentAssets == 0)
  {
    currentAssets = totalAssets;
  }
  else
  {
    currentAssets = new GraphNode(getTranslation('currentAssets', language), formatUnitOfAccount(balance.currentAssets, unitOfAccount, true), scale * balance.currentAssets, Colors.LimeGreen, Colors.RedOrange, fontSizeBig);
    graph.addNode(currentAssets);
    graph.addLink(currentAssets, "right", totalAssets, "left", 1);
  }

  // cashDueFromBanks / currentAssets
  var cashDueFromBanks = new GraphNode(getTranslation('cashDueFromBanks', language), formatUnitOfAccount(balance.cashDueFromBanks, unitOfAccount, true), scale * balance.cashDueFromBanks, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(cashDueFromBanks);
  graph.addLink(cashDueFromBanks, "right", currentAssets, "left", 1);

  // bankInvestments / currentAssets
  var bankInvestments = new GraphNode(getTranslation('bankInvestments', language), formatUnitOfAccount(balance.bankInvestments, unitOfAccount, true), scale * balance.bankInvestments, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(bankInvestments);
  graph.addLink(bankInvestments, "right", currentAssets, "left", 1);

  // netLoans / currentAssets
  var netLoans = new GraphNode(getTranslation('netLoans', language), formatUnitOfAccount(balance.netLoans, unitOfAccount, true), scale * balance.netLoans, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(netLoans);
  graph.addLink(netLoans, "right", currentAssets, "left", 1);

  // cashShortTermInvestments / currentAssets
  var cashShortTermInvestments = new GraphNode(getTranslation('cashShortTermInvestments', language), formatUnitOfAccount(balance.cashShortTermInvestments, unitOfAccount, true), scale * balance.cashShortTermInvestments, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(cashShortTermInvestments);
  graph.addLink(cashShortTermInvestments, "right", currentAssets, "left", 1);

  // cash / cashShortTermInvestments
  var cash = new GraphNode(getTranslation('cash', language), formatUnitOfAccount(balance.cash, unitOfAccount, true), scale * balance.cash, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(cash);
  graph.addLink(cash, "right", cashShortTermInvestments, "left", 1);

  // shortTermInvestments / cashShortTermInvestments
  var shortTermInvestments = new GraphNode(getTranslation('shortTermInvestments', language), formatUnitOfAccount(balance.shortTermInvestments, unitOfAccount, true), scale * balance.shortTermInvestments, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(shortTermInvestments);
  graph.addLink(shortTermInvestments, "right", cashShortTermInvestments, "left", 1);

  // totalReceivables / currentAssets
  var totalReceivables = new GraphNode(getTranslation('totalReceivables', language), formatUnitOfAccount(balance.totalReceivables, unitOfAccount, true), scale * balance.totalReceivables, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(totalReceivables);
  graph.addLink(totalReceivables, "right", currentAssets, "left", 1);

  // accountsReceivables / totalReceivables
  var accountsReceivables = new GraphNode(getTranslation('accountsReceivables', language), formatUnitOfAccount(balance.accountsReceivables, unitOfAccount, true), scale * balance.accountsReceivables, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(accountsReceivables);
  graph.addLink(accountsReceivables, "right", totalReceivables, "left", 1);

  // otherReceivables / totalReceivables
  var otherReceivables = new GraphNode(getTranslation('otherReceivables', language), formatUnitOfAccount(balance.otherReceivables, unitOfAccount, true), scale * balance.otherReceivables, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(otherReceivables);
  graph.addLink(otherReceivables, "right", totalReceivables, "left", 1);

  // inventory / currentAssets
  var inventory = new GraphNode(getTranslation('inventory', language), formatUnitOfAccount(balance.inventory, unitOfAccount, true), scale * balance.inventory, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(inventory);
  graph.addLink(inventory, "right", currentAssets, "left", 1);

  // otherCurrentAssets / currentAssets
  var otherCurrentAssets = new GraphNode(getTranslation('otherCurrentAssets', language), formatUnitOfAccount(balance.otherCurrentAssets, unitOfAccount, true), scale * balance.otherCurrentAssets, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(otherCurrentAssets);
  graph.addLink(otherCurrentAssets, "right", currentAssets, "left", 1);



  // long term assets
  let longTermAssetsSum = balance.propertyPlantEquipment +
                          balance.intangiblesAssets +
                          balance.longTermInvestments +
                          balance.otherLongTermAssets +
                          balance.noteReceivableLongTerm;

  // longTermAssets / totalAssets
  var longTermAssets = new GraphNode(getTranslation('longTermAssetsSum', language), formatUnitOfAccount(longTermAssetsSum, unitOfAccount, true), scale * longTermAssetsSum, Colors.LintGreen, Colors.RedOrange, fontSizeBig);
  graph.addNode(longTermAssets);
  graph.addLink(longTermAssets, "right", totalAssets, "left", 1);

  // deferredPolicyAcquisitionCosts / totalAssets
  var deferredPolicyAcquisitionCosts = new GraphNode(getTranslation('deferredPolicyAcquisitionCosts', language), formatUnitOfAccount(balance.deferredPolicyAcquisitionCosts, unitOfAccount, true), scale * balance.deferredPolicyAcquisitionCosts, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(deferredPolicyAcquisitionCosts);
  graph.addLink(deferredPolicyAcquisitionCosts, "right", totalAssets, "left", 1);

  // insuranceReceivables / totalAssets
  var insuranceReceivables = new GraphNode(getTranslation('insuranceReceivables', language), formatUnitOfAccount(balance.insuranceReceivables, unitOfAccount, true), scale * balance.insuranceReceivables, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(insuranceReceivables);
  graph.addLink(insuranceReceivables, "right", totalAssets, "left", 1);

   // propertyPlantEquipment / longTermAssets
  var propertyPlantEquipment = new GraphNode(getTranslation('propertyPlantEquipment', language), formatUnitOfAccount(balance.propertyPlantEquipment, unitOfAccount, true), scale * balance.propertyPlantEquipment, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(propertyPlantEquipment);
  graph.addLink(propertyPlantEquipment, "right", longTermAssets, "left", 1);

  // intangiblesAssets / longTermAssets
  var intangiblesAssets = new GraphNode(getTranslation('intangiblesAssets', language), formatUnitOfAccount(balance.intangiblesAssets, unitOfAccount, true), scale * balance.intangiblesAssets, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(intangiblesAssets);
  graph.addLink(intangiblesAssets, "right", longTermAssets, "left", 1);

  // longTermInvestments / longTermAssets
  var longTermInvestments = new GraphNode(getTranslation('longTermInvestments', language), formatUnitOfAccount(balance.longTermInvestments, unitOfAccount, true), scale * balance.longTermInvestments, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(longTermInvestments);
  graph.addLink(longTermInvestments, "right", longTermAssets, "left", 1);

  // otherLongTermAssets / longTermAssets
  var otherLongTermAssets = new GraphNode(getTranslation('otherLongTermAssets', language), formatUnitOfAccount(balance.otherLongTermAssets, unitOfAccount, true), scale * balance.otherLongTermAssets, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(otherLongTermAssets);
  graph.addLink(otherLongTermAssets, "right", longTermAssets, "left", 1);
  

  // noteReceivableLongTerm / longTermAssets
  var noteReceivableLongTerm = new GraphNode(getTranslation('noteReceivableLongTerm', language), formatUnitOfAccount(balance.noteReceivableLongTerm, unitOfAccount, true), scale * balance.noteReceivableLongTerm, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(noteReceivableLongTerm);
  graph.addLink(noteReceivableLongTerm, "right", longTermAssets, "left", 1);

  // otherAssets / totalAssets
  var otherAssets = new GraphNode(getTranslation('otherAssets', language), formatUnitOfAccount(balance.otherAssets, unitOfAccount, true), scale * balance.otherAssets, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(otherAssets);
  graph.addLink(otherAssets, "right", totalAssets, "left", 1);

  // totalAssets / totalLiabilities
  var totalLiabilities = new GraphNode(getTranslation('totalLiabilities', language), formatUnitOfAccount(balance.totalLiabilities, unitOfAccount, true), scale * balance.totalLiabilities, Colors.RedOrange, Colors.Green, fontSizeBig, true);
  graph.addNode(totalLiabilities);
  graph.addLink(totalLiabilities, "left", totalAssets, "right", 1);

  // cashEquivalents / cashShortTermInvestments
  var cashEquivalents = new GraphNode(getTranslation('cashEquivalents', language), formatUnitOfAccount(balance.cashEquivalents, unitOfAccount, true), scale * balance.cashEquivalents, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(cashEquivalents);
  if(isInsurance)
  {
    graph.addLink(cashEquivalents, "right", totalAssets, "left", 1);
  }
  else
  {
    graph.addLink(cashEquivalents, "right", cashShortTermInvestments, "left", 1);
  }
  

  // currentLiabilities / totalLiabilities
  var currentLiabilities = new GraphNode(getTranslation('currentLiabilities', language), formatUnitOfAccount(balance.currentLiabilities, unitOfAccount, true), scale * balance.currentLiabilities, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(currentLiabilities);
  graph.addLink(currentLiabilities, "left", totalLiabilities, "right", 1);


  // accountsPayable / currentLiabilities
  var accountsPayable = new GraphNode(getTranslation('accountsPayable', language), formatUnitOfAccount(balance.accountsPayable, unitOfAccount, true), scale * balance.accountsPayable, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(accountsPayable);
  graph.addLink(accountsPayable, "left", currentLiabilities, "right", 1);

  // accruedLiability / currentLiabilities
  var accruedLiability = new GraphNode(getTranslation('accruedLiability', language), formatUnitOfAccount(balance.accruedLiability, unitOfAccount, true), scale * balance.accruedLiability, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(accruedLiability);
  graph.addLink(accruedLiability, "left", currentLiabilities, "right", 1);

  // shortTermDebt / currentLiabilities
  var shortTermDebt = new GraphNode(getTranslation('shortTermDebt', language), formatUnitOfAccount(balance.shortTermDebt, unitOfAccount, true), scale * balance.shortTermDebt, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(shortTermDebt);
  graph.addLink(shortTermDebt, "left", currentLiabilities, "right", 1);

  // currentPortionLongTermDebt / currentLiabilities
  var currentPortionLongTermDebt = new GraphNode(getTranslation('currentPortionLongTermDebt', language), formatUnitOfAccount(balance.currentPortionLongTermDebt, unitOfAccount, true), scale * balance.currentPortionLongTermDebt, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(currentPortionLongTermDebt);
  graph.addLink(currentPortionLongTermDebt, "left", currentLiabilities, "right", 1);

  // otherCurrentliabilities / currentLiabilities
  var otherCurrentliabilities = new GraphNode(getTranslation('otherCurrentliabilities', language), formatUnitOfAccount(balance.otherCurrentliabilities, unitOfAccount, true), scale * balance.otherCurrentliabilities, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(otherCurrentliabilities);
  graph.addLink(otherCurrentliabilities, "left", currentLiabilities, "right", 1);

  // otherInterestBearingLiabilities / currentLiabilities
  var otherInterestBearingLiabilities = new GraphNode(getTranslation('otherInterestBearingLiabilities', language), formatUnitOfAccount(balance.otherInterestBearingLiabilities, unitOfAccount, true), scale * balance.otherInterestBearingLiabilities, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(otherInterestBearingLiabilities);
  graph.addLink(otherInterestBearingLiabilities, "left", currentLiabilities, "right", 1);

 
  // long term liabilities
  let longTermLiabilitiesSum = balance.longTermDebt +
                                balance.deferredIncomeTax +
                                balance.otherLiabilities +
                                balance.minorityInterest;

  // longTermLiabilities / totalLiabilities
  var longTermLiabilities = new GraphNode(getTranslation('longTermLiabilities', language), formatUnitOfAccount(longTermLiabilitiesSum, unitOfAccount, true), scale * longTermLiabilitiesSum, Colors.Orange, Colors.Green, fontSizeBig);
  graph.addNode(longTermLiabilities);
  graph.addLink(longTermLiabilities, "left", totalLiabilities, "right", 1);

  // totalDeposits / totalLiabilities
  var totalDeposits = new GraphNode(getTranslation('totalDeposits', language), formatUnitOfAccount(balance.totalDeposits, unitOfAccount, true), scale * balance.totalDeposits, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(totalDeposits);
  graph.addLink(totalDeposits, "left", totalLiabilities, "right", 1);

  // longTermDebt / longTermLiabilities
  var longTermDebt = new GraphNode(getTranslation('longTermDebt', language), formatUnitOfAccount(balance.longTermDebt, unitOfAccount, true), scale * balance.longTermDebt, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(longTermDebt);
  graph.addLink(longTermDebt, "left", longTermLiabilities, "right", 1);

  // deferredIncomeTax / longTermLiabilities
  var deferredIncomeTax = new GraphNode(getTranslation('deferredIncomeTax', language), formatUnitOfAccount(balance.deferredIncomeTax, unitOfAccount, true), scale * balance.deferredIncomeTax, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(deferredIncomeTax);
  graph.addLink(deferredIncomeTax, "left", longTermLiabilities, "right", 1);

  // otherLiabilities / longTermLiabilities
  var otherLiabilities = new GraphNode(getTranslation('otherLiabilities', language), formatUnitOfAccount(balance.otherLiabilities, unitOfAccount, true), scale * balance.otherLiabilities, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(otherLiabilities);
  graph.addLink(otherLiabilities, "left", longTermLiabilities, "right", 1);

  // minorityInterest / longTermLiabilities
  var minorityInterest = new GraphNode(getTranslation('minorityInterest', language), formatUnitOfAccount(balance.minorityInterest, unitOfAccount, true), scale * balance.minorityInterest, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(minorityInterest);
  graph.addLink(minorityInterest, "left", longTermLiabilities, "right", 1);

  // insurancePolicyLiabilities / currentLiabilities
  var insurancePolicyLiabilities = new GraphNode(getTranslation('insurancePolicyLiabilities', language), formatUnitOfAccount(balance.insurancePolicyLiabilities, unitOfAccount, true), scale * balance.insurancePolicyLiabilities, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(insurancePolicyLiabilities);
  graph.addLink(insurancePolicyLiabilities, "left", totalLiabilities, "right", 1);

  // totalEquity / totalAssets
  var totalEquity = new GraphNode(getTranslation('totalEquity', language), formatUnitOfAccount(balance.totalEquity, unitOfAccount, true), scale * balance.totalEquity, Colors.Blue8, Colors.Blue5, fontSizeSmall);
  graph.addNode(totalEquity);
  graph.addLink(totalEquity, "left", totalAssets, "right", 1);

  // commonStock / totalEquity
  var commonStock = new GraphNode(getTranslation('commonStock', language), formatUnitOfAccount(balance.commonStock, unitOfAccount, true), scale * balance.commonStock, Colors.Blue7, Colors.Blue5, fontSizeSmall);
  graph.addNode(commonStock);
  graph.addLink(commonStock, "left", totalEquity, "right", 1);

  // preferredStock / totalEquity
  var preferredStock = new GraphNode(getTranslation('preferredStock', language), formatUnitOfAccount(balance.preferredStock, unitOfAccount, true), scale * balance.preferredStock, Colors.Blue7, Colors.Blue5, fontSizeSmall);
  graph.addNode(preferredStock);
  graph.addLink(preferredStock, "left", totalEquity, "right", 1);

  // treasuryStock / totalEquity
  var treasuryStock = new GraphNode(getTranslation('treasuryStock', language), formatUnitOfAccount(balance.treasuryStock, unitOfAccount, true), scale * balance.treasuryStock, Colors.Blue7, Colors.Blue5, fontSizeSmall);
  graph.addNode(treasuryStock);
  graph.addLink(treasuryStock, "left", totalEquity, "right", 1);

  // retainedEarnings / totalEquity
  var retainedEarnings = new GraphNode(getTranslation('retainedEarnings', language), formatUnitOfAccount(balance.retainedEarnings, unitOfAccount, true), scale * balance.retainedEarnings, Colors.Blue7, Colors.Blue5, fontSizeSmall);
  graph.addNode(retainedEarnings);
  graph.addLink(retainedEarnings, "left", totalEquity, "right", 1);

  // additionalPaidInCapital / totalEquity
  var additionalPaidInCapital = new GraphNode(getTranslation('additionalPaidInCapital', language), formatUnitOfAccount(balance.additionalPaidInCapital, unitOfAccount, true), scale * balance.additionalPaidInCapital, Colors.Blue7, Colors.Blue5, fontSizeSmall);
  graph.addNode(additionalPaidInCapital);
  graph.addLink(additionalPaidInCapital, "left", totalEquity, "right", 1);
 
  // unrealizedProfitLossSecurity / totalEquity
  var unrealizedProfitLossSecurity = new GraphNode(getTranslation('unrealizedProfitLossSecurity', language), formatUnitOfAccount(balance.unrealizedProfitLossSecurity, unitOfAccount, true), scale * balance.unrealizedProfitLossSecurity, Colors.Blue7, Colors.Blue5, fontSizeSmall);
  graph.addNode(unrealizedProfitLossSecurity);
  graph.addLink(unrealizedProfitLossSecurity, "left", totalEquity, "right", 1)

  // otherEquity / totalEquity
  var otherEquity = new GraphNode(getTranslation('otherEquity', language), formatUnitOfAccount(balance.otherEquity, unitOfAccount, true), scale * balance.otherEquity, Colors.Blue7, Colors.Blue5, fontSizeSmall);
  graph.addNode(otherEquity);
  graph.addLink(otherEquity, "left", totalEquity, "right", 1);

  graph.transformGraph(scale, unitOfAccount, language);

  // create grid based graph
  var gridGraph = CreateGridBasedGraph('balance' + balance.period + uniqueId, 400, width, 250, 60, graph);

  return gridGraph.toSVG(svgId); 

}

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the balance statement
/---------------------------------------------------------------------------------------------------------*/
export function createBalanceStatementGraphFMP(uniqueId, balance, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  var width = 40;
  var height = 400;


  var max = balance.totalAssets;
  if(balance.totalLiabilities > balance.totalAssets)
  {
    max = balance.totalLiabilities;
  }

  var scale = height / max;

  var fontSizeSmall = 11 * fontSizeScale;
  var fontSizeBig = 14 * fontSizeScale;

  var graph = new Graph(fontSizeSmall);

  // Current Assets
  /*---------------------------------------------------------------------------------------------------------*/
  var cashAndCashEquivalents = new GraphNode(language == 'de' ? 'Bargeld und gleichwertige Zahlungsmittel' : 'Cash and Cash Equivalents', formatUnitOfAccount(balance.cashAndCashEquivalents, unitOfAccount, true), scale * balance.cashAndCashEquivalents, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(cashAndCashEquivalents);

  var shortTermInvestments = new GraphNode(language == 'de' ? 'Kurzfristige Investitionen' : 'Short Term Investments', formatUnitOfAccount(balance.shortTermInvestments, unitOfAccount, true), scale * balance.shortTermInvestments, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(shortTermInvestments);

  var netReceivables = new GraphNode(language == 'de' ? 'Nettoforderungen' : 'Net Receivables', formatUnitOfAccount(balance.netReceivables, unitOfAccount, true), scale * balance.netReceivables, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(netReceivables);

  var inventory = new GraphNode(language == 'de' ? 'Inventar' : 'Inventory', formatUnitOfAccount(balance.inventory, unitOfAccount), scale * balance.inventory, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(inventory);

  var otherCurrentAssets = new GraphNode(language == 'de' ? 'Sonstige Umlaufvermögen' : 'Other Current Assets', formatUnitOfAccount(balance.otherCurrentAssets, unitOfAccount, true), scale * balance.otherCurrentAssets, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(otherCurrentAssets);

  var totalCurrentAssets = new GraphNode(language == 'de' ? 'Gesamt Umlaufvermögen' : 'Total Current Assets', formatUnitOfAccount(balance.totalCurrentAssets, unitOfAccount, true), scale * balance.totalCurrentAssets, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(totalCurrentAssets);

  graph.addLink(cashAndCashEquivalents, "right", totalCurrentAssets, "left", 1);
  graph.addLink(shortTermInvestments, "right", totalCurrentAssets, "left", 1);
  graph.addLink(netReceivables, "right", totalCurrentAssets, "left", 1);
  graph.addLink(inventory, "right", totalCurrentAssets, "left", 1);
  graph.addLink(otherCurrentAssets, "right", totalCurrentAssets, "left", 1);

  // Non Current Assets
  /*---------------------------------------------------------------------------------------------------------*/
  var propertyPlantEquipmentNet = new GraphNode(language == 'de' ? 'Sachanlagen' : 'Property, Plant & Equipement', formatUnitOfAccount(balance.propertyPlantEquipmentNet, unitOfAccount, true), scale * balance.propertyPlantEquipmentNet, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(propertyPlantEquipmentNet);

  var goodwill = new GraphNode(language == 'de' ? 'Geschäfts- oder Firmenwert (Goodwill)' : 'Goodwill', formatUnitOfAccount(balance.goodwill, unitOfAccount, true), scale * balance.goodwill, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(goodwill);

  var intangibleAssets = new GraphNode(language == 'de' ? 'Immaterielle Vermögenswerte' : 'Intangible Assets', formatUnitOfAccount(balance.intangibleAssets, unitOfAccount, true), scale * balance.intangibleAssets, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(intangibleAssets);

  var longTermInvestments =  new GraphNode(language == 'de' ? 'Langfristige Investitionen' : 'Long Term Investments', formatUnitOfAccount(balance.longTermInvestments, unitOfAccount, true), scale * balance.longTermInvestments, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(longTermInvestments);

  var taxAssets = new GraphNode(language == 'de' ? 'Steuerguthaben' : 'Tax Assets', formatUnitOfAccount(balance.taxAssets, unitOfAccount), scale * balance.taxAssets, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(taxAssets);

  var otherNonCurrentAssets = new GraphNode(language == 'de' ? 'Sonstige langfristige Vermögenswerte' : 'Other Non Current Assets', formatUnitOfAccount(balance.otherNonCurrentAssets, unitOfAccount, true), scale * balance.otherNonCurrentAssets, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(otherNonCurrentAssets);

  var totalNonCurrentAssets = new GraphNode(language == 'de' ? 'Gesamt Anlagevermögen' : 'Total Non Current Assets', formatUnitOfAccount(balance.totalNonCurrentAssets, unitOfAccount, true), scale * balance.totalNonCurrentAssets, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(totalNonCurrentAssets);

  graph.addLink(propertyPlantEquipmentNet, "right", totalNonCurrentAssets, "left", 1);
  graph.addLink(goodwill, "right", totalNonCurrentAssets, "left", 1);
  graph.addLink(intangibleAssets, "right", totalNonCurrentAssets, "left", 1);
  graph.addLink(longTermInvestments, "right", totalNonCurrentAssets, "left", 1);
  graph.addLink(taxAssets, "right", totalNonCurrentAssets, "left", 1);
  graph.addLink(otherNonCurrentAssets, "right", totalNonCurrentAssets, "left", 1);

   // Total Assets
  /*---------------------------------------------------------------------------------------------------------*/
  var otherAssets = new GraphNode(language == 'de' ? 'Sonstige Vermögenswerte' : 'Other Assets', formatUnitOfAccount(balance.otherAssets, unitOfAccount, true), scale * balance.otherAssets, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(otherAssets);
  
  var totalAssets = new GraphNode(language == 'de' ? 'Gesamtvermögen' : 'Total Assets', formatUnitOfAccount(balance.totalAssets, unitOfAccount, true), scale * balance.totalAssets, Colors.EmeraldGreen, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(totalAssets);

  graph.addLink(totalCurrentAssets, "right", totalAssets, "left", 1);
  graph.addLink(totalNonCurrentAssets, "right", totalAssets, "left", 1);
  graph.addLink(otherAssets, "right", totalAssets, "left", 1);

  // Total Libalities (4)
  /*---------------------------------------------------------------------------------------------------------*/
  var totalLiabilities = new GraphNode(language == 'de' ? 'Gesamtverbindlichkeiten' : 'Total Liabilities', formatUnitOfAccount(balance.totalLiabilities, unitOfAccount, true), scale * balance.totalLiabilities, Colors.RedOrange, Colors.Green, fontSizeBig, true);
  graph.addNode(totalLiabilities);
  graph.addLink(totalLiabilities, "left", totalAssets, "right", 1);

  var totalCurrentLiabilities = new GraphNode(language == 'de' ? 'Gesamt kurzfristige Verbindlichkeiten' : 'Total Current Liabilties', formatUnitOfAccount(balance.totalCurrentLiabilities, unitOfAccount, true), scale * balance.totalCurrentLiabilities, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(totalCurrentLiabilities);

  var totalNonCurrentLiabilities = new GraphNode(language == 'de' ? 'Gesamt langfristige Verbindlichkeiten' : 'Total Non Current Liabilties', formatUnitOfAccount(balance.totalNonCurrentLiabilities, unitOfAccount, true), scale * balance.totalNonCurrentLiabilities, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(totalNonCurrentLiabilities);

  var otherLiabilities = new GraphNode(language == 'de' ? 'Sonstige Verbindlichkeiten' : 'Other Liabilties', formatUnitOfAccount(balance.otherLiabilities, unitOfAccount, true), scale * balance.otherLiabilities, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(otherLiabilities);

  graph.addLink(totalCurrentLiabilities, "left", totalLiabilities, "right", 1);
  graph.addLink(totalNonCurrentLiabilities, "left", totalLiabilities, "right", 1);
  graph.addLink(otherLiabilities, "left", totalLiabilities, "right", 1);

  // Current Libalities 6
  /*---------------------------------------------------------------------------------------------------------*/
  var accountPayables = new GraphNode(language == 'de' ? 'Verbindlichkeiten aus Lieferungen und Leistungen' : 'Accounts Payables', formatUnitOfAccount(balance.accountPayables, unitOfAccount, true), scale * balance.accountPayables, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(accountPayables);

  var shortTermDebt = new GraphNode(language == 'de' ? 'Kurzfristige Verbindlichkeiten' : 'Short Term Debt', formatUnitOfAccount(balance.shortTermDebt, unitOfAccount, true), scale * balance.shortTermDebt, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(shortTermDebt);
 
  var taxPayables = new GraphNode(language == 'de' ? 'Steuerverbindlichkeiten' : 'Tax Payables', formatUnitOfAccount(balance.taxPayables, unitOfAccount, true), scale * balance.taxPayables, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(taxPayables);

  var deferredRevenue = new GraphNode(language == 'de' ? 'Ertragsrückstellungen' : 'Deferred Revenue', formatUnitOfAccount(balance.deferredRevenue, unitOfAccount, true), scale * balance.deferredRevenue, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(deferredRevenue);

  var otherCurrentLiabilities = new GraphNode(language == 'de' ? 'Sonstige kurzfristige Verbindlichkeiten' : 'Other Current Liablities', formatUnitOfAccount(balance.otherCurrentLiabilities, unitOfAccount), scale * balance.otherCurrentLiabilities, Colors.RedOrange, Colors.Green, fontSizeSmall);
  graph.addNode(otherCurrentLiabilities);

  graph.addLink(accountPayables, "left", totalCurrentLiabilities, "right", 1);
  graph.addLink(shortTermDebt, "left", totalCurrentLiabilities, "right", 1);
  graph.addLink(taxPayables, "left", totalCurrentLiabilities, "right", 1);
  graph.addLink(deferredRevenue, "left", totalCurrentLiabilities, "right", 1);
  graph.addLink(otherCurrentLiabilities, "left", totalCurrentLiabilities, "right", 1);

  // Non Current Libalities
  /*---------------------------------------------------------------------------------------------------------*/
  var longTermDebt = new GraphNode(language == 'de' ? 'Langfristige Verbindlichkeiten' : 'Long Term Debt', formatUnitOfAccount(balance.longTermDebt, unitOfAccount, true), scale * balance.longTermDebt, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(longTermDebt);

  var deferredRevenueNonCurrent = new GraphNode(language == 'de' ? 'Nicht aktuelle Ertragsrückstellungen' : 'Deferred Revenue Non Current', formatUnitOfAccount(balance.deferredRevenueNonCurrent, unitOfAccount, true), scale * balance.deferredRevenueNonCurrent, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(deferredRevenueNonCurrent);

  var deferredTaxLiabilitiesNonCurrent = new GraphNode(language == 'de' ? 'Nicht aktuelle Steuerrückstellungen' : 'Deferred Tax Liabilities Non Current', formatUnitOfAccount(balance.deferredTaxLiabilitiesNonCurrent, unitOfAccount, true), scale * balance.deferredTaxLiabilitiesNonCurrent, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(deferredTaxLiabilitiesNonCurrent);

  var otherNonCurrentLiabilities = new GraphNode(language == 'de' ? 'Sonstige langfristige Verbindlichkeiten' : 'Other Non Current Liabilities', formatUnitOfAccount(balance.otherNonCurrentLiabilities, unitOfAccount, true), scale * balance.otherNonCurrentLiabilities, Colors.Orange, Colors.Green, fontSizeSmall);
  graph.addNode(otherNonCurrentLiabilities);

  graph.addLink(longTermDebt, "left", totalNonCurrentLiabilities, "right", 1);
  graph.addLink(deferredRevenueNonCurrent, "left", totalNonCurrentLiabilities, "right", 1);
  graph.addLink(deferredTaxLiabilitiesNonCurrent, "left", totalNonCurrentLiabilities, "right", 1);
  graph.addLink(otherNonCurrentLiabilities, "left", totalNonCurrentLiabilities, "right", 1);

  // Stockholders Equity
  /*---------------------------------------------------------------------------------------------------------*/
  
  var totalStockholdersEquity = new GraphNode(language == 'de' ? 'Gesamtes Eigenkapital' : 'Total Stockholders Equity', formatUnitOfAccount(balance.totalStockholdersEquity, unitOfAccount, true), scale * balance.totalStockholdersEquity, Colors.Blue8, Colors.Blue5, fontSizeBig, true);
  graph.addNode(totalStockholdersEquity);

  graph.addLink(totalStockholdersEquity, "left", totalAssets, "right", 1);

  var minorityInterest = new GraphNode(language == 'de' ? 'Minderheitsbeteiligung' : 'Minority Interest', formatUnitOfAccount(balance.minorityInterest, unitOfAccount, true), scale * balance.minorityInterest, Colors.Blue5, Colors.Blue5, fontSizeSmall);
  graph.addNode(minorityInterest);

  
  var accumulatedOtherComprehensiveIncomeLoss = new GraphNode(language == 'de' ? 'Kumulierter sonstiger umfassender Einkommensverlust' : 'Accumulated Other Comprehensive Income Loss', formatUnitOfAccount(balance.accumulatedOtherComprehensiveIncomeLoss, unitOfAccount, true), scale * balance.accumulatedOtherComprehensiveIncomeLoss, Colors.Blue5, Colors.Blue5, fontSizeSmall);
  graph.addNode(accumulatedOtherComprehensiveIncomeLoss);

  var retainedEarnings = new GraphNode(language == 'de' ? 'Gewinnrücklagen' : 'Retained Earnings', formatUnitOfAccount(balance.retainedEarnings, unitOfAccount, true), scale * balance.retainedEarnings, Colors.Blue5, Colors.Blue5, fontSizeSmall);
  graph.addNode(retainedEarnings);

  var othertotalStockholdersEquity = new GraphNode(language == 'de' ? 'Sonstiges Gesamteigenkapital der Aktionäre' : 'Other Total Stockholders Equity', formatUnitOfAccount(balance.othertotalStockholdersEquity, unitOfAccount, true), scale * balance.othertotalStockholdersEquity, Colors.Blue8, Colors.Blue5, fontSizeSmall);
  graph.addNode(othertotalStockholdersEquity);
  
  var commonStock = new GraphNode(language == 'de' ? 'Stammaktien' : 'Common Stock', formatUnitOfAccount(balance.commonStock, unitOfAccount, true), scale * balance.commonStock, Colors.Blue7, Colors.Blue5, fontSizeSmall);
  graph.addNode(commonStock);

  var preferredStock = new GraphNode(language == 'de' ? 'Vorzugsaktien' : 'Preferred Stock', formatUnitOfAccount(balance.preferredStock, unitOfAccount, true), scale * balance.preferredStock, Colors.Blue7, Colors.Blue5, fontSizeSmall);
  graph.addNode(preferredStock);

  graph.addLink(commonStock, "left", totalStockholdersEquity, "right", 1);
  graph.addLink(preferredStock, "left", totalStockholdersEquity, "right", 1);
  graph.addLink(retainedEarnings, "left", totalStockholdersEquity, "right", 1);
  graph.addLink(othertotalStockholdersEquity, "left", totalStockholdersEquity, "right", 1);
  graph.addLink(accumulatedOtherComprehensiveIncomeLoss, "left", totalStockholdersEquity, "right", 1);
  graph.addLink(minorityInterest, "left", totalStockholdersEquity, "right", 1);

  graph.transformGraph(scale, unitOfAccount, language);

  // create grid based graph
  var gridGraph = CreateGridBasedGraph('balance' + balance.date + uniqueId, 400, width, 250, 60, graph);

  return gridGraph.toSVG(svgId); 

}

/* eslint-enable */



