/* eslint-disable */
<script>
import { toUnitOfAccount, formatUnitOfAccount, getInterpolatedColor, formatUnitOfAccountChart, replaceUnderscoreWithDot, getColorValuationRatio } from '../helper';
import { Colors, adjustColor, interpolateColors } from '../colors';
import { sessionManager } from '@/main';

import ValuationThumbnail from '@/components/valuation-thumbnail.vue';

export default {
  name: 'CompanyValuationTable',
  components: {
    ValuationThumbnail,
  },
  props: {
    symbol: {
      type: String,
      required: true,
      default: () => '',
    },
    valuation: {
      type: Object,
      required: true,
      default: () => null,
    },
    marketCap: {
      type: Object,
      required: true,
      default: () => null,
    },
    unitOfAccount: {
      type: String,
      required: true,
      default: () => 'USD',
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    logo: {
      type: Boolean,
      default: () => false
    },
    logoText: {
      type: String,
      default: () => ''
    },
  },

  data() {
    return {
      textAlign: 'right',
      whiteSpace: 'nowrap',
      padding: '6px',
      chartData: {},
      chartOptions: {},
      chartId: '',
      datasetIdKey: '',
      languageStrings: {
        en: {
          title: 'Valuation',
          price: 'Current Price',
          on_sale_for: 'on sale for',
          graph: 'Graph',
          method: 'Method',
          valuation: 'Valuation',
          pv: 'Price / Valuation',
          ten_cap_earnings: 'Ten-Cap Earnings',
          ten_cap_fcf: 'Ten-Cap Free Cashflow',
          ten_cap_oe: 'Ten-Cap Owner Earnings',
          margin_of_safety: 'Margin of Safety Valuation',
          payback_fcf: 'Payback Time Free Cashflow',
          payback_oe: 'Payback Time Owner Earnings',
          graham_number: 'Graham Number',
          net_current_asset: 'Net Current Asset Value Valuation',
          dcf_free_cashflow: 'Discounted Cashflow',
        },
        de: {
          title: 'Bewertung',
          price: 'Aktueller Preis',
          on_sale_for: 'aktueller Preis',
          graph: 'Graph',
          method: 'Bewertungsart',
          valuation: 'Bewertung',
          pv: 'Preis / Bewertung',
          ten_cap_earnings: 'Ten-Cap Gewinn',
          ten_cap_fcf: 'Ten-Cap Freier Cashflow',
          ten_cap_oe: 'Ten-Cap Owner Earnings',
          margin_of_safety: 'Sicherheitsmargen-Bewertung',
          payback_fcf: 'Payback-Time Freier Cashflow',
          payback_oe: 'Payback-Time Owner Earnings',
          graham_number: 'Graham Nummer',
          net_current_asset: 'Aktueller Nettovermögenswert',
          dcf_free_cashflow: 'Diskontierter Cashflow',
        }
      },
      language: '',
      thumbnailWidth: 100,
      thumbnailHeight: 32,
      price: 0,
      minValuation: 0,
      maxValuation: 0,
    };
  },
  created() {
    this.language = sessionManager.read('language');
    this.price = toUnitOfAccount(this.marketCap, this.unitOfAccount);

    // compute the range of the valuations
    this.minValuation = Math.min(
      this.price,
      this.valuation.tenCapEarningsValuation[0].valuation,
      this.valuation.tenCapFreeCashflowValuation[0].valuation,
      this.valuation.tenCapOwnerEarningsValuation[0].valuation,
      this.valuation.mosValuation[0].valuation,
      this.valuation.paybackTimeValuationFreeCashflow[0].valuation,
      this.valuation.paybackTimeValuationOwnerEarnings[0].valuation,
      this.valuation.netCurrentAssetValueValuation[0].valuation,
      this.valuation.grahamNumberValuation[0].valuation
    );
    this.maxValuation = Math.max(
      this.price,
      this.valuation.tenCapEarningsValuation[0].valuation,
      this.valuation.tenCapFreeCashflowValuation[0].valuation,
      this.valuation.tenCapOwnerEarningsValuation[0].valuation,
      this.valuation.mosValuation[0].valuation,
      this.valuation.paybackTimeValuationFreeCashflow[0].valuation,
      this.valuation.paybackTimeValuationOwnerEarnings[0].valuation,
      this.valuation.netCurrentAssetValueValuation[0].valuation,
      this.valuation.grahamNumberValuation[0].valuation
    );
  },
  methods: {
    /*---------------------------------------------------------------------------------------------------------/
    // compute the color for the growth value
    /---------------------------------------------------------------------------------------------------------*/
    getBackgroundColor(value, graph) {

      var price = toUnitOfAccount(this.marketCap, this.unitOfAccount);
      let pastel = true;

      // relative valuation
      if(price > 0)
      {
        var ratio = value / price;
        if(value < 0)
        {
          ratio = 0;
        }
        let veryBad = 0.25;
        let bad = 0.4;
        let slightlyBad = 0.6;
        let neutral = 0.75;
        let slightlyGood = 1;
        let good = 1.25;
        let veryGood = 2;

        let colorNeutral = Colors.White;
        if(graph)
        {
          colorNeutral = Colors.Gray;
          pastel = false;
        }

        return getInterpolatedColor(ratio, veryBad, bad, slightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral, pastel);
      }
      else
      {
        return Colors.White;
      }     
    },
    sanitizeValuationRatio(vr)
    {
      if(vr < 0 || vr >= 1000)
      {
        return '-';
      }
      return vr;
    },
  },
  computed: {
    strings() {
          return this.languageStrings[this.language];
    },
    tenCapEarningsValuationFormatted() {
      return formatUnitOfAccount(this.valuation.tenCapEarningsValuation[0].valuation, this.unitOfAccount, true);
    },
    tenCapEarningsValuationFormatted_color() {
      return this.getBackgroundColor(this.valuation.tenCapEarningsValuation[0].valuation);
    },
    tenCapEarningsValuationRatioFormatted()
    {
      return this.sanitizeValuationRatio(this.valuation.tenCapEarningsValuationRatio.toFixed(1));
    },
    tenCapEarningsValuationRatioFormatted_color() {
      return getColorValuationRatio(this.valuation.tenCapEarningsValuationRatio);
    },
    tenCapFreeCashflowValuationFormatted() {
      return formatUnitOfAccount(this.valuation.tenCapFreeCashflowValuation[0].valuation, this.unitOfAccount, true);
    },
    tenCapFreeCashflowValuationFormatted_color() {
      return this.getBackgroundColor(this.valuation.tenCapFreeCashflowValuation[0].valuation);
    },
    tenCapFreeCashflowValuationRatioFormatted() {
      return this.sanitizeValuationRatio(this.valuation.tenCapFreeCashflowValuationRatio.toFixed(1));
    },
    tenCapFreeCashflowValuationRatioFormatted_color()
    {
      return getColorValuationRatio(this.valuation.tenCapFreeCashflowValuationRatio);
    },
    tenCapOwnerEarningsValuationFormatted() {
      return formatUnitOfAccount(this.valuation.tenCapOwnerEarningsValuation[0].valuation, this.unitOfAccount, true);
    },
    tenCapOwnerEarningsValuationFormatted_color() {
      return this.getBackgroundColor(this.valuation.tenCapOwnerEarningsValuation[0].valuation);
    },
    tenCapOwnerEarningsValuationRatioFormatted()
    {
      return this.sanitizeValuationRatio(this.valuation.tenCapOwnerEarningsValuationRatio.toFixed(1));
    },
    tenCapOwnerEarningsValuationRatioFormatted_color()
    {
      return getColorValuationRatio(this.valuation.tenCapOwnerEarningsValuationRatio);
    },
    discountedEarningsValuationFormatted() {
      return formatUnitOfAccount(this.valuation.mosValuation[0].valuation, this.unitOfAccount, true);
    },
    discountedEarningsValuationFormatted_color() {
      return this.getBackgroundColor(this.valuation.mosValuation[0].valuation);
    },
    discountedEarningsValuationRatioFormatted() {
      return this.sanitizeValuationRatio(this.valuation.mosValuationRatio.toFixed(1));
    },
    discountedEarningsValuationRatioFormatted_color() {
      return getColorValuationRatio(this.valuation.mosValuationRatio);
    },
    paybackTimeValuationFreeCashflowFormatted() {
      return formatUnitOfAccount(this.valuation.paybackTimeValuationFreeCashflow[0].valuation, this.unitOfAccount, true);
    },
    paybackTimeValuationFreeCashflowFormatted_color() {
      return this.getBackgroundColor(this.valuation.paybackTimeValuationFreeCashflow[0].valuation);
    },
    paybackTimeFreeCashflowValuationRatioFormatted()
    {
      return this.sanitizeValuationRatio(this.valuation.paybackTimeValuationRatioFreeCashflow.toFixed(1));
    },
    paybackTimeFreeCashflowValuationRatioFormatted_color()
    {
      return getColorValuationRatio(this.valuation.paybackTimeValuationRatioFreeCashflow);
    },
    paybackTimeValuationOnwerEarningsFormatted() {
      return formatUnitOfAccount(this.valuation.paybackTimeValuationOwnerEarnings[0].valuation, this.unitOfAccount, true);
    },
    paybackTimeValuationOnwerEarningsFormatted_color() {
      return this.getBackgroundColor(this.valuation.paybackTimeValuationOwnerEarnings[0].valuation);
    },
    paybackTimeOnwerEarningsValuationRatioFormatted()
    {
      return this.sanitizeValuationRatio(this.valuation.paybackTimeValuationRatioOwnerEarnings.toFixed(1));
    },
    paybackTimeOnwerEarningsValuationRatioFormatted_color()
    {
      return getColorValuationRatio(this.valuation.paybackTimeValuationRatioOwnerEarnings); 
    },
    netCurrentAssetValueValuationFormatted() {
      return formatUnitOfAccount(this.valuation.netCurrentAssetValueValuation[0].valuation, this.unitOfAccount, true);
    },
    netCurrentAssetValueValuationFormatted_color() {
      return this.getBackgroundColor(this.valuation.netCurrentAssetValueValuation[0].valuation);
    },
    netCurrentAssetValueValuationRatioFormatted()
    {
      return this.sanitizeValuationRatio(this.valuation.netCurrentAssetValueValuationRatio.toFixed(1));
    },
    netCurrentAssetValueValuationRatioFormatted_color()
    {
      return getColorValuationRatio(this.valuation.netCurrentAssetValueValuationRatio);
    },
    grahamNumberValuationFormatted() {
      return formatUnitOfAccount(this.valuation.grahamNumberValuation[0].valuation, this.unitOfAccount, true);
    },
    grahamNumberValuationFormatted_color() {
      return this.getBackgroundColor(this.valuation.grahamNumberValuation[0].valuation);
    },
    grahamNumberValuationRatioFormatted()
    {
      return this.sanitizeValuationRatio(this.valuation.grahamNumberValuationRatio.toFixed(1));
    },
    grahamNumberValuationRatioFormatted_color()
    {
      return getColorValuationRatio(this.valuation.grahamNumberValuationRatio);
    },
    dcfValuationFreeCashflowFormatted() {
      return formatUnitOfAccount(this.valuation.dcfValuationFreeCashflow[0].valuation, this.unitOfAccount, true);
    },
    dcfValuationFreeCashflowFormatted_color() {
      return this.getBackgroundColor(this.valuation.dcfValuationFreeCashflow[0].valuation);
    },
    dcfValuationRatioFreeCashflowFormatted()
    {
      return this.sanitizeValuationRatio(this.valuation.dcfValuationRatioFreeCashflow.toFixed(1));
    },
    dcfValuationRatioFreeCashflowFormatted_color()
    {
      return getColorValuationRatio(this.valuation.dcfValuationRatioFreeCashflow);
    },
    marketCapFormatted() {
      return formatUnitOfAccount(toUnitOfAccount(this.marketCap, this.unitOfAccount), this.unitOfAccount, true);
    }
  },
  beforeMount() {
    this.symbol = replaceUnderscoreWithDot(this.symbol);
  },
}
</script>

<style scoped>

td > svg {
    display: block;
}

.no-wrap {
    white-space: nowrap;
}

</style>


<template>
  <div v-if="valuation">
    <h3 class="darkest font-pj text-v-xl text-bold"><span v-if="logoText!=''">{{ logoText }} </span>{{strings['title']}}</h3>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="darkest font-pj text-v-base pl-0">{{strings['method']}}</th>
              <th class="darkest font-pj text-v-base pl-0">{{strings['graph']}}</th>
              <th class="darkest font-pj text-v-base pl-0">{{strings['valuation']}}</th>
              <th class="darkest font-pj text-v-base pl-0">{{strings['pv']}}</th>              
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['price']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0"><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="price"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ marketCapFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign }">1.0</td>   
            </tr>
            <tr>
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['ten_cap_earnings']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0""><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="valuation.tenCapEarningsValuation[0].valuation"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0" :style="{ 'background-color': tenCapEarningsValuationFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ tenCapEarningsValuationFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'background-color': tenCapEarningsValuationRatioFormatted_color }">{{ tenCapEarningsValuationRatioFormatted }}</td>   
            </tr>
            <tr>
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['ten_cap_fcf']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0"><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="valuation.tenCapFreeCashflowValuation[0].valuation"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0" :style="{ 'background-color': tenCapFreeCashflowValuationFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ tenCapFreeCashflowValuationFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'background-color': tenCapFreeCashflowValuationRatioFormatted_color }">{{ tenCapFreeCashflowValuationRatioFormatted }}</td>
            </tr>
            <tr>              
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['ten_cap_oe']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0""><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="valuation.tenCapOwnerEarningsValuation[0].valuation"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0" :style="{ 'background-color': tenCapOwnerEarningsValuationFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ tenCapOwnerEarningsValuationFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'background-color': tenCapOwnerEarningsValuationRatioFormatted_color }">{{ tenCapOwnerEarningsValuationRatioFormatted }}</td>
            </tr>
            <tr>
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['margin_of_safety']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0""><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="valuation.mosValuation[0].valuation"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0 no-wrap" :style="{ 'background-color': discountedEarningsValuationFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ discountedEarningsValuationFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'background-color': discountedEarningsValuationRatioFormatted_color }">{{ discountedEarningsValuationRatioFormatted }}</td>
            </tr> 
            <tr>
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['payback_fcf']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0""><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="valuation.paybackTimeValuationFreeCashflow[0].valuation"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0" :style="{ 'background-color': paybackTimeValuationFreeCashflowFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ paybackTimeValuationFreeCashflowFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'background-color': paybackTimeFreeCashflowValuationRatioFormatted_color }">{{ paybackTimeFreeCashflowValuationRatioFormatted }}</td>
            </tr>
            <tr>
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['payback_oe']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0""><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="valuation.paybackTimeValuationOwnerEarnings[0].valuation"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0" :style="{ 'background-color': paybackTimeValuationOnwerEarningsFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ paybackTimeValuationOnwerEarningsFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'background-color': paybackTimeOnwerEarningsValuationRatioFormatted_color }">{{ paybackTimeOnwerEarningsValuationRatioFormatted }}</td>
            </tr>       
            <tr>
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['graham_number']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0""><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="valuation.grahamNumberValuation[0].valuation"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0" :style="{ 'background-color': grahamNumberValuationFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ grahamNumberValuationFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'background-color': grahamNumberValuationRatioFormatted_color }">{{ grahamNumberValuationRatioFormatted }}</td>
            </tr>                  
            <tr>
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['net_current_asset']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0""><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="valuation.netCurrentAssetValueValuation[0].valuation"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0" :style="{ 'background-color': netCurrentAssetValueValuationFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ netCurrentAssetValueValuationFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'background-color': netCurrentAssetValueValuationRatioFormatted_color }">{{ netCurrentAssetValueValuationRatioFormatted }}</td>   
            </tr>
            <tr>
              <td class="darkest font-pj text-v-base pl-0 no-wrap">{{strings['dcf_free_cashflow']}}</td>
              <td class="pt-0 pb-0 pl-0 pr-0""><ValuationThumbnail 
                v-bind:price="price"
                v-bind:valuation="valuation.dcfValuationFreeCashflow[0].valuation"
                v-bind:minValue="minValuation"
                v-bind:maxValue="maxValuation"
                v-bind:width="thumbnailWidth" 
                v-bind:height="thumbnailHeight"/>
              </td>
              <td class="pl-3 darkest font-pj text-v-base pl-0" :style="{ 'background-color': dcfValuationFreeCashflowFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ dcfValuationFreeCashflowFormatted }}</td>
              <td class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'background-color': dcfValuationRatioFreeCashflowFormatted_color }">{{ dcfValuationRatioFreeCashflowFormatted }}</td>   
            </tr>

            
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="logo">
      <div class="flex justify-end">
        <div class="flex-none text-v-base font-pj py-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none text-v-base font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
      </div>
    </div>
  </div>
</template>

/* eslint-enable */ 