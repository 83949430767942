
<script>
import { sessionManager } from '@/main';

import {
  logUserEvent,
} from '../helper';

export default {
  name: 'Premium',
  props: {
    email: String,
    code: String,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Valuetion Premium',
          headline: 'Unlock the Full Potential of Value Investing with Valuetion Premium!',
          what_title: 'What is Valuetion Premium?',
          what_text: 'With the premium subscription, embark on a comprehensive journey across the vast realms of the NASDAQ and NYSE, accessing data from every listed company at your fingertips. Not just limited to the S&P 500, delve deeper into extensive, enriched, and enlightened analytical content, helping you make well-informed investment decisions.',
          under_construction_title: 'Valuetion Premium - Under Construction',
          under_construction_text: 'We are putting the final touches to make your premium experience unparalleled and seamless. Meanwhile, do explore our free tier and get a glimpse of the value-packed insights Valuetion brings to your investment journey.',
          pricing_title: 'Premium Pricing',
          pricing_text: 'The pricing details for Valuetion Premium will be unveiled shortly. Stay tuned for more information and get ready to elevate your investing experience!',
          },
        de: {
          title: 'Valuetion Premium',
          headline: 'Entfalte das volle Potenzial des Value Investing mit Valuetion Premium!',
          what_title: 'Was ist Valuetion Premium?',
          what_text: 'Mit dem Premium-Abo erhältst Du umfassenden Zugriff auf alle gelisteten Unternehmen von NASDAQ und NYSE. Erweitere Deine Perspektiven über den S&P 500 hinaus, und erhalte tiefgehende und aufschlussreiche analytische Inhalte, um wohlüberlegte Investitionsentscheidungen zu treffen.',
          under_construction_title: 'Valuetion Premium - im Aufbau',
          under_construction_text: 'Wir geben unser Bestes, um Dein Premium-Erlebnis außergewöhnlich und störungsfrei zu gestalten. Unterdessen, erforsche unsere kostenlose Version und erhalte einen Vorgeschmack auf die wertvollen Einsichten, die Valuetion Dir bietet.',
          pricing_title: 'Preis für Premium',
          pricing_text: 'Die Preisdetails für Valuetion Premium werden bald veröffentlicht. Bleib gespannt und bereite Dich vor, Dein Investitionserlebnis auf ein neues Level zu heben!',
        },
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
    logUserEvent('premium', sessionManager, this.$vuetify.breakpoint.mobile);
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
        <div>
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">{{ strings['what_title'] }}</div>
          <div class="darkest text-v-sm font-pj pb-v-sm">{{ strings['what_text'] }}</div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">{{ strings['under_construction_title'] }}</div>
          <div class="darkest text-v-sm font-pj pb-v-sm">{{ strings['under_construction_text'] }}</div>
          
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">{{ strings['pricing_title'] }}</div>
          <div class="darkest text-v-sm font-pj pb-v-sm">{{ strings['pricing_text'] }}</div>          
        </div>
      </div>
    </div>
  </div>
</template>


