/* eslint-disable */
<script>

  import { sessionManager } from '@/main';
 
  export default {
    name: 'CompanySECFilings',
    props: {
      symbol: {
        type: String,
        required: true,
        default: () => '',
      },
      company: {
        type: Object,
        required: true,
        default: () => null,
      },
      SECFilings: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    data() {
      return {
        search: '',
        languageStrings: {
          en: {
            title: 'SEC Filings',
            date: 'Date',
            type: 'Type',
            link: 'Link',
          },
          de: {
            title: 'SEC Meldungen',
            date: 'Datum',
            type: 'Typ',
            link: 'Link',
          }
        },
        language: '',
        headersLanguages: {
          en: [
            {
              text: 'Date',
              value: 'date',
              width: '100px',
              sortable: true,
            },
            {
              text: 'Type',
              value: 'type',
              width: '80px',
              sortable: true,
              filterable: true,
            },
            {
              text: 'Link',
              value: 'link',
              width: '200px',
              sortable: true,
            },
          ],
          de: [
            {
              text: 'Datum',
              value: 'date',
              width: '100px',
              sortable: true,
            },
            {
              text: 'Typ',
              value: 'type',
              width: '80px',
              sortable: true,
            },
            {
              text: 'Link',
              value: 'link',
              width: '200px',
              sortable: true,
            },
          ],
        }, 
        headers: [],         
      }
    },
    created() {
      this.language = sessionManager.read('language');
      this.headers = this.headersLanguages[this.language];

      // fill the data for the table
      let arr = [];
      this.SECFilings.forEach(function(c){

        // convert the date
        var dateObj = new Date(c.fillingDate);
        var year = dateObj.getFullYear();
        var month = dateObj.getMonth() + 1; // getMonth() returns zero-based month, so add 1
        var day = dateObj.getDate();
        var yearMonthDay = year + "-" + month.toString().padStart(2, '0') + "-" + day.toString().padStart(2, '0');


        arr.push({
          date: yearMonthDay,
          type: c.type,
          link: c.finalLink,
        });
      });
      this.items = arr;
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>
  
  
<template>
  <v-container class="pt-3" fluid>
    <h3>{{strings['title']}} {{ company.companyName }}</h3>

    <v-text-field class="pa-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

    <v-data-table
        width="100%"
        dense
        :headers="headers"
        :items="items"
        :hide-default-footer="false"
        mobile-breakpoint="0"
        :search="search"
        :items-per-page="50"
        :footer-props="{'items-per-page-options':[20, 50, 100, 1000]}"
    >
      <template #item.link="{value}">
        <router-link :to="{ path: '../filing?url=' + value }" target="_blank">{{ value }}</router-link>
        <a :href="value" target="_blank">
          {{ value }}
        </a>
      </template>
    </v-data-table>
  </v-container>
</template>
/* eslint-enable */ 
  