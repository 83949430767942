/* eslint-disable */
<script>

  import { sessionManager } from '@/main';
 
  export default {
    name: 'CompanyFilingDetail',
    props: {
      url: {
        type: String,
        required: true,
        default: () => '',
      },
    },
    data() {
      return {
        search: '',
        languageStrings: {
          en: {
            title: 'Fliling Detail',
            date: 'Date',
          },
          de: {
            title: 'Meldungs Detail',
            date: 'Datum',
          }
        },
        language: '',
        content: null,        
      }
    },
    created() {
      this.language = sessionManager.read('language');
    
      // fetch the filing detail 
      fetch(
        '/api/HttpTriggerTranslateAndSummarize?' +
          new URLSearchParams({
            url: this.url,
          }),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        }
      ).then((response) => response.json()).then(data => {
          this.content = data;
      });       
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>
  
  
<template>
  <v-container class="pt-3" fluid>
    <h3>{{strings['title']}}</h3>
    <p> url {{  url }}</p>
    <p> html {{  html_content }}</p>

    
    <div v-if="content" class="pa-0" v-html="content.en.text"></div>
  </v-container>
</template>
/* eslint-enable */ 
  