/* eslint-disable */
<script>
import html2canvas from 'html2canvas';
import { logUserEvent } from '../helper';
import { sessionManager } from '@/main';

export default {
  name: 'PopupSaveContainer',
  props: {
    companyName: {
      type: String,
      required: true,
      default: () => '',
    },
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    popup : {
      type: Boolean,
      required: true,
      default: () => true,
    },
  },
  data() {
    return {
      showIconsPopup: true,
      showIcons: true,
      showPopup: false,
      showLogo: false,
      popupMaxWidth: '2000px',
      popupMaxHeight: '1600px',
      id: this.generateUniqueId(),
      idPopup: this.generateUniqueId(),
      isMobile: false,
      languageStrings: {
        en: {
          download: 'Download',
          expand: 'Expand',
          close: 'Close',
        },
        de: {
          download: 'Herunterladen',
          expand: 'Vergrössern',
          close: 'Schliessen',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  mounted() {
    this.calculateMaxPopupSize();
    window.addEventListener('resize', this.calculateMaxPopupSize);
    this.isMobile = window.innerWidth < 400;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateMaxPopupSize);
  },
  methods: {
    generateUniqueId() {
      return 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now();
    },
    calculateMaxPopupSize() {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // the aspect ratio of the popup is 2:1 (width:height) and the with is either 90% of the screen width or such that the height is 90% of the screen height
      let width = Math.min(0.9 * screenWidth, screenWidth - 8);
      let height = 0.9 * Math.min(screenHeight, screenHeight - 8);
      let widthHeightRatio = width / height;

      let factor = 1.8;
      if(screenHeight < 400)
      {
        factor = 1.2;
      }
      if(screenHeight < 600)
      {
        factor = 1.5;
      }
      else if(screenHeight < 800)
      {
        factor = 1.7;
      }
      else if(screenHeight < 1000)
      {
        factor = 1.7;
      }

      if((width / height) > factor)
      {
        let maxWidth = (height * factor).toFixed(0);
        this.popupMaxWidth = `${maxWidth}px`;
      }
      else
      {
        this.popupMaxWidth = `${width}px`;
      }
    },
    async onSavePopup()
    { 
      // hide icons
      this.showIconsPopup = false;

      logUserEvent('saveGraphics', sessionManager, this.$vuetify.breakpoint.mobile, this.companyName + ' ' + this.title);

      // wait for the dom to update
      await new Promise(resolve => requestAnimationFrame(resolve));
      // capture the div
      await this.captureDiv(this.idPopup, this.companyName + ' ' + this.title + '.png');
      // change back to normal
      this.showIconsPopup = true;
    },  
    async onSave()
    {
      // hide icons
      this.showIcons = false;
      this.showLogo = true;

      logUserEvent('saveGraphics', sessionManager, this.$vuetify.breakpoint.mobile, this.companyName + ' ' + this.title);

      // wait for the dom to update
      await new Promise(resolve => requestAnimationFrame(resolve));
      // capture the div
      await this.captureDivWithBorder(this.id, this.companyName + ' ' + this.title + '.png', 16);
      // change back to normal
      this.showIcons = true;
      this.showLogo = false;
    },
    // capture an individual div with a border and download it as png
    async captureDivWithBorder(divId, filename, borderWidth) {
      const element = document.getElementById(divId);
      const canvas = await html2canvas(element);
      // Create a new canvas with additional size for the border
      const borderCanvas = document.createElement('canvas');
      borderCanvas.width = canvas.width + 2 * borderWidth;
      borderCanvas.height = canvas.height + 2 * borderWidth;
      const ctx = borderCanvas.getContext('2d');
      // Draw a white rectangle (border)
      ctx.fillStyle = '#FFFFFF'; // White color
      ctx.fillRect(0, 0, borderCanvas.width, borderCanvas.height);
      // Draw the original canvas onto the border canvas, offset by the border width
      ctx.drawImage(canvas, borderWidth, borderWidth);
      // Convert the new canvas to a data URL
      const image = borderCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      this.downloadImage(image, filename);
    },
    // capture an individual div and download it as png
    async captureDiv(divId, filename) {
      const element = document.getElementById(divId);
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      this.downloadImage(image, filename);
    },
    downloadImage(data, filename) {
      const link = document.createElement('a');
      link.href = data;
      link.download = filename;
      link.click();
    },
  },
  
}
</script>

<template>
  <div :id="id">
    <div class="flex">
      <div class="flex-auto">
        <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">
          <span v-if="showLogo">{{ companyName }} </span>{{ title }}
        </h3>
      </div>

      <div class="flex-none">

        <p v-if="showIcons" style="text-align:right;">
          <span v-if="!popup" class="px-v-sm">
            <v-tooltip bottom color="primary" light>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="onSave()">mdi-download</v-icon>
              </template>
              <span class="darkest font-pj text-v-tiny">{{ strings['download'] }}</span>
            </v-tooltip>
          </span>
          <span v-if="popup &&!isMobile" class="px-v-sm">
            <v-tooltip bottom color="primary" light>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="showPopup = true">mdi-arrow-expand</v-icon>
              </template>
              <span class="darkest font-pj text-v-tiny">{{ strings['expand'] }}</span>
            </v-tooltip>
          </span>
        </p>
      </div>
    </div>
    <slot name="content"></slot> 
    <div v-if="showLogo">
      <div class="flex justify-end">
        <div class="flex-none text-v-base font-pj pt-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none text-v-base font-pj pt-v-md pl-v-sm"><b>value</b>tion</div>
      </div>
    </div>
    <v-dialog v-if="popup" v-model="showPopup" :max-width="popupMaxWidth" light>
      <v-card>
        <div :id="idPopup" class="p-v-md">
        <div class="flex">
          <div class="flex-auto">
            <h3 class="darkest font-pj text-v-xl text-bold">
              {{ companyName }} {{ title }} 
            </h3>
          </div>
          <div class="flex-none" v-if="showIconsPopup">
            <p style="text-align:right;">
              <v-tooltip bottom color="primary" light>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="onSavePopup()">mdi-download</v-icon>
                </template>
                <span class="darkest font-pj text-v-tiny">{{ strings['download'] }}</span>
              </v-tooltip>
              <v-tooltip bottom color="primary" light>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="showPopup = false"> mdi-close</v-icon>
                </template>
                <span class="darkest font-pj text-v-tiny">{{ strings['close'] }}</span>
              </v-tooltip>
            </p>
          </div>
        </div>
        <slot name="content_popup"></slot> 
        <div>
          <div class="flex justify-end">
            <div class="flex-none text-v-base font-pj pt-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
            </div>
            <div class="flex-none text-v-base font-pj pt-v-md pl-v-sm"><b>value</b>tion</div>
          </div>
        </div>
      </div>
      </v-card>
    </v-dialog>
  </div>
</template>

/* eslint-enable */ 