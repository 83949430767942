import Vue from 'vue';
import App from '@/app.vue';
import './vuetify.css';
import './tailwind.css';

import router from './router';
import vuetifyPlugin from './plugins/vuetify';
import Vuetify from 'vuetify/lib'
//import './assets/my-style.css';

import { SessionManager } from './SessionManager';




const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#71b224', // '#ff9800', 
        secondary: '#71b224',
        accent: '#71b224',
        error: '#71b224',
      },
    },
  },
})

export const eventBus = new Vue()
export const sessionManager = new SessionManager()

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  created() {
  },
}).$mount('#app');

import VueMeta from 'vue-meta'
Vue.use(VueMeta)


