/* eslint-disable */
<script>
import { toUnitOfAccount, formatUnitOfAccount, getInterpolatedColor, formatUnitOfAccountChart, replaceUnderscoreWithDot, getColorValuationRatio } from '../helper';
import { Colors, adjustColor, interpolateColors } from '../colors';
import { Bar } from 'vue-chartjs/legacy';
import { sessionManager } from '@/main';

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, LineController } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, LineController)


export default {
  name: 'CompanyValuationChart',
  components: {
    Bar,
  },
  props: {
    symbol: {
      type: String,
      required: true,
      default: () => '',
    },
    valuation: {
      type: Object,
      required: true,
      default: () => null,
    },
    marketCap: {
      type: Object,
      required: true,
      default: () => null,
    },
    unitOfAccount: {
      type: String,
      required: true,
      default: () => 'USD',
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 100
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      textAlign: 'center',
      whiteSpace: 'nowrap',
      padding: '6px',
      chartData: {},
      chartOptions: {},
      chartId: '',
      datasetIdKey: '',
      languageStrings: {
        en: {
          title: 'Valuation',
          on_sale_for: 'on sale for',
          method: 'Method',
          valuation: 'Valuation',
          pv: 'Price / Valuation',
          ten_cap_earnings: 'Ten-Cap Earnings',
          ten_cap_fcf: 'Ten-Cap of Free Cashflow',
          ten_cap_oe: 'Ten-Cap of Owner Earnings',
          margin_of_safety: 'Margin of Safety',
          payback_fcf: 'Payback-Time Free Cashflow',
          payback_oe: 'Payback-Time Owner Earnings',
          graham_number: 'Graham Number',
          net_current_asset: 'Net Current Asset Value Valuation',
        },
        de: {
          title: 'Bewertung',
          on_sale_for: 'aktueller Preis',
          method: 'Bewertungsart',
          valuation: 'Bewertung',
          pv: 'Preis / Bewertung',
          ten_cap_earnings: 'Ten-Cap Gewinn',
          ten_cap_fcf: 'Ten-Cap freier Cashflow',
          ten_cap_oe: 'Ten-Cap Owner Earnings',
          margin_of_safety: 'Sicherheitsmargen-Bewertung',
          payback_fcf: 'Payback-Time Freier Cashflow',
          payback_oe: 'Payback-Time Owner Earnings',
          graham_number: 'Graham Nummer',
          net_current_asset: 'Aktueller Nettovermögenswert',
        }
      },
      language: ''
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  methods: {
    /*---------------------------------------------------------------------------------------------------------/
    // compute the color for the growth value
    /---------------------------------------------------------------------------------------------------------*/
    getBackgroundColor(value, graph) {

      var price = toUnitOfAccount(this.marketCap, this.unitOfAccount);
      let pastel = true;

      // relative valuation
      if(price > 0)
      {
        var ratio = value / price;
        if(value < 0)
        {
          ratio = 0;
        }
        let veryBad = 0.25;
        let bad = 0.4;
        let slightlyBad = 0.6;
        let neutral = 0.75;
        let slightlyGood = 1;
        let good = 1.25;
        let veryGood = 2;

        let colorNeutral = Colors.White;
        if(graph)
        {
          colorNeutral = Colors.Gray;
          pastel = false;
        }

        return getInterpolatedColor(ratio, veryBad, bad, slightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral, pastel);
      }
      else
      {
        return Colors.White;
      }     
    },
    sanitizeValuationRatio(vr)
    {
      if(vr < 0 || vr >= 1000)
      {
        return '-';
      }
      return vr;
    },
    /*---------------------------------------------------------------------------------------------------------/
    // Initialize the valuation graph
    /---------------------------------------------------------------------------------------------------------*/
    initializeData() {
    
      if(!this.valuation)
      {
        return;
      }
      
      this.chartData.datasets = [];
      this.chartData.labels = [];

      this.chartOptions = {
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: (value, index, ticks)  => {
                  return formatUnitOfAccountChart(value, this.unitOfAccount, this.language);
              }
            },
            stacked: false, 
          }
        },
        elements: {
          point: {
            radius: 0
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };

      
      this.chartId = 'company-valuation-table-chartID' + this.symbol;
      this.datasetIdKey = 'company-valuation-table-datasetIDKey' + this.symbol;

      var valuations = [];
      var colors = [];
      var line = [];

      line.push(toUnitOfAccount(this.marketCap, this.unitOfAccount));
      line.push(toUnitOfAccount(this.marketCap, this.unitOfAccount));
      line.push(toUnitOfAccount(this.marketCap, this.unitOfAccount));
      line.push(toUnitOfAccount(this.marketCap, this.unitOfAccount));
      line.push(toUnitOfAccount(this.marketCap, this.unitOfAccount));
      line.push(toUnitOfAccount(this.marketCap, this.unitOfAccount));
      line.push(toUnitOfAccount(this.marketCap, this.unitOfAccount));
      line.push(toUnitOfAccount(this.marketCap, this.unitOfAccount));

      valuations.push(this.valuation.tenCapEarningsValuation[0].valuation);
      this.chartData.labels.push(this.strings['ten_cap_earnings']);
      colors.push(this.getBackgroundColor(this.valuation.tenCapEarningsValuation[0].valuation, true));

      valuations.push(this.valuation.tenCapFreeCashflowValuation[0].valuation);
      this.chartData.labels.push(this.strings['ten_cap_fcf']);
      colors.push(this.getBackgroundColor(this.valuation.tenCapFreeCashflowValuation[0].valuation, true));

      valuations.push(this.valuation.tenCapOwnerEarningsValuation[0].valuation);
      this.chartData.labels.push(this.strings['ten_cap_oe']);
      colors.push(this.getBackgroundColor(this.valuation.tenCapOwnerEarningsValuation[0].valuation, true));

      valuations.push(this.valuation.mosValuation[0].valuation);
      this.chartData.labels.push(this.strings['margin_of_safety']);
      colors.push(this.getBackgroundColor(this.valuation.mosValuation[0].valuation, true));

      valuations.push(this.valuation.paybackTimeValuationFreeCashflow[0].valuation);
      this.chartData.labels.push(this.strings['payback_fcf']);
      colors.push(this.getBackgroundColor(this.valuation.paybackTimeValuationFreeCashflow[0].valuation, true));

      valuations.push(this.valuation.paybackTimeValuationOwnerEarnings[0].valuation);
      this.chartData.labels.push(this.strings['payback_oe']);
      colors.push(this.getBackgroundColor(this.valuation.paybackTimeValuationOwnerEarnings[0].valuation, true));

      valuations.push(this.valuation.grahamNumberValuation[0].valuation);
      this.chartData.labels.push(this.strings['graham_number']);
      colors.push(this.getBackgroundColor(this.valuation.grahamNumberValuation[0].valuation, true));

      valuations.push(this.valuation.netCurrentAssetValueValuation[0].valuation);
      this.chartData.labels.push(this.strings['net_current_asset']);
      colors.push(this.getBackgroundColor(this.valuation.netCurrentAssetValueValuation[0].valuation, true));

      this.chartData.datasets = [
        {
          type: 'line',
          data: line,
          backgroundColor: Colors.Black,
          borderWidth: 2,
          borderColor: Colors.Black,
        },
        {
          data: valuations,
          backgroundColor: colors,
        },          
      ];      
    },
  },
  computed: {
    strings() {
          return this.languageStrings[this.language];
    },
    marketCapFormatted() {
      return formatUnitOfAccount(toUnitOfAccount(this.marketCap, this.unitOfAccount), this.unitOfAccount, true);
    }
  },
  beforeMount() {
    this.initializeData();
    this.symbol = replaceUnderscoreWithDot(this.symbol);
  },
}
</script>


<template>
  <div v-if="valuation" class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <h3 class="darkest font-pj text-v-xl text-bold">{{strings['title']}}</h3>
    <p class="darkest font-pj text-v-sm" align="center">{{strings['on_sale_for']}} {{ marketCapFormatted }}</p>
          <Bar v-if="valuation"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
  </div>
</template>

/* eslint-enable */ 