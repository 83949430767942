
<script>
import { sessionManager } from '@/main';

import {
  logUserEvent,
} from '../helper';

export default {
  name: 'FreqAskedQuestions',
  props: {
    email: String,
    code: String,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'FAQ',
          headline: 'Frequently Asked Questions',
        },
        de: {
          title: 'FAQ',
          headline: 'Häufig gestellte Fragen',
        }
      },
      language: '',
      images: {
        invested_de: 
        {
          defaultUrl: '../assets/faq_invested_de.jpg',
          externalUrl: '//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3864706041&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE',
          currentUrl: '../assets/faq_invested_de.jpg',
        },
        rule1_de:
        {
          defaultUrl: '../assets/faq_rule1_de.jpg',
          externalUrl: '//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3864706068&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE',
          currentUrl: '../assets/faq_rule1_de.jpg',
        },
        payback_time_de:
        {
          defaultUrl: '../assets/faq_payback_time_de.jpg',
          externalUrl: '//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3941493264&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE',
          currentUrl: '../assets/faq_payback_time_de.jpg',
        },
        intelligent_investor_de:
        {
          defaultUrl: '../assets/faq_the_intelligent_investor_de.jpg',
          externalUrl: '//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3959723415&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE',
          currentUrl: '../assets/faq_the_intelligent_investor_de.jpg',
        },
        security_analysis_de:
        {
          defaultUrl: '../assets/faq_security_analysis_de.jpg',
          externalUrl: '//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3898799530&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE',
          currentUrl: '../assets/faq_security_analysis_de.jpg',
        },
        one_up_on_wallstreet_de:
        {
          defaultUrl: '../assets/faq_one_up_on_wallstreet_de.jpg',
          externalUrl: '//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3864705657&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE',
          currentUrl: '../assets/faq_one_up_on_wallstreet_de.jpg',
        },
        invested_en:
        {
          defaultUrl: '../assets/faq_invested_de.jpg',
          externalUrl: '//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3864706041&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE',
          currentUrl: '../assets/faq_invested_de.jpg',
        },
      },
    };
  },
  created() {
    this.language = sessionManager.read('language', this.$vuetify.breakpoint.mobile);
    document.title = this.strings['title'] + ' - Valuetion';
    logUserEvent('faq', sessionManager);
  },
  mounted() {
    this.loadExternalImages();
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    loadExternalImages() {
     // Iterate over each key-value pair in the images object
     for (let key in this.images) {
      let image = this.images[key];
      let preloadImage = new Image();

      preloadImage.onload = () => {
        image.currentUrl = image.externalUrl;
      };

      preloadImage.onerror = () => {
        console.error(`Failed to load ${image.externalUrl}`);
        // The currentUrl will remain as the default image URL
      };

      preloadImage.src = image.externalUrl;
    }
  },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>

        <!-- 1st question -->
        <div v-if="language == 'de'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">
            Unsere Philosophie
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Value Investing für alle verständlich und zugänglich zu machen.<br><br>
            Wenn Du in Aktien investierst, verdienst Du es, dies mit Verstand, Gelassenheit und Vertrauen in den Wert hinter jeder Entscheidung zu tun.<br><br>

            In einer Welt, die allzu oft von Kurzfristigkeit geprägt ist, sind wir entschlossen, langfristige, rationale und gelassene Investmententscheidungen zu fördern, 
            indem wir bewährte Value-Investing-Methoden anwenden und Ablenkungen bewusst vermeiden.</div>
        </div>
        <div v-if="language == 'en'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">
            Our Philosophy
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            We want to make value investing understandable and accessible to everyone.<br><br>
            If you invest in stocks, you deserve to do it with understanding, peace of mind, and confidence in your decision.<br><br>

            In a world too often characterized by short-term thinking, we are committed to promoting long-term, rational, 
            and calm investment decisions by applying proven value investing methods and consciously avoiding distractions.</div>
        </div>
        

        <!-- 1st question -->
        <div v-if="language == 'de'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">
            Welche Valueinvesting Methoden sind auf Valuetion verfügbar?
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Für jedes Unternehmen berechnen wir den Wert aufgrund verschiedenen Bewertungsmethoden. Zusätzlich zu den gängigen Kennzahlen wie Kurs-Gewinn-Verhältnis (KGV) und anderen, verwenden wir Auswahlkriteren und Bewertungsmehtoden der folgenden Value-Investing Legenden die in ihren Büchern beschrieben werden:
          </div>

          <div class="darkest text-v-sm font-pj font-bold pt-v-xl">
            Phil Town & Danielle Town
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Phil Town, ein erfolgreicher Investor, Motivationsredner und Bestsellerautor, und seine Tochter Danielle Town, eine Unternehmensanwältin, die zur Investorin und Co-Autorin wurde, informieren mit ihrem beliebten Podcast "InvestED" und ihren umfassenden Büchern über Value-Investing-Strategien von <b>Warren Buffett</b> und <b>Charlie Munger</b>.
          </div>
          <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-3 pt-v-md">
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/Gut-investiert-Gedanken-Emotionen-Kontrolle/dp/3864706041?&linkCode=li2&tag=valuetion-21&linkId=65082727bbabb381ba5738bb42cd2b7e&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" :src="images['invested_de'].currentUrl"></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3864706041" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/Gut-investiert-Gedanken-Emotionen-Kontrolle/dp/3864706041?&linkCode=li2&tag=valuetion-21&linkId=65082727bbabb381ba5738bb42cd2b7e&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_invested_de.jpg"></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3864706041" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>         
                <div class="darkest text-v-sm font-pj px-v-md">
                  <a target="_blank" href="https://www.amazon.de/Gut-investiert-Gedanken-Emotionen-Kontrolle/dp/3864706041/?&_encoding=UTF8&tag=valuetion-21&linkCode=ur2&linkId=1392d0e21a6d4070be6346c6573248e0&camp=1638&creative=6742">"Gut Investiert" von Danielle Town &amp; Phil Town</a>
                </div>
              </div>
            </div>
            <div>
              <div class="flex items-center py-v-md">
                <div>
                  <a href="https://www.amazon.de/Regel-Nummer-Einfach-erfolgreich-anlegen/dp/3864706068?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=QZ9W5C4ET7ZG&keywords=phil+town&qid=1690011140&rnid=186606&s=books&sprefix=phil+town%2Cstripbooks%2C96&sr=1-4&linkCode=li2&tag=valuetion-21&linkId=b562456b2199999e72192ee3a4cf1f57&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_rule1_de.jpg" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3864706068" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <!--
                <div>
                  <a href="https://www.amazon.de/Regel-Nummer-Einfach-erfolgreich-anlegen/dp/3864706068?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=QZ9W5C4ET7ZG&keywords=phil+town&qid=1690011140&rnid=186606&s=books&sprefix=phil+town%2Cstripbooks%2C96&sr=1-4&linkCode=li2&tag=valuetion-21&linkId=b562456b2199999e72192ee3a4cf1f57&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" :src="images['rule1_de'].currentUrl" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3864706068" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div>
                  <a href="https://www.amazon.de/Regel-Nummer-Einfach-erfolgreich-anlegen/dp/3864706068?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=QZ9W5C4ET7ZG&keywords=phil+town&qid=1690011140&rnid=186606&s=books&sprefix=phil+town%2Cstripbooks%2C96&sr=1-4&linkCode=li2&tag=valuetion-21&linkId=b562456b2199999e72192ee3a4cf1f57&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3864706068&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3864706068" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                
                <div  class="darkest text-v-sm font-pj px-v-md">
                  <a targe="_blank" href="https://www.amazon.de/Regel-Nummer-Einfach-erfolgreich-anlegen/dp/3864706068?&linkCode=ll1&tag=valuetion-21&linkId=49867e26d6db39f6cea8ca0228947fbc&language=de_DE&ref_=as_li_ss_tl">"Regel Nummer 1" von Phil Town</a>
                </div>
              </div>
            </div>
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/Jetzt-aber-Erfolgreich-anlegen-Schritten/dp/3941493264?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=QZ9W5C4ET7ZG&keywords=phil+town&qid=1690011140&rnid=186606&s=books&sprefix=phil+town%2Cstripbooks%2C96&sr=1-3&linkCode=li2&tag=valuetion-21&linkId=381fa56e7c9bb0ff3aca46942cd7d2da&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3941493264&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3941493264" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div>
                  <a href="https://www.amazon.de/Jetzt-aber-Erfolgreich-anlegen-Schritten/dp/3941493264?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=QZ9W5C4ET7ZG&keywords=phil+town&qid=1690011140&rnid=186606&s=books&sprefix=phil+town%2Cstripbooks%2C96&sr=1-3&linkCode=li2&tag=valuetion-21&linkId=381fa56e7c9bb0ff3aca46942cd7d2da&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" :src="images['payback_time_de'].currentUrl"></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3941493264" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                
                <div>
                  <a href="https://www.amazon.de/Jetzt-aber-Erfolgreich-anlegen-Schritten/dp/3941493264?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=QZ9W5C4ET7ZG&keywords=phil+town&qid=1690011140&rnid=186606&s=books&sprefix=phil+town%2Cstripbooks%2C96&sr=1-3&linkCode=li2&tag=valuetion-21&linkId=381fa56e7c9bb0ff3aca46942cd7d2da&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_payback_time_de.jpg"></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3941493264" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div class="darkest text-v-sm font-pj px-v-md">
                  <a target="_blank" href="https://www.amazon.de/Jetzt-aber-Erfolgreich-anlegen-Schritten/dp/3941493264?&linkCode=ll1&tag=valuetion-21&linkId=660c47b5fdc16c467715603c5a006e2b&language=de_DE&ref_=as_li_ss_tl">"Jetzt aber! Erfolgreich anlegen in 8 Schritten" von Phil Town</a>
                </div>
              </div>
            </div>
          </div>
          <div class="darkest text-v-sm font-pj font-bold pt-v-xl">
            Benjamin Graham
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Benjamin Graham, oft als der "Vater des Value Investing" bezeichnet, war ein renommierter Ökonom und professioneller Investor, dessen bahnbrechende Arbeit die Grundlage für die moderne Wertpapieranalyse gelegt hat.
          </div>
          <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2 pt-v-md">
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/Intelligent-Investieren-gro%C3%9Fartiger-Investment-Ratgeber-Investing%C2%AB/dp/3959723415?&linkCode=li2&tag=valuetion-21&linkId=2c797d6eacd9eeb4ebb6ca83d3ed5ba2&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3959723415&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3959723415" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div>
                  <a href="https://www.amazon.de/Intelligent-Investieren-gro%C3%9Fartiger-Investment-Ratgeber-Investing%C2%AB/dp/3959723415?&linkCode=li2&tag=valuetion-21&linkId=2c797d6eacd9eeb4ebb6ca83d3ed5ba2&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" :src="images['intelligent_investor_de'].currentUrl"></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3959723415" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/Intelligent-Investieren-gro%C3%9Fartiger-Investment-Ratgeber-Investing%C2%AB/dp/3959723415?&linkCode=li2&tag=valuetion-21&linkId=2c797d6eacd9eeb4ebb6ca83d3ed5ba2&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_the_intelligent_investor_de.jpg"></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3959723415" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div class="darkest text-v-sm font-pj px-v-md">
                  <a target="_blank" href="https://www.amazon.de/Intelligent-Investieren-gro%C3%9Fartiger-Investment-Ratgeber-Investing%C2%AB/dp/3959723415?&linkCode=ll1&tag=valuetion-21&linkId=4f960b20ac359ea2b0caf150d4a18419&language=de_DE&ref_=as_li_ss_tl">"Intelligent Investieren" von Benjamin Graham</a>
                </div>
              </div>
            </div>
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/Die-Geheimnisse-Wertpapieranalyse-%C3%9Cberlegenes-Anlageentscheidung/dp/3898799530?&linkCode=li2&tag=valuetion-21&linkId=04b1c25619d682feb3aeb5eab2e7d31b&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3898799530&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3898799530" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div>
                  <a href="https://www.amazon.de/Die-Geheimnisse-Wertpapieranalyse-%C3%9Cberlegenes-Anlageentscheidung/dp/3898799530?&linkCode=li2&tag=valuetion-21&linkId=04b1c25619d682feb3aeb5eab2e7d31b&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" :src="images['security_analysis_de'].currentUrl" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3898799530" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/Die-Geheimnisse-Wertpapieranalyse-%C3%9Cberlegenes-Anlageentscheidung/dp/3898799530?&linkCode=li2&tag=valuetion-21&linkId=04b1c25619d682feb3aeb5eab2e7d31b&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_security_analysis_de.jpg" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3898799530" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div  class="darkest text-v-sm font-pj px-v-md">
                  <a targe="_blank" href="https://www.amazon.de/Die-Geheimnisse-Wertpapieranalyse-%C3%9Cberlegenes-Anlageentscheidung/dp/3898799530?&linkCode=ll1&tag=valuetion-21&linkId=2603d164188b5e34869c5ebb985ac3ce&language=de_DE&ref_=as_li_ss_tl">"Geheimnisse der Wertpapier Analyse" von Benjamin Graham und David L. Dodd</a>
                </div>
              </div>
            </div>
          </div>
          <div class="darkest text-v-sm font-pj font-bold pt-v-xl">
            Peter Lynch
          </div>
          <div>
            Peter Lynch, ein legendärer Investor und Autor, ist bekannt für sein außergewöhnliches Management des Fidelity Magellan Fonds, wo er seine Philosophie 'investiere in das, was Du kennst' implementierte, um bemerkenswerte Renditen zu erzielen.
          </div>
          <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2 pt-v-md">
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/B%C3%B6rse-einen-Schritt-voraus-Neuauflage/dp/3864705657?&linkCode=li2&tag=valuetion-21&linkId=5e152b69d2979ba3fcdf6f0ccfce3e23&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3864705657&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3864705657" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div>
                  <a href="https://www.amazon.de/B%C3%B6rse-einen-Schritt-voraus-Neuauflage/dp/3864705657?&linkCode=li2&tag=valuetion-21&linkId=5e152b69d2979ba3fcdf6f0ccfce3e23&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=3864705657&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3864705657" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/B%C3%B6rse-einen-Schritt-voraus-Neuauflage/dp/3864705657?&linkCode=li2&tag=valuetion-21&linkId=5e152b69d2979ba3fcdf6f0ccfce3e23&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_one_up_on_wallstreet_de.jpg" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=3864705657" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div class="darkest text-v-sm font-pj px-v-md">
                  <a target="_blank" href="https://www.amazon.de/B%C3%B6rse-einen-Schritt-voraus-Neuauflage/dp/3864705657?&linkCode=ll1&tag=valuetion-21&linkId=603fd7d93d5705200d47b8cae5061793&language=de_DE&ref_=as_li_ss_tl">"Der Börse einen Schritt voraus" von Peter Lynch</a>
                </div>
              </div>
            </div>
          </div>
          <div class="darkest text-v-sm font-pj font-bold pt-v-xl">
            Welche Methode fehlt?
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Wir planen die Liste laufend zu erweitern. Wenn Du eine bestimmte Bewertungsmethode oder ein Auswahlkriterium eines Value Investing Gurus vermisst, bitte schreib uns eine E-Mail an <a href="mailto:info@valuetion.com">info@valuetion.com</a>.
          </div>
        </div>


        <div v-if="language == 'en'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">
              What value investing methods are available on Valuetion?
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            For each company we calculate the value based on different valuation methods. In addition to the common ratios such as price-earnings ratio (P/E) and others, we use selection criteria and valuation methods of the following value investing legends described in their books:
          </div>

          <div class="darkest text-v-sm font-pj font-bold pt-v-xl">
            Phil Town & Danielle Town
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Phil Town, a successful investor, motivational speaker, and bestselling author, along with his daughter Danielle Town, a corporate attorney turned investor and co-author, both educate individuals on value investing strategies of <b>Warren Buffett</b> and <b>Charlie Munger</b> through their popular "InvestED" podcast and comprehensive books.
          </div>
          <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-3 pt-v-md">
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/Invested-Warren-Buffett-Charlie-Emotions/dp/0062672649?&linkCode=li2&tag=valuetion-21&linkId=d09961f10b09295ec4fab2923e121679&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0062672649&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=0062672649" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/Invested-Warren-Buffett-Charlie-Emotions/dp/0062672649?&linkCode=li2&tag=valuetion-21&linkId=d09961f10b09295ec4fab2923e121679&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_invested_en.jpg" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=0062672649" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div class="darkest text-v-sm font-pj px-v-md">
                  <a target="_blank" href="https://www.amazon.de/Invested-Warren-Buffett-Charlie-Emotions/dp/0062672649?&linkCode=ll1&tag=valuetion-21&linkId=21f4e0641b1cfa20f314839d226fcfb3&language=de_DE&ref_=as_li_ss_tl">"Invested" by Danielle Town &amp; Phil Town</a>
                </div>
              </div>
            </div>
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/Rule-Strategy-Successful-Investing-Minutes/dp/1905211317?&linkCode=li2&tag=valuetion-21&linkId=cc7cfe7b550311ff2b3be62f791494df&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1905211317&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=1905211317" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/Rule-Strategy-Successful-Investing-Minutes/dp/1905211317?&linkCode=li2&tag=valuetion-21&linkId=cc7cfe7b550311ff2b3be62f791494df&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_rule1_en.jpg" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=1905211317" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div  class="darkest text-v-sm font-pj px-v-md">
                  <a targe="_blank" href="https://www.amazon.de/Rule-Strategy-Successful-Investing-Minutes/dp/1905211317?&linkCode=ll1&tag=valuetion-21&linkId=0f847a71c9ffd50a9448ce7136dcc80d&language=de_DE&ref_=as_li_ss_tl">"Rule #1" by Phil Town</a>
                </div>
              </div>
            </div>
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/gp/product/B00JYHS23W?&linkCode=li2&tag=valuetion-21&linkId=e3194e37ce0b40d011f7ee39b61344fc&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00JYHS23W&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=B00JYHS23W" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/gp/product/B00JYHS23W?&linkCode=li2&tag=valuetion-21&linkId=e3194e37ce0b40d011f7ee39b61344fc&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_payback_time_en.jpg" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=B00JYHS23W" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div class="darkest text-v-sm font-pj px-v-md">
                  <a target="_blank" href="https://www.amazon.de/gp/product/B00JYHS23W?&linkCode=ll1&tag=valuetion-21&linkId=5240ce752b800dbff96a610458e3836d&language=de_DE&ref_=as_li_ss_tl">"Payback Time" by Phil Town</a>
                </div>
              </div>
            </div>
          </div>
          <div class="darkest text-v-sm font-pj font-bold pt-v-xl">
            Benjamin Graham
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Benjamin Graham, often known as the "father of value investing," was a renowned economist and professional investor whose pioneering work laid the foundation for modern security analysis.
          </div>
          <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2 pt-v-md">
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/Intelligent-Investor-Benjamin-Graham/dp/0060555661?&linkCode=li2&tag=valuetion-21&linkId=3fcdba6433f28173b1ba442d5762ad14&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0060555661&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=0060555661" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/Intelligent-Investor-Benjamin-Graham/dp/0060555661?&linkCode=li2&tag=valuetion-21&linkId=3fcdba6433f28173b1ba442d5762ad14&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_the_intelligent_investor_en.jpg" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=0060555661" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div class="darkest text-v-sm font-pj px-v-md">
                  <a target="_blank" href="https://www.amazon.de/Intelligent-Investor-Benjamin-Graham/dp/0060555661?&linkCode=ll1&tag=valuetion-21&linkId=e15ebe6ae3c3b94127247b05f5b25bb0&language=de_DE&ref_=as_li_ss_tl">"The Intelligent Investor" by Benjamin Graham</a>
                </div>
              </div>
            </div>
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/Security-Analysis-6Th-David-Dodd/dp/0070140650?&linkCode=li2&tag=valuetion-21&linkId=1bd5144b65ad2ceabc92e6f37591bac3&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0070140650&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=0070140650" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/Security-Analysis-6Th-David-Dodd/dp/0070140650?&linkCode=li2&tag=valuetion-21&linkId=1bd5144b65ad2ceabc92e6f37591bac3&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_security_analysis_en.jpg" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=0070140650" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div  class="darkest text-v-sm font-pj px-v-md">
                  <a targe="_blank" href="https://www.amazon.de/Security-Analysis-6Th-David-Dodd/dp/0070140650?&linkCode=ll1&tag=valuetion-21&linkId=a65e47c0650bd23902b32da27e95e978&language=de_DE&ref_=as_li_ss_tl">"Security Analysis" by Benjamin Graham and David L. Dodd</a>
                </div>
              </div>
            </div>
          </div>
          <div class="darkest text-v-sm font-pj font-bold pt-v-xl">
            Peter Lynch
          </div>
          <div>
            Peter Lynch, a legendary investor and author, is renowned for his exceptional management of the Fidelity Magellan Fund, where he implemented his 'invest in what you know' philosophy to achieve remarkable returns.
          </div>
          <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2 pt-v-md">
            <div>
              <div class="flex items-center py-v-md">
                <!--
                <div>
                  <a href="https://www.amazon.de/One-Up-Wall-Street-Already/dp/0743200403?&linkCode=li2&tag=valuetion-21&linkId=dba17f50193b11f03057d9f88f10b71a&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0743200403&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=valuetion-21&language=de_DE" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=0743200403" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                -->
                <div>
                  <a href="https://www.amazon.de/One-Up-Wall-Street-Already/dp/0743200403?&linkCode=li2&tag=valuetion-21&linkId=dba17f50193b11f03057d9f88f10b71a&language=de_DE&ref_=as_li_ss_il" target="_blank"><img class="block" border="0" src="../assets/faq_one_up_on_wallstreet_en.jpg" ></a><img class="block" src="https://ir-de.amazon-adsystem.com/e/ir?t=valuetion-21&language=de_DE&l=li2&o=3&a=0743200403" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
                </div>
                <div class="darkest text-v-sm font-pj px-v-md">
                  <a target="_blank" href="https://www.amazon.de/One-Up-Wall-Street-Already/dp/0743200403?&linkCode=ll1&tag=valuetion-21&linkId=f57df4aa6d24a6a72bb8655256789e34&language=de_DE&ref_=as_li_ss_tl">"One Up On Wall Street" by Peter Lynch</a>
                </div>
              </div>
            </div>
          </div>
          <div class="darkest text-v-sm font-pj font-bold pt-v-xl">
            Which method is missing?
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            We plan to expand the list continuously. If you miss a certain valuation method or selection criterion of a value investing guru, please send us an email to <a href="mailto:info@valuetion.com">info@valuetion.com</a>.
          </div>
        </div>

        <!-- 2nd question -->
        <div v-if="language == 'de'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">
            Woher beziehen wir unsere Daten?
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Die auf dieser Website präsentierten Finanzdaten stammen von einem externen Datenanbieter: Financial Modeling Prep. 
          </div>

        </div>
        <div v-if="language == 'en'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">
            Where do we get our data from?
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            The financial data presented on this website comes from an external data provider: Financial Modeling Prep.
          </div>
        </div>

        <!-- 3rd question -->
        <div v-if="language == 'de'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">
            Wer steckt hinter Valuetion?
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Valuetion ist ein Projekt von Peter Wey, Softwareentwickler und Value Investor aus der Schweiz.
          </div>

        </div>
        <div v-if="language == 'en'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl pb-v-md">
            Who is behind Valuetion?
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Valuetion is a project of Peter Wey, software developer and value investor from Switzerland.
          </div>
        </div>


      </div>
    </div>
  </div>
</template>


