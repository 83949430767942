/* eslint-disable */
<script>

  import { formatUnitOfAccount, 
    toUnitOfAccount, 
    getInterpolatedColor,
  } from '../helper';

  import CompanyValuationMarginOfsafetyInfo from '@/components/company-valuation-margin-of-safety-info.vue'

  import { Colors } from '../colors';
  import { sessionManager } from '@/main';
 
  export default {
    name: 'CompanyValuationMarginOfsafety',
    props: {
      valuation: {
        type: Object,
        required: true,
        default: () => null,
      },
      unitOfAccount: {
        type: String,
        required: true,
        default: () => 'USD',
      },
      marketCap: {
        type: Object,
        required: true,
        default: () => null,
      },
      logo: {
        type: Boolean,
        default: () => false
      },
      logoText: {
        type: String,
        default: () => ''
      },
    },
    components: {
      CompanyValuationMarginOfsafetyInfo,
    },
    data() {
      return {
        valuationPE: 0,
        earningsGrowthRate: 0,
        earningsGrowthRateSet: false,
        discountRate: 15,
        dateYear: '',
        languageStrings: {
          en: {
            title: 'Margin of safety Valuation',
            subtitle: 'From "Invested" by Danielle Town & Phil Town',
            earnings: 'Earnings',   
            growth: 'Estimated Earnings growth rate',
            future_earnings: 'Future earnings',
            future_pe: 'Future P/E value',
            future_market_cap: 'Expected Future Market Cap',
            discount_rate: 'Discount Rate',
            discounted_valuation: 'Discounted Market Cap',
            net_debt: 'Net Debt',
            net_cash: 'Net Cash',
            valuation: 'Valuation',
            valuation_ratio: 'Price / Valuation',
            valuation_adjusted: 'Valuation adjusted for Cash/Debt',
            valuation_safety: 'Valuation with safety Margin',

            explaination_title: 'Short Explaination',
            explaination: 'Imagine you buy a printer that prints 100$ this year and every year a little bit more. How much would you pay for it?',
            explaination2: 'We think about how much the printer will print in 10 years. Then we estimate how much we would pay for that money in the future. Then we think about how much we would pay for that money today.',  

            citation_title: 'How did we get there?',
            citation: 'In their book "Invested - How Warren Buffett and Charlie Munger taught me to master my mind, my emotions, and my money (with a little help from my dad)" '+
              'by Danielle Town and Phil Town the valuation method "Margin of safety Valuation" is described (ISBN 978-3-86470-604-2 Kapiel 8: Charlies viertes Prinzip: Value, Die Sicherheitsmargen-Bewertungsmethode, page 251ff).',
            citation_how: 'How does it work?',  
            citation2: 'The Margin of safety Valuation is based on the earnings and the estimated earnings growth rate. Based on the estimated earnings growth rate, the earnings in 10 years are calculated. ' +
              'Based on the historical P/E ratio, the expected market cap in 10 years is calculated (for P/E > 25, 25 is assumed).',
            citation3: 'Since money in the future is worth less than money today, the expected market cap is discounted back to the present with a discount rate. Then debt is subtracted.',
            citation4: 'The valuation of the company is discounted with a margin of safety of 50%. This means that we would only pay half of the calculated value.',
            close: 'Close',
            help: 'More information'
          },
          de: {
            title: 'Sicherheitsmargen-Bewertung',
            subtitle: 'Aus "Gut Investiert" von Danielle Town & Phil Town',
            earnings: 'Gewinn',
            growth: 'Geschätzters Gewinnwachstum',
            future_earnings: 'Zukünftiger Gewinn',
            future_pe: 'Zukünftiges Kurs/Gewinn Verhältnis (KGV)',
            future_market_cap: 'Zukünftige Markkapitalisierung',
            discount_rate: 'Diskontierungsrate',
            discounted_valuation: 'Discontierte Marktkapitalisierung',
            net_debt: 'Nettoverschuldung',
            net_cash: 'Nettobarvermögen',
            valuation: 'Bewertung',
            valuation_ratio: 'Preis / Bewertung',
            valuation_adjusted: 'Bewertung nach Vermögen/Schulden',
            valuation_safety: 'Bewertung mit Sicherheitsmarge',

            explaination_title: 'Kurz erklärt',
            explaination: 'Stell Dir vor, es Du kaufst einen Drucker der dieses Jahr 100$ druckt und jedes Jahr mit einer Wachstumsrate etwas mehr. Wie viel würdest Du dafür bezahlen?',
            explaination2: 'Wir überlegen uns vieviel der Drucker in 10 Jahren pro Jahr drucken wird. Dann schätzen wir vieviel dafür in der Zukunft bezahlt würde. Dann überlegen wir uns wieviel wir heute für das Geld in 10 Jahren zahlen würden.',

            citation_title: 'Wie kommen wir darauf?',

            citation: 'In ihrem Buch "Gut Investiert - Wie Warren Buffett und Charlie Munger (und Paps*) mich lehrten, meine Gedanken, meine Emotionen und mein Geld unter Kontrolle zu bekommen" '+
              'von Danielle Town und Phil Town ist die Bewertungsmethode "Sicherheitsmargen-Bewertungsmethode" beschrieben (ISBN 978-3-86470-604-2 Kapiel 8: Charlies viertes Prinzip: Value, Die Sicherheitsmargen-Bewertungsmethode, Seite 251ff).',

            citation_how: 'Wie funktioniert die Methode?',
            citation2: 'Die Sicherheitsmargen-Bewertung basiert auf dem Gewinn und dem Gewinnwachstum. Dabei wird, basierend auf einem geschätzen Gewinnwachstum, errechnet, ' + 
            'welchen Gewinn das Unternehmen in 10 Jahren haben könnte. Gemessen am historischen Kurs/Gewinn Verhältnis (KGV) errechnet sich daraus eine erwartete Marktkapitalisierung in 10 Jahren (für KGV > 25 wird 25 angenommen). ',
            citation3: 'Da Geld in der Zukunft weniger wert ist als Geld heute, diskonieren wir die erwartete Marktkapitalisierung mit einer Diskontierungsrate zurück in die Gegenwart. Anschliessend werden Schulden abgezogen.',
            citation4: 'Die so errechnete Bewertung des Unternehmens wird mit einer Sicherheitsmarge von 50% bewertet. Das heisst, dass wir nur die Hälfte des errechneten Wertes bezahlen würden.',
            close: 'Schliessen',
            help: 'Mehr Informationen'
          }
        },
        language: '',
        showPopup: false,
        expanded: true,
        fontSize: '14px',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    methods: {
      onSetEarningsGrowthRate() {
        this.earningsGrowthRateSet = true;
      },
      closePopup() {
        this.showPopup = false;
      },
    },
    mounted() {
      // get the initial values
      this.valuationPE = this.valuation.valuationPE;
      this.earningsGrowthRate = 100.0 * this.valuation.estimatedGrowthRate;
      const d = new Date();
      this.dateYear = d.getFullYear();
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
      futureEarnings() {
        var growth = 100.0 * this.valuation.estimatedGrowthRate;
        if(this.earningsGrowthRateSet)
        {
          growth = this.earningsGrowthRate;
        }
        return this.valuation.earnings * (1.0 + growth/100) ** 10;
      },
      earningsFormatted() {
        return formatUnitOfAccount(this.valuation.earnings, this.unitOfAccount);
      },
      futureEarningsFormatted() {
        return formatUnitOfAccount(this.futureEarnings, this.unitOfAccount);
      },
      futureValue() {
        return this.futureEarnings * this.valuationPE;
      },
      futureValueFormatted() {
        return formatUnitOfAccount(this.futureValue, this.unitOfAccount);
      },
      discountedValuation() {

        // calculate the discount rate
        var currentValue = this.futureValue;
        for(var i = 0; i < 10; i++)
        {
          currentValue = currentValue / (1.0 + this.discountRate/100.0);
        }
        return currentValue;
      },
      discountedValuationFormatted() {
        return formatUnitOfAccount(this.discountedValuation, this.unitOfAccount);
      },
      netDebtFormatted()
      {
        return formatUnitOfAccount(this.valuation.netDebt, this.unitOfAccount);
      },
      netCashFormatted()
      {
        return formatUnitOfAccount(-this.valuation.netDebt, this.unitOfAccount);
      },
      stickerPriceCashDebtFormatted()
      {
        const value = this.discountedValuation - this.valuation.netDebt;
        return formatUnitOfAccount(value, this.unitOfAccount);
      },
      companyValuation()
      {
        const value = this.discountedValuation - this.valuation.netDebt;
        return value / 2.0;
      },
      valuationFormatted() {
        return formatUnitOfAccount(this.companyValuation, this.unitOfAccount);
      },
      companyValuationRatioFormatted()
      {
        if(this.companyValuation < 0)
        {
          return '-';
        }
        var vr = toUnitOfAccount(this.marketCap, this.unitOfAccount) / this.companyValuation;
        return vr.toFixed(1);
      },
      valuationColor()
      {
        const value = this.companyValuation;

        var price = toUnitOfAccount(this.marketCap, this.unitOfAccount);

        // relative valuation
        if(price > 0)
        {
          var ratio = value / price;
          if(value < 0)
          {
            ratio = 0;
          }
          let veryBad = 0.25;
          let bad = 0.4;
          let slightlyBad = 0.6;
          let neutral = 0.75;
          let slightlyGood = 1;
          let good = 1.25;
          let veryGood = 2;

          let colorNeutral = Colors.White;
          return getInterpolatedColor(ratio, veryBad, bad, slightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral);
        }
        else
        {
          return Colors.White;
        }     
      }
    }
  }
</script>
  
  
<template>
  <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <div class="flex">
      <div class="flex-auto">
        <h3 v-if="logo" class="darkest font-pj text-v-xl text-bold">{{ logoText }}</h3>
        <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">{{strings['title']}}</h3>
      </div>
      <div class="flex-none">
        <p style="text-align:right;">
          <span class="px-v-sm">
            <v-tooltip bottom color="primary" light>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="showPopup = true">mdi-help-circle-outline</v-icon>
              </template>
              <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
            </v-tooltip>
          </span>
        </p>
      </div>
    </div>
    <p class="darkest font-pj text-v-tiny py-v-sm" @click="showPopup = true">{{ strings['subtitle'] }}</p>

    <v-simple-table>
      <tbody>
        <tr v-if="expanded">
          <td class="darkest font-pj text-v-base pl-0">{{strings['earnings']}} ({{this.dateYear}})</td>
          <td class="darkest font-pj text-v-base pl-0" align="right">{{ this.earningsFormatted }}</td>
        </tr>
        <tr v-if="expanded">
          <td class="darkest font-pj text-v-base pl-0" colspan="2">{{strings['growth']}} ({{this.dateYear}} - {{this.dateYear-1990}})
            <v-slider max="50" min="-25" v-model="earningsGrowthRate" @change="onSetEarningsGrowthRate">
              <template v-slot:append>
                <v-text-field
                  v-model="earningsGrowthRate"
                  hide-spin-buttons
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  suffix="%"
                  style="width: 58px"
                  v-bind:style="{ 'font-size': fontSize }"
                ></v-text-field>
              </template>
            </v-slider>
          </td>
        </tr>
        <tr v-if="expanded">
          <td class="darkest font-pj text-v-base pl-0">{{strings['future_earnings']}} ({{this.dateYear+10}})</td>
          <td class="darkest font-pj text-v-base pl-0" align="right">{{ this.futureEarningsFormatted }}</td>
        </tr>
        <tr v-if="expanded">
          <td class="darkest font-pj text-v-base pl-0" colspan="2">{{strings['future_pe']}} ({{this.dateYear+10}})
            <v-slider max="50" min="0" v-model="valuationPE">
              <template v-slot:append>
                <v-text-field
                  v-model="valuationPE"
                  hide-spin-buttons
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 50px"
                  v-bind:style="{ 'font-size': fontSize }"
                ></v-text-field>
              </template>
            </v-slider>
          </td>
        </tr>
        <tr v-if="expanded">
          <td class="darkest font-pj text-v-base pl-0">{{strings['future_market_cap']}} ({{this.dateYear+10}})</td>
          <td class="darkest font-pj text-v-base pl-0" align="right">{{ this.futureValueFormatted }}</td>
        </tr>
        <tr v-if="expanded">
          <td class="darkest font-pj text-v-base pl-0" colspan="2">{{strings['discount_rate']}} 
            <v-slider max="50" min="0" v-model="discountRate">
              <template v-slot:append>
                <v-text-field
                  v-model="discountRate"
                  hide-spin-buttons
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  suffix="%"
                  style="width: 50px"
                  v-bind:style="{ 'font-size': fontSize }"
                ></v-text-field>
              </template>
            </v-slider>
          </td>
        </tr>
        <tr v-if="expanded">
          <td class="darkest font-pj text-v-sm pl-0">{{strings['discounted_valuation']}} ({{this.dateYear}})</td>
          <td class="darkest font-pj text-v-sm pl-0" align="right">{{ this.discountedValuationFormatted }}</td>
        </tr>
        <tr v-if="expanded && valuation.netDebt != 0">
          <td class="darkest font-pj text-v-sm pl-0" v-if="valuation.netDebt > 0">{{strings['net_debt']}}</td>
          <td class="darkest font-pj text-v-sm pl-0" v-else>{{strings['net_cash']}}</td>
          <td class="darkest font-pj text-v-sm pl-0" v-if="valuation.netDebt > 0" align="right">-{{ netDebtFormatted }}</td>
          <td class="darkest font-pj text-v-sm pl-0" v-else align="right">{{ netCashFormatted }}</td>
        </tr>
        <tr v-if="expanded">
          <td class="darkest font-pj text-v-sm pl-0">{{strings['valuation_adjusted']}} ({{this.dateYear}})</td>
          <td class="darkest font-pj text-v-sm pl-0" align="right">{{ this.stickerPriceCashDebtFormatted }}</td>
        </tr>
        <tr>
          <td class="darkest font-pj text-v-sm pl-0">{{strings['valuation_safety']}} ({{this.dateYear}})</td>
          <td class="darkest font-pj text-v-sm pl-0" align="right" :style="{ 'background-color': valuationColor }">{{ this.valuationFormatted }}</td>
        </tr>
        <tr>
          <td class="darkest font-pj text-v-sm pl-0">{{strings['valuation_ratio']}}</td>
          <td class="darkest font-pj text-v-sm pl-0" align="right" :style="{ 'background-color': valuationColor}">{{ companyValuationRatioFormatted }}</td>
        </tr> 
      </tbody>
    </v-simple-table>
    <div v-if="logo">
      <div class="flex justify-end">
        <div class="flex-none text-v-base font-pj py-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none text-v-base font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
      </div>
    </div>
    <CompanyValuationMarginOfsafetyInfo :onClose="closePopup" :showPopup="showPopup"/>
  </div>
</template>
/* eslint-enable */ 
  