<script>
/* eslint-disable */
import { formatUnitOfAccount } from '../helper';
import GraphThumbnail from '@/components/graph-thumbnail.vue';
import { sessionManager } from '@/main';
import { getYearAndQuarter, getYear
 } from '../helper';
import { getTranslation } from '../translations';

export default {
  name: 'CompanyFinancialsCashflow',
  components: {
    GraphThumbnail,
  },
  props: {
    cashflow: {
      type: Object,
      required: true,
      default: () => null,
    },
    unitOfAccount: {
      type: String,
      required: true,
      default: () => 'USD',
    },
    reportedCurrency: {
      type: String,
      required: true,
      default: () => 'USD',
    },
  },
  data() {
    return {
      currentCurrency: null,
      headers: [],
      items: [],
      languageStrings: {
        en: {
          title: 'Cashflow Statement',
          ttm: 'TTM',
        },
        de: {
          title: 'Kapitalflussrechnung',
          ttm: 'letzte 12 Monate',
        }
      },
      language: '',
      maxLength: 15,
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    row_classes(item) {
      let classes = 'darkest font-pj text-v-sm pl-0';
      if (item.bold) {
        return classes + ' bg-light-yellow';
      }
      if (item.strong) {
        return classes + ' bg-very-light-yellow';
      }
      return classes;
    },
    processElement(element, propertyName) {
      for(let i = 0; i < 4; i++)
      {
        if(this.cashflow.cashflowQuarter.length > i)
        {
          let year = getYearAndQuarter(this.cashflow.cashflowQuarter[i].date);
          element[year.toString()] = formatUnitOfAccount(this.cashflow.cashflowQuarter[i][propertyName], this.unitOfAccount);
        }
      }
      if(this.cashflow.hasTTM) 
      {
        element['ttm'] = formatUnitOfAccount(this.cashflow.cashflowTTM[propertyName], this.unitOfAccount);
      }  
      for (let j = 0; j < this.cashflow.cashflowAnnual.length && j < this.maxLength; j += 1) { 
        let year = getYear(this.cashflow.cashflowAnnual[j].date);
        element[year.toString()] = formatUnitOfAccount(this.cashflow.cashflowAnnual[j][propertyName], this.unitOfAccount);
        element['graph'].push(this.cashflow.cashflowAnnual[j][propertyName]);
      }
    },
    addLine(propertyName, bold, strong)
    {
      let element = {};
      element.Line = getTranslation(propertyName, this.language);
      element.bold = bold;
      element.strong = strong;
      element['graph'] = [];
      element['ttm'] = '';
      this.processElement(element, propertyName);
      this.items.push(element);
    },
    getDate(obj) {
      return obj.date !== undefined ? obj.date : obj.period;
    },
    createHeaders()
    {
      var hasTTM = this.cashflow.hasTTM;
      const cashflowAnnual = this.cashflow.cashflowAnnual;
      const cashflowQuarter = this.cashflow.cashflowQuarter;

      /* header */
      this.headers.push({
        text: '',
        sortable: false,
        value: 'Line',
        width: '180px',
      });

      // first we show the last quarters
      for(let i = 0; i < 4; i++)
      {
        if(cashflowQuarter.length > i)
        {
          let quarter = getYearAndQuarter(this.getDate(cashflowQuarter[i]));
          this.headers.push({
            text: quarter,
            sortable: false,
            value: quarter.toString(),
            width: '120px',
            align: 'right',
          });
        }
      }

      // then we show the trailing twelve months
      if(hasTTM)
      {
        this.headers.push({
          text: this.strings['ttm'],
          sortable: false,
          value: 'ttm',
          width: '130px',
          align: 'right',
        });
      }
      
      // then we show the graph
      this.headers.push({      
        text: '',
        sortable: false,
        value: 'graph',
        width: '100px',
        align: 'right',
      });

      // then we show all the years
      for (let i = 0; i < cashflowAnnual.length && i < this.maxLength; i += 1) {
        let year = getYear(this.getDate(cashflowAnnual[i]));
        this.headers.push({
          text: year,
          sortable: false,
          value: year.toString(),
          width: '120px',
          align: 'right',
        });
      }
    },
    updateFinancials()
    {
      if(this.cashflow.type == "FMP")
      {
        this.updateFinancialsFMP();
      }
      else
      {
        this.updateFinancialsFH();
      }
    },
    updateFinancialsFH() {
      this.headers = [];
      this.items = [];
      this.currentCurrency = this.unitOfAccount
      this.createHeaders();

      // now we add the lines
      this.addLine('netIncomeStartingLine', false, true);
      this.addLine('depreciationAmortization', false, false);
      this.addLine('deferredTaxesInvestmentTaxCredit', false, false);
      this.addLine('otherFundsNonCashItems', false, false);
      this.addLine('stockBasedCompensation', false, false);
      this.addLine('changesinWorkingCapital', false, false);
      this.addLine('cashNet', false, false);
      this.addLine('netOperatingCashFlow', true, true);

      this.addLine('capex', false, false);
      this.addLine('otherInvestingCashFlowItemsTotal', false, false);
      this.addLine('netInvestingCashFlow', true, true);

      this.addLine('cashDividendsPaid', false, false);
      this.addLine('issuanceReductionCapitalStock', false, false);
      this.addLine('issuanceReductionDebtNet', false, false);
      this.addLine('otherFundsFinancingItems', false, false);
      this.addLine('netCashFinancingActivities', true, true);

      this.addLine('foreignExchangeEffects', false, false);
      this.addLine('changeinCash', true, true);
    },
    updateFinancialsFMP() {
      this.headers = [];
      this.items = [];
      this.currentCurrency = this.unitOfAccount

      this.createHeaders();

      // now we add the lines
      this.addLine('netIncome', false, true);
      this.addLine('depreciationAndAmortization', false, false);
      this.addLine('deferredIncomeTax', false, false);
      this.addLine('stockBasedCompensation', false, false);
      this.addLine('changeInWorkingCapital', false, false);
      this.addLine('otherNonCashItems', false, false);
      this.addLine('netCashProvidedByOperatingActivities', true, true);

      this.addLine('investmentsInPropertyPlantAndEquipment', false, false);
      this.addLine('salesMaturitiesOfInvestments', false, false);
      this.addLine('purchasesOfInvestments', false, false);
      this.addLine('acquisitionsNet', false, false);
      this.addLine('otherInvestingActivites', false, false);
      this.addLine('netCashUsedForInvestingActivites', true, true);

      this.addLine('debtRepayment', false, false);
      this.addLine('commonStockIssued', false, false);
      this.addLine('commonStockRepurchased', false, false);
      this.addLine('dividendsPaid', false, false);
      this.addLine('otherFinancingActivites', false, false);
      this.addLine('netCashUsedProvidedByFinancingActivities', true, true);

      this.addLine('netChangeInCash', true, true);
      this.addLine('cashAtBeginningOfPeriod', false, false);
      this.addLine('cashAtEndOfPeriod', false, false);
      this.addLine('operatingCashFlow', true, true);
      this.addLine('capitalExpenditure', false, true);
      this.addLine('freeCashFlow', true, true);
    },
  },
  watch: {
    unitOfAccount: {
      handler(val) {
        this.updateFinancials();
      },
    },
    annual: {
      handler(val) {
        this.updateFinancials();
      },
    },
  },
  beforeMount() {
    this.updateFinancials();
  },
};
</script>
/* eslint-enable */ 

<template>
  <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <h3 class="darkest font-pj text-v-xl text-bold pt-v-sm pb-v-md">{{strings['title']}}</h3> 

    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :items-per-page="50"
      :item-class="row_classes"
      :hide-default-footer="true"
      mobile-breakpoint="0"
    >
      <template v-slot:item.graph="{ item }">
        <GraphThumbnail v-bind:data="item.graph" v-bind:width="100" v-bind:height="20"/>
      </template>
    </v-data-table>
  </div>
</template>

