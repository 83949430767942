<script>
/* eslint-disable */
import { formatMillions, formatUnitOfAccount } from '../helper';
import GraphThumbnail from '@/components/graph-thumbnail.vue';
import { sessionManager } from '@/main';
import { getYearAndQuarter, getYear
 } from '../helper';
import { getTranslation } from '../translations';

export default {
  name: 'CompanyFinancialsBalance',
  components: {
    GraphThumbnail,
  },
  props: {
    balance: {
      type: Object,
      required: true,
      default: () => null,
    },
    unitOfAccount: {
      type: String,
      required: true,
      default: () => null,
    },
    reportedCurrency: {
      type: String,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      currentCurrency: null,
      reportedCurrency: null,
      headers: [],
      items: [],
      languageStrings: {
        en: {
          title: 'Balance Statement', 
        },
        de: {
          title: 'Bilanz',
        }
      },
      language: '',
      maxLength: 15,
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    row_classes(item) {
      let classes = 'darkest font-pj text-v-sm pl-0';
      if (item.bold) {
        return classes + ' bg-light-yellow';
      }
      if (item.strong) {
        return classes + ' bg-very-light-yellow';
      }
      return classes;
    },
    processElement(element, propertyName) {
      for(let i = 0; i < 4; i++)
      {
        if(this.balance.balanceQuarter.length > i)
        {
          let quarter = getYearAndQuarter(this.getDate(this.balance.balanceQuarter[i]));
          element[quarter.toString()] = formatUnitOfAccount(this.balance.balanceQuarter[i][propertyName], this.unitOfAccount);
        }
      }
      for (let j = 0; j < this.balance.balanceAnnual.length && j < this.maxLength; j += 1) { 
        let year = getYear(this.getDate(this.balance.balanceAnnual[j]));
        element[year.toString()] = formatUnitOfAccount(this.balance.balanceAnnual[j][propertyName], this.unitOfAccount);
        element['graph'].push(this.balance.balanceAnnual[j][propertyName]);
      }
    },
    checkIfAllEntriesAreZero(propertyName)
    {
      const balanceQuarter = this.balance.balanceQuarter;
      const balanceAnnual = this.balance.balanceAnnual;
      for(let i = 0; i < 4; i++)
      {
        if(balanceQuarter.length > i)
        {
          if(balanceQuarter[i][propertyName] != 0)
          {
            return false;
          }
        }
      }
      for (let j = 0; j < balanceAnnual.length && j < this.maxLength; j += 1) { 
        if(balanceAnnual[j][propertyName] != 0)
        {
          return false;
        }
      }
      return true;
    },
    addLine(propertyName, bold, strong)
    {
      if(this.checkIfAllEntriesAreZero(propertyName))
      {
        return;
      }
      let element = {};
      element.Line = getTranslation(propertyName, this.language);
      element.bold = bold;
      element.strong = strong;
      element['graph'] = [];
      this.processElement(element, propertyName);
      this.items.push(element);
    },
    getDate(obj) {
      return obj.date !== undefined ? obj.date : obj.period;
    },
    createHeaders()
    {
      var maxLength = 15;
      const balanceQuarter = this.balance.balanceQuarter;
      const balanceAnnual = this.balance.balanceAnnual;
      
      /* header */
      this.headers.push({
        text: '',
        sortable: false,
        value: 'Line',
        width: '180px',
      });

      var offset = 0;
      // first we show the last quarters
      for(let i = 0; i < 4; i++)
      {
        if(balanceQuarter.length > i)
        {
          let quarter = getYearAndQuarter(this.getDate(balanceQuarter[i]));
          this.headers.push({
            text: quarter,
            sortable: false,
            value: quarter.toString(),
            width: '120px',
            align: 'right',
          });
          offset += 1;
        }
      }
      
      // then we show the graph
      this.headers.push({      
        text: '',
        sortable: false,
        value: 'graph',
        width: '100px',
        align: 'right',
      });

      // then we show all the years
      for (let i = 0; i < balanceAnnual.length && i < maxLength; i += 1) {
        let year = getYear(this.getDate(balanceAnnual[i]));
        this.headers.push({
          text: year,
          sortable: false,
          value: year.toString(),
          width: '120px',
          align: 'right',
        });
      }
    },
    updateFinancials()
    {
      this.currentCurrency = this.unitOfAccount;
      const balance = this.balance.balanceQuarter[0];

      // check for the type
      if(this.balance.type == "FMP")
      {
        this.updateFinancialsFMP();
      }
      else
      {
        this.updateFinancialsFH();
      }
    },
    updateFinancialsFH() 
    {
      this.headers = [];
      this.items = [];
      this.createHeaders();

      // current assets
      this.addLine("cashDueFromBanks", false, false);
      this.addLine("bankInvestments", false, false);
      this.addLine("netLoans", true, false);
      this.addLine("cash", false, false);
      this.addLine("cashEquivalents", false, false);
      this.addLine("shortTermInvestments", false, false);
      this.addLine("accountsReceivables", true, false);
      this.addLine("otherReceivables", false, false);
      this.addLine("totalReceivables", true, false);
      this.addLine("insuranceReceivables", false, false);
      this.addLine("deferredPolicyAcquisitionCosts", false, false);
      this.addLine("inventory", false, false);
      this.addLine("otherCurrentAssets", false, false);
      this.addLine("currentAssets", true, false);

      // non-current assets
      this.addLine("propertyPlantEquipment", false, false);
      this.addLine("intangiblesAssets", false, false);
      this.addLine("goodwill", false, false);
      this.addLine("longTermInvestments", false, false);
      this.addLine("otherLongTermAssets", false, false);
      this.addLine("noteReceivableLongTerm", false, false);
      this.addLine("otherAssets", false, false);
      this.addLine("totalAssets", true, false);

      // current liabilities
      this.addLine("insurancePolicyLiabilities", false, false);
      this.addLine("accountsPayable", false, false);
      this.addLine("accruedLiability", false, false);
      this.addLine("shortTermDebt", false, false);
      this.addLine("currentPortionLongTermDebt", false, false);
      this.addLine("otherCurrentliabilities", false, false);
      this.addLine("totalDeposits", false, false);
      this.addLine("otherInterestBearingLiabilities", false, false);
      this.addLine("currentLiabilities", true, false);

      // non-current liabilities
      this.addLine("longTermDebt", false, false);
      this.addLine("totalDebt", false, false);
      this.addLine("netDebt", true, false);
      this.addLine("deferredIncomeTax", false, false);
      this.addLine("otherLiabilities", false, false);
      this.addLine("minorityInterest", false, false);
      this.addLine("accumulatedDepreciation", false, false);
      this.addLine("totalLiabilities", true, false);

      // equity
      this.addLine("commonStock", false, false);
      this.addLine("preferredStock", false, false);
      this.addLine("treasuryStock", false, false);
      this.addLine("retainedEarnings", false, false);
      this.addLine("additionalPaidInCapital", false, false);
      this.addLine("unrealizedProfitLossSecurity", false, false);
      this.addLine("otherEquity", false, false);
      this.addLine("totalEquity", true, false);
      this.addLine("liabilitiesShareholdersEquity", true, false);
    },
    updateFinancialsFMP() 
    {
      this.headers = [];
      this.items = [];
      this.createHeaders();

      this.addLine("cashAndCashEquivalents", false, false);
      this.addLine("shortTermInvestments", false, false);
      this.addLine("cashAndShortTermInvestments", true, false);
      this.addLine("netReceivables", false, false);
      this.addLine("inventory", false, false);
      this.addLine("otherCurrentAssets", false, false);
      this.addLine("totalCurrentAssets", true, false);
      this.addLine("propertyPlantEquipmentNet", false, false);
      this.addLine("goodwill", false, false);
      this.addLine("intangibleAssets", false, false);
      this.addLine("goodwillAndIntangibleAssets", false, false);
      this.addLine("longTermInvestments", false, false);
      this.addLine("taxAssets", false, false);
      this.addLine("otherNonCurrentAssets", false, false);
      this.addLine("totalNonCurrentAssets", true, false);
      this.addLine("otherAssets", false, false);
      this.addLine("totalAssets", true, true);
      this.addLine("accountPayables", false, false);
      this.addLine("shortTermDebt", false, false);
      this.addLine("taxPayables", false, false);
      this.addLine("deferredRevenue", false, false);
      this.addLine("otherCurrentLiabilities", false, false);
      this.addLine("totalCurrentLiabilities", true, false);
      this.addLine("longTermDebt", false, false);
      this.addLine("deferredRevenueNonCurrent", false, false);
      this.addLine("deferredTaxLiabilitiesNonCurrent", false, false);
      this.addLine("otherNonCurrentLiabilities", false, false);
      this.addLine("totalNonCurrentLiabilities", true, false);
      this.addLine("otherLiabilities", false, false);
      this.addLine("totalLiabilities", true, false);
      this.addLine("retainedEarnings", false, false);
      this.addLine("accumulatedOtherComprehensiveIncomeLoss", false, false);
      this.addLine("othertotalStockholdersEquity", false, false);
      this.addLine("commonStock", false, false);
      this.addLine("preferredStock", false, false);
      this.addLine("totalStockholdersEquity", true, false);
      this.addLine("totalLiabilitiesAndStockholdersEquity", true, false);
      this.addLine("totalDebt", false, false);
      this.addLine("netDebt", true, false);
    },
  },
  watch: {
    unitOfAccount: {
      handler(val) {
        this.updateFinancials();
      },
    },
    annual: {
      handler(val) {
        this.updateFinancials();
      },
    },
  },
  beforeMount() {
    this.updateFinancials();
  },
};
</script>

<template>
  <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <h3 class="darkest font-pj text-v-xl text-bold pt-v-sm pb-v-md">{{strings['title']}}</h3> 
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :items-per-page="50"
      :item-class="row_classes"
      :hide-default-footer="true"
      mobile-breakpoint="0"
    >
      <template v-slot:item.graph="{ item }">
        <GraphThumbnail v-bind:data="item.graph" v-bind:width="100" v-bind:height="20"/>
      </template>
    </v-data-table>
  </div>
</template>
