
<script>
import { sessionManager } from '@/main';

export default {
  name: 'UserAccountVerification',
  props: {
    email: String,
    code: String,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'You can get started!',
          headline: 'Thank you for verifying your Valuetion account!',
          text: 'You can now log in and get started!',
          text_contact: 'If you have any questions or suggestions, please feel free to contact us at ',
          text_contact_2: '.',
        },
        de: {
          title: 'Du kannst loslegen!',
          headline: 'Vielen Dank für die Bestätigung Deines Valuetion Kontos!',
          text: 'Du kannst Dich jetzt einloggen und loslegen!',
          text_contact: 'Falls Du Fragen oder Anregungen hast, kannst Du uns jederzeit unter ',
          text_contact_2: ' kontaktieren.',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
    this.fetchData();
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    async fetchData () {
      try {
        const url = '/api/HttpTriggerVerifyUserAccount';
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.email, 
            verificationCode: this.code,
          }),
        }).then((response) => {

          if (response.status === 200) {
            // Redirect to the login page after successful verification
            this.$router.push({
              name: 'login',
              params: {
                email: this.email,
                welcome: true,
              },
            });         
          } else {
            // report error
            this.$router.push({
              name: 'error',
            });
          }
        });            
      }
      catch (error) {
        console.log(error);
      }
    },
  }, 
}
</script>

<style>

  .font_white_big {
    color: white;
    font-size: 2.5rem;
  }

</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
          <p class="darkest text-v-base font-pj">{{ strings['text'] }}</p>
          <p class="darkest text-v-base font-pj">{{ strings['text_thank_you'] }}</p>
      </div>      
    </div>
  </div>
</template>


