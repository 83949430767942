
<script>
import { sessionManager } from '@/main';
import { eventBus } from '@/main'

export default {
  name: 'UserLogin',
  props: {
    email: {
      type: String,
      required: false,
      default: () => '',
    },
    welcome: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      password: '',
      valid: false,
      passwordRulesEN: [
        v => !!v || 'Password is required',
        v => v.length >= 6 || 'Password must be at least 6 characters.'
      ],
      passwordRulesDE: [
        v => !!v || 'Passwort ist erforderlich',
        v => v.length >= 6 || 'Password muss mindestens 6 Zeichen lang sein.'
      ],
      emailRulesEN: [
        v => !!v || 'Email is required',
        v => /.+@.+/.test(v) || 'Email must be valid.'
      ],
      emailRulesDE: [
        v => !!v || 'Email ist erforderlich',
        v => /.+@.+/.test(v) || 'Email muss gültig sein.'
      ],
      languageStrings: {
        en: {
          title: 'Login',
          headline: 'Welcome back!',
          email: 'Email',
          password: 'Password',
          submit: 'Login',
          welcome: 'Welcome',
          login_success: 'You are now logged in.',
          start_searching: 'Lets Go!',
          register: 'No Account? Join the waitlist!',
          forgot_password: 'Forgot Password?',
          welcome_headline: 'Thank you for verifying your Valuetion account!',
          welcome_text: 'You can now log in and get started!',
          welcome_text_contact: 'If you have any questions or suggestions, please feel free to contact us at ',
          welcome_text_contact_2: '.',
          login_failed_password: 'Login failed. Please check your email and password.',
          login_failed: 'Login failed. Please try again later.',
          login_success: 'You are now logged in.',
          login_accepts_cookies: 'By logging in you accept our use of cookies.',
        },
        de: {
          title: 'Anmelden',
          headline: 'Willkommen zurück!',
          email: 'Email',
          password: 'Passwort',
          submit: 'Anmelden',
          welcome: 'Willkommen',
          login_success: 'Du bist nun angemeldet.',
          start_searching: 'Los Gehts!',
          register: 'Kein Konto? Auf die Warteliste setzen!',
          forgot_password: 'Passwort vergessen?',
          welcome_headline: 'Vielen Dank für die Bestätigung Deines Valuetion Kontos!',
          welcome_text: 'Du kannst Dich jetzt einloggen und loslegen!',
          welcome_text_contact: 'Falls Du Fragen oder Anregungen hast, kannst Du uns jederzeit unter ',
          welcome_text_contact_2: ' kontaktieren.',
          login_failed_password: 'Login fehlgeschlagen. Bitte überprüfe Deine E-Mail-Adresse und Dein Passwort.',
          login_failed: 'Login fehlgeschlagen. Bitte versuche es später erneut.',
          login_success: 'Du bist nun angemeldet.',
          login_accepts_cookies: 'Durch das Anmelden akzeptierst Du unsere Verwendung von Cookies.',
        }
      },
      showPopup: false,
      username: '',
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
    if (this.language === 'en') {
      this.emailRules = this.emailRulesEN;
      this.passwordRules = this.passwordRulesEN;
    } else {
      this.emailRules = this.emailRulesDE;
      this.passwordRules = this.passwordRulesDE;
    }
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
    loginAcceptsCookies() {
      return !sessionManager.getCookiesAllowed();
    },
  },
  methods: {
    goToRegistration() {
      this.$router.push('/waitlist');
    },
    goToForgotPassword() {
      this.$router.push('/requestResetPassword');
    },
    async submitForm() {
      // send email and password to the API to login the user
      const url = '/api/HttpTriggerLogin';
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
           email: this.email, 
           password: this.password 
        }),
      }).then((response, reject) => {
        if (response.status === 200) {

          // get the jwt token from the response header
          const token = response.headers.get('token');

          // read user data from the response json          
          response.json().then((data) => {

            const settings = data.settings;
            this.username = data.name + ' ' + data.surname;

            // write the user data to the session
            sessionManager.setCookiesAllowed(true);
            sessionManager.write('email', data.email);
            sessionManager.write('id', data.id);
            sessionManager.write('name', data.name);
            sessionManager.write('surname', data.surname);
            sessionManager.write('language', settings.language);
            sessionManager.write('unitOfAccount', settings.unitOfAccount);
            sessionManager.write('sendWatchlistUpdates', settings.sendWatchlistUpdates);
            sessionManager.write('onboardingStep', data.onboardingStep);
            sessionManager.write('onboardingDone', data.onboardingDone);
            sessionManager.write('role', data.role);
            sessionManager.write('userCreated', data.created);
            sessionManager.write('token', token);
            sessionManager.write('useFinnhub', settings.finnhubPremium);

            // emit an event to the parent component to update the login status
            eventBus.$emit('login');

            if(!sessionManager.isOnboardingDone())
            {
              // redirect to the onboarding page, since the user has not completed the onboarding yet
              this.$router.push('/onboarding');
            }
            else
            {
              // redirect to the dashboard page
              this.$router.push('/dashboard');
            }            
          });
        } else if (response.status === 401) {
          // show an error message if the login failed
          eventBus.$emit('error', this.strings['login_failed_password']);
        } else if (response.status === 500) {
          // show an error message if the login failed
          eventBus.$emit('error', this.strings['login_failed']);
        } else {
          // show an error message if the login failed
          eventBus.$emit('error', this.strings['login_failed']);
        }
      });        
    },
  }, 
}
</script>

<style scoped>


  .v-list-item__content {
    font-size: 15px;
    font-family: Plus Jakarta Sans;
  }
  .v-input {
      font-size: 15x;
      font-family: Plus Jakarta Sans;
      border: 0px !important;
      border: none; 
      border-radius: 8px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <div v-if="welcome">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['welcome_headline']}}</h3>
          <p class="darkest text-v-sm font-pj">{{ strings['welcome_text'] }}</p>
          <p class="darkest text-v-sm font-pj">{{ strings['welcome_text_thank_you'] }}</p>
          <p class="darkest text-v-sm font-pj">{{ strings['welcome_text_contact'] }} <a href="mailto:info@valuetion.com">info@valuetion.com</a>{{ strings['welcome_text_contact_2'] }}</p>
          
          
        </div>
        <div v-else>
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
        </div>
        <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
          <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['email']}}</div>
          <div>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              outlined
            />
            <div class="align_right pb-v-md">
              <a class="text-v-sm font-pj" @click="goToRegistration">
                {{strings['register']}} 
              </a>
            </div> 
          </div>
              
          <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['password']}}</div>
          <div>
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              outlined
              type="password"
            />
            <div class="align_right pb-v-md">
              <a class="text-v-sm font-pj" @click="goToForgotPassword">
                {{strings['forgot_password']}} 
              </a>
            </div> 
          </div>
          <button type="submit" :disabled="!valid" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light">{{ strings['submit'] }}</button>
        </v-form>
        <div v-if="loginAcceptsCookies" class="align_right pb-v-md">
          <div class="text-v-tiny font-pj pt-v-md">
            {{strings['login_accepts_cookies']}} 
          </div>
        </div> 
      </div>
    </div>
    <v-dialog v-model="showPopup" width="500">
      <v-card>
        <v-card-title>
          <span class="headline">{{strings['welcome']}}, {{ username }}!</span>
        </v-card-title>
        <v-card-text>
          {{ strings['login_success'] }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :to="{ name: 'account' }">{{strings['start_searching']}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


