/* eslint-disable */
<script>
import { getInterpolatedColor } from '../helper';
import { Colors } from '../colors';

export default {
  name: 'ValuationThumbnail',
  props: {
    price: {
      type: Number,
      required: true,
      default: () => null,
    },
    valuation: {
      type: Number,
      required: true,
      default: () => null,
    },
    minValue: {
      type: Number,
      required: true,
      default: () => null,
    },
    maxValue: {
      type: Number,
      required: true,
      default: () => null,
    },
    width: {
      type: Number,
      required: true,
      default: () => 100,
    },
    height: {
      type: Number,
      required: true,
      default: () => 20,
    }
  },
  data() {
    return {
      valuationXStart : 0,
      valuationXEnd : 0,
      valuationYStart : 0,
      valuationYEnd : 0,
      valuationColor: '',
      zeroX: 0,
      priceX: 0,
      textX: 0,
      textY: 0,
    };
  },
  methods: {
    computeSVG()
    {
      // compute the valuation range in x
      const borderX = 0.3;
      const range = this.maxValue - this.minValue;
      let minValueWithOffset = this.minValue - borderX * range;
      let maxValueWithOffset = this.maxValue + borderX * range;

      if(this.valuation < 0)
      {
        this.valuationXStart = (this.valuation - minValueWithOffset) / (maxValueWithOffset - minValueWithOffset) * this.width;
        this.valuationXEnd = (0 - minValueWithOffset) / (maxValueWithOffset - minValueWithOffset) * this.width;      
      }
      else
      {
        this.valuationXStart = (0 - minValueWithOffset) / (maxValueWithOffset - minValueWithOffset) * this.width;
        this.valuationXEnd = (this.valuation - minValueWithOffset) / (maxValueWithOffset - minValueWithOffset) * this.width;      
      }

      // compute the valuation range in y
      const borderY = 0.2;
      this.valuationYStart = borderY * this.height;
      this.valuationYEnd = (1-borderY) * this.height;

      // compute the color for the valuation rectangle
      this.valuationColor = this.getColor(this.price, this.valuation);

      // compute the zero line
      this.zeroX = (0 - minValueWithOffset) / (maxValueWithOffset - minValueWithOffset) * this.width;

      // compute the market price line
      this.priceX = (this.price - minValueWithOffset) / (maxValueWithOffset - minValueWithOffset) * this.width;

      this.textX = this.zeroX;
      this.textY = this.height / 2;
    },
    /*---------------------------------------------------------------------------------------------------------/
    // compute the color the valuation rectangle
    /---------------------------------------------------------------------------------------------------------*/
    getColor(price, value) 
    {
      if(price == value)
      {
        return Colors.Gray;
      }
      else if(price > 0)
      {
        var ratio = value / price;
        if(value < 0)
        {
          ratio = 0;
        }
        let veryBad = 0.25;
        let bad = 0.4;
        let slightlyBad = 0.6;
        let neutral = 0.75;
        let slightlyGood = 1;
        let good = 1.25;
        let veryGood = 2;

        let colorNeutral = Colors.Gray;
        let pastel = false;

        return getInterpolatedColor(ratio, veryBad, bad, slightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral, pastel);
      }
      else
      {
        return Colors.White;
      }     
    }
  },
  mounted() {
    // initialization of UI content
    this.graph = this.computeSVG();
  },
  watch: {
    data: {
      handler(val) {
        this.graph = this.computeSVG();
      },
    },
  },
  computed: {
    heightInPixels() {
      return this.height;// + "px";
    },
    widthInPixels() {
      return this.width;// + "px";
    }
  }
}
</script>

<template>
    <svg :width="widthInPixels" :height="heightInPixels">
      <!-- draw the background 
      <rect :x="0" :y="0" :width="width" :height="this.height" fill="rgb(245,249,240)" stroke-width="0.5" opacity="1"></rect>
      -->
      <!-- draw the rectangle of the valuation-->
      <rect :x="valuationXStart" :y="valuationYStart" :width="valuationXEnd - valuationXStart" :height="valuationYEnd - valuationYStart" :fill="valuationColor" stroke-width="0.5" opacity="1"></rect>
      <!-- draw the zero line -->
      <line :x1="zeroX" :y1="0" :x2="zeroX" :y2="this.height" stroke-width="0.5" stroke="black" opacity="1"></line>
      <!-- draw the price line -->
      <line :x1="priceX" :y1="0" :x2="priceX" :y2="this.height" stroke-width="0.5" stroke="black" opacity="1"></line>

      <!-- draw the zero at the zero line -->
      <!--<text font-size="14" font-family="Plus Jakarta Sans, sans-serif" :x="textX" :y="textY" text-anchor="middle" dy="0.5ex" stroke="black">0</text>   
    -->   
    </svg>
</template>

/* eslint-enable */ 