<script>
/* eslint-disable */
import {
  getSymbolList,
  logUserEvent
} from '../helper';

import { sessionManager } from '@/main';

export default {
  props: {
    label: {
      type: String,
      default: 'Ticker symbol',
    },
    value: String,
    name: String,
    error: Object,
    errorMessage: Object,
  },
  data() {
    return {
      select: null,
      search: null,
      items: [],
      searchItems: [],
      loading: false,
      required: false,
      currentSearch: null,
      filteredItems: [],
      loaded: false,
    };
  },
  watch: {
    select(value) {
      if(value) {
        this.searchItems = this.items;
        document.activeElement.blur();

        // replace . in symbol with _ to avoid problems with vuetify
        value.id = value.id.replace(/\./g, '_');

        // make the string uppercase
        value.id = value.id.toUpperCase();

        this.$emit('input', value ? value.id : null);
        logUserEvent('symbolSearch', sessionManager, this.$vuetify.breakpoint.mobile, value.id);
      }            
    },
    value(value) {
      this.setValue(value);
    },
    search: function (value) {
      if (value === null || value.length === 0) {
        this.searchItems = this.items;
        this.currentSearch = null;
      } else {
        if(value.match(/ \(.*/)) {
          value = value.replace(/ \(.*/, '');
        }
        if(!this.currentSearch) {
          this.currentSearch = value;
        }       

        if (value.search(this.currentSearch) == -1) {
          this.searchItems = this.items;
        }
        this.searchItems = this.filterItems(value);
      }
    }
  },
  created() {
    this.preloading();
  },

  methods: {
    filterItems_(value, items) {
      const lowercasedValue = value.toLowerCase();
      
      return items.filter(function(el) {
          return (el.companyName.indexOf(lowercasedValue) > -1) || 
            (el.id.indexOf(lowercasedValue) > -1) ||
            (el.id === lowercasedValue); // exact match for single letter ticker
        }).sort((a, b) => {         
          
          const isAIdExactMatch = a.id === lowercasedValue;
          const isBIdExactMatch = b.id === lowercasedValue;
          
          const isANameExactMatch = a.companyName === lowercasedValue;
          const isBNameExactMatch = b.companyName === lowercasedValue;
          
          const isANameStartsWith = a.companyName.startsWith(lowercasedValue);
          const isBNameStartsWith = b.companyName.startsWith(lowercasedValue);
          
          const isAIdPartialMatch = a.id.includes(lowercasedValue);
          const isBIdPartialMatch = b.id.includes(lowercasedValue);
          
          const isANamePartialMatch = a.companyName.includes(lowercasedValue);
          const isBNamePartialMatch = b.companyName.includes(lowercasedValue);
          
          if (isAIdExactMatch && !isBIdExactMatch) return -1;
          if (!isAIdExactMatch && isBIdExactMatch) return 1;
          
          if (isANameExactMatch && !isBNameExactMatch) return -1;
          if (!isANameExactMatch && isBNameExactMatch) return 1;
          
          if (isANameStartsWith && !isBNameStartsWith) return -1;
          if (!isANameStartsWith && isBNameStartsWith) return 1;

          if (isANameStartsWith && isBNameStartsWith) {
            return a.companyName.localeCompare(b.companyName);
          }
          
          if (isAIdPartialMatch && !isBIdPartialMatch) return -1;
          if (!isAIdPartialMatch && isBIdPartialMatch) return 1;
          
          if (isANamePartialMatch && !isBNamePartialMatch) return -1;
          if (!isANamePartialMatch && isBNamePartialMatch) return 1;

          return a.companyName.localeCompare(b.companyName);

        });
    },
    filterItems(value) 
    {
      let len = value.length;
      
      if (len > 1) {
        const entry = this.filteredItems[len-1];
        if (entry) {
          if (value.includes(entry.value)) {
            this.filteredItems[len] = { value: value, items: [...this.filterItems_(value, entry.items)] }; 
            return this.filteredItems[len].items;
          }
        }
      }
      
      this.filteredItems[len] = { value: value, items: [...this.filterItems_(value, this.filteredItems[0].items)] };
      return this.filteredItems[len].items;
    },
    preloading() {
      this.loaded = false;
      getSymbolList(sessionManager).then(
        (json) => {
          this.items = json;
          this.items.forEach(element => {
            element.displayName = (element.companyName + ' (' + element.id + ')');
            element.id = element.id.toLowerCase();
            if(element.companyName)
            {
              element.companyName = element.companyName.toLowerCase();
            }
          });
          this.searchItems = this.items;

          this.filteredItems[0] = { value: '', items: [...this.searchItems], };
          this.loaded = true;
        }
      );
    }
  }
}
</script>

<style>
.v-list-item__content {
  font-size: 16px;
  font-family: Plus Jakarta Sans;
}
</style>

<template>
    <v-autocomplete v-if="loaded" class="ml-3"
      v-model="select"
      :loading="loading"
      :items="searchItems"
      item-text="displayName"
      item-value="id"
      :search-input.sync="search"
      no-filter
      clearable
      return-object
      :label="label"
      :name="name"
      :required="required"
      :rules="[v => !this.required || !!v || 'ticker symbol needed']"
      :error="error"
      :error-message="errorMessage"
      single-line
      flat
      dense
      solo
      hide-details
      outlined
    />
</template>

/* eslint-enable */
