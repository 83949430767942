<script>
import { sessionManager } from '@/main';


export default {
  name: 'DebugSymbolsPageFinnhub',
  props: {
    exchange: {
      type: String,
      required: false,
      default: () => 'F',
    },
  },
  components: {
   
  },
  data() {
    return {
      symbols: [],
      headers: [
        {
          text: 'currency',
          value: 'currency',
          width: '20px',
          sortable: true,
        },       
        {
          text: 'description',
          value: 'description',
          width: '200px',
          sortable: true,
        },
        {
          text: 'displaySymbol',
          value: 'displaySymbol',
          width: '20px',
          sortable: true,
        },
        {
          text: 'figi',
          value: 'figi',
          width: '20px',
          sortable: true,
        },   
        {
          text: 'isin',
          value: 'isin',
          width: '20px',
          sortable: true,
        },
        {
          text: 'mic',
          value: 'mic',
          width: '20px',
          sortable: true,
        },   
        {
          text: 'shareClassFIGI',
          value: 'shareClassFIGI',
          width: '20px',
          sortable: true,
        },
        {
          text: 'symbol',
          value: 'symbol',
          width: '20px',
          sortable: true,
        }, 
        {
          text: 'type',
          value: 'type',
          width: '20px',
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    document.title = 'Finnhub Symbols of ' + this.exchange + ' - Valuetion';
  },
  created() {
    this.language = sessionManager.read('language');
    this.unitOfAccount = sessionManager.read('unitOfAccount');

    // check if user is logged in, if not redirect to login page
    if(!sessionManager.isLoggedin())
    {
      this.$router.push('/login');
    }
  },
  methods: {
    // method to get the company valuation
    async fetchSymbolData()
    {
      const url = '/api/HttpTriggerGetStockSymbolsFH?';
      const response = await fetch(url +
        new URLSearchParams({
          exchange: this.exchange, 
        }), { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });

      // check if response is ok
      if(response.ok) {
        const data = await response.json();
        this.symbols = data;
      }
      else {
        // check if response is unauthorized
        if(response.status === 401) {
          // redirect to login page
          this.$router.push('/login');
        }
      }    
    },
   
  },
  beforeMount() { 
    this.fetchSymbolData();
  },
}
</script>

<template>
  <div class="flex flex-col">
    <div class="bg-primary">
        <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md pb-v-sm' : 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md pb-v-sm'">Finnhub exchange symbols</h2>
    </div>
    <div class="w-full">
      <v-data-table v-if="symbols != null"
          dense
          :headers="headers"
          :items="symbols.symbols"
          :items-per-page="600"
          mobile-breakpoint="0"
        >
        <template #item.symbol="{value}">
            <router-link :to="{ path: 'profile/' + value + ''}" target="_blank">
            {{ value }}
          </router-link>
        </template>
        </v-data-table>          
    </div>
  </div>
</template>
