/* eslint-disable */

import { Colors } from './colors';
import { formatUnitOfAccount } from './helper';
import { Graph, GraphNode, CreateGridBasedGraph } from './arrow-graph';
import { getTranslation } from './translations';

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the income statement
/---------------------------------------------------------------------------------------------------------*/
export function createIncomeStatementGraph(uniqueId, income, type, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  if(type == 'FMP')
  {
    return createIncomeStatementGraphFMP(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale);
  }
  else
  {
    return createIncomeStatementGraphFH(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale);
  }
  return;
}

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the income statement for Financial Modeling Prep
/---------------------------------------------------------------------------------------------------------*/
export function createIncomeStatementGraphFMP(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  // we are searching for the largest absolute value in the income statement
  var max = Math.abs(income.revenue);
  if(Math.abs(income.operatingIncome) > max)
  {
    max = Math.abs(income.operatingIncome);
  }
  if(Math.abs(income.netIncome) > max)
  {
    max = Math.abs(income.netIncome);
  }
  if(Math.abs(income.costOfRevenue) > max)
  {
    max = Math.abs(income.costOfRevenue);
  }
  if(Math.abs(income.grossProfit) > max)
  {
    max = Math.abs(income.grossProfit);
  }
  if(Math.abs(income.incomeBeforeTax) > max)
  {
    max = Math.abs(income.incomeBeforeTax);
  }
  if(Math.abs(income.incomeTaxExpense) > max)
  {
    max = Math.abs(income.incomeTaxExpense);
  }
  if(Math.abs(income.operatingExpenses) > max)
  {
    max = Math.abs(income.operatingExpenses);
  }
  if(Math.abs(income.researchAndDevelopmentExpenses) > max)
  {
    max = Math.abs(income.researchAndDevelopmentExpenses);
  }
  if(Math.abs(income.totalOtherIncomeExpensesNet) > max)
  {
    max = Math.abs(income.totalOtherIncomeExpensesNet);
  }

  var width = 40;
  var height = 200;
  var scale = height / max;

  var knownOrderedNodes = [];
  let fontSizeSmall = 11 * fontSizeScale;
  let fontSizeBig = 14 * fontSizeScale;

  var graph = new Graph(fontSizeSmall);

  // Revenue Segments
  /*---------------------------------------------------------------------------------------------------------*/
  var segmentNodes = new Array();
  if(income.revenueSegments.length > 0 && income.isRevenueSegmentsConsistent)
  {
    income.revenueSegments.forEach(element => {
      var segment = new GraphNode(element.name, formatUnitOfAccount(element.revenue, unitOfAccount, true), scale * element.revenue, Colors.Blue8, Colors.RedOrange, fontSizeSmall);
      graph.addNode(segment);
      segmentNodes.push(segment);
    });
  }

  // Root
  /*---------------------------------------------------------------------------------------------------------*/
  var revenue = new GraphNode(language == 'de' ? 'Umsatz' : 'Revenue', formatUnitOfAccount(income.revenue, unitOfAccount, true), scale * income.revenue, Colors.Green, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(revenue);
  knownOrderedNodes.push(revenue);

  // add optional segment information
  segmentNodes.forEach(element => {
    graph.addLink(element, "right", revenue, "left", 1);
  });

  // Root / Gross Profit 
  /*---------------------------------------------------------------------------------------------------------*/
  var grossProfit = new GraphNode(language == 'de' ? 'Bruttogewinn' : 'Gross Profit', formatUnitOfAccount(income.grossProfit, unitOfAccount, true), scale * income.grossProfit, Colors.LintGreen, Colors.RedOrange, fontSizeSmall, 2);
  graph.addNode(grossProfit);
  graph.addLink(revenue, "right", grossProfit, "left", 1);
  knownOrderedNodes.push(grossProfit);

  // Root / Cost of revenue
  /*---------------------------------------------------------------------------------------------------------*/
  var costOfRevenue = new GraphNode(language == 'de' ? 'Umsatzkosten' : 'Cost of Revenue', formatUnitOfAccount(income.costOfRevenue, unitOfAccount, true), scale * income.costOfRevenue, Colors.RedOrange, Colors.Green, fontSizeSmall, 3);
  graph.addNode(costOfRevenue);
  graph.addLink(revenue, "right", costOfRevenue, "left", 1);
  knownOrderedNodes.push(costOfRevenue);

  // operatingIncome / Income
  /*---------------------------------------------------------------------------------------------------------*/
  var operatingIncome = new GraphNode(language == 'de' ? 'Betriebsergebnis' : 'Operating Income', formatUnitOfAccount(income.operatingIncome, unitOfAccount, true), scale * income.operatingIncome, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(operatingIncome);
  graph.addLink(grossProfit, "right", operatingIncome, "left", 1);

  var incomeBeforeTax = new GraphNode(language == 'de' ? 'Einkommen vor Steuern' : 'Income before Tax', formatUnitOfAccount(income.incomeBeforeTax, unitOfAccount, true), scale * income.incomeBeforeTax, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(incomeBeforeTax);
  graph.addLink(operatingIncome, "right", incomeBeforeTax, "left", 1);

  var totalOtherIncomeExpensesNet = new GraphNode(language == 'de' ? 'Sonstige Erträge & Aufwendungen Netto' : 'Other Income & Expenses Net', formatUnitOfAccount(income.totalOtherIncomeExpensesNet, unitOfAccount, true), scale * income.totalOtherIncomeExpensesNet, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(totalOtherIncomeExpensesNet);
  graph.addLink(totalOtherIncomeExpensesNet, "right", incomeBeforeTax, "left", 1);

  var netIncome = new GraphNode(language == 'de' ? 'Nettoeinkommen' : 'Net Income', formatUnitOfAccount(income.netIncome, unitOfAccount, true), scale * income.netIncome, Colors.Green, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(netIncome);
  graph.addLink(incomeBeforeTax, "right", netIncome, "left", 1);

  var incomeTaxExpense = new GraphNode(language == 'de' ? 'Einkommenssteuer' : 'Income Tax', formatUnitOfAccount(income.incomeTaxExpense, unitOfAccount, true), scale * income.incomeTaxExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(incomeTaxExpense);
  graph.addLink(incomeBeforeTax, "right", incomeTaxExpense, "left", 1);

  // operatingExpenses
  /*---------------------------------------------------------------------------------------------------------*/
  var operatingExpenses = new GraphNode(language == 'de' ? 'Betriebskosten' : 'Operating Expenses', formatUnitOfAccount(income.operatingExpenses, unitOfAccount, true), scale * income.operatingExpenses, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(operatingExpenses);
  graph.addLink(grossProfit, "right", operatingExpenses, "left", 1);
  
  var researchAndDevelopmentExpenses = new GraphNode(language == 'de' ? 'Forschung und Entwicklung' : 'Research & Development', formatUnitOfAccount(income.researchAndDevelopmentExpenses, unitOfAccount, true), scale * income.researchAndDevelopmentExpenses, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(researchAndDevelopmentExpenses);
  graph.addLink(operatingExpenses, "right", researchAndDevelopmentExpenses, "left", 1);

  var sellingGeneralAndAdministrativeExpenses = new GraphNode(language == 'de' ? 'Vertrieb, Allgemeines und Verwaltung' : 'Selling, General & Admin', formatUnitOfAccount(income.sellingGeneralAndAdministrativeExpenses, unitOfAccount, true), scale * income.sellingGeneralAndAdministrativeExpenses, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(sellingGeneralAndAdministrativeExpenses);
  graph.addLink(operatingExpenses, "right", sellingGeneralAndAdministrativeExpenses, "left", 1);

  var otherExpenses = new GraphNode(language == 'de' ? 'Sonstige Aufwendungen' : 'Other Expenses', formatUnitOfAccount(income.otherExpenses, unitOfAccount, true), scale * income.otherExpenses, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(otherExpenses);
  graph.addLink(operatingExpenses, "right", otherExpenses, "left", 1);

  graph.transformGraph(scale, unitOfAccount, language);

  // create grid based graph
  var name = 'income' + income.date + uniqueId;
  if(income.isTTM)
  {
    name += "TTM";
  }
  var gridGraph = CreateGridBasedGraph(name, 500, width, 250, 60, graph, knownOrderedNodes, revenue, grossProfit);
  return gridGraph.toSVG(svgId); 
}

//---------------------------------------------------------------------------------------------------------/
// Helper function to search for the maximum value in the income statement
//---------------------------------------------------------------------------------------------------------/
function searchMaxIncomeFH(income)
{
  // we are searching for the largest absolute value in the income statement
  var max = Math.abs(income.bankInterestIncome);
  if(Math.abs(income.bankInterestExpense) > max)
  {
    max = Math.abs(income.bankInterestExpense);
  }
  if(Math.abs(income.netInterestIncome) > max)
  {
    max = Math.abs(income.netInterestIncome);
  }
  if(Math.abs(income.loanLossProvision) > max)
  {
    max = Math.abs(income.loanLossProvision);
  }
  if(Math.abs(income.netInterestIncAfterLoanLossProv) > max)
  {
    max = Math.abs(income.netInterestIncAfterLoanLossProv);
  }
  if(Math.abs(income.grossPremiumsEarned) > max)
  {
    max = Math.abs(income.grossPremiumsEarned);
  }
  if(Math.abs(income.otherRevenue) > max)
  {
    max = Math.abs(income.otherRevenue);
  }
  if(Math.abs(income.revenue) > max)
  {
    max = Math.abs(income.revenue);
  }
  if(Math.abs(income.costOfGoodsSold) > max)
  {
    max = Math.abs(income.costOfGoodsSold);
  }
  if(Math.abs(income.grossIncome) > max)
  {
    max = Math.abs(income.grossIncome);
  }
  if(Math.abs(income.bankNonInterestIncome) > max)
  {
    max = Math.abs(income.bankNonInterestIncome);
  }
  if(Math.abs(income.bankNonInterestExpense) > max)
  {
    max = Math.abs(income.bankNonInterestExpense);
  }
  if(Math.abs(income.benefitsClaimsLossAdjustment) > max)
  {
    max = Math.abs(income.benefitsClaimsLossAdjustment);
  }
  if(Math.abs(income.deferredPolicyAcquisitionExpense) > max)
  {
    max = Math.abs(income.deferredPolicyAcquisitionExpense);
  }
  if(Math.abs(income.sgaExpense) > max)
  {
    max = Math.abs(income.sgaExpense);
  }
  if(Math.abs(income.researchDevelopment) > max)
  {
    max = Math.abs(income.researchDevelopment);
  }
  if(Math.abs(income.purchasedFuelPowerGas) > max)
  {
    max = Math.abs(income.purchasedFuelPowerGas);
  }
  if(Math.abs(income.operationsMaintenance) > max)
  {
    max = Math.abs(income.operationsMaintenance);
  }
  if(Math.abs(income.depreciationAmortization) > max)
  {
    max = Math.abs(income.depreciationAmortization);
  }
  if(Math.abs(income.otherOperatingExpensesTotal) > max)
  {
    max = Math.abs(income.otherOperatingExpensesTotal);
  }
  if(Math.abs(income.totalOperatingExpense) > max)
  {
    max = Math.abs(income.totalOperatingExpense);
  }
  if(Math.abs(income.ebit) > max)
  {
    max = Math.abs(income.ebit);
  }
  if(Math.abs(income.interestIncomeExpense) > max)
  {
    max = Math.abs(income.interestIncomeExpense);
  }
  if(Math.abs(income.nonRecurringItems) > max)
  {
    max = Math.abs(income.nonRecurringItems);
  }
  if(Math.abs(income.gainLossOnDispositionOfAssets) > max)
  {
    max = Math.abs(income.gainLossOnDispositionOfAssets);
  }
  if(Math.abs(income.totalOtherIncomeExpenseNet) > max)
  {
    max = Math.abs(income.totalOtherIncomeExpenseNet);
  }
  if(Math.abs(income.pretaxIncome) > max)
  {
    max = Math.abs(income.pretaxIncome);
  }
  if(Math.abs(income.provisionforIncomeTaxes) > max)
  {
    max = Math.abs(income.provisionforIncomeTaxes);
  }
  if(Math.abs(income.netIncomeAfterTaxes) > max)
  {
    max = Math.abs(income.netIncomeAfterTaxes);
  }
  if(Math.abs(income.minorityInterest) > max)
  {
    max = Math.abs(income.minorityInterest);
  }
  if(Math.abs(income.equityEarningsAffiliates) > max)
  {
    max = Math.abs(income.equityEarningsAffiliates);
  }
  if(Math.abs(income.netIncome) > max)
  {
    max = Math.abs(income.netIncome);
  }
  return max;
}

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the income statement
/---------------------------------------------------------------------------------------------------------*/
export function createIncomeStatementGraphFH(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  if(income.bankInterestIncome != 0 || income.bankNonInterestExpense != 0 || income.bankNonInterestIncome != 0)
  {
    return createIncomeStatementGraphFHBank(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale);
  }
  else if(income.benefitsClaimsLossAdjustment != 0 || income.costOfGoodsSold == 0 || income.deferredPolicyAcquisitionExpense != 0)
  {
    return createIncomeStatementGraphFHInsurance(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale);
  }
  else
  {
    return createIncomeStatementGraphFHNormal(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale);
  }
}

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the income statement for normal companies
/---------------------------------------------------------------------------------------------------------*/
export function createIncomeStatementGraphFHNormal(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  var width = 40;
  var height = 200;
  var max = searchMaxIncomeFH(income);
  var scale = height / max;

  var knownOrderedNodes = [];
  let fontSizeSmall = 11 * fontSizeScale;
  let fontSizeBig = 14 * fontSizeScale;  

  var graph = new Graph(fontSizeSmall);

  // Root
  var revenue = new GraphNode(getTranslation('revenue', language), formatUnitOfAccount(income.revenue, unitOfAccount, true), scale * income.revenue, Colors.Green, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(revenue);
  knownOrderedNodes.push(revenue);

  // Root / Gross Profit 
  var grossIncome = new GraphNode(getTranslation('grossIncome', language), formatUnitOfAccount(income.grossIncome, unitOfAccount, true), scale * income.grossIncome, Colors.LintGreen, Colors.RedOrange, fontSizeSmall, 2);
  graph.addNode(grossIncome);
  graph.addLink(revenue, "right", grossIncome, "left", 1);
  knownOrderedNodes.push(grossIncome);  

  // Root / Cost of revenue
  var costOfGoodsSold = new GraphNode(getTranslation('costOfGoodsSold', language), formatUnitOfAccount(income.costOfGoodsSold, unitOfAccount, true), scale * income.costOfGoodsSold, Colors.RedOrange, Colors.Green, fontSizeSmall, 3);
  graph.addNode(costOfGoodsSold);
  graph.addLink(revenue, "right", costOfGoodsSold, "left", 1);
  knownOrderedNodes.push(costOfGoodsSold);

  // Gross Income / Ebit
  var ebit = new GraphNode(getTranslation('ebit', language), formatUnitOfAccount(income.ebit, unitOfAccount, true), scale * income.ebit, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(ebit);
  graph.addLink(grossIncome, "right", ebit, "left", 1);

  // Ebit / pretaxIncome
  var pretaxIncome = new GraphNode(getTranslation('pretaxIncome', language), formatUnitOfAccount(income.pretaxIncome, unitOfAccount, true), scale * income.pretaxIncome, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(pretaxIncome);
  graph.addLink(ebit, "right", pretaxIncome, "left", 1);

  // Ebit / interestIncomeExpense
  var interestIncomeExpense = new GraphNode(getTranslation('interestIncomeExpense', language), formatUnitOfAccount(-income.interestIncomeExpense, unitOfAccount, true), scale * -income.interestIncomeExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(interestIncomeExpense);
  graph.addLink(ebit, "right", interestIncomeExpense, "left", 1);

  // Ebit / nonRecurringItems
  var nonRecurringItems = new GraphNode(getTranslation('nonRecurringItems', language), formatUnitOfAccount(-income.nonRecurringItems, unitOfAccount, true), scale * -income.nonRecurringItems, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(nonRecurringItems);
  graph.addLink(ebit, "right", nonRecurringItems, "left", 1);

  // Ebit / gainLossOnDispositionOfAssets
  var gainLossOnDispositionOfAssets = new GraphNode(getTranslation('gainLossOnDispositionOfAssets', language), formatUnitOfAccount(income.gainLossOnDispositionOfAssets, unitOfAccount, true), scale * income.gainLossOnDispositionOfAssets, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(gainLossOnDispositionOfAssets);
  graph.addLink(ebit, "right", gainLossOnDispositionOfAssets, "left", 1);

  // Ebit / totalOtherIncomeExpenseNet
  var totalOtherIncomeExpenseNet = new GraphNode(getTranslation('totalOtherIncomeExpenseNet', language), formatUnitOfAccount(income.totalOtherIncomeExpenseNet, unitOfAccount, true), scale * -income.totalOtherIncomeExpenseNet, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(totalOtherIncomeExpenseNet);
  graph.addLink(ebit, "right", totalOtherIncomeExpenseNet, "left", 1);

  // pretaxIncome / netIncomeAfterTaxes
  var netIncomeAfterTaxes = new GraphNode(getTranslation('netIncomeAfterTaxes', language), formatUnitOfAccount(income.netIncomeAfterTaxes, unitOfAccount, true), scale * income.netIncomeAfterTaxes, Colors.Green, Colors.RedOrange, fontSizeSmall);
  graph.addNode(netIncomeAfterTaxes);
  graph.addLink(pretaxIncome, "right", netIncomeAfterTaxes, "left", 1);

  // pretaxIncome / provisionforIncomeTaxes
  var provisionforIncomeTaxes = new GraphNode(getTranslation('provisionforIncomeTaxes', language), formatUnitOfAccount(income.provisionforIncomeTaxes, unitOfAccount, true), scale * income.provisionforIncomeTaxes, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(provisionforIncomeTaxes);
  graph.addLink(pretaxIncome, "right", provisionforIncomeTaxes, "left", 1);

  // netIncomeAfterTaxes / netIncome
  var netIncome = new GraphNode(getTranslation('netIncome', language), formatUnitOfAccount(income.netIncome, unitOfAccount, true), scale * income.netIncome, Colors.Green, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(netIncome);
  graph.addLink(netIncomeAfterTaxes, "right", netIncome, "left", 1);

  // netIncomeAfterTaxes / minorityInterest
  var minorityInterest = new GraphNode(getTranslation('minorityInterest', language), formatUnitOfAccount(-income.minorityInterest, unitOfAccount, true), scale * -income.minorityInterest, Colors.Blue5, Colors.Blue5, fontSizeSmall);
  graph.addNode(minorityInterest);
  graph.addLink(netIncomeAfterTaxes, "right", minorityInterest, "left", 1);

  // netIncomeAfterTaxes / equityEarningsAffiliates
  var equityEarningsAffiliates = new GraphNode(getTranslation('equityEarningsAffiliates', language), formatUnitOfAccount(income.equityEarningsAffiliates, unitOfAccount, true), scale * -income.equityEarningsAffiliates, Colors.Blue5, Colors.Blue5, fontSizeSmall);
  graph.addNode(equityEarningsAffiliates);
  graph.addLink(netIncomeAfterTaxes, "right", equityEarningsAffiliates, "left", 1);

  // Gross Income / Total Operating Expense
  var totalOperatingExpense = new GraphNode(getTranslation('totalOperatingExpense', language), formatUnitOfAccount(income.totalOperatingExpense, unitOfAccount, true), scale * income.totalOperatingExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(totalOperatingExpense);
  graph.addLink(grossIncome, "right", totalOperatingExpense, "left", 1);

  // Total Operating Expense / sgaExpense
  var sgaExpense = new GraphNode(getTranslation('sgaExpense', language), formatUnitOfAccount(income.sgaExpense, unitOfAccount, true), scale * income.sgaExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(sgaExpense);
  graph.addLink(totalOperatingExpense, "right", sgaExpense, "left", 1);

  // Total Operating Expense / researchDevelopment
  var researchDevelopment = new GraphNode(getTranslation('researchDevelopment', language), formatUnitOfAccount(income.researchDevelopment, unitOfAccount, true), scale * income.researchDevelopment, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(researchDevelopment);
  graph.addLink(totalOperatingExpense, "right", researchDevelopment, "left", 1);

  // Total Operating Expense / purchasedFuelPowerGas
  var purchasedFuelPowerGas = new GraphNode(getTranslation('purchasedFuelPowerGas', language), formatUnitOfAccount(income.purchasedFuelPowerGas, unitOfAccount, true), scale * income.purchasedFuelPowerGas, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(purchasedFuelPowerGas);
  graph.addLink(totalOperatingExpense, "right", purchasedFuelPowerGas, "left", 1);

  // Total Operating Expense / operationsMaintenance
  var operationsMaintenance = new GraphNode(getTranslation('operationsMaintenance', language), formatUnitOfAccount(income.operationsMaintenance, unitOfAccount, true), scale * income.operationsMaintenance, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(operationsMaintenance);
  graph.addLink(totalOperatingExpense, "right", operationsMaintenance, "left", 1);

  // Total Operating Expense / depreciationAmortization
  var depreciationAmortization = new GraphNode(getTranslation('depreciationAmortization', language), formatUnitOfAccount(income.depreciationAmortization, unitOfAccount, true), scale * income.depreciationAmortization, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(depreciationAmortization);
  graph.addLink(totalOperatingExpense, "right", depreciationAmortization, "left", 1);

  // Total Operating Expense / otherOperatingExpensesTotal
  var otherOperatingExpensesTotal = new GraphNode(getTranslation('otherOperatingExpensesTotal', language), formatUnitOfAccount(income.otherOperatingExpensesTotal, unitOfAccount, true), scale * income.otherOperatingExpensesTotal, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(otherOperatingExpensesTotal);
  graph.addLink(totalOperatingExpense, "right", otherOperatingExpensesTotal, "left", 1);

  graph.transformGraph(scale, unitOfAccount, language);

  // create grid based graph
  var name = 'income' + income.period + uniqueId;
  if(income.isTTM)
  {
    name += "TTM";
  }

  var gridGraph = CreateGridBasedGraph(name, 500, width, 250, 60, graph, knownOrderedNodes, revenue, grossIncome);
  return gridGraph.toSVG(svgId); 
}

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the income statement for banks
/---------------------------------------------------------------------------------------------------------*/
export function createIncomeStatementGraphFHBank(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  var width = 40;
  var height = 200;
  var max = searchMaxIncomeFH(income);
  var scale = height / max;

  var knownOrderedNodes = [];
  let fontSizeSmall = 11 * fontSizeScale;
  let fontSizeBig = 14 * fontSizeScale;  

  var graph = new Graph(fontSizeSmall);

  // bankInterestIncome
  var bankInterestIncome = new GraphNode(getTranslation('bankInterestIncome', language), formatUnitOfAccount(income.bankInterestIncome, unitOfAccount, true), scale * income.bankInterestIncome, Colors.Green, Colors.RedOrange, fontSizeSmall);
  graph.addNode(bankInterestIncome);
  knownOrderedNodes.push(bankInterestIncome);

  // bankInterestIncome / netInterestIncome
  var netInterestIncome = new GraphNode(getTranslation('netInterestIncome', language), formatUnitOfAccount(income.netInterestIncome, unitOfAccount, true), scale * income.netInterestIncome, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(netInterestIncome);
  graph.addLink(bankInterestIncome, "right", netInterestIncome, "left", 1);
  knownOrderedNodes.push(netInterestIncome);

  // netInterestIncome / netInterestIncAfterLoanLossProv
  var netInterestIncAfterLoanLossProv = new GraphNode(getTranslation('netInterestIncAfterLoanLossProv', language), formatUnitOfAccount(income.netInterestIncAfterLoanLossProv, unitOfAccount, true), scale * income.netInterestIncAfterLoanLossProv, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(netInterestIncAfterLoanLossProv);
  graph.addLink(netInterestIncome, "right", netInterestIncAfterLoanLossProv, "left", 1);

  // netInterestIncome / loanLossProvision
  var loanLossProvision = new GraphNode(getTranslation('loanLossProvision', language), formatUnitOfAccount(income.loanLossProvision, unitOfAccount, true), scale * income.loanLossProvision, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(loanLossProvision);
  graph.addLink(netInterestIncome, "right", loanLossProvision, "left", 1);

  // bankInterestIncome / interestExpense
  var interestExpense = new GraphNode(getTranslation('interestExpense', language), formatUnitOfAccount(income.interestExpense, unitOfAccount, true), scale * income.interestExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(interestExpense);
  graph.addLink(bankInterestIncome, "right", interestExpense, "left", 1);

  // bankNonInterestExpense
  var bankNonInterestExpense = new GraphNode(getTranslation('bankNonInterestExpense', language), formatUnitOfAccount(income.bankNonInterestExpense, unitOfAccount, true), scale * income.bankNonInterestExpense, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(bankNonInterestExpense);
  
  // bankNonInterestIncome
  var bankNonInterestIncome = new GraphNode(getTranslation('bankNonInterestIncome', language), formatUnitOfAccount(income.bankNonInterestIncome, unitOfAccount, true), scale * income.bankNonInterestIncome, Colors.Green, Colors.RedOrange, fontSizeSmall);
  graph.addNode(bankNonInterestIncome);

  // Root
  var revenue = new GraphNode(getTranslation('revenue', language), formatUnitOfAccount(income.revenue, unitOfAccount, true), scale * income.revenue, Colors.Green, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(revenue);

  // Root / Gross Profit 
  var grossIncome = new GraphNode(getTranslation('grossIncome', language), formatUnitOfAccount(income.grossIncome, unitOfAccount, true), scale * income.grossIncome, Colors.LintGreen, Colors.RedOrange, fontSizeSmall, 2);
  graph.addNode(grossIncome);
  graph.addLink(revenue, "right", grossIncome, "left", 1);

  // Root / Cost of revenue
  var costOfGoodsSold = new GraphNode(getTranslation('costOfGoodsSold', language), formatUnitOfAccount(income.costOfGoodsSold, unitOfAccount, true), scale * income.costOfGoodsSold, Colors.RedOrange, Colors.Green, fontSizeSmall, 3);
  graph.addNode(costOfGoodsSold);
  graph.addLink(revenue, "right", costOfGoodsSold, "left", 1);

  // Gross Income / Ebit
  var ebit = new GraphNode(getTranslation('ebit', language), formatUnitOfAccount(income.ebit, unitOfAccount, true), scale * income.ebit, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(ebit);
  graph.addLink(grossIncome, "right", ebit, "left", 1);

  // Ebit / pretaxIncome
  var pretaxIncome = new GraphNode(getTranslation('pretaxIncome', language), formatUnitOfAccount(income.pretaxIncome, unitOfAccount, true), scale * income.pretaxIncome, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(pretaxIncome);
  graph.addLink(ebit, "right", pretaxIncome, "left", 1);

  graph.addLink(netInterestIncAfterLoanLossProv, "right", pretaxIncome, "left", 1);


  // Ebit / interestIncomeExpense
  var interestIncomeExpense = new GraphNode(getTranslation('interestIncomeExpense', language), formatUnitOfAccount(-income.interestIncomeExpense, unitOfAccount, true), scale * -income.interestIncomeExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(interestIncomeExpense);
  graph.addLink(ebit, "right", interestIncomeExpense, "left", 1);

  // Ebit / nonRecurringItems
  var nonRecurringItems = new GraphNode(getTranslation('nonRecurringItems', language), formatUnitOfAccount(-income.nonRecurringItems, unitOfAccount, true), scale * -income.nonRecurringItems, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(nonRecurringItems);
  graph.addLink(ebit, "right", nonRecurringItems, "left", 1);

  // Ebit / gainLossOnDispositionOfAssets
  var gainLossOnDispositionOfAssets = new GraphNode(getTranslation('gainLossOnDispositionOfAssets', language), formatUnitOfAccount(income.gainLossOnDispositionOfAssets, unitOfAccount, true), scale * income.gainLossOnDispositionOfAssets, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(gainLossOnDispositionOfAssets);
  graph.addLink(ebit, "right", gainLossOnDispositionOfAssets, "left", 1);

  // Ebit / totalOtherIncomeExpenseNet
  var totalOtherIncomeExpenseNet = new GraphNode(getTranslation('totalOtherIncomeExpenseNet', language), formatUnitOfAccount(income.totalOtherIncomeExpenseNet, unitOfAccount, true), scale * -income.totalOtherIncomeExpenseNet, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(totalOtherIncomeExpenseNet);
  graph.addLink(ebit, "right", totalOtherIncomeExpenseNet, "left", 1);

  // pretaxIncome / netIncomeAfterTaxes
  var netIncomeAfterTaxes = new GraphNode(getTranslation('netIncomeAfterTaxes', language), formatUnitOfAccount(income.netIncomeAfterTaxes, unitOfAccount, true), scale * income.netIncomeAfterTaxes, Colors.Green, Colors.RedOrange, fontSizeSmall);
  graph.addNode(netIncomeAfterTaxes);
  graph.addLink(pretaxIncome, "right", netIncomeAfterTaxes, "left", 1);

  // bankNonInterestIncome / pretaxIncome
  graph.addLink(bankNonInterestIncome, "right", pretaxIncome, "left", 1);

  // bankNonInterestExpense / pretaxIncome
  graph.addLink(bankNonInterestExpense, "right", pretaxIncome, "left", 1);

  // pretaxIncome / provisionforIncomeTaxes
  var provisionforIncomeTaxes = new GraphNode(getTranslation('provisionforIncomeTaxes', language), formatUnitOfAccount(income.provisionforIncomeTaxes, unitOfAccount, true), scale * income.provisionforIncomeTaxes, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(provisionforIncomeTaxes);
  graph.addLink(pretaxIncome, "right", provisionforIncomeTaxes, "left", 1);

  // netIncomeAfterTaxes / netIncome
  var netIncome = new GraphNode(getTranslation('netIncome', language), formatUnitOfAccount(income.netIncome, unitOfAccount, true), scale * income.netIncome, Colors.Green, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(netIncome);
  graph.addLink(netIncomeAfterTaxes, "right", netIncome, "left", 1);

  // netIncomeAfterTaxes / minorityInterest
  var minorityInterest = new GraphNode(getTranslation('minorityInterest', language), formatUnitOfAccount(-income.minorityInterest, unitOfAccount, true), scale * -income.minorityInterest, Colors.Blue5, Colors.Blue5, fontSizeSmall);
  graph.addNode(minorityInterest);
  graph.addLink(netIncomeAfterTaxes, "right", minorityInterest, "left", 1);

  // netIncomeAfterTaxes / equityEarningsAffiliates
  var equityEarningsAffiliates = new GraphNode(getTranslation('equityEarningsAffiliates', language), formatUnitOfAccount(income.equityEarningsAffiliates, unitOfAccount, true), scale * -income.equityEarningsAffiliates, Colors.Blue5, Colors.Blue5, fontSizeSmall);
  graph.addNode(equityEarningsAffiliates);
  graph.addLink(netIncomeAfterTaxes, "right", equityEarningsAffiliates, "left", 1);

  // Gross Income / Total Operating Expense
  var totalOperatingExpense = new GraphNode(getTranslation('totalOperatingExpense', language), formatUnitOfAccount(income.totalOperatingExpense, unitOfAccount, true), scale * income.totalOperatingExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(totalOperatingExpense);
  graph.addLink(grossIncome, "right", totalOperatingExpense, "left", 1);

  graph.transformGraph(scale, unitOfAccount, language);

  // create grid based graph
  var name = 'income' + income.period + uniqueId;
  if(income.isTTM)
  {
    name += "TTM";
  }

  var gridGraph = CreateGridBasedGraph(name, 500, width, 250, 60, graph, knownOrderedNodes, bankInterestIncome, netInterestIncome);
  return gridGraph.toSVG(svgId); 

}

/*---------------------------------------------------------------------------------------------------------/
// Create a graph for the income statement for insurance companies
/---------------------------------------------------------------------------------------------------------*/
export function createIncomeStatementGraphFHInsurance(uniqueId, income, unitOfAccount, language, svgId, fontSizeScale = 1.0)
{
  var width = 40;
  var height = 200;
  var max = searchMaxIncomeFH(income);
  var scale = height / max;

  var knownOrderedNodes = [];
  let fontSizeSmall = 11 * fontSizeScale;
  let fontSizeBig = 14 * fontSizeScale;  

  var graph = new Graph(fontSizeSmall);

  // Root
  var revenue = new GraphNode(getTranslation('revenue', language), formatUnitOfAccount(income.revenue, unitOfAccount, true), scale * income.revenue, Colors.Green, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(revenue);
  knownOrderedNodes.push(revenue);

  // Gross Income / Ebit
  var ebit = new GraphNode(getTranslation('ebit', language), formatUnitOfAccount(income.ebit, unitOfAccount, true), scale * income.ebit, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(ebit);
  graph.addLink(revenue, "right", ebit, "left", 1);
  knownOrderedNodes.push(ebit);

  // Ebit / pretaxIncome
  var pretaxIncome = new GraphNode(getTranslation('pretaxIncome', language), formatUnitOfAccount(income.pretaxIncome, unitOfAccount, true), scale * income.pretaxIncome, Colors.LimeGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(pretaxIncome);
  graph.addLink(ebit, "right", pretaxIncome, "left", 1);

  // Ebit / interestIncomeExpense
  var interestIncomeExpense = new GraphNode(getTranslation('interestIncomeExpense', language), formatUnitOfAccount(-income.interestIncomeExpense, unitOfAccount, true), scale * -income.interestIncomeExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(interestIncomeExpense);
  graph.addLink(ebit, "right", interestIncomeExpense, "left", 1);

  // Ebit / nonRecurringItems
  var nonRecurringItems = new GraphNode(getTranslation('nonRecurringItems', language), formatUnitOfAccount(-income.nonRecurringItems, unitOfAccount, true), scale * -income.nonRecurringItems, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(nonRecurringItems);
  graph.addLink(ebit, "right", nonRecurringItems, "left", 1);

  // Ebit / gainLossOnDispositionOfAssets
  var gainLossOnDispositionOfAssets = new GraphNode(getTranslation('gainLossOnDispositionOfAssets', language), formatUnitOfAccount(income.gainLossOnDispositionOfAssets, unitOfAccount, true), scale * income.gainLossOnDispositionOfAssets, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(gainLossOnDispositionOfAssets);
  graph.addLink(ebit, "right", gainLossOnDispositionOfAssets, "left", 1);

  // Ebit / totalOtherIncomeExpenseNet
  var totalOtherIncomeExpenseNet = new GraphNode(getTranslation('totalOtherIncomeExpenseNet', language), formatUnitOfAccount(income.totalOtherIncomeExpenseNet, unitOfAccount, true), scale * -income.totalOtherIncomeExpenseNet, Colors.LintGreen, Colors.RedOrange, fontSizeSmall);
  graph.addNode(totalOtherIncomeExpenseNet);
  graph.addLink(ebit, "right", totalOtherIncomeExpenseNet, "left", 1);

  // pretaxIncome / netIncomeAfterTaxes
  var netIncomeAfterTaxes = new GraphNode(getTranslation('netIncomeAfterTaxes', language), formatUnitOfAccount(income.netIncomeAfterTaxes, unitOfAccount, true), scale * income.netIncomeAfterTaxes, Colors.Green, Colors.RedOrange, fontSizeSmall);
  graph.addNode(netIncomeAfterTaxes);
  graph.addLink(pretaxIncome, "right", netIncomeAfterTaxes, "left", 1);

  // pretaxIncome / provisionforIncomeTaxes
  var provisionforIncomeTaxes = new GraphNode(getTranslation('provisionforIncomeTaxes', language), formatUnitOfAccount(income.provisionforIncomeTaxes, unitOfAccount, true), scale * income.provisionforIncomeTaxes, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(provisionforIncomeTaxes);
  graph.addLink(pretaxIncome, "right", provisionforIncomeTaxes, "left", 1);

  // netIncomeAfterTaxes / netIncome
  var netIncome = new GraphNode(getTranslation('netIncome', language), formatUnitOfAccount(income.netIncome, unitOfAccount, true), scale * income.netIncome, Colors.Green, Colors.RedOrange, fontSizeBig, true);
  graph.addNode(netIncome);
  graph.addLink(netIncomeAfterTaxes, "right", netIncome, "left", 1);

  // netIncomeAfterTaxes / minorityInterest
  var minorityInterest = new GraphNode(getTranslation('minorityInterest', language), formatUnitOfAccount(-income.minorityInterest, unitOfAccount, true), scale * -income.minorityInterest, Colors.Blue5, Colors.Blue5, fontSizeSmall);
  graph.addNode(minorityInterest);
  graph.addLink(netIncomeAfterTaxes, "right", minorityInterest, "left", 1);

  // netIncomeAfterTaxes / equityEarningsAffiliates
  var equityEarningsAffiliates = new GraphNode(getTranslation('equityEarningsAffiliates', language), formatUnitOfAccount(income.equityEarningsAffiliates, unitOfAccount, true), scale * -income.equityEarningsAffiliates, Colors.Blue5, Colors.Blue5, fontSizeSmall);
  graph.addNode(equityEarningsAffiliates);
  graph.addLink(netIncomeAfterTaxes, "right", equityEarningsAffiliates, "left", 1);

  // revenue / Total Operating Expense
  var totalOperatingExpense = new GraphNode(getTranslation('totalOperatingExpense', language), formatUnitOfAccount(income.totalOperatingExpense, unitOfAccount, true), scale * income.totalOperatingExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(totalOperatingExpense);
  graph.addLink(revenue, "right", totalOperatingExpense, "left", 1);

  // Total Operating Expense / benefitsClaimsLossAdjustment
  var benefitsClaimsLossAdjustment = new GraphNode(getTranslation('benefitsClaimsLossAdjustment', language), formatUnitOfAccount(income.benefitsClaimsLossAdjustment, unitOfAccount, true), scale * income.benefitsClaimsLossAdjustment, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(benefitsClaimsLossAdjustment);
  graph.addLink(totalOperatingExpense, "right", benefitsClaimsLossAdjustment, "left", 1);

  // Total Operating Expense / deferredPolicyAcquisitionExpense
  var deferredPolicyAcquisitionExpense = new GraphNode(getTranslation('deferredPolicyAcquisitionExpense', language), formatUnitOfAccount(income.deferredPolicyAcquisitionExpense, unitOfAccount, true), scale * income.deferredPolicyAcquisitionExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(deferredPolicyAcquisitionExpense);
  graph.addLink(totalOperatingExpense, "right", deferredPolicyAcquisitionExpense, "left", 1);

  // Total Operating Expense / sgaExpense
  var sgaExpense = new GraphNode(getTranslation('sgaExpense', language), formatUnitOfAccount(income.sgaExpense, unitOfAccount, true), scale * income.sgaExpense, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(sgaExpense);
  graph.addLink(totalOperatingExpense, "right", sgaExpense, "left", 1);

  // Total Operating Expense / researchDevelopment
  var researchDevelopment = new GraphNode(getTranslation('researchDevelopment', language), formatUnitOfAccount(income.researchDevelopment, unitOfAccount, true), scale * income.researchDevelopment, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(researchDevelopment);
  graph.addLink(totalOperatingExpense, "right", researchDevelopment, "left", 1);

  // Total Operating Expense / purchasedFuelPowerGas
  var purchasedFuelPowerGas = new GraphNode(getTranslation('purchasedFuelPowerGas', language), formatUnitOfAccount(income.purchasedFuelPowerGas, unitOfAccount, true), scale * income.purchasedFuelPowerGas, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(purchasedFuelPowerGas);
  graph.addLink(totalOperatingExpense, "right", purchasedFuelPowerGas, "left", 1);

  // Total Operating Expense / operationsMaintenance
  var operationsMaintenance = new GraphNode(getTranslation('operationsMaintenance', language), formatUnitOfAccount(income.operationsMaintenance, unitOfAccount, true), scale * income.operationsMaintenance, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(operationsMaintenance);
  graph.addLink(totalOperatingExpense, "right", operationsMaintenance, "left", 1);

  // Total Operating Expense / depreciationAmortization
  var depreciationAmortization = new GraphNode(getTranslation('depreciationAmortization', language), formatUnitOfAccount(income.depreciationAmortization, unitOfAccount, true), scale * income.depreciationAmortization, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(depreciationAmortization);
  graph.addLink(totalOperatingExpense, "right", depreciationAmortization, "left", 1);

  // Total Operating Expense / otherOperatingExpensesTotal
  var otherOperatingExpensesTotal = new GraphNode(getTranslation('otherOperatingExpensesTotal', language), formatUnitOfAccount(income.otherOperatingExpensesTotal, unitOfAccount, true), scale * income.otherOperatingExpensesTotal, Colors.RedOrange, Colors.LintGreen, fontSizeSmall);
  graph.addNode(otherOperatingExpensesTotal);
  graph.addLink(totalOperatingExpense, "right", otherOperatingExpensesTotal, "left", 1);

  graph.transformGraph(scale, unitOfAccount, language);

  // create grid based graph
  var name = 'income' + income.period + uniqueId;
  if(income.isTTM)
  {
    name += "TTM";
  }

  var gridGraph = CreateGridBasedGraph(name, 500, width, 250, 60, graph, knownOrderedNodes, revenue, ebit);
  return gridGraph.toSVG(svgId); 
}



/* eslint-enable */



