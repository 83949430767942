/* eslint-disable */
<script>

  import { formatUnitOfAccount, 
    formatMillions, 
    toUnitOfAccount, 
    formatPercent,
  } from '../helper';
  import { logUserEvent } from '../helper';
  import { sessionManager } from '@/main';
 
  export default {
    name: 'CompanyInfoTable',
    props: {
      company: {
        type: Object,
        required: true,
        default: () => null,
      },
      unitOfAccount: {
        type: String,
        required: true,
        default: () => 'USD',
      },
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Company Information',
            stock_symbol: 'Stock Exchange : Symbol',
            industry: 'Industry',
            sector: 'Sector',
            country: 'Country',
            website: 'Website',
            on_sale: 'On sale for',
            dividend: 'Dividend TTM',
            last_financial_period: 'Last financial period',
            last_financial_update: 'Last financial update',
            isin: 'ISIN',
            cik: 'CIK',
            cusip: 'CUSIP',
          },
          de: {
            title: 'Übersicht',
            stock_symbol: 'Börse : Symbol',
            industry: 'Industrie',
            sector: 'Branche',
            country: 'Land',
            website: 'Website',
            on_sale: 'Zum Verkauf für',
            dividend: 'Dividende der letzen 12 Monate',
            last_financial_period: 'Letzte Finanzbericht Periode',
            last_financial_update: 'Letzte Finanzbericht Aktualisierung',
            isin: 'ISIN',
            cik: 'CIK',
            cusip: 'CUSIP',
          }
        },
        language: ''
      }
    },
    created() {
      this.language = sessionManager.read('language');
      logUserEvent('companyOverview', sessionManager, this.$vuetify.breakpoint.mobile);
    },
    methods: {
      toClosestQuarter(dateTimeStr) {

        // Parse the DateTime string to a Date object
        const date = new Date(dateTimeStr);
        
        // Extract the year and month from the Date object
        var year = date.getFullYear();
        var month = date.getMonth(); // Note: January is 0, December is 11
        var quarter = 0;
        if(0 <= month && month < 3)
        {
          quarter = 1;
        }
        else if(3 <= month && month < 6)
        {
          quarter = 2;
        }
        else if(6 <= month && month < 9)
        {
          quarter = 3;
        }
        else
        {
          quarter = 4;
        }

        // Format the result as "year Q[quarter]"
        return `${year} Q${quarter}`;
      },
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
      displayMarketCapInUnitOfAccount() {
        return formatUnitOfAccount(toUnitOfAccount(this.company.marketCap, this.unitOfAccount), this.unitOfAccount, true);
      },
      displayMarketCapOriginal() {
        return formatMillions(this.company.marketCap.price);
      },
      displayDividendTTMInUnitOfAccount() {
        if(this.company.dividendValid)
        {
          return formatUnitOfAccount(toUnitOfAccount(this.company.dividendTTM, this.unitOfAccount), this.unitOfAccount, true);
        }        
      },
      displayDividendTTMOriginal() {
        if(this.company.dividendValid)
        {
          return formatMillions(this.company.dividendTTM.price);
        }
      },
      displayDividendTTMYield()
      {
        return formatPercent(this.company.dividendTTM.priceInUSD / this.company.marketCap.priceInUSD);
      },
      lastFinancialPeriod() {
        return this.toClosestQuarter(this.company.lastFinancialPeriod);
      },
      showLastFinancialUpdate()
      {
        // check if the date is youger than 1 year ago
        const lastFinancialsUpdate = new Date(this.company.lastFinancialsUpdate);
        const now = new Date();
        const oneYearAgo = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        return lastFinancialsUpdate > oneYearAgo;
      },
      lastFinancialUpdate() {
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        if(this.language == 'de')
        {
          return new Date(this.company.lastFinancialsUpdate).toLocaleDateString("de-DE", options);
        }
        else
        {
          return new Date(this.company.lastFinancialsUpdate).toLocaleDateString("en-US", options);
        }
      }
    }
  }
</script>
  
  
<template>
  <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <h3 class="darkest font-pj text-v-xl text-bold">{{strings['title']}}</h3>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{ company.companyName }}</td>
            <td class="pl-0"><v-img :src="company.image" max-width="64" max-height="64" contain /></td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['stock_symbol']}}</td>
            <td class="darkest font-pj text-v-base pl-0">{{ company.exchangeShortName }} : {{ company.id }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['isin']}}</td>
            <td class="darkest font-pj text-v-base pl-0">{{ company.isin }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['industry']}}</td>
            <td class="darkest font-pj text-v-base pl-0">{{ company.industry }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['sector']}}</td>
            <td class="darkest font-pj text-v-base pl-0">{{ company.sector }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['country']}}</td>
            <td class="darkest font-pj text-v-base pl-0">{{ company.country }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['website']}}</td>
            <td class="darkest font-pj text-v-base pl-0"><a :href=company.website>{{ company.website }}</a></td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['on_sale']}}</td>
            <td class="darkest font-pj text-v-base pl-0" v-if="company.currency != unitOfAccount">
              {{ displayMarketCapInUnitOfAccount }} ({{ displayMarketCapOriginal }} {{ company.currency }})
            </td>
            <td class="darkest font-pj text-v-base pl-0" v-else>
              {{ displayMarketCapInUnitOfAccount }}  
            </td>
          </tr>
          <tr v-if="company.dividendValid">
            <td class="darkest font-pj text-v-base pl-0"> {{strings['dividend']}} {{ displayDividendTTMYield }}</td>
            <td class="darkest font-pj text-v-base pl-0" v-if="company.currency != unitOfAccount">
              {{ displayDividendTTMInUnitOfAccount }} ({{ displayDividendTTMOriginal }} {{ company.currency }})
            </td>
            <td class="darkest font-pj text-v-base pl-0" v-else>
              {{ displayDividendTTMInUnitOfAccount }}  
            </td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['last_financial_period']}}</td>
            <td class="darkest font-pj text-v-base pl-0">{{lastFinancialPeriod}}</td>
          </tr>
          <tr v-if="showLastFinancialUpdate">
            <td class="darkest font-pj text-v-base pl-0">{{strings['last_financial_update']}}</td>
            <td class="darkest font-pj text-v-base pl-0">{{lastFinancialUpdate}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
/* eslint-enable */ 
  