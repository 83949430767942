/* eslint-disable */

/*---------------------------------------------------------------------------------------------------------/
// Color palette
/---------------------------------------------------------------------------------------------------------*/
export const Colors = {
  Green : "#0A7029",
  Yellow : "#FEDE00",
  YellowGreen : "#C8DF52",
  Mint : "#DBE8D8",
  ForestGreen : "#013A20",
  EmeraldGreen : "#478C5C", // #02894B
  LintGreen : "#BACC81",
  LimeGreen : "#32CD30",
  LimeGreenGray: '#a2d1a1',
  SeofarmGreen : "#B2D2A4",
  Orange : "#FBAA60",
  Orange2: '#f5841d',
  Orange2Gray: '#a8917b',
  Peach : "#FBC490",
  Coral : "#F67B50",
  Scarlet : "#A82810",
  Black : "#01161F",
  RedOrange : "#FD1700",
  DarkBlue: "#335abe",
  Blue1: "#0747a1",
  Blue2: "#1065c0",
  Blue3: "#1477d2",
  Blue4: "#1a8ae5",
  Blue4Gray: '#a4c4de',
  Blue5: "#1e97f3",
  Blue6: "#41a7f5",
  Blue7: "#64b7f6",
  Blue8: "#90cbf9",
  Blue9: "#bbdffb",
  BlueGray: '#7393B3',
  Violet: '#9850f6',
  VioletGray: '#d4baf5',
  Gray: '#c0c1c2',
  LightGray: '#e6e6e6', 
  White: '#ffffff',
  Black: '#000000',
  GradientRed: '#ff0000',
  GradientOrange: '#ffa700',
  GradientYellow: '#fff400',
  GradientLightGreen: '#a3ff00',
  GradientGreen: '#2cba00',
  GradientRedLight: '#FF9E6E',
  GradientOrangeLight: '#FFFF89',
  GradientYellowLight: '#FFFF9B',
  GradientLightGreenLight: '#FFFF9B', //E0FF5E
  GradientGreenLight: '#B0FF86',
  GreenOk: '#2cba00',
  GreenOKLight: '#90ff50',
  LaunchDark: '#0b275b', // '#001d2f',
  PastelLightGreen: '#e1ffcd',
  PastelGreen: '#cbf2c3',
  PastelYellow: '#fff8b9',
  PastelLightYellow: '#feffd7',
  PastelLightOrange: '#ffe7d1',
  PastelLightRed: '#ffd6ca',
  PrimaryLight: '#81cd29',
};

/*---------------------------------------------------------------------------------------------------------/
// Helper function to adjust the color
/---------------------------------------------------------------------------------------------------------*/
export function adjustColor(color, amount) {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
};

/*---------------------------------------------------------------------------------------------------------/
// Helper function to interpolate 2 colors
/---------------------------------------------------------------------------------------------------------*/
export function interpolateColors(colorA, colorB, amount) { 

  let ah = parseInt(colorA.replace(/#/g, ''), 16);
  let ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff;
  let bh = parseInt(colorB.replace(/#/g, ''), 16);
  let br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff;
  let rr = ar + amount * (br - ar);
  let rg = ag + amount * (bg - ag);
  let rb = ab + amount * (bb - ab);
  return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
};


/* eslint-enable */

/*
module.exports.Colors = Colors;
module.exports.adjustColor = adjustColor;
module.exports.interpolateColors = interpolateColors;
*/

