
<script>
import { sessionManager } from '@/main';

export default {
  name: 'Imprint',
  props: {
    email: String,
    code: String,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Imprint',
        },
        de: {
          title: 'Impressum',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>

        <div v-if="language == 'de'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Impressum
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Betreiber der Website:
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Peter Wey
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Flüehügel 2
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            5436 Würenlos, Schweiz
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            EMail: info@valuetion.com
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Tel: +41 76 372 08 00
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Haftungsausschluss:
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Externe Daten und Richtigkeit der Informationen
          </div>
          <div class="darkest text-v-sm font-pj">
            Die auf dieser Website präsentierten Finanzdaten stammen von einem externen Datenanbieter: Financial Modeling Prep. Wir haben keinen Einfluss auf die Qualität der Daten und übernehmen keine Gewähr für deren Richtigkeit oder Aktualität. Fehler können sowohl durch den Datenanbieter als auch durch uns selbst entstehen. Daher lehnen wir jegliche Haftung für Schäden, die aus der Nutzung dieser Informationen entstehen, ab.
            Wir übernehmen keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen. Haftungsansprüche gegen den Betreiber der Website wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Keine Anlageberatung oder Aufforderung zum Handeln
          </div>
          <div class="darkest text-v-sm font-pj">
            Die auf unserer Website dargestellten Finanzdaten von börsennotierten Unternehmen, einschließlich Firmenbewertungen, dienen ausschließlich Informationszwecken und stellen keine Anlageberatung oder Aufforderung zum Kauf oder Verkauf von Wertpapieren dar. Jeder Nutzer ist selbst dafür verantwortlich, seine eigenen Schlussfolgerungen aus den bereitgestellten Informationen zu ziehen und sich gegebenenfalls an einen professionellen Finanzberater zu wenden. Wir empfehlen vor einer Investitionsentscheidung die Konsultation der Investor Relations Website des entsprechenden Unternehmens zu besuchen und die Finanzdaten zu überprüfen.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Haftungsbeschränkung
          </div>
          <div class="darkest text-v-sm font-pj">
            Wir übernehmen keine Haftung für Schäden, die durch Investitionsentscheidungen auf der Grundlage der auf unserer Website bereitgestellten Informationen entstehen. Jeder Nutzer handelt auf eigenes Risiko und sollte sich vor einer Investition professionellen Rat einholen.
          </div>
          <div class="darkest text-v-sm font-pj">
            Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs.
            Es wird jegliche Verantwortung für solche Webseiten abgelehnt.
            Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Änderungen und Aktualisierungen
          </div>
          <div class="darkest text-v-sm font-pj">
            Wir behalten uns das Recht vor, das Impressum jederzeit zu ändern oder zu aktualisieren. Die jeweils aktuelle Version ist auf unserer Website einsehbar.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Anwendbares Recht und Gerichtsstand
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Die Rechtsbeziehung zwischen dem Nutzer und Valuetion untersteht ausschliesslich schweizerischem Recht.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Ausschliesslicher Gerichtsstand für sämtliche Streitigkeiten zwischen dem Nutzer und Valuetion ist der Wohnort des Betreibers oder ein von Valuetion gewählter Gerichtsstand in der Schweiz.
          </div>

          <div class="darkest text-v-sm font-pj pb-v-xl pt-v-xl">
            Datum: 31. Juli 2023
          </div>

        </div>



        <div v-else>
          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Imprint
          </div>

          <div class="darkest text-v-sm font-pj pb-v-sm">
            Operator of the website:
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Peter Wey
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Flüehügel 2
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            5436 Würenlos, Schweiz
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            EMail: info@valuetion.com
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Tel: +41 76 372 08 00
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Disclaimer:
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            External Data and Accuracy of Information
          </div>
          <div class="darkest text-v-sm font-pj">
            The financial data presented on this website is provided by an external data provider: Financial Modeling Prep. We have no control over the quality of the data and assume no responsibility for its accuracy or timeliness. Errors may occur both by the data provider and by ourselves. Therefore, we disclaim any liability for damages arising from the use of this information.
            The author takes no responsibility for the correctness, accuracy, timeliness, reliability and completeness of the informations of this website.
            Liability claims against the author due to material or immaterial damage caused by the use or disuse of the provided information and / or by the use of incorrect and incomplete information are generally excluded.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            No Investment Advice or Solicitation to Act
          </div>
          <div class="darkest text-v-sm font-pj">
            The financial data of publicly traded companies displayed on our website, including company valuations, is for informational purposes only and does not constitute investment advice or an invitation to buy or sell securities. Each user is responsible for drawing their own conclusions from the provided information and, if necessary, consulting a professional financial advisor. Before making an investment decision, we recommend consulting the investor relations website of the relevant company and reviewing the financial data.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Limitation of Liability
          </div>
          <div class="darkest text-v-sm font-pj">
            We assume no liability for damages resulting from investment decisions based on the information provided on our website. Each user acts at their own risk and should seek professional advice before making an investment.
          </div>
          <div class="darkest text-v-sm font-pj">
            References and links to third-party websites are outside our area of responsibility.
            Any responsibility for such websites is declined.
            Access to and use of such websites is at the user's own risk.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Changes and Updates
          </div>
          <div class="darkest text-v-sm font-pj">
            We reserve the right to change or update the imprint at any time. The current version can always be viewed on our website.
          </div>
          
          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Applicable law and place of jurisdiction
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            The legal relationship between the user and Valuetion is subject exclusively to Swiss law.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            The exclusive place of jurisdiction for all disputes between the user and Valuetion is the place of residence of the operator or a place of jurisdiction in Switzerland chosen by Valuetion.
          </div>

          <div class="darkest text-v-sm font-pj pb-v-xl pt-v-xl">
            Date: July 31th 2023
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


