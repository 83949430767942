import { render, staticRenderFns } from "./tweet-generator.vue?vue&type=template&id=baa3aede"
import script from "./tweet-generator.vue?vue&type=script&lang=js"
export * from "./tweet-generator.vue?vue&type=script&lang=js"
import style0 from "./tweet-generator.vue?vue&type=style&index=0&id=baa3aede&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports