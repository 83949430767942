/* eslint-disable */
<script>
import { formatPercent, getInterpolatedColor } from '../helper';
import { sessionManager } from '@/main';
import { Colors } from '../colors.js';

import CompanyGrowthInfo from '@/components/company-growth-info.vue';

export default {
  name: 'CompanyGrowth',
  props: {
    moatData: {
      required: true,
      default: () => null,
    },
    logo: {
      type: Boolean,
      default: () => false
    },
    logoText: {
      type: String,
      default: () => ''
    },
  },
  components: {
    CompanyGrowthInfo,
  },
  data() {
    return {
      textAlign: 'center',
      whiteSpace: 'nowrap',
      padding: '2px',
      languageStrings: {
        en: {
          title: 'Moat',
          subtitle: 'Company Growth',
          book: 'Book Value + Dividends',
          earnings: 'Earnings',
          cashflow: 'Operating Cashflow',
          revenue: 'Revenue',
          years: 'years',
          help: 'More information'
        },
        de: {
          title: 'Burggraben',
          subtitle: 'Wachstum',
          book: 'Buchwert + Dividenden',
          earnings: 'Gewinn',
          cashflow: 'Operativer Cashflow',
          revenue: 'Umsatz',
          years: 'Jahre',
          help: 'Mehr Informationen'
        }
      },
      language: '',
      showPopup: false,
      canAccess: false,
    };
  },
  created() {
    this.language = sessionManager.read('language');
    this.canAccess = this.moatData.canAccess;
  },
  methods: {
    // compute the color for the growth value
    getBackgroundColor(growth) {
      return getInterpolatedColor(growth, -0.2, -0.1, -0.05, 0, 0.05, 0.1, 0.2);
    },
    // formats the display of the growth
    formatGrowth(start, end, growth) {
      if(start >= 0 && end >= 0)
      {
        // nornal growth
        return formatPercent(growth, 0);
      }
      // unable to compute
      return '-'
    },
    // formats the display of the growth
    growthColor(start, end, growth) {
      if(start >= 0 && end >= 0)
      {
        // nornal growth
        return this.getBackgroundColor(growth);
      }
      if(start >= 0 && end <= 0)
      {
        // bad
        return this.getBackgroundColor(-1);
      }
      if(start <= 0 && end >= 0)
      {
        // good
        return this.getBackgroundColor(1);
      }
      else
      {
        // both negative -> neutral
        return this.getBackgroundColor(0);
      }
    },
    closePopup() {
      this.showPopup = false;
    },
    gotoPremium()
    {
      //  we only forward to premium if the user can not access
      if(!this.canAccess)
      {
        const url = this.$router.resolve({ path: '/premium' }).href;
        window.open(url, '_blank');  
      }
    },
  },
  computed: {
    strings() {
      return this.languageStrings[this.language];
    },
    year0() {
      return this.moatData.year0;
    },
    year0Short() {
      return this.moatData.year0-2000;
    },
    year1() {
      return this.moatData.year1;
    },
    year3() {
      return this.moatData.year3;
    },
    year5() {
      return this.moatData.year5;
    },
    year10() {
      return this.moatData.year10;
    },
    bookValueAndDividendsGrowth10Formatted() {
      return this.formatGrowth(this.moatData.bookValueAndDividendsGrowth10Start, 
        this.moatData.bookValueAndDividendsGrowth10End, 
        this.moatData.bookValueAndDividendsGrowth10);
    },
    bookValueAndDividendsGrowth10Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.bookValueAndDividendsGrowth10Start, 
        this.moatData.bookValueAndDividendsGrowth10End, 
        this.moatData.bookValueAndDividendsGrowth10);
    },
    bookValueAndDividendsGrowth5Formatted() {
      return this.formatGrowth(this.moatData.bookValueAndDividendsGrowth5Start, 
        this.moatData.bookValueAndDividendsGrowth5End, 
        this.moatData.bookValueAndDividendsGrowth5);
    },
    bookValueAndDividendsGrowth5Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.bookValueAndDividendsGrowth5Start, 
        this.moatData.bookValueAndDividendsGrowth5End, 
        this.moatData.bookValueAndDividendsGrowth5);
    },
    bookValueAndDividendsGrowth3Formatted() {
      return this.formatGrowth(this.moatData.bookValueAndDividendsGrowth3Start, 
        this.moatData.bookValueAndDividendsGrowth3End, 
        this.moatData.bookValueAndDividendsGrowth3);
    },
    bookValueAndDividendsGrowth3Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.bookValueAndDividendsGrowth3Start, 
        this.moatData.bookValueAndDividendsGrowth3End, 
        this.moatData.bookValueAndDividendsGrowth3);
    },
    bookValueAndDividendsGrowth1Formatted() {
      return this.formatGrowth(this.moatData.bookValueAndDividendsGrowthTTMStart, 
        this.moatData.bookValueAndDividendsGrowthTTMEnd, 
        this.moatData.bookValueAndDividendsGrowthTTM);
    },
    bookValueAndDividendsGrowth1Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.bookValueAndDividendsGrowthTTMStart, 
        this.moatData.bookValueAndDividendsGrowthTTMEnd, 
        this.moatData.bookValueAndDividendsGrowthTTM);
    },
    earningsGrowth10Formatted() {
      return this.formatGrowth(this.moatData.earningsGrowth10Start, 
        this.moatData.earningsGrowth10End, 
        this.moatData.earningsGrowth10);
    },
    earningsGrowth10Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.earningsGrowth10Start, 
        this.moatData.earningsGrowth10End, 
        this.moatData.earningsGrowth10);
    },
    earningsGrowth5Formatted() {
      return this.formatGrowth(this.moatData.earningsGrowth5Start, 
        this.moatData.earningsGrowth5End, 
        this.moatData.earningsGrowth5);
    },
    earningsGrowth5Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.earningsGrowth5Start, 
        this.moatData.earningsGrowth5End, 
        this.moatData.earningsGrowth5);
    },
    earningsGrowth3Formatted() {
      return this.formatGrowth(this.moatData.earningsGrowth3Start, 
        this.moatData.earningsGrowth3End, 
        this.moatData.earningsGrowth3);
    },
    earningsGrowth3Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.earningsGrowth3Start, 
        this.moatData.earningsGrowth3End, 
        this.moatData.earningsGrowth3);
    },
    earningsGrowth1Formatted() {
      return this.formatGrowth(this.moatData.earningsGrowthTTMStart, 
        this.moatData.earningsGrowthTTMEnd, 
        this.moatData.earningsGrowthTTM);
    },
    earningsGrowth1Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.earningsGrowthTTMStart, 
        this.moatData.earningsGrowthTTMEnd, 
        this.moatData.earningsGrowthTTM);
    },
    operatingCashflowGrowth10Formatted() {
      return this.formatGrowth(this.moatData.operatingCashflowGrowth10Start, 
        this.moatData.operatingCashflowGrowth10End, 
        this.moatData.operatingCashflowGrowth10);
    },
    operatingCashflowGrowth10Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.operatingCashflowGrowth10Start, 
        this.moatData.operatingCashflowGrowth10End, 
        this.moatData.operatingCashflowGrowth10);
    },
    operatingCashflowGrowth5Formatted() {
      return this.formatGrowth(this.moatData.operatingCashflowGrowth5Start, 
        this.moatData.operatingCashflowGrowth5End, 
        this.moatData.operatingCashflowGrowth5);
    },
    operatingCashflowGrowth5Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.operatingCashflowGrowth5Start, 
        this.moatData.operatingCashflowGrowth5End, 
        this.moatData.operatingCashflowGrowth5);
    },
    operatingCashflowGrowth3Formatted() {
      return this.formatGrowth(this.moatData.operatingCashflowGrowth3Start, 
        this.moatData.operatingCashflowGrowth3End, 
        this.moatData.operatingCashflowGrowth3);
    },
    operatingCashflowGrowth3Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.operatingCashflowGrowth3Start, 
        this.moatData.operatingCashflowGrowth3End, 
        this.moatData.operatingCashflowGrowth3);
    },
    operatingCashflowGrowth1Formatted() {
      return this.formatGrowth(this.moatData.operatingCashflowGrowthTTMStart, 
        this.moatData.operatingCashflowGrowthTTMEnd, 
        this.moatData.operatingCashflowGrowthTTM);
    },
    operatingCashflowGrowth1Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.operatingCashflowGrowthTTMStart, 
        this.moatData.operatingCashflowGrowthTTMEnd, 
        this.moatData.operatingCashflowGrowthTTM);
    },
    revenueGrowth10Formatted() {
      return this.formatGrowth(this.moatData.revenueGrowth10Start, 
        this.moatData.revenueGrowth10End, 
        this.moatData.revenueGrowth10);
    },
    revenueGrowth10Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.revenueGrowth10Start, 
        this.moatData.revenueGrowth10End, 
        this.moatData.revenueGrowth10);
    },
    revenueGrowth5Formatted() {
      return this.formatGrowth(this.moatData.revenueGrowth5Start, 
        this.moatData.revenueGrowth5End, 
        this.moatData.revenueGrowth5);
    },
    revenueGrowth5Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.revenueGrowth5Start, 
        this.moatData.revenueGrowth5End, 
        this.moatData.revenueGrowth5);
    },
    revenueGrowth3Formatted() {
      return this.formatGrowth(this.moatData.revenueGrowth3Start, 
        this.moatData.revenueGrowth3End, 
        this.moatData.revenueGrowth3);
    },
    revenueGrowth3Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.revenueGrowth3Start, 
        this.moatData.revenueGrowth3End, 
        this.moatData.revenueGrowth3);
    },
    revenueGrowth1Formatted() {
      return this.formatGrowth(this.moatData.revenueGrowthTTMStart, 
        this.moatData.revenueGrowthTTMEnd, 
        this.moatData.revenueGrowthTTM);
    },
    revenueGrowth1Formatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.growthColor(this.moatData.revenueGrowthTTMStart, 
        this.moatData.revenueGrowthTTMEnd, 
        this.moatData.revenueGrowthTTM);
    },
  }
}
</script>

<style scoped>

.th {
    font-size: 16px !important; 
    font-family: Plus Jakarta Sans;  
  }
  
  .componentroot {
    filter: blur(5px);
    cursor: pointer;
  }

</style>

<template>
  <div v-if="moatData" :class="[{ componentroot: !canAccess }]" @click="gotoPremium()">
    <div class="flex">
      <div class="flex-auto">
        <h3 v-if="logoText != ''" class="darkest font-pj text-v-xl text-bold">{{ logoText }}</h3>
        <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">{{strings['title']}}</h3>
      </div>
      <div class="flex-none" v-if="!logo">
        <p style="text-align:right;">
          <span class="px-v-sm">
            <v-tooltip bottom color="primary" light>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="showPopup = true">mdi-help-circle-outline</v-icon>
              </template>
              <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
            </v-tooltip>
          </span>
        </p>
      </div>
    </div>

    <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['subtitle'] }}</div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <th v-if="moatData.hasYear10" class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">Ø 10 {{ strings['years']}}<br>{{ year10 }}-{{ year0Short }}</th>
            <th v-if="moatData.hasYear5" class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">Ø 5 {{ strings['years']}}<br>{{ year5 }}-{{ year0Short }}</th>
            <th v-if="moatData.hasYear3" class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">Ø 3 {{ strings['years']}}<br>{{ year3 }}-{{ year0Short }}</th>
            <th v-if="moatData.hasTTM" class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">Ø 1 {{ strings['years']}}<br>TTM</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['revenue']}}</td>
            <td v-if="moatData.hasYear10" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': revenueGrowth10Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ revenueGrowth10Formatted }}</td>
            <td v-if="moatData.hasYear5" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': revenueGrowth5Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ revenueGrowth5Formatted }}</td>
            <td v-if="moatData.hasYear3" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': revenueGrowth3Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ revenueGrowth3Formatted }}</td>
            <td v-if="moatData.hasTTM" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': revenueGrowth1Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ revenueGrowth1Formatted }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['earnings']}}</td>
            <td v-if="moatData.hasYear10" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': earningsGrowth10Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ earningsGrowth10Formatted }}</td>
            <td v-if="moatData.hasYear5" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': earningsGrowth5Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ earningsGrowth5Formatted }}</td>
            <td v-if="moatData.hasYear3" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': earningsGrowth3Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ earningsGrowth3Formatted }}</td>
            <td v-if="moatData.hasTTM" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': earningsGrowth1Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ earningsGrowth1Formatted }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['cashflow']}}</td>
            <td v-if="moatData.hasYear10" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': operatingCashflowGrowth10Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ operatingCashflowGrowth10Formatted }}</td>
            <td v-if="moatData.hasYear5" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': operatingCashflowGrowth5Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ operatingCashflowGrowth5Formatted }}</td>
            <td v-if="moatData.hasYear3" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': operatingCashflowGrowth3Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ operatingCashflowGrowth3Formatted }}</td>
            <td v-if="moatData.hasTTM" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': operatingCashflowGrowth1Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ operatingCashflowGrowth1Formatted }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['book']}}</td>
            <td v-if="moatData.hasYear10" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': bookValueAndDividendsGrowth10Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ bookValueAndDividendsGrowth10Formatted }}</td>
            <td v-if="moatData.hasYear5" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': bookValueAndDividendsGrowth5Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ bookValueAndDividendsGrowth5Formatted }}</td>
            <td v-if="moatData.hasYear3" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': bookValueAndDividendsGrowth3Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ bookValueAndDividendsGrowth3Formatted }}</td>
            <td v-if="moatData.hasTTM" class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': bookValueAndDividendsGrowth1Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ bookValueAndDividendsGrowth1Formatted }}</td>
          </tr>
          
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="logo">
      <div class="flex justify-end">
        <div class="flex-none text-v-base font-pj py-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none text-v-base font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
      </div>
    </div>
    <CompanyGrowthInfo :onClose="closePopup" :showPopup="showPopup"/>
  </div>
</template>

/* eslint-enable */ 