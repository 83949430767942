
<script>
import { sessionManager } from '@/main';

export default {
  name: 'WaitlistVerification',
  props: {
    email: String,
    code: String,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'You are now on the waitlist!',
          headline: 'Thank you for confirming your email address!',
          text: 'You will be notified as soon as Valuetion becomes available for you.',
          text_thank_you: 'Thank you for your interest in valuetion.com!',
        },
        de: {
          title: 'Du bist jetzt auf der Warteliste!',
          headline: 'Vielen Dank für die Bestätigung deiner E-Mail-Adresse!',
          text: 'Du erhältst eine Benachrichtigung, sobald Valuetion für Dich verfügbar ist.',
          text_thank_you: 'Wir danken Dir für Dein Interesse an valuetion.com!',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
    this.fetchData();
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    async fetchData () {
      try {
        const url = '/api/HttpTriggerVerifyWaitlist';
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.email, 
            verificationCode: this.code,
          }),
        }).then((response) => {
        });        
      }
      catch (error) {
        console.log(error);
      }
    },
  }, 
}
</script>

<style>

  .font_white_big {
    color: white;
    font-size: 2.5rem;
  }

</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
          <p class="darkest text-v-base font-pj">{{ strings['text'] }}</p>
          <p class="darkest text-v-base font-pj">{{ strings['text_thank_you'] }}</p>
      </div>      
    </div>
  </div>
</template>


