
<script>

import { sessionManager } from '@/main';
import { eventBus } from '@/main'
import { logUserEvent } from '../helper';

export default {
  name: 'LegalDisclaimer',
  data() {
    return {
      dialog: true,
      acceptedPrivacy: false,
      acceptedTerms: false,
      rules: {
        required: value => !!value || 'This field is required.'
      },
      languageStrings: {
        en: {
          title: 'Legal Disclaimer',
          info: 'Before you can access our website, please review and accept our Privacy & Cookie Policy and our Terms & Conditions.',
          fineprint: 'By using this website, you agree to our ',
          fineprint_link_privacy: 'privacy policy',
          fineprint_link_terms: 'terms of service',
          fineprint_2: '.',
          accept: 'I Accept',
        },
        de: {
          title: 'Rechtlicher Hinweis',
          info: 'Bevor Du auf unsere Website zugreifen kannst, überprüfe und akzeptiere bitte die Datenschutzbestimmungen und die Allgemeinen Geschäftsbedingungen.',
          fineprint: 'Mit der Verwendung dieser Webiste erklärst Du Dich mit den ',
          fineprint_link_privacy: 'Datenschutzbestimmungen',
          fineprint_link_terms: 'Allgemeinen Geschäftsbedingungen',
          fineprint_2: ' einverstanden.',
          accept: 'Akzeptieren',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');

    
  },
  computed: {
    formValid() {
      return this.acceptedPrivacy && this.acceptedTerms;
    },
    strings() {
        return this.languageStrings[this.language];
    }
  },
  methods: {
    acceptDisclaimer() {
      sessionManager.setDisclaimerAccepted(true);
      sessionManager.setCookiesAllowed(true);
      this.dialog = false;
      eventBus.$emit('legalDisclaimerDecided');

      logUserEvent('legalDisclaimerAccepted', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
    },
    navigateToPrivacy() {
      const route = this.$router.resolve({
        name: 'privacy',
      });
      window.open(route.href, '_blank');
    },
    navigateToTerms() {
      const route = this.$router.resolve({
        name: 'terms',
      });
      window.open(route.href, '_blank');
    }
  },
  mounted() {
    if(sessionManager.getDisclaimerAccepted() || sessionManager.isLoggedin()) {
      this.dialog = false;
    }
    else
    {
      logUserEvent('legalDisclaimerShown', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
    }
  }
};
</script>


<style scoped>
</style>


<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <div class="px-v-md pt-v-md">
        <h3 class="darkest font-pj text-v-xl text-bold">{{strings['title']}}</h3>
      </div>
      <div class="px-v-md pb-v-md">
        <div class="darkest text-v-sm font-pj py-v-md">{{ strings['info'] }}</div>
        <v-checkbox class="text-v-md font-pj pb-0" v-model="acceptedPrivacy" dense>
          <template v-slot:label>
            <div class="flex flex-wrap">
              <span class="darkest pr-1 flex-wrap">{{ strings['fineprint'] }}</span>
              <span class="text-primary flex-wrap" @click.prevent="navigateToPrivacy" style="cursor: pointer; text-decoration: underline;">{{ strings['fineprint_link_privacy'] }}</span>
              <span class="darkest pl-1 flex-wrap">{{ strings['fineprint_2'] }}</span>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox class="text-v-md font-pj pt-0" v-model="acceptedTerms" dense>
          <template v-slot:label>
            <div class="flex flex-wrap">
              <span class="darkest pr-1 flex-wrap">{{ strings['fineprint'] }}</span>
              <span class="text-primary flex-wrap" @click.prevent="navigateToTerms" style="cursor: pointer; text-decoration: underline;">{{ strings['fineprint_link_terms'] }}</span>
              <span class="darkest pl-1 flex-wrap">{{ strings['fineprint_2'] }}</span>
            </div>
          </template> 
        </v-checkbox>
      </div>
      <div class="px-v-md pb-v-md">
        <v-row justify="end">
          <v-col class="d-flex justify-end">
            <v-btn :disabled="!formValid" color="primary" @click="acceptDisclaimer">{{ strings['accept'] }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>