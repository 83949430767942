<script>
/* eslint-disable */
import {
  formatRatio,
  formatUnitOfAccount,
  formatMillions,
} from '../helper';
import GraphThumbnail from '@/components/graph-thumbnail.vue';
import { sessionManager } from '@/main';
import { getYearAndQuarter, getYear
 } from '../helper';
import { logUserEvent } from '../helper';
import { getTranslation } from '../translations';


export default {
  name: 'CompanyFinancialsIncome',
  components: {
    GraphThumbnail,
},
  props: {
    income: {
      type: Object,
      required: true,
      default: () => null,
    },
    reportedCurrency: {
      type: String,
      required: true,
      default: () => '',
    },
    unitOfAccount: {
      type: String,
      required: true,
      default: () => 'USD',
    },
    slider: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  data() {
    return {
      incomeHeaders: null,
      incomeItems: null,
      languageStrings: {
        en: {
          title: 'Income Statement',
          ttm: 'TTM',
        },
        de: {
          title: 'Gewinn- und Verlustrechnung',
          ttm: 'letzte 12 Monate',
        }
      },
      language: '',
      maxLength: 15,
    };
  },
  created() {
    this.language = sessionManager.read('language');
    logUserEvent('companyFinancials', sessionManager, this.$vuetify.breakpoint.mobile);
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    row_classes(item) {
      let classes = 'darkest font-pj text-v-sm pl-0';
      if (item.bold) {
        return classes + ' bg-light-yellow';
      }
      if (item.strong) {
        return classes + ' bg-very-light-yellow';
      }
      return classes;
    },
    processElement(incomeQuarter, incomeAnnual, incomeTTM, element, propertyName, hasTTM) {
      for(let i = 0; i < 4; i++)
      {
        if(incomeQuarter.length > i)
        {
          let quarter = getYearAndQuarter(this.getDate(incomeQuarter[i]));
          element[quarter.toString()] = formatUnitOfAccount(incomeQuarter[i][propertyName], this.unitOfAccount);
        }
      }
      if(this.income.hasTTM) 
      {
        element['ttm'] = formatUnitOfAccount(incomeTTM[propertyName], this.unitOfAccount);
      }  
      for (let j = 0; j < incomeAnnual.length && j < this.maxLength; j += 1) { 
        let year = getYear(this.getDate(incomeAnnual[j]));
        element[year.toString()] = formatUnitOfAccount(incomeAnnual[j][propertyName], this.unitOfAccount);
        element['graph'].push(incomeAnnual[j][propertyName]);
      }
    },
    addLine(propertyName, bold, strong, incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM)
    {
      let element = {};
      element.Line = getTranslation(propertyName, this.language);
      element.bold = bold;
      element.strong = strong;
      element['graph'] = [];
      this.processElement(incomeQuarter, incomeAnnual, incomeTTM, element, propertyName, hasTTM);
      incomeItems.push(element);
    },
    getDate(obj) {
      return obj.date !== undefined ? obj.date : obj.period;
    },
    createHeaders()
    {
      var hasTTM = this.income.hasTTM;

      const incomeAnnual = this.income.incomeAnnual;
      const incomeQuarter = this.income.incomeQuarter;
      const incomeTTM = this.income.incomeTTM;

       /* header */
       this.incomeHeaders.push({
        text: '',
        sortable: false,
        value: 'Line',
        width: '180px',
      });

      var offset = 0;

      // first we show the last quarters
      for(let i = 0; i < 4; i++)
      {
        if(incomeQuarter.length > i)
        {
          let quarter = getYearAndQuarter(this.getDate(incomeQuarter[i]));
          this.incomeHeaders.push({
            text: quarter,
            sortable: false,
            value: quarter.toString(),
            width: '120px',
            align: 'right',
          });
          offset += 1;
        }
      }

      // then we show the trailing twelve months
      if(hasTTM)
      {
        this.incomeHeaders.push({      
          text: this.strings['ttm'],
          sortable: false,
          value: 'ttm',
          width: '140px',
          align: 'right',
        });
        offset += 1;
      }     

      // then we show the graph
      this.incomeHeaders.push({      
        text: '',
        sortable: false,
        value: 'graph',
        width: '100px',
        align: 'right',
      });

      // then we show all the years
      for (let i = 0; i < incomeAnnual.length && i < this.maxLength; i += 1) {
        let year = getYear(this.getDate(incomeAnnual[i]));
        this.incomeHeaders.push({
          text: getYear(this.getDate(incomeAnnual[i])),
          sortable: false,
          value: year.toString(),
          width: '120px',
          align: 'right',
        });
      }
    },
    updateFinancials()
    {
      // Todo:: is this the right place to do this?
      this.currentCurrency = this.unitOfAccount;
      const income = this.income.incomeQuarter[0];

      if(this.income.type == "FMP")
      {
        // we have a fmp income statement
        this.updateFinancialsFMP();
      }
      else
      {
        // we have a finnhub income statement and we need to check the type of the finnhub income statement
        if(income.bankInterestIncome != 0 || income.bankNonInterestExpense != 0 || income.bankNonInterestIncome != 0)
        {
          this.updateFinancialsFHBank();
        }
        else if(income.benefitsClaimsLossAdjustment != 0 || income.costOfGoodsSold == 0 || income.deferredPolicyAcquisitionExpense != 0)
        {
          this.updateFinancialsFHInsurance();
        }
        else
        {
          this.updateFinancialsFHNormal();
        }
      }
    },
    updateFinancialsFHBank ()
    {
      this.incomeHeaders = [];
      this.incomeItems = [];
      var hasTTM = this.income.hasTTM;
      const incomeAnnual = this.income.incomeAnnual;
      const incomeQuarter = this.income.incomeQuarter;
      const incomeTTM = this.income.incomeTTM;

      this.createHeaders();

      // Interest income & expense
      this.addLine('bankInterestIncome', true, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('interestExpense', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('loanLossProvision', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('netInterestIncAfterLoanLossProv', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);


      // non interest income & expense
      this.addLine('bankNonInterestIncome', true, false, this.incomeItems, incomeQuarter, incomeAnnual,  incomeTTM, hasTTM);
      this.addLine('bankNonInterestExpense', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
    
      // sum
      this.addLine('pretaxIncome', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('provisionforIncomeTaxes', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      
      this.addLine('netIncomeAfterTaxes', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('minorityInterest', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('equityEarningsAffiliates', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);

      this.addLine('netIncome', true, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
 
    },
    updateFinancialsFHInsurance ()
    {
      this.incomeHeaders = [];
      this.incomeItems = [];
      var hasTTM = this.income.hasTTM;
      const incomeAnnual = this.income.incomeAnnual;
      const incomeQuarter = this.income.incomeQuarter;
      const incomeTTM = this.income.incomeTTM;

      this.createHeaders();

      this.addLine('revenue', true, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      
      // operating expense
      this.addLine('benefitsClaimsLossAdjustment', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('deferredPolicyAcquisitionExpense', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('sgaExpense', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('otherOperatingExpensesTotal', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);

      this.addLine('ebit', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('interestIncomeExpense', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('nonRecurringItems', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('gainLossOnDispositionOfAssets', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('totalOtherIncomeExpenseNet', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);

      this.addLine('pretaxIncome', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('provisionforIncomeTaxes', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      
      this.addLine('netIncomeAfterTaxes', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('minorityInterest', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('equityEarningsAffiliates', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);

      this.addLine('netIncome', true, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);

    },
    updateFinancialsFHNormal() 
    {
      this.incomeHeaders = [];
      this.incomeItems = [];
      var hasTTM = this.income.hasTTM;
      const incomeAnnual = this.income.incomeAnnual;
      const incomeQuarter = this.income.incomeQuarter;
      const incomeTTM = this.income.incomeTTM;

      this.createHeaders();

      this.addLine('revenue', true, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('costOfGoodsSold', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('grossIncome', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);

      // operating expenses
      this.addLine('sgaExpense', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('researchDevelopment', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('depreciationAmortization', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('purchasedFuelPowerGas', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('operationsMaintenance', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('otherOperatingExpensesTotal', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('totalOperatingExpense', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);

      this.addLine('ebit', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('interestIncomeExpense', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('nonRecurringItems', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('gainLossOnDispositionOfAssets', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('totalOtherIncomeExpenseNet', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);

      this.addLine('pretaxIncome', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('provisionforIncomeTaxes', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      
      this.addLine('netIncomeAfterTaxes', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('minorityInterest', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('equityEarningsAffiliates', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);

      this.addLine('netIncome', true, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
    },
    updateFinancialsFMP() {
      this.incomeHeaders = [];
      this.incomeItems = [];
      var hasTTM = this.income.hasTTM;
      const incomeAnnual = this.income.incomeAnnual;
      const incomeQuarter = this.income.incomeQuarter;
      const incomeTTM = this.income.incomeTTM;

      this.createHeaders();
      this.addLine('revenue', true, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('costOfRevenue', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('grossProfit', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('researchAndDevelopmentExpenses', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('sellingGeneralAndAdministrativeExpenses', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('otherExpenses', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('operatingExpenses', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('operatingIncome', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('totalOtherIncomeExpensesNet', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('incomeBeforeTax', false, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('incomeTaxExpense', false, false, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
      this.addLine('netIncome', true, true, this.incomeItems, incomeQuarter, incomeAnnual, incomeTTM, hasTTM);
    },
  },
  watch: {
    unitOfAccount: {
      handler(val) {
        this.updateFinancials();
      },
    },
    annual: {
      handler(val) {
        this.updateFinancials();
      },
    },
  },
  beforeMount() {
    this.updateFinancials();
  },
};
</script>
/* eslint-enable */ 


<template>
  <div v-if="income" class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <h3 class="darkest font-pj text-v-xl text-bold pt-v-sm pb-v-md">{{strings['title']}}</h3> 

    <v-data-table
      dense
      :headers="incomeHeaders"
      :items="incomeItems"
      :items-per-page="50"
      :item-class="row_classes"
      :hide-default-footer="true"
      mobile-breakpoint="0"
    >
      <template v-slot:item.graph="{ item }">
        <GraphThumbnail v-bind:data="item.graph" v-bind:width="100" v-bind:height="18"/>
      </template>
    </v-data-table>
  </div>
</template>
