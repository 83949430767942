/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
 
  export default {
    name: 'CompanyDescription',
    props: {
      company: {
        type: Object,
        required: true,
        default: () => null,
      },
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Company Description',
            read_more: 'read more',
          },
          de: {
            title: 'Beschreibung',
            read_more: 'mehr lesen',
          }
        },
        language: '',
        isExpanded: false
      }
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
      isMobile() {
        return window.innerWidth <= 768;
      },
    },
  }
</script>

  
<template>
  <div v-if="company" class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <h3 class="darkest font-pj text-v-xl text-bold">{{strings['title']}}</h3>
    <p class="darkest font-pj text-v-sm" v-if="isMobile">{{ isExpanded ? company.description : company.description.substring(0, company.description.indexOf('.') + 1) + ' ...' }}<button @click="isExpanded = true" v-if="!isExpanded">{{ strings['read_more'] }}</button></p>
    <p class="darkest font-pj text-v-sm" v-else >{{ company.description }}</p>
  </div>
</template>
/* eslint-enable */ 
  