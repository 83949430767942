/* eslint-disable */

import { getYearAndQuarter, getYear} from '../helper';

/*---------------------------------------------------------------------------------------------------------/
// Helper function to get the difference in days between two dates
/---------------------------------------------------------------------------------------------------------*/
function getDifferenceInDays(date1, date2) 
{
    // Convert input dates to Date objects if they are not already
    const dateObj1 = new Date(date1);
    const dateObj2 = new Date(date2);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = Math.abs(dateObj2 - dateObj1);

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

    // Return the difference in days
    return differenceInDays;
}

/*---------------------------------------------------------------------------------------------------------/
// Helper function to update the graphs for the financials
/---------------------------------------------------------------------------------------------------------*/
export function updateCompanyFinancialsGraph({
    dataQuarter,
    dataTTM,
    dataAnnual,
    graphName,
    graphType, 
    hasTTM,
    createGraphFunction,
    fontSizeBase,
    isFH,
    strings,
    unitOfAccount,
    language,
    onlyTTM = false
  }) {

    var tabData = new Array();
    let uniqueId = 0;
    let fontSize = fontSizeBase;
    let fontSizeBig = 0.75 * fontSize;
    
    // Determine if the data points are half-year or quarter based on the date difference
    let startDate = new Date(isFH ? dataQuarter[0].period : dataQuarter[0].date);
    let endDate = new Date(startDate);
    // make the end date 11 months earlier
    // we do that instead of 1 year earlier to avoid issues with reporting dates that might be off a little bit
    endDate.setMonth(startDate.getMonth() - 11);

    // check if the quarters are quarters or half years
    let halfYear = false;
    if(dataQuarter.length > 1)
    {
        let date1 = new Date(isFH ? dataQuarter[0].period : dataQuarter[0].date);
        let date2 = new Date(isFH ? dataQuarter[1].period : dataQuarter[1].date);
        let difference = getDifferenceInDays(date1, date2);
        if(difference > 170 && difference < 190)
        {
            halfYear = true;
        }
    }

    // add the quarter data
    if(!onlyTTM)
    {
        let i = 0;
        while(i < dataQuarter.length)
        {
            let dateString = isFH ? dataQuarter[i].period : dataQuarter[i].date;
            let date = new Date(dateString);
            if(date <= endDate)
            {
                break;
            }

            const svgId = `${graphName}_quarter${dateString}`;
            tabData.push({
                key: svgId,
                title: getYearAndQuarter(date, halfYear),
                save_title: `${graphName} Quarter ${getYearAndQuarter(date, halfYear)}`,
                date: date,
                content_Small: createGraphFunction(uniqueId++, dataQuarter[i], graphType, unitOfAccount, language, svgId, fontSize),
                content_Big: createGraphFunction(uniqueId++, dataQuarter[i], graphType, unitOfAccount, language, svgId, fontSizeBig),
            }); 
            i++;
        }
    }
    

    // TTM
    if (hasTTM && dataTTM) {
        let date = isFH ? dataTTM.period : dataTTM.date;
        const svgId = `${graphName}TTM${date}`;
        tabData.push({
            key: svgId,
            title: strings['ttm'],
            save_title: `${graphName} TTM`,
            date: date,
            content_Small: createGraphFunction(uniqueId++, dataTTM, graphType, unitOfAccount, language, svgId, fontSize),
            content_Big: createGraphFunction(uniqueId++, dataTTM, graphType, unitOfAccount, language, svgId, fontSizeBig),
        });
    }

  
    // Annual
    if(!onlyTTM)
    {
        dataAnnual.forEach(entry => {
            let date = isFH ? entry.period : entry.date;
            const svgId = `${graphName}_annual${date}`;
            tabData.push({
              key: svgId,
              title: getYear(date),
              save_title: `${graphName} ${getYearAndQuarter(date)}`,
              date: date,
              content_Small: createGraphFunction(uniqueId++, entry, graphType, unitOfAccount, language, svgId, fontSize),
              content_Big: createGraphFunction(uniqueId++, entry, graphType, unitOfAccount, language, svgId, fontSizeBig),
            });
          });
    }
    
    return tabData;
  }


/* eslint-enable */