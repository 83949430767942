
<script>
import { sessionManager } from '@/main';

export default {
  name: 'vue-app/src/components/terms-and-conditions.vue',
  props: {
    email: String,
    code: String,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Terms and Conditions',
          headline: 'Terms and Conditions',
        },
        de: {
          title: 'Allgemeine Geschäftsbedingungen',
          headline: 'Allgemeine Geschäftsbedingungen',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>

        <div v-if="language == 'de'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            1. Präambel und Akzeptanz der Allgemeinen Geschäftsbedingungen (AGB)
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Valuetion ist eine von Peter Wey, Flüehügel 2, 5436 Würenlos, Schweiz (nachfolgend "Anbieter"), betriebene Website, die auf die Aufbereitung und grafische Darstellung von Finanzdaten spezialisiert ist. Diese Daten werden durch einen oder mehrere Drittanbieter (nachfolgend "Datenprovider") bereitgestellt. Die Website bietet sowohl kostenlose als auch kostenpflichtige Abonnement-Services (nachfolgend "Service") an, die Nutzern den Zugriff auf verschiedene Funktionen und Daten ermöglichen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Bitte lies die folgenden Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") sorgfältig durch. Mit der Nutzung der Website und der darauf angebotenen Dienstleistungen erklärst Du Dich mit diesen AGB vollumfänglich einverstanden. Solltest Du mit Teilen dieser AGB nicht einverstanden sein, ist Dir die Nutzung der Website und der darauf angebotenen Dienstleistungen nicht gestattet.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Valuetion ist kein lizenzierter Finanzberater und bietet keine Anlageberatung an. Die auf der Website bereitgestellten Informationen und Daten dienen ausschließlich zu Informationszwecken und sind nicht als finanzieller Ratschlag zu verstehen. Jeder Nutzer ist selbst dafür verantwortlich, wie er die bereitgestellten Informationen und Daten nutzt und interpretiert. Die Entscheidung und das Risiko für finanzielle Investitionen liegt ausschließlich beim Nutzer.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            2. Anwendungsbereich der AGB
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Diese AGB regeln das Vertragsverhältnis zwischen dem Anbieter, Peter Wey, Betreiber von Valuetion, und den natürlichen oder juristischen Personen (nachfolgend "Nutzer"), die die Dienstleistungen des Anbieters nutzen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) Die AGB gelten sowohl für die kostenlose als auch für die kostenpflichtige Nutzung der auf der Website des Anbieters bereitgestellten Dienstleistungen und Inhalte und sind für alle über diese Website abgeschlossenen Verträge maßgeblich.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) Sie gelten in ihrer zum Zeitpunkt des Vertragsschlusses gültigen Fassung. Abweichende Geschäftsbedingungen des Nutzers werden nicht anerkannt, es sei denn, der Anbieter stimmt ihrer Geltung ausdrücklich schriftlich zu.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Valuetion behält sich das Recht vor, diese AGB jederzeit zu ändern. Die geänderten Bedingungen werden den Nutzern mindestens 14 Tage vor ihrem Inkrafttreten bekannt gegeben. Wenn ein Nutzer den geänderten Bedingungen nicht innerhalb von 14 Tagen ab Bekanntgabe widerspricht, gelten diese als angenommen. Valuetion wird in der Bekanntgabe auf die Bedeutung dieser 14-Tage-Frist hinweisen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) Der Nutzer kann die jeweils gültige Version der AGB auf der Website des Anbieters einsehen.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            3. Vertragsübertragung und -änderung
          </div>

          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Der Nutzer erkennt an und stimmt zu, dass der gegenwärtige Vertrag zwischen dem Nutzer und dem Betreiber von Valuetion, Peter Wey, abgeschlossen wird.
          </div>

          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) Sollte Valuetion in der Zukunft in eine andere Rechtsform umgewandelt werden (beispielsweise in eine Gesellschaft mit beschränkter Haftung), so wird dieser Vertrag automatisch zwischen dem Nutzer und der neu gegründeten Rechtsform von Valuetion fortgesetzt. Alle Rechte und Pflichten aus diesem Vertrag übertragen sich in diesem Fall auf die neu gegründete Rechtsform.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            4. Vertragsabschluss
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Die Präsentation und Bewerbung von Dienstleistungen auf unserer Website stellt kein bindendes Angebot zum Abschluss eines Vertrages dar.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) Der Vertrag zwischen dem Nutzer und Valuetion kommt erst durch das Erstellen eines Nutzerkontos auf der Website von Valuetion zustande. Hierfür muss der Nutzer den auf der Website vorgegebenen Anmeldeprozess durchlaufen. Dies umfasst das Ausfüllen des Anmeldeformulars mit den erforderlichen Daten, die Annahme dieser Allgemeinen Geschäftsbedingungen und ggf. weiterer Nutzungsbedingungen durch Anklicken des entsprechenden Kästchens, sowie die Bestätigung der Anmeldung durch Anklicken des in der daraufhin von Valuetion versendeten Bestätigungs-E-Mail enthaltenen Links (Double-Opt-In-Verfahren).
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) Valuetion speichert den Vertragstext nach dem Vertragsschluss. Der Nutzer erhält eine Bestätigungsemail über den erfolgreichen Abschluss der Registrierung, die auch einen Link zu diesen Allgemeinen Geschäftsbedingungen enthält. Die AGB können jederzeit auch auf der Website von Valuetion eingesehen werden.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Der Nutzer ist verpflichtet, im Rahmen der Anmeldung wahrheitsgemäße, aktuelle und vollständige Angaben zu machen und diese bei Änderungen unverzüglich zu aktualisieren. Valuetion behält sich das Recht vor, Nutzerkonten, bei denen Verdacht auf Falschangaben besteht, zu löschen oder zu sperren.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) Jeder Nutzer darf nur ein Nutzerkonto erstellen und dieses nicht an Dritte übertragen. Der Nutzer ist für den vertraulichen Umgang mit seinen Zugangsdaten selbst verantwortlich. Bei Verdacht auf Missbrauch des Nutzerkontos durch Dritte ist Valuetion unverzüglich zu informieren.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            5. Nutzungsbedingungen und -einschränkungen
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion gewährt den Nutzern ein nicht-exklusives, nicht übertragbares und beschränktes Recht, die Website und die darauf zur Verfügung gestellten Daten (nachfolgend "Daten") für persönliche und nicht-kommerzielle Zwecke zu nutzen. Jede Nutzung der Daten für kommerzielle Zwecke ist ausdrücklich untersagt, sofern nicht ausdrücklich und schriftlich von Valuetion genehmigt.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) Die auf Valuetion publizierten Wirtschafts- und Finanzinformationen dienen ausschliesslich zu Informationszwecken und begründen weder eine Werbung, Empfehlung oder Beratung noch eine Aufforderung zum Erwerb oder Verkauf von Anlageinstrumenten oder zur Tätigung sonstiger Transaktionen. Valuetion gibt keinerlei Zusicherungen über die Zuverlässigkeit, Korrektheit oder Vollständigkeit der publizierten Informationen und Meinungen ab.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) Die von Valuetion verbreiteten Informationen und Meinungen werden ausschliesslich zum privaten Gebrauch bereitgestellt. Sie können jederzeit und ohne vorherige Ankündigung geändert oder gelöscht werden. Valuetion ist nicht verpflichtet, die Informationen stets aktuell zu halten.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Alle Inhalte (einschliesslich aller Texte, Daten, Grafiken, Logos, etc.) auf Valuetion sind urheberrechtlich geschützt. Sämtliche Rechte, Titel und Ansprüche daran (einschliesslich Marken, Patente, Urheberrechte und andere Rechte an geistigem Eigentum sowie sonstiger Rechte) verbleiben bei Valuetion bzw. dem jeweiligen Rechteinhaber. Die vollständige oder teilweise Reproduktion, Übermittlung, Modifikation, Verknüpfung oder Benutzung der Inhalte für öffentliche oder kommerzielle Zwecke ist ohne vorherige schriftliche Zustimmung von Valuetion untersagt.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) Die Nutzer dürfen keine Darstellungen oder Zusicherungen hinsichtlich Valuetion oder den Daten gegenüber Dritten (einschließlich, aber nicht beschränkt auf, potenzielle Kunden oder Endnutzer) machen, es sei denn, Valuetion hat dies vorher schriftlich genehmigt.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (6) Die Nutzer sind berechtigt, die Daten ausschließlich für den persönlichen Gebrauch zu nutzen. Jegliche Weitergabe, Weiterverkauf oder Lizenzierung der Daten an Dritte ist strengstens untersagt.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (7) Die Nutzer sind verpflichtet, sich jederzeit gesetzestreu zu verhalten und keine unlauteren oder irreführenden Geschäftspraktiken oder unethische Geschäftspraktiken zu betreiben.
          </div>


          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            6. Abonnementbedingungen und -gebühren
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion bietet einen kostenlosen Account (nachfolgend "Gratis-Account") und plant in Zukunft, ein kostenpflichtiges Premium-Abonnement (nachfolgend "Premium-Abo") anzubieten. Beide Kontotypen unterliegen diesen AGB. 
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) Der Gratis-Account ermöglicht den Zugang zu bestimmten Funktionen der Website, kann jedoch im Vergleich zum Premium-Abo Einschränkungen aufweisen. Der Nutzer kann jederzeit seinen Gratis-Account kündigen. Nach Einführung des Premium-Abos wird die Möglichkeit angeboten, vom Gratis-Account auf ein Premium-Abo umzusteigen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2a) Während der Beta-Testphase kann die Funktionalität der Website noch nicht vollständig sein. Valuetion behält sich das Recht vor, die Funktionen der Website jederzeit zu ändern oder zu erweitern, um die Leistungsfähigkeit und den Service zu verbessern.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) Nach Einführung des Premium-Abos bietet dieses einen vollständigen Zugang zu allen Funktionen und Features der Website. Die genauen Kosten für das Premium-Abo sowie die zur Verfügung stehenden Zahlungsmethoden werden auf der Website von Valuetion angegeben.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Die Gebühren für das Premium-Abo sind monatlich im Voraus über den ausgewählten Zahlungsdienstleister zu entrichten. Die Zahlung wird am Tag des Abschlusses des Premium-Abos fällig und anschließend monatlich zum jeweiligen Tag des Vertragsbeginns.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) Nach Einführung des Premium-Abos kann dieses jederzeit auf das Ende des nächsten Monats gekündigt werden. Nach der Kündigung wird das Premium-Abo in einen Gratis-Account umgewandelt, es sei denn, der Nutzer wählt eine vollständige Kündigung seines Accounts. Im Falle einer vollständigen Kündigung werden alle Nutzerdaten und -informationen gelöscht, es sei denn, es bestehen gesetzliche Aufbewahrungspflichten.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (6) Alle genannten Preise sind, sofern nicht anders angegeben, Endpreise inklusive der gesetzlichen Mehrwertsteuer.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (7) Valuetion behält sich das Recht vor, die Preise und die verfügbaren Funktionen der Abonnements jederzeit zu ändern. Änderungen werden dem Nutzer rechtzeitig mitgeteilt und haben keine Auswirkungen auf bereits abgeschlossene Abonnements bis zum Ende ihrer jeweiligen Laufzeit.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (8) Valuetion behält sich das Recht vor, den Umfang des Gratis-Accounts und/oder des Premium-Abos zu ändern, zusätzliche Abotypen hinzuzufügen oder bestehende Abotypen zu ändern. Änderungen werden dem Nutzer rechtzeitig mitgeteilt.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            7. Verfügbarkeit der Dienstleistungen
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion stellt die Website und die darauf angebotenen Dienstleistungen "wie sie sind" und "wie verfügbar" zur Verfügung. Dies bedeutet, dass Valuetion keine Garantie für die ununterbrochene, zeitgerechte, sichere oder fehlerfreie Bereitstellung der Website und der Dienstleistungen gibt. 
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) Insbesondere kann Valuetion nicht für technische Störungen, Ausfälle des Kommunikationsnetzes oder des Internets, Systemausfälle, oder sonstige Beeinträchtigungen der Verfügbarkeit der Website und der Dienstleistungen haftbar gemacht werden, die außerhalb des Einflussbereichs von Valuetion liegen, einschließlich, aber nicht beschränkt auf, Fehler Dritter, höhere Gewalt oder Angriffe gegen die Infrastruktur durch Hacker.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) Die Nutzung der Dienstleistungen setzt eine kompatible und leistungsfähige technische Ausstattung des Nutzers voraus. Valuetion übernimmt keine Gewährleistung für die Kompatibilität oder Leistungsfähigkeit der technischen Ausstattung des Nutzers.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Der Nutzer erkennt an, dass nicht jede Aktualisierung, Verbesserung oder sonstige Änderung der Website oder der Dienstleistungen bei jedem Nutzer gleich wirkt und dass Valuetion keine Verantwortung dafür übernimmt, wenn die Website oder die Dienstleistungen aufgrund der technischen Spezifikationen oder Einstellungen des Nutzers nicht ordnungsgemäß funktionieren.
          </div>


          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            7. Haftungsbeschränkungen und -ausschlüsse
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion stellt alle Inhalte und Dienstleistungen "wie sie sind" und "wie verfügbar" zur Verfügung. Valuetion und/oder seine Lieferanten lehnen ausdrücklich jede ausdrückliche oder stillschweigende Gewährleistung ab, einschließlich, aber nicht beschränkt auf die Gewährleistung der Marktgängigkeit, der Eignung für einen bestimmten Zweck und der Nichtverletzung von Rechten Dritter.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) Valuetion übernimmt insbesondere keine Gewähr für die Korrektheit, Vollständigkeit, Zuverlässigkeit, Aktualität oder Brauchbarkeit der auf der Website zur Verfügung gestellten Daten und Informationen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) Valuetion haftet nicht für Schäden, die durch die Nutzung oder Nichtnutzung der Website oder der auf der Website zur Verfügung gestellten Dienstleistungen entstehen. Dies gilt insbesondere für Schäden (einschließlich Folgeschäden), die durch Verlust von Gewinn, Geschäftsunterbrechung, Verlust von Programmen oder Daten in Ihrem Informationssystem oder sonstige Schäden entstehen. Dies gilt auch, wenn Valuetion ausdrücklich auf die Möglichkeit solcher Schäden hingewiesen wurde.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Valuetion haftet nicht für Fehler, Auslassungen, Unterbrechungen, Löschungen, Defekte, Verzögerungen im Betrieb oder der Übertragung, Ausfälle von Kommunikationsleitungen, Diebstahl oder Zerstörung oder unbefugten Zugriff auf oder Änderungen von Nutzerkommunikationen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) Die Haftungsbeschränkungen und -ausschlüsse gelten im Rahmen des geltenden Rechts, insbesondere des Schweizer Obligationenrechts.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (6) Die Dienstleistungen und Publikationen von Valuetion sind nicht für Personen bestimmt, die (aufgrund ihrer Nationalität, ihres Wohnsitzes oder aus anderen Gründen) Gesetzen unterstehen, welche den Zugang zu den Publikationen von Valuetion (egal aus welchen Gründen) verbieten. Personen, auf welche solche Einschränkungen zutreffen, ist der Zugriff auf die Publikationen von Valuetion nicht gestattet.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (7) Der Nutzer stellt Valuetion sowie ihre Organe und Mitarbeiter von jeglichen Rechtsstreitigkeiten, Schadenersatzforderungen und anderen Klagen und Ansprüchen frei, die Dritte im Zusammenhang mit den Inhalten oder das Verhalten des Nutzers einleiten oder geltend machen, samt den damit verbundenen Anwalts- und Gerichtskosten.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (8) Valuetion behält sich das Recht vor, den Umfang und die Art der auf der Website angebotenen Dienstleistungen jederzeit zu ändern, zu erweitern, zu begrenzen oder einzustellen. Die Nutzer haben keinen Anspruch darauf, dass die Website oder die Dienstleistungen in ihrer aktuellen Form aufrechterhalten werden. Valuetion ist berechtigt, den Betrieb der Website jederzeit ohne Vorankündigung ganz oder teilweise einzustellen. Eine Haftung von Valuetion für Schäden oder Folgeschäden, die sich aus der Einstellung des Betriebs der Website ergeben, ist ausgeschlossen.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            8. Vertraulichkeit und Datenschutz
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion verpflichtet sich, alle persönlichen Informationen und personenbezogenen Daten, die von den Nutzern bereitgestellt werden, vertraulich zu behandeln und diese Daten nur in Übereinstimmung mit den Datenschutzbestimmungen zu verwenden.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) Bei der Nutzung der Website werden verschiedene Arten von Daten erhoben und verarbeitet, wie in den Datenschutzbestimmungen von Valuetion dargelegt. Dazu gehören unter anderem E-Mail-Adresse, Name, Adresse, Telefonnummer, Cookies, Nutzungsdaten, Kommunikationsdaten, Vertragsdaten und Zahlungsdaten.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) Die Datenverarbeitung erfolgt zum Zweck der Betreibung der Website, für Marketingmaßnahmen und um unseren Service zu verbessern. Mit der Registrierung auf unserer Website oder der Erstellung eines Kontos stimmen Sie der Verarbeitung Ihrer Daten für diese Zwecke zu.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Ihre Daten werden unter Umständen an Dritte weitergegeben, wie in den Datenschutzbestimmungen ausführlich dargelegt. Diese Weitergabe erfolgt stets unter Beachtung der geltenden Datenschutzbestimmungen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) Valuetion verwendet auf der Website funktionelle Cookies. Weitere Informationen dazu finden Sie in unseren Datenschutzbestimmungen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (6) Sie haben als Nutzer verschiedene Rechte in Bezug auf Ihre Daten, einschließlich des Rechts auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung und Datenübertragbarkeit. Weitere Informationen dazu finden Sie in unseren Datenschutzbestimmungen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (7) Valuetion behält sich das Recht vor, die Datenschutzbestimmungen jederzeit zu ändern. Änderungen werden den Nutzern mindestens 14 Tage vor ihrem Inkrafttreten mitgeteilt. Mit der weiteren Nutzung der Website nach solchen Änderungen akzeptieren Sie die überarbeiteten Datenschutzbestimmungen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (8) Bei Fragen oder Bedenken hinsichtlich Ihrer personenbezogenen Daten können Sie sich jederzeit an den für die Datenverarbeitung Verantwortlichen wenden. Die Kontaktdaten finden Sie in den Datenschutzbestimmungen.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (9) Durch die Nutzung der Website und die Zustimmung zu diesen AGB erkennen Sie die Datenschutzbestimmungen von Valuetion an und stimmen ihnen zu. Sie bestätigen, dass Sie die Datenschutzbestimmungen gelesen und verstanden haben.
          </div>


          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            9. Anwendbares Recht und Gerichtsstand
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Die Rechtsbeziehung zwischen dem Nutzer und Valuetion untersteht ausschliesslich schweizerischem Recht.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) Ausschliesslicher Gerichtsstand für sämtliche Streitigkeiten zwischen dem Nutzer und Valuetion ist der Wohnort des Betreibers oder ein von Valuetion gewählter Gerichtsstand in der Schweiz.
          </div>

          <div class="darkest text-v-sm font-pj pb-v-xl pt-v-xl">
            Datum: 20. Juli 2023
          </div>

        </div>
        <div v-else>
          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            1. Preamble and Acceptance of the General Terms and Conditions (T&C)
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Valuetion is a website operated by Peter Wey, Flüehügel 2, 5436 Würenlos, Switzerland (hereinafter "Provider"), specializing in the preparation and graphical representation of financial data. These data are provided by one or more third-party providers (hereinafter "Data Providers"). The website offers both free and paid subscription services (hereinafter "Service" or "Subscription") that allow users to access various functions and data.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Please read the following General Terms and Conditions (hereinafter "T&C") carefully. By using the website and the services offered on it, you accept these T&C in full. If you do not agree with parts of these T&C, you are not permitted to use the website and the services offered on it.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Valuetion is not a licensed financial advisor and does not offer investment advice. The information and data provided on the website are solely for informational purposes and should not be understood as financial advice. Each user is solely responsible for how they use and interpret the provided information and data. The decision and risk for financial investments rest exclusively with the user.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            2. Scope of the T&C
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) These T&C govern the contractual relationship between the Provider, Peter Wey, operator of Valuetion, and the natural or legal persons (hereinafter "Users") who use the Provider's services.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) The T&C apply both to the free and the paid use of the services and contents provided on the Provider's website and are decisive for all contracts concluded via this website.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) They apply in their version valid at the time of the conclusion of the contract. Deviating terms and conditions of the user are not recognized, unless the provider expressly agrees to their validity in writing.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Valuetion reserves the right to change these T&C at any time. The changed conditions will be announced to the users at least 14 days before they come into effect. If a user does not object to the changed conditions within 14 days of the announcement, these are considered accepted. Valuetion will point out the significance of this 14-day period in the announcement.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) The user can view the currently valid version of the T&C on the provider's website.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            3. Transfer and amendment of contract
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) The user acknowledges and agrees that the current contract is concluded between the user and the operator of Valuetion, Peter Wey.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) If Valuetion is converted into another legal form in the future (for example into a limited liability company), this contract will automatically continue between the User and the newly founded legal form of Valuetion. All rights and obligations arising from this contract shall be transferred to the newly founded legal form in this case.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            4. Conclusion of contract
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) The presentation and promotion of services on our website does not constitute a binding offer to conclude a contract.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) The contract between the User and Valuetion is only concluded by creating a user account on the Valuetion website. For this purpose, the user must complete the registration process specified on the website. This includes filling out the registration form with the required data, accepting these General Terms and Conditions and any other terms of use by clicking on the appropriate box, as well as confirming the registration by clicking on the link contained in the confirmation e-mail sent by Valuetion (double opt-in procedure).
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) Valuetion stores the text of the contract after the conclusion of the contract. The user receives a confirmation email about the successful completion of the registration, which also contains a link to these Terms and Conditions. The T&C can be viewed at any time on the Valuetion website.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) The User is obliged to provide truthful, up-to-date and complete information when registering and to update this information immediately in case of changes. Valuetion reserves the right to delete or block user accounts where there is suspicion of false information.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) Each user may only create one user account and may not transfer it to third parties. The user is responsible for the confidential handling of his access data. In case of suspicion of misuse of the user account by third parties, Valuetion must be informed immediately.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            5. Terms and conditions of use and restrictions
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion grants users a non-exclusive, non-transferable and limited right to use the website and the data provided on it (hereinafter "data") for personal and non-commercial purposes. Any use of the data for commercial purposes is expressly prohibited, unless expressly and in writing approved by Valuetion.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) The economic and financial information published on Valuetion is provided for information purposes only and does not constitute an advertisement, recommendation or advice or an invitation to purchase or sell investment instruments or to make any other transactions. Valuetion makes no representations or warranties as to the reliability, correctness or completeness of the published information and opinions.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) The information and opinions disseminated by Valuetion are provided exclusively for private use. They may be changed or deleted at any time without prior notice. Valuetion is not obliged to keep the information up to date at all times.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) All content (including all texts, data, graphics, logos, etc.) on Valuetion is protected by copyright. 
            All rights, title and interest therein (including trademarks, patents, copyrights and other intellectual property rights and other rights)
             remain with Valuetion or the respective rights holder. 
             Reproduction, transmission, modification or use of the content, in whole or in part, for public or commercial purposes is prohibited without
              the prior written consent of Valuetion, except for sharing exported graphics on social media platforms.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) Users may not make any representations or warranties to third parties (including, but not limited to, potential customers or end users) regarding 
            Valuetion or the data, unless Valuetion has previously approved this in writing.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (6) Users are entitled to use the data exclusively for personal use. Any transfer, resale or licensing of the data to third parties is strictly prohibited.
          </div>  
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (7) Users are obliged to behave in a lawful manner at all times and not to engage in unfair or misleading business practices or unethical business practices.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (8) Attribution: Users must acknowledge that the data is provided by Financial Modelling Prep (FMP), and ensure proper attribution to Financial Modelling Prep (FMP) whenever the data is displayed.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (9) Data Integrity: Users are prohibited from modifying, altering, or distorting the data in any way that would materially affect its integrity.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (10) Corrections and Updates: Users are required to accept and promptly incorporate any corrections to the data that are provided by Valuetion based on updates or corrections received from Valuetion.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (11) Prohibited Representations: Users must not make any representations or warranties concerning Financial Modelling Prep (FMP) or the data without prior written approval from Valuetion and Financial Modelling Prep (FMP).
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (12) Trademarks and Publicity: Users are prohibited from using Financial Modelling Prep (FMP)'s name, logos, trademarks, or other proprietary information without prior written permission from Valuetion and Financial Modelling Prep (FMP), except for nominative references when redistributing the data or offering derived products
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            6. Subscription terms and fees
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion offers a free account (hereinafter "Free Account") and plans to offer a paid premium subscription (hereinafter "Premium Subscription") in the future. Both account types are subject to these GTC.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) The Free Account allows access to certain features of the Website, but may have restrictions compared to the Premium Subscription. The user can cancel his Free Account at any time. After the introduction of the Premium Subscription, it will be possible to upgrade from the Free Account to a Premium Subscription.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2a) During the beta test phase, the functionality of the website may not yet be complete. Valuetion reserves the right to change or expand the functions of the website at any time in order to improve performance and service.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) After the introduction of the Premium Subscription, it offers full access to all functions and features of the Website. The exact costs for the Premium Subscription as well as the available payment methods will be indicated on the Valuetion website.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) The fees for the Premium Subscription are to be paid monthly in advance via the selected payment service provider. Payment is due on the day the Premium Subscription is concluded and then monthly on the respective day of the start of the contract.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) After the introduction of the Premium Subscription, it can be cancelled at any time at the end of the following month. After cancellation, the Premium Subscription will be converted into a Free Account, unless the user chooses to cancel his account completely. In the event of a complete cancellation, all user data and information will be deleted, unless there are legal retention obligations.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (6) All prices mentioned are, unless otherwise stated, final prices including VAT.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (7) Valuetion reserves the right to change the prices and the available functions of the subscriptions at any time. Changes will be communicated to the user in good time and will not affect subscriptions already concluded until the end of their respective term.
          </div>  
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (8) Valuetion reserves the right to change the scope of the Free Account and/or the Premium Subscription, to add additional subscription types or to change existing subscription types. Changes will be communicated to the user in good time.
          </div>
        
          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            7. Availability of services
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion provides the website and the services offered on it "as is" and "as available". This means that Valuetion does not guarantee the uninterrupted, timely, secure or error-free provision of the website and the services.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) In particular, Valuetion cannot be held liable for technical malfunctions, failures of the communication network or the Internet, system failures, or other impairments of the availability of the website and the services that are beyond Valuetion's control, including, but not limited to, errors by third parties, force majeure or attacks on the infrastructure by hackers.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) The use of the services requires a compatible and powerful technical equipment of the user. Valuetion does not guarantee the compatibility or performance of the user's technical equipment.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) The user acknowledges that not every update, improvement or other change to the website or the services will have the same effect on every user and that Valuetion is not responsible if the website or the services do not function properly due to the technical specifications or settings of the user.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            7. Limitation of liability and exclusion of liability
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion provides all content and services "as is" and "as available". Valuetion and/or its suppliers expressly disclaim all warranties, express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) In particular, Valuetion does not warrant the accuracy, completeness, reliability, timeliness or usefulness of the information provided on the website.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) Valuetion shall not be liable for any damages arising from the use or non-use of the website or the services provided on the website. This applies in particular to damages (including consequential damages) arising from loss of profit, business interruption, loss of programs or data in your information system or other damages. This also applies if Valuetion has been expressly advised of the possibility of such damages.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Valuetion shall not be liable for any errors, omissions, interruptions, deletions, defects, delays in operation or transmission, failures of communication lines, theft or destruction or unauthorized access to or alteration of user communications.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) The limitations of liability and exclusions apply within the framework of applicable law, in particular Swiss law.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (6) Valuetion's services and publications are not intended for persons subject to laws (for whatever reason) which prohibit access to Valuetion's publications. Persons to whom such restrictions apply are not permitted to access Valuetion's publications.
          </div> 
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (7) The user indemnifies Valuetion and its officers and employees from any legal disputes, claims for damages and other claims and claims asserted by third parties in connection with the content or the user's conduct, including the associated legal and court costs.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (8) Valuetion reserves the right to change, expand, limit or discontinue the scope and nature of the services offered on the website at any time. Users have no right to expect that the Website or the Services will be maintained in their current form. Valuetion is entitled to discontinue operation of the website in whole or in part at any time without prior notice. Any liability of Valuetion for damages or consequential damages resulting from the discontinuation of the operation of the website is excluded.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            8. Confidentiality and data protection
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) Valuetion undertakes to treat all personal information and personal data provided by users as confidential and to use such data only in accordance with the privacy policy.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) When using the website, various types of data are collected and processed, as set out in the privacy policy of Valuetion. These include, among other things, e-mail address, name, address, telephone number, cookies, usage data, communication data, contract data and payment data.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (3) The data processing is carried out for the purpose of operating the website, for marketing measures and to improve our service. By registering on our website or creating an account, you agree to the processing of your data for these purposes.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (4) Your data may be passed on to third parties, as set out in the privacy policy in detail. This transfer is always carried out in compliance with the applicable data protection regulations.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (5) Valuetion uses functional cookies on the website. For more information, please refer to our privacy policy.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (6) As a user, you have various rights with regard to your data, including the right to information, correction, deletion, restriction of processing, objection to processing and data transferability. For more information, please refer to our privacy policy.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (7) Valuetion reserves the right to change the privacy policy at any time. Changes will be communicated to users at least 14 days before they come into effect. By continuing to use the website after such changes, you accept the revised privacy policy.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (8) If you have any questions or concerns regarding your personal data, you can contact the person responsible for data processing at any time. The contact details can be found in the privacy policy.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (9) By using the website and agreeing to these GTC, you acknowledge and agree to Valuetion's privacy policy. You confirm that you have read and understood the privacy policy.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            9. Applicable law and place of jurisdiction
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (1) The legal relationship between the user and Valuetion is subject exclusively to Swiss law.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            (2) The exclusive place of jurisdiction for all disputes between the user and Valuetion is the place of residence of the operator or a place of jurisdiction in Switzerland chosen by Valuetion.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-xl pt-v-xl">
            Date: July 20th 2023
          </div>





        </div>
      </div>
    </div>
  
  </div>
</template>


