
<script>
import { sessionManager } from '@/main';
import { eventBus } from '@/main'

export default {
  name: 'UserResetPassword',
  props: {
  },
  data() {
    return {
      password: '',
      valid: false,
      email: '',
      emailRulesEN: [
        v => !!v || 'Email is required',
        v => /.+@.+/.test(v) || 'Email must be valid.'
      ],
      emailRulesDE: [
        v => !!v || 'Email ist erforderlich',
        v => /.+@.+/.test(v) || 'Email muss gültig sein.'
      ],
      languageStrings: {
        en: {
          title: 'Reset Password',
          headline: 'Forgot Password?',
          email: 'Email',
          submit: 'Reset',
          text: 'Please enter your email address to reset your password.',
          text_2: ' If your email address is registered in our system, you will receive an email with further instructions.',
          headline_sent: 'Email sent!',
          text_sent: 'We have sent you an email with further instructions.',
          text_sent_2: 'If you do not receive an email, please check your spam folder.',
        },
        de: {
          title: 'Passwort zurücksetzen',
          headline: 'Passwort vergessen?',
          email: 'Email',
          submit: 'Zurücksetzen',
          text: 'Bitte gib Deine E-Mail-Adresse ein, um Dein Passwort zurückzusetzen.',
          text_2:'Falls Deine E-Mail-Adresse in unserem System registriert ist, erhältst Du eine E-Mail mit weiteren Anweisungen.',
          headline_sent: 'Email gesendet!',
          text_sent: 'Wir haben Dir eine E-Mail mit weiteren Anweisungen gesendet.',
          text_sent_2: 'Falls Du keine E-Mail erhalten hast, überprüfe bitte Deinen Spam-Ordner.',
        }
      },
      username: '',
      language: '',
      emailSent: false,
    };
  },
  created() {
    this.language = sessionManager.read('language');
    if (this.language === 'en') {
      this.emailRules = this.emailRulesEN;
      this.passwordRules = this.passwordRulesEN;
    } else {
      this.emailRules = this.emailRulesDE;
      this.passwordRules = this.passwordRulesDE;
    }
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    async submitForm() {
      // send email and password to the API to login the user
      const url = '/api/HttpTriggerRequestResetPassword';
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
           email: this.email, 
        }),
      }).then((response, reject) => {
        if (response.status === 200) {
          this.emailSent = true;
        } else {
          // report error
          this.$router.push({
            name: 'error',
          });
        }
      });        
    },
  }, 
}
</script>

<style scoped>


  .v-list-item__content {
    font-size: 15px;
    font-family: Plus Jakarta Sans;
  }
  .v-input {
      font-size: 15x;
      font-family: Plus Jakarta Sans;
      border: 0px !important;
      border: none; 
      border-radius: 8px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <div v-if="emailSent">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline_sent']}}</h3>
          <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['text_sent']}}</div>
          <div class="text-v-sm darkest font-pj pb-v-xl">{{strings['text_sent_2']}}</div>
        </div>
        <div v-else>
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
          <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['text']}}</div>
          <div class="text-v-sm darkest font-pj pb-v-xl">{{strings['text_2']}}</div>
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['email']}}</div>
            <div>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                outlined
              />
            </div>
            <button type="submit" :disabled="!valid" 
              class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light disabled:bg-gray disabled:border-gray disabled:text-dark disabled:cursor-not-allowed"
              >{{ strings['submit'] }}</button>

          </v-form>
        </div>        
      </div>
    </div>
  </div>
</template>


