/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
 
  export default {
    name: 'PopupInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        languageStrings: {
          en: {
            close: 'close',
          },
          de: {
            close: 'Schliessen',
          }
        },
        language: '',
      };
    },
    methods: {
      close() {
        if(this.onClose && typeof this.onClose === "function") {
          this.onClose();
        }
      },
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>
  
  
<template>
  <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <v-dialog v-model="showPopup" max-width="800px" @click:outside="close">
      <v-card>
        <div class="px-v-md pt-v-md">
          <div class="flex-auto">
            <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">{{title}}</h3>
          </div>
          <div class="flex-none">
            <p style="text-align:right;">
              <v-tooltip bottom color="primary" light>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="close">mdi-close</v-icon>
                </template>
                <span class="darkest font-pj text-v-tiny">{{ strings['close'] }}</span>
              </v-tooltip>
            </p>
          </div>
        </div>
        <div class="px-v-md pb-v-md">
          <slot name="content"></slot> 
        </div>
        <div class="px-v-md pb-v-md">
          <button type="button" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="close">
            {{ strings['close']}}
           </button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
/* eslint-enable */ 
  