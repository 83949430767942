<script>
/* eslint-disable */
import CompanyInfoTable from '@/components/company-info-table.vue';
import CompanyDescription from '@/components/company-description.vue';
import CompanyImage from '@/components/company-image.vue';
import CompanyValuationTable from '@/components/company-valuation-table.vue';
import CompanyValuationChart from '@/components/company-valuation-chart.vue';
import OverviewGraphCashflow from '@/components/overview-graph-cashflow.vue';
import OverviewGraphBalance from '@/components/overview-graph-balance.vue';
import OverviewGraphIncome from '@/components/overview-graph-income.vue';
import CompanyValuationSimpleMetrics from '@/components/company-valuation-simple-metrics.vue';
import CompanyValuationTenCapEarnings from '@/components/company-valuation-ten-cap-earnings.vue';
import CompanyValuationTenCapFreeCashflow from '@/components/company-valuation-ten-cap-free-cashflow.vue';
import CompanyValuationTenCapOwnerEarnings from '@/components/company-valuation-ten-cap-owner-earnings.vue';
import CompanyValuationMarginOfSafety from '@/components/company-valuation-margin-of-safety.vue';
import CompanyValuationPaybackTimeFreeCashflow from '@/components/company-valuation-payback-time-free-cashflow.vue';
import CompanyValuationPaybackTimeOwnerEarnings from '@/components/company-valuation-payback-time-owner-earnings.vue';
import CompanyValuationNetCurrentAssetValue from '@/components/company-valuation-net-current-asset-value.vue';
import CompanyValuationPeterLynchPEGRatio from '@/components/company-valuation-peter-lynch-peg-ratio.vue';
import CompanyValuationGrahamNumber from '@/components/company-valuation-graham-number.vue';
import CompanyValuationDCFFreeCashflow from '@/components/company-valuation-dcf-free-cashflow.vue';
import CompanyValuationHistorical from '@/components/company-valuation-historical.vue';
import CompanyFinancialsIncomeGraph from '@/components/company-financials-income-graph.vue';
import CompanyFinancialsBalanceGraph from '@/components/company-financials-balance-graph.vue';
import CompanyFinancialsCashflowGraph from '@/components/company-financials-cashflow-graph.vue';
import CompanyFinancialsIncome from '@/components/company-financials-income.vue';
import CompanyFinancialsBalance from '@/components/company-financials-balance.vue';
import CompanyFinancialsCashflow from '@/components/company-financials-cashflow.vue';
import CompanyFinancialsIncomeGraphTweet from '@/components/company-financials-income-graph-tweet.vue';
import CompanyFinancialsCashflowGraphTweet from '@/components/company-financials-cashflow-graph-tweet.vue';
import CompanyFinancialsBalanceGraphTweet from '@/components/company-financials-balance-graph-tweet.vue';
import CompanySECFilings from './company-sec-filings.vue';

import { replaceUnderscoreWithDot, 
  formatUnitOfAccount, toUnitOfAccount, formatUnitOfAccountTweets,
  logUserEvent,
 } from '../helper';
import CompanyGrowth from './company-growth.vue';
import CompanyManagement from './company-management.vue';
import { Colors } from '../colors';
import { sessionManager } from '@/main';
import * as settings from '../settings';
import html2canvas from 'html2canvas';


export default {
  name: 'CompanyTweetPage',
  props: {
    symbol: {
      type: String,
      required: true,
      default: () => null,
    },
    logo: {
      type: Boolean,
      default: () => false
    },
  },
  components: {
    CompanyInfoTable,
    CompanyDescription,
    CompanyImage,
    CompanyGrowth,
    CompanyManagement,
    CompanyValuationTable,
    CompanyValuationChart,
    OverviewGraphCashflow,
    OverviewGraphBalance,
    OverviewGraphIncome,
    CompanyFinancialsIncomeGraphTweet,
    CompanyFinancialsCashflowGraphTweet,
    CompanyFinancialsBalanceGraphTweet,
    CompanyValuationSimpleMetrics,
    CompanyValuationTenCapEarnings,
    CompanyValuationTenCapFreeCashflow,
    CompanyValuationTenCapOwnerEarnings,
    CompanyValuationMarginOfSafety,
    CompanyValuationPaybackTimeFreeCashflow,
    CompanyValuationPaybackTimeOwnerEarnings, 
    CompanyValuationNetCurrentAssetValue,
    CompanyValuationPeterLynchPEGRatio,
    CompanyValuationGrahamNumber,
    CompanyValuationDCFFreeCashflow,
    CompanyValuationHistorical,
    CompanyFinancialsIncomeGraph,
    CompanyFinancialsBalanceGraph,
    CompanyFinancialsCashflowGraph,
    CompanyFinancialsIncome,
    CompanyFinancialsBalance,
    CompanyFinancialsCashflow,
},
  data() {
    return {
      languageStrings: {
        en: {
          overview: 'Overview',
          valuation: 'Valuation',
          financials: 'Financials',
          filings: 'Filings',
          consistency_warning: 'Warning: Inconsistent data from the data provider.',
          financial_statements: 'Financial Statements',
          financial_statements_text: 'Income, balance and cashflow statement of',
          reported_in: 'reported in',
          on_sale: 'price',
          follow: 'Follow',
          following: 'Following',
          unfollow: 'Unfollow',
          
        },
        de: {
          overview: 'Übersicht',
          valuation: 'Bewertung',
          financials: 'Finanzen',
          filings: 'Meldungen',
          consistency_warning: 'Warnung: Inkonsistente Daten vom Datenanbieter.',
          financial_statements: 'Finanzberichte',
          financial_statements_text: 'Gewinn- und Verlustrechnung, Bilanz und Kapitalflussrechnung von',
          reported_in: 'gemeldet in',
          on_sale: 'Preis',
          follow: 'Folgen',
          following: 'Folge ich',
          unfollow: 'Nicht mehr folgen',
        }
      },
      companyDetail: null,
      companyValuation: null,
      companySECFilings: null,
      cashFlowData: null,
      balanceData: null,
      incomeData: null,
      cashFlowDataFull: null,
      balanceDataFull: null,
      incomeDataFull: null,
      marketCapData: null,
      equityValueData: null,
      tabs: null,
      annualPeriod: true,
      financialsSlider: 0,
      warningColor: Colors.RedOrange,
      language: '',
      isOnWatchlist: false,
      watchlistHovering: false,
      tweet_de: '',
      tweet_en: '',
    };
  },
  mounted() {
    document.title = this.cleanTickerSymbol + ' - Valuetion';
  },
  created() {
    this.language = sessionManager.read('language');
    this.unitOfAccount = sessionManager.read('unitOfAccount');

    // check if user is logged in, if not redirect to login page
    if(settings.requiresLogin()) {
      if(!sessionManager.isLoggedin())
      {
        this.$router.push('/login');
      }
    }

    logUserEvent('companyPage', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
  },
  methods: {
    generateTweet()
    {
      this.tweet_en = '';
      this.tweet_de = '';

      // add the company name
      this.tweet_en += this.companyDetail.companyName + ' (' + this.companyDetail.exchangeShortName + ': $' + this.companyDetail.id + ')';
      this.tweet_en += ' is on sale for ' +  formatUnitOfAccountTweets(toUnitOfAccount(this.companyDetail.marketCap, this.unitOfAccount), this.unitOfAccount) + '. ';

      this.tweet_de += this.companyDetail.companyName + ' (' + this.companyDetail.exchangeShortName + ': $' + this.companyDetail.id + ')';
      this.tweet_de += ' steht für ' +  formatUnitOfAccountTweets(toUnitOfAccount(this.companyDetail.marketCap, this.unitOfAccount), this.unitOfAccount) + ' zum Verkauf. ';

      // cashflows
      this.tweet_en += 'For that you get free cashflows of ' + formatUnitOfAccountTweets(this.cashFlowData.cashflowTTM.freeCashflow, this.unitOfAccount)  + ', ';
      this.tweet_en += 'earnings of ' + formatUnitOfAccountTweets(this.incomeData.incomeTTM.netIncome, this.unitOfAccount) + ' and ';
      this.tweet_de += 'Dafür gibt es freie Cashflows von ' + formatUnitOfAccountTweets(this.cashFlowData.cashflowTTM.freeCashflow, this.unitOfAccount)  + ', ';
      this.tweet_de += 'Gewinne von ' + formatUnitOfAccountTweets(this.incomeData.incomeTTM.netIncome, this.unitOfAccount) + ' und ';
      if(this.companyValuation.management.netDebt < 0)
      {
        this.tweet_en += 'a solid balance sheet with ' + formatUnitOfAccountTweets(-this.companyValuation.management.netDebt, this.unitOfAccount) + ' of net cash.'
        this.tweet_de += 'eine solide Bilanz mit ' + formatUnitOfAccountTweets(-this.companyValuation.management.netDebt, this.unitOfAccount) + ' Nettokapital.'
      }
      else
      {
        this.tweet_en += 'a balance sheet with ' + formatUnitOfAccountTweets(this.companyValuation.management.netDebt, this.unitOfAccount) + ' of net debt.'
        this.tweet_de += 'eine Bilanz mit ' + formatUnitOfAccountTweets(this.companyValuation.management.netDebt, this.unitOfAccount) + ' Nettoschulden.'
      }

      // valuations 
      let vr = this.companyValuation.combinedValuationRatio;
      if(vr < 0)
      {
        // negative valuation
        this.tweet_en += ' The valuations on my site are very red. ';
        this.tweet_de += ' Die Bewertungen auf meiner Seite sind tief rot. ';
      }
      else if(vr < 0.5)
      {
        // really cheap
        this.tweet_en += ' The valuations on my site are green. ';
        this.tweet_de += ' Die Bewertungen auf meiner Seite sind grün. ';
      }
      else if(vr < 0.75)
      {
        // cheap
        this.tweet_en += ' The valuations on my site are green. ';
        this.tweet_de += ' Die Bewertungen auf meiner Seite sind grün. ';
      }
      else if(vr < 1)
      {
        // fair
        this.tweet_en += ' The valuations on my site are mostly green. ';
        this.tweet_de += ' Die Bewertungen auf meiner Seite sind mehrheitlich grün. ';
      }
      else if(vr < 1.25)
      {
        // expensive
        this.tweet_en += ' The valuations on my site are approaching the buy zone. ';
        this.tweet_de += ' Die Bewertungen auf meiner Seite nähern sich der Kaufzone. ';
      }
      else if(vr < 1.5)
      {
        // expensive
        this.tweet_en += ' The valuations on my site are still a bit high. ';
        this.tweet_de += ' Die Bewertungen auf meiner Seite sind noch etwas hoch. ';
      }
      else
      {
        // expensive
        this.tweet_en += ' The valuations on my site are red. ';
        this.tweet_de += ' Die Bewertungen auf meiner Seite sind rot. ';
      }  
    },
    // generate the text for the tweet
    async captureImages()
    {
      // capture the images of the graphs
      //await this.captureFourDivs('OverviewGraphCashflow', 'OverviewGraphIncome', 'CompanyFinancialsBalanceGraph', 'CompanyValuationTable', this.symbol + ' Instagram.png');
      //await this.captureFourDivs('CompanyFinancialsIncomeGraph', 'CompanyFinancialsBalanceGraph', 'CompanyFinancialsCashflowGraph', 'CompanyValuationTable', this.symbol + ' Instagram financials.png');

      await this.captureDiv('CompanyValuationTable', this.symbol + ' CompanyValuations.png');
      //await this.captureDiv169('CompanyValuationTable', this.symbol + ' CompanyValuations_169.png')
      await this.captureDiv('OverviewGraphCashflow', this.symbol + ' OverviewGraphCashflow.png');
      //await this.captureDiv169('OverviewGraphCashflow', this.symbol + ' OverviewGraphCashflow_169.png');
      await this.captureDiv('OverviewGraphIncome', this.symbol + ' OverviewGraphIncome.png');
      //await this.captureDiv169('OverviewGraphIncome', this.symbol + ' OverviewGraphIncome_169.png');
      await this.captureDiv('OverviewGraphBalance', this.symbol + ' OverviewGraphBalance.png');
      //await this.captureDiv169('OverviewGraphBalance', this.symbol + ' OverviewGraphBalance_169.png');
      await this.captureDiv('CompanyFinancialsCashflowGraph', this.symbol + ' CompanyFinancialsCashflowGraph TTM.png');
      //await this.captureDiv169('CompanyFinancialsCashflowGraph', this.symbol + ' CompanyFinancialsCashflowGraph TTM_169.png');
      await this.captureDiv('CompanyFinancialsIncomeGraph', this.symbol + ' CompanyFinancialsIncomeGraph TTM.png');
      //await this.captureDiv169('CompanyFinancialsIncomeGraph', this.symbol + ' CompanyFinancialsIncomeGraph TTM_169.png');
      await this.captureDiv('CompanyFinancialsBalanceGraph', this.symbol + ' CompanyFinancialsBalanceGraph.png');
      //await this.captureDiv169('CompanyFinancialsBalanceGraph', this.symbol + ' CompanyFinancialsBalanceGraph_169.png');
    },
    // capture an individual div and download it as png
    async captureDiv(divId, filename) {
      const element = document.getElementById(divId);
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      this.downloadImage(image, filename);
    },
    downloadImage(data, filename) {
      const link = document.createElement('a');
      link.href = data;
      link.download = filename;
      link.click();
    },
    async captureFourDivs(divId1, divId2, divId3, divId4, filename) {
      const element1 = document.getElementById(divId1);
      const element2 = document.getElementById(divId2);
      const element3 = document.getElementById(divId3);
      const element4 = document.getElementById(divId4);

      const canvas1 = await html2canvas(element1);
      const canvas2 = await html2canvas(element2);
      const canvas3 = await html2canvas(element3);
      const canvas4 = await html2canvas(element4);

      // Get the dimensions of the captured canvases
      const width1 = canvas1.width;
      const height1 = canvas1.height;
      const width2 = canvas2.width;
      const height2 = canvas2.height;
      const width3 = canvas3.width;
      const height3 = canvas3.height;
      const width4 = canvas4.width;
      const height4 = canvas4.height;

      // Determine the size of the new square canvas (1:1 aspect ratio)
      const newSize = 2 * Math.max(width1, height1, width2, height2, width3, height3, width4, height4);

      // Create a new canvas with 1:1 aspect ratio
      const newCanvas = document.createElement('canvas');
      newCanvas.width = newSize;
      newCanvas.height = newSize;
      const ctx = newCanvas.getContext('2d');

      // Fill the new canvas with a white background
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, newSize, newSize);

      // Calculate the dimensions for each quadrant
      const halfWidth = newSize / 2;
      const halfHeight = newSize / 2;

      // Function to draw and scale a canvas in a given quadrant
      function drawScaled(canvas, x, y, maxWidth, maxHeight) {
        const aspectRatio = canvas.width / canvas.height;
        let drawWidth, drawHeight;
        if (aspectRatio > 1) {
          drawWidth = maxWidth;
          drawHeight = maxWidth / aspectRatio;
        } else {
          drawHeight = maxHeight;
          drawWidth = maxHeight * aspectRatio;
        }
        const offsetX = (maxWidth - drawWidth) / 2;
        const offsetY = (maxHeight - drawHeight) / 2;
        ctx.drawImage(canvas, x + offsetX, y + offsetY, drawWidth, drawHeight);
      }

      // Draw each canvas in its respective quadrant
      drawScaled(canvas1, 0, 0, halfWidth, halfHeight); // Top-left
      drawScaled(canvas2, halfWidth, 0, halfWidth, halfHeight); // Top-right
      drawScaled(canvas3, 0, halfHeight, halfWidth, halfHeight); // Bottom-left
      drawScaled(canvas4, halfWidth, halfHeight, halfWidth, halfHeight); // Bottom-right


      // Convert the new canvas to a data URL
      const image = newCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

      // Download the image
      this.downloadImageWithNewDomEntry(image, filename);
    },
    downloadImageWithNewDomEntry(data, filename) {
      const a = document.createElement('a');
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async captureDiv169(divId, filename) {
      const element = document.getElementById(divId);
      const originalCanvas = await html2canvas(element);
      
      // Get the dimensions of the original canvas
      const originalWidth = originalCanvas.width;
      const originalHeight = originalCanvas.height;

      // Determine the size of the new canvas with 16:9 aspect ratio
      const newWidth = originalWidth > originalHeight ? originalWidth : originalHeight * (16 / 9);
      const newHeight = originalHeight > originalWidth ? originalHeight : originalWidth * (9 / 16);

      // Create a new canvas with 16:9 aspect ratio
      const newCanvas = document.createElement('canvas');
      newCanvas.width = newWidth;
      newCanvas.height = newHeight;
      const ctx = newCanvas.getContext('2d');

      // Fill the new canvas with a white background
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, newWidth, newHeight);

      // Calculate the position to center the original image on the new canvas
      const offsetX = (newWidth - originalWidth) / 2;
      const offsetY = (newHeight - originalHeight) / 2;

      // Draw the original image onto the new canvas
      ctx.drawImage(originalCanvas, offsetX, offsetY);

      // Convert the new canvas to a data URL
      const image = newCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

      // Download the image
      this.downloadImageSquare(image, filename);
    },
    async captureDivSquare(divId, filename) {
      const element = document.getElementById(divId);
      const originalCanvas = await html2canvas(element);
      
      // Get the dimensions of the original canvas
      const originalWidth = originalCanvas.width;
      const originalHeight = originalCanvas.height;

      // Determine the size of the new square canvas (1:1 aspect ratio)
      const size = Math.max(originalWidth, originalHeight);

      // Create a new canvas with 1:1 aspect ratio
      const newCanvas = document.createElement('canvas');
      newCanvas.width = size;
      newCanvas.height = size;
      const ctx = newCanvas.getContext('2d');

      // Fill the new canvas with a white background
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, size, size);

      // Calculate the position to center the original image on the new canvas
      const offsetX = (size - originalWidth) / 2;
      const offsetY = (size - originalHeight) / 2;

      // Draw the original image onto the new canvas
      ctx.drawImage(originalCanvas, offsetX, offsetY);

      // Convert the new canvas to a data URL
      const image = newCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

      // Download the image
      this.downloadImageSquare(image, filename);
    },
    downloadImageSquare(data, filename) {
      const a = document.createElement('a');
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    // method to get the company valuation
    async fetchCompanyData()
    {
      const urlFMP = '/api/HttpTriggerGetCompanyDataFMP?';
      const urlFH = '/api/HttpTriggerGetCompanyDataFH?';
      var url = '';

      if(sessionManager.read('useFinnhub'))
      {
        url = urlFH;
      }
      else
      {
        url = urlFMP;
      }
    
      const response = await fetch(url +
        new URLSearchParams({
          symbol: this.cleanTickerSymbol,
          unitOfAccount: this.unitOfAccount, 
          language: this.language,
        }), { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });

      // check if response is ok
      if(response.ok) {
        const data = await response.json();
        this.companyValuation = data.valuation;
        this.companyDetail = data.profile;
        this.cashFlowData = { 
          cashflowAnnual: data.cashFlowAnnual, 
          cashflowQuarter: data.cashFlowQuarter, 
          cashflowTTM: data.cashFlowTTM, 
          hasTTM: data.hasTTM,
        };
        this.cashFlowDataFull = { 
          cashflowAnnual: data.cashFlowAnnualFull, 
          cashflowQuarter: data.cashFlowQuarterFull, 
          cashflowTTM: data.cashFlowTTMFull, 
          hasTTM: data.hasTTM,
          type: data.type,
        };
        this.incomeData = { 
          incomeAnnual: data.incomeAnnual, 
          incomeQuarter: data.incomeQuarter, 
          incomeTTM: data.incomeTTM,
          hasTTM: data.hasTTM,
        };
        this.incomeDataFull = { 
          incomeAnnual: data.incomeAnnualFull, 
          incomeQuarter: data.incomeQuarterFull, 
          incomeTTM: data.incomeTTMFull,
          hasTTM: data.hasTTM,
          type: data.type,
        };
        this.reportedCurrency = data.reportedCurrency;
        this.balanceData = { 
          balanceAnnual: data.balanceAnnual, 
          balanceQuarter: data.balanceQuarter,
        };
        this.balanceDataFull = { 
          balanceAnnual: data.balanceAnnualFull, 
          balanceQuarter: data.balanceQuarterFull,
          type: data.type,
        };
        this.isBank = data.type == "FHBank";
        this.marketCapData = data.marketCap;
        this.isOnWatchlist = data.isOnWatchlist;

        document.title = this.companyDetail.companyName + ' - Valuetion';
        this.generateTweet();
      }
      else {
        // check if response is unauthorized
        if(response.status === 401) {
          // redirect to login page
          this.$router.push('/login');
        }
      }    
    },
    async updateCompanyDetail()
    {
      this.companyDetail = null;
      this.companyValuation = null;
      this.cashFlowData = null;
      this.balanceData = null;
      this.incomeData = null;
      this.marketCapData = null;

      // get company detail
      await this.fetchCompanyData();
    },
    updateSlider(val)
    {
      this.financialsSlider = val.slider;
    },
    updateAnnual(val)
    {
      this.annualPeriod = val.annual;
    },
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
    dataDateString()
    {
      // Get the current time in milliseconds
      const now = new Date().getTime();

      // Convert the timestamp to milliseconds
      const timestampInMilliseconds = this.companyDetail.timestamp * 1000;

      // Calculate the difference in milliseconds
      const differenceInMilliseconds = now - timestampInMilliseconds;

      // Convert the difference from milliseconds to days
      const differenceInDays = differenceInMilliseconds / 1000 / 60 / 60 / 24;

      // Print the number of days
      if(differenceInDays < 5)
      {
        return '';
      }
      else
      {
        return '(' + differenceInDays.toFixed(0) + ' days ago)';
      }
    },
    cleanTickerSymbol()
    {
      return replaceUnderscoreWithDot(this.symbol);
    },
    marketCapFormatted()
    {
      const marketCap = toUnitOfAccount(this.companyDetail.marketCap, this.unitOfAccount);
      return formatUnitOfAccount(marketCap, this.unitOfAccount, true);
    },
    annualPeriodLabel()
    {
      if(this.annualPeriod)
      {
        return 'annual';
      }
      else
      {
        return 'quarter';
      }
    },
    historicalValuationHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 150
        case 'sm': return 50
        case 'md': return 50
        case 'lg': return 50
        case 'xl': return 25
      }
    },
    historicalValuationWidth ()
    {
      return 100;
    },
    // generate the text for the tweet
    tweetTextEn()
    {
      return this.tweet_en;
    },
    // generate the text for the tweet
    tweetTextDe()
    {
      return this.tweet_de;
    },
  },
  beforeMount() { 
    this.updateCompanyDetail();
  },
  watch: {
    symbol() {
      this.updateCompanyDetail();
    }
  },
}
</script>

<style scoped>
  .v-tab {
    text-transform: none !important;
    font-size: 18px;
    font-family: Plus Jakarta Sans;
    color: #1d270f;
    letter-spacing: 0em;
    padding-left: 8px;
    font-weight: bold;
  }
  .v-tab--active {
    text-transform: none !important;
    font-size: 18px;
    font-family: Plus Jakarta Sans;
    color: #1d270f;
    padding-left: 8px;
    letter-spacing: 0em;
    font-weight: bold;
  }
</style>

<template>
  <div class="flex flex-col">
    <div class="bg-primary">

          <h2 v-if="companyDetail" :class="$vuetify.breakpoint.smAndDown ? 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md pb-v-sm' : 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md pb-v-sm'">{{ companyDetail.companyName }} ({{ companyDetail.exchangeShortName }}:{{ companyDetail.id }})</h2>
          <h2 v-else :class="$vuetify.breakpoint.smAndDown ? 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md' : 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md'">{{ cleanTickerSymbol }}</h2>
          <p v-if="companyDetail && marketCapFormatted" class="text-center text-lightest text-v-base font-pj px-v-sm">{{ marketCapFormatted }}</p>
          <p v-else class="text-center text-lightest text-v-base font-pj px-v-sm"></p>
    </div>

    <section :class="$vuetify.breakpoint.smAndDown ? 'pt-v-md pb-v-2xl px-v-2xl' : 'py-v-3xl px-v-4xl'">
      <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
        <div>
          <div class="flex justify-start items-center">
            <div v-if="companyValuation && companyDetail && cashFlowData && incomeData && balanceData && marketCapData" class="max-w-4xl p-v-xl font-pj">
              {{ tweetTextEn }}
            </div>
            <div v-if="companyValuation && companyDetail && cashFlowData && incomeData && balanceData && marketCapData" class="max-w-4xl p-v-xl font-pj">
              {{ tweetTextDe }}
            </div>
          </div>
          <div class="max-w-xl py-v-xl font-pj">
            <button type="button" 
              class="text-v-sm flex-none bg-primary border rounded-md border-solid border-very-light-gray regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-very-light-gray" 
              @click="captureImages">
              capture images
            </button>
          </div>
        </div>
      </div>
    </section>  

    <div class="max-w-[1000px] mx-auto">
      <v-row v-if="!companyDetail" class="font-pj">
        <v-col>
          <div class="flex justify-center p-v-xl">
            <div>
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="font-pj" v-if="companyValuation && !companyValuation.isValid">
        <v-col :style="{ 'background-color': warningColor }">
          <h5 align="center" :style="{ 'color': 'rgb(255,255,255)' }" >Daten inkonsistent</h5>
        </v-col>
      </v-row>
      <v-row class="font-pj" v-if="companyValuation && !companyValuation.dataComplete">
        <v-col :style="{ 'background-color': warningColor }">
          <h5 align="center" :style="{ 'color': 'rgb(255,255,255)' }" >Daten nicht komplett</h5>
        </v-col>
      </v-row>
      <v-row dense v-if="companyDetail && companyDetail.isValid">
        <v-col cols="12">
          <CompanyInfoTable v-if="companyDetail && companyDetail.isValid" v-bind:company="companyDetail" v-bind:unitOfAccount="unitOfAccount" />
        </v-col>
        <v-col cols="12">
          <CompanyDescription v-if="companyDetail && companyDetail.isValid" v-bind:company="companyDetail"/>
        </v-col>
        <v-col cols="12">
          <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
            <CompanyGrowth v-if="companyValuation" 
              v-bind:moatData="companyValuation.moat[0]" 
              v-bind:unitOfAccount="unitOfAccount"
              v-bind:logo="logo"
              v-bind:logoText="logo ? companyDetail.companyName : ''"/>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
            <CompanyManagement v-if="companyValuation" 
              v-bind:managementData="companyValuation.management" 
              v-bind:unitOfAccount="unitOfAccount"
              v-bind:logo="logo"
              v-bind:logoText="logo ? companyDetail.companyName : ''"/>
          </div>
        </v-col>

        <v-col cols="12">
          <div id="CompanyValuationTable" class="p-v-md">
            <CompanyValuationTable v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
              v-bind:symbol="cleanTickerSymbol" 
              v-bind:valuation="companyValuation" 
              v-bind:marketCap="companyDetail.marketCap" 
              v-bind:unitOfAccount="unitOfAccount"
              v-bind:logo="logo"
              v-bind:logoText="logo ? companyDetail.companyName : ''"/>
          </div>
        </v-col>            
        <v-col cols="12">
          <div id="OverviewGraphCashflow" class="p-v-md">
            <OverviewGraphCashflow v-if="cashFlowData && unitOfAccount && companyDetail && companyDetail.isValid" 
              v-bind:cashFlowData="cashFlowData" 
              v-bind:unitOfAccount="unitOfAccount"
              v-bind:companyName="companyDetail.companyName"
              v-bind:tweet="true"/>
          </div>
        </v-col>
        <v-col cols="12">
          <div id="OverviewGraphIncome" class="p-v-md">
            <OverviewGraphIncome v-if="incomeData && unitOfAccount" 
              v-bind:incomeData="incomeData" 
              v-bind:unitOfAccount="unitOfAccount"
              v-bind:companyName="companyDetail.companyName"
              v-bind:tweet="true"/>
          </div>
        </v-col>
        <v-col cols="12">
          <div id="OverviewGraphBalance" class="p-v-md">
            <OverviewGraphBalance v-if="balanceData && unitOfAccount" 
              v-bind:balanceData="balanceData" 
              v-bind:unitOfAccount="unitOfAccount"
              v-bind:companyName="companyDetail.companyName"
              v-bind:tweet="true"/>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!companyValuation" class="font-pj">
        <v-col>
          <div class="flex justify-center p-v-xl">
            <div>
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
      
    <div class="max-w-[1600px] mx-auto">    
      <v-row v-if="!companyValuation" class="font-pj">
        <v-col>
          <div class="flex justify-center p-v-xl">
            <div>
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="companyValuation && !companyValuation.isValid">
        <v-col :style="{ 'background-color': warningColor }">
          <h5 align="center" :style="{ 'color': 'rgb(255,255,255)' }" {{strings['consistency_warning']}}</h5>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
            <CompanyFinancialsIncomeGraphTweet 
                v-if="incomeDataFull" 
                v-bind:income="incomeDataFull" 
                v-bind:companyName="companyDetail.companyName" 
                v-bind:exchangeShortName="companyDetail.exchangeShortName"
                v-bind:symbol="companyDetail.id"
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
            <CompanyFinancialsBalanceGraphTweet 
                v-if="balanceDataFull" 
                v-bind:balance="balanceDataFull" 
                v-bind:companyName="companyDetail.companyName" 
                v-bind:exchangeShortName="companyDetail.exchangeShortName"
                v-bind:symbol="companyDetail.id"
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
            <CompanyFinancialsCashflowGraphTweet 
                v-if="cashFlowDataFull" 
                v-bind:cashflow="cashFlowDataFull" 
                v-bind:companyName="companyDetail.companyName" 
                v-bind:exchangeShortName="companyDetail.exchangeShortName" 
                v-bind:symbol="companyDetail.id"
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="companyValuation && companyValuation.isValid">
        <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
          <CompanyValuationSimpleMetrics v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
            v-bind:valuation="companyValuation" 
            v-bind:marketCap="companyDetail.marketCap" 
            v-bind:unitOfAccount="unitOfAccount"
            v-bind:logo="logo"
            v-bind:logoText="companyDetail.companyName"/>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
          <CompanyValuationTenCapEarnings v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
            v-bind:valuation="companyValuation.tenCapEarningsValuation[0]" 
            v-bind:marketCap="companyDetail.marketCap" 
            v-bind:unitOfAccount="unitOfAccount"
            v-bind:logo="logo"
            v-bind:logoText="companyDetail.companyName"/>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
          <CompanyValuationTenCapFreeCashflow v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
            v-bind:valuation="companyValuation.tenCapFreeCashflowValuation[0]" 
            v-bind:marketCap="companyDetail.marketCap" 
            v-bind:unitOfAccount="unitOfAccount"
            v-bind:logo="logo"
            v-bind:logoText="companyDetail.companyName"/>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
          <CompanyValuationTenCapOwnerEarnings v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
            v-bind:valuation="companyValuation.tenCapOwnerEarningsValuation[0]" 
            v-bind:marketCap="companyDetail.marketCap" 
            v-bind:unitOfAccount="unitOfAccount"
            v-bind:logo="logo"
            v-bind:logoText="companyDetail.companyName"/>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
          <CompanyValuationMarginOfSafety v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
            v-bind:valuation="companyValuation.mosValuation[0]" 
            v-bind:marketCap="companyDetail.marketCap" 
            v-bind:unitOfAccount="unitOfAccount"
            v-bind:logo="logo"
            v-bind:logoText="companyDetail.companyName"/>
        </v-col>          
        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
          <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
            <CompanyValuationPaybackTimeFreeCashflow v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
              v-bind:valuation="companyValuation.paybackTimeValuationFreeCashflow[0]" 
              v-bind:marketCap="companyDetail.marketCap" 
              v-bind:unitOfAccount="unitOfAccount"
              v-bind:logo="logo"
              v-bind:logoText="companyDetail.companyName"/>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
          <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
            <CompanyValuationPaybackTimeOwnerEarnings v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
              v-bind:valuation="companyValuation.paybackTimeValuationOwnerEarnings[0]" 
              v-bind:marketCap="companyDetail.marketCap" 
              v-bind:unitOfAccount="unitOfAccount"
              v-bind:logo="logo"
              v-bind:logoText="companyDetail.companyName"/>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
          <CompanyValuationNetCurrentAssetValue v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
            v-bind:valuation="companyValuation" 
            v-bind:marketCap="companyDetail.marketCap" 
            v-bind:unitOfAccount="unitOfAccount"
            v-bind:logo="logo"
            v-bind:logoText="companyDetail.companyName"/>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
          <CompanyValuationPeterLynchPEGRatio v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
            v-bind:valuation="companyValuation.peterLynchPEGRatio[0]" 
            v-bind:marketCap="companyDetail.marketCap" 
            v-bind:unitOfAccount="unitOfAccount"
            v-bind:logo="logo"
            v-bind:logoText="companyDetail.companyName"/> 
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
          <CompanyValuationGrahamNumber v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
            v-bind:valuation="companyValuation.grahamNumberValuation[0]" 
            v-bind:marketCap="companyDetail.marketCap" 
            v-bind:unitOfAccount="unitOfAccount"
            v-bind:logo="logo"
            v-bind:logoText="companyDetail.companyName"/> 
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
          <CompanyValuationDCFFreeCashflow v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
            v-bind:valuation="companyValuation.dcfValuationFreeCashflow[0]" 
            v-bind:marketCap="companyDetail.marketCap" 
            v-bind:unitOfAccount="unitOfAccount"
            v-bind:logo="logo"
            v-bind:logoText="companyDetail.companyName"/> 
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <CompanyValuationHistorical v-if="companyValuation && companyValuation.isValid && marketCapData && unitOfAccount" 
            v-bind:valuationData="companyValuation" 
            v-bind:marketCapData="marketCapData" 
            v-bind:unitOfAccount="unitOfAccount" 
            v-bind:height="historicalValuationHeight" 
            v-bind:width="historicalValuationWidth"
            v-bind:companyName="companyDetail.companyName" 
            v-bind:tweet="true"/>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
