/* eslint-disable */
<script>
  export default {
    name: 'SVGFullsize',
    props: {
      svg: {
        type: String,
        required: true,
        default: () => null,
      },
      svgId: {
        type: String,
        required: true,
        default: () => null,
      },
    },
    methods: {
      updateSVGViewBoxById(name) {
        let svg = document.querySelector('#' + name);
        if(svg == undefined) {
          return;
        }
        let elements = svg.querySelectorAll('circle, ellipse, image, line, path, polygon, polyline, rect, text, use, textPath, tspan, foreignObject');
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

        elements.forEach(element => {
          let bbox = element.getBBox();

          minX = Math.min(minX, bbox.x);
          minY = Math.min(minY, bbox.y);
          maxX = Math.max(maxX, bbox.x + bbox.width);
          maxY = Math.max(maxY, bbox.y + bbox.height);
        });

        let viewBoxWidth = maxX-minX;
        let viewBoxHeight = maxY-minY;

        // Calculate the new dimensions based on the aspect ratio and the specified maximum width/height
        let newWidth = viewBoxWidth;
        let newHeight = viewBoxHeight;
        let aspectRatio = viewBoxWidth / viewBoxHeight;

        if (this.maxWidth) {
          newWidth = this.maxWidth;
          newHeight = newWidth / aspectRatio;
        }

        if (this.maxHeight) {
          newHeight = this.maxHeight;
          newWidth = newHeight * aspectRatio;
        }

        // Apply the new viewbox and dimensions to the SVG
        svg.setAttribute('viewBox', `${minX} ${minY} ${viewBoxWidth} ${viewBoxHeight}`);
      },
    },
    mounted() {
      this.$nextTick(function () {
        this.updateSVGViewBoxById(this.svgId);
      })
    },
    watch: {
      svg(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$nextTick(function () {
            this.updateSVGViewBoxById(this.svgId);
          })
        }
      },
    },
  }
</script>

<style scoped>

.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="pa-0 svg-container" v-html="svg"></div>
</template>

/* eslint-enable */ 