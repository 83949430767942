
<script>
import { sessionManager } from '@/main';
import UserWatchlist from '@/components/user-watchlist.vue';
import UserWelcome from '@/components/user-welcome.vue';
import UserSettings from '@/components/user-settings.vue';
import { logUserEvent } from '../helper';

export default {
  name: 'UserDashboard',
  components: {
    UserWelcome,
    UserSettings,
    UserWatchlist,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Dashboard',
          welcome: 'Welcome',
          watchlist: 'Watchlist',
          settings: 'Settings',
        },
        de: {
          title: 'Dashboard',
          welcome: 'Willkommen',
          watchlist: 'Watchlist',
          settings: 'Einstellungen',
        },
      },
      language: '',
      unitOfAccount: null,
      tabs: null,
    };
  },
  
  created() {
    this.language = sessionManager.read('language');
    this.unitOfAccount = sessionManager.read('unitOfAccount');
    document.title = this.strings['title'] + ' - Valuetion';
    logUserEvent('dashboard', sessionManager, this.$vuetify.breakpoint.mobile);
  },
  computed: {
    strings() {
      return this.languageStrings[this.language];
    },
  },
  methods: {
  },
}
</script>

<style scoped>
  .v-tab {
    text-transform: none !important;
    font-size: 18px;
    font-family: Plus Jakarta Sans;
    color: #1d270f;
    letter-spacing: 0em;
    padding-left: 8px;
    font-weight: bold;
  }
  .v-tab--active {
    text-transform: none !important;
    font-size: 18px;
    font-family: Plus Jakarta Sans;
    color: #1d270f;
    padding-left: 8px;
    letter-spacing: 0em;
    font-weight: bold;
  }
</style>



<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div class="w-full">
      <v-tabs v-model="tabs" grow show-arrows height="48px">
        <v-tab key="DashboardWatchlist">{{strings['watchlist']}}</v-tab>
        <v-tab key="DashboardSettings">{{strings['settings']}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs" touchless>
        <v-tab-item key="DashboardWatchlist">
          <UserWatchlist 
            v-bind:unitOfAccount="unitOfAccount"
          />
        </v-tab-item>
        <v-tab-item key="DashboardSettings">
          <UserSettings />
        </v-tab-item>
      </v-tabs-items>     
    </div> 
  </div>
</template>


