/* eslint-disable */
<script>

import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { formatUnitOfAccountChart } from '../helper';
import { Colors } from '../colors';
import { sessionManager } from '@/main';
import PopupSaveContainer from '@/components/popup-save-container.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'OverviewGraphBalanceBank',
  props: {
    balanceData: {
      type: Object,
      required: true,
      default: () => [],
    },
    unitOfAccount: {
      type: String,
      required: true,
      default: () => 'USD',
    },
    companyName: {
      type: String,
      default: () => ''
    },
    tweet: {
      type: Boolean,
      required: false,
      default: () => false,
    }
  },
  components: {
    Bar,
    PopupSaveContainer,
  },
  data() {
    return {
      datasetIdKey: 'label',
      cssClasses: '',
      styles: {},
      chartData: {},
      chartOptions: {},
      plugins: [],
      languageStrings: {
        en: {
          title: 'Bank Balance',
          total_loans: 'Total Loans',
          total_deposits: 'Total Deposits',
          cash_due_from_banks: 'Cash due from banks',
          total_equity: 'Total Equity',
        },
        de: {
          title: 'Bankbilanz',
          total_loans: 'Gesamtkredite',
          total_deposits: 'Gesamteinlagen',
          cash_due_from_banks: 'Forderungen an Kreditinstitute',
          total_equity: 'Eigenkapital',
        }
      },
      language: '',
      chartKey: 0,
      width: 200,
      height: 100,
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  mounted() {
    this.updateChartOptions();
    window.addEventListener('resize', this.updateChartOptions); 

    this.$nextTick(() => {
      // Check if the ref is available and then adjust the height
      if (this.$refs.chartContainer) {
        this.adjustChartHeight();
      }
      window.addEventListener('resize', this.adjustChartHeight);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateChartOptions); 
    window.removeEventListener('resize', this.adjustChartHeight);
  },
  methods: {
    adjustChartHeight() {
      const widthThreshold = 300;
      const widthThreshold2 = 380;

      if (this.$refs && this.$refs.chartContainer) {
        const chartContainer = this.$refs.chartContainer;
        if (chartContainer.offsetWidth < widthThreshold) {
          this.width = 100;
          this.height = 100;
        } 
        else if(chartContainer.offsetWidth < widthThreshold2) {
          this.width = 150;
          this.height = 100;
        }
        else {
          this.width = 200;
          this.height = 100;
        }
      }

      this.chartKey += 1;

    },
    // transforms the data into the expected structure of the bar component
    initializeData() {
      this.chartData.datasets = [];
      this.chartData.labels = []

      var totalLoans = [];
      var totalDeposits = [];
      var cashDueFromBanks = [];
      var totalEquity = [];

      var length = 10;

      if(!this.balanceData)
      {
        return;
      }

      // add the entry of the last quarter to the chart
      if(this.balanceData.balanceQuarter.length > 0)
      {
        var lastQuarter = this.balanceData.balanceQuarter[0];
        totalLoans.push(lastQuarter.totalBankLoans);
        totalDeposits.push(lastQuarter.totalBankDeposits);
        cashDueFromBanks.push(lastQuarter.cashDueFromBanks);
        totalEquity.push(lastQuarter.totalEquity);
        var date = new Date(Date.parse(lastQuarter.date));
        this.chartData.labels.push(date.getFullYear() + ' Q' + (Math.floor(date.getMonth() / 3 + 1)).toFixed(0));
      }

      let i = 0; 
      this.balanceData.balanceAnnual.forEach(element => {
        if(i < length)
        {
          totalLoans.push(element.totalBankLoans);
          totalDeposits.push(element.totalBankDeposits);
          cashDueFromBanks.push(element.cashDueFromBanks);
          totalEquity.push(element.totalEquity);
          var date = new Date(Date.parse(element.date));
          this.chartData.labels.push(date.getFullYear());
        }
        i = i + 1;
      });

      totalLoans.reverse();
      totalDeposits.reverse();
      cashDueFromBanks.reverse();
      totalEquity.reverse();
      this.chartData.labels.reverse();
      
      this.chartData.datasets = [
        {
          label: this.strings['total_loans'],
          data: totalLoans,
          backgroundColor: Colors.Orange, 
        },
        {
          label: this.strings['total_deposits'],
          data: totalDeposits,
          backgroundColor: Colors.Blue9, 
        },
        {
          label: this.strings['cash_due_from_banks'],
          data: cashDueFromBanks,
          backgroundColor: Colors.GreenOKLight, 
        },
        {
          label: this.strings['total_equity'],
          data: totalEquity,
          backgroundColor: Colors.Blue6, 
        },
      ];

      // remove & adjust
      this.chartOptions = {
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: (value, index, ticks)  => {
                        return formatUnitOfAccountChart(value, this.unitOfAccount, this.language);
                    }
                }
            }
        },
        responsive: true,
      };
    },
    updateChartOptions() {
      const isMobile = window.innerWidth < 380; // You can adjust the width as per your need

      this.chartOptions = {
        scales: {
          y: {
            ticks: {
              display: !isMobile, // Hide on mobile screens
              callback: (value) => {
                return formatUnitOfAccountChart(value, this.unitOfAccount, this.language);
              }
            }
          }
        },
        responsive: true,
      };
    },
  },
  beforeMount() {
    this.initializeData();
  },
}
</script>

<template>
  <div>
    <div v-if="tweet">
      <div class="flex-auto">
        <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">
          {{ companyName }} {{ strings['title'] }}
        </h3>
      </div>
      <Bar
        :key="chartKey"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
      <div>
        <div class="flex justify-end">
          <div class="flex-none text-v-base font-pj pt-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
          </div>
          <div class="flex-none text-v-base font-pj pt-v-md pl-v-sm"><b>value</b>tion</div>
        </div>
      </div>      
    </div>
    <PopupSaveContainer v-if="!tweet" v-bind:title="strings['title']" v-bind:companyName="companyName" :popup="true">
      <template v-slot:content>
        <Bar
          :key="chartKey"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </template>    
      <template v-slot:content_popup>
        <Bar
          :key="chartKey"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </template>    
    </PopupSaveContainer>
  </div>  
</template>

/* eslint-enable */ 