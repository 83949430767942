/* eslint-disable */
<script>

import { createBalanceStatementGraph } from '../balance-graph';
import SVGSave from '@/components/svg-save.vue';
import { Colors } from '../colors';
import { sessionManager } from '@/main';
import { updateCompanyFinancialsGraph } from './company-financials-helper';

  export default {
    name: 'CompanyFinancialsBalanceGraph',
    props: {
      balance: {
        type: Object,
        required: true,
        default: () => null,
      },
      unitOfAccount: {
        type: String,
        required: true,
        default: () => 'USD',
      },
      companyName: {
        type: String,
        required: true,
        default: () => '',
      },
      exchangeShortName: {
        type: String,
        required: true,
        default: () => '',
      },
      symbol: {
        type: String,
        required: true,
        default: () => '',
      },
      logo: {
        type: Boolean,
        default: () => false
      },
      logoText: {
        type: String,
        default: () => ''
      },
      navigation: {
        type: Boolean,
        default: () => true
      },
      allowSave: {
        type: Boolean,
        default: () => true
      },
    },
    components: {
      SVGSave,
    },
    data() {
      return {
        languageStrings: {
          en: {
            balance: 'Balance Statement',
            ttm: 'TTM',   
          },
          de: {
            balance: 'Bilanz',
            ttm: 'letzte 12 Monate',
          }
        },
        date: null,
        warningColor: Colors.RedOrange,
        language: '',
        tabData: null,
        tabs: null,
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    methods: {
      isFH() {
        return this.balance.type == "FHNormal" || this.balance.type == "FHInsurance" || this.balance.type == "FHBank";
      },
      updateGraph() {
        this.tabData = updateCompanyFinancialsGraph({
          dataQuarter: this.balance.balanceQuarter,
          dataTTM: null,
          dataAnnual: this.balance.balanceAnnual,
          graphName: 'Balance',
          graphType: this.balance.type,
          hasTTM: false,
          createGraphFunction: createBalanceStatementGraph,
          fontSizeBase: 0.85,
          isFH: this.isFH(),
          strings: this.strings,
          unitOfAccount: this.unitOfAccount,
          language: this.language,
        });  
      },
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
      smallScreen() {
        return this.$vuetify.breakpoint.smAndDown;
      },
    },
    beforeMount() {
      this.updateGraph();
    }
  }
</script>

<style scoped>
.v-tab {
    text-transform: none !important;
    font-size: 15px;
    font-family: Plus Jakarta Sans;
    color: #3f4d29;
    letter-spacing: 0em;
  }
</style>

<template>
  <div v-if="balance">
    <h3 class="darkest font-pj text-v-xl text-bold py-v-sm">{{strings['balance']}}</h3> 
    <v-tabs v-model="tabs" grow show-arrows>
      <v-tab v-for="entry in tabData" :key="entry.key">{{ entry.title }}</v-tab>
      <v-tabs-items v-model="tabs" touchless>
        <v-tab-item v-for="entry in tabData" :key="entry.key" transition="false" reverse-trasition="false">  
          <SVGSave :svg_small="entry.content_Small" :svg_big="entry.content_Big" :svgId="entry.key" :title="entry.save_title" :companyName="companyName" :popup="false" :allowSave="allowSave"/>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

/* eslint-enable */ 