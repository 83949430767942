/* eslint-disable */


//---------------------------------------------------------------------------------------------------------/
// Premium Features
//---------------------------------------------------------------------------------------------------------/
export function isPremiumFeatureEnabled() {
  return false;
}

//---------------------------------------------------------------------------------------------------------/
// ETF Feature
//---------------------------------------------------------------------------------------------------------/
export function isETFFeatureEnabled() {
  return false;
}


/*---------------------------------------------------------------------------------------------------------/
// requires login
/---------------------------------------------------------------------------------------------------------*/
export function requiresLogin() {
  return false;
}


/*---------------------------------------------------------------------------------------------------------/
// has waitlist
/---------------------------------------------------------------------------------------------------------*/
export function hasWaitlist() {
  return false;
}

