<script>
/* eslint-disable */
import CompanyInfoTable from '@/components/company-info-table.vue';
import CompanyDescription from '@/components/company-description.vue';
import CompanyImage from '@/components/company-image.vue';
import CompanyValuationTable from '@/components/company-valuation-table.vue';
import CompanyValuationChart from '@/components/company-valuation-chart.vue';
import OverviewGraphCashflow from '@/components/overview-graph-cashflow.vue';
import OverviewGraphBalance from '@/components/overview-graph-balance.vue';
import OverviewGraphBalanceBank from './overview-graph-balance-bank.vue';
import OverviewGraphIncome from '@/components/overview-graph-income.vue';
import overviewGraphInvestedCapital from '@/components/overview-graph-invested-capital.vue';
import CompanyValuationSimpleMetrics from '@/components/company-valuation-simple-metrics.vue';
import CompanyValuationTenCapEarnings from '@/components/company-valuation-ten-cap-earnings.vue';
import CompanyValuationTenCapFreeCashflow from '@/components/company-valuation-ten-cap-free-cashflow.vue';
import CompanyValuationTenCapOwnerEarnings from '@/components/company-valuation-ten-cap-owner-earnings.vue';
import CompanyValuationMarginOfSafety from '@/components/company-valuation-margin-of-safety.vue';
import CompanyValuationPaybackTimeFreeCashflow from '@/components/company-valuation-payback-time-free-cashflow.vue';
import CompanyValuationPaybackTimeOwnerEarnings from '@/components/company-valuation-payback-time-owner-earnings.vue';
import CompanyValuationNetCurrentAssetValue from '@/components/company-valuation-net-current-asset-value.vue';
import CompanyValuationPeterLynchPEGRatio from '@/components/company-valuation-peter-lynch-peg-ratio.vue';
import CompanyValuationGrahamNumber from '@/components/company-valuation-graham-number.vue';
import CompanyValuationDCFFreeCashflow from '@/components/company-valuation-dcf-free-cashflow.vue';
import CompanyValuationHistorical from '@/components/company-valuation-historical.vue';
import CompanyFinancialsIncomeGraph from '@/components/company-financials-income-graph.vue';
import CompanyFinancialsBalanceGraph from '@/components/company-financials-balance-graph.vue';
import CompanyFinancialsCashflowGraph from '@/components/company-financials-cashflow-graph.vue';
import CompanyFinancialsIncome from '@/components/company-financials-income.vue';
import CompanyFinancialsBalance from '@/components/company-financials-balance.vue';
import CompanyFinancialsCashflow from '@/components/company-financials-cashflow.vue';
import CompanyNews from '@/components/company-news.vue';
import CompanySECFilings from './company-sec-filings.vue';
import LegalDisclaimer from '@/components/legal-disclaimer.vue';


import { replaceUnderscoreWithDot, 
  formatUnitOfAccount, toUnitOfAccount, getCompanyFinancialsCashFlow,
  logUserEvent,
 } from '../helper';
import CompanyGrowth from './company-growth.vue';
import CompanyManagement from './company-management.vue';
import { Colors } from '../colors';
import { sessionManager } from '@/main';
import * as settings from '../settings';
import OverviewGraphInvestedCapital from './overview-graph-invested-capital.vue';


export default {
  name: 'CompanyPage',
  props: {
    symbol: {
      type: String,
      required: true,
      default: () => null,
    },
    tab: {
      type: String,
      default: () => 'overview',
    },
    logo: {
      type: Boolean,
      default: () => false
    },
  },
  components: {
    CompanyInfoTable,
    CompanyDescription,
    CompanyImage,
    CompanyGrowth,
    CompanyManagement,
    CompanyValuationTable,
    CompanyValuationChart,
    OverviewGraphCashflow,
    OverviewGraphBalance,
    OverviewGraphBalanceBank,
    OverviewGraphIncome,
    OverviewGraphInvestedCapital,
    CompanyValuationSimpleMetrics,
    CompanyValuationTenCapEarnings,
    CompanyValuationTenCapFreeCashflow,
    CompanyValuationTenCapOwnerEarnings,
    CompanyValuationMarginOfSafety,
    CompanyValuationPaybackTimeFreeCashflow,
    CompanyValuationPaybackTimeOwnerEarnings, 
    CompanyValuationNetCurrentAssetValue,
    CompanyValuationPeterLynchPEGRatio,
    CompanyValuationGrahamNumber,
    CompanyValuationDCFFreeCashflow,
    CompanyValuationHistorical,
    CompanyFinancialsIncomeGraph,
    CompanyFinancialsBalanceGraph,
    CompanyFinancialsCashflowGraph,
    CompanyFinancialsIncome,
    CompanyFinancialsBalance,
    CompanyFinancialsCashflow,
    CompanySECFilings,
    LegalDisclaimer,
    CompanyNews,
},
  data() {
    return {
      languageStrings: {
        en: {
          overview: 'Overview',
          valuation: 'Valuation',
          financials: 'Financials',
          filings: 'Filings',
          consistency_warning: 'Warning: Inconsistent data from the data provider.',
          financial_statements: 'Financial Statements',
          financial_statements_text: 'Income, balance and cashflow statement of',
          reported_in: 'reported in',
          on_sale: 'price',
          follow: 'Follow',
          following: 'Following',
          unfollow: 'Unfollow',
          news: 'News',
          
        },
        de: {
          overview: 'Übersicht',
          valuation: 'Bewertung',
          financials: 'Finanzen',
          filings: 'Meldungen',
          consistency_warning: 'Warnung: Inkonsistente Daten vom Datenanbieter.',
          financial_statements: 'Finanzberichte',
          financial_statements_text: 'Gewinn- und Verlustrechnung, Bilanz und Kapitalflussrechnung von',
          reported_in: 'gemeldet in',
          on_sale: 'Preis',
          follow: 'Folgen',
          following: 'Folge ich',
          unfollow: 'Nicht mehr folgen',
          news: 'News',
        }
      },
      companyDetail: null,
      companyValuation: null,
      companySECFilings: null,
      cashFlowData: null,
      cashFlowDataFull: null,
      balanceData: null,
      balanceDataFull: null,
      incomeData: null,
      incomeDataFull: null,
      marketCapData: null,
      equityValueData: null,
      tabs: null,
      annualPeriod: true,
      financialsSlider: 0,
      warningColor: Colors.RedOrange,
      language: '',
      isOnWatchlist: false,
      watchlistHovering: false,
      reportedCurrency: '',
      showDebug: false,
      isBank: false,
    };
  },
  mounted() {
    document.title = this.cleanTickerSymbol + ' - Valuetion';
  },
  created() {
    this.language = sessionManager.read('language');
    this.unitOfAccount = sessionManager.read('unitOfAccount');

    switch(this.tab)
    {
      case 'overview':
        this.tabs = 0;
        break;
      case 'valuation':
        this.tabs = 1;
        break;
      case 'financials':
        this.tabs = 2;
        break;
      case 'news':
        this.tabs = 3;
        break;
      default:
        this.tabs = 0;
    }

    if(sessionManager.isAdmin())
    {
      this.showDebug = true;
    } 

    logUserEvent('companyPage', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
  },
  methods: {
    // add the company to the watchlist
    async addToWaitlist() {
      // send email and password to the API to login the user
      const url = '/api/HttpTriggerWatchlist';
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
        body: JSON.stringify({
           action: 'add', 
           id: this.cleanTickerSymbol, 
        }),
      }).then((response, reject) => {
        if (response.status === 200) {
          // show the popup with the success message
          this.isOnWatchlist = true;
          logUserEvent('addToWatchlist', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
        }
      });
    },
    // remove the company from the watchlist
    async removeFromWaitlist() {
      // send email and password to the API to login the user
      const url = '/api/HttpTriggerWatchlist';
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
        body: JSON.stringify({
           action: 'remove', 
           id: this.cleanTickerSymbol, 
        }),
      }).then((response, reject) => {
        if (response.status === 200) {
          // show the popup with the success message
          this.isOnWatchlist = false;
          logUserEvent('removeFromWatchlist', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
        }
      });
    },
    // method to get the company valuation
    async fetchCompanyData()
    {
      const urlFMP = '/api/HttpTriggerGetCompanyDataFMP?';
      const urlFH = '/api/HttpTriggerGetCompanyDataFH?';
      var url = '';

      if(sessionManager.read('useFinnhub'))
      {
        url = urlFH;
      }
      else
      {
        url = urlFMP;
      }
      
      const response = await fetch(url +
        new URLSearchParams({
          symbol: this.cleanTickerSymbol,
          unitOfAccount: this.unitOfAccount, 
          language: this.language,
        }), { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });

      // check if response is ok
      if(response.ok) {
        const data = await response.json();
        this.companyValuation = data.valuation;
        this.companyDetail = data.profile;
        this.cashFlowData = { 
          cashflowAnnual: data.cashFlowAnnual, 
          cashflowQuarter: data.cashFlowQuarter, 
          cashflowTTM: data.cashFlowTTM, 
          hasTTM: data.hasTTM,
        };
        this.cashFlowDataFull = { 
          cashflowAnnual: data.cashFlowAnnualFull, 
          cashflowQuarter: data.cashFlowQuarterFull, 
          cashflowTTM: data.cashFlowTTMFull, 
          hasTTM: data.hasTTM,
          type: data.type,
        };
        this.incomeData = { 
          incomeAnnual: data.incomeAnnual, 
          incomeQuarter: data.incomeQuarter, 
          incomeTTM: data.incomeTTM,
          hasTTM: data.hasTTM,
        };
        this.incomeDataFull = { 
          incomeAnnual: data.incomeAnnualFull, 
          incomeQuarter: data.incomeQuarterFull, 
          incomeTTM: data.incomeTTMFull,
          hasTTM: data.hasTTM,
          type: data.type,
        };
        this.reportedCurrency = data.reportedCurrency;
        this.balanceData = { 
          balanceAnnual: data.balanceAnnual, 
          balanceQuarter: data.balanceQuarter,
        };
        this.balanceDataFull = { 
          balanceAnnual: data.balanceAnnualFull, 
          balanceQuarter: data.balanceQuarterFull,
          type: data.type,
        };
        this.isBank = data.type == "FHBank";
        this.marketCapData = data.marketCap;
        this.isOnWatchlist = data.isOnWatchlist;

        document.title = this.companyDetail.companyName + ' - Valuetion';
      }
      else {
        // check if response is unauthorized
        if(response.status === 401) {
          // redirect to login page
          this.$router.push('/login');
        }
      }    
    },
    async updateCompanyDetail()
    {
      this.companyDetail = null;
      this.companyValuation = null;
      this.cashFlowData = null;
      this.balanceData = null;
      this.incomeData = null;
      this.marketCapData = null;

      // get company detail
      await this.fetchCompanyData();
    },
    updateSlider(val)
    {
      this.financialsSlider = val.slider;
    },
    updateAnnual(val)
    {
      this.annualPeriod = val.annual;
    },
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    dataDateString()
    {
      // Get the current time in milliseconds
      const now = new Date().getTime();

      // Convert the timestamp to milliseconds
      const timestampInMilliseconds = this.companyDetail.timestamp * 1000;

      // Calculate the difference in milliseconds
      const differenceInMilliseconds = now - timestampInMilliseconds;

      // Convert the difference from milliseconds to days
      const differenceInDays = differenceInMilliseconds / 1000 / 60 / 60 / 24;

      // Print the number of days
      if(differenceInDays < 5)
      {
        return '';
      }
      else
      {
        return '(' + differenceInDays.toFixed(0) + ' days ago)';
      }
    },
    cleanTickerSymbol()
    {
      return replaceUnderscoreWithDot(this.symbol);
    },
    marketCapFormatted()
    {
      const marketCap = toUnitOfAccount(this.companyDetail.marketCap, this.unitOfAccount);
      return formatUnitOfAccount(marketCap, this.unitOfAccount, true);
    },
    annualPeriodLabel()
    {
      if(this.annualPeriod)
      {
        return 'annual';
      }
      else
      {
        return 'quarter';
      }
    },
    historicalValuationHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 150
        case 'sm': return 50
        case 'md': return 50
        case 'lg': return 50
        case 'xl': return 25
      }
    },
    historicalValuationWidth ()
    {
      return 100;
    }
  },
  beforeMount() { 
    this.updateCompanyDetail();
  },
  watch: {
    symbol() {
      this.updateCompanyDetail();
    }
  },
}
</script>

<style scoped>
  .v-tab {
    text-transform: none !important;
    font-size: 18px;
    font-family: Plus Jakarta Sans;
    color: #1d270f;
    letter-spacing: 0em;
    padding-left: 8px;
    font-weight: bold;
    background-color: #f8f9f6;
  }
  .v-tab--active {
    text-transform: none !important;
    font-size: 18px;
    font-family: Plus Jakarta Sans;
    color: #1d270f;
    padding-left: 8px;
    letter-spacing: 0em;
    font-weight: bold;
    background-color: #f8f9f6;
  }

  .mobile-tabs :deep(.v-tab) {
    font-size: 15px !important;
    padding: 0 8px !important;
    min-width: auto !important;
  }
</style>

<template>
  <div class="flex flex-col">
    <LegalDisclaimer />
    <div class="bg-primary">

          <h2 v-if="companyDetail" :class="$vuetify.breakpoint.smAndDown ? 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md pb-v-sm' : 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md pb-v-sm'">{{ companyDetail.companyName }} ({{ companyDetail.exchangeShortName }}:{{ companyDetail.id }})</h2>
          <h2 v-else :class="$vuetify.breakpoint.smAndDown ? 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md' : 'text-center text-lightest text-v-xl font-pj font-bold pt-v-md'">{{ cleanTickerSymbol }}</h2>
          <p v-if="companyDetail && marketCapFormatted" class="text-center text-lightest text-v-base font-pj px-v-sm">{{ marketCapFormatted }}</p>
          <p v-else class="text-center text-lightest text-v-base font-pj px-v-sm"></p>


          <div v-if="companyDetail && companyDetail.isValid" class="flex justify-center pb-3">
            <button v-if="!isOnWatchlist" type="button" 
              class="text-v-sm flex-none bg-primary border rounded-md border-solid border-very-light-gray regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-very-light-gray" 
              @click="addToWaitlist">{{ strings['follow'] }}
            </button>

            <button v-if="isOnWatchlist" type="button" 
              class="text-v-sm flex-none bg-primary border rounded-md border-solid border-very-light-gray regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-very-light-gray" 
              @click="removeFromWaitlist"
              @mouseover="watchlistHovering = true" @mouseleave="watchlistHovering = false">
              {{ watchlistHovering ? strings['unfollow'] : strings['following'] }}
            </button>
          </div>
          <div v-else>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>

    </div>

    <div class="w-full">
      <v-tabs v-model="tabs" grow show-arrows height="48px" :class="['bg-very-very-light', {'mobile-tabs': isMobile}]">
        <v-tab key="CompanyPageOverview">{{strings['overview']}}</v-tab>
        <v-tab key="CompanyPageValuation">{{strings['valuation']}}</v-tab>
        <v-tab key="CompanyPageFinancials">{{strings['financials']}}</v-tab>
        <v-tab key="CompanyNews">{{strings['news']}}</v-tab>
        <!--<v-tab key="CompanyPageFilings">{{strings['filings']}}</v-tab>-->
      </v-tabs>
      <v-tabs-items v-model="tabs" touchless>
        <v-tab-item key="CompanyPageOverview">
          <v-row v-if="!companyDetail" class="font-pj">
            <v-col>
              <div class="flex justify-center p-v-xl">
                <div>
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row dense v-if="companyDetail && companyDetail.isValid">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <CompanyInfoTable v-if="companyDetail && companyDetail.isValid" v-bind:company="companyDetail" v-bind:unitOfAccount="unitOfAccount" />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <CompanyDescription v-if="companyDetail && companyDetail.isValid" v-bind:company="companyDetail"/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="4">
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <CompanyGrowth v-if="companyValuation" 
                  v-bind:moatData="companyValuation.moat[0]" 
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:logo="logo"
                  v-bind:logoText="logo ? companyDetail.companyName : ''"/>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="4">
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <CompanyManagement v-if="companyValuation" 
                  v-bind:managementData="companyValuation.management" 
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:logo="logo"
                  v-bind:logoText="logo ? companyDetail.companyName : ''"/>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="4">
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <CompanyValuationTable v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                  v-bind:symbol="cleanTickerSymbol" 
                  v-bind:valuation="companyValuation" 
                  v-bind:marketCap="companyDetail.marketCap" 
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:logo="logo"
                  v-bind:logoText="logo ? companyDetail.companyName : ''"/>
              </div>
            </v-col>            
            <v-col cols="12" sm="12" md="6" lg="6" xl="4">
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <OverviewGraphCashflow v-if="cashFlowData && unitOfAccount && companyDetail && companyDetail.isValid" 
                  v-bind:cashFlowData="cashFlowData" 
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:logo="logo"
                  v-bind:companyName="companyDetail.companyName"/>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="4">
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <OverviewGraphIncome v-if="incomeData && unitOfAccount" 
                  v-bind:incomeData="incomeData" 
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:companyName="companyDetail.companyName"/>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="4">
              <div v-if="isBank" class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <OverviewGraphBalanceBank v-if="balanceData && unitOfAccount" 
                  v-bind:balanceData="balanceData" 
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:companyName="companyDetail.companyName"/>
              </div>
              <div v-else class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <OverviewGraphBalance v-if="balanceData && unitOfAccount" 
                  v-bind:balanceData="balanceData" 
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:companyName="companyDetail.companyName"/>
              </div>
            </v-col>
            <!--
            <v-col cols="12" sm="12" md="6" lg="6" xl="4">
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <OverviewGraphInvestedCapital v-if="balanceData && incomeData && unitOfAccount" 
                  v-bind:balanceData="balanceData" 
                  v-bind:incomeData="incomeData"
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:companyName="companyDetail.companyName"/>
              </div>
            </v-col>
            -->
          </v-row>
        </v-tab-item>
        <v-tab-item key="CompanyPageValuation"> 
          <v-row v-if="!companyValuation" class="font-pj">
            <v-col>
              <div class="flex justify-center p-v-xl">
                <div>
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="companyDetail && companyValuation && companyValuation.isValid">
            <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
              <CompanyValuationSimpleMetrics v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="9" xl="6">
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
              <CompanyValuationHistorical v-if="companyValuation && companyValuation.isValid && marketCapData && unitOfAccount" 
                v-bind:valuationData="companyValuation" 
                v-bind:marketCapData="marketCapData" 
                v-bind:marketCap="companyDetail.marketCap"
                v-bind:unitOfAccount="unitOfAccount" 
                v-bind:height="historicalValuationHeight" 
                v-bind:width="historicalValuationWidth"
                v-bind:companyName="companyDetail.companyName"/>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
              <CompanyValuationTenCapEarnings v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation.tenCapEarningsValuation[0]" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
              <CompanyValuationTenCapFreeCashflow v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation.tenCapFreeCashflowValuation[0]" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
              <CompanyValuationTenCapOwnerEarnings v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation.tenCapOwnerEarningsValuation[0]" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3"> 
              <CompanyValuationMarginOfSafety v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation.mosValuation[0]" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/>
            </v-col>          
            <v-col cols="12" sm="12" md="6" lg="3" xl="3">
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <CompanyValuationPaybackTimeFreeCashflow v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                  v-bind:valuation="companyValuation.paybackTimeValuationFreeCashflow[0]" 
                  v-bind:marketCap="companyDetail.marketCap" 
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:logo="logo"
                  v-bind:logoText="companyDetail.companyName"/>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3">
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <CompanyValuationPaybackTimeOwnerEarnings v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                  v-bind:valuation="companyValuation.paybackTimeValuationOwnerEarnings[0]" 
                  v-bind:marketCap="companyDetail.marketCap" 
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:logo="logo"
                  v-bind:logoText="companyDetail.companyName"/>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3">
              <CompanyValuationNetCurrentAssetValue v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3">
              <CompanyValuationPeterLynchPEGRatio v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation.peterLynchPEGRatio[0]" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/> 
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3">
              <CompanyValuationGrahamNumber v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation.grahamNumberValuation[0]" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/> 
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3">
              <CompanyValuationDCFFreeCashflow v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation.dcfValuationFreeCashflow[0]" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="logo"
                v-bind:logoText="companyDetail.companyName"/> 
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item key="CompanyPageFinancials">
          <v-row v-if="!companyValuation" class="font-pj">
            <v-col>
              <div class="flex justify-center p-v-xl">
                <div>
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="companyDetail && companyValuation && !companyValuation.isValid">
            <v-col :style="{ 'background-color': warningColor }">
              <h5 align="center" :style="{ 'color': 'rgb(255,255,255)' }">{{strings['consistency_warning']}}</h5>
            </v-col>
          </v-row>
          <v-row v-if="companyDetail">
            <v-col>
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <CompanyFinancialsIncomeGraph 
                    v-if="incomeDataFull" 
                    v-bind:income="incomeDataFull" 
                    v-bind:exchangeShortName="companyDetail.exchangeShortName"
                    v-bind:symbol="companyDetail.id"
                    v-bind:unitOfAccount="unitOfAccount"
                    v-bind:logo="logo"
                    v-bind:companyName="companyDetail.companyName"/>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="companyDetail">
            <v-col>
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <CompanyFinancialsBalanceGraph 
                    v-if="balanceDataFull" 
                    v-bind:balance="balanceDataFull" 
                    v-bind:companyName="companyDetail.companyName" 
                    v-bind:exchangeShortName="companyDetail.exchangeShortName"
                    v-bind:symbol="companyDetail.id"
                    v-bind:unitOfAccount="unitOfAccount"
                    v-bind:logo="logo"
                    v-bind:logoText="companyDetail.companyName"/>
              </div>
            </v-col>
          </v-row>
          
          <v-row v-if="companyDetail">
            <v-col>
              <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
                <CompanyFinancialsCashflowGraph 
                    v-if="cashFlowDataFull" 
                    v-bind:cashflow="cashFlowDataFull" 
                    v-bind:exchangeShortName="companyDetail.exchangeShortName" 
                    v-bind:symbol="companyDetail.id"
                    v-bind:unitOfAccount="unitOfAccount"
                    v-bind:logo="logo"
                    v-bind:companyName="companyDetail.companyName"/>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <CompanyFinancialsIncome v-if="incomeDataFull" 
                v-bind:income="incomeDataFull" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:reportedCurrency="reportedCurrency"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CompanyFinancialsBalance v-if="balanceDataFull" 
                v-bind:balance="balanceDataFull" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:reportedCurrency="reportedCurrency"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CompanyFinancialsCashflow v-if="cashFlowDataFull" 
                v-bind:cashflow="cashFlowDataFull" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:reportedCurrency="reportedCurrency"/>
            </v-col>
          </v-row>  
        </v-tab-item>
        <v-tab-item key="CompanyNews">
          <CompanyNews v-bind:symbol="cleanTickerSymbol"/>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
