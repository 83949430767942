/* eslint-disable */
<script>

export default {
  name: 'GraphThumbnail',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => null,
    },
    width: {
      type: Number,
      required: true,
      default: () => 100,
    },
    height: {
      type: Number,
      required: true,
      default: () => 20,
    }
  },
  data() {
    return {
      graph: [],
    };
  },
  methods: {
    computeGraph()
    {
      // normalize the data
      var normalized = this.data;
      var halfHeight = this.height / 2;
      if(this.data.length > 0)
      {
        var max = this.data[0];
        var min = this.data[0];
        for(var i = 1; i < this.data.length; i++)
        {
          if(this.data[i] > max)
          {
            max = this.data[i];
          }
          if(this.data[i] < min)
          {
            min = this.data[i];
          }
        }

        // determine the size of the graph bars
        var size_y = max;
        if(min < 0)
        {
          if(-min > max)
          {
            size_y = -min;
          }
        }

        for(var i = 0; i < this.data.length; i++)
        {
          if(size_y == 0)
          {
            normalized[i] = 0;
            continue;
          }
          normalized[i] = 2*halfHeight * this.data[i] / size_y;
        }

        // build the graph
        var g = [];
        var bar_width = (this.width - this.data.length) / (this.data.length);
        var w = 1 + bar_width;
        var offset_y = 0;
        var offset_x = 0;     
        
        // compute offset in y direction
        var hasPos = max >= 0;
        var hasNeg = min <= 0;

        if(hasPos && hasNeg)
        {
          // both -> center
          offset_y = halfHeight; 
        }
        else if(hasPos && !hasNeg)
        {
          // only pos -> bottom
          offset_y = halfHeight*2;
          
        }
        else if(hasNeg && !hasPos)
        {
          // only neg -> top
          offset_y = 0;
        }

        var x = 0.0;
        for(i = 0; i < this.data.length; i++)
        {
          if(normalized[i] >= 0)
          {
            g.push({
              x: offset_x,
              y: offset_y - normalized[i],
              width: bar_width,
              height: normalized[i],
              fill: '#30B4EB',
            });
          }
          else
          {
            g.push({
              x: offset_x,
              y: offset_y,
              width: bar_width,
              height: -normalized[i],
              fill: '#EE5252',
            });
          }
          offset_x += w;
        }  
        
        return g;
      }
    }
  },
  mounted() {
    // initialization of UI content
    this.graph = this.computeGraph();
  },
  watch: {
    data: {
      handler(val) {
        this.graph = this.computeGraph();
      },
    },
  },
  computed: {
    heightInPixels() {
      return this.height + "px";
    },
    widthInPixels() {
      return this.width + "px";
    }
  }
}
</script>

<template>
  <div v-if="height > 0 && width > 0">
    <svg :width="widthInPixels" :height="heightInPixels">
      <rect v-for="entry in graph" :x="entry.x" :y="entry.y" :width="entry.width" :height="entry.height" :fill="entry.fill" stroke-width="0.5" opacity="1"></rect>
    </svg>
  </div>
</template>

/* eslint-enable */  