
<script>
import { sessionManager } from '@/main';

export default {
  name: 'PrivacyPolicy',
  props: {
    email: String,
    code: String,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Privacy Policy',
          headline: 'Privacy Policy',
        },
        de: {
          title: 'Datenschutzbestimmung',
          headline: 'Datenschutzbestimmung',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>

        <div v-if="language == 'de'">
          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Verantwortlicher für die Datenverarbeitung
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Der Verantwortliche für die Datenverarbeitung auf dieser Website ist:
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Peter Wey
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Flüehügel 2
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            5436 Würenlos, Schweiz
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            EMail: info@valuetion.com
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Tel: +41 76 372 08 00
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Gegenstand des Datenschutzes
          </div>
          <div class="darkest text-v-sm font-pj">
            Gegenstand des Datenschutzes sind personenbezogene Daten, die bei der Nutzung unserer Website erfasst werden, insbesondere bei der Registrierung auf unserer Warteliste oder bei der Erstellung eines Kontos.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Datenerfassung und Zweck der Verarbeitung
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Wir erheben und verarbeiten folgende personenbezogene Daten bei der Anmeldung auf unserer Warteliste:
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - E-Mail-Adresse
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Antwort auf die Fragen bei der Anmeldung
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl  pb-v-sm">
            - Sprache der verwendeten Website
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            Wir erheben und verarbeiten folgende personenbezogene Daten bei der Erstellung eines Kontos:
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - E-Mail-Adresse
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Name
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Adresse
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Nutzungsdaten & Cookies
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Kommunikationsdaten
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Vertragsdaten
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl pb-v-sm">
            - Zahlungsdaten
          </div>

          <div class="darkest text-v-sm font-pj">
            Die Verarbeitung dieser Daten erfolgt zum Zweck der Betreibung der Website, für Marketingmaßnahmen und um unseren Service zu verbessern. Mit der Registrierung auf unserer Warteliste oder der Erstellung eines Kontos stimmen Sie der Verarbeitung Ihrer Daten für diese Zwecke zu.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Datenweitergabe
          </div>
          <div class="darkest text-v-sm font-pj">
            Zum Zweck der Verarbeitung werden Ihre Daten an den Clouddienst Microsoft Azure weitergegeben. Die Übermittlung erfolgt unter Einhaltung der geltenden Datenschutzbestimmungen.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Cookies
          </div>
          <div class="darkest text-v-sm font-pj pb-v-md">
            Unsere Website verwendet funktionelle Cookies, um folgende Informationen zu speichern:
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Zustimmung zur Verwendung von Cookies
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Sprache der Website
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Login-Informationen
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl pb-v-sm">
            - Einstellungen der Website
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Rechte der betroffenen Person
          </div>
          <div class="darkest text-v-sm font-pj">
            Als betroffene Person haben Sie das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung und Widerspruch gegen die Verarbeitung Ihrer Daten sowie das Recht auf Datenübertragbarkeit. Sie können diese Rechte durch Kontaktaufnahme mit uns ausüben. Darüber hinaus haben Sie das Recht, sich bei einer Datenschutz-Aufsichtsbehörde zu beschweren.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Kontaktmöglichkeit
          </div>
          <div class="darkest text-v-sm font-pj">
            Für Fragen oder Anliegen bezüglich Ihrer personenbezogenen Daten können Sie sich jederzeit an uns wenden. Unsere Kontaktdaten finden Sie oben im Abschnitt "Verantwortlicher für die Datenverarbeitung".
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Änderungen der Datenschutzbestimmung
          </div>
          <div class="darkest text-v-sm font-pj">
            Wir behalten uns das Recht vor, diese Datenschutzbestimmung jederzeit zu ändern, um sie an geänderte gesetzliche Vorgaben oder Änderungen unseres Serviceangebots anzupassen. Die jeweils aktuelle Fassung der Datenschutzbestimmung ist auf unserer Website einsehbar.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-xl  pt-v-xl">
            Datum: 20. Juli 2023
          </div>
        </div>
        <div v-else>
          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Data Controller
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            The data controller responsible for data processing on this website is:
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Peter Wey
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Flüehügel 2
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            5436 Würenlos, Schweiz
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            EMail: info@valuetion.com
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            Tel: +41 76 372 08 00
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Subject of Data Protection
          </div>
          <div class="darkest text-v-sm font-pj">
            The subject of data protection includes personal data collected when using our website, particularly during registration on our waiting list or when creating an account.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Data Collection and Purpose of Processing
          </div>
          <div class="darkest text-v-sm font-pj pb-v-sm">
            We collect and process the following personal data:
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Email address
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Answer to the questions during the registration
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl  pb-v-sm">
            - Language of the used website
          </div>
          <div class="darkest text-v-sm font-pj">
            The processing of this data is for the purpose of operating the website, for marketing measures, and to improve our service. By registering on our waiting list or creating an account, you consent to the processing of your data for these purposes.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Data Transfer
          </div>
          <div class="darkest text-v-sm font-pj">
            For the purpose of processing, your data will be transferred to the cloud service Microsoft Azure. The transmission takes place in compliance with applicable data protection regulations.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Cookies
          </div>
          <div class="darkest text-v-sm font-pj pb-v-md">
            Our website uses functional cookies to store the following information:
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Consent to the use of cookies
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Language of the website
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl">
            - Login information
          </div>
          <div class="darkest text-v-sm font-pj pl-v-xl pb-v-sm">
            - Website settings
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Rights of the Data Subject
          </div>
          <div class="darkest text-v-sm font-pj">
            As a data subject, you have the right to access, rectification, erasure, restriction of processing, and objection to the processing of your data, as well as the right to data portability. You can exercise these rights by contacting us. Furthermore, you have the right to lodge a complaint with a data protection supervisory authority.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Contact Information
          </div>
          <div class="darkest text-v-sm font-pj">
            For questions or concerns regarding your personal data, you can contact us at any time. Our contact details can be found above in the "Data Controller" section.
          </div>

          <div class="darkest text-v-base font-pj font-bold pt-v-xl">
            Changes to the Privacy Policy
          </div>
          <div class="darkest text-v-sm font-pj">
            We reserve the right to change this privacy policy at any time to adapt it to changed legal requirements or changes in our service offerings. The current version of the privacy policy can always be viewed on our website.
          </div>
          <div class="darkest text-v-sm font-pj pb-v-xl pt-v-xl">
            Date: July 20th 2023
          </div>
        </div>
      </div>
    </div>
  
  </div>
</template>


