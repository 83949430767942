/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyManagementInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Management',
            subtitle: 'Can we infer management quality from financial data?',
            text: 'Drawing direct conclusions about management quality from a company\'s financial data is complex. However, there are certain indicators that can provide insights into the performance of management.',

            debt: 'Leverage',
            debt_2: 'Additional debt provides investment opportunities that can generate revenue. However, it\'s crucial that the company can manage its debt obligations. As an indicator of leverage, we use the number of years required to repay the debt with operational cash flow, assuming all investments are halted and only debts are serviced. Especially when interest rates are rising, the leverage ratio should not exceed 3 years.',

            payback: 'Shareholder Paybacks',
            payback_2: 'If the company lacks profitable investment opportunities, profits should be distributed to shareholders, either in the form of dividends or share buybacks.',
            payback_3: 'Sustainable management ensures that either sufficient revenue is generated to cover paybacks, or sufficient equity is present.',
            payback_4: 'Share buybacks are particularly attractive at low share prices, as shareholders get more shares for their money.',
          },
          de: {
            title: 'Management',
            subtitle: 'Können wir die Managementqualität aus Finanzdaten ableiten?',
            text: 'Eine direkte Ableitung der Managementqualität aus Finanzdaten einer Firma ist komplex. Dennoch gibt es einige Anhaltspunkte, die Rückschlüsse auf die Managementperformance zulassen.',
  
            debt: 'Verschuldungsgrad',
            debt_2: 'Zusätzliches Fremdkapital eröffnet Investitionsmöglichkeiten, die Einnahmen generieren können. Es ist jedoch essenziell, dass das Unternehmen seine Schulden begleichen kann. Als Indikator für die Verschuldung verwenden wir die Anzahl Jahre, die zur Tilgung der Schulden mit dem operativen Cashflow nötig wären, wenn auf Investitionen verzichtet und nur Schulden getilgt würden. Besonders bei steigenden Zinsen sollte der Verschuldungsgrad 3 Jahre nicht übersteigen.',

            payback: 'Rückzahlungen an Aktionäre',
            payback_2: 'Wenn das Unternehmen keine weiteren profitablen Investitionsmöglichkeiten hat, sollten Gewinne an die Aktionäre ausgezahlt werden, entweder als Dividenden oder Aktienrückkäufe.',
            payback_3: 'Nachhaltiges Management gewährleistet, dass entweder ausreichend Geld generiert wird, um die Rückzahlungen zu decken, oder dass ausreichend Eigenkapital vorhanden ist.',
            payback_4: 'Aktienrückkäufe sind besonders attraktiv bei niedrigen Kursen, da die Aktionäre so mehr Anteile für ihr Geld erhalten.',
          }
        },

        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>
  
<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['subtitle'] }}</div>
      <div class="text-darkest font-pj text-v-sm py-v-md">{{ strings['text'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">
        <span v-if="language=='de'">
          Rendite
        </span>
        <span v-else>
          Return
        </span>
      </div>
      <div class="text-darkest text-v-sm font-pj py-v-md">
        <span v-if="language=='de'">
          Die Rendite spiegelt die Qualität des Managements wider, wobei höhere Renditen auf eine bessere Leistung des Managements hindeuten. 
          Da Renditen je nach Branche variieren, ist ein Vergleich mit der Konkurrenz notwendig. 
          Ein Anstieg der Renditen im Laufe der Zeit, insbesondere nach Akquisitionen, deutet auf gutes Management hin.
        </span>
        <span v-else>
          Return serves as an indicator of management quality, with higher returns signaling better management performance. 
          Since returns vary by industry, comparing with competitors is essential. 
          An increase in returns over time, especially following acquisitions, suggests effective management.
        </span>
      </div>


      <div class="text-darkest font-pj text-v-base font-bold pt-v-md">
        <span v-if="language=='de'">
          Eigenkapitalrendite (ROE)
        </span>
        <span v-else>
          Return on Equity (ROE)
        </span>
      </div>  
      <div class="text-darkest text-v-sm font-pj py-v-md">
        <span v-if="language=='de'">
          Die Eigenkapitalrendite (ROE) misst den mit dem Eigenkapital erzielten Ertrag und zeigt, wie effizient das Management das Eigenkapital nutzt, um Gewinne zu erzielen.
        </span>
        <span v-else>
          The return on equity (ROE) measures how effectively a company uses shareholders' equity to generate profits, 
          indicating the efficiency of management in creating value for investors.
        </span>
      </div>
      <div class="text-darkest text-v-sm font-pj py-v-md">
        <span v-if="language=='de'">
          <div class="text-darkest text-v-sm font-pj pb-v-md">
              <strong>Formel:</strong><br>
              ROE = Gewinn / Eigenkapital
          </div>
          <div class="text-darkest text-v-sm font-pj pb-v-md"><strong>Erklärung:</strong></div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">Gewinn: Profit nach Steuern und allen Ausgaben.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">Eigenkapital: Eigenkapital.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">Eigenkapitalrendite misst den mit dem Eigenkapital erzielten Ertrag und zeigt, wie effizient das Management das Eigenkapital nutzt, um Gewinne zu erzielen.</div>
        </span>
        <span v-else>
          <div class="text-darkest text-v-sm font-pj pb-v-md">
              <strong>Formula:</strong><br>
              ROE = Net Income / Equity
          </div>
          <div class="text-darkest text-v-sm font-pj pb-v-md"><strong>Explanation:</strong></div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">Net Income: Profit after taxes and all expenses.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">Equity: Shareholders' equity.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">ROE measures the return generated from the shareholders' equity, indicating how efficiently the management is using the equity to generate profits.</div>
        </span>        
      </div>

      <div class="text-darkest font-pj text-v-base font-bold pt-v-md">
        <span v-if="language=='de'">
          Gesamtkapitalrendite (ROIC)
        </span>
        <span v-else>
          Return on Invested Capital (ROIC)
        </span>
      </div>  
      <div class="text-darkest text-v-sm font-pj py-v-md">
        <span v-if="language=='de'">
          Die Gesamtkapitalrendite (ROIC) misst, wie effizient ein Unternehmen sein Kapital nutzt, um Gewinne zu erzielen, 
          und bietet Einblicke in seine operative Leistung und Investitionseffektivität.
        </span>
        <span v-else>
          The return on invested capital (ROIC) measures how efficiently a company uses its capital to generate profits, 
          providing insight into its operational performance and investment effectiveness.
        </span>
      </div>
      <div class="text-darkest text-v-sm font-pj py-v-md">
        <span v-if="language=='de'">
          <div class="text-darkest text-v-sm font-pj pb-v-md">
              <strong>Formel:</strong><br>
              ROIC = NOPAT / Investiertes Kapital
          </div>
          <div class="text-darkest text-v-sm font-pj pb-v-md"><strong>Erklärung:</strong></div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm"><i>NOPAT (Net Operating Profit After Tax):</i> Betriebsergebnis nach Steuern, aber vor Zinsaufwendungen.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm"><i>Investiertes Kapital:</i> Gesamtkapital, das in das Unternehmen investiert wurde, einschließlich Eigen- und Fremdkapital.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">ROIC misst den Ertrag, der aus dem Gesamtkapital (Eigen- und Fremdkapital) generiert wird, und gibt Aufschluss darüber, wie gut das Unternehmen sein Kapital nutzt, um Gewinne zu erzielen.</div>
        </span>
        <span v-else>
          <div class="text-darkest text-v-sm font-pj pb-v-md">
              <strong>Formula:</strong><br>
              ROIC = NOPAT / Invested Capital
          </div>
          <div class="text-darkest text-v-sm font-pj pb-v-md"><strong>Explanation:</strong></div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm"><i>NOPAT (Net Operating Profit After Tax):</i> Operating income after taxes but before interest expenses.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm"><i>Invested Capital:</i> Total capital invested in the company, which includes both equity and debt.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">ROIC measures the return generated from the total capital (both equity and debt), providing insight into how well the company is using its capital to generate profits.</div>
        </span>        
      </div>

      <div class="text-darkest font-pj text-v-base font-bold pt-v-md">
        <span v-if="language=='de'">
          Rendite des inkrementell investierten Kapitals (ROIIC) 
        </span>
        <span v-else>
          Return on Incremental Invested Capital (ROIIC)
        </span>
      </div>  
      <div class="text-darkest text-v-sm font-pj py-v-md">
        <span v-if="language=='de'">
          „Wenn man die Frage des Preises beiseite lässt, ist das beste Unternehmen, das man besitzt, eines, das über einen längeren Zeitraum große Mengen an zusätzlichem Kapital zu sehr hohen Renditen einsetzen kann.“ - Warren Buffett, Aktionärsbrief 1992
          <br><br>
          Rentabilität des inkrementell investierten Kapitals (ROIIC) ist eine wichtige Kennzahl zur Analyse und Suche nach Compoundern, 
          da sie die Fähigkeit eines Unternehmens misst, 
          hohe Renditen auf zusätzliche Kapitalinvestitionen zu erzielen und damit das Potenzial für nachhaltiges Wachstum und Wertschöpfung 
          im Laufe der Zeit anzeigt.
        </span>
        <span v-else>
          “Leaving the question of price aside, the best business to own is one that over an extended period can employ large amounts of incremental capital at very high rates of return.” – Warren Buffett, 1992 Shareholder Letter
          <br><br>
          Return on incremental invested capital (ROIIC) is an important metric for analyzing and searching for compounders because it measures a company's ability to generate high 
          returns on additional capital investments, indicating the potential for sustainable growth and value creation over time.
        </span>
      </div>

      <div class="text-darkest text-v-sm font-pj py-v-md">
        <span v-if="language=='de'">
          <div class="text-darkest text-v-sm font-pj pb-v-md">
              <strong>Formel:</strong><br>
              ROIC = Δ NOPAT / Δ Investiertes Kapital
          </div>
          <div class="text-darkest text-v-sm font-pj pb-v-md"><strong>Erklärung:</strong></div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm"><i>Δ NOPAT (Net Operating Profit After Tax):</i> Veränderung des NOPAT zwischen zwei Perioden.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm"><i>Δ Investiertes Kapital:</i> Veränderung des investierten Kapitals (Eigen- und Fremdkapital) zwischen zwei Perioden.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">ROIIC misst den Ertrag, der auf das zusätzlich investierte Kapital über einen Zeitraum generiert wird, und zeigt, wie effektiv das Unternehmen neue Kapitalinvestitionen nutzt.</div>
        </span>
        <span v-else>
          <div class="text-darkest text-v-sm font-pj pb-v-md">
              <strong>Formula:</strong><br>
              ROIIC = Δ NOPAT / Δ Invested Capital
          </div>
          <div class="text-darkest text-v-sm font-pj pb-v-md"><strong>Explanation:</strong></div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm"><i>Δ NOPAT (Net Operating Profit After Tax):</i> Change in NOPAT between two periods.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm"><i>Δ Invested Capital:</i> Change in invested capital (equity + debt) between two periods.</div>
          <div class="text-darkest text-v-sm font-pj pb-v-sm">ROIIC measures the return generated on the additional capital invested over a period, indicating how effectively the company is utilizing new capital investments.</div>
        </span>        
      </div>

      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['debt'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['debt_2'] }}</div>

      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['payback'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['payback_2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['payback_3'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['payback_4'] }}</div>
    </template>
  </PopupInfo>
</template>

/* eslint-enable */ 
  