/* eslint-disable */
<script>

import { createBalanceStatementGraph } from '../balance-graph';
import SVGFullsize from '@/components/svg-fullsize.vue';
import { Colors } from '../colors';
import { sessionManager } from '@/main';
import { updateCompanyFinancialsGraph } from './company-financials-helper';

  export default {
    name: 'CompanyFinancialsBalanceGraphTweet',
    props: {
      balance: {
        type: Object,
        required: true,
        default: () => null,
      },
      unitOfAccount: {
        type: String,
        required: true,
        default: () => 'USD',
      },
      companyName: {
        type: String,
        required: true,
        default: () => '',
      },
      exchangeShortName: {
        type: String,
        required: true,
        default: () => '',
      },
      symbol: {
        type: String,
        required: true,
        default: () => '',
      },
      logo: {
        type: Boolean,
        default: () => false
      },
      logoText: {
        type: String,
        default: () => ''
      },
    },
    components: {
      SVGFullsize,
    },
    data() {
      return {
        languageStrings: {
          en: {
            balance: 'Balance Statement',
            ttm: 'TTM',   
          },
          de: {
            balance: 'Bilanz',
            ttm: 'letzte 12 Monate',
          }
        },
        date: null,
        warningColor: Colors.RedOrange,
        language: '',
        tabData: null,
        tabs: null,
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    methods: {
      isFH() {
        return this.balance.type == "FHNormal" || this.balance.type == "FHInsurance" || this.balance.type == "FHBank";
      },
      updateGraph() {
        this.tabData = updateCompanyFinancialsGraph({
          dataQuarter: this.balance.balanceQuarter,
          dataTTM: null,
          dataAnnual: this.balance.balanceAnnual,
          graphName: 'Balance',
          graphType: this.balance.type,
          hasTTM: false,
          createGraphFunction: createBalanceStatementGraph,
          fontSizeBase: 0.85,
          isFH: this.isFH(),
          strings: this.strings,
          unitOfAccount: this.unitOfAccount,
          language: this.language,
        });  
      },
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    },
    beforeMount() {
      this.updateGraph();
    }
  }
</script>

<style scoped>
.v-tab {
    text-transform: none !important;
    font-size: 15px;
    font-family: Plus Jakarta Sans;
    color: #3f4d29;
    letter-spacing: 0em;
  }
</style>

<template>
  <div v-if="balance && tabData" id="CompanyFinancialsBalanceGraph" class="px-v-md">
    <div class="align-center justify-center">
      <h3 v-if="logoText!=''" class="darkest font-pj text-v-xl text-bold py-v-md text-left justify-end">
          <span v-if="logoText!=''">{{ logoText }} </span>{{strings['balance']}} ({{ tabData[0].title }})
      </h3>
      <div>
        <div class="pt-v-md">
            <SVGFullsize :svg="tabData[0].content_Big" :svgId="tabData[0].key"/>
        </div>
      </div>
    </div>
    <div v-if="logo">
      <div class="flex justify-end">
        <div class="flex-none text-v-base font-pj py-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none text-v-base font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
      </div>
    </div>
  </div>
</template>

/* eslint-enable */ 