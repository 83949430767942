
<script>
import { sessionManager } from '@/main';

export default {
  name: 'UserCheckYourMail',
  props: {
    email: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Activate Account',
          headline: 'Check your inbox!',
          hi: 'Hi ' + this.name + '!',
          text_mail: 'We\'ve just sent a confirmation email to ' + this.email + '.',
          text: 'Please check your emails (and maybe your spam folder) and click on the link to confirm your account.',
          text_patience: 'It may take a few minutes for the email to arrive – please be patient.',
          text_waitlist: 'Once your email address is verified, your account will be activated.',
          text_thank_you: 'We are looking forward to welcoming you at valuetion.com!'
        },
        de: {
          title: 'Konto aktivieren',
          headline: 'Prüfe Dein Postfach!',
          hi: 'Hallo ' + this.name + '!',
          text_mail: 'Wir haben gerade eine Bestätigungs E-Mail an ' + this.email + ' gesendet.',
          text: 'Bitte überprüfe Deine E-Mails (oder auch den Spam) und klicke auf den Link, um Dein Konto zu bestätigen.',
          text_patience: 'Es kann einige Minuten dauern, bis die E-Mail eintrifft. Wir bitten um Geduld.',
          text_waitlist: 'Sobald Deine E-Mail-Adresse verifiziert ist, ist Dein Konto aktiviert.',
          text_thank_you: 'Wir freuen uns, Dich bei valuetion.com begrüssen zu dürfen!',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
  }, 
}
</script>

<style>
  .font_white_big {
    color: white;
    font-size: 2.5rem;
  }
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
          <p class="darkest text-v-sm font-pj">{{ strings['hi'] }}</p>
          <p class="darkest text-v-sm font-pj">{{ strings['text_mail'] }}</p>
          <p class="darkest text-v-sm font-pj">{{ strings['text'] }}</p>
          <p class="darkest text-v-sm font-pj">{{ strings['text_patience'] }}</p>
          <p class="darkest text-v-sm font-pj">{{ strings['text_waitlist'] }}</p>
      </div>      
    </div>
  </div>
</template>


