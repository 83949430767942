
<script>
import { sessionManager } from '@/main';

import {
  getColorValuationRatio
} from '../helper';

export default {
  name: 'TweetGenerator',
  props: {
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Tweet generator',
        },
        de: {
          title: 'Tweet generator',
        },
      },
      headers: [
        {
          text: 'Symbol',
          value: 'symbol',
          width: '20px',
          sortable: true,
        },
        
        {
          text: 'Company Name',
          value: 'companyName',
          width: '180px',
          sortable: true,
        },
        {
          text: 'Valuation',
          value: 'valuationRatio',
          width: '40px',
          sortable: true,
        },
        {
          text: 'Last Update',
          value: 'lastFinancialReportDate',
          width: '100px',
          sortable: true,
        },
        {
          text: 'Days ago',
          value: 'daysAgo',
          width: '80px',
          sortable: true,
        }
        
      ],
      items: null,
      itemsSP500: null,
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  beforeMount() {
    this.fetchCompaniesSP500();
    this.fetchCompanies();
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    getColorValuationRatio(value) {
      return getColorValuationRatio(value)
    }, 
    getDaysAgo(inputDate) {
      
         // Parse the input date
        const date = new Date(inputDate);
        
        // Get the current date
        const now = new Date();
        
        // Calculate the difference in milliseconds
        const diffInMs = now - date;
        
        // Convert milliseconds to days and round down to the nearest whole number
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        
        return diffInDays
    },
    async fetchCompaniesSP500()
    {
      const url = '/api/HttpTriggerTweetGenerator?';
      const response = await fetch(url +
        new URLSearchParams({
          sp500: true,
        }), { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });

      // check if response is ok
      if(response.ok) {
        const data = await response.json();
        let companies = data;
        this.itemsSP500 = null;
        let arr = [];
        const maxRatio = 999;

        let daysAgo = this.getDaysAgo;

        companies.forEach(function(c){
          arr.push({
            symbol: c.symbol,
            companyName: c.companyName,
            lastFinancialReportDate: c.lastFinancialReportDate,
            valuationRatio: (typeof c.valuationRatio == "undefined" || c.valuationRatio > maxRatio || c.valuationRatio <= 0.0)? '-' : c.valuationRatio.toFixed(1),
            daysAgo: daysAgo(c.lastFinancialReportDate),
          })     
        });

        this.itemsSP500 = arr;
      }
      else {
        // check if response is unauthorized
        if(response.status === 401) {
          // redirect to login page
          this.$router.push('/login');
        }
      }    
    },
    async fetchCompanies()
    {
      const url = '/api/HttpTriggerTweetGenerator?';
      const response = await fetch(url +
        new URLSearchParams({
          sp500: false,
        }), { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });

      // check if response is ok
      if(response.ok) {
        const data = await response.json();
        let companies = data;
        this.items = null;
        let arr = [];
        const maxRatio = 999;

        let daysAgo = this.getDaysAgo;

        companies.forEach(function(c){
          arr.push({
            symbol: c.symbol,
            companyName: c.companyName,
            lastFinancialReportDate: c.lastFinancialReportDate,
            valuationRatio: (typeof c.valuationRatio == "undefined" || c.valuationRatio > maxRatio || c.valuationRatio <= 0.0)? '-' : c.valuationRatio.toFixed(1),
            daysAgo: daysAgo(c.lastFinancialReportDate),
          })     
        });

        this.items = arr;
      }
      else {
        // check if response is unauthorized
        if(response.status === 401) {
          // redirect to login page
          this.$router.push('/login');
        }
      }    
    },
    replaceDotWithUnderscore(str) {
      return str.replace(/\./g, "_");
    },
  }, 
}
</script>

<style>
  .font_white_big {
    color: white;
    font-size: 2.5rem;
  }
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">S&P 500</h3>
            <v-data-table v-if="itemsSP500"
              dense
              :headers="headers"
              :items="itemsSP500"
              :items-per-page="600"
              mobile-breakpoint="0"
            >
              <template #item.symbol="{value}">
                  <router-link :to="{ path: 'tweet/' + replaceDotWithUnderscore(value) + ''}" target="_blank">
                  {{ value }}
                </router-link>
              </template>
              <template #item.valuationRatio="{value, index}">
                <div :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
              </template>
            </v-data-table>          
      </div>      
      <div class="max-w-4xl pt-v-xl">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">All companies</h3>
            <v-data-table v-if="items"
              dense
              :headers="headers"
              :items="items"
              :items-per-page="600"
              mobile-breakpoint="0"
            >
              <template #item.symbol="{value}">
                  <router-link :to="{ path: 'tweet/' + replaceDotWithUnderscore(value) + ''}" target="_blank">
                  {{ value }}
                </router-link>
              </template>
              <template #item.valuationRatio="{value, index}">
                <div :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
              </template>
            </v-data-table>          
      </div>   
    </div>
  </div>
</template>


