/* eslint-disable */
<script>
 
  export default {
    name: 'CompanyImage',
    props: {
      company: {
        type: Object,
        required: true,
        default: () => null,
      },
    },
  }
</script>
  
  
<template>
  <v-container v-if="company">
    <v-img :src="company.image" max-width="100" max-height="100" contain />
  </v-container>
</template>
/* eslint-enable */ 
  