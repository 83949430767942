export class SessionManager {
  constructor() {
    this.sessionCookieName = 'valuetion.com';
    this.session = this.getSessionFromCookie();

    // check if the cookiesAllowed flag is set
    if(this.session['cookiesAllowed'] === undefined || this.session['cookiesAllowed'] === false) {
      // if the flag is not set we set it to false
      this.allowedCookies = false;
    }
    else {
      // cookies are allowed
      this.allowedCookies = true;
    }
  }

  getUniqueID() {
      const dateString = Date.now().toString(36);
      const randomness = Math.random().toString(36).substr(2);
      return dateString + randomness;
  }

  // get if the disclaimer was accepted
  getDisclaimerAccepted()
  {
    return this.read('disclaimerAccepted');
  }

  // set the disclaimer accepted flag
  setDisclaimerAccepted(value)
  {
    this.write('disclaimerAccepted', value);
  }

  // get the allowed cookies flag
  getCookiesAllowed() {
    return this.allowedCookies;
  }

  // set the allowed cookies flag
  setCookiesAllowed(value) {
    this.allowedCookies = value;
    this.write('cookiesAllowed', value);
  }

  isLoggedin() {
    // write the session to the console
    return this.read('token') !== null;
  }

  isAdmin() {
    return this.read('token') !== null && this.read('role') === 'admin';
  }

  isContentCreator() {
    return this.read('token') !== null && (this.read('role') === 'contentCreator' || this.read('role') === 'admin');
  }

  logOut() {
    this.write('token', null);
  }

  // read the session from the cookie
  getSessionFromCookie() 
  {
    let cookie = document.cookie;
    let sessionCookie = cookie.split(';').find(c => c.trim().startsWith(this.sessionCookieName))
    if (!sessionCookie) {
      return {}
    }
    let session = JSON.parse(sessionCookie.split('=')[1])
    return session;
  }

  // write the session cookie to the browser
  setSessionCookie(session) {
    // we only write the session if cookies are allowed
    if(this.allowedCookies) {
      let sessionString = JSON.stringify(session);
      let date = new Date();
      date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // Set the cookie to expire in 7 days
      let expires = "; expires=" + date.toUTCString();
      document.cookie = `${this.sessionCookieName}=${sessionString}${expires}; path=/`;
    }
  }
  

  // read a value from the session
  read(key) 
  {
    // we only read the session from the cookie if cookies are allowed
    if(this.allowedCookies) {
      this.session = this.getSessionFromCookie()
    }

    // if the key is not in the session we return the default value
    if(this.session[key] === undefined)
    {
      this.session[key] = this.getDefault(key);
      this.setSessionCookie(this.session);
    }

    return this.session[key];
  }

  // write a value to the session
  write(key, value) 
  {
    if(this.allowedCookies) 
    {
      this.session = this.getSessionFromCookie();
    }
    else if(this.session === undefined) 
    {
      // if cookies are not allowed we create a new session
      this.session = {};
    }
    this.session[key] = value;
    this.setSessionCookie(this.session);
  }

  getDefault(key) {
    switch (key) {
      case 'language':
        return this.getLanguagefromNavigator();
      case 'unitOfAccount':
        return 'USD';
      case 'id':
        return this.getUniqueID();
      case 'email':
        return '';
      case 'sendWatchlistUpdates':
        return true;
      case 'onboardingStep':
        return 'done';
      case 'onboardingDone':
        return true;
      case 'onboardingSymbol':
        return '';
      case 'showSurvey':
        return true;
      case 'useFinnhub':
        return false;
      case 'userCreated':
        {
          let today = new Date();
          let year = today.getFullYear();
          let month = String(today.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based.
          let day = String(today.getDate()).padStart(2, '0');
          let formattedDate = `${year}-${month}-${day}`;
          return formattedDate;
        }
      default:
        return null;
    }
  }

  getLanguagefromNavigator() {
    let language = navigator.language || navigator.userLanguage;
    if (language.startsWith('de')) {
      return 'de';
    }
    return 'en';
  }

  isOnboardingDone() {
    return this.read('onboardingDone') || this.read('onboardingStep') === 'done' || this.read('onboardingStep') === 'dashboard';
  }

  clear() {
    if(this.allowedCookies) {
      document.cookie = `${this.sessionCookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
    }
  }
}
