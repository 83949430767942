<script>
/* eslint-disable */

import CompanySearch from '@/components/company-search.vue';
import CompanyScreener from '@/components/company-screener.vue';
import Popup from '@/components/popup.vue';
import { eventBus } from '@/main'
import { sessionManager } from '@/main';
import { hasWaitlist } from './settings';

import {
  logUserEvent,
} from './helper';

export default {
  name: 'valuetion.com',
  components: {
    CompanySearch,
    CompanyScreener,
    Popup,
  },
  // set the meta information for the page (title, description, etc.)
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "valuetion.com - The Essential Toolkit for Value Investors",
    meta: [
        { name: 'author', content: 'valuetion.com' },
        { name: 'description', content:  'valuetion.com is the essential toolkit for value investors that are searching a low timepreference stock screener, conceise company overview pages and company valuations and easy to understand graphical financial statements.' },
        { property: 'og:site_name', content: 'valuetion.com'},
        { property: 'og:type', content: 'website'},    
        { name: 'robots', content: 'index,follow'}, 
        { rel: 'icon', type: 'image/png', href: 'icon32.png' }
    ]
  },
  data() {
    return {
      cookieDecided: false,
      landingPage: true,
      drawer: false,
      overlay: false,
      absolute: true,
      collapse: true,
      languageStrings: {
        en: {
          menu: 'Menu',
          home: 'Home',
          search: 'Screener',
          knowledge: 'Knowledge',
          dashboard: 'Dashboard',
          unitOfAccount: 'Unit of Account',
          register: 'Register',
          login: 'Login',
          logout: 'Logout',
          cookie: 'We use functional cookies for central functions on this website. You can find more detailed information in our ',
          cookie_link: 'privacy policy.',
          accept: 'Accept',
          reject: 'Reject',
          imprint: 'Imprint',
          waitlist: 'Join the waitlist',
          register: 'Join the beta',
          contact: 'Contact',
          legal: 'Legal',
          privacy: 'Privacy policy',
          imprint: 'Imprint',
          terms: 'Terms and Conditions',
          allrights: 'all rights reserved',
          faq: 'FAQ',
          tour: 'Introduction',
          survey: 'We are currently conducting a survey to better understand your needs. Please take a few minutes to fill out the survey. Thank you!',
          to_survey: 'Go to survey',
          survey_link: 'https://docs.google.com/forms/d/e/1FAIpQLSexYQJkVHn7CzRkaGuW2dHF1VBoSwjrq9WdkV9hDodksYbdvQ/viewform?usp=sf_link',
          hide_survey: 'Hide',
          follow_us: 'Follow us on',
        },
        de: {
          menu: 'Menu',
          home: 'Start',
          search: 'Suche',
          knowledge: 'Wissen',
          dashboard: 'Dashboard',
          unitOfAccount: 'Hauptwährung',
          register: 'Registrieren',
          login: 'Anmelden',
          logout: 'Abmelden',
          cookie: 'Wir verwenden funktionale Cookies für zentrale Funktionen auf dieser Webseite. Genauere Informationen dazu findest Du in unserer ',
          cookie_link: 'Datenschutzbestimmung.',
          accept: 'Akzeptieren',
          reject: 'Ablehnen',
          imprint: 'Impressum',
          waitlist: 'Warteliste beitreten',
          register: 'Konto erstellen',
          contact: 'Kontakt',
          legal: 'Rechtliches',
          privacy: 'Datenschutzbestimmung',
          imprint: 'Impressum',
          terms: 'Allgemeine Geschäftsbedingungen',
          allrights: 'alle Rechte vorbehalten',
          faq: 'FAQ',
          tour: 'Einführung',
          survey: 'Wir führen derzeit eine Umfrage durch, um Deine Bedürfnisse besser zu verstehen. Bitte nimm Dir einige Minuten Zeit, um die Umfrage auszufüllen. Vielen Dank!',
          to_survey: 'Zur Umfrage',
          survey_link: 'https://docs.google.com/forms/d/e/1FAIpQLSe7Wa_F8A4vpzvLCr_dZTMatws9esFw_PnSQ4NO7i7kcItT5Q/viewform?usp=sf_link',
          hide_survey: 'Ausblenden',
          follow_us: 'Folge uns auf',
        },
      },
      menuItems: [
        { title: 'home', icon: 'mdi-home', route: '/*' },
        { title: 'search', icon: 'mdi-feature-search-outline', route: '/search' },
      ],
      items: [
        {
          icon: 'mdi-feature-search-outline',
          title: 'Search',
          url: '/',
        },
        {
          icon: 'mdi-currency-usd',
          title: 'Valuation',
          url: '/profile/INTC',
        },      
      ],
      languages: [
        {
          icon: '<svg width="20" height="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m0 0h32v32h-32z" fill="#f00"/><path d="m13 6h6v7h7v6h-7v7h-6v-7h-7v-6h7z" fill="#fff"/>',
          name: 'Deutsch',
          value: 'de',
        },
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="40" height="20"><clipPath id="s"><path d="M0,0 v30 h60 v-30 z"/></clipPath><clipPath id="t"><path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"/></clipPath><g clip-path="url(#s)"><path d="M0,0 v30 h60 v-30 z" fill="#012169"/><path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" stroke-width="6"/><path d="M0,0 L60,30 M60,0 L0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/><path d="M30,0 v30 M0,15 h60" stroke="#fff" stroke-width="10"/><path d="M30,0 v30 M0,15 h60" stroke="#C8102E" stroke-width="6"/></g></svg>',
          name: 'English',
          value: 'en',
        }
      ],
      currencies: [
        {
          icon: 'mdi-currency-usd',
          name: 'USD',
          value: 'USD',
        },
        {
          icon: 'mdi-currency-eur',
          name: 'Euro',
          value: 'EUR',
        },
        {
          icon: 'mdi-bitcoin',
          name: 'Bitcoin',
          value: 'BTC',
        },
        {
          icon: 'mdi-gold',
          name: 'Gold',
          value: 'GC',
        },
        /*
        {
          icon: 'mdi-percent',
          name: 'Percent of M2 USD',
          value: 'PercentOfM2USD',
        },
        */
      ],
      selectedLanguageIndex: 0,
      selectedUnitOfAccountIndex: 0,
      language: '',
      unitOfAccount: '',
      loggedInEventCount: 1,
      waitlist: false,
      showMenu: true,
      showSurveyLocal: true,
    }; 
  },
  
  watch: {
    '$route'(to, from) {
      // Update `showMenu` based on the route.
      // For example, hide the menu on '/special-route'
      this.updateMenuVisibility(to);
    }
  },
  created() 
  {
    // Set initial visibility of the menu based on the initial route
    this.updateMenuVisibility(this.$route);

    this.language = sessionManager.read('language');
    this.unitOfAccount = sessionManager.read('unitOfAccount');
    this.waitlist = hasWaitlist();

    window.addEventListener('resize', this.handleResize);
    // login/logout events
    eventBus.$on('login', () => {
      this.loggedInEventCount = this.loggedInEventCount + 1;
      this.cookieDecided = sessionManager.getCookiesAllowed();
    });
    eventBus.$on('logout', () => {
    });
    eventBus.$on('legalDisclaimerDecided', () => {
      this.cookieDecided = sessionManager.getCookiesAllowed();
    });

    // let us check if the user has already decided about cookies
    this.cookieDecided = sessionManager.getCookiesAllowed();
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
    isLoggedIn() {
      return this.loggedInEventCount > 0 && sessionManager.isLoggedin();
    },
    userName() {
      return sessionManager.read('name');
    },
    showCookieBanner() {
      return !this.cookieDecided;
    },
    showSurvey() {
      // we only show the survey if the user is logged in and if the user has used the site for more than 5 days
      if(!this.isLoggedIn)
      {
        return false;
      }
      if(!this.showSurveyLocal)
      {
        return false;
      }
      if(!sessionManager.read('showSurvey'))
      {
        return false;
      }
      let days = this.daysSince(sessionManager.read('userCreated'), new Date());
      return days > 5;
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    savePush(path)
    {
      if(this.$route.path !== path)
      {
        this.$router.push({ path: path });
      }
    },
    daysSince(dateString) {
      // Convert date strings to Date objects
      const date1 = new Date(dateString);
      const date2 = new Date();

      // Calculate the difference in milliseconds
      const differenceInMilliseconds = Math.abs(date2 - date1);
      const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

      return differenceInDays;
    },
    hideSurvey() {
      sessionManager.write('showSurvey', false);
      this.showSurveyLocal = false;
    },
    gotoSurvey() {
      window.open(this.strings['survey_link'], '_blank');
    },
    updateMenuVisibility(route) {

      // Update `showMenu` based on the route.
      // we don't show the menu on the onboarding page
      this.showMenu = route.path !== '/onboarding';
    },
    acceptCookies() {
      this.cookieDecided = true;
      sessionManager.setCookiesAllowed(true);
    },
    rejectCookies() {
      this.cookieDecided = true;
      sessionManager.setCookiesAllowed(false);
    },
    showMobileMenu() {
      this.overlay = true;
      window.scrollTo(0, 0);
    },
    hideMobileMenu() {
      this.overlay = false;
    },
    handleResize() {
      // we need to close the mobile menu
      this.overlay = false;
    },
    handleMenuClick(event) {
      this.overlay = false;
    },
    logout() {
      sessionManager.logOut();
      this.overlay = false;
      this.isLoggedIn = false;
      location.reload();
    },
    changeLanguage(selectedObject) {
      this.language = selectedObject;

      // compute the index of the selected language
      let index = 0;
      this.languages.forEach(element => {
        if(element.value == selectedObject)
        {
          this.selectedLanguageIndex = index;
        }
        index++;
      });
      sessionManager.write('language', this.language);
      location.reload();
    },
    changeUnitOfAccount(selectedObject) {
      this.selectedUnitOfAccount = selectedObject;

      // compute the index of the selected currency
      let index = 0;
      this.currencies.forEach(element => {
        if(element.value == selectedObject)
        {
          this.selectedUnitOfAccountIndex = index;
        }
        index++;
      });
      sessionManager.write('unitOfAccount', this.selectedUnitOfAccount);
      location.reload();
    },
    forceUserRegistration()
    {
      if(!this.isLoggedIn)
      {
        this.hideMobileMenu();
        this.savePush('/forceRegister');
        return true;
      }
      return false;  
    },
    updateSearchInput(value) {
      if(this.forceUserRegistration())
      {
        logUserEvent('forceUserRegistrationTickerSearch', sessionManager, this.$vuetify.breakpoint.mobile);
        return;
      }
      this.savePush('/profile/' + value);
    },    
    gotoRegistration()
    {
      this.hideMobileMenu();
      this.savePush('/register');
    },
    gotoWaitlist()
    {
      this.hideMobileMenu();
      this.savePush('/waitlist');
    },
    gotoSearch()
    {
      if(this.forceUserRegistration())
      {
        logUserEvent('forceUserRegistrationSearch', sessionManager, this.$vuetify.breakpoint.mobile);
        return;
      }
      this.hideMobileMenu();
      this.savePush('/search');
    },
    gotoDashboard()
    {
      if(this.forceUserRegistration())
      {
        logUserEvent('forceUserRegistrationDashboard', sessionManager, this.$vuetify.breakpoint.mobile);
        return;
      }
      this.hideMobileMenu();
      this.savePush('/dashboard');
    },
    gotoFAQ()
    {
      this.hideMobileMenu();
      this.savePush('/faq');
    },
    gotoTour()
    {
      this.hideMobileMenu();
      this.savePush('/tour');
    },
    gotoLogin()
    {
      this.hideMobileMenu();
      this.savePush('/login');
    },
  },
};
</script>

<style>

</style>

<template>
  <v-app>
      <popup />
      <div v-if="showMenu" class="px-4 inline-flex flexitems-center justify-between justify-center items-center h-16">
        <!-- landing page or dashboard-->
        <div v-if="isLoggedIn" class="flex-none regular font-pj py-v-md cursor-pointer" @click="$router.push({ path: '/'})">
          <svg width="14x" height="14px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div v-else class="flex-none regular font-pj py-v-md cursor-pointer" @click="$router.push({ path: '/'})">
          <svg width="14x" height="14px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div v-if="isLoggedIn" class="flex-none regular font-pj py-v-md pl-v-sm pr-v-md cursor-pointer" @click="$router.push({ path: '/'})"><b>value</b>tion</div>
        <div v-else class="flex-none regular font-pj py-v-md pl-v-sm pr-v-md cursor-pointer" @click="$router.push({ path: '/'})"><b>value</b>tion</div>
        <!-- dashboard -->
        <div v-if="isLoggedIn && !$vuetify.breakpoint.smAndDown" @click="$router.push({ path: '/dashboard'})" class="flex-none regular font-pj hover:underline hover:regular hover:underline-offset-4 p-v-md cursor-pointer">{{ strings['dashboard'] }}</div>
        <!-- screener -->
        <div v-if="!$vuetify.breakpoint.smAndDown" @click="gotoSearch" class="flex-none regular font-pj hover:underline hover:regular hover:underline-offset-4 p-v-md cursor-pointer">{{ strings['search'] }}</div>
        <!-- tour -->
        <div v-if="isLoggedIn && !$vuetify.breakpoint.smAndDown" @click="$router.push({ path: '/tour'})" class="flex-none regular font-pj hover:underline hover:regular hover:underline-offset-4 p-v-md cursor-pointer">{{ strings['tour'] }}</div>
        <!-- faq -->
        <div v-if="!$vuetify.breakpoint.smAndDown" @click="$router.push({ path: '/faq'})" class="flex-none regular font-pj hover:underline hover:regular hover:underline-offset-4 p-v-md cursor-pointer">{{ strings['faq'] }}</div>
        <!-- language -->
        <div v-if="!$vuetify.breakpoint.smAndDown && this.language == 'en'" class="flex-none regular font-pj hover:underline hover:regular hover:underline-offset-4 p-v-md cursor-pointer" @click="changeLanguage(languages[0].value)">Deutsch</div>
        <div v-if="!$vuetify.breakpoint.smAndDown && this.language == 'de'" class="flex-none regular font-pj hover:underline hover:regular hover:underline-offset-4 p-v-md cursor-pointer" @click="changeLanguage(languages[1].value)">English</div>
        <div v-if="!isLoggedIn && landingPage" class="flex-grow">
        </div>
        <!-- ticker search -->
        <div class="flex-grow regular font-pj p-v-md justify-between justify-center items-center">
          <CompanySearch v-on:input="updateSearchInput" />
        </div>
        <div v-if="!landingPage && !isLoggedIn && !$vuetify.breakpoint.smAndDown" class="p-v-md">
          <button type="button" class="flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="$router.push({ path: '/registration'})">{{ strings['register'] }}</button>
        </div>
        <div v-if="!isLoggedIn && !$vuetify.breakpoint.smAndDown" class="p-v-md">
          <button type="button" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="$router.push({ path: '/login'})">{{ strings['login'] }}</button>
        </div>
        <div v-if="waitlist && landingPage && !isLoggedIn && !$vuetify.breakpoint.smAndDown" class="p-v-md">
          <button type="button" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="$router.push({ path: '/waitlist'})">{{ strings['waitlist'] }}</button>
        </div>
        <div v-if="!waitlist && landingPage && !isLoggedIn && !$vuetify.breakpoint.smAndDown" class="p-v-md">
          <button type="button" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="$router.push({ path: '/register'})">{{ strings['register'] }}</button>
        </div>
        <div v-if="isLoggedIn && !$vuetify.breakpoint.smAndDown" class="p-v-md">
          <button type="button" class="flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="logout">{{ strings['logout'] }}</button>
        </div>
        <div class="font-pj py-v-md pl-v-md" v-if="$vuetify.breakpoint.smAndDown">
          <v-icon v-if="!overlay" class="menuicon" @click.stop="showMobileMenu">mdi-menu</v-icon>    
          <v-icon v-if="overlay" class="menuicon" @click.stop="hideMobileMenu">mdi-close</v-icon>
        </div>
      </div>
      <div v-else class="px-4 inline-flex flexitems-center justify-between justify-center items-center h-16">
        <!-- landing page or dashboard-->
        <div v-if="isLoggedIn" class="flex-none regular font-pj py-v-md cursor-pointer" @click="$router.push({ path: '/'})">
          <svg width="14x" height="14px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div v-else class="flex-none regular font-pj py-v-md cursor-pointer" @click="$router.push({ path: '/'})">
          <svg width="14x" height="14px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none regular font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
        <div class="flex-none regular font-pj py-v-md pl-v-sm pr-v-md">Onboarding Tour</div>
      </div>

    <v-main>
      <v-container v-if="showCookieBanner">
        <v-row class="pa-0 ma-0">
          <v-col :class="$vuetify.breakpoint.xsOnly ? 'pt-0 pb-1 pl-1 pr-1' : 'pa-1'"  cols="12" sm="6" md="6" lg="6" xl="6">
            <p class="fineprint">{{ strings['cookie'] }}<router-link :to="{ path: 'privacy' }">{{ strings['cookie_link'] }}</router-link></p>
          </v-col>
          <v-col :class="$vuetify.breakpoint.xsOnly ? 'pt-0 pb-1 pl-1 pr-1' : 'pa-1'" cols="12" sm="6" md="6" lg="6" xl="6">
            <div align="right">
              <v-btn dense dark style="margin-right: 10px" color="#aaaaaa" outlined @click="rejectCookies">{{strings['reject']}}</v-btn>
              <v-btn dense dark color="primary"  @click="acceptCookies">{{strings['accept']}}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="showSurvey">
        <v-row class="pa-0 ma-0">
          <v-col  :class="$vuetify.breakpoint.xsOnly ? 'pt-0 pb-1 pl-1 pr-1' : 'pa-1'"  cols="12" sm="6" md="6" lg="6" xl="6">
            {{ strings['survey'] }}
          </v-col>
          <v-col :class="$vuetify.breakpoint.xsOnly ? 'pt-0 pb-1 pl-1 pr-1' : 'pa-1'" cols="12" sm="6" md="6" lg="6" xl="6"> 
            <div align="right">
              <v-btn dense dark style="margin-right: 10px" color="#aaaaaa" outlined @click="hideSurvey">{{strings['hide_survey']}}</v-btn>
              <v-btn dense dark color="primary"  @click="gotoSurvey">{{strings['to_survey']}}</v-btn>
            </div>
          </v-col>
        </v-row>  
      </v-container>
      <!-- mobile navigation bar -->
      <section v-if="overlay">
        <!-- dashboard -->
        <div v-if="isLoggedIn" class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="gotoDashboard">{{ strings['dashboard'] }}</div>
        <!-- screener -->
        <div class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="gotoSearch">{{ strings['search'] }}</div>
        <!-- tour -->
        <div v-if="isLoggedIn" class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="gotoTour">{{ strings['tour'] }}</div>
        <!-- faq -->
        <div class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="gotoFAQ">{{ strings['faq'] }}</div>
        <!-- language -->
        <div v-if="this.language == 'en'" class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="changeLanguage(languages[0].value)">Deutsch</div>
        <div v-if="this.language == 'de'" class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="changeLanguage(languages[1].value)">English</div>
        <!-- login -->
        <div v-if="!isLoggedIn" class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="gotoLogin">{{ strings['login'] }}</div>
        <div v-if="waitlist && landingPage && !isLoggedIn" class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="gotoWaitlist">{{ strings['waitlist'] }}</div>
        <div v-if="!waitlist && landingPage && !isLoggedIn" class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="gotoRegistration">{{ strings['register'] }}</div>
        <div v-if="isLoggedIn" class="text-v-xl font-bold font-pj hover:underline hover:regular hover:underline-offset-4 p-v-sm px-v-xl" @click="logout">{{ strings['logout'] }}</div>
      </section>
      <div v-if="!overlay" class="">
        <router-view :unitOfAccount="unitOfAccount"></router-view>       
      </div>
    </v-main>

    <section class="py-v-xl sm:pt-v-md lg:pt-v-xl">



    </section>  

    <div class="bg-lightest inline-flex flexitems-center justify-between justify-center items-center h-16 pt-v-xl">
      <div class="flex-none text-v-base font-pj py-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
      </div>
      <div class="flex-none text-v-base font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
    </div>
    <section class="bg-lightest">
      <div>
          <div class="grid grid-cols-1 sm:grid-cols-2">
              <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl py-v-sm' : 'px-v-4xl py-v-sm'">
                <div class="sm:flex sm:justify-center">
                  <div>
                    <div class="text-darkest text-v-base font-pj font-bold py-v-md">{{strings['contact']}}</div>
                    <div class="text-darkest text-v-sm font-pj">EMail: <a href="mailto:info@valuetion.com">info@valuetion.com</a></div>
                    <div class="flex">
                      <div class="text-darkest text-v-sm font-pj pr-v-sm">{{strings['follow_us']}}</div>
                      <div>
                        <a href="https://twitter.com/ValuetionCH"><img src="./assets/x-logo-black.png" alt="image" style="height: 15px; width: 15px;"/></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl py-v-sm' : 'px-v-4xl py-v-sm'">
                <div class="sm:flex sm:justify-center">
                  <div>
                    <div class="text-darkest text-v-base font-pj font-bold py-v-md">{{ strings['legal']}}</div>
                      <div class="text-darkest text-v-sm font-pj"><router-link :to="{ name: 'terms' }">{{strings['terms']}}</router-link></div>
                      <div class="text-darkest text-v-sm font-pj"><router-link :to="{ name: 'privacy' }">{{strings['privacy']}}</router-link></div>
                      <div class="text-darkest text-v-sm font-pj"><router-link :to="{ name: 'imprint' }">{{strings['imprint']}}</router-link></div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </section>

    <div class="bg-lightest inline-flex flexitems-center justify-between justify-center items-center h-16">
      <div class="flex-none text-v-tiny darkest font-pj pr-v-md pt-v-xl pb-v-md">
        © Copyright {{ new Date().getFullYear() }} Valuetion, {{strings['allrights']}}
      </div>
    </div>
</section>


  </v-app>

</template>


