<script>
import { sessionManager } from '@/main';
import { logUserEvent } from '../helper';

export default {
  name: 'CompanyNews',
  props: {
    symbol: {
      type: String,
      required: true,
      default: () => null,
    }
  },
  data() {
    return {
      activeTab: 0,
      pressReleases: [],
      news: [],
      analystArticles: [],
      maxMobileItemsNews: 5,
      maxMobileItemsPress: 5,
      maxMobileItemsArticles: 5,
      maxMobileItemsAll: 5,
      maxTextLength: 150,
      maxItemsNews: 10,
      maxItemsPress: 10,
      maxItemsArticles: 10,
      maxItemsAll: 10,
      languageStrings: {
        en: {
          pressReleases: 'Press Releases',
          news: 'News',
          analystArticles: 'Analysis',
          all: 'All',
          more: 'show more',  
          loadMore: 'Load more',  
          addArticleButton: 'Post Analysis Article',     
          editArticle: 'Edit Article',
          addArticle: 'Add Company/Stock Analysis Article',
          deleteArticle: 'Delete Article',
          cancel: 'Cancel',
          save: 'Save',
          delete: 'Delete',
          sureToDelete: 'Are you sure you want to delete this article?',
          title: 'Title',
          url: 'URL',
          source: 'Source',
          summary: 'Summary (optional)',
        },
        de: {
          pressReleases: 'Pressemeldungen',
          news: 'News',
          analystArticles: 'Analysen',
          all: 'Alle',
          more: 'mehr anzeigen',
          loadMore: 'Mehr laden',
          addArticleButton: 'Analyse Artikel veröffentlichen',
          editArticle: 'Artikel bearbeiten',
          addArticle: 'Unternehmens-/Aktienanalyse hinzufügen',
          deleteArticle: 'Artikel löschen',
          article: 'Artikel',
          cancel: 'Abbrechen',
          save: 'Speichern',
          delete: 'Löschen',
          sureToDelete: 'Möchtest Du diesen Artikel wirklich löschen?',
          title: 'Überschrift',	
          url: 'URL',
          source: 'Quelle',
          summary: 'Zusammenfassung (optional)',
        }
      },
      dialog: false,
      editingArticle: null,
      articleForm: {
        title: '',
        url: '',
        source: '',
        text: '',
        createdAt: null,
        updatedAt: null,
      },
      deleteDialog: false,
      articleToDelete: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    filteredPressReleases() {
      const slicedReleases = this.pressReleases.slice(0, this.maxItemsPress);
      return this.isMobile 
        ? slicedReleases.slice(0, this.maxMobileItemsPress)
        : slicedReleases
    },
    filteredNews() {
      const slicedNews = this.news.slice(0, this.maxItemsNews);
      return this.isMobile
        ? slicedNews.slice(0, this.maxMobileItemsNews)
        : slicedNews
    },
    filteredAnalystArticles() {
      const slicedArticles = this.analystArticles.slice(0, this.maxItemsArticles);
      return this.isMobile
        ? slicedArticles.slice(0, this.maxMobileItemsArticles)
        : slicedArticles
    },
    allItems() {
      const combined = [
        ...this.analystArticles.map(item => ({...item, type: 'analyst'})),
        ...this.news.map(item => ({...item, type: 'news', createdAt: item.publishedDate})),
        ...this.pressReleases.map(item => ({...item, type: 'press', createdAt: item.date}))
      ];
      return combined.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    filteredAllItems() {
      const slicedAll = this.allItems.slice(0, this.maxItemsAll);
      return this.isMobile
        ? slicedAll.slice(0, this.maxMobileItemsAll)
        : slicedAll
    },
    strings() {
        return this.languageStrings[this.language];
    },
    hasMoreNews() {
      return this.news.length > this.maxItemsNews;
    },
    hasMorePressReleases() {
      return this.pressReleases.length > this.maxItemsPress;
    },
    hasMoreAnalystArticles() {
      return this.analystArticles.length > this.maxItemsArticles;
    },
    hasMoreAll() {
      return this.allItems.length > this.maxItemsAll;
    },
    isContentCreator() {
      return sessionManager.isContentCreator();
    },
    computedSource() {
      if(this.articleForm.url != '') {
        return this.getHostname(this.articleForm.url);
      }
      sessionManager.read('userName');
    },
  },
  mounted() {
    this.fetchCompanyNews();
    logUserEvent('newsPage', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
  },
  created() {
    this.language = sessionManager.read('language');
    this.unitOfAccount = sessionManager.read('unitOfAccount');
  },
  methods: {
    getHostname(url) {
      try {
        const parsedUrl = new URL(url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`);
        return `${parsedUrl.hostname}`;
      } catch (error) {
        console.error("Invalid URL:", error);
        return null;
      }
    },
    isCreatedByMe(item) {
      return item.createdBy == sessionManager.read('id');
    },
    truncateText(text) {
      if (text.length <= this.maxTextLength) return text;
      return text.substring(0, this.maxTextLength) + '...';
    },
    getDaysAgo(dateString) {
      const date = new Date(dateString);
      const today = new Date();
      const diffTime = Math.abs(today - date);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays === 1 ? '1 day ago' : `${diffDays} days ago`;
    },
    async fetchCompanyNews() {
      const url = '/api/HttpTriggerGetCompanyNews?';
      const response = await fetch(url +
        new URLSearchParams({
          symbol: this.symbol,
          unitOfAccount: this.unitOfAccount, 
          language: this.language,
        }), { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });
      if(response.ok) {
        const data = await response.json();
        this.pressReleases = data.pressReleases;
        this.news = data.news;

        // add a bool value to the entries to track if they are expanded
        this.pressReleases.forEach((item) => {
          item.expanded = item.text.length <= this.maxTextLength;
          item.fullText = item.text;
          item.truncateText = this.truncateText(item.text);
          this.updateText(item);
        });
        this.news.forEach((item) => {
          item.expanded = item.text.length <= this.maxTextLength;
          item.fullText = item.text;
          item.truncateText = this.truncateText(item.text);
          this.updateText(item);
        });
        this.articles = data.articles;
        this.analystArticles = data.analystArticles.map((item) => {
          if(item.text == null)
          {
            item.text = '';
          }
          item.expanded = item.text.length <= this.maxTextLength;
          item.fullText = item.text;
          item.truncateText = this.truncateText(item.text);
          this.updateText(item);
          return item;
        });
      }
    },
    updateText(item)
    {
      if(!item.expanded)
      {
        item.text = item.truncateText + this.strings['more'];
      }
      else
      {
        item.text = item.fullText;
      }
    },
    onClick(item) {
      item.expanded = !item.expanded;
      this.updateText(item);
    },  
    showMoreNews() {
      this.maxItemsNews += 5;
      this.maxMobileItemsNews += 5;
      logUserEvent('showMoreNews', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
    },
    showMorePressReleases() {
      this.maxItemsPress += 5;
      this.maxMobileItemsPress += 5;
      logUserEvent('showMorePressReleases', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
    },
    showMoreAnalystArticles() {
      this.maxItemsArticles += 5;
      this.maxMobileItemsArticles += 5;
      logUserEvent('showMoreAnalystArticles', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
    },
    showMoreAll() {
      this.maxItemsAll += 5;
      this.maxMobileItemsAll += 5;
      logUserEvent('showMoreAll', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
    },
    clickOnNews(item) {
      logUserEvent('newsClick', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol + " " + item.url);
      window.open(item.url, '_blank');
    },
    clickOnPressRelease(item) {
      logUserEvent('pressReleaseClick', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
    },
    clickOnAnalystArticle(item) {
      logUserEvent('analystArticleClick', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol + " " + item.url);
      window.open(item.url, '_blank');
    },
    openArticleDialog(article = null) {
      this.editingArticle = article;
      if (article) {
        this.articleForm = { ...article };
      } else {
        this.articleForm = {
          title: '',
          url: '',
          source: sessionManager.read('userName')
        };
      }
      this.dialog = true;
    },
    async saveArticle() {
      const endpoint = '/api/HttpTriggerArticlePost';

      if(this.articleForm.url != '') {
        this.articleForm.source = this.getHostname(this.articleForm.url) + ' - ' + sessionManager.read('name') + ' ' + sessionManager.read('surname');
      }
      else
      {
        this.articleForm.source = sessionManager.read('name') + ' ' + sessionManager.read('surname');
      }
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'JWT': sessionManager.read('token')
        },
        body: JSON.stringify({
          ...this.articleForm,
          symbol: this.symbol,
          id: this.editingArticle?.id
        })
      });

      if (response.ok) {
        this.dialog = false;
        this.fetchCompanyNews();
      }
    },
    confirmDelete(article) {
      this.articleToDelete = article;
      this.deleteDialog = true;
    },
    async deleteArticle() {
      const response = await fetch('/api/HttpTriggerArticleDelete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'JWT': sessionManager.read('token')
        },
        body: JSON.stringify({
          id: this.articleToDelete.id,
          symbol: this.symbol
        })
      });

      if (response.ok) {
        this.deleteDialog = false;
        this.articleToDelete = null;
        this.fetchCompanyNews();
      }
    }
  },
  watch: {
    symbol() {
      this.fetchCompanyNews();
    }
  },
}
</script>

<template>
  <div class="news-container">
    <div>
      <div v-if="isContentCreator" class="mb-4">
        <v-btn color="primary" @click="openArticleDialog()">
          {{ strings['addArticleButton'] }}
        </v-btn>
      </div>

      <v-tabs v-model="activeTab" show-arrows :class="{'mobile-tabs': isMobile}">
        <v-tab>{{ strings['all'] }}</v-tab>
        <v-tab :style="{ display: (analystArticles.length > 0 || isContentCreator) ? '' : 'none' }">{{ strings['analystArticles'] }}</v-tab>
        <v-tab :style="{ display: news.length > 0 ? '' : 'none' }">{{ strings['news'] }}</v-tab>
        <v-tab :style="{ display: pressReleases.length > 0 ? '' : 'none' }">{{ strings['pressReleases'] }}</v-tab>

        <v-tab-item>
          <div class="px-v-1 py-v-sm">
            <v-card v-for="item in filteredAllItems" :key="item.createdAt" class="mb-4">
              <v-card-text>
                <div class="d-flex">
                  <div v-if="item.type === 'news' && item.image" class="news-thumbnail mr-3">
                    <img :src="item.image" :alt="item.title">
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-caption grey--text">{{ getDaysAgo(item.createdAt) }}</div>
                    <div class="text-darkest font-pj text-v-md">
                      <template v-if="item.type === 'analyst' || item.type === 'news'">
                        <a @click.prevent="item.type === 'analyst' ? clickOnAnalystArticle(item) : clickOnNews(item)" 
                           :href="item.url" 
                           class="text-decoration-none cursor-pointer">
                          {{ item.title }}
                        </a>
                      </template>
                      <template v-else>
                        {{ item.title }}
                      </template>
                    </div>
                    <div v-if="item.type === 'analyst'" class="text-caption grey--text">{{strings['source']}}: {{ item.source }}</div>
                    <div v-if="item.type === 'news'" class="text-caption grey--text">Source: {{ item.site }}</div>
                    <div class="text-darkest font-pj text-v-sm" @click="onClick(item)">{{ item.text }}</div>
                    <div v-if="item.type === 'analyst' && isCreatedByMe(item)" class="d-flex justify-end">
                      <v-icon small class="mr-2" @click="openArticleDialog(item)">mdi-pencil</v-icon>
                      <v-icon small @click="confirmDelete(item)">mdi-delete</v-icon>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <button v-if="hasMoreAll" type="button" @click="showMoreAll()" class="flex-none bg-secondary border rounded-md border-solid border-secondary text-darkest text-v-sm font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-very-very-light hover:border-secondary-light">
              {{ strings['loadMore'] }}
            </button>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="px-v-1 py-v-sm">
            <v-card v-for="item in filteredAnalystArticles" :key="item.date" class="mb-4">
              <v-card-text>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <div class="text-caption grey--text">{{ getDaysAgo(item.createdAt) }}</div>
                    <div class="text-darkest font-pj text-v-md">
                      <a @click.prevent="clickOnAnalystArticle(item)" :href="item.url" class="text-decoration-none cursor-pointer">
                        {{ item.title }}
                      </a>
                    </div>
                    <div class="text-caption grey--text">{{strings['source']}}: {{ item.source }}</div>
                    <div class="text-darkest font-pj text-v-sm" @click="onClick(item)">{{ item.text }}</div>
                    <div v-if="isContentCreator && isCreatedByMe(item)" class="d-flex justify-end">
                      <v-icon small class="mr-2" @click="openArticleDialog(item)">mdi-pencil</v-icon>
                      <v-icon small @click="confirmDelete(item)">mdi-delete</v-icon>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <button v-if="hasMoreAnalystArticles" type="button" @click="showMoreAnalystArticles()" class="flex-none bg-secondary border rounded-md border-solid border-secondary text-darkest text-v-sm font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-very-very-light hover:border-secondary-light">
              {{ strings['loadMore'] }}
            </button>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="px-v-1 py-v-sm">
            <v-card v-for="item in filteredNews" :key="item.publishedDate" class="mb-4">
              <v-card-text>
                <div class="d-flex">
                  <div v-if="item.image" class="news-thumbnail mr-3">
                    <img :src="item.image" :alt="item.title">
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-caption grey--text">{{ getDaysAgo(item.publishedDate) }}</div>
                    <div class="text-darkest font-pj text-v-md">
                      <a @click.prevent="clickOnNews(item)" :href="item.url" class="text-decoration-none cursor-pointer">
                        {{ item.title }}
                      </a>
                    </div>
                    <div class="text-caption grey--text">Source: {{ item.site }}</div>
                    <div class="text-darkest font-pj text-v-sm" @click="onClick(item)">{{ item.text }}</div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <button v-if="hasMoreNews" type="button" @click="showMoreNews()" class="flex-none bg-secondary border rounded-md border-solid border-secondary text-darkest text-v-sm font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-very-very-light hover:border-secondary-light">
              {{ strings['loadMore'] }}
            </button>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="px-v-1 py-v-sm">
            <v-card v-for="item in filteredPressReleases" :key="item.date" class="mb-4">
              <v-card-text>
                <div class="text-caption grey--text">{{ getDaysAgo(item.date) }}</div>
                <div class="text-darkest font-pj text-v-md">{{ item.title }}</div>
                <div class="text-darkest font-pj text-v-sm cursor-pointer" @click="onClick(item)">{{ item.text }}</div>
              </v-card-text>
            </v-card>
            <button v-if="hasMorePressReleases" type="button" @click="showMorePressReleases()" class="flex-none bg-secondary border rounded-md border-solid border-secondary text-darkest text-v-sm font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-very-very-light hover:border-secondary-light">
              {{ strings['loadMore'] }}
            </button>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>{{ editingArticle ? strings['editArticle'] : strings['addArticle'] }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="articleForm.title" :label="strings['title']"></v-text-field>
          <v-text-field v-model="articleForm.url" :label="strings['url']"></v-text-field>
          <v-textarea v-model="articleForm.text" :label="strings['summary']"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">{{strings['cancel']}}</v-btn>
          <v-btn color="primary" @click="saveArticle">{{strings['save']}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="300px">
      <v-card>
        <v-card-title>{{strings['deleteArticle']}}</v-card-title>
        <v-card-text>{{strings['sureToDelete']}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">{{strings['cancel']}}</v-btn>
          <v-btn color="error" @click="deleteArticle">{{strings['delete']}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.news-container {
  padding: 20px;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: underline !important;
}

.news-thumbnail {
  width: 80px;
  height: 80px;
  overflow: hidden;
  flex-shrink: 0;
}

.news-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.v-text-field textarea[readonly="readonly"] {
    background-color: yellowgreen
}

.mobile-tabs :deep(.v-tab) {
  font-size: 12px !important;
  padding: 0 8px !important;
  min-width: auto !important;
}
</style>
