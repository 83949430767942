/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyGrowthInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Growth & Competitive Advantage',
            explaination_title: 'Identifying the Moat',
            explaination: 'Companies able to consistently increase sales, earnings, operating cash flow, and book value over an extended period likely possess a significant competitive advantage, often termed a "moat".',
            explaination_2: 'While individual metrics can be manipulated, an upward trend across all metrics over time generally indicates robust performance.',
            explaination_3: 'A "moat" refers to an inherent competitive advantage that enables a company to generate higher profits than its competitors over the long term.',

            how: 'Calculation Method',
            how_1: 'The average growth Ø for the last 3, 5, and 10 years is calculated from the most recent annual reports. For the TTM (Trailing Twelve Months), the latest quarterly reports are utilized.',
            how_2: 'The average growth rate is then computed using the formula: (End Value / Start Value) ^ (1 / Number of Years) - 1.',
            how_3: 'For example, if we are calculating the average for a three-year period (from 2019 to 2022), we would use the 2019 value as the start point and the 2022 value as the end point.',
          },
          de: {
            title: 'Wachstum & Buggraben',
            explaination_title: 'Erkennung des Wettbewerbsvorteils',
            explaination: 'Unternehmen, die über längere Zeit Umsatz, Gewinn, operativen Cashflow und Buchwert steigern, besitzen mit hoher Wahrscheinlichkeit einen Wettbewerbsvorteil ("Burggraben").',
            explaination_2: 'Obwohl Einzelkennzahlen manipulierbar sein können, deutet ein langfristiger Anstieg aller Kennzahlen auf eine starke Position hin.',
            explaination_3: 'Ein "Burggraben" bezeichnet einen inhärenten Wettbewerbsvorteil, der es einem Unternehmen ermöglicht, über einen längeren Zeitraum höhere Gewinne als die Konkurrenz zu erzielen.',

            how: 'Berechnungsweise',
            how_1: 'Das durchschnittliche Wachstum Ø der letzten 3, 5 und 10 Jahre wird aus den jüngsten Jahresberichten ermittelt. Für die TTM (Trailing Twelve Months, die letzten 12 Monate) verwenden wir die neuesten Quartalsberichte.',
            how_2: 'Die durchschnittliche Wachstumsrate berechnet sich nach folgender Formel: (Endwert / Startwert) ^ (1 / Anzahl der Jahre) - 1.',
            how_3: 'Als Beispiel: Zur Berechnung des Dreijahresdurchschnitts (von 2019 bis 2022) verwenden wir den Wert von 2019 als Start- und den von 2022 als Endwert.',
          }
        },

        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>


<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination_2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination_3'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['how'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['how_1'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['how_2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['how_3'] }}</div>
    </template>
  </PopupInfo>
</template>
  
/* eslint-enable */ 
  