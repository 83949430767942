
<script>
import { sessionManager } from '@/main';
import { logUserEvent } from '../helper';

export default {
  name: 'WaitlistRegistration',
  data() {
    return {
      email: '',
      name: '',
      emailRulesEN: [
        v => !!v || 'Email is required',
        v => /.+@.+/.test(v) || 'Email must be valid'
      ],
      emailRulesDE: [
        v => !!v || 'Email ist erforderlich',
        v => /.+@.+/.test(v) || 'Email muss gültig sein'
      ],
      valid: false,
      languageStrings: {
        en: {
          title: 'Join the waiting list',
          email: 'Email',
          submit: 'Join the waitlist',
          headline: 'Join the waitlist!',
          text: 'Join the waitlist and be the first to dive into the world of value investing! Don\'t miss out on this opportunity to get ahead of the game. Sign up now and let the good times roll!',
          question: 'What method do you use to evaluate companies?',
          fineprint: 'By signing up, you agree to our ',
          fineprint_link: 'privacy policy',
          fineprint_2: '.',
        },
        de: {
          title: 'Eintrag in die Warteliste',
          email: 'E-Mail',
          submit: 'Einladung anfordern',
          headline: 'Eintrag in die Warteliste!',
          text: 'Melde Dich für unsere Warteliste an und sichere Dir frühzeitigen Zugang zu Valuetion!',
          question: 'Welche Methode verwendest Du, um Unternehmen zu bewerten?',
          fineprint: 'Mit Deiner Anmeldung erklärst Du Dich mit unseren ',
          fineprint_link: 'Datenschutzbestimmungen',
          fineprint_2: ' einverstanden.',
        }
      },
      language: '',
      answer: '',
      emailRules: [],
      submitted: false,
    };
  },
  created() {
    this.language = sessionManager.read('language');
    if (this.language === 'en') {
      this.emailRules = this.emailRulesEN;
    } else {
      this.emailRules = this.emailRulesDE;
    }
    this.$router.push('/register');
    logUserEvent('registerWaitlist', sessionManager, this.$vuetify.breakpoint.mobile);
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
    emailColor() {
      return (this.email && this.email.length > 0 && !this.validateEmail() && this.submitted) ? "rgba(255, 0, 0, 0.1)" : "white";
    },
  },
  methods: {
    validateEmail() {
      const errors = this.emailRules.map(rule => rule(this.email)).filter(result => result !== true);
      if (errors.length > 0) {
        return false;
      }
      return true;
    },
    async submitForm() {
      this.submitted = true;

      if (!this.validateEmail()) {
        return;
      }

      logUserEvent('submitRegisterWaitlist', sessionManager, this.$vuetify.breakpoint.mobile);

      // Register the user to the beta waitlist
      const url = '/api/HttpTriggerRegisterWaitlist';
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email, 
          name: this.name,
          answer: this.answer,
          language: this.language,
        }),
      }).then((response) => {
        if (response.status === 200) {

          // Redirect to the verification page
          this.$router.push({
            name: 'checkMailWaitlist',
            params: {
              email: this.email,
            },
          });
        } else {
        }
      });    
    },
  }, 
}
</script>

<style scoped>
  .v-list-item__content {
    font-size: 15px;
    font-family: Plus Jakarta Sans;
  }
  .v-input {
    font-size: 15x;
    font-family: Plus Jakarta Sans;
    border: 0px !important;

    border: none; /* <-- This thing here */
    border-radius: 8px;
    padding: 8px;
    /*background-color: #f8faf5;*/
  }
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
        <div class="text-v-sm darkest font-pj pb-v-xl">{{strings['text']}}</div>
        <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
          <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['email']}}</div>
          <div>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :background-color="emailColor"
              outlined
            />
          </div>
          <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['question']}}</div>
          <div>
            <v-textarea
              outlined
              v-model="answer"
            ></v-textarea>
          </div>
          <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light">{{ strings['submit'] }}</button>
        </v-form>
        <div class="font-pj text-v-tiny py-v-sm">
            {{ strings['fineprint'] }} <router-link :to="{ name: 'privacy' }">{{ strings['fineprint_link'] }}</router-link>{{ strings['fineprint_2'] }}
          </div>
      </div>
    </div>
  </div>
</template>


