/* eslint-disable */

import { Colors } from './colors';
import { formatUnitOfAccount } from './helper';

const invalidDepth = -100;

/*---------------------------------------------------------------------------------------------------------/
// Graph node
/---------------------------------------------------------------------------------------------------------*/
export class GraphNode
{
  constructor(name, text, height, colorPos, colorNeg, fontSize, bold = false)
  {
    this.name = name;
    this.text = text;
    this.height = height;
    this.fontSize = fontSize;
    this.bold = bold;
    this.colorPos = colorPos;
    this.colorNeg = colorNeg;

    this.parentsLeft = [];
    this.parentsRight = [];

    this.childrenLeft = [];
    this.childrenRight = [];

    this.addedToList = false;
  }
}

/*---------------------------------------------------------------------------------------------------------/
// Graph class
/---------------------------------------------------------------------------------------------------------*/
export class Graph
{
  constructor(fontSizeSum)
  {
    this.fontSizeSum = fontSizeSum;
    this.nodes = [];
    this.id = 0;
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Add node to the graph
  /---------------------------------------------------------------------------------------------------------*/
  addNode(node)
  {
    this.nodes.push(node);
    node.id = this.id;
    this.id += 1;
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Copy links
  /---------------------------------------------------------------------------------------------------------*/
  copyLink(links)
  {
    var copy = [];
    links.forEach(element => {
      copy.push({ node: element.node, dirNodeTo: element.dirNodeTo, invertedLinkDir: element.invertedLinkDir });
    });
    
    return copy;
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Invert left and right
  /---------------------------------------------------------------------------------------------------------*/
  invertLeftRight(node)
  {
    var mapIsInverted = new Map();
    this.nodes.forEach(element => {
      mapIsInverted[element.id] = false;
    });

    this.invertLeftRightRecursive(node, mapIsInverted);
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Invert left and right
  /---------------------------------------------------------------------------------------------------------*/
  invertLeftRightRecursive(node, mapIsInverted)
  {
    //console.log('invertLeftRight ' + node.name);

    if(mapIsInverted[node.id] == true)
    {
      //console.log('invertLeftRight ' + node.name + ' skipped.');
      return;
    }

    // invert children
    //---------------------------------------------

    // remove links
    var tempChildrenLeft = this.copyLink(node.childrenLeft);
    tempChildrenLeft.forEach(element => {
      this.removeLink(node, "left", element.node, element.dirNodeTo);
    });

    // remove links
    var tempChildrenRight = this.copyLink(node.childrenRight);
    tempChildrenRight.forEach(element => {
      this.removeLink(node, "right", element.node, element.dirNodeTo);
    });

    // add links
    tempChildrenLeft.forEach(element => {
      this.addLink(node, "right", element.node, element.dirNodeTo, element.invertedLinkDir);
    });

    // add links
    tempChildrenRight.forEach(element => {
      this.addLink(node, "left", element.node, element.dirNodeTo, element.invertedLinkDir);
    });

    // invert parents
    //---------------------------------------------
    var tempParentsLeft = this.copyLink(node.parentsLeft);
    tempParentsLeft.forEach(element => {
      this.removeLink(element.node, element.dirNodeTo, node, "left");
    });

    var tempParentsRight = this.copyLink(node.parentsRight);
    tempParentsRight.forEach(element => {
      this.removeLink(element.node, element.dirNodeTo, node, "right");
    });

    // add links
    tempParentsLeft.forEach(element => {
      this.addLink(element.node, element.dirNodeTo, node, "right", element.invertedLinkDir);
    });

    // add links
    tempParentsRight.forEach(element => {
      this.addLink(element.node, element.dirNodeTo, node, "left", element.invertedLinkDir);
    });

    // mark as inverted
    mapIsInverted[node.id] = true;

    // invert nodes 
    var copyChildrenLeft = this.copyLink(node.childrenLeft);
    for(let i = 0; i < copyChildrenLeft.length; i++)
    {
      // console.log('node ' + node.name + ' node.childrenLeft[' + i + ']: ' + copyChildrenLeft[i].node.name);
      this.invertLeftRightRecursive(copyChildrenLeft[i].node, mapIsInverted);
    }
    var copyParentsLeft = this.copyLink(node.parentsLeft);
    for(let i = 0; i < copyParentsLeft.length; i++)
    {
      // console.log('node ' + node.name + ' node.parentsLeft[' + i + ']: ' + copyParentsLeft[i].node.name);
      this.invertLeftRightRecursive(copyParentsLeft[i].node, mapIsInverted);
    }
    var copyChildrenRight = this.copyLink(node.childrenRight);
    for(let i = 0; i < copyChildrenRight.length; i++)
    {
      // console.log('node ' + node.name + ' node.childrenRight[' + i + ']: ' + copyChildrenRight[i].node.name);
      this.invertLeftRightRecursive(copyChildrenRight[i].node, mapIsInverted);
    }
    var copyParentsRight = this.copyLink(node.parentsRight);
    for(let i = 0; i < copyParentsRight.length; i++)
    {
      // console.log('node ' + node.name + ' node.parentsRight[' + i + ']: ' + copyParentsRight[i].node.name);
      this.invertLeftRightRecursive(copyParentsRight[i].node, mapIsInverted);
    }
  }


  /*---------------------------------------------------------------------------------------------------------/
  // Add a link between nodes
  /---------------------------------------------------------------------------------------------------------*/
  addLink(nodeFrom, dirNodeFrom, nodeTo, dirNodeTo, invertedLinkDir)
  {
    if(dirNodeFrom == "left")
    {
      nodeFrom.childrenLeft.push({ node: nodeTo, dirNodeTo: dirNodeTo, invertedLinkDir: invertedLinkDir });
    }
    else
    {
      nodeFrom.childrenRight.push({ node: nodeTo, dirNodeTo: dirNodeTo, invertedLinkDir: invertedLinkDir });
    }

    if(dirNodeTo == "left")
    {
      nodeTo.parentsLeft.push({ node: nodeFrom, dirNodeTo: dirNodeFrom, invertedLinkDir: invertedLinkDir });
    }
    else
    {
      nodeTo.parentsRight.push({ node: nodeFrom, dirNodeTo: dirNodeFrom, invertedLinkDir: invertedLinkDir });
    }
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Remove a link between nodes
  /---------------------------------------------------------------------------------------------------------*/
  removeLink(nodeFrom, dirNodeFrom, nodeTo, dirNodeTo)
  {
    // remove children from nodeFrom
    if(dirNodeFrom == "left")
    {
      var index = -1;
      var i = 0; 
      nodeFrom.childrenLeft.forEach(element => {
        if(element.node === nodeTo && element.dirNodeTo == dirNodeTo)
        {
          index = i;
        }
        i = i + 1;
      });
      if (index > -1) { // only splice array when item is found
        nodeFrom.childrenLeft.splice(index, 1); // 2nd parameter means remove one item only
      }
      else
      {
        // console.log('link not removed.');
      }
    }
    else
    {
      var index = -1;
      var i = 0; 
      nodeFrom.childrenRight.forEach(element => {
        if(element.node === nodeTo && element.dirNodeTo == dirNodeTo)
        {
          index = i;
        }
        i = i + 1;
      });
      if (index > -1) { // only splice array when item is found
        nodeFrom.childrenRight.splice(index, 1); // 2nd parameter means remove one item only
      }
      else
      {
        // console.log('link not removed.');
      }
    }

    if(dirNodeTo == "left")
    {
      // remove parents from nodeTo
      var index = -1;
      var i = 0; 
      nodeTo.parentsLeft.forEach(element => {
        if(element.node === nodeFrom && element.dirNodeTo == dirNodeFrom)
        {
          index = i;
        }
        i = i + 1;
      });
      if (index > -1) { // only splice array when item is found
        nodeTo.parentsLeft.splice(index, 1); // 2nd parameter means remove one item only
      }
      else
      {
        // console.log('link not removed.');
      }
    }
    else
    {
      // remove parents from nodeTo
      var index = -1;
      var i = 0; 
      nodeTo.parentsRight.forEach(element => {
        if(element.node === nodeFrom && element.dirNodeTo == dirNodeFrom)
        {
          index = i;
        }
        i = i + 1;
      });
      if (index > -1) { // only splice array when item is found
        nodeTo.parentsRight.splice(index, 1); // 2nd parameter means remove one item only
      }
      else
      {
        // console.log('link not removed.');
      }
    }
  }

   /*---------------------------------------------------------------------------------------------------------/
  // Transform the graph
  /---------------------------------------------------------------------------------------------------------*/
  invertDirection(direction)
  {
    if(direction == "left")
    {
      return "right";
    }
    return "left";
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Transform the graph
  /---------------------------------------------------------------------------------------------------------*/
  transformGraph(scale, unitOfAccount, language)
  {
    var mapUpdate = new Map();
    this.nodes.forEach(element => {
      mapUpdate[element.id] = true;
    });

    this.nodes.forEach(element => {
      this.transform(element, scale, unitOfAccount, mapUpdate, language);
    });
  }

  

  /*---------------------------------------------------------------------------------------------------------/
  // print
  /---------------------------------------------------------------------------------------------------------*/
  printGraph()
  {
    console.log('-----------------------------------------------------------------------------');
    console.log('Graph: ' + this.name);
    console.log(this.nodes);

    this.nodes.forEach(element => {
      // print graph
      console.log('node: ' + element.name + ' height ' + element.height);

      element.childrenLeft.forEach(e => {
        console.log('  ' + element.name + '  child left ---- ' + e.node.name + ' ' + e.dirNodeTo + ' link inversion dir ' + e.invertedLinkDir);
      });
      element.parentsLeft.forEach(e => {
        console.log('  ' + element.name + '  parent left ---- ' + e.node.name + ' ' + e.dirNodeTo + ' link inversion dir ' + e.invertedLinkDir);
      });

      element.childrenRight.forEach(e => {
        console.log('  ' + element.name + '  child right ---- ' + e.node.name + ' ' + e.dirNodeTo + ' link inversion dir ' + e.invertedLinkDir);
      });
      element.parentsRight.forEach(e => {
        console.log('  ' + element.name + '  parent right ---- ' + e.node.name + ' ' + e.dirNodeTo + ' link inversion dir ' + e.invertedLinkDir);
      });
    });

    console.log('-----------------------------------------------------------------------------');    
  }


  /*---------------------------------------------------------------------------------------------------------/
  // Transform the graph
  /---------------------------------------------------------------------------------------------------------*/
  transform(node, scale, unitOfAccount, mapUpdate, language)
  {
    if(!mapUpdate[node.id])
    {
      return;
    }

    // nodes on the left side
    //---------------------------------------------------------------------------------------

    // check if the left side needs adjustments
    var sumLeft = 0;
    for(let i = 0; i < node.parentsLeft.length; i++)  
    {
      var edge = Math.min(Math.abs(node.height), Math.abs(node.parentsLeft[i].node.height));
      sumLeft += edge;
    }
    for(let i = 0; i < node.childrenLeft.length; i++)  
    {
      var edge = Math.min(Math.abs(node.height), Math.abs(node.childrenLeft[i].node.height));
      sumLeft += edge;
    }
    var leftNeedsAdjustments = true;
    if((sumLeft == Math.abs(node.height)) || ((node.parentsLeft.length + node.childrenLeft.length) == 0))
    {
      leftNeedsAdjustments = false;
    }

    var nodesToProcess = [];

    if(leftNeedsAdjustments)
    {
      // check if there are negative parents
      var sumPos = 0;
      var parentsPos = [];
      var childrenPos = [];
      var parentsNeg = [];
      var childrenNeg = [];

      for(let i = 0; i < node.parentsLeft.length; i++)
      {
        const element = node.parentsLeft[i];
        if((element.node.height * node.height * element.invertedLinkDir) < 0)
        {
          parentsNeg.push(element);
        }
        else
        {
          sumPos += element.node.height;
          parentsPos.push(element);
        }
      }
      for(let i = 0; i < node.childrenLeft.length; i++)
      {
        const element = node.childrenLeft[i];
        if((element.node.height * node.height * element.invertedLinkDir) < 0)
        {
          childrenNeg.push(element);
        }
        else
        {
          sumPos += element.node.height;
          childrenPos.push(element);
        }
      }
  
      if((parentsNeg.length + childrenNeg.length) > 0)
      {
        // we need to add a new sum node of all the positive parents
        var lengthPos = parentsPos.length + childrenPos.length;
        if(lengthPos > 1)
        {
          // add new node
          var nodePos = new GraphNode(language == 'de' ? "Summe" : "Sum", formatUnitOfAccount(sumPos / scale, unitOfAccount, true), sumPos, node.colorPos, node.colorNeg, this.fontSizeSum);
          this.addNode(nodePos);
          mapUpdate[nodePos.id] = false;

          // connect positive parents to the node
          parentsPos.forEach(element => {
            this.addLink(element.node, element.dirNodeTo, nodePos, "left", 1); 
            this.removeLink(element.node, element.dirNodeTo, node, "left"); 

            mapUpdate[element.node.id] = true; 
            nodesToProcess.push(element);
          });

          // connect the positve children to the node
          childrenPos.forEach(element => {
            this.addLink(nodePos, "left", element.node, element.dirNodeTo, 1);
            this.removeLink(node, "left", element.node, element.dirNodeTo);

            mapUpdate[element.node.id] = true;  
            nodesToProcess.push(element);
          });

          // connect negative parents to the new node 
          parentsNeg.forEach(element => {
            this.removeLink(element.node, element.dirNodeTo, node, "left");
            var invertedDirNodeTo = this.invertDirection(element.dirNodeTo);
            this.invertLeftRight(element.node);
            this.addLink(nodePos, "right", element.node, invertedDirNodeTo, -1);

            mapUpdate[element.node.id] = true;   
            nodesToProcess.push(element);
          });

          // connect positive chidren to the sum
          childrenNeg.forEach(element => {
            this.removeLink(node, "left", element.node, element.dirNodeTo);
            var invertedDirNodeTo = this.invertDirection(element.dirNodeTo);
            this.invertLeftRight(element.node);
            this.addLink(nodePos, "right", element.node, invertedDirNodeTo, -1);            

            mapUpdate[element.node.id] = true;  
            nodesToProcess.push(element);
          })

          // connect the own node to the new node
          if(parentsNeg.length > childrenNeg.length)
          {
            this.addLink(nodePos, "right", node, "left", 1);
          }
          else
          {
            this.addLink(node, "left", nodePos, "right", 1);
          }        
        }
        else if(lengthPos == 1)
        {
          var nodePos;
          if(parentsPos.length > 0)
          {
            nodePos = parentsPos[0].node;
          }
          if(childrenPos.length > 0)
          {
            nodePos = childrenPos[0].node;
          }

          // relink to the existing node if not already linked
          parentsNeg.forEach(element => {
            if(!(nodePos == element.node))
            {
              this.removeLink(element.node, element.dirNodeTo, node, "left");
              var invertedDirNodeTo = this.invertDirection(element.dirNodeTo);
              this.invertLeftRight(element.node);
              this.addLink(nodePos, "right", element.node, invertedDirNodeTo, -1);
             
              mapUpdate[element.node.id] = true;  
              nodesToProcess.push(element);
            }
          });

          // connect positive chidren to the sum
          childrenNeg.forEach(element => {
            if(!(nodePos == element.node))
            {
              this.removeLink(node, "left", element.node, element.dirNodeTo);
              var invertedDirNodeTo = this.invertDirection(element.dirNodeTo);
              this.invertLeftRight(element.node);
              this.addLink(nodePos, "right", element.node, invertedDirNodeTo, -1);  
              
              mapUpdate[element.node.id] = true;
              nodesToProcess.push(element);
            }
          });
        }
        else
        {
          // there is no positive parent -> no need to relink, just remember to process negative
          parentsNeg.forEach(element => {
            // remember to invert
            nodesToProcess.push(element);
          });
          childrenNeg.forEach(element => {
            // remember to invert
            nodesToProcess.push(element);
          });
        }
      }
    }

    // nodes on the right side
    //---------------------------------------------------------------------------------------

    // check if the right side needs adjustments
    var sumRight = 0;
    for(let i = 0; i < node.parentsRight.length; i++)  
    {
      var edge = Math.min(Math.abs(node.height), Math.abs(node.parentsRight[i].node.height));
      sumRight += edge;
    }
    for(let i = 0; i < node.childrenRight.length; i++)  
    {
      var edge = Math.min(Math.abs(node.height), Math.abs(node.childrenRight[i].node.height));
      sumRight += edge;
    }
    var rightNeedsAdjustment = true;
    if((sumRight == Math.abs(node.height)) || ((node.parentsRight.length + node.childrenRight.length) == 0))
    {
      rightNeedsAdjustment = false;
    }

    if(rightNeedsAdjustment)
    {
      // check if there are negative parents
      var sumPos = 0;
      var parentsPos = [];
      var childrenPos = [];
      var parentsNeg = [];
      var childrenNeg = [];

      for(let i = 0; i < node.parentsRight.length; i++)
      {
        const element = node.parentsRight[i];
        if((element.node.height * node.height * element.invertedLinkDir) < 0)
        {
          parentsNeg.push(element);
        }
        else
        {
          sumPos += element.node.height;
          parentsPos.push(element);
        }
      }
      for(let i = 0; i < node.childrenRight.length; i++)
      {
        const element = node.childrenRight[i];
        if((element.node.height * node.height * element.invertedLinkDir) < 0)
        {
          childrenNeg.push(element);
        }
        else
        {
          sumPos += element.node.height;
          childrenPos.push(element);
        }
      }

      if((childrenNeg.length + parentsNeg.length) > 0)
      {
        if((childrenPos.length + parentsPos.length) > 1)
        {
          // we need to add a new sum node for all the positive links
          var nodeSum = new GraphNode(language == 'de' ? "Summe" : "Sum", formatUnitOfAccount(sumPos / scale, unitOfAccount, true), sumPos, node.colorPos, node.colorNeg, this.fontSizeSum);
          this.addNode(nodeSum);
          mapUpdate[nodeSum.id] = true;

          // connect all positive children to the node
          childrenPos.forEach(element => {
            this.addLink(nodeSum, "right", element.node, element.dirNodeTo, 1);
            this.removeLink(node, "right", element.node, element.dirNodeTo);

            mapUpdate[element.node.id] = true;                
            nodesToProcess.push(element);
          });

          // connect all positive parents to the node
          parentsPos.forEach(element => {
            this.addLink(element.node, element.dirNodeTo, nodeSum, "right", 1);
            this.removeLink(element.node, element.dirNodeTo, node, "right");

            mapUpdate[element.node.id] = true; 
            nodesToProcess.push(element);
          });

          // connect all negative children to the node and invert the relationship
          childrenNeg.forEach(element => {            
            this.removeLink(node, "right", element.node, element.dirNodeTo);
            var invertedDirNodeTo = this.invertDirection(element.dirNodeTo);
            this.invertLeftRight(element.node);
            this.addLink(nodeSum, "left", element.node, invertedDirNodeTo, -1);

            mapUpdate[element.node.id] = true; 
            nodesToProcess.push(element);
          });

          // connect all negative parents to the node and invert the relationship
          parentsNeg.forEach(element => {            
            this.removeLink(element.node, element.dirNodeTo, node, "right");
            var invertedDirNodeTo = this.invertDirection(element.dirNodeTo);
            this.invertLeftRight(element.node);
            this.addLink(element.node, invertedDirNodeTo, nodeSum, "left", -1);

            mapUpdate[element.node.id] = true; 
            nodesToProcess.push(element);
          });

          // connect the own node to the new node
          if(childrenNeg.length > parentsNeg.length)
          {
            this.addLink(node, "right", nodeSum, "left", 1);
          }
          else
          {
            this.addLink(nodeSum, "left", node, "right", 1);
          }
        }
        else if((childrenPos.length + parentsPos.length) == 1)
        {
          var nodePos;
          if(childrenPos.length > 0)
          {
            nodePos = childrenPos[0].node;
          }
          if(parentsPos.length > 0)
          {
            nodePos = parentsPos[0].node;
          }

          // connect all negative children to the node and invert the relationship
          childrenNeg.forEach(element => {
            if(!(element.node == nodePos))
            {
              this.removeLink(node, "right", element.node, element.dirNodeTo);
              var invertedDirNodeTo = this.invertDirection(element.dirNodeTo);
              this.invertLeftRight(element.node);
              this.addLink(nodePos, "left", element.node, invertedDirNodeTo, -1);

              mapUpdate[element.node.id] = true; 
              nodesToProcess.push(element);
            }
          });

          // connect all negative parents to the node and invert the relationship
          parentsNeg.forEach(element => {
            if(!(element.node == nodePos))
            {              
              this.removeLink(element.node, element.dirNodeTo, node, "right");
              var invertedDirNodeTo = this.invertDirection(element.dirNodeTo);
              this.invertLeftRight(element.node);
              this.addLink(element.node, invertedDirNodeTo, nodePos, "left", -1);

              mapUpdate[element.node.id] = true; 
              nodesToProcess.push(element);
            }
          });
        }       
      }
      else
      {
        // we only need to process the children
        childrenNeg.forEach(element => {
          nodesToProcess.push(element);
        });
        // we only need to process the children
        parentsNeg.forEach(element => {
          nodesToProcess.push(element);
        });
      }      
    }

    // mark self as processed
    mapUpdate[node.id] = false;

    // process all nodes
    nodesToProcess.forEach(element => {
      this.transform(element.node, scale, unitOfAccount, mapUpdate, language);
    });
  }

  
  /*---------------------------------------------------------------------------------------------------------/
  // Set the depth of the nodes
  /---------------------------------------------------------------------------------------------------------*/
  setDepthOfNodes()
  {
    // initialize all nodes to an invalid depth
    var mapUpdate = new Map();
    this.nodes.forEach(element => {
      element.depth = invalidDepth;
      mapUpdate[element.id] = true;
    });

    // search a node that has a non zero height and start from there
    var startNode = null;
    this.nodes.forEach(element => {
      if(element.height != 0)
      {
        startNode = element;
      }
    });

    // start with one node 
    this.setAndPropagateDepth(startNode, 0, 1, mapUpdate);

    // check if all nodes have a valid depth
    var allValid = true;
    this.nodes.forEach(element => {
      if(element.depth == invalidDepth)
      {
        //console.log('setDepthOfNodes: node ' + element.name + ' has invalid depth.');
        allValid = false;
      }
    });
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Propagate depth
  /---------------------------------------------------------------------------------------------------------*/
  setAndPropagateDepth(node, depth, direction, mapUpdate)
  {
    //// console.log('setAndPropagateDepth -- ' + node.name + ' node.depth ' + node.depth + ' depth ' + depth + ' direction ' + direction);

    if(node.depth < depth)
    {
      node.depth = depth;
      node.direction = direction;
      
      // the current value changed -> update all childs & parents
      node.childrenLeft.forEach(element => {
        mapUpdate[element.node.id] = true;
      });
      node.parentsLeft.forEach(element => {
        mapUpdate[element.node.id] = true;
      });
      node.childrenRight.forEach(element => {
        mapUpdate[element.node.id] = true;
      });
      node.parentsRight.forEach(element => {
        mapUpdate[element.node.id] = true;
      });
    }

    // mark as updated
    mapUpdate[node.id] = false;

    // propagate to right
    node.childrenRight.forEach(element => {
      //// console.log('childrenRight -- ' + element.node.name);
      if(mapUpdate[element.node.id] == true)
      {
        this.setAndPropagateDepth(element.node, depth + 1, direction, mapUpdate);
      }
    });
    node.parentsRight.forEach(element => {
      //// console.log('parentRight -- ' + element.node.name);
      if(mapUpdate[element.node.id] == true)
      {
        this.setAndPropagateDepth(element.node, depth + 1, direction, mapUpdate);
      }
    });

    // propagate to left
    node.childrenLeft.forEach(element => {
      //// console.log('childrenLeft -- ' + element.node.name);
      if(mapUpdate[element.node.id] == true)
      {
        this.setAndPropagateDepth(element.node, depth - 1, direction, mapUpdate);
      }
    });
    node.parentsLeft.forEach(element => {
      //// console.log('parentsLeft -- ' + element.node.name);
      if(mapUpdate[element.node.id] == true)
      {
        this.setAndPropagateDepth(element.node, depth - 1, direction, mapUpdate);
      }
    });
  }
 

  /*---------------------------------------------------------------------------------------------------------/
  // Propagate depth
  /---------------------------------------------------------------------------------------------------------*/
  getNodesAsOrderedArray(node, orderedNodes, mapIsAdded)
  {
    // early exit
    if(mapIsAdded[node.id])
    {
      return;
    }

    // check if the node is really needed    
    var hasNonZeroLeft = false;
    node.parentsLeft.forEach(element => {
      if(element.node.height != 0)
      {
        hasNonZeroLeft = true;
      }
    });
    node.childrenLeft.forEach(element => {
      if(element.node.height != 0)
      {
        hasNonZeroLeft = true;
      }
    });
    var hasNonZeroRight = false;
    node.parentsRight.forEach(element => {
      if(element.node.height != 0)
      {
        hasNonZeroRight = true;
      }
    });
    node.childrenRight.forEach(element => {
      if(element.node.height != 0)
      {
        hasNonZeroRight = true;
      }
    });
    node.isNeeded = hasNonZeroLeft && hasNonZeroRight;

    mapIsAdded[node.id] = true;

    // add all links 
    node.childrenLeft.forEach(element => {
      this.getNodesAsOrderedArray(element.node, orderedNodes, mapIsAdded)
    });

    node.parentsLeft.forEach(element => {
      this.getNodesAsOrderedArray(element.node, orderedNodes, mapIsAdded)
    });

    // add myself
    orderedNodes.push(node);    

    node.childrenRight.forEach(element => {
      this.getNodesAsOrderedArray(element.node, orderedNodes, mapIsAdded)
    });
    node.parentsRight.forEach(element => {
      this.getNodesAsOrderedArray(element.node, orderedNodes, mapIsAdded)
    });
  }
}

/*---------------------------------------------------------------------------------------------------------/
// Grid based graph node
/---------------------------------------------------------------------------------------------------------*/
export class GridBasedGraphNode 
{
  /*---------------------------------------------------------------------------------------------------------/
  // Constructor
  /---------------------------------------------------------------------------------------------------------*/
  constructor(name, text, width, height, color, fontSize, bold)
  {
    this.name = name;
    this.text = text;
    this.fontSize = fontSize;
    this.width = width;
    this.bold = bold;
    
    if(height < 0)
    {
      this.height = -height;
      this.isNegative = true;
    }
    else
    {
      this.height = height;
      this.isNegative = false;
    }
    this.linkHeightLeft = 0; // to smaller columns
    this.linkHeightRight = 0; // to larger columns
    this.color = color;
    this.links = new Array();
    this.linksRight = new Array();

    this.column = 0;

    this.isPositionSet = false;
    this.topLeftX = 0;
    this.topLeftY = 0;
  }

   
  /*---------------------------------------------------------------------------------------------------------/
  // Set position
  /---------------------------------------------------------------------------------------------------------*/
  setPosition(topLeftX, topLeftY)
  {
    //// console.log('setPosition ' + topLeftX + ' ' + topLeftY);
    this.topLeftX = topLeftX;
    this.topLeftY = topLeftY;
    this.isPositionSet = true;
  }
}

/*---------------------------------------------------------------------------------------------------------/
// Create a grid based graph based on a transform graph
/---------------------------------------------------------------------------------------------------------*/
export function CreateGridBasedGraph(name, targetHeight, width, spaceX, spaceY, graph, knownOrderedNodes = [], nodeLeft = null, nodeRight = null)
{
  // get the maximal depth of the graph
  graph.setDepthOfNodes();

  // check if we need to mirror the graph
  if(nodeLeft != null && nodeRight != null)
  {
    if(nodeLeft.depth > nodeRight.depth)
    {
      // flip
      graph.invertLeftRight(nodeLeft);
      graph.setDepthOfNodes();
    }
  }

  var mapIsAdded = new Map();

  // get min and max depth
  var min = 0;
  var max = 0;
  graph.nodes.forEach(element => {
    if(element.depth < min && element.depth != invalidDepth)
    {
      min = element.depth;
    }
    if(element.depth > max)
    {
      max = element.depth;
    }
    mapIsAdded[element.id] = false;
  });

  var offset = -min;

  var gridGraph = new GridBasedGraph(name, max - min + 1, spaceX, spaceY);

  var orderedNodes = [];
  graph.getNodesAsOrderedArray(graph.nodes[0], orderedNodes, mapIsAdded);

  // add all the known nodes first
  var mapIsKnownAdded = new Map();
  knownOrderedNodes.forEach(element => {
    var color;
    if(element.height != 0 || element.isNeeded)
    {
      if(element.height > 0)
      {
        color = element.colorPos;
      }
      else
      {
        color = element.colorNeg;
      }
      element.node = new GridBasedGraphNode(element.name, element.text, width, element.height, color, element.fontSize, element.bold);
      gridGraph.addNode(offset + element.depth, element.node);   
      element.display = true;
      element.node.gridNode = element;
    }   
    mapIsKnownAdded[element.id] = true;
  });

  // create nodes for every node of the graph
  orderedNodes.forEach(element => {
    var color;
    if(mapIsKnownAdded[element.id] == true)
    {
      // already added -> skip
    }
    else if((element.height != 0 || element.isNeeded) && element.depth != invalidDepth)
    {
      if(element.height > 0)
      {
        color = element.colorPos;
      }
      else
      {
        color = element.colorNeg;
      }
      element.node = new GridBasedGraphNode(element.name, element.text, width, element.height, color, element.fontSize, element.bold);
      gridGraph.addNode(offset + element.depth, element.node);   
      element.display = true;
      element.node.gridNode = element;
    }    
  });

  // compute the max height of all columns
  var maxHeight = 0;
  for(let column = 0; column < gridGraph.columns.length; column++)
  {
    var height = 0;
    for(let i = 0; i < gridGraph.columns[column].length; i++)
    {
      height += gridGraph.columns[column][i].height;
      height += spaceY;
    }
    if(height > maxHeight)
    {
      maxHeight = height;
    }
  }

  // scale the height of the nodes
  var scale = targetHeight / maxHeight;
  for(let column = 0; column < gridGraph.columns.length; column++)
  {
    for(let i = 0; i < gridGraph.columns[column].length; i++)
    {
      gridGraph.columns[column][i].height *= scale;
    }
  }

  // iterate over all columns of the graph
  for(let column = 0; column < gridGraph.columns.length-1; column++)
  {
    var maxRank = gridGraph.columns[column+1].length;

    for(let i = 0; i < gridGraph.columns[column].length; i++)
    {
      let element = gridGraph.columns[column][i].gridNode;

      for(let rank = 0; rank < maxRank; rank++)
      {
        // add all childs that have the matching rank
        element.childrenRight.forEach(child => {
          if(element.display && child.node.display && child.node.node.rank == rank)
          {
            gridGraph.addLink(element.node, child.node.node, false, child.invertedLinkDir < 0);
          }      
        });
        element.parentsRight.forEach(parent => {
          if(element.display && parent.node.display && parent.node.node.rank == rank)
          {
            gridGraph.addLink(element.node, parent.node.node, true, parent.invertedLinkDir < 0);
          }      
        });
      }
    }
  }



  return gridGraph;
}


/*---------------------------------------------------------------------------------------------------------/
// Grid based graph
/---------------------------------------------------------------------------------------------------------*/
export class GridBasedGraph {

  /*---------------------------------------------------------------------------------------------------------/
  // Constructor
  /---------------------------------------------------------------------------------------------------------*/
  constructor(name, numberOfColumns, spaceX, spaceY)
  {
    this.numberOfColumns = numberOfColumns;
    this.spaceX = spaceX;
    this.spaceY = spaceY;
    this.columns = new Array();
    for(let i = 0; i < numberOfColumns; i++)
    {
      this.columns.push(new Array());
    }

    this.boundingBoxSet = false;
    this.bbMinX = 0;
    this.bbMinY = 0;
    this.bbMaxX = 0;
    this.bbMaxY = 0;

    this.name = name;
    this.uniqueID = 0;
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Add a node to the grid
  /---------------------------------------------------------------------------------------------------------*/
  addNode(column, node)
  {
    node.column = column;
    node.rank = this.columns[column].length;
    this.columns[column].push(node);
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Add a new link in the graph
  /---------------------------------------------------------------------------------------------------------*/
  addLink(nodeFrom, nodeTo, backwards = false, isNegative = false)
  {
    let height;
    let absHeightFrom = nodeFrom.height;
    if(absHeightFrom < 0)
    {
      absHeightFrom = - nodeFrom.height;
    }
    let absHeightTo = nodeTo.height;
    if(absHeightTo < 0)
    {
      absHeightTo = - nodeTo.height;
    }

    if(absHeightFrom < absHeightTo)
    {
      height = absHeightFrom;
    }
    else
    {
      height = absHeightTo;
    }

    let nodeF = nodeFrom;
    let nodeT = nodeTo;
    if(nodeFrom.column > nodeTo.column)
    {
      nodeF = nodeTo;
      nodeT = nodeFrom;
    }

    // nodeFrom/right to nodeTo/left
    let entryFrom = {
      node: nodeT,
      height: height,
      linkHeightFrom: nodeF.linkHeightRight,
      linkHeightTo: nodeT.linkHeightLeft,
      backwards: backwards,
      isNegative: isNegative,
    }
    nodeF.linksRight.push(entryFrom);
    nodeF.links.push(entryFrom);

    let entryTo = {
      node: nodeF,
      height: height,
    }
    nodeT.links.push(entryTo);

    nodeF.linkHeightRight += height;
    nodeT.linkHeightLeft += height;
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Helper function that updates the bounding box of the graph
  /---------------------------------------------------------------------------------------------------------*/
  updateBoundingBox(x, y)
  {
    //// console.log('updateBoundingBox('+x+','+y+')');

    // update the bounding box
    if(this.boundingBoxSet)
    {
      if(x < this.bbMinX)
      {
        this.bbMinX = x;
      }
      if(y < this.bbMinY)
      {
        this.bbMinY = y;
      }
      if(x > this.bbMaxX)
      {
        this.bbMaxX = x;
      }
      if(y > this.bbMaxY)
      {
        this.bbMaxY = y;
      }
    }
    else
    {
      // just assign the first value
      this.bbMinX = x;
      this.bbMaxX = y;
      this.bbMinY = x;
      this.bbMaxY = y;
    }
    this.boundingBoxSet = true;
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Helper function to adjust the color
  /---------------------------------------------------------------------------------------------------------*/
  adjustColor(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Create a SVG text
  /---------------------------------------------------------------------------------------------------------*/
  createSVGText_helper(topLeftX, topLeftY, width, color, name, text, fontSize, strokeWhite = false, maxCharPerLine = 30)
  {
    // draw rect
    var svg = "";

    // draw text
    svg += '<g font-size="' + fontSize + '" font-family="Plus Jakarta Sans, sans-serif" fill="' + color;
    if(strokeWhite)
    {
      svg += '" text-anchor="middle" stroke="white" stroke-width="2">';
    }
    else 
    {
      svg += '" text-anchor="middle" stroke="none">';
    }

    var words = name.split(" ");
    var line = "";

    const lineHeight = 1.2 * fontSize;
    var y = topLeftY - 1.2 * lineHeight;

    // first iteration, check the number of lines
    var numberOfLines = 0;
    for (var i = 0; i < words.length; i++) {
      var testLine = line + words[i] + " ";
      if (testLine.length > maxCharPerLine) {
        numberOfLines += 1;
      }
      else {  
        line = testLine;
      }
    }

    // calculate the starting y position
    y -= (lineHeight * numberOfLines);

    // reset the line for the second iteration
    var line = "";
    for (var i = 0; i < words.length; i++) {
      var testLine = line + words[i] + " ";
      if (testLine.length > maxCharPerLine) {
        svg += '<text x="' + (topLeftX + width/2) + '" y="' + y + '">' + line + '</text>';
        line = words[i] + " ";
        y += lineHeight;
      }
      else {
        line = testLine;
      }
    }

    svg += '<text x="' + (topLeftX + width/2) + '" y="' + y + '">' + line + '</text>';  // Add the last (or only) line
    svg += '</g>';

    svg += '<g font-size="' + fontSize + '" font-family="Plus Jakarta Sans, sans-serif" fill="#303030" ' ;

    if(strokeWhite)
    {
      svg += 'text-anchor="middle" stroke="white" stroke-width="2">';
    }
    else 
    {
      svg += 'stroke="none" text-anchor="middle">'
    }

    svg += '<text x="' + (topLeftX + width/2) + '" y="' + (y+lineHeight) + '">' + text + '</text>';
    svg += '</g>';

    return svg;
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Create a SVG text with white border
  /---------------------------------------------------------------------------------------------------------*/
  createSVGText(topLeftX, topLeftY, width, color, name, text, fontSize)
  {
    // draw rect
    var svg;
    const maxCharPerLine = 32;
    svg += this.createSVGText_helper(topLeftX, topLeftY, width, color, name, text, fontSize, true, maxCharPerLine); 
    svg += this.createSVGText_helper(topLeftX, topLeftY, width, color, name, text, fontSize, false, maxCharPerLine); 
    return svg;
  }

  /*---------------------------------------------------------------------------------------------------------/
  // Create a SVG rectangle
  /---------------------------------------------------------------------------------------------------------*/
  createSVGRect(topLeftX, topLeftY, width, height, color, isNegative, bold)
  {
    // draw rect
    var svg = "";

    var borderSize = 1;
    var borderColor = color;

    if(bold)
    {
      borderSize = 2;
      borderColor = this.adjustColor(color, -80);
    }
    topLeftX += (borderSize / 2);
    topLeftY += (borderSize / 2);
    
    if(width >= borderSize)
    {
      width -= (borderSize);
    }
    if(height >= borderSize)
    {
      height -= (borderSize);
    }

    if(isNegative)
    {
      // diagonal hatch as background
      svg += '<pattern id="diagonalHatch' + this.name + this.uniqueID + '" patternUnits="userSpaceOnUse" width="8" height="8">';
      svg += '<path d="M-2,2 l4,-4 M0,8 l8,-8 M6,10 l4,-4" style="stroke:' + color + '; stroke-width:1" />';
      svg += '</pattern>';

      svg += '<rect ';
      svg += 'width="' + width + '" ';
      svg += 'height="' + height + '" ';
      svg += 'x="' + topLeftX + '" ';
      svg += 'y="' + topLeftY + '" style="fill:url(#diagonalHatch' + this.name + this.uniqueID + ');stroke:'+borderColor+';stroke-width:'+borderSize+';"/>';

      this.uniqueID += 1;
    }
    else
    {

      svg += '<rect style="fill:' + color + ';fill-opacity:1;stroke:'+borderColor+';stroke-width:'+borderSize+';" ';
      svg += 'width="' + width + '" ';
      svg += 'height="' + height + '" ';
      svg += 'x="' + topLeftX + '" ';
      svg += 'y="' + topLeftY + '" />';
    }

  
    // update bounding box
    this.updateBoundingBox(topLeftX, topLeftY);
    this.updateBoundingBox(topLeftX + width, topLeftY + height);


    // update the bounding box for the text (guess)

    let margin = width;
    this.updateBoundingBox(topLeftX - 2*margin, topLeftY - margin);
    this.updateBoundingBox(topLeftX + 2*margin, topLeftY + height + margin);

    this.updateBoundingBox(topLeftX - 2*margin, topLeftY - margin);
    this.updateBoundingBox(topLeftX + 4*margin, topLeftY + height + 2*margin);

    
    return svg;
  }

  
  /*---------------------------------------------------------------------------------------------------------/
  // Create a SVG rectangle
  /---------------------------------------------------------------------------------------------------------*/
  createSVGLink(topLeftX, topLeftY, topRightX, topRightY, bottomLeftX, bottomLeftY, bottomRightX, bottomRightY, colorLeft, colorRight)
  {
    var svg = '<defs><linearGradient id="' + this.name + this.uniqueID + '" x1="0%" y1="0%" x2="100%" y2="0%">';
    svg += '<stop offset="0%" style="stop-color:' + colorLeft + ';stop-opacity:0.5" />';
    svg += '<stop offset="100%" style="stop-color:' + colorRight + ';stop-opacity:0.5" />';
    svg += '</linearGradient></defs>';

    svg += '<path d="M ' + topLeftX + ',' + topLeftY + ' '; // move to start position
    svg += 'C ' + ((topLeftX + topRightX)/2) + ',' + topLeftY + ' '; // first control point
    svg += ((topLeftX + topRightX)/2) + ',' + topRightY + ' '; // second control point
    svg += topRightX + ',' + topRightY + ' '; // end point
    svg += 'L ' + bottomRightX + ',' + bottomRightY + ' '; // line to the bottom spline
    svg += 'C ' + ((bottomLeftX + bottomRightX)/2) + ',' + bottomRightY + ' '; // first control point
    svg += ((bottomLeftX + bottomRightX)/2) + ',' + bottomLeftY + ' '; // second control point
    svg += bottomLeftX + ',' + bottomLeftY + ' '; // end point
    svg += ' Z " fill="url(#' + this.name + this.uniqueID + ')" />'; // close the path;

    // update the uniqueID 
    this.uniqueID += 1;

    return svg;
  }


  /*---------------------------------------------------------------------------------------------------------/
  // Create a SVG rectangle
  /---------------------------------------------------------------------------------------------------------*/
  createSVGArrow(startCenterX, startCenterY, endCenterX, endCenterY, arrowSizeX, arrowSizeY, arrowWidth, colorStart, colorEnd, backwards, switchOpacity)
  {
    var startTopX;
    var startTopY;
    var endTopX;
    var endTopY;      
    var arrowTopX;
    var arrowTopY;
    var arrowEndX;
    var arrowEndY;
    var arrowBottomX; 
    var arrowBottomY;
    var startBottomX;
    var startBottomY;
    var endBottomX;
    var endBottomY;

    if(backwards)
    {
      startTopX = endCenterX;
      startTopY = endCenterY + arrowWidth/2;
      endTopX = startCenterX; 
      endTopY = startCenterY + arrowWidth/2;
      
      arrowTopX = startCenterX; 
      arrowTopY = startCenterY + arrowSizeY/2;

      arrowEndX = startCenterX - arrowSizeX;
      arrowEndY = startCenterY;

      arrowBottomX = startCenterX; 
      arrowBottomY = startCenterY - arrowSizeY/2;

      startBottomX = endCenterX;
      startBottomY = endCenterY - arrowWidth/2;
      endBottomX = startCenterX;
      endBottomY = startCenterY - arrowWidth/2;
    }
    else
    {
      startTopX = startCenterX;
      startTopY = startCenterY + arrowWidth/2;
      endTopX = endCenterX; 
      endTopY = endCenterY + arrowWidth/2;
      
      arrowTopX = endCenterX; 
      arrowTopY = endCenterY + arrowSizeY/2;

      arrowEndX = endCenterX + arrowSizeX;
      arrowEndY = endCenterY;

      arrowBottomX = endCenterX; 
      arrowBottomY = endCenterY - arrowSizeY/2;

      startBottomX = startCenterX;
      startBottomY = startCenterY - arrowWidth/2;
      endBottomX = endCenterX;
      endBottomY = endCenterY - arrowWidth/2;
    }


    var svg = '<defs><linearGradient id="' + this.name + this.uniqueID + '" x1="0%" y1="0%" x2="100%" y2="0%">';

    if(backwards)
    {
        svg += '<stop offset="0%" style="stop-color:' + colorEnd + ';stop-opacity:1.0" />';
        svg += '<stop offset="100%" style="stop-color:' + colorStart + ';stop-opacity:0.0" />';
    }
    else
    {
        svg += '<stop offset="0%" style="stop-color:' + colorStart + ';stop-opacity:0.0" />';
        svg += '<stop offset="100%" style="stop-color:' + colorEnd + ';stop-opacity:1.0" />';
    }
    svg += '</linearGradient></defs>';

    // first spline
    svg += '<path d="M ' + startTopX + ',' + startTopY + ' '; // move to start position
    svg += 'C ' + ((startTopX + endTopX)/2) + ',' + startTopY + ' '; // first control point
    svg += ((startTopX + endTopX)/2) + ',' + endTopY + ' '; // second control point
    svg += endTopX + ',' + endTopY + ' '; // end point

    // Arrow lines
    svg += 'L ' + arrowTopX + ',' + arrowTopY + ' '; // line up in the arrow triangle
    svg += 'L ' + arrowEndX + ',' + arrowEndY + ' '; // line to the arrow center
    svg += 'L ' + arrowBottomX + ',' + arrowBottomY + ' '; // line to the arrow bottom
    svg += 'L ' + endBottomX + ',' + endBottomY + ' '; // closing line of the arrow triangle

    // second spline
    svg += 'C ' + ((startBottomX + endBottomX)/2) + ',' + endBottomY + ' '; // first control point
    svg += ((startBottomX + endBottomX)/2) + ',' + startBottomY + ' '; // second control point
    svg += startBottomX + ',' + startBottomY + ' '; // end point
    svg += ' Z " fill="url(#' + this.name + this.uniqueID + ')" />'; // close the path;

    this.uniqueID += 1;

    return svg;
  }


  /*---------------------------------------------------------------------------------------------------------/
  // helper function to align the nodes in a column
  /---------------------------------------------------------------------------------------------------------*/
  alignNodes(column, posX)
  {
    //// console.log('this.alignNodes(' + column + ',' + posX + ')');

    // determine the combined height of all nodes
    var combinedHeight = 0;
    for(let i = 0; i < this.columns[column].length; i++)
    {
      if(i > 0)
      {
        combinedHeight += this.spaceY;
      }
      combinedHeight += this.columns[column][i].height;
    }

    // align the nodes around the center
    var posY = 0 - combinedHeight/2;
    for(let i = 0; i < this.columns[column].length; i++)
    {
      this.columns[column][i].setPosition(posX, posY);
      posY += this.spaceY + this.columns[column][i].height;
    }
  }

  /*---------------------------------------------------------------------------------------------------------/
  // create SVG graph
  /---------------------------------------------------------------------------------------------------------*/
  toSVG(svgId)
  {
    //// console.log('toSVG');

    // determine the column with the max height
    var columnMaxHeight = 0;
    var maxHeight = 0;
    for(let i = 0; i < this.columns.length; i++)
    {
      var height = 0;
      for(let j = 0; j < this.columns[i].length; j++)
      {
        if(j > 0)
        {
          height += this.spaceY;
        }
        height += this.columns[i][j].height;
      }

      if(height > maxHeight)
      {
        maxHeight = height;
        columnMaxHeight = i;
      }
    }

    // determine the positions of the nodes in the column with the max height
    var posX = 0;
    var posY = 0;
    for(let j = 0; j < this.columns[columnMaxHeight].length; j++)
    {
      this.columns[columnMaxHeight][j].setPosition(posX, posY);
      posY += this.columns[columnMaxHeight][j].height + this.spaceY;
    }

    // set the positions for all nodes
    var posX = 0;
    for(let i = 0; i < this.columns.length; i++)
    {
      this.alignNodes(i, posX);
      posX += this.spaceX;
    }

    // draw all the nodes
    let svgObj = {
      svg: ''
    }

    for(let i = 0; i < this.columns.length; i++)
    {
      for(let j = 0; j < this.columns[i].length; j++)
      {
        const e = this.columns[i][j];
        svgObj.svg += this.createSVGRect(e.topLeftX, e.topLeftY, e.width, e.height, e.color, e.isNegative, e.bold);
      }
    }
    // draw all the links
    for(let i = 0; i < this.columns.length-1; i++)
    {
      for(let j = 0; j < this.columns[i].length; j++)
      {
        // iterate over all links
        this.columns[i][j].linksRight.forEach(element => {
          
          let topLeftX = this.columns[i][j].topLeftX + this.columns[i][j].width;
          let topLeftY = this.columns[i][j].topLeftY + element.linkHeightFrom;
          let topRightX = element.node.topLeftX;
          let topRightY = element.node.topLeftY + element.linkHeightTo;
          let bottomLeftX = topLeftX;
          let bottomLeftY = topLeftY + element.height;
          let bottomRightX = topRightX;
          let bottomRightY = topRightY + element.height;
          let colorLeft = this.columns[i][j].color;
          let colorRight = element.node.color;

          if(element.isNegative)
          {
            if(element.backwards)
            {
              colorRight = Colors.White;
            }
            else
            {
              colorLeft = Colors.White;
            }
          }

          svgObj.svg += this.createSVGLink(topLeftX, topLeftY, topRightX, topRightY, bottomLeftX, bottomLeftY, bottomRightX, bottomRightY, colorLeft, colorRight);

          let arrowWidth = (bottomLeftY - topLeftY)/6;

          if(4*arrowWidth > element.node.width)
          {
            arrowWidth = element.node.width / 4;
          }
          
          let arrowSize = 2*arrowWidth;

          let colorStart = colorLeft;
          let colorArrow = Colors.White;
          if(element.backwards)
          {
            colorStart = colorRight;
          }
          if(element.isNegative)
          {
            colorArrow = Colors.RedOrange;
          }
          svgObj.svg += this.createSVGArrow(topLeftX, (topLeftY + bottomLeftY)/2, topRightX, (topRightY + bottomRightY)/2, arrowSize, arrowSize, arrowWidth, colorStart, colorArrow, element.backwards, false);

        });
      }
    }

    // as a last step draw all the text
    for(let i = 0; i < this.columns.length; i++)
    {
      for(let j = 0; j < this.columns[i].length; j++)
      {
        const e = this.columns[i][j];
       svgObj.svg += this.createSVGText(e.topLeftX, e.topLeftY, e.width, e.color, e.name, e.text, e.fontSize);
      }
    }
 
    //var svg = '<svg width="100%" height="100%" viewBox="' + this.bbMinX + ' ' + this.bbMinY + ' ' + (this.bbMaxX-this.bbMinX) + ' ' + (this.bbMaxY-this.bbMinY) + '">';
    var svg = '<svg width="100%" height="100%" id="' + svgId + '">';
    //var svg = '<svg width="100%" height="100%" id="' + svgId + '" viewBox="' + this.bbMinX + ' ' + this.bbMinY + ' ' + (this.bbMaxX-this.bbMinX) + ' ' + (this.bbMaxY-this.bbMinY) + '">';
    
    svg += svgObj.svg;
    svg += '</svg>';

    return svg;
  }
}



/* eslint-enable */



