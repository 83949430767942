
<script>
import { sessionManager } from '@/main';
import UserWatchlist from '@/components/user-watchlist.vue';
import { logUserEvent } from '../helper';

export default {
  name: 'UserAccount',
  components: {
    UserWatchlist,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Settings',
          headline: 'Settings',
          name: 'Name', 
          surname: 'Surname',
          email: 'Email',
          settings_language: 'Language',
          settings_unitOfAccount: 'Unit of Account',
          EUR: 'Euro',
          USD: 'US Dollar',
          BTC: 'Bitcoin',
          GC: 'Gold (oz)',
          JPY: 'Japanese Yen',
          GBP: 'British Pound',
          CHF: 'Swiss Franc',
          PercentOfM2USD: 'Percent of M2 (USD)',
          text: 'You can change your account details here. Currently, the only thing you can change is your unit of account. This only has an effect on the company overview pages. We will add more features soon.',
          headline_2: 'Watchlist',
          sendWatchlistUpdates: 'Send me updates about my watchlist. You only get an email if a company on your watchlist reported new financials that we added to the system.',
          finnhub: 'Finnhub',
          financialModellingPrep: 'Financial Modelling Prep',   
          select_data_provider: 'Select Data Provider',       
        },
        de: {
          title: 'Einstellungen',
          headline: 'Kontodetails',
          name: 'Name',
          surname: 'Nachname',
          email: 'Email',
          settings_language: 'Sprache',
          settings_unitOfAccount: 'Währung',     
          EUR: 'Euro',
          USD: 'US Dollar',
          BTC: 'Bitcoin',  
          GC: 'Gold (oz)',
          JPY: 'Japanischer Yen',
          GBP: 'Britisches Pfund',
          CHF: 'Schweizer Franken',
          PercentOfM2USD: 'Prozent von M2 (USD)',
          text: 'Hier kannst Du Deine Kontodetails ändern. Aktuell kannst Du nur Deine Währung ändern. Diese hat nur Auswirkungen auf die Unternehmensübersichtsseiten. Wir werden bald weitere Funktionen hinzufügen.',
          headline_2: 'Watchlist',
          sendWatchlistUpdates: 'Sende mir Updates zu meiner Watchlist. Du erhältst jeweils eine E-Mail, wenn ein Unternehmen auf deiner Watchlist neue Finanzberichte veröffentlich hat die wir im System aufgenommen haben.',
          finnhub: 'Finnhub',
          financialModellingPrep: 'Financial Modelling Prep',
          select_data_provider: 'Datenanbieter auswählen',
        },
      },
      language: '',
      name: '',
      surname: '',
      email: '',
      unitOfAccountOptions: ['USD', 'EUR', 'JPY', 'GBP', 'CHF', 'GC', 'BTC', 'PercentOfM2USD'], // ['USD', 'EUR', 'GC', 'BTC'], // , 
      selectedUnitOfAccount: null,
      sendWatchlistUpdates: false,
      sendWatchlistUpdatesActive: true,
      admin: false,
      radioDataProvider: 0,
    };
  },
  
  created() {
    this.language = sessionManager.read('language');
    this.name = sessionManager.read('name');
    this.surname = sessionManager.read('surname');
    this.email = sessionManager.read('email');
    this.selectedUnitOfAccount = sessionManager.read('unitOfAccount');
    this.sendWatchlistUpdates = sessionManager.read('sendWatchlistUpdates');
    logUserEvent('settings', sessionManager, this.$vuetify.breakpoint.mobile);

    this.admin = sessionManager.isAdmin();
    if(sessionManager.read('useFinnhub'))
    {
      this.radioDataProvider = 1;
    }
    else
    {
      this.radioDataProvider = 2;
    }
  },
  computed: {
    strings() {
      return this.languageStrings[this.language];
    },
    unitOfAccount() {
      return sessionManager.read('unitOfAccount');
    },
    selectedDataProvider() {
      if(this.radioDataProvider == 1)
      {
        return 'Finnhub';
      }
      else if(this.radioDataProvider == 2)
      {
        return 'Financial Modelling Prep';
      }
    },
  },
  methods: {
    async changeDataProvider() {
      var update = false;
      if(this.radioDataProvider == 1)
      {
        update = sessionManager.read('useFinnhub') == false;
        sessionManager.write('useFinnhub', true);
      }
      else if(this.radioDataProvider == 2)
      {
        update = sessionManager.read('useFinnhub') == true;
        sessionManager.write('useFinnhub', false);
      }

      if(update)
      {
        await this.updateAccountSettings();
      }
    },
    async updateAccountSettings()
    {
      try {
        const url = '/api/HttpTriggerUpdateUser';
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: JSON.stringify({
            id: sessionManager.read('id'),
            email: sessionManager.read('email'),
            name: sessionManager.read('name'),
            surname: sessionManager.read('surname'),
            language: this.language,
            unitOfAccount: sessionManager.read('unitOfAccount'),
            finnhub: sessionManager.read('useFinnhub'),
          }),
        });
      } catch (error) {
        console.log(error);
      }
    },
    async updateUnitOfAccount(selectedUnitOfAccount) {
        sessionManager.write('unitOfAccount', this.selectedUnitOfAccount);
        await this.updateAccountSettings();
    },
    async updateSendWatchlistUpdates() {
      logUserEvent('sendWatchlistUpdates', sessionManager, this.$vuetify.breakpoint.mobile, this.sendWatchlistUpdates);
      try {
        sessionManager.write('sendWatchlistUpdates', this.sendWatchlistUpdates);
        const url = '/api/HttpTriggerUpdateSendWatchlistUpdates';
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type' : 'application/json',
            'JWT' : sessionManager.read('token'),
          },
          body: JSON.stringify({
            sendWatchlistUpdates: this.sendWatchlistUpdates,
          }),
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<style scoped>

  .v-list-item__content {
    font-size: 14px;
    font-family: Plus Jakarta Sans;
  }
  .v-input .v-input__control .v-input__slot {
      font-size: 14x !important;
      font-family: Plus Jakarta Sans;
      border: 0px !important;
      border: none; 
      border-radius: 8px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

  .v-select 
  {
    font-size: 14px !important;
    font-family: Plus Jakarta Sans;
    border: 0px !important;
      border: none; 
      border-radius: 8px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 8px;
      padding-bottom: 8px;
  }

</style>

<template>
  <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-md flex justify-center' : 'px-v-xl flex justify-center'">
    <div class="max-w-4xl">
      <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
      <div class="text-v-sm darkest font-pj pb-v-xl">
        {{strings['text']}}
      </div>
      <v-simple-table>
        <tbody>
          <tr>
            <td class="text-v-sm darkest font-pj">{{strings['name']}}</td>
            <td class="text-v-sm darkest font-pj">{{name}}</td>
          </tr>
          <tr>
            <td class="text-v-sm darkest font-pj">{{strings['surname']}}</td>
            <td class="text-v-sm darkest font-pj">{{surname}}</td>
          </tr>
          <tr>
            <td class="text-v-sm darkest font-pj">{{strings['email']}}</td>
            <td class="text-v-sm darkest font-pj">{{email}}</td>
          </tr>
          <tr v-if="true">
            <td class="text-v-sm darkest font-pj">{{strings['settings_unitOfAccount']}}</td>
            <td class="text-v-sm darkest font-pj">
                <v-combobox
                  v-model="selectedUnitOfAccount"
                  :items="unitOfAccountOptions"
                  @change="updateUnitOfAccount(selectedUnitOfAccount)"
                >
                  <template v-slot:selection="data">
                    {{ strings[data.item] }}
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="strings[data.item]" />
                    </template>
                  </template>
                </v-combobox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>  
      <div v-if="sendWatchlistUpdatesActive">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline_2']}}</h3>
        <div>
          <v-checkbox v-model="sendWatchlistUpdates" :label="strings['sendWatchlistUpdates']" @change="updateSendWatchlistUpdates"></v-checkbox>
        </div>
      </div>
      <div v-if="admin">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['select_data_provider']}}</h3>
        <div class="text-v-sm darkest font-pj">{{ selectedDataProvider }}</div>
        <div>
          <v-radio-group v-model="radioDataProvider" @change="changeDataProvider">
            <v-radio key="1" label="Finnhub" value="1"></v-radio>
            <v-radio key="2" label="Financial Modelling Prep" value="2"></v-radio>
          </v-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>


