<script>
/* eslint-disable */

import {
  formatPercent,
  formatUnitOfAccount,
  formatUnitOfAccountChart,
  toUnitOfAccount,
  getUnitOfAccountUnit,
  getInterpolatedColor,
  getColor10Ratio,
  getColorValuationRatio,
  getColorDividendYield,
  getColorDividendYieldSupportedByFreeCashflow,
  logUserEvent,
} from '../helper';

import CompanyValuationCombinedValuationInfo from '@/components/company-valuation-combined-valuation-info.vue';
import CompanyValuationTenCapEarningsInfo from '@/components/company-valuation-ten-cap-earnings-info.vue';
import CompanyValuationTenCapFreeCashflowInfo from '@/components/company-valuation-ten-cap-free-cashflow-info.vue';
import CompanyValuationTenCapOwnerEarningsInfo from '@/components/company-valuation-ten-cap-owner-earnings-info.vue';
import CompanyValuationMarginOfSafetyInfo from '@/components/company-valuation-margin-of-safety-info.vue';
import CompanyValuationPaybackTimeFreeCashflowInfo from '@/components/company-valuation-payback-time-free-cashflow-info.vue';
import CompanyValuationPaybackTimeOwnerEarningsInfo from '@/components/company-valuation-payback-time-owner-earnings-info.vue';
import CompanyValuationPeterLynchPEGRatioInfo from '@/components/company-valuation-peter-lynch-peg-ratio-info.vue';
import CompanyValuationGrahamNumberInfo from '@/components/company-valuation-graham-number-info.vue';
import CompanyValuationNetCurrentAssetValueInfo from '@/components/company-valuation-net-current-asset-value-info.vue';

import * as settings from '../settings';

import { Colors, adjustColor, interpolateColors } from '../colors';
import { sessionManager } from '@/main';

import {
  getSectorAndIndustryTree,
  getSectorAndIndustryTreeFH,
  getCountryTree,
  setLanguageOfTree,
} from '../screener';

export default {
  name: 'CompanyScreener',
  props: {
    unitOfAccount: {
      type: String,
      required: true,
      default: () => 'USD',
    },
  },
  components: {
    CompanyValuationCombinedValuationInfo,
    CompanyValuationTenCapEarningsInfo,
    CompanyValuationTenCapFreeCashflowInfo,
    CompanyValuationTenCapOwnerEarningsInfo,
    CompanyValuationMarginOfSafetyInfo,
    CompanyValuationPaybackTimeFreeCashflowInfo,
    CompanyValuationPaybackTimeOwnerEarningsInfo,
    CompanyValuationPeterLynchPEGRatioInfo,
    CompanyValuationGrahamNumberInfo,
    CompanyValuationNetCurrentAssetValueInfo,
  },
  data() {
    return {
      language: '',
      languageStrings: {
        en: {
          title: 'Screen for Companies',
          text_title: 'Use our value investing stock screener to search companies based on fundamentals and find great business on sale!',
          text_simple_steps: ' Follow these simple steps:',
          location_title: 'Company Location',
          location_text: 'Narrow your search for specific countries or skip this step to search for companies from all around the world.',
          text_continue: 'continue',
          text_all_countries: 'Entire World',
          text_specify_countries: 'Specify Countries',
          sector_title: 'Sector & Industry',
          sector_text: 'Narrow your search to your circle of competence or skip this step to search for companies of every industry.',
          sector_all_sectors: 'All Sectors',
          sector_specify_sectors: 'Specify Sectors',
          marketcap_title: 'Market Capitalization',
          marketcap_text: 'Choose the market capitalization of the company or skip this step to search for companies of all sizes.',
          marketcap_label: 'specify market cap range',
          growth_title: 'Growth',
          growth_text: 'Consistent growth across book value, earnings, cashflow and revenue is a solid indicator for a great company moat.',
          growth_all: 'Ø growth last 5 years of Book value & dividends, earnings, cashflow from operation and revenue',
          growth_book: 'Ø Book value & dividends growth last 5 years',
          growth_earnings: 'Ø Earnings growth last 5 years',
          growth_cashflow: 'Ø Cashflow from operation growth last 5 years',
          growth_revenue: 'Ø Revenue growth last 5 years',
          growth_specify_individually: 'Specify growth individually',
          roc_title: 'Return on Capital',
          roc_text: 'A high return on equity and return on invested capital is a good indicator for a great company.',
          roc_label: 'Minimal ROE last 5 years',
          debt_title: 'Debt',
          debt_text: 'Debt can improve the return on invested capital but is also dangerous.',
          debt_label_no_debt: 'No net debt',
          debt_label_years: 'Number of years to pay down the debt with Operating Cashflow',
          valuation_title: 'Company Valuation',
          valuation_text: 'Valuations matter, select your favorite metric or skip to search for all companies.',
          valuation_specify_valuations: 'Detailed Valuation Criterias',
          pay: 'Pay ~',
          for_1: ' for 1',
          price_value: 'Price/Value',
          combined_valuation: 'Combined Valuation',
          combined_valuation_label: 'Combined Valuation',
          simple_metrics: 'Simple Metrics',
          pe_full: 'Price to Earnings (P/E)',
          pe: 'P/E',
          price_fcf_full: 'Price to Free Cashflow (P/FC)',
          pfc: 'P/FC',
          price_oe_full: 'Price to Owner Earnings (P/OE)',
          price_oe: 'P/OE',
          dividend_yield: 'Dividend Yield',
          dividend_supported: 'supported by Free Cashflow',
          valuations_no_growth: 'Valuations with no growth assumptions',
          ten_cap_earnings: 'Ten-Cap of Earnings',
          ten_cap_fcf: 'Ten-Cap of Free Cashflow',
          ten_cap_oe: 'Ten-Cap of Owner Earnings',
          valuations_with_growth: 'Valuations with growth assumptions',
          marin_of_safety: 'Margin of Safety Valuation',
          payback_fcf: 'Payback Time Free Cashflow',
          payback_oe: 'Payback Time Owner Earnings',
          peg_ratio: 'Price to Earnings Growth (PEG) Ratio',
          peg_ratio_short: 'PEG Ratio',
          book_value: 'Valuations with Book Value',
          graham_number: 'Graham Number Valuation',
          liquidation: 'Valuations with Liquidation Value',
          net_current_asset: 'Net Current Asset Value Valuation',
          found_companies: 'companies',
          companies: '# Companies',
          help: 'More information'
        },
        de: {
          title: 'Suche nach Unternehmen',
          text_title: 'Finde Unternehmen weltweit auf der Basis von Fundamentaldaten die zum Verkauf stehen!',
          text_simple_steps: ' Folge diesen einfachen Schritten:',
          location_title: 'Infos: Standort des Unternehmens',
          location_text: 'Grenze Deine Suche auf bestimmte Länder ein oder überspringe diesen Schritt, um nach Unternehmen auf der ganzen Welt zu suchen.',
          text_continue: 'weiter',
          text_all_countries: 'Ganze Welt',
          text_specify_countries: 'Länder angeben',
          sector_title: 'Infos: Branche & Industie',
          sector_text: 'Grenze Deine Ihre Suche auf deinen Kompetenzbereich ein oder überspringe diesen Schritt, um nach Unternehmen aus allen Branchen suchen.',
          sector_all_sectors: 'Alle Branchen',
          sector_specify_sectors: 'Branchen angeben',
          marketcap_title: 'Infos: Marktkapitalisierung',
          marketcap_text: 'Wähle die Marktkapitalisierung des Unternehmens oder überspringe diesen Schritt, um nach Unternehmen jeder Grösse zu suchen.',
          marketcap_label: 'Marktkapitalisierung angeben',
          growth_title: 'Qualität: Wachstum',
          growth_text: 'Ein beständiges Wachstum von Buchwert, Gewinn, Cashflow und Umsatz ist ein solider Indikator für einen großen Unternehmensgraben.',
          growth_all: 'Ø Wachstum der letzten 5 Jahre von Buchwert & Dividenden, Gewinn, Cashflow aus dem operativen Geschäft und Umsatz',
          growth_book: 'Ø Buchwert- und Dividendenwachstum der letzten 5 Jahre',
          growth_earnings: 'Ø Gewinnwachstum der letzten 5 Jahre',
          growth_cashflow: 'Ø Wachstum des Cashflow aus dem operativen Geschäft der letzten 5 Jahre',
          growth_revenue: 'Ø Umsatzwachstum der letzten 5 Jahre',
          growth_specify_individually: 'Wachstum individuell angeben',
          roc_title: 'Qualität: Kapitalrendite',
          roc_text: 'Eine hohe Eigenkapitalrendite und Rendite des investierten Kapitals sind ein guter Indikator für ein großartiges Unternehmen.',
          roc_label: 'Minimale Eigenkapitalrendite in den letzten 5 Jahren',
          debt_title: 'Qualität: Verschuldung',
          debt_text: 'Schulden können die Rendite des investierten Kapitals verbessern, sind aber auch gefährlich.',
          debt_label_no_debt: 'Keine Nettoverschuldung',
          debt_label_years: 'Anzahl der Jahre für die Rückzahlung der Schulden mit dem operativen Cashflow',
          valuation_title: 'Bewertung',
          valuation_text: 'Bewertungen sind wichtig. Wähle die bevorzugte Unternehmensbewertung oder überspringe diesen Schritt, um nach Unternehmen mit jeglicher Bewertung zu suchen.',
          valuation_specify_valuations: 'Mehr Details',
          pay: 'Zahle ~',
          for_1: ' für 1',
          price_value: 'Preis/Wert',
          combined_valuation: 'Kombinierte Bewertung',
          combined_valuation_label: 'Kombinierte Bewertung',
          simple_metrics: 'Einfache Messgrößen',
          pe_full: 'Kurs-Gewinn-Verhältnis (KGV)',
          pe: 'KGV',
          price_fcf_full: 'Preis zu freiem Cashflow (P/FC)',
          pfc: 'P/FC',
          price_oe_full: 'Preis zu Owner Earnings (P/OE)',
          price_oe: 'P/OE',
          dividend_yield: 'Dividendenrendite',
          dividend_supported: 'gestützt durch freien Cashflow',
          valuations_no_growth: 'Bewertungen ohne Wachstumsannahmen',
          ten_cap_earnings: 'Ten-Cap Gewinn',
          ten_cap_fcf: 'Ten-Cap freier Cashflow',
          ten_cap_oe: 'Ten-Cap Owner Earnings',
          valuations_with_growth: 'Bewertungen mit Wachstumsannahmen',
          marin_of_safety: 'Sicherheitsmargen-Bewertung',
          payback_fcf: 'Payback-Time Freier Cashflow',
          payback_oe: 'Payback-Time Owner Earnings',
          peg_ratio: 'Kurs-Gewinn-Wachstums-Verhältnis',
          peg_ratio_short: 'KGV-Wachstums-Verhältnis',
          book_value: 'Bewertungen mit Buchwert',
          graham_number: 'Graham Nummer Bewertung',
          liquidation: 'Bewertungen mit Liquidationswert',
          net_current_asset: 'Aktueller Nettovermögenswert',
          found_companies: 'Unternehmen',
          companies: '# Unternehmen',
          help: 'Mehr Informationen'
        },
      },
      specifyCountries: false,
      specifyIndustries: false,
      specifyGrowthIndividually: false,
      speficyValuations: false,
      industryItems: [],
      industrySelection: [],
      countryItems: [],
      countrySelection: [],
      languageHeaders: {
        en: [
          {
            text: 'Symbol',
            value: 'symbol',
            width: '20px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Name',
            value: 'companyName',
            width: '180px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Valuation',
            value: 'vr',
            width: '40px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Country',
            value: 'country',
            width: '10px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Industry',
            value: 'industry',
            width: '120px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Market Cap',
            value: 'price',
            width: '160px',
            align: 'right',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Price/Earnings',
            value: 'pe',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Price/Free Cash Flow',
            value: 'pfc',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Price/Owner Earnings',
            value: 'poe',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'ROE last 5 years',
            value: 'roe5',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Dividend Yield',
            value: 'dividendYield',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Dividends as % of Free Cashflow',
            value: 'dividendsOfFCF',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Book value + dividends growth last 5 years',
            value: 'bndG5',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Net Debt in years of Operating Cashflow',
            value: 'netDebtInYearsOfOCF',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Ten Cap of Earnings Valuation Ratio',
            value: 'tenCapEVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Ten Cap of Free Cashflow Valuation Ratio',
            value: 'tenCapFCFVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Ten Cap of Owner Earnings Valuation Ratio',
            value: 'tenCapOEVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Discounted Earnings Valuation Ratio',
            value: 'mosVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Payback Time of Free Cashflow Valuation Ratio',
            value: 'pbtVRFCF',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Payback Time of Owner Earnings Valuation Ratio',
            value: 'pbtVROE',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Price/Earnings Growth Ratio',
            value: 'pegRatio',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Graham Number Valuation Ratio',
            value: 'grahamVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Net Current Asset Value Valuation Ratio',
            value: 'ncavVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
        ],
        de: [
          {
            text: 'Symbol',
            value: 'symbol',
            width: '20px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Name',
            value: 'companyName',
            width: '180px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Bewertung',
            value: 'vr',
            width: '40px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Land',
            value: 'country',
            width: '10px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Industrie',
            value: 'industry',
            width: '120px',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Marktkapitalisierung',
            value: 'price',
            width: '160px',
            align: 'right',
            sortable: true,
            showOnMobile: true,
          },
          {
            text: 'Kurs-Gewinn-Verhältnis (KGV)',
            value: 'pe',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis zu freiem Cashflow (P/FC)',
            value: 'pfc',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis zu Owner Earnings (P/OE)',
            value: 'poe',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Eigenkapitalrendite der letzten 5 Jahre',
            value: 'roe5',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Dividendenrendite',
            value: 'dividendYield',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Dividenden als % des freien Cashflow',
            value: 'dividendsOfFCF',
            width: '40px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Ø Buchwert- und Dividendenwachstum der letzten 5 Jahre',
            value: 'bndG5',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Anzahl der Jahre für die Rückzahlung der Schulden mit dem operativen Cashflow',
            value: 'netDebtInYearsOfOCF',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / 10x Gewinn',
            value: 'tenCapEVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / 10x Freier Cashflow',
            value: 'tenCapFCFVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / 10x Owner Earnings',
            value: 'tenCapOEVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Diskontierter Gewinn',
            value: 'mosVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Payback-Time Freier Cashflow',
            value: 'pbtVRFCF',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Payback-Time Owner Earnings',
            value: 'pbtVROE',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Kurs-Gewinn-Wachstums-Verhältnis (PEG-Ratio)',
            value: 'pegRatio',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Graham Nummer',
            value: 'grahamVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },
          {
            text: 'Preis / Liquidationswert',
            value: 'ncavVR',
            width: '60px',
            sortable: true,
            showOnMobile: false,
          },          
        ]
      },
      options_new: {},
      step: 1,
      stepInfo: 1,
      loading: true,
      totalCompanies: 0,
      items: [],
      companies: null,
      search_pe_active: false,
      search_pe: 10,
      search_pfc_active: false,
      search_pfc: 10,
      search_poe_active: false,
      search_poe: 10,
      search_ROE_active: false,
      search_ROE_slider: 3, 
      search_growth_slider_active: false,
      search_growth_slider: 3,
      search_BookValueGrowth_active: false,
      search_BookValueGrowth_slider: 3,
      search_EarningsGrowth_active: false,
      search_EarningsGrowth_slider: 3,
      search_CashflowFromOperationGrowth_active: false,
      search_CashflowFromOperationGrowth_slider: 3,
      search_RevenueGrowth_active: false,
      search_RevenueGrowth_slider: 3,
      search_netDebtInYearsOfOperatingCashFlow_active: false,
      search_netDebtInYearsOfOperatingCashFlow: 3,
      search_netDebtInYearsOfOperatingCashFlow_slider: 3,
      search_noNetDebt_active: false,
      search_tenCapEarnings_active: false,
      search_tenCapEarnings_slider: 2,
      search_tenCapFreeCashflow_active: false,
      search_tenCapFreeCashflow_slider: 2,
      search_tenCapOwnerEarnings_active: false,
      search_tenCapOwnerEarnings_slider: 2,
      search_combinedValuation_active: false,
      search_combinedValuation_slider: 2,
      search_valuationLabels_en: [
          'extremely cheap',   
          'very cheap',
          'very cheap',
          'cheap',
          'on sale',
          'expensive',
          'very expensive',
          'extremely expensive',
        ],
      search_valuationLabels_de: [
          'extrem günstig',   
          'sehr günstig',
          'sehr günstig',
          'günstig',
          'preiswert',
          'teuer',
          'sehr teuer',
          'extrem teuer',
        ],
      search_valuationLabels: null,
      search_valuationLabelValues: [
          10,
          25,
          50,
          75,
          100,
          200,
          500,
          1000,
        ],
      search_peg_ratioValues: [
          0.1,
          0.25,
          0.5,
          0.75,
          1,
          2,
          5,
          10,
      ],
      search_growth: [
        0,
        3,
        5,
        7,
        10,
        15,
        20,
        30
      ],
      search_dividendYield_active: false,
      search_dividendYield_slider: 3,
      search_dividendYieldSupportedFCF_active: false,
      search_MOS_active: false,
      search_MOS_slider: 2,
      search_PBTFreeCashflow_active: false,
      search_PBTFreeCashflow_slider: 2,
      search_PBTOwnerEarnings_active: false,
      search_PBTOwnerEarnings_slider: 2,
      search_PEG_Ratio_active: false,
      search_PEG_Ratio_slider: 2,
      search_netCurrentAssetValueValuation_active: false,
      search_netCurrentAssetValueValuation_slider: 2,
      search_grahamNumberValuation_active: false,
      search_grahamNumberValuation_slider: 2,
      search_industries: [],
      search_sectors: [],
      search_marketCapMin: 0,
      search_marketCapMax: 8,
      search_marketCapRange: [0, 8],
      search_MarketCap_active: false,
      search_marketCap: 
      [
        0, // 0,
        1000000, // 1 000 000,
        10000000, // 10 000 000,
        100000000, // 100 000 000,
        1000000000, // 1 000 000 000,
        10000000000, // 10 000 000 000,
        100000000000, // 100 000 000 000,
        1000000000000, // 1 000 000 000 000,
        10000000000000, // 10 000 000 000 000,
      ],
      search_marketCap_BTC: 
      [
        0, 
        1, 
        10, 
        100, 
        1000, 
        10000, 
        100000, 
        1000000, 
        10000000, 
        100000000,
        1000000000,
      ],
      search_marketCap_M2: 
      [
        0.000000001,
        0.0000001,
        0.000001,
        0.0001, 
        0.001,
        0.01,
        0.1, 
        1, 
        10, 
      ],
      search_countryOpen: [1, 2],
      tab: null,
      items: [
        { tab: 'Company Financials', content: '' },
        { tab: 'Company Location', content: '' },
      ],
      unitOfAccountUnit: '',     
      showCompanyValuationCombinedValuationInfo: false, 
      showCompanyValuationTenCapEarningsInfo: false,
      showCompanyValuationTenCapFreeCashflowInfo: false,
      showCompanyValuationTenCapOwnerEarningsInfo: false,
      showCompanyValuationMarginOfSafetyInfo: false,
      showCompanyValuationPaybackTimeFreeCashflowInfo: false,
      showCompanyValuationPaybackTimeOwnerEarningsInfo: false,
      showCompanyValuationPEGRatioInfo: false,
      showCompanyValuationGrahamNumberInfo: false,
      showCompanyValuationNetCurrentAssetValueInfo: false,
    };
  },
  watch: {
    options_new: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted() {
    // check if user is logged in, if not redirect to login page
    if(settings.requiresLogin()) {
      if(!sessionManager.isLoggedin())
      {
        this.$router.push('/login');
      }
    }

    // initialization of UI content

    if(sessionManager.read('useFinnhub'))
    {
      this.industryItems = getSectorAndIndustryTreeFH();
    }
    else
    {
      this.industryItems = getSectorAndIndustryTree();
    }

    if(this.unitOfAccount === 'BTC')
    {
      this.search_marketCap = this.search_marketCap_BTC;
      this.search_marketCapMax = this.search_marketCap_BTC.length - 1;
    }
    else if(this.unitOfAccount === 'PercentOfM2USD')
    {
      this.search_marketCap = this.search_marketCap_M2;
      this.search_marketCapMax = this.search_marketCap_M2.length - 1;
    }

    setLanguageOfTree(this.industryItems, this.language);
    this.countryItems = getCountryTree();
    setLanguageOfTree(this.countryItems, this.language);
  },
  methods: {
    doSearch() {
      this.getDataFromApi();
    },
    replaceDotWithUnderscore(str) {
      return str.replace(/\./g, "_");
    },
    gotoPremium(item)
    {
      const url = this.$router.resolve({ path: '/premium' }).href;
      window.open(url, '_blank');  
    },
    async getDataFromApi () {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options_new;

      let search_page = 1;
      let search_itemsPerPage = 20;
      let search_sortBy = '';
      let search_sortDesc = false;

      if (typeof page !== 'undefined')
      {
        search_page = page;
      }

      if (typeof itemsPerPage !== 'undefined')
      {
        search_itemsPerPage = itemsPerPage;
      }

      if (typeof sortBy !== 'undefined' && sortBy.length === 1)
      {
        search_sortBy = sortBy[0];
      }

      if (typeof sortDesc !== 'undefined' && sortDesc.length === 1)
      {
        search_sortDesc = sortDesc[0];
      }  

      let url = '/api/HttpTriggerCompanyScreener?'; 
      if(this.search_ROE_active) {
        url += '&search_ROE=' + this.search_growth[this.search_ROE_slider];
      }
      if(this.search_pe_active) {
        url += '&search_pe=' + this.search_pe;
      }
      if(this.search_pfc_active) {
        url += '&search_pfc=' + this.search_pfc;
      }
      if(this.search_poe_active) {
        url += '&search_poe=' + this.search_poe;
      }
      if(this.search_BookValueGrowth_active) {
        url += '&search_BookValueGrowth=' + this.search_growth[this.search_BookValueGrowth_slider];
      }
      if(this.search_EarningsGrowth_active) {
        url += '&search_EarningsGrowth=' + this.search_growth[this.search_EarningsGrowth_slider];
      }
      if(this.search_CashflowFromOperationGrowth_active) {
        url += '&search_CashflowFromOperationGrowth=' + this.search_growth[this.search_CashflowFromOperationGrowth_slider];
      }
      if(this.search_RevenueGrowth_active) {
        url += '&search_RevenueGrowth=' + this.search_growth[this.search_RevenueGrowth_slider];
      }
      if(this.search_growth_slider_active) {
        if(!this.search_BookValueGrowth_active)
        {
          url += '&search_BookValueGrowth=' + this.search_growth[this.search_growth_slider];
        }
        if(!this.search_EarningsGrowth_active)
        {
          url += '&search_EarningsGrowth=' + this.search_growth[this.search_growth_slider];
        }
        if(!this.search_CashflowFromOperationGrowth_active)
        {
          url += '&search_CashflowFromOperationGrowth=' + this.search_growth[this.search_growth_slider];
        }
        if(!this.search_RevenueGrowth_active)
        {
          url += '&search_RevenueGrowth=' + this.search_growth[this.search_growth_slider];
        }
      }
      if(this.search_netDebtInYearsOfOperatingCashFlow_active) {
        url += '&search_netDebtInYearsOfOperatingCashFlow=' + this.search_netDebtInYearsOfOperatingCashFlow;
      }
      if(this.search_noNetDebt_active) 
      {
        url += '&search_noNetDebt=true';
      }
      if(this.search_tenCapEarnings_active) {
        url += '&search_TenCapEarnings=' + this.search_valuationLabelValues[this.search_tenCapEarnings_slider];
      }
      if(this.search_tenCapFreeCashflow_active) {
        url += '&search_TenCapFreeCashflow=' +  this.search_valuationLabelValues[this.search_tenCapFreeCashflow_slider];
      }
      if(this.search_tenCapOwnerEarnings_active) {
        url += '&search_TenCapOwnerEarnings=' + this.search_valuationLabelValues[this.search_tenCapOwnerEarnings_slider];
      }
      if(this.search_MOS_active) {
        url += '&search_MOS=' + this.search_valuationLabelValues[this.search_MOS_slider];
      }
      if(this.search_PBTFreeCashflow_active) {
        url += '&search_PBTFreeCashflow=' + this.search_valuationLabelValues[this.search_PBTFreeCashflow_slider];
      }
      if(this.search_PBTOwnerEarnings_active) {
        url += '&search_PBTOwnerEarnings=' +  this.search_valuationLabelValues[this.search_PBTOwnerEarnings_slider];
      }
      if( this.search_netCurrentAssetValueValuation_active) {
        url += '&search_netCurrentAssetValueValuation=' + this.search_valuationLabelValues[this.search_netCurrentAssetValueValuation_slider];
      }
      if(this.search_PEG_Ratio_active) {
        url += '&search_PEG_Ratio=' + this.search_peg_ratioValues[this.search_PEG_Ratio_slider];
      }
      if(this.search_grahamNumberValuation_active)
      {
        url += '&search_GrahamNumber=' + this.search_valuationLabelValues[this.search_grahamNumberValuation_slider];
      }
      if(this.search_dividendYield_active)
      {
        url += '&search_dividendYield_active='+ this.search_growth[this.search_dividendYield_slider];
        if(this.search_dividendYieldSupportedFCF_active)
        {
          url += '&search_dividendYieldSupportedFCF_active=' + this.search_dividendYieldSupportedFCF_active;
        }
      }
      if(this.search_combinedValuation_active) {
        url += '&search_combinedValuation=' + this.search_valuationLabelValues[this.search_combinedValuation_slider];
      }

      if(this.search_MarketCap_active)
      {
        url += '&search_marketCapMin=' + this.search_marketCap[this.search_marketCapRange[0]];
        url += '&search_marketCapMax=' + this.search_marketCap[this.search_marketCapRange[1]];
      }

      if(this.countrySelection.length > 0)
      {
        url += '&search_country=';
        this.countrySelection.forEach(element => {
          if(element.api === ';')
          {
            // not supported
          }
          else
          {
            url += element.api + '-'
          }      
        });
      }

      let offset = (search_page - 1) * search_itemsPerPage;
      url += '&offset=' + offset;
      url += '&limit=' + search_itemsPerPage;

      if(search_sortBy !== '')
      {
        url += '&sortBy=' + search_sortBy;
        url += '&sortDesc=' + search_sortDesc;
      }

      if(this.industrySelection.length > 0)
      {
        url += '&search_industry=';
        this.industrySelection.forEach(element => {
          // sanitize the & in the element.api name
          let sanitized = element.api.replace('&', ';');
          url += sanitized + ','
        });
      }

      const response = await fetch(
        url,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type' : 'application/json',
            'JWT' : sessionManager.read('token'),
          },
        });

      // check if response is ok
      if(response.ok) 
      {
        const data = await response.json();

        let unitOfAccount = this.unitOfAccount;

        this.companies = data.companies;
        this.totalCompanies = data.count;
        let arr = [];
        const maxRatio = 999;
        let invalid = '-';

        this.companies.forEach(function(c){
          arr.push({
            symbol: c.id,
            companyName: c.companyName,
            currency: c.currency,
            price: c.price != null ? formatUnitOfAccount(c.price, unitOfAccount, true) : 'XXXXXXXXXX',
            country: c.country,
            industry: c.industry,
            roe5: formatPercent(c.roe5, 0),
            bndG5: formatPercent(c.bndG5, 0),
            netDebtInYearsOfOCF: c.netDebt < 0 ? 'no debt' : (c.netDebtInYearsOfOCF < 0) ? '∞' : (c.netDebtInYearsOfOCF).toFixed(1),
            pe: (c.pe > maxRatio || c.pe <= 0.0) ? invalid : (c.pe).toFixed(1),
            pfc: (c.pfc > maxRatio || c.pfc <= 0.0) ? invalid : (c.pfc).toFixed(1),
            poe: (c.poe > maxRatio || c.poe <= 0.0) ? invalid : (c.poe).toFixed(1),
            tenCapEVR: (typeof c.tenCapEVR == "undefined" || c.tenCapEVR > maxRatio || c.tenCapEVR <= 0.0 )? '-' : c.tenCapEVR.toFixed(1),
            tenCapFCFVR: (typeof c.tenCapFCFVR == "undefined" || c.tenCapFCFVR > maxRatio || c.tenCapFCFVR <= 0.0 )? '-' : c.tenCapFCFVR.toFixed(1),
            tenCapOEVR:  (typeof c.tenCapOEVR == "undefined" || c.tenCapOEVR > maxRatio || c.tenCapOEVR <= 0.0 )? '-' : c.tenCapOEVR.toFixed(1),
            mosVR: (c.mosVR > maxRatio || c.mosVR <= 0.0)  ? '-' : c.mosVR.toFixed(1),
            pbtVRFCF: (typeof c.pbtVRFCF == "undefined" || c.pbtVRFCF > maxRatio || c.pbtVRFCF <= 0.0) ? '-' : c.pbtVRFCF.toFixed(1),
            pbtVROE: (typeof c.pbtVROE == "undefined" || c.pbtVROE > maxRatio || c.pbtVROE <= 0.0) ? '-' : c.pbtVROE.toFixed(1),
            pegRatio: (typeof c.pegRatio == "undefined" || c.pegRatio > maxRatio || c.pegRatio <= 0.0) ? '-' : c.pegRatio.toFixed(1),
            grahamVR: (typeof c.grahamNumber == "undefined" || c.grahamNumber > maxRatio || c.grahamNumber <= 0.0) ? '-' : c.grahamNumber.toFixed(1),
            ncavVR: (c.ncavVR > maxRatio || c.ncavVR <= 0.0) ? '-' : c.ncavVR.toFixed(1),
            dividendYield: (typeof c.dividendYield == "undefined") ? '-' : formatPercent(c.dividendYield, 1),
            dividendsOfFCF: (typeof c.dividendsOfFCF == "undefined") ? '-' : formatPercent(c.dividendsOfFCF,0),
            vr: (typeof c.vr == "undefined" || c.vr > maxRatio || c.vr <= 0.0)? '-' : c.vr.toFixed(1),
            canAccess: c.canAccess,
          })         
        });
        this.items = arr;
        this.loading = false;
      }
      else {
        // check if response is unauthorized
        if(response.status === 401) {
          // redirect to login page
          this.$router.push('/login');
        }
      }
    },
    getColorValuationRatio(value) {
      return getColorValuationRatio(value)
    }, 
    getColorDebt(yearsOfCashFlow) {

      let years = yearsOfCashFlow;
      if(years == '-')
      {
        years = 0;
      }

      return getInterpolatedColor(-years, -10, -6, -4, -3, -2, -1, -0.5);
    },    
    convertToNumber(str) {
      // Remove "%" character and white spaces from the string
      str = str.replace(/%/g, '').replace(/\s+/g, '');

      // Convert the string to a number and return the result
      return Number(str);
    },
    getColorGrowth(growth)
    {
      var g = this.convertToNumber(growth);
      return getInterpolatedColor(g/100, -0.2, -0.1, -0.05, 0, 0.05, 0.1, 0.2);
    },
    getColorFactor(factor)
    {
      return getColor10Ratio(factor);
    },
    getColorDividendY(dividendY)
    {
      var v = this.convertToNumber(dividendY)/100;
      return getColorDividendYield(v);
    },
    getColorDividendYieldFCF(dividendYFCF)
    {
      var v = this.convertToNumber(dividendYFCF)/100;
      return getColorDividendYieldSupportedByFreeCashflow(v);
    },
    closePopupCombinedValuation() {
      this.showCompanyValuationCombinedValuationInfo = false;
    },
    closePopupTenCapEarnings() {
      this.showCompanyValuationTenCapEarningsInfo = false;
    },
    closePopupTenCapFreeCashflow()
    {
      this.showCompanyValuationTenCapFreeCashflowInfo = false;
    },
    closePopupTenCapOwnerEarnings()
    {
      this.showCompanyValuationTenCapOwnerEarningsInfo = false;
    },
    closePopupMarginOfSafety()
    {
      this.showCompanyValuationMarginOfSafetyInfo = false;
    },
    closePopupPaybackTimeFreeCashflow()
    {
      this.showCompanyValuationPaybackTimeFreeCashflowInfo = false;
    },
    closePopupPaybackTimeOwnerEarnings()
    {
      this.showCompanyValuationPaybackTimeOwnerEarningsInfo = false;
    },
    closePopupPEGRatio()
    {
      this.showCompanyValuationPEGRatioInfo = false;
    },
    closePopupGrahamNumber()
    {
      this.showCompanyValuationGrahamNumberInfo = false;
    },
    closePopupNetCurrentAssetValue()
    {
      this.showCompanyValuationNetCurrentAssetValueInfo = false;
    },
    selectAllIndustries() {
      this.industrySelection = [];
    },
    selectAllCountries() {
      this.countrySelection = [];
    },
  },
  created() {
    this.language = sessionManager.read('language');
    this.unitOfAccountUnit = getUnitOfAccountUnit(this.unitOfAccount);
    if(this.language === 'en') {
      this.search_valuationLabels = this.search_valuationLabels_en;
    }
    else
    {
      this.search_valuationLabels = this.search_valuationLabels_de;
    }
    document.title = this.strings['title'] + ' - Valuetion';
    logUserEvent('screener', sessionManager, this.$vuetify.breakpoint.mobile);
    this.getDataFromApi();    
  },
  computed: {
    row_classes(item) {
      let classes = 'darkest font-pj text-v-sm pl-0';
      return classes;
    },
    strings() {
      return this.languageStrings[this.language];
    },
    headers() {
      // filter the headers based on mobile or not
      if (this.$vuetify.breakpoint.mobile) {
        return this.languageHeaders[this.language].filter(h => h.showOnMobile);
      } else {
        return this.languageHeaders[this.language];
      }
    },
    search_netDebtInYearsOfOperatingCashFlow_text: {
      get() {
        return this.search_netDebtInYearsOfOperatingCashFlow;
      },
      set(value) {
        this.search_netDebtInYearsOfOperatingCashFlow = value;
        this.doSearch();
      },
    },
    search_MOS_text: {
      get() {
        return this.search_MOS;
      },
      set(value) {
        this.search_MOS = value;
        this.doSearch();
      },
    },
    search_marketCapFormatted() {
      return formatUnitOfAccount(this.search_marketCap[this.search_marketCapRange[0]], this.unitOfAccount, false, false) + 
      ' - ' + formatUnitOfAccount(this.search_marketCap[this.search_marketCapRange[1]], this.unitOfAccount, true, false);
    }
  },
  watch: {
    search_marketCapRange: {
      handler(val) {
        this.doSearch();
      }
    },
    search_MarketCap_active: {
      handler(val) {
        this.doSearch();
      }
    },
    search_dividendYield_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_BookValueGrowth_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_EarningsGrowth_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_CashflowFromOperationGrowth_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_RevenueGrowth_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_growth_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_ROE_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_netDebtInYearsOfOperatingCashFlow_slider: {
      handler(val) {
        this.search_netDebtInYearsOfOperatingCashFlow = val;
        this.doSearch();
      }
    },
    search_pe: {
      handler(val) {
        this.doSearch();
      }
    },
    search_pfc: {
      handler(val) {
        this.doSearch();
      }
    },
    search_poe: {
      handler(val) {
        this.doSearch();
      }
    },
    search_tenCapEarnings_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_tenCapFreeCashflow_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_tenCapOwnerEarnings_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_MOS_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_PBTFreeCashflow_slider:  {
      handler(val) {
        this.doSearch();
      }
    },
    search_PBTOwnerEarnings_slider:  {
      handler(val) {
        this.doSearch();
      }
    },
    search_netCurrentAssetValueValuation_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_grahamNumberValuation_slider: {
      handler(val) {
        this.doSearch();
      }
    },
    search_combinedValuation_slider: {
      handler(val) {
        this.doSearch();
      }
    },
  }
}
</script>

<style scoped>
  .v-input--checkbox .v-label .v-input .v-input--selection-controls__input{
    font-size: 16px !important; 
    font-family: Plus Jakarta Sans;  
  }

  .v-input {
    font-size: 16px !important; 
    font-family: Plus Jakarta Sans;  
  }

  .v-treeview {
    font-size: 14px;
    font-family: Plus Jakarta Sans;
  }

  .v-stepper {
    font-family: Plus Jakarta Sans;
  }
  .blurred {
    filter: blur(5px);
    cursor: pointer;
  }

  .v-data-table {
      font-family: Plus Jakarta Sans;  
      font-size: 16px !important; 
      font-weight:400;
  }

  .v-data-table >>> th {
      font-family: Plus Jakarta Sans;  
      font-size: 14px !important; 
      font-weight: normal !important;
      vertical-align: baseline !important;    
      padding-left: 0  !important;  
      color:#1d270f !important;     
  }

  .v-data-table >>> td {
      font-family: Plus Jakarta Sans;  
      font-size: 14px !important; 
      font-weight: normal !important;
      padding-left: 0  !important;   
      color:#1d270f !important;  
  }


</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
          <p :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-md font-pj py-v-md' : 'darkest text-v-md font-pj py-v-md'">{{strings['text_title']}}<br>{{strings['text_simple_steps']}}</p>
      </div>  
    </div>
    <div class="flex justify-center">
      <v-stepper fluid flat rounded="0" class="pa-0" v-model="step" vertical non-linear>

        <!-- Step 1 -->
        <v-stepper-step :complete="step > 1" step="1" @click.native="step = 1">
          <p class="text-v-xl font-pj"><b>{{strings['location_title']}}</b><span v-if="step == 1"> ({{strings['companies']}} {{ totalCompanies }})</span></p>
        </v-stepper-step>
        <v-stepper-content step="1" complete editable>
          <p class="text-v-md font-pj">{{ strings['location_text'] }}</p>
          <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
            <div class="p-v-sm">
              <v-btn color="primary" @click="selectAllCountries(); step = 2">{{strings['text_all_countries']}}</v-btn>
            </div>
            <div class="p-v-sm">
              <v-btn color="primary" @click="specifyCountries = true">{{strings['text_specify_countries']}}</v-btn>
            </div>
          </div>    
          <div v-if="specifyCountries">
            <v-container>
                <v-treeview selectable v-model="countrySelection" dense :items="countryItems" return-object @input="doSearch" :open="search_countryOpen" :activate-parents="true"></v-treeview>
            </v-container>
            <v-btn color="primary" @click="step = 2">{{strings['text_continue']}}</v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 2 -->
        <v-stepper-step :complete="step > 2" step="2" @click.native="step = 2">
          <p class="text-v-xl font-pj"><b>{{strings['sector_title']}}</b><span v-if="step == 2"> ({{strings['companies']}} {{ totalCompanies }})</span></p>
        </v-stepper-step>
        <v-stepper-content step="2" complete editable>
          <p class="text-v-md font-pj">{{ strings['sector_text'] }}</p>
          <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
            <div class="p-v-sm">
              <v-btn color="primary" @click="selectAllIndustries(); step = 3">{{strings['sector_all_sectors']}}</v-btn>
            </div>
            <div class="p-v-sm">
              <v-btn color="primary" @click="specifyIndustries = true">{{strings['sector_specify_sectors']}}</v-btn>
            </div>
          </div>           
          <div v-if="specifyIndustries">
            <v-container>
              <v-treeview selectable v-model="industrySelection" dense :items="industryItems" return-object @input="doSearch"></v-treeview>
            </v-container>
            <v-btn color="primary" @click="step = 3">{{strings['text_continue']}}</v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 3 -->
        <v-stepper-step :complete="step > 3" step="3" @click.native="step = 3">
          <p class="text-v-xl font-pj"><b>{{strings['marketcap_title']}}</b><span v-if="step == 3"> ({{strings['companies']}} {{ totalCompanies }})</span></p>
        </v-stepper-step>
        <v-stepper-content step="3" complete editable>
          <p class="text-v-md font-pj">{{ strings['marketcap_text'] }}</p>
          <v-row>
            <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
              <v-checkbox class="text-v-md font-pj" dense :label="strings['marketcap_label']" v-model="search_MarketCap_active" @change="doSearch"/> 
            </v-col>
            <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="6" sm="4" md="6" lg="4" xl="4">
              <v-range-slider
                v-model="search_marketCapRange"
                :min="search_marketCapMin"
                :max="search_marketCapMax"
                step="1"
                :disabled="!search_MarketCap_active"
              >
              </v-range-slider> 
            </v-col>
            <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3 text-v-sm font-pj' : 'pt-3 pl-3 pb-0 pr-0 text-v-sm font-pj'" cols="4" sm="4" md="2" lg="3" xl="3">
              {{ search_marketCapFormatted }}
            </v-col>
          </v-row>
          <v-btn color="primary" @click="step = 4">{{strings['text_continue']}}</v-btn>
        </v-stepper-content>

        <!-- Step 4 -->
        <v-stepper-step :complete="step > 4" step="4" @click.native="step = 4">
          <p class="text-v-xl font-pj"><b>{{strings['growth_title']}}</b><span v-if="step == 4"> ({{strings['companies']}} {{ totalCompanies }})</span></p>
        </v-stepper-step>
        <v-stepper-content step="4" complete editable>
          <p class="text-v-md font-pj">{{ strings['growth_text'] }}</p>
          <v-row>
            <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
              <v-checkbox class="text" dense :label="strings['growth_all']" v-model="search_growth_slider_active" @change="doSearch"/> 
            </v-col>             
            <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="8" md="6" lg="6" xl="6">
              <v-slider v-model="search_growth_slider" :max="search_growth.length - 1" min="0" step="1" thumb-label :disabled="!search_growth_slider_active">
                <template v-slot:thumb-label="{ value }">
                  {{ search_growth[search_growth_slider] }} %
                </template>
              </v-slider>
            </v-col>
            <v-col :class="$vuetify.breakpoint.sm ? 'text-v-sm font-pj pt-0 pl-3 pb-0 pr-3' : 'text-v-sm font-pj pt-3 pl-3 pb-0 pr-3'" cols="4" sm="4" md="2" lg="2" xl="2">
              {{ search_growth[search_growth_slider] }} %
            </v-col>
          </v-row>
          <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
            <div class="p-v-sm">
              <v-btn color="primary" @click="step = 5">{{strings['text_continue']}}</v-btn>
            </div>
            <div class="p-v-sm">
              <v-btn color="primary" @click="specifyGrowthIndividually = true">{{strings['growth_specify_individually']}}</v-btn>
            </div>
          </div>
          <div v-if="specifyGrowthIndividually">
          <v-container>
            <v-row>
              <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                <v-checkbox class="text" dense :label="strings['growth_book']" v-model="search_BookValueGrowth_active" @change="doSearch"/> 
              </v-col>             
              <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="8" md="6" lg="6" xl="6">
                <v-slider v-model="search_BookValueGrowth_slider" :max="search_growth.length - 1" min="0" step="1" thumb-label :disabled="!search_BookValueGrowth_active">
                  <template v-slot:thumb-label="{ value }">
                    {{ search_growth[search_BookValueGrowth_slider] }} %
                  </template>
                </v-slider>
              </v-col>
              <v-col :class="$vuetify.breakpoint.sm ? 'text-v-sm font-pj pt-0 pl-3 pb-0 pr-3' : 'text-v-sm font-pj pt-3 pl-3 pb-0 pr-3'" cols="4" sm="4" md="2" lg="2" xl="2">
                {{ search_growth[search_BookValueGrowth_slider] }} %
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                <v-checkbox class="text" dense :label="strings['growth_earnings']" v-model="search_EarningsGrowth_active" @change="doSearch"/> 
              </v-col>             
              <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="8" md="6" lg="6" xl="6">
                <v-slider v-model="search_EarningsGrowth_slider" :max="search_growth.length - 1" min="0" step="1" thumb-label :disabled="!search_EarningsGrowth_active">
                  <template v-slot:thumb-label="{ value }">
                    {{ search_growth[search_EarningsGrowth_slider] }} %
                  </template>
                </v-slider>
              </v-col>
              <v-col :class="$vuetify.breakpoint.sm ? 'text-v-sm font-pj pt-0 pl-3 pb-0 pr-3' : 'text-v-sm font-pj pt-3 pl-3 pb-0 pr-3'" cols="4" sm="4" md="2" lg="2" xl="2">
                {{ search_growth[search_EarningsGrowth_slider] }} %
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                <v-checkbox class="text" dense :label="strings['growth_cashflow']" v-model="search_CashflowFromOperationGrowth_active" @change="doSearch"/> 
              </v-col>             
              <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="8" md="6" lg="6" xl="6">
                <v-slider v-model="search_CashflowFromOperationGrowth_slider" :max="search_growth.length - 1" min="0" step="1" thumb-label :disabled="!search_CashflowFromOperationGrowth_active">
                  <template v-slot:thumb-label="{ value }">
                    {{ search_growth[search_CashflowFromOperationGrowth_slider] }} %
                  </template>
                </v-slider>
              </v-col>
              <v-col :class="$vuetify.breakpoint.sm ? 'text-v-sm font-pj pt-0 pl-3 pb-0 pr-3' : 'text-v-sm font-pj pt-3 pl-3 pb-0 pr-3'" cols="4" sm="4" md="2" lg="2" xl="2">
                {{ search_growth[search_CashflowFromOperationGrowth_slider] }} %
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                <v-checkbox class="text" dense :label="strings['growth_revenue']" v-model="search_RevenueGrowth_active" @change="doSearch"/> 
              </v-col>             
              <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="8" md="6" lg="6" xl="6">
                <v-slider v-model="search_RevenueGrowth_slider" :max="search_growth.length - 1" min="0" step="1" thumb-label :disabled="!search_RevenueGrowth_active">
                  <template v-slot:thumb-label="{ value }">
                    {{ search_growth[search_RevenueGrowth_slider] }} %
                  </template>
                </v-slider>
              </v-col>
              <v-col :class="$vuetify.breakpoint.sm ? 'text-v-sm font-pj pt-0 pl-3 pb-0 pr-3' : 'text-v-sm font-pj pt-3 pl-3 pb-0 pr-3'" cols="4" sm="4" md="2" lg="2" xl="2">
                {{ search_growth[search_RevenueGrowth_slider] }} %
              </v-col>
            </v-row>
          </v-container>  
          <v-btn color="primary" @click="step = 5">{{strings['text_continue']}}</v-btn>
        </div>
          
        </v-stepper-content>

        <!-- Step 5 -->
        <v-stepper-step :complete="step > 5" step="5" @click.native="step = 5;">
          <p class="text-v-xl font-pj"><b>{{strings['roc_title']}}</b><span v-if="step == 5"> ({{strings['companies']}} {{ totalCompanies }})</span></p>
        </v-stepper-step>
        <v-stepper-content step="5" complete editable>
          <p class="text-v-md font-pj">{{ strings['roc_text'] }}</p>
          <v-row>
            <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
              <v-checkbox dense :label="strings['roc_label']" v-model="search_ROE_active" @change="doSearch"/>
            </v-col>             
            <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="8" md="6" lg="6" xl="6">
              <v-slider v-model="search_ROE_slider" :max="search_growth.length - 1" min="0" step="1" thumb-label :disabled="!search_ROE_active">
                <template v-slot:thumb-label="{ value }">
                  {{ search_growth[search_ROE_slider] }} %
                </template>
              </v-slider>
            </v-col>
            <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="4" md="2" lg="2" xl="2">
              {{ search_growth[search_ROE_slider] }} %
            </v-col>
          </v-row>
          <v-btn color="primary" @click="step = 6">{{strings['text_continue']}}</v-btn>
        </v-stepper-content>

        <!-- Step 6 -->
        <v-stepper-step :complete="step > 6" step="6" @click.native="step = 6">
          <p class="text-v-xl font-pj"><b>{{strings['debt_title']}}</b><span v-if="step == 6"> ({{strings['companies']}} {{ totalCompanies }})</span></p>
        </v-stepper-step>
        <v-stepper-content step="6" complete editable>
          <p class="text-v-md font-pj">{{ strings['debt_text'] }}</p>
          <v-row>
            <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
              <v-checkbox dense :label="strings['debt_label_no_debt']" v-model="search_noNetDebt_active" @change="doSearch"/>
            </v-col>  
          </v-row>   
          <v-row>    
            <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
              <v-checkbox dense :label="strings['debt_label_years']" v-model="search_netDebtInYearsOfOperatingCashFlow_active" @change="doSearch" :disabled="search_noNetDebt_active"/>
            </v-col>  
            
            <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="8" md="6" lg="6" xl="6">
              <v-slider v-model="search_netDebtInYearsOfOperatingCashFlow_slider" max="5" min="0" step="1" thumb-label :disabled="!search_netDebtInYearsOfOperatingCashFlow_active">
                <template v-slot:thumb-label="{ value }">
                  {{ value }} 
                </template>
              </v-slider>
            </v-col>
            <v-col :class="$vuetify.breakpoint.sm ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="4" md="2" lg="2" xl="2">
              {{ search_netDebtInYearsOfOperatingCashFlow_text }} years
            </v-col>
          </v-row>
          <v-btn color="primary" @click="step = 7">{{strings['text_continue']}}</v-btn>
        </v-stepper-content>

              

        <!-- Step 7 -->
        <v-stepper-step step="7" @click.native="step = 7">
          <p class="text-v-xl font-pj"><b>{{strings['valuation_title']}}</b><span v-if="step == 7"> ({{strings['companies']}} {{ totalCompanies }})</span></p>
        </v-stepper-step>
        <v-stepper-content step="7" complete editable>
          <p class="text-v-md font-pj">{{ strings['valuation_text'] }}</p>
          <div>

            <!-- combined valuation-->
            <div class="text-v-xl font-pj pt-6 pb-3">{{strings['combined_valuation']}}<span v-if="step == 7"> ({{strings['companies']}} {{ totalCompanies }})</span></div>
            <v-row>
              <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                <div class="flex">
                  <div><v-checkbox dense :label="strings['combined_valuation_label']" v-model="search_combinedValuation_active" @change="doSearch"/></div>
                  <div class="pl-v-sm pt-v-sm">
                    <v-tooltip bottom color="primary" light>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationCombinedValuationInfo = true">mdi-help-circle-outline</v-icon>
                      </template>
                      <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                    </v-tooltip>
                  </div>
                  <CompanyValuationCombinedValuationInfo :onClose="closePopupCombinedValuation" :showPopup="showCompanyValuationCombinedValuationInfo"/>
                </div>              
              </v-col>
         
              <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                <v-slider 
                  v-model="search_combinedValuation_slider"
                  step="1"
                  append-icon="mdi-cash-multiple"
                  prepend-icon="mdi-cash"
                  :max="search_valuationLabels.length - 1" min="0"
                  :disabled="!search_combinedValuation_active"></v-slider>                
              </v-col>
              <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                <p class="pa-1">{{strings['price_value']}} < {{ search_valuationLabelValues[search_combinedValuation_slider]/100}} ({{ search_valuationLabels[search_combinedValuation_slider] }}) </p>  
              </v-col>
            </v-row>
            <!-- ask for more details -->
            <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
              <div class="p-v-sm">
                <v-btn color="primary" @click="speficyValuations = true; search_combinedValuation_active = false">{{strings['valuation_specify_valuations']}}</v-btn>
              </div>
            </div>
            <!-- detailed valuation metrics -->
            <div class="pt-6" v-if="speficyValuations">
              <!-- simple metrics -->
              <div class="text-v-xl font-pj">{{strings['simple_metrics']}}<span v-if="step == 7"> ({{strings['companies']}} {{ totalCompanies }})</span></div>
              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <v-checkbox dense :label="strings['pe_full']" v-model="search_pe_active" @change="doSearch"/>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_pe"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max=30 min=1
                    :disabled="!search_pe_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['pe']}} < {{ search_pe }}</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <v-checkbox dense :label="strings['price_fcf_full']" v-model="search_pfc_active" @change="doSearch"/>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_pfc"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max=30 min=1
                    :disabled="!search_pfc_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['pfc']}} < {{ search_pfc }}</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <v-checkbox dense :label="strings['price_oe_full']" v-model="search_poe_active" @change="doSearch"/>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_poe"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max=30 min=1
                    :disabled="!search_poe_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['price_oe']}} < {{ search_poe }}</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="6" sm="3" md="2" lg="2" xl="2">
                  <v-checkbox dense :label="strings['dividend_yield']" v-model="search_dividendYield_active" @change="doSearch"/>
                </v-col>     
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="6" sm="3" md="2" lg="2" xl="2">
                  <v-checkbox dense :label="strings['dividend_supported']" v-model="search_dividendYieldSupportedFCF_active" @change="doSearch"/>
                </v-col>           
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_dividendYield_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_growth.length - 1" min="0"
                    :disabled="!search_dividendYield_active">
                    <template v-slot:thumb-label="{ value }">
                      {{ search_growth[search_dividendYield_slider] }} %
                    </template>
                  </v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{ search_growth[search_dividendYield_slider] }} % </p>
                </v-col>
              </v-row>

              <!-- valuations no groth -->
              <div class="text-v-xl font-pj pt-6 pb-3">{{strings['valuations_no_growth']}}<span v-if="step == 7"> ({{strings['companies']}} {{ totalCompanies }})</span></div>
              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <div class="flex">
                    <div><v-checkbox dense :label="strings['ten_cap_earnings']" v-model="search_tenCapEarnings_active" @change="doSearch"/></div>
                    <div class="pl-v-sm pt-v-sm">
                      <v-tooltip bottom color="primary" light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationTenCapEarningsInfo = true">mdi-help-circle-outline</v-icon>
                        </template>
                        <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                      </v-tooltip>
                    </div>
                    <CompanyValuationTenCapEarningsInfo :onClose="closePopupTenCapEarnings" :showPopup="showCompanyValuationTenCapEarningsInfo"/>
                  </div>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_tenCapEarnings_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_valuationLabels.length - 1" min="0"
                    :disabled="!search_tenCapEarnings_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['price_value']}} < {{ search_valuationLabelValues[search_tenCapEarnings_slider]/100}} ({{ search_valuationLabels[search_tenCapEarnings_slider] }}) </p>  
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <div class="flex">
                    <div><v-checkbox dense :label="strings['ten_cap_fcf']" v-model="search_tenCapFreeCashflow_active" @change="doSearch"/></div>
                    <div class="pl-v-sm pt-v-sm">
                      <v-tooltip bottom color="primary" light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationTenCapFreeCashflowInfo = true">mdi-help-circle-outline</v-icon>
                        </template>
                        <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                      </v-tooltip>
                    </div>
                    <CompanyValuationTenCapFreeCashflowInfo :onClose="closePopupTenCapFreeCashflow" :showPopup="showCompanyValuationTenCapFreeCashflowInfo"/>
                  </div>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_tenCapFreeCashflow_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_valuationLabels.length - 1" min="0"
                    :disabled="!search_tenCapFreeCashflow_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['price_value']}} < {{ search_valuationLabelValues[search_tenCapFreeCashflow_slider]/100}} ({{ search_valuationLabels[search_tenCapFreeCashflow_slider] }}) </p>  
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <div class="flex">
                    <div><v-checkbox dense :label="strings['ten_cap_oe']" v-model="search_tenCapOwnerEarnings_active" @change="doSearch"/></div>
                    <div class="pl-v-sm pt-v-sm">
                      <v-tooltip bottom color="primary" light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationTenCapOwnerEarningsInfo = true">mdi-help-circle-outline</v-icon>
                        </template>
                        <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                      </v-tooltip>
                    </div>
                    <CompanyValuationTenCapOwnerEarningsInfo :onClose="closePopupTenCapOwnerEarnings" :showPopup="showCompanyValuationTenCapOwnerEarningsInfo"/>
                  </div>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_tenCapOwnerEarnings_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_valuationLabels.length - 1" min="0"
                    :disabled="!search_tenCapOwnerEarnings_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['price_value']}} < {{ search_valuationLabelValues[search_tenCapOwnerEarnings_slider]/100}} ({{ search_valuationLabels[search_tenCapOwnerEarnings_slider] }}) </p>  
                </v-col>
              </v-row>

              <!-- valuations with growth -->
              <div class="text-v-xl font-pj pt-6 pb-3">{{strings['valuations_with_growth']}}<span v-if="step == 7"> ({{strings['companies']}} {{ totalCompanies }})</span></div>
              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <div class="flex">
                    <div><v-checkbox dense :label="strings['marin_of_safety']" v-model="search_MOS_active" @change="doSearch"/></div>
                    <div class="pl-v-sm pt-v-sm">
                      <v-tooltip bottom color="primary" light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationMarginOfSafetyInfo = true">mdi-help-circle-outline</v-icon>
                        </template>
                        <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                      </v-tooltip>
                    </div>
                    <CompanyValuationMarginOfSafetyInfo :onClose="closePopupMarginOfSafety" :showPopup="showCompanyValuationMarginOfSafetyInfo"/>
                  </div>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_MOS_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_valuationLabels.length - 1" min="0"
                    :disabled="!search_MOS_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['price_value']}} < {{ search_valuationLabelValues[search_MOS_slider]/100}} ({{ search_valuationLabels[search_MOS_slider] }}) </p>  
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <div class="flex">
                    <div><v-checkbox dense :label="strings['payback_fcf']" v-model="search_PBTFreeCashflow_active" @change="doSearch"/></div>
                    <div class="pl-v-sm pt-v-sm">
                      <v-tooltip bottom color="primary" light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationPaybackTimeFreeCashflowInfo = true">mdi-help-circle-outline</v-icon>
                        </template>
                        <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                      </v-tooltip>
                    </div>
                    <CompanyValuationPaybackTimeFreeCashflowInfo :onClose="closePopupPaybackTimeFreeCashflow" :showPopup="showCompanyValuationPaybackTimeFreeCashflowInfo"/>
                  </div>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_PBTFreeCashflow_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_valuationLabels.length - 1" min="0"
                    :disabled="!search_PBTFreeCashflow_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['price_value']}} < {{ search_valuationLabelValues[search_PBTFreeCashflow_slider]/100}} ({{ search_valuationLabels[search_PBTFreeCashflow_slider] }}) </p>  
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <div class="flex">
                    <div><v-checkbox dense :label="strings['payback_oe']" v-model="search_PBTOwnerEarnings_active" @change="doSearch"/></div>
                    <div class="pl-v-sm pt-v-sm">
                      <v-tooltip bottom color="primary" light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationPaybackTimeOwnerEarningsInfo = true">mdi-help-circle-outline</v-icon>
                        </template>
                        <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                      </v-tooltip>
                    </div>
                    <CompanyValuationPaybackTimeOwnerEarningsInfo :onClose="closePopupPaybackTimeOwnerEarnings" :showPopup="showCompanyValuationPaybackTimeOwnerEarningsInfo"/>
                  </div>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_PBTOwnerEarnings_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_valuationLabels.length - 1" min="0"
                    :disabled="!search_PBTOwnerEarnings_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['price_value']}} < {{ search_valuationLabelValues[search_PBTOwnerEarnings_slider]/100}} ({{ search_valuationLabels[search_PBTOwnerEarnings_slider] }}) </p>  
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <div class="flex">
                    <div><v-checkbox dense :label="strings['peg_ratio']" v-model="search_PEG_Ratio_active" @change="doSearch"/></div>
                    <div class="pl-v-sm pt-v-sm">
                      <v-tooltip bottom color="primary" light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationPEGRatioInfo = true">mdi-help-circle-outline</v-icon>
                        </template>
                        <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                      </v-tooltip>
                    </div>
                    <CompanyValuationPeterLynchPEGRatioInfo :onClose="closePopupPEGRatio" :showPopup="showCompanyValuationPEGRatioInfo"/>
                  </div>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_PEG_Ratio_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_peg_ratioValues.length - 1" min="0"
                    :disabled="!search_PEG_Ratio_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['peg_ratio_short']}}: {{ search_peg_ratioValues[search_PEG_Ratio_slider] }} ({{ search_valuationLabels[search_PEG_Ratio_slider] }})</p>
                </v-col>
              </v-row>

              <!-- valuations with book value -->
              <div class="text-v-xl font-pj pt-6 pb-3">{{strings['book_value']}}<span v-if="step == 7"> ({{strings['companies']}} {{ totalCompanies }})</span></div>

              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <div class="flex">
                    <div><v-checkbox dense :label="strings['graham_number']" v-model="search_grahamNumberValuation_active" @change="doSearch"/></div>
                    <div class="pl-v-sm pt-v-sm">
                      <v-tooltip bottom color="primary" light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationGrahamNumberInfo = true">mdi-help-circle-outline</v-icon>
                        </template>
                        <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                      </v-tooltip>
                    </div>
                    <CompanyValuationGrahamNumberInfo :onClose="closePopupGrahamNumber" :showPopup="showCompanyValuationGrahamNumberInfo"/>
                  </div>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_grahamNumberValuation_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_valuationLabels.length - 1" min="0"
                    :disabled="!search_grahamNumberValuation_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['price_value']}}: {{ search_peg_ratioValues[search_grahamNumberValuation_slider] }} ({{ search_valuationLabels[search_grahamNumberValuation_slider] }})</p>
                </v-col>
              </v-row>

              <!-- liquidation -->

              <div class="text-v-xl font-pj pt-6 pb-3">{{strings['liquidation']}}<span v-if="step == 7"> ({{strings['companies']}} {{ totalCompanies }})</span></div>
              <v-row>
                <v-col class="pt-3 pl-3 pb-0 pr-3" cols="12" sm="6" md="4" lg="4" xl="4">
                  <div class="flex">
                    <div><v-checkbox dense :label="strings['net_current_asset']" v-model="search_netCurrentAssetValueValuation_active" @change="doSearch"/></div>
                    <div class="pl-v-sm pt-v-sm">
                      <v-tooltip bottom color="primary" light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="showCompanyValuationNetCurrentAssetValueInfo = true">mdi-help-circle-outline</v-icon>
                        </template>
                        <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
                      </v-tooltip>
                    </div>
                    <CompanyValuationNetCurrentAssetValueInfo :onClose="closePopupNetCurrentAssetValue" :showPopup="showCompanyValuationNetCurrentAssetValueInfo"/>
                  </div>
                </v-col>             
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="8" sm="4" md="4" lg="4" xl="4">
                  <v-slider 
                    v-model="search_netCurrentAssetValueValuation_slider"
                    step="1"
                    append-icon="mdi-cash-multiple"
                    prepend-icon="mdi-cash"
                    :max="search_valuationLabels.length - 1" min="0"
                    :disabled="!search_netCurrentAssetValueValuation_active"></v-slider>                
                </v-col>
                <v-col :class="$vuetify.breakpoint.sx ? 'pt-0 pl-3 pb-0 pr-3' : 'pt-3 pl-3 pb-0 pr-3'" cols="4" sm="2" md="4" lg="4" xl="4">
                  <p class="pa-1">{{strings['price_value']}}: {{ search_peg_ratioValues[search_netCurrentAssetValueValuation_slider] }} ({{ search_valuationLabels[search_netCurrentAssetValueValuation_slider] }})</p>
                </v-col>
              </v-row>
              <v-row class="pa-2"></v-row>
            </div>
          </div>  
          
        </v-stepper-content>
      </v-stepper>
    </div>

    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{ totalCompanies }} {{strings['found_companies']}}</h2>
    </div>
    <div class="flex justify-center darkest font-pj text-v-sm pl-3 pt-3">

      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :items-per-page="100"
        :hide-default-footer="false"
        :footer-props="{'items-per-page-options':[20, 50, 100, 1000]}"
        :options.sync="options_new"
        :server-items-length="totalCompanies"
        :loading="loading"
        :item-class="row_classes"
        mobile-breakpoint="0"
        @update:page="doSearch"
        @update:sortBy="doSearch"
        @update:options="doSearch"
      >
        <template #item.symbol="{item}">
          <div v-if="item.canAccess">
            <router-link :to="{ path: 'profile/' + replaceDotWithUnderscore(item.symbol) }" target="_blank">
              <div>{{ item.symbol }}</div>
            </router-link>
          </div>
          <div v-else class="blurred" @click="gotoPremium(item)">{{ item.symbol }}</div>
        </template>

        <template #item.companyName="{item}">
          <div v-if="item.canAccess">{{ item.companyName }}</div>
          <div v-else class="blurred" @click="gotoPremium(item)">{{ item.companyName }}</div>
        </template>

        <template #item.country="{item}">
          <div v-if="item.canAccess">{{ item.country }}</div>
          <div v-else class="blurred" @click="gotoPremium(item)">{{ item.country }}</div>
        </template>

        <template #item.industry="{item}">
          <div v-if="item.canAccess">{{ item.industry }}</div>
          <div v-else class="blurred" @click="gotoPremium(item)">{{ item.industry }}</div>
        </template>

        <template #item.price="{item}">
          <div v-if="item.canAccess">{{ item.price }}</div>
          <div v-else class="blurred" @click="gotoPremium(item)">{{ item.price }}</div>
        </template>

        <template #item.tenCapEVR="{value, index}">
          <div :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>
        <template #item.tenCapOEVR="{value, index}">
          <div :style="{ 'text-align': 'center','background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>
        <template #item.tenCapFCFVR="{value, index}">
          <div :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>
        <template #item.mosVR="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>
        <template #item.pbtVRFCF="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>
        <template #item.pbtVROE="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>
        <template #item.ncavVR="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>
        <template #item.netDebtInYearsOfOCF="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorDebt(value) }">{{ value }}</div>
        </template>
        <template #item.bndG5="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorGrowth(value) }">{{ value }}</div>
        </template>
        <template #item.roe5="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorGrowth(value) }">{{ value }}</div>
        </template>
        <template #item.pe="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorFactor(value) }">{{ value }}</div>
        </template>
        <template #item.pfc="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorFactor(value) }">{{ value }}</div>
        </template>
        <template #item.poe="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorFactor(value) }">{{ value }}</div>
        </template>
        <template #item.vr="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>        
        <template #item.pegRatio="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>   
        <template #item.grahamVR="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorValuationRatio(value) }">{{ value }}</div>
        </template>   
        <template #item.dividendYield="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorDividendY(value) }">{{ value }}</div>
        </template>
        <template #item.dividendsOfFCF="{value, index}">
          <div  :style="{ 'text-align': 'center', 'background-color': getColorDividendYieldFCF(value) }">{{ value }}</div>
        </template>        

      </v-data-table>
    </div>
  </div>
</template>

/* eslint-enable */ 