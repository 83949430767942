/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationTenCapOwnerEarningsInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Ten-Cap Owner Earnings', 
            subtitle: 'Inspired by Phil Town & Danielle Town',
            explaination_title: 'Short Explaination',
            explaination: 'Imagine you buy a printer that prints 100$ every year. How much would you pay for it?',
            explaination2: 'We think it is worth 10x 100$, so 1\'000$.',
            citation_title: 'How do we get there?',
            citation: 'In their book "Invested - How Warren Buffett and Charlie Munger taught me to master my mind, my emotions, and my money (with a little help from my dad)" '+
              'by Danielle Town and Phil Town the valuation method "Ten-Cap-Pricing Method" is described (ISBN 978-3-86470-604-2 Kapiel 7: Die Ten-Cap-Pricing Methode, Page 225-242).',
            citation_how: 'How does it work?',
            citation2: 'The Ten-Cap valuation method is an approach to valuation that helps investors determine the value of a company. ' +
              'It states that an investor should ideally not pay more than ten times the owner earnings of a company, hence the name "Ten-Cap".',
            citation3: 'Owner earnings is a term coined by Warren Buffet, which essentially means net income with depreciation and amortization added back, ' +
              'from which then the maintenance capex is subtracted.',
            citation4: 'Only the maintenance capex is subtracted, which is used to maintain the company. ' +
              'Since the split of maintenance capex and growth capex is not part of the usual financial reporting, initially it is assumed that 70% of the total investment is necessary to maintain the company. ' +
              'If you have a more precise idea of the split, you should adjust this value.',
            citation5: 'At the end we deviate slightly from the original method by subtracting the net debt to get the value of the company.',
          },
          de: {
            title: 'Ten-Cap Owner Earnings', 
            subtitle: 'Aus "Gut Investiert" von Danielle Town & Phil Town',
            explaination_title: 'Kurz erklärt',
            explaination: 'Stell Dir vor, es Du kaufst einen Drucker der jedes Jahr 100$ druckt. Wie viel würdest Du dafür bezahlen?',
            explaination2: 'Wir denken, er ist 10x 100$ wert, also 1\'000$.',
            citation_title: 'Wie kommen wir darauf?',
            citation: 'In ihrem Buch "Gut Investiert - Wie Warren Buffett und Charlie Munger (und Paps*) mich lehrten, meine Gedanken, meine Emotionen und mein Geld unter Kontrolle zu bekommen" '+
              'von Danielle Town und Phil Town ist die Bewertungsmethode "Ten-Cap-Pricing Methode" beschrieben (ISBN 978-3-86470-604-2 Kapiel 7: Die Ten-Cap-Pricing Methode, Seite 225-242).',
            citation_how: 'Wie funktioniert die Methode?',
            citation2: 'Die Ten-Cap Bewertungsmethode ist ein Bewertungsansatz, der Anlegern hilft, den Wert eines Unternehmens zu bestimmen. ' +
              'Sie besagt, dass ein Anleger idealerweise nicht mehr als das Zehnfache des Eigentümergewinns eines Unternehmens zahlen sollte, daher der Name "Ten-Cap".',
            citation3: 'Eigentümergewinn oder Owner Earnings, ist ein Begriff, den Warren Buffett geprägt hat, der im Wesentlichen den Nettogewinn mit wieder hinzugefügter Abschreibung und Amortisation meint, von dem dann die Instandhaltungskosten/Kapitalkosten abgezogen werden.',
            citation4: 'Dabei werden lediglich die Instandhaltungskosten/Kapitalkosten abgezogen die für den Erhalt des Unternehmens verwendet werden. ' +
              'Da die Aufteilung der Instandhaltungskosten/Kapitalkosten auf Instandhaltung und Wachstum nicht Teil des üblichen Finanz-Reportings sind, wird inital angenommen, dass 70% der Gestammtinvestitionen für den Erhalt des Unternehmens notwendig ist. ' +
              'Wenn Du eine genauere Vorstellung über die Aufteilung hast, solltest Du diesen Wert anpassen.',
            citation5: 'Am Ende weichen wir leicht von der ursprünglichen Methode ab, indem wir die Nettoverschuldung abziehen, um den Wert des Unternehmens zu erhalten.',            
          }
        },
        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>
  
  
<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_how'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation3'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation4'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation5'] }}</div>
    </template>
  </PopupInfo>
</template>
/* eslint-enable */ 
  