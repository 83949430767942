/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationGrahamNumberInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Graham Number',   
            subtitle: 'From "Intelligent Investor" by Benjamin Graham',   
            explaination_title: 'Short explaination',
            explaination: 'Imagine you buy a printer that prints 100$ per year and whose material costs 500$. How much would you pay for it?',
            explaination2: 'We find it is square root of [22.5x 100$) x 500$] = ~1060$ worth.',
            citation_title: 'How do we get there?',
            citation: 'In his book "Intelligent Investor" Benjamin Graham describes the Graham Number (ISBN: 978-3-89879-827-3, Kapitel 14: Aktienauswahl für den defensiven Investor, 7. Moderates KBV, Page 371).',
            citation_how: 'How does the method work?',
            citation2: 'This method is used to determine the intrinsic value of a company and identify undervalued companies for investment purposes. ' + 
              'The Graham number is calculated based on a company\'s earnings and book value. ',
            citation3: 'The formula for the Graham number is: ',
            citation4: 'Graham number = square root of [(22.5 x Earnings) x Book Value]. ' ,
            citation5: 'The number 22.5 is a constant that Graham used to represent a stock price that is 15 times the earnings per share (P/E) ratio of 1.5 times the growth rate of 7%. ' +
              'The idea is that a company trading for a P/E ratio below this fair value could potentially be undervalued.',
            citation6: 'Since cash and debt are already accounted for in the book value, they are not deducted separately.',
          },
          de: {
            title: 'Graham Nummer',
            subtitle: 'Aus "Intelligent Investieren" von Benjamin Graham',
            explaination_title: 'Kurz erklärt',
            explaination: 'Stell Dir vor, es Du kaufst einen Drucker, der pro Jahr 100$ druckt und dessen Materialwert 500$ beträgt. Wie viel würdest Du dafür bezahlen?',
            explaination2: 'Wir finden er ist Quadratwurzel aus [(22.5x 100$) x 500$] = ~1060$ wert.',
            citation_title: 'Wie kommen wir darauf?',
            citation: 'In seinem Buch "Intelligent Investieren" beschreibt Benjamin Graham die Graham Nummer (ISBN: 978-3-89879-827-3, Kapitel 14: Aktienauswahl für den defensiven Investor, 7. Moderates KBV, Seite 371)).',
            citation_how: 'Wie funktioniert die Methode?',
            citation2: 'Diese Methode wird verwendet, um den inneren Wert eines Unternehmens zu ermitteln und unterbewertete Unternehmen für Investitionszwecke zu identifizieren. ' + 
              'Die Graham-Zahl wird auf der Grundlage des Gewinns und des Buchwerts eines Unternehmens berechnet. ',
            citation3: 'Die Formel für die Grahamsche Zahl lautet:',
            citation4: 'Grahamsche Zahl = Quadratwurzel aus [(22,5 x Gewinn) x Buchwert]. ',
            citation5: 'Die Zahl 22,5 ist eine Konstante, die Graham verwendet hat, um ein angemessenes Kurs-Gewinn-Verhältnis (KGV) für ein Unternehmen mit einer Wachstumsrate von 7 % zu ermitteln. ' +
              'Die Idee ist, dass ein Unternehmen mit einem KGV unter diesem fairen Wert potenziell unterbewertet ist.',
            citation6: 'Da im Buchwert bereits Bargeld und Schulden verrechnet sind, werden diese nicht separat abgezogen.',
          }
        },

        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>

<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_how'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation3'] }}<br>{{ strings['citation4'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation5'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation6'] }}</div>
    </template>
  </PopupInfo>
</template>

/* eslint-enable */ 
  