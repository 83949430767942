<script>

import { sessionManager } from '@/main';
import { eventBus } from '@/main'
import { Colors } from '../colors.js';

export default {
  data() {
    return {
      visible: false,
      message: '',
      color: 'error', // default to error. Change this based on types of messages if needed.
      languageStrings: {
        en: {
          close: 'Close',
        },
        de: {
          close: 'Schließen',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  mounted() {
    // Listen to emitted events
    eventBus.$on('error', this.showError);
    eventBus.$on('success', this.showSuccess);
    // Add similar listeners for other types of events if necessary.
  },
  beforeDestroy() {
    // Cleanup listeners
    eventBus.$off('error', this.showError);
    eventBus.$on('success', this.showSuccess);
  },
  methods: {
    showError(message) {
      this.message = message;
      this.visible = true;
      this.color = Colors.RedOrange;
    },
    showSuccess(message) {
      this.message = message;
      this.visible = true;
      this.color = Colors.PrimaryLight;
    },
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
};
</script>

<template>
  <v-snackbar v-model="visible" :timeout="5000" :color="color">
    <div class="darkest text-v-sm font-pj pb-3">{{ message }}</div>
    <!--<v-btn text @click="visible = false" class="darkest text-v-sm font-pj">{{strings['close']}}</v-btn>-->
  </v-snackbar>
</template>

