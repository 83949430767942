/* eslint-disable */
// Translation mapping
const translations = {
    bankInterestIncome: {
        en: 'Bank Interest Income',
        de: 'Bank zinsabhängige Einnahmen'
    },
    netInterestIncome: {
        en: 'Net Interest Income',
        de: 'Nettozinsen'
    },
    netInterestIncAfterLoanLossProv: {  
        en: 'Net Interest Inc After Loan Loss Prov',
        de: 'Nettozinsen nach Kreditverlustvorsorge'
    },
    loanLossProvision: {
        en: 'Loan Loss Provision',
        de: 'Kreditverlustvorsorge'
    },
    interestExpense: {
        en: 'Bank Interest Expense',
        de: 'Bank zinsabhängige Ausgaben'
    },
    bankNonInterestExpense: {
        en: 'Bank Non Interest Expense',
        de: 'Bank nicht zinsabhängige Ausgaben'
    },
    bankNonInterestIncome: {
        en: 'Bank Non Interest Income',
        de: 'Bank nicht zinsabhängige Einnahmen'
    },
    revenue: {
        en: 'Revenue',
        de: 'Umsatz'
    },
    grossIncome: {
        en: 'Gross Income',
        de: 'Bruttogewinn'
    },
    costOfGoodsSold: {
        en: 'Cost of Goods Sold',
        de: 'Umsatzkosten'
    },
    ebit: {
        en: 'Ebit',
        de: 'Ebit'
    },
    pretaxIncome: {
        en: 'Income before Tax',
        de: 'Einkommen vor Steuern'
    },
    interestIncomeExpense: {
        en: 'Interest Income Expense',
        de: 'Zinsaufwand'
    },
    nonRecurringItems: {
        en: 'Non Recurring Items',
        de: 'Nicht wiederkehrende Posten'
    },
    gainLossOnDispositionOfAssets: {
        en: 'Gain / Loss on Disposition of Assets',
        de: 'Gewinn / Verlust aus der Veräußerung von Vermögenswerten'
    },
    totalOtherIncomeExpenseNet: {
        en: 'Other Income & Expenses Net',
        de: 'Sonstige Erträge & Aufwendungen Netto'
    },
    netIncomeAfterTaxes: {
        en: 'Net Income After Taxes',
        de: 'Nettoeinkommen nach Steuern'
    },
    provisionforIncomeTaxes: {
        en: 'Provision for Income Taxes',
        de: 'Steuerrückstellungen'
    },
    netIncome: {
        en: 'Net Income',
        de: 'Nettoeinkommen'
    },
    minorityInterest: {
        en: 'Minority Interest',
        de: 'Minderheitsbeteiligung'
    },
    equityEarningsAffiliates: {
        en: 'Equity Earnings Affiliates',
        de: 'Beteiligungsergebnis'
    },
    totalOperatingExpense: {
        en: 'Total Operating Expense',
        de: 'Gesamtbetriebsaufwand'
    },
    benefitsClaimsLossAdjustment: {
        en: 'Benefits Claims Loss Adjustment',
        de: 'Leistungen, Schadensregulierung'
    },
    deferredPolicyAcquisitionExpense: {
        en: 'Deferred Policy Acquisition Expense',
        de: 'Ausgezahlte Versicherungsprämien'
    },
    sgaExpense: {
        en: 'Selling, General & Admin',
        de: 'Vertriebs-, Allgemeine und Verwaltungskosten'
    },
    researchDevelopment: {
        en: 'Research & Development',
        de: 'Forschung und Entwicklung'
    },
    purchasedFuelPowerGas: {
        en: 'Purchased Fuel Power Gas',
        de: 'Gekaufter Kraftstoff, Strom, Gas'
    },
    operationsMaintenance: {
        en: 'Operations Maintenance',
        de: 'Betrieb und Wartung'
    },
    depreciationAndAmortization: {
        en: 'Depreciation & Amortization',
        de: 'Abschreibungen'
    },
    depreciationAmortization: {
        en: 'Depreciation Amortization',
        de: 'Abschreibungen'
    },
    otherOperatingExpensesTotal: {
        en: 'Other Operating Expenses Total',
        de: 'Sonstige Betriebsaufwendungen'
    },
    netOperatingCashFlow: {
        en: 'Net Operating Cash Flow',
        de: 'Cashflow aus laufender Geschäftstätigkeit'
    },
    netIncomeStartingLine: {
        en: 'Net Income',
        de: 'Nettoeinkommen'
    },
    deferredTaxesInvestmentTaxCredit: {
        en: 'Deferred Taxes Investment Tax Credit',
        de: 'Rückgestellte Einkommensteuer'
    },
    otherFundsNonCashItems: {
        en: 'Other Funds Non Cash Items',
        de: 'Andere nicht liquiditätswirksame Positionen'
    },
    stockBasedCompensation: {
        en: 'Stock Based Compensation',
        de: 'Aktienbasierte Vergütung'
    },
    changeInWorkingCapital : {
        en: 'Change In Working Capital',
        de: 'Veränderung des Betriebskapitals'
    },
    changesinWorkingCapital: {
        en: 'Changes in Working Capital',
        de: 'Veränderung des Betriebskapitals'
    },
    cashNet: {
        en: 'Cash Net',
        de: 'Cash Net'
    },
    netInvestingCashFlow: {
        en: 'Net Investing Cash Flow',
        de: 'Cashflow aus Investitionstätigkeit'
    },
    capex: {
        en: 'Capex',
        de: 'Investitionen'
    },
    otherInvestingCashFlowItemsTotal: {
        en: 'Other Investing Cash Flow Items Total',
        de: 'Andere Investitionen'
    },
    netCashFinancingActivities: {
        en: 'Net Cash Financing Activities',
        de: 'Cashflow aus Finanzierungstätigkeit'
    },
    cashDividendsPaid: {
        en: 'Cash Dividends Paid',
        de: 'Dividendenzahlungen'
    },
    issuanceReductionCapitalStock: {
        en: 'Issuance Reduction Capital Stock',
        de: 'Ausgabe / Rücknahme von Aktien'
    },
    issuanceReductionDebtNet: {
        en: 'Debt Issuance Net',
        de: 'Aufnahme von Schulden Netto'
    },
    otherFundsFinancingItems: {
        en: 'Other Funds Financing Items',
        de: 'Andere Finanzierungsposten'
    },
    foreignExchangeEffects: {
        en: 'Foreign Exchange Effects',
        de: 'Wechselkurseffekte'
    },
    netChangeCash : {
        en: 'Net Change Cash',
        de: 'Veränderung des Cashbestands'
    },
    changeinCash: {
        en: 'Change in Cash',
        de: 'Veränderung des Cashbestands'
    },
    issuanceReductionDebtNetPos: {
        en: 'Debt Issuance Net',
        de: 'Aufnahme von Schulden Netto'
    },
    issuanceReductionDebtNetNeg: {
        en: 'Debt Reduction Net',
        de: 'Rückzahlung von Schulden Netto'
    },
    totalAssets: {
        en: 'Total Assets',
        de: 'Gesamtvermögen'
    },
    currentAssets: {
        en: 'Current Assets',
        de: 'Umlaufvermögen'
    },
    cashDueFromBanks: {
        en: 'Cash Due From Banks',
        de: 'Kassenbestand, Guthaben bei Zentralbanken und Postgiroämtern'
    },
    bankInvestments: {
        en: 'Bank Investments',
        de: 'Wertpapiere'
    },
    netLoans: {
        en: 'Net Loans',
        de: 'Kredite'
    },
    cashShortTermInvestments: {
        en: 'Cash Short Term Investments',
        de: 'Bargeld und kurzfristige Investitionen'
    },
    cash: {
        en: 'Cash',
        de: 'Bargeld'
    },
    shortTermInvestments: {
        en: 'Short Term Investments',
        de: 'Kurzfristige Investitionen'
    },
    totalReceivables: {
        en: 'Total Receivables',
        de: 'Forderungen'
    },
    accountsReceivables: {
        en: 'Accounts Receivables',
        de: 'Forderungen'
    },
    otherReceivables: {
        en: 'Other Receivables',
        de: 'Sonstige Forderungen'
    },
    inventory: {
        en: 'Inventory',
        de: 'Inventar'
    },
    otherCurrentAssets: {
        en: 'Other Current Assets',
        de: 'Sonstige Umlaufvermögen'
    },
    longTermAssets: {
        en: 'Long Term Assets',
        de: 'Langfristige Vermögenswerte'
    },
    deferredPolicyAcquisitionCosts: {
        en: 'Deferred Policy Acquisition Costs',
        de: 'Aktivierte Abschlusskosten'
    },
    insuranceReceivables: {
        en: 'Insurance Receivables',
        de: 'Versicherungsforderungen'
    },
    propertyPlantEquipment: {
        en: 'Property Plant Equipment',
        de: 'Sachanlagen'
    },
    intangiblesAssets: {
        en: 'Intangible Assets',
        de: 'Immaterielle Vermögenswerte'
    },
    longTermInvestments: {
        en: 'Long Term Investments',
        de: 'Langfristige Investitionen'
    },
    otherLongTermAssets: {
        en: 'Other Long Term Assets',
        de: 'Sonstige langfristige Vermögenswerte'
    },
    longTermAssetsSum: {
        en: 'Long Term Assets',
        de: 'Langfristige Vermögenswerte'
    },
    noteReceivableLongTerm: {
        en: 'Note Receivable Long Term',
        de: 'Langfristige Forderungen'
    },
    otherAssets: {
        en: 'Other Assets',
        de: 'Sonstige Vermögenswerte'
    },
    totalLiabilities: {
        en: 'Total Liabilities',
        de: 'Gesamtverbindlichkeiten'
    },
    cashEquivalents: {
        en: 'Cash Equivalents',
        de: 'Gleichwertige Zahlungsmittel'
    },
    currentLiabilities: {
        en: 'Current Liabilities',
        de: 'Kurzfristige Verbindlichkeiten'
    },
    accountsPayable: {
        en: 'Accounts Payable',
        de: 'Verbindlichkeiten aus Lieferungen und Leistungen'
    },
    accruedLiability: {
        en: 'Accrued Liability',
        de: 'Sonstige kurzfristige Verbindlichkeiten'
    },
    shortTermDebt: {
        en: 'Short Term Debt',
        de: 'Kurzfristige Verbindlichkeiten'
    },
    currentPortionLongTermDebt: {
        en: 'Current Portion Long Term Debt',
        de: 'Kurzfristige Verbindlichkeiten'
    },
    otherCurrentliabilities: {
        en: 'Other Current Liabilities',
        de: 'Sonstige kurzfristige Verbindlichkeiten'
    },
    otherInterestBearingLiabilities: {
        en: 'Other Interest Bearing Liabilities',
        de: 'Sonstige verzinsliche Verbindlichkeiten'
    },
    longTermLiabilities: {
        en: 'Long Term Liabilities',
        de: 'Langfristige Verbindlichkeiten'
    },
    totalDeposits: {
        en: 'Total Deposits',
        de: 'Einlagen'
    },
    longTermDebt: {
        en: 'Long Term Debt',
        de: 'Langfristige Verbindlichkeiten'
    },
    deferredIncomeTax: {
        en: 'Deferred Income Tax',
        de: 'Steuerrückstellungen'
    },
    otherLiabilities: {
        en: 'Other Liabilities',
        de: 'Sonstige Verbindlichkeiten'
    },
    insurancePolicyLiabilities: {
        en: 'Insurance Policy Liabilities',
        de: 'Versicherungsrückstellungen'
    },
    totalEquity: {
        en: 'Total Equity',
        de: 'Eigenkapital'
    },
    commonStock: {
        en: 'Common Stock',
        de: 'Stammaktien'
    },
    preferredStock: {
        en: 'Preferred Stock',
        de: 'Vorzugsaktien'
    },
    treasuryStock: {
        en: 'Treasury Stock',
        de: 'Eigene Aktien'
    },
    retainedEarnings: {
        en: 'Retained Earnings',
        de: 'Gewinnrücklagen'
    },
    additionalPaidInCapital: {
        en: 'Additional Paid In Capital',
        de: 'Zusätzliches eingezahltes Kapital'
    },
    unrealizedProfitLossSecurity: {
        en: 'Unrealized Profit/Loss Security',
        de: 'Unrealisierter Gewinn/Verlust aus Wertpapieren'
    },
    otherEquity: {
        en: 'Other Equity',
        de: 'Sonstiges Eigenkapital'
    },
    costOfRevenue: {
        en: 'Cost of Revenue',
        de: 'Umsatzkosten'
    },
    grossProfit: {
        en: 'Gross Profit',
        de: 'Bruttogewinn'
    },
    researchAndDevelopmentExpenses: {
        en: 'Research & Development',
        de: 'Forschung und Entwicklung'
    },
    sellingGeneralAndAdministrativeExpenses: {
        en: 'Selling, General & Admin',
        de: 'Vertriebs-, Allgemeine und Verwaltungskosten'
    },
    otherExpenses: {
        en: 'Other Expenses',
        de: 'Sonstige Aufwendungen'
    },
    operatingExpenses: {
        en: 'Operating Expenses',
        de: 'Betriebsaufwand'
    },
    operatingIncome: {
        en: 'Operating Income',
        de: 'Betriebsergebnis'
    },
    incomeBeforeTax: {
        en: 'Income Before Tax',
        de: 'Einkommen vor Steuern'
    },
    incomeTaxExpense: {
        en: 'Income Tax Expense',
        de: 'Steueraufwand'
    },
    netIncome: {
        en: 'Net Income',
        de: 'Nettoeinkommen'
    },
    totalOtherIncomeExpensesNet : {
        en: 'Total Other Income Expenses Net',
        de: 'Sonstige Erträge & Aufwendungen Netto'
    },
    stockBasedCompensation: {
        en: 'Stock Based Compensation',
        de: 'Aktienbasierte Vergütung'
    },
    changesinWorkingCapital: {
        en: 'Changes in Working Capital',
        de: 'Veränderung des Betriebskapitals'
    },
    otherNonCashItems : {
        en: 'Other Non Cash Items',
        de: 'Andere nicht liquiditätswirksame Positionen'
    },
    netCashProvidedByOperatingActivities: {
        en: 'Net Cash Provided By Operating Activities',
        de: 'Cashflow aus laufender Geschäftstätigkeit'
    },
    investmentsInPropertyPlantAndEquipment: {
        en: 'Investments In Property Plant And Equipment',
        de: 'Investitionen in Sachanlagen'
    },
    salesMaturitiesOfInvestments: {
        en: 'Sales Maturities Of Investments',
        de: 'Verkäufe von Wertpapieren'
    },
    purchasesOfInvestments: {
        en: 'Purchases Of Investments',
        de: 'Käufe von Wertpapieren'
    },
    acquisitionsNet: {
        en: 'Acquisitions Net',
        de: 'Akquisitionen Netto'
    },
    otherInvestingActivites: {
        en: 'Other Investing Activites',
        de: 'Andere Investitionen'
    },
    netCashUsedForInvestingActivites: {
        en: 'Net Cash Used For Investing Activites',
        de: 'Cashflow aus Investitionstätigkeit'
    },
    debtRepayment: {
        en: 'Debt Repayment',
        de: 'Rückzahlung von Schulden'
    },
    commonStockIssued: {
        en: 'Common Stock Issued',
        de: 'Ausgabe von Aktien'
    },
    commonStockRepurchased: {
        en: 'Common Stock Repurchased',
        de: 'Rückkauf von Aktien'
    },
    dividendsPaid: {
        en: 'Dividends Paid',
        de: 'Dividendenzahlungen'
    },
    otherFinancingActivites: {
        en: 'Other Financing Activites',
        de: 'Andere Finanzierungsposten'
    },
    netCashUsedProvidedByFinancingActivities: {
        en: 'Net Cash Used Provided By Financing Activities',
        de: 'Cashflow aus Finanzierungstätigkeit'
    },  
    netChangeInCash: {
        en: 'Net Change In Cash',
        de: 'Veränderung des Cashbestands'
    },
    cashAtBeginningOfPeriod: {
        en: 'Cash At Beginning Of Period',
        de: 'Bargeld am Anfang des Zeitraums'
    },
    cashAtEndOfPeriod: {
        en: 'Cash At End Of Period',
        de: 'Bargeld am Ende des Zeitraums'
    },
    operatingCashFlow: {
        en: 'Operating Cash Flow',
        de: 'Cashflow aus laufender Geschäftstätigkeit'
    },
    capitalExpenditure: {
        en: 'Capital Expenditure',
        de: 'Investitionen'
    },
    freeCashFlow: {
        en: 'Free Cash Flow',
        de: 'Freier Cashflow'
    },
    cashAndCashEquivalents: {
        en: 'Cash And Cash Equivalents',
        de: 'Bargeld und gleichwertige Zahlungsmittel'
    },
    cashAndShortTermInvestments: {
        en: 'Cash And Short Term Investments',
        de: 'Bargeld und kurzfristige Investitionen'
    },
    netReceivables : {
        en: 'Net Receivables',
        de: 'Forderungen'
    },
    otherCurrentAssets: {
        en: 'Other Current Assets',
        de: 'Sonstige Umlaufvermögen'
    },
    totalCurrentAssets: {
        en: 'Total Current Assets',
        de: 'Umlaufvermögen'
    },
    propertyPlantEquipmentNet: {
        en: 'Property Plant Equipment Net',
        de: 'Sachanlagen'
    },
    goodwill: {
        en: 'Goodwill',
        de: 'Goodwill'
    },
    intangibleAssets: {
        en: 'Intangible Assets',
        de: 'Immaterielle Vermögenswerte'
    },
    goodwillAndIntangibleAssets: {
        en: 'Goodwill And Intangible Assets',
        de: 'Goodwill und immaterielle Vermögenswerte'
    },
    taxAssets: {
        en: 'Tax Assets',
        de: 'Steuervermögen'
    },
    otherNonCurrentAssets: {
        en: 'Other Non Current Assets',
        de: 'Sonstige langfristige Vermögenswerte'
    },
    totalNonCurrentAssets: {
        en: 'Total Non Current Assets',
        de: 'Langfristige Vermögenswerte'
    },
    otherAssets: {
        en: 'Other Assets',
        de: 'Sonstige Vermögenswerte'
    },
    accountPayables: {
        en: 'Account Payables',
        de: 'Verbindlichkeiten aus Lieferungen und Leistungen'
    },
    shortTermDebt: {
        en: 'Short Term Debt',
        de: 'Kurzfristige Verbindlichkeiten'
    },
    taxPayables: {
        en: 'Tax Payables',
        de: 'Steuerverbindlichkeiten'
    },
    deferredRevenue: {
        en: 'Deferred Revenue',
        de: 'Rückstellungen'
    },
    otherCurrentLiabilities: {
        en: 'Other Current Liabilities',
        de: 'Sonstige kurzfristige Verbindlichkeiten'
    },
    totalCurrentLiabilities: {
        en: 'Total Current Liabilities',
        de: 'Kurzfristige Verbindlichkeiten'
    },
    longTermDebt: {
        en: 'Long Term Debt',
        de: 'Langfristige Verbindlichkeiten'
    },
    deferredRevenueNonCurrent: {
        en: 'Deferred Revenue Non Current',
        de: 'Rückstellungen'
    },
    deferredTaxLiabilitiesNonCurrent: {
        en: 'Deferred Tax Liabilities Non Current',
        de: 'Steuerrückstellungen'
    },
    otherNonCurrentLiabilities: {
        en: 'Other Non Current Liabilities',
        de: 'Sonstige langfristige Verbindlichkeiten'
    },
    totalNonCurrentLiabilities: {
        en: 'Total Non Current Liabilities',
        de: 'Langfristige Verbindlichkeiten'
    },
    otherLiabilities: {
        en: 'Other Liabilities',
        de: 'Sonstige Verbindlichkeiten'
    },
    retainedEarnings: {
        en: 'Retained Earnings',
        de: 'Gewinnrücklagen'
    },
    accumulatedOtherComprehensiveIncomeLoss: {
        en: 'Accumulated Other Comprehensive Income Loss',
        de: 'Kumulierte sonstige Ergebnisse'
    },
    othertotalStockholdersEquity: {
        en: 'Other Total Stockholders Equity',
        de: 'Sonstiges Eigenkapital'
    },
    commonStock: {
        en: 'Common Stock',
        de: 'Stammaktien'
    },
    preferredStock: {
        en: 'Preferred Stock',
        de: 'Vorzugsaktien'
    },
    totalStockholdersEquity: {
        en: 'Total Stockholders Equity',
        de: 'Eigenkapital'
    },
    totalLiabilitiesAndStockholdersEquity: {
        en: 'Total Liabilities And Stockholders Equity',
        de: 'Passiva'
    },
    liabilitiesShareholdersEquity : {
        en: 'Liabilities Shareholders Equity',
        de: 'Passiva'
    },
    totalDebt: {
        en: 'Total Debt',
        de: 'Gesamtverschuldung'
    },
    netDebt: {
        en: 'Net Debt',
        de: 'Nettoverschuldung'
    },
};


// Function to get the string based on property and language
export function getTranslation(propertyName, language) {
  //console.log('getTranslation', propertyName, language);

  if (translations[propertyName] && translations[propertyName][language]) {
      //console.log(translations[propertyName][language]);
      return translations[propertyName][language];
  } else {
    console.log('Translation not found for the given property and language', propertyName, language);
  }
}



/* eslint-enable */


