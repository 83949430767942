/* eslint-disable */
<script>

  import { formatUnitOfAccount, 
    toUnitOfAccount, 
    getInterpolatedColor,
  } from '../helper';

  import CompanyValuationDCFFreeCashflowInfo from '@/components/company-valuation-dcf-free-cashflow-info.vue';

  import { Colors} from '../colors';
  import { sessionManager } from '@/main';
 
  export default {
    name: 'CompanyValuationDCFFreeCashflow',
    props: {
      valuation: {
        type: Object,
        required: true,
        default: () => null,
      },
      unitOfAccount: {
        type: String,
        required: true,
        default: () => 'USD',
      },
      marketCap: {
        type: Object,
        required: true,
        default: () => null,
      },
      logo: {
        type: Boolean,
        default: () => false
      },
      logoText: {
        type: String,
        default: () => ''
      },
    },
    components: {
      CompanyValuationDCFFreeCashflowInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Discounted Cashflow (DCF)',      
            subtitle: 'The standard method for valuing a company',
            free_cash_flow: 'Free Cashflow (TTM)',   
            growth: 'Growth Rate',
            discount: 'Discount Rate',
            number_of_years: 'Number of Years',
            dcfValuation: 'Sum of discounted Cashflows',
            net_debt: 'Net Debt',
            net_cash: 'Net Cash',
            valuation: 'Valuation',
            valuation_ratio: 'Price / Valuation',
            years: 'years',
            help: 'More information'
          },
          de: {
            title: 'Diskontierter Cashflow (DCF)',
            subtitle: 'Die Standardmethode zur Bewertung eines Unternehmens',
            free_cash_flow: 'Freier Cashflow (letzte 12 Monate)',
            growth: 'Wachstumsrate',
            discount: 'Diskontierungsrate',         
            number_of_years: 'Anzahl Jahre',   
            dcfValuation: 'Summe der diskontierten Cashflows',
            net_debt: 'Nettoverschuldung',
            net_cash: 'Nettobarvermögen',
            valuation: 'Bewertung',
            valuation_ratio: 'Preis / Bewertung',
            years: 'Jahre',
            help: 'Mehr Informationen'
          }
        },
        language: '',
        showPopup: false,
        expanded: true,
        growthRate: 0,
        discountRate: 0,
        numberOfYearsSliderValue: 1,
        fontSize: '14px',
        growthRateSet: false,
        discountRateSet: false,
        numYearsOptions: [
          '5',
          '10',
          '15',
          '20',
          '50',
          '100',
        ],	
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    methods: {
      closePopup() {
        this.showPopup = false;
      },
      onSetGrowthRate() {
        this.growthRateSet = true;
      },
      onSetDiscountRate() {
        this.discountRateSet = true;
      },
    },
    mounted() {
      this.growthRate = 100.0 * this.valuation.estimatedGrowthRate;
      this.discountRate = 100.0 * this.valuation.discountRate;
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },      
      freeCashflowFormatted()
      {
        return formatUnitOfAccount(this.valuation.freeCashflow, this.unitOfAccount);
      },
      netDebtFormatted()
      {
        return formatUnitOfAccount(this.valuation.netDebt, this.unitOfAccount);
      },
      netCashFormatted()
      {
        return formatUnitOfAccount(-this.valuation.netDebt, this.unitOfAccount);
      },
      dcfValuation()
      {
        let growth = 100.0 * this.valuation.estimatedGrowthRate;
        let discount = 100.0 * this.valuation.discountRate;

        if(this.growthRateSet)
        {
          growth = this.growthRate;
        }
        if(this.discountRateSet)
        {
          discount = this.discountRate;
        }

        let numberOfYears = this.numYearsOptions[this.numberOfYearsSliderValue];

        let valuation = 0.0;
        if(this.valuation.freeCashflow > 0)
        {
          for(let i = 0; i < numberOfYears; i++)
          {
            // cashflow of year i
            let cashflow = this.valuation.freeCashflow * Math.pow((1.0 + growth / 100.0), i);

            // discount factor
            let discountFactor = Math.pow((1.0 + discount / 100.0), i);

            // discounted cashflow
            let discountedCashflow = cashflow / discountFactor;

            // add to the valuation
            valuation += discountedCashflow;
          }
        }
        return valuation;
      },
      dcfValuationFormatted()
      {
        return formatUnitOfAccount(this.dcfValuation, this.unitOfAccount);
      },
      companyValuation()
      {
        return this.dcfValuation - this.valuation.netDebt;
      },
      companyValuationFormatted()
      {
        return formatUnitOfAccount(this.companyValuation, this.unitOfAccount);
      },
      companyValuationRatioFormatted()
      {
        if(this.companyValuation < 0)
        {
          return '-';
        }
        var vr = toUnitOfAccount(this.marketCap, this.unitOfAccount) / this.companyValuation;
        return vr.toFixed(1);
      },
      companyValuationColor()
      {
        const value = this.companyValuation;

        var price = toUnitOfAccount(this.marketCap, this.unitOfAccount);

        // relative valuation
        if(price > 0)
        {
          var ratio = value / price;
          if(value < 0)
          {
            ratio = 0;
          }
          let veryBad = 0.25;
          let bad = 0.4;
          let slightlyBad = 0.6;
          let neutral = 0.75;
          let slightlyGood = 1;
          let good = 1.25;
          let veryGood = 2;

          let colorNeutral = Colors.White;
          return getInterpolatedColor(ratio, veryBad, bad, slightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral);
        }
        else
        {
          return Colors.White;
        }     
      }
    }
  }
</script>
  
  
<template>
  <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <div class="flex">
      <div class="flex-auto">
        <h3 v-if="logo" class="darkest font-pj text-v-xl text-bold">{{ logoText }}</h3>
        <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">{{strings['title']}}</h3>
      </div>
      <div class="flex-none" v-if="!logo">
        <p style="text-align:right;">
          <span class="px-v-sm">
            <v-tooltip bottom color="primary" light>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="showPopup = true">mdi-help-circle-outline</v-icon>
              </template>
              <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
            </v-tooltip>
          </span>
        </p>
      </div>
    </div>
    <p class="darkest font-pj text-v-tiny py-v-sm" @click="showPopup = true">{{ strings['subtitle'] }}</p>

    <v-simple-table v-if="valuation" dense>
      <template v-slot:default>
        <tbody>
          <tr v-if="expanded">
            <td class="darkest font-pj text-v-base pl-0">{{strings['free_cash_flow']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right">{{ freeCashflowFormatted }}</td>
          </tr>
          <tr v-if="expanded">
            <td class="darkest font-pj text-v-base pl-0" colspan="2">{{strings['growth']}}
            <v-slider max="50" min="-25" v-model="growthRate" @change="onSetGrowthRate">
              <template v-slot:append>
                <v-text-field
                  v-model="growthRate"
                  hide-spin-buttons
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  suffix="%"
                  style="width: 55px"
                  v-bind:style="{ 'font-size': fontSize }"
                ></v-text-field>
              </template>
            </v-slider>
          </td>
          <tr v-if="expanded">
            <td class="darkest font-pj text-v-base pl-0" colspan="2">{{strings['discount']}}
              <v-slider max="50" min="0" v-model="discountRate" @change="onSetDiscountRate">
                <template v-slot:append>
                  <v-text-field
                    v-model="discountRate"
                    hide-spin-buttons
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    suffix="%"
                    style="width: 55px"
                    v-bind:style="{ 'font-size': fontSize }"
                  ></v-text-field>
                </template>
              </v-slider>
            </td>
          </tr>
          <tr v-if="expanded">
            <td class="darkest font-pj text-v-base pl-0" colspan="2">{{strings['number_of_years']}}
              <v-slider :max="numYearsOptions.length-1" min="0" step="1" v-model="numberOfYearsSliderValue">
                <template v-slot:append>
                  <v-text-field
                    v-model="numYearsOptions[numberOfYearsSliderValue]"
                    hide-spin-buttons
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 55px"
                    v-bind:style="{ 'font-size': fontSize }"
                  ></v-text-field>
                </template>
              </v-slider>
            </td>
          </tr>
          <tr v-if="expanded">
            <td class="darkest font-pj text-v-base pl-0">{{strings['dcfValuation']}} ({{numYearsOptions[numberOfYearsSliderValue]}} {{ strings['years'] }})</td>
            <td class="darkest font-pj text-v-base pl-0" align="right">{{ dcfValuationFormatted }}</td>
          </tr>
          <tr v-if="expanded && valuation.netDebt != 0">
            <td class="darkest font-pj text-v-base pl-0" v-if="valuation.netDebt > 0">{{strings['net_debt']}}</td>
            <td class="darkest font-pj text-v-base pl-0" v-else>{{strings['net_cash']}}</td>
            <td class="darkest font-pj text-v-base pl-0" v-if="valuation.netDebt > 0" align="right">-{{ netDebtFormatted }}</td>
            <td class="darkest font-pj text-v-base pl-0" v-else align="right">{{ netCashFormatted }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['valuation']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': companyValuationColor}">{{ companyValuationFormatted }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['valuation_ratio']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': companyValuationColor}">{{ companyValuationRatioFormatted }}</td>
          </tr>              
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="logo">
      <div class="flex justify-end">
        <div class="flex-none text-v-base font-pj py-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none text-v-base font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
      </div>
    </div>
    <CompanyValuationDCFFreeCashflowInfo :onClose="closePopup" :showPopup="showPopup"/>
  </div>
</template>
/* eslint-enable */ 
  