/* eslint-disable */
<script>

import PopupSaveContainer from '@/components/popup-save-container.vue';
import SVGFullsize from '@/components/svg-fullsize.vue';
import html2canvas from 'html2canvas';
import { logUserEvent } from '../helper';
import { sessionManager } from '@/main';

export default {
  name: 'SVGSave',
  props: {
    svg_small: {
      type: String,
      required: true,
      default: () => null,
    },
    svg_big: {
      type: String,
      required: true,
      default: () => null,
    },
    svgId: {
      type: String,
      required: true,
      default: () => null,
    },
    companyName: {
      type: String,
      required: true,
      default: () => '',
    },
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    allowSave: {
      type: Boolean,
      default: () => true
    },
  },
  components: {
    SVGFullsize,
    PopupSaveContainer,
  },
  data() {
    return {
      showIcons: true,
      showLogo: false,
      showTitle: false,
      id: this.generateUniqueId(),
      languageStrings: {
        en: {
          download: 'Download',
        },
        de: {
          download: 'Herunterladen',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    generateUniqueId() {
      return 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now();
    },
    async onSave()
    {
      // hide icons
      this.showIcons = false;
      this.showLogo = true;
      this.showTitle = true;

      logUserEvent('saveGraphics', sessionManager, this.$vuetify.breakpoint.mobile, this.companyName + ' ' + this.title);

      // wait for the dom to update
      //await new Promise(resolve => requestAnimationFrame(resolve));
      await this.$nextTick();
      // capture the div
      await this.captureDivWithBorder(this.id, this.companyName + ' ' + this.title + '.png', 16);
      // change back to normal
      this.showIcons = true;
      this.showLogo = false;
      this.showTitle = false;
    },
    // capture an individual div with a border and download it as png
    async captureDivWithBorder(divId, filename, borderWidth) {
      const element = document.getElementById(divId);
      const canvas = await html2canvas(element);
      // Create a new canvas with additional size for the border
      const borderCanvas = document.createElement('canvas');
      borderCanvas.width = canvas.width + 2 * borderWidth;
      borderCanvas.height = canvas.height + 2 * borderWidth;
      const ctx = borderCanvas.getContext('2d');
      // Draw a white rectangle (border)
      ctx.fillStyle = '#FFFFFF'; // White color
      ctx.fillRect(0, 0, borderCanvas.width, borderCanvas.height);
      // Draw the original canvas onto the border canvas, offset by the border width
      ctx.drawImage(canvas, borderWidth, borderWidth);
      // Convert the new canvas to a data URL
      const image = borderCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      this.downloadImage(image, filename);
    },
    // capture an individual div and download it as png
    async captureDiv(divId, filename) {
      const element = document.getElementById(divId);
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      this.downloadImage(image, filename);
    },
    downloadImage(data, filename) {
      const link = document.createElement('a');
      link.href = data;
      link.download = filename;
      link.click();
    },
    calculateMaxWidth() {
      this.maxWidth = window.innerWidth - 32; 
    },
    calculateMaxHeight() {
      this.maxHeight = window.innerHeight - 32;
    },
  },
}
</script>


<template>
  <div>
    <div class="flex">
      <div class="flex-auto">
      </div>
      <div class="flex-none" v-if="showIcons">
        <p style="text-align:right;">
          <span v-if="allowSave" class="px-v-sm">
            <v-tooltip bottom color="primary" light>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="onSave()">mdi-download</v-icon>
              </template>
              <span class="darkest font-pj text-v-tiny">{{ strings['download'] }}</span>
            </v-tooltip>
          </span>
        </p>
      </div>
    </div>
      <div class="max-h-[1600px] mx-auto" >
        <div :id="id">  
        <h3 v-if="showTitle" class="darkest font-pj text-v-xl text-bold">
          {{ companyName }} {{ title }} 
        </h3>
        <div class="pt-v-md">
          <SVGFullsize :svg="$vuetify.breakpoint.lgAndDown ? svg_small : svg_big" :svgId="svgId"/>
        </div>
        <div v-if="showLogo">
          <div class="flex justify-end">
            <div class="flex-none text-v-base font-pj pt-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
            </div>
            <div class="flex-none text-v-base font-pj pt-v-md pl-v-sm"><b>value</b>tion</div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

/* eslint-enable */ 