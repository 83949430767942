/* eslint-disable */
<script>
import { formatPercent, 
  formatUnitOfAccount, 
  getInterpolatedColor, 
  getColorDividendYield, 
  getColorShareBuybackYield,
  getColorDividendYieldSupportedByFreeCashflow } from '../helper';
import { sessionManager } from '@/main';
import { Colors } from '../colors.js';

import CompanyManagementInfo from '@/components/company-management-info.vue';


export default {
  name: 'CompanyManagement',
  props: {
    managementData: {
      required: true,
      default: () => null,
    },
    unitOfAccount: {
      required: true,
      default: () => 'USD',
    },
    logo: {
      type: Boolean,
      default: () => false
    },
    logoText: {
      type: String,
      default: () => ''
    },
  },
  components: {
    CompanyManagementInfo,
  },
  data() {
    return {
      textAlign: 'center',
      whiteSpace: 'nowrap',
      padding: '2px',
      languageStrings: {
        en: {
          title: 'Management Quality',
          roe: 'Return on Equity (ROE)',
          roic: 'Return on Invested Capital (ROIC)',
          roiic: 'Return on Incremental Invested Capital (ROIIC)',
          net_cash: 'Net Cash',
          net_debt: 'Net Debt',
          in_years: 'in years of operating cash flow',
          years: 'years',
          dividends: 'Dividends',
          dividends_ttm: 'Dividends TTM',
          dividends_yield: 'Dividends Yield TTM',
          sharebuybacks: 'Share Buybacks',
          sharebuybacks_ttm: 'Share Buybacks TTM',
          sharebuybacks_yeild: 'Share Buybacks Yield TTM',
          payback_ttm: 'Dividends & Share Buybacks TTM',
          payback_yield: 'Dividends & Share Buybacks Yield TTM',
          free_cash_flow: 'Free Cash Flow',
          covered_by_free_cash_flow: 'Relative to Free Cash Flow',
          covered_by: 'Relative to',
          free_cash_flow_2: 'Free Cash Flow',
          payback: 'Payback to Shareholders',
          debt: 'Debt',
          cash: 'Cash',
          return: 'Return',
          amount: 'Amount',
          percentage: 'Percent',
          help: 'More information'
        },
        de: {
          title: 'Managementqualität',
          roe: 'Eigenkapitalrendite',
          roic: 'Kapitalrendite',
          roiic: 'Rendite auf das inkrementelle investierte Kapital',
          net_cash: 'Netto Barmittel',
          net_debt: 'Nettoverschuldung',
          in_years: 'in Jahren des operativen Cashflows',
          years: 'Jahre',
          dividends: 'Dividenden',
          dividends_ttm: 'Dividenden TTM',
          dividends_yield: 'Dividendenrendite TTM',
          sharebuybacks: 'Aktienrückkäufe',
          sharebuybacks_ttm: 'Aktienrückkäufe TTM',
          sharebuybacks_yeild: 'Aktienrückkäufe Rendite TTM',
          payback_ttm: 'Dividenden & Aktienrückkäufen TTM',
          payback_yield: 'Dividenden & Aktienrückkäufen Rendite TTM',
          free_cash_flow: 'Freier Cashflow',
          covered_by_free_cash_flow: 'Relativ zum freien Cashflow',
          covered_by: 'Relativ zum',
          free_cash_flow_2: 'freien Cashflow',
          payback: 'Rückzahlungen an die Aktionäre',
          debt: 'Verschuldung',
          cash: 'Barmittel',
          return: 'Rendite',
          amount: 'Betrag',
          percentage: 'Prozent',
          help: 'Mehr Informationen'
        }
      },
      language: '',
      showPopup: false,
      canAccess: false,
    };
  },
  created() {
    this.language = sessionManager.read('language');
    this.canAccess = this.managementData.canAccess;
  }, 
  methods: {
    getBackgroundColor(growth) {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return getInterpolatedColor(growth, -0.2, -0.1, -0.05, 0, 0.05, 0.1, 0.2);
    },
    getBackgroundColorDebt(yearsOfCashFlow) {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      // 0-1 is great, 1-2 is good, 3+ is neutral and 5+ is bad and 10+ is really bad
      return getInterpolatedColor(-yearsOfCashFlow, -10, -6, -4, -3, -2, -1, -0.5);
    },
    closePopup() {
        this.showPopup = false;
      },
    gotoPremium()
    {
      //  we only forward to premium if the user can not access
      if(!this.canAccess)
      {
        const url = this.$router.resolve({ path: '/premium' }).href;
        window.open(url, '_blank');  
      }
    },
  },
  computed: {
    strings() {
          return this.languageStrings[this.language];
    },
    year1Short() {
      return this.managementData.year1-2000;
    },
    year1() {
      return this.managementData.year1;
    },
    year3() {
      return this.managementData.year3;
    },
    year5() {
      return this.managementData.year5;
    },
    year10() {
      return this.managementData.year10;
    },
    // return on equity
    ROE10Formatted() {
      return formatPercent(this.managementData.ROE10, 0);
    },
    ROE10Formatted_color() {
      return this.getBackgroundColor(this.managementData.ROE10);
    },
    ROE5Formatted() {
      return formatPercent(this.managementData.ROE5, 0);
    },
    ROE5Formatted_color() {
      return this.getBackgroundColor(this.managementData.ROE5);
    },
    ROE3Formatted() {
      return formatPercent(this.managementData.ROE3);
    },
    ROE3Formatted_color() {
      return this.getBackgroundColor(this.managementData.ROE3);
    },
    ROETTMFormatted() {
      return formatPercent(this.managementData.ROETTM, 0);
    },
    ROETTMFormatted_color() {
      return this.getBackgroundColor(this.managementData.ROETTM);
    },
    // return on invested capital
    ROIC10Formatted() {
      return formatPercent(this.managementData.ROIC10, 0);
    },
    ROIC10Formatted_color() {
      return this.getBackgroundColor(this.managementData.ROIC10);
    },
    ROIC5Formatted() {
      return formatPercent(this.managementData.ROIC5, 0);
    },
    ROIC5Formatted_color() {
      return this.getBackgroundColor(this.managementData.ROIC5);
    },
    ROIC3Formatted() {
      return formatPercent(this.managementData.ROIC3, 0);
    },
    ROIC3Formatted_color() {
      return this.getBackgroundColor(this.managementData.ROIC3);
    },
    ROICTTMFormatted() {
      return formatPercent(this.managementData.ROICTTM, 0);
    },    
    ROICTTMFormatted_color() {
      return this.getBackgroundColor(this.managementData.ROICTTM);
    },  
    // return on incremental invested capital
    ROIIC10Formatted() {
      if(!this.managementData.hasROIIC10)
      {
        return '-'; 
      }
      return formatPercent(this.managementData.ROIIC10, 0);
    },
    ROIIC10Formatted_color() {
      return this.getBackgroundColor(this.managementData.ROIIC10);
    },
    ROIIC5Formatted() {
      if(!this.managementData.hasROIIC5)
      {
        return '-'; 
      }
      return formatPercent(this.managementData.ROIIC5, 0);
    },
    ROIIC5Formatted_color() {
      return this.getBackgroundColor(this.managementData.ROIIC5);
    },
    ROIIC3Formatted() {
      if(!this.managementData.hasROIIC3)
      {
        return '-'; 
      }
      return formatPercent(this.managementData.ROIIC3, 0);
    },
    ROIIC3Formatted_color() {
      return this.getBackgroundColor(this.managementData.ROIIC3);
    },
    ROIICTTMFormatted() {
      if(!this.managementData.hasROIICTTM)
      {
        return '-'; 
      }
      return formatPercent(this.managementData.ROIICTTM, 0);
    },    
    ROIICTTMFormatted_color() {
      return this.getBackgroundColor(this.managementData.ROIICTTM);
    },  
    netCashFormatted() {
      return formatUnitOfAccount(-this.managementData.netDebt, this.unitOfAccount, true);
    },
    netDebtFormatted() {
      return formatUnitOfAccount(this.managementData.netDebt, this.unitOfAccount, true);
    },
    netDebtInYearsOfOperatingCashFlowFormatted() {
      return this.managementData.netDebtInYearsOfOperatingCashFlow.toFixed(2);
    },
    netDebtInYearsOfOperatingCashFlowFormatted_color() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return this.getBackgroundColorDebt(this.managementData.netDebtInYearsOfOperatingCashFlow);
    },
    // dividends
    //---------------------------------------------------------------------------------------------------------------------
    dividendsTTMFormatted() {
      return formatUnitOfAccount(this.managementData.dividendsTTM, this.unitOfAccount, true);
    },
    dividendsTTMYieldFormatted() {
      return formatPercent(this.managementData.dividendsTTMYield, 1);
    },
    dividendsTTMYieldFormattedColor()
    {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return getColorDividendYield(this.managementData.dividendsTTMYield);
    },
    dividendsTTMSupportedByFreeCashflowFormatted() {
      if(this.managementData.dividendsTTMCoveredByFreeCashflow < 0)
      {
        return '-';
      }
      else
      {
        return formatPercent(this.managementData.dividendsTTMCoveredByFreeCashflow);
      }
    },
    dividendsTTMSupportedByFreeCashflowFormattedColor() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return getColorDividendYieldSupportedByFreeCashflow(this.managementData.dividendsTTMCoveredByFreeCashflow);
    },
    // share buybacks
    //---------------------------------------------------------------------------------------------------------------------
    shareBuybacksTTMFormatted() {
      return formatUnitOfAccount(this.managementData.shareBuybacksTTM, this.unitOfAccount, true);
    },
    shareBuybacksYieldTTMFormatted() {
      return formatPercent(this.managementData.shareBuybacksTTMYield, 1);
    },
    shareBuybacksTTMYieldFormattedColor()
    {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return getColorDividendYield(this.managementData.shareBuybacksTTMYield);
    },
    shareBuybacksTTMSupportedByFreeCashflowFormatted() {
      if(this.managementData.shareBuybacksTTMCoveredByFreeCashflow < 0)
      {
        return '-';
      }
      else
      {
        return formatPercent(this.managementData.shareBuybacksTTMCoveredByFreeCashflow);
      }
    },
    shareBuybacksTTMSupportedByFreeCashflowFormattedColor() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return getColorDividendYieldSupportedByFreeCashflow(this.managementData.shareBuybacksTTMCoveredByFreeCashflow);
    },
    
    // payback
    //---------------------------------------------------------------------------------------------------------------------
    paybackTTMFormatted() {
      return formatUnitOfAccount(this.managementData.paybackTTM, this.unitOfAccount, true);
    },
    paybackTTMYieldFormatted() {
      return formatPercent(this.managementData.paybackTTMYield, 1);
    },
    paybackTTMYieldFormattedColor()
    {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return getColorDividendYield(this.managementData.paybackTTMYield);
    },
    paybackTTMSupportedByFreeCashflowFormatted() {
      if(this.managementData.paybackTTMCoveredByFreeCashflow < 0)
      {
        return '-';
      }
      else
      {
        return formatPercent(this.managementData.paybackTTMCoveredByFreeCashflow);
      }
    },
    paybackTTMSupportedByFreeCashflowFormattedColor() {
      if(!this.canAccess)
      {
        return Colors.LightGray;
      }
      return getColorDividendYieldSupportedByFreeCashflow(this.managementData.paybackTTMCoveredByFreeCashflow);
    }
  }
}
</script>

<style scoped>
  
  .componentroot {
    filter: blur(5px);
    cursor: pointer;
  }

</style>

<template>
  <div v-if="managementData" :class="[{ componentroot: !canAccess }]" @click="gotoPremium()">
    <div class="flex">
      <div class="flex-auto">
        <h3 v-if="logoText != ''" class="darkest font-pj text-v-xl text-bold">{{ logoText }}</h3>
        <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">{{strings['title']}}</h3>
      </div>
      <div class="flex-none" v-if="!logo">
        <p style="text-align:right;">
          <span class="px-v-sm">
            <v-tooltip bottom color="primary" light>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="showPopup = true">mdi-help-circle-outline</v-icon>
              </template>
              <span class="darkest font-pj text-v-tiny">{{ strings['help'] }}</span>
            </v-tooltip>
          </span>
        </p>
      </div>
    </div>

    <h3 class="darkest font-pj text-v-base text-bold"><span v-if="logo">{{ logoText }} : </span>{{strings['return']}}</h3>
    <v-simple-table dense>
      <thead>
        <tr>
          <th></th>
          <th class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">Ø 10 {{strings['years']}}<br>{{ year10 }}-{{ year1Short }}</br></th>
          <th class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">Ø 5 {{strings['years']}}<br>{{ year5 }}-{{ year1Short }}</th>
          <th class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">Ø 3 {{strings['years']}}<br>{{ year3 }}-{{ year1Short }}</th>
          <th class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">TTM</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="darkest font-pj text-v-base pl-0">{{strings['roe']}}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROE10Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROE10Formatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROE5Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROE5Formatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROE3Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROE3Formatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROETTMFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROETTMFormatted }}</td>
        </tr>
        <tr>
          <td class="darkest font-pj text-v-base pl-0">{{strings['roic']}}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROIC10Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROIC10Formatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROIC5Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROIC5Formatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROIC3Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROIC3Formatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROICTTMFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROICTTMFormatted }}</td>
        </tr>
        <tr>
          <td class="darkest font-pj text-v-base pl-0">{{strings['roiic']}}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROIIC10Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROIIC10Formatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROIIC5Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROIIC5Formatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROIIC3Formatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROIIC3Formatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': ROIICTTMFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ ROIICTTMFormatted }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <h3 class="darkest font-pj text-v-base text-bold pt-v-xl"><span v-if="logo">{{ logoText }} : </span>
      <span v-if="this.managementData.netDebt <= 0">{{strings['cash']}}</span>
      <span v-else>{{strings['debt']}}</span>
    </h3>
    <v-simple-table dense>
      <tbody>
        <tr v-if="this.managementData.netDebt <= 0">
          <td class="darkest font-pj text-v-base pl-0">{{strings['net_cash']}}</td>
          <td class="darkest font-pj text-v-base pl-0">{{ netCashFormatted }}</td>
        </tr>
        <tr v-if="this.managementData.netDebt > 0">
          <td class="darkest font-pj text-v-base pl-0">{{strings['net_debt']}}</td>
          <td class="darkest font-pj text-v-base pl-0">{{ netDebtFormatted }}</td>
        </tr>
        <tr v-if="this.managementData.netDebt > 0">
          <td class="darkest font-pj text-v-base pl-0">{{strings['in_years']}}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': netDebtInYearsOfOperatingCashFlowFormatted_color, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ netDebtInYearsOfOperatingCashFlowFormatted }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <h3 class="darkest font-pj text-v-base text-bold pt-v-xl"><span v-if="logo">{{ logoText }} : </span>{{strings['payback']}}</h3>
    <v-simple-table dense>
      <tbody>
        <tr>
          <th></th>
          <th class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{strings['amount']}}</th>
          <th class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{strings['percentage']}}</th>
          <th class="darkest font-pj text-v-base pl-0" :style="{ 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{strings['covered_by']}} <br>{{ strings['free_cash_flow_2'] }}</th>
        </tr>
        <tr>
          <td class="darkest font-pj text-v-base pl-0">{{strings['dividends_ttm']}}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': dividendsTTMYieldFormattedColor, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ dividendsTTMFormatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': dividendsTTMYieldFormattedColor, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ dividendsTTMYieldFormatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': dividendsTTMSupportedByFreeCashflowFormattedColor, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ dividendsTTMSupportedByFreeCashflowFormatted }}</td>
        </tr>
        <tr>
          <td class="darkest font-pj text-v-base pl-0">{{strings['sharebuybacks_ttm']}}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': shareBuybacksTTMYieldFormattedColor, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ shareBuybacksTTMFormatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': shareBuybacksTTMYieldFormattedColor, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ shareBuybacksYieldTTMFormatted }}</td>      
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': shareBuybacksTTMSupportedByFreeCashflowFormattedColor, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ shareBuybacksTTMSupportedByFreeCashflowFormatted }}</td>
   
        </tr>
        <tr>
          <td class="darkest font-pj text-v-base pl-0">{{strings['payback_ttm']}}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': paybackTTMYieldFormattedColor, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ paybackTTMFormatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': paybackTTMYieldFormattedColor, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ paybackTTMYieldFormatted }}</td>
          <td class="darkest font-pj text-v-base pl-0" :style="{ 'background-color': paybackTTMSupportedByFreeCashflowFormattedColor, 'text-align': textAlign, 'white-space': whiteSpace, 'padding': padding }">{{ paybackTTMSupportedByFreeCashflowFormatted }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <div v-if="logo">
      <div class="flex justify-end">
        <div class="flex-none text-v-base font-pj py-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none text-v-base font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
      </div>
    </div>
    <CompanyManagementInfo :onClose="closePopup" :showPopup="showPopup"/>
  </div>
</template>

/* eslint-enable */ 