/* eslint-disable */
<script>

import { createIncomeStatementGraph } from '../income-graph';
import { Colors } from '../colors';
import { sessionManager } from '@/main';
import SVGSave from '@/components/svg-save.vue';
import { updateCompanyFinancialsGraph } from './company-financials-helper';

  export default {
    name: 'CompanyFinancialsIncomeGraph',
    props: {
      income: {
        type: Object,
        required: true,
        default: () => null,
      },
      unitOfAccount: {
        type: String,
        required: true,
        default: () => 'USD',
      },
      companyName: {
        type: String,
        required: true,
        default: () => '',
      },
      exchangeShortName: {
        type: String,
        required: true,
        default: () => '',
      },
      symbol: {
        type: String,
        required: true,
        default: () => '',
      },
      logo: {
        type: Boolean,
        default: () => false
      },
      companyName: {
        type: String,
        default: () => ''
      },
      navigation: {
        type: Boolean,
        default: () => true
      },
      allowSave: {
        type: Boolean,
        default: () => true
      },  
    },
    components: {
      SVGSave
    },
    data() {
      return {
        languageStrings: {
          en: {
            income: 'Income Statement',
            ttm: 'TTM',   
          },
          de: {
            income: 'Gewinn- und Verlustrechnung',
            ttm: 'letzte 12 Monate',
          }
        },
        date: null,
        warningColor: Colors.RedOrange,
        language: '',
        tabData: null,
        tabs: null,
        maxWidth: 1600,
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    methods: {
      isFH() {
        return this.income.type == "FHNormal" || this.income.type == "FHInsurance" || this.income.type == "FHBank";
      },
      updateGraph() {
        this.tabData = updateCompanyFinancialsGraph({
          dataQuarter: this.income.incomeQuarter,
          dataTTM: this.income.incomeTTM,
          dataAnnual: this.income.incomeAnnual,
          graphName: 'Income',
          graphType: this.income.type,
          hasTTM: this.income.hasTTM,
          createGraphFunction: createIncomeStatementGraph,
          fontSizeBase: 0.85,
          isFH: this.isFH(),
          strings: this.strings,
          unitOfAccount: this.unitOfAccount,
          language: this.language,
        });
      },
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    },
    beforeMount() {
      this.updateGraph();
    }
  }
</script>

<style scoped>
.v-tab {
    text-transform: none !important;
    font-size: 15px;
    font-family: Plus Jakarta Sans;
    color: #3f4d29;
    letter-spacing: 0em;
  }
</style>

  
<template>
  <div v-if="income">
    <h3 class="darkest font-pj text-v-xl text-bold py-v-sm">{{strings['income']}}</h3> 
    <v-tabs v-model="tabs" grow show-arrows>
      <v-tab v-for="entry in tabData" :key="entry.key">{{ entry.title }}</v-tab>
      <v-tabs-items v-model="tabs" touchless>
        <v-tab-item v-for="entry in tabData" :key="entry.key" transition="false" reverse-trasition="false">  
          <SVGSave :svg_small="entry.content_Small" :svg_big="entry.content_Big" :svgId="entry.key" :title="entry.save_title" :companyName="companyName" :popup="false" :allowSave="allowSave"/>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>
/* eslint-enable */ 