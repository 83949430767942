
<script>
import { sessionManager } from '@/main';

export default {
  name: 'UserResetPassword',
  props: {
    email: {
      type: String,
      required: true,
      default: () => '',
    },
    code: {
      type: String,
      required: true,
      default: () => '',
    },    
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      valid: false,
      passwordRulesEN: [
        v => !!v || 'Password is required',
        v => v.length >= 6 || 'Password must be at least 6 characters.'
      ],
      passwordRulesDE: [
        v => !!v || 'Passwort ist erforderlich',
        v => v.length >= 6 || 'Password muss mindestens 6 Zeichen lang sein.'
      ],
      passwordConfirmationRules_en: [
        v => !!v || 'Password confirmation is required',
        v => v === this.password || 'Password confirmation must be equal to the password.'
      ],
      passwordConfirmationRules_de: [
        v => !!v || 'Bitte bestätige Dein Passwort',
        v => v === this.password || 'Deine Passwortbestätigung muss mit dem Passwort übereinstimmen.'
      ],
      languageStrings: {
        en: {
          title: 'Change Password',
          headline: 'Set new password',
          password: 'Password',
          submit: 'Change Password',
          password_confirmation: 'Password confirmation',
          text: 'Please enter your new password.',
          headline_success: 'Password changed!',
          text_success: 'Your password has been successfully changed.',
          text_success_2: 'You can now login with your new password.',
          login: 'Login',          
        },
        de: {
          title: 'Passwort ändern',
          headline: 'Neues Passwort festlegen',
          password: 'Passwort',
          submit: 'Password ändern',
          password_confirmation: 'Passwort bestätigen',
          text: 'Bitte gib Dein neues Passwort ein.',
          headline_success: 'Passwort geändert!',
          text_success: 'Dein Passwort wurde erfolgreich geändert.',
          text_success_2: 'Du kannst Dich jetzt mit Deinem neuen Passwort anmelden.',
          login: 'Anmelden',
        }
      },
      language: '',
      passwordChanged: false,
    };
  },
  created() {
    this.language = sessionManager.read('language');
    if (this.language === 'en') {
      this.passwordRules = this.passwordRulesEN;
      this.passwordConfirmationRules = this.passwordConfirmationRules_en;
    } else {
      this.passwordRules = this.passwordRulesDE;
      this.passwordConfirmationRules = this.passwordConfirmationRules_de;
    }
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    async submitForm() {
      // send email and password to the API to login the user
      const url = '/api/HttpTriggerResetPassword';
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email, 
          password: this.password, 
          code: this.code,          
        }),
      }).then((response, reject) => {
        if (response.status === 200) {
          this.passwordChanged = true;
        } else {
          // push to the error page
          this.$router.push({ name: 'error' });
        }
      });        
    },
  }, 
}
</script>

<style scoped>
    .v-input {
      font-size: 15x;
      font-family: Plus Jakarta Sans;
      border: 0px !important;
      border: none; 
      border-radius: 8px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <div v-if="passwordChanged">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline_success']}}</h3>
          <div class="text-v-sm darkest font-pj pb-v-md">{{strings['text_success']}}</div>
          <div class="text-v-sm darkest font-pj pb-v-md">{{strings['text_success_2']}}</div>
          <div class="flex justify-center pt-v-md">
            <button type="submit" @click="$router.push({ name: 'login', params: { email: email }})" 
                class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light"
                >{{ strings['login'] }}
            </button>
          </div>
        </div>  
        <div v-else>
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
          <div class="text-v-sm darkest font-pj pb-v-md">{{strings['text']}}</div>

          <v-form ref="form" v-model="valid" @submit.prevent="submitForm" class="pb-v-sm">
            <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['password']}}</div>
              <v-text-field
                :label="strings['password']"
                v-model="password"
                :rules="passwordRules"
                outlined
                type="password"
              />
              <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['password_confirmation']}}</div>
              <v-text-field
                :label="strings['password_confirmation']"
                v-model="passwordConfirmation"
                :rules="passwordConfirmationRules"
                outlined
                type="password"
              />
              <button type="submit" :disabled="!valid" 
                class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light disabled:bg-gray disabled:border-gray disabled:text-dark disabled:cursor-not-allowed"
                >{{ strings['submit'] }}</button>
            </v-form>
        </div>
      </div>      
    </div>
  </div>
</template>


