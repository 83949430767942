
<script>
import { sessionManager } from '@/main';
import { logUserEvent, registerUser } from '../helper';
const bcrypt = require('bcryptjs');
import {
  hasWaitlist,
} from '../settings';

export default {
  name: 'UserRegistration',
  props: {
    email: {
      type: String,
      required: false,
      default: () => '',
    },
    code: {
      type: String,
      required: false,
      default: () => '',
    },
    force: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      name: '',
      surname: '',
      nameRules_en: [
        v => !!v || 'Please provide your name.'
      ],
      nameRules_de: [
        v => !!v || 'Bitte gib Deinen Namen an.'
      ],
      surnameRules_en: [
        v => !!v || 'Please provide your surname.'
      ],
      surnameRules_de: [
        v => !!v || 'Bitte gib Deinen Nachnamen an.'
      ],
      emailRules_en: [
        v => !!v || 'Email is required',
        v => /.+@.+/.test(v) || 'Email must be valid.'
      ],
      emailRules_de: [
        v => !!v || 'Email notwendig',
        v => /.+@.+/.test(v) || 'Die Email muss gültig sein.'
      ],
      passwordRules_en: [
        v => !!v || 'Password is required',
        v => v.length >= 6 || 'Password must be at least 6 characters.'
      ],
      passwordRules_de: [
        v => !!v || 'Password notwendig',
        v => v.length >= 6 || 'Das Passwort muss mindestens 6 Zeichen lang sein.'
      ],
      passwordConfirmationRules_en: [
        v => !!v || 'Password confirmation is required',
        v => v === this.password || 'Password confirmation must be equal to the password.'
      ],
      passwordConfirmationRules_de: [
        v => !!v || 'Bitte bestätige Dein Passwort',
        v => v === this.password || 'Deine Passwortbestätigung muss mit dem Passwort übereinstimmen.'
      ],
      valid: false,
      codeAccepted: false,
      codeTested: false,
      noCode: false,
      acceptedPrivacy: false,
      acceptedTerms: false,
      showPassword: false,
      textTypePassword: 'password',
      showPasswordConfirmation: false,
      textTypePasswordConfirmation: 'password',
      languageStrings: {
        en: {
          title: 'Account registration',
          email: 'Email',
          name: 'Name',
          surname: 'Surname',
          password: 'Password',
          password_confirmation: 'Password confirmation',
          submit: 'Register',
          fineprint: 'By signing up, you agree to our ',
          fineprint_link_privacy: 'privacy policy',
          fineprint_link_terms: 'terms of service',
          fineprint_2: '.',
          no_code: 'Valuetion is not yet public.',
          headline_no_code: 'Valuetion is not yet public.',
          waitlist_1: 'Secure your access to the beta phase by signing up for the ',
          waitlist_link: 'waitlist',
          waitlist_2: '.',
          invalid_code: 'The code for registration is invalid.',
          headline_invalid_code: 'The code for registration is invalid.',
          welcome: ' We are thrilled to have you as a new user.',
          headline: 'Join the beta!',
          free: 'Don\'t worry: the beta phase is free.',
          headline_anonymous: 'Continue with an account and join the beta!',
          anonymous: 'To continue we kindly ask you to create an account. Don\'t worry: the beta phase is free.'
        },
        de: {
          title: 'Konto erstellen',
          email: 'Email',
          name: 'Name',
          surname: 'Nachname',
          password: 'Passwort',
          password_confirmation: 'Passwort bestätigen',
          submit: 'Registrieren',
          fineprint: 'Mit Deiner Anmeldung erklärst Du Dich mit unseren ',
          fineprint_link_privacy: 'Datenschutzbestimmungen',
          fineprint_link_terms: 'Allgemeinen Geschäftsbedingungen',
          fineprint_2: ' einverstanden.',
          no_code: 'Valuetion ist noch nicht öffentlich.',
          headline_no_code: 'Valuetion ist noch nicht öffentlich.',
          waitlist_1: ' Sichere Dir Zugang zur Beta-Phase indem Du Dich auf der ',
          waitlist_link: 'Warteliste',
          waitlist_2: ' anmeldest.',
          invalid_code: 'Der Code für die Anmeldung ist leider ungültig.',
          headline_invalid_code: 'Der Code für die Anmeldung ist leider ungültig.',
          welcome: ' Wir freuen uns, Dich als neuen Nutzer begrüßen zu dürfen.',
          headline: 'Für die Beta-Phase anmelden!',
          free: 'Keine Angst: Die Beta-Phase ist kostenlos.',
          headline_anonymous: 'Mit einem Konto fortfahren und für die Beta-Phase anmelden!',
          anonymous: 'Um Valuetion weiter zu verwenden bitten wir Dich ein Konto zu erstellen. Keine Angst: Die Beta-Phase ist kostenlos.'
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');

    // if the user is already logged in, redirect to the dashboard
    if(sessionManager.isLoggedin())
    {
      this.$router.push('/dashboard');
    }

    
    this.emailRules = this.language === 'en' ? this.emailRules_en : this.emailRules_de;
    this.passwordRules = this.language === 'en' ? this.passwordRules_en : this.passwordRules_de;
    this.passwordConfirmationRules = this.language === 'en' ? this.passwordConfirmationRules_en : this.passwordConfirmationRules_de;
    this.nameRules = this.language === 'en' ? this.nameRules_en : this.nameRules_de;
    this.surnameRules = this.language === 'en' ? this.surnameRules_en : this.surnameRules_de;

    this.noCode = this.code === '' || this.code === undefined || this.code === null || this.email === '' || this.email === undefined || this.email === null;
    if(!hasWaitlist()) {
      this.noCode = false;
      logUserEvent('registerBeta', sessionManager, this.$vuetify.breakpoint.mobile, 'beta');
    }
    else
    {
      logUserEvent('register', sessionManager, this.$vuetify.breakpoint.mobile, 'waitlist');
    }
    // check if the code is valid
    this.checkCode();
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
    privacyLink() {
      return this.$router.resolve({ name: 'privacy' }).href;
    },
    allowRegister() {
      return this.valid && this.acceptedPrivacy && this.acceptedTerms;
    }
  },
  methods: {
    async checkCode() {
      // check if the code is valid
      const url = '/api/HttpTriggerCheckRegistrationCode';
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: this.code,
          email: this.email,
        }),
      }).then((response) => {
        if (response.status === 200) {
          // code is valid
          this.codeAccepted = true;
          this.codeTested = true;
        }
        else {
          // code is invalid
          this.codeAccepted = false;
          this.codeTested = true;
        }
      });
    },
    async submitForm() {
      // send email and password to the API to register the user
      const hashedPassword = this.generateHashedPassword(this.password);

      // log the registration
      logUserEvent('submitRegisterBeta', sessionManager, this.$vuetify.breakpoint.mobile);
      sessionManager.write('email', this.email);

      // Register the user
      const url = '/api/HttpTriggerRegisterUser';
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email, 
          password: hashedPassword,
          name: this.name,
          surname: this.surname, 
          code: this.code,
          language: this.language,
        }),
      }).then((response) => {
        if (response.status === 200) {
                  
          // Redirect to the verification page
          this.$router.push({
            name: 'checkMailRegistration',
            params: {
              name: this.name,
              email: this.email,
            },
          });
        } else {
          // report error
          this.$router.push({
            name: 'error',
          });
        }
      });        
    },
    generateHashedPassword(password) {
      // generate the hashed password with bycrypt
      var salt = bcrypt.genSaltSync(10);
      var hashedPassword = bcrypt.hashSync(password, salt); 
      return hashedPassword;
    },
    navigateToPrivacy() {
      const route = this.$router.resolve({
        name: 'privacy',
      });
      window.open(route.href, '_blank');
    },
    navigateToTerms() {
      const route = this.$router.resolve({
        name: 'terms',
      });
      window.open(route.href, '_blank');
    }
  }, 
  
}
</script>
<style scoped>
    .v-input {
      font-size: 15x;
      font-family: Plus Jakarta Sans;
      border: 0px !important;
      border: none; 
      border-radius: 8px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <div v-if="noCode">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline_no_code']}}</h3> 
          <div class="text-v-sm darkest font-pj pb-v-xl" v-if="noCode">{{ strings['no_code'] }} {{  strings['waitlist_1'] }}<a href="/waitlist">{{ strings['waitlist_link'] }}</a>{{ strings['waitlist_2'] }}</div>
        </div>
        <div v-if="codeTested && !codeAccepted">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline_invalid_code']}}</h3> 
          <div class="text-v-sm darkest font-pj pb-v-xl">{{ strings['invalid_code'] }} {{  strings['waitlist_1'] }}<a href="/waitlist">{{ strings['waitlist_link'] }}</a>{{ strings['waitlist_2'] }}</div>
        </div>
        <div v-if="codeTested && codeAccepted">
          <span v-if="force">
            <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline_anonymous']}}</h3> 
          <div class="text-v-sm darkest font-pj pb-v-xl">{{ strings['anonymous'] }}</div>
          </span>
          <span v-else>
            <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
            <div class="text-v-sm darkest font-pj pb-v-md">{{strings['welcome']}} {{ strings['free'] }}</div>
          </span>

          <v-form ref="form" v-model="valid" @submit.prevent="submitForm" class="pb-v-sm">
            <v-text-field
              :label="strings['name']"
              v-model="name"
              :rules="nameRules"
            />
            <v-text-field
              :label="strings['surname']"
              v-model="surname"
              :rules="surnameRules"
            />
            <v-text-field
              :label="strings['email']"
              v-model="email"
              :rules="emailRules"
            />
            <div class="flex">
              <v-text-field
                :label="strings['password']"
                v-model="password"
                :rules="passwordRules"
                :type="textTypePassword"
              />
              <div class="pr-3"></div>
              <v-icon v-if="!showPassword" @click="showPassword = true; textTypePassword = 'text'">mdi-eye-off</v-icon>
              <v-icon v-if="showPassword" @click="showPassword = false; textTypePassword = 'password'">mdi-eye</v-icon>
            </div>
            <div class="flex">
              <v-text-field
                :label="strings['password_confirmation']"
                v-model="passwordConfirmation"
                :rules="passwordConfirmationRules"
                :type="textTypePasswordConfirmation"
              />
              <div class="pr-3"></div>
              <v-icon v-if="!showPasswordConfirmation" @click="showPasswordConfirmation = true; textTypePasswordConfirmation = 'text'">mdi-eye-off</v-icon>
              <v-icon v-if="showPasswordConfirmation" @click="showPasswordConfirmation = false; textTypePasswordConfirmation = 'password'">mdi-eye</v-icon>
            </div>
            
            <button type="submit" :disabled="!allowRegister" 
              class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light disabled:bg-gray disabled:border-gray disabled:text-dark disabled:cursor-not-allowed"
              >{{ strings['submit'] }}</button>
          </v-form>

          <v-checkbox class="text-v-md font-pj pb-0" v-model="acceptedPrivacy" dense>
            <template v-slot:label>
              <div class="flex flex-wrap">
                <span class="pr-1 flex-wrap">{{ strings['fineprint'] }}</span>
                <span class="text-primary flex-wrap" @click.prevent="navigateToPrivacy" style="cursor: pointer; text-decoration: underline;">{{ strings['fineprint_link_privacy'] }}</span>
                <span class="pl-1 flex-wrap">{{ strings['fineprint_2'] }}</span>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox class="text-v-md font-pj pt-0" v-model="acceptedTerms" dense>
            <template v-slot:label>
              <div class="flex flex-wrap">
                <span class="pr-1 flex-wrap">{{ strings['fineprint'] }}</span>
                <span class="text-primary flex-wrap" @click.prevent="navigateToTerms" style="cursor: pointer; text-decoration: underline;">{{ strings['fineprint_link_terms'] }}</span>
                <span class="pl-1 flex-wrap">{{ strings['fineprint_2'] }}</span>
              </div>
            </template> 
          </v-checkbox>
        </div>

      </div>
    </div>
  </div>
</template>


