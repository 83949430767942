
<script>
import { sessionManager } from '@/main';
import CompanyList from '@/components/company-list.vue';
import { logUserEvent } from '../helper';


export default {
  name: 'UserWatchlist',
  props: {
    unitOfAccount: {
      type: String,
      required: true,
      default: () => 'USD',
    },
  },
  components: {
    CompanyList,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Watchlist',
          headline: 'Companies you are following',
          text: 'The watchlist lets you keep track of the companies you are interested in.',
          text_2: 'You can add companies to your watchlist by clicking the follow button on the company overview page.',     
          text_3: 'The stock screener is a good starting point to look for undervalued stocks.',
          search: 'Go to Stock Screener', 
          empty: 'Your watchlist is empty.',
        },
        de: {
          title: 'Watchlist',
          headline: 'Unternehmen denen Du folgst',
          text: 'Die Watchlist ermöglicht es Dir, die Unternehmen, an denen Du interessiert bist, im Auge zu behalten.',
          text_2: 'Du kannst Unternehmen zu Deiner Watchlist hinzufügen, indem Du auf der Unternehmensübersichtsseite auf die Schaltfläche "Folgen" klickst.',
          text_3: 'Die Aktiensuche ist ein guter Startpunkt, um nach unterbewerteten Aktien zu suchen.',
          search: 'Zur Aktiensuche',
          empty: 'Deine Watchlist ist leer.',
        },
      },
      language: '',
      watchlist: null,
      loading: true,
    };
  },
  
  created() {
    this.language = sessionManager.read('language');
    this.getWatchlist();
    logUserEvent('watchlist', sessionManager, this.$vuetify.breakpoint.mobile);
  },
  computed: {
    strings() {
      return this.languageStrings[this.language];
    },
  },
  methods: {
    // get the companies on the watchlist
    async getWatchlist()
    {
      try {
        const url = '/api/HttpTriggerGetWatchlist';
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type' : 'application/json',
            'JWT' : sessionManager.read('token'),
          },
        });
        const data = await response.json();
        this.watchlist = data;
        this.loading = false;

      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-md' : 'px-v-xl'">
    <div>
      <div class="max-w-4xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h3>
        <div class="text-v-sm darkest font-pj pb-v-xl">{{ strings['text'] }}</div>
        <h3 class="darkest font-pj text-v-base text-bold pb-6">{{strings['headline']}}</h3>
        <div v-if="loading" class="flex justify-center p-v-xl">
          <div>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>
        <span v-if="watchlist == null || watchlist.length == 0">
          <div class="text-v-sm darkest font-pj pb-v-xl">{{ strings['empty'] }} {{ strings['text_2'] }} {{ strings['text_3'] }}</div>
          <div class="p-v-sm">
              <v-btn color="primary" @click="$router.push({ path: '/search'})">{{strings['search']}}</v-btn>
          </div>
        </span>
      </div>
    </div>
    <div>
      <span v-if="!(watchlist == null) && watchlist.length > 0">
        <CompanyList 
          v-bind:companies="watchlist" 
          v-bind:unitOfAccount="unitOfAccount"
          v-bind:serverSideSearch="false"></CompanyList>
      </span>
    </div>
  </div>
</template>


