/* eslint-disable */

/*---------------------------------------------------------------------------------------------------------/
/   gets the tree data for the countries
/---------------------------------------------------------------------------------------------------------*/
function getCountryTree() {
  const continentToCountries = {
    'Africa': ['DZ', 'AO', 'BJ', 'BW', 'BI', 'CM', 'CV', 'CF', 'TD', 'KM', 'CG', 'CI', 'DJ', 'EG', 'GQ', 'ER', 'SZ', 'ET', 'GA', 'GM', 'GH', 'GN', 'GW', 'KE', 'LS', 'LR', 'LY', 'MG', 'MW', 'ML', 'MR', 'MU', 'MA', 'MZ', 'NA', 'NE', 'NG', 'RE', 'RW', 'ST', 'SN', 'SC', 'SL', 'SO', 'ZA', 'SS', 'SH', 'SD', 'SZ', 'TZ', 'TG', 'TN', 'UG', 'CD', 'ZM', 'TZ', 'ZW'],
    'Asia': ['AF', 'AM', 'AZ', 'BH', 'BD', 'BT', 'BN', 'KH', 'CN', 'CY', 'GE', 'HK', 'IN', 'ID', 'IR', 'IQ', 'IL', 'JP', 'JO', 'KZ', 'KP', 'KR', 'KW', 'KG', 'LA', 'LB', 'MO', 'MY', 'MV', 'MN', 'MM', 'NP', 'OM', 'PK', 'PS', 'PH', 'QA', 'RU', 'SA', 'SG', 'LK', 'SY', 'TW', 'TJ', 'TH', 'TL', 'TR', 'TM', 'AE', 'UZ', 'VN', 'YE'],
    'Europe': ['AL', 'AD', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FO', 'FI', 'FR', 'DE', 'GI', 'GR', 'HU', 'IS', 'IE', 'IM', 'IT', 'XK', 'LV', 'LI', 'LT', 'LU', 'MK', 'MT', 'MD', 'MC', 'ME', 'NL', 'NO', 'PL', 'PT', 'RO', 'RU', 'SM', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'UA', 'GB', 'VA'],
    'North America': ['AG', 'BS', 'BB', 'BZ', 'CA', 'CR', 'CU', 'DM', 'DO', 'SV', 'GD', 'GT', 'HT', 'HN', 'JM', 'MX', 'NI', 'PA', 'KN', 'LC', 'VC', 'TT', 'US'],
    'Oceania': ['AS', 'AU', 'CK', 'FJ', 'PF', 'GU', 'KI', 'MH', 'FM', 'NR', 'NC', 'NZ', 'NU', 'NF', 'MP', 'PW', 'PG', 'PN', 'WS', 'SB', 'TK', 'TO', 'TV', 'VU', 'WF', 'WS'],
    'South America': ['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'GY', 'PY', 'PE', 'SR', 'UY', 'VE'],
  };

  const continentToContinentName = {
    'Africa': { name_en: 'Africa', name_de: 'Afrika' },
    'Asia': { name_en: 'Asia', name_de: 'Asien' },
    'Europe': { name_en: 'Europe', name_de: 'Europa' },
    'North America': { name_en: 'North America', name_de: 'Nordamerika' },
    'Oceania': { name_en: 'Oceania', name_de: 'Ozeanien' },
    'South America': { name_en: 'South America', name_de: 'Südamerika' },
  };

  const countryCodeToCountryName = {
      'AE': { name_en: 'United Arab Emirates', name_de: 'Vereinigte Arabische Emirate' },
      'AI': { name_en: 'Anguilla', name_de: 'Anguilla' },
      'AN': { name_en: 'Netherlands Antilles', name_de: 'Niederländische Antillen' },
      'AR': { name_en: 'Argentina', name_de: 'Argentinien' },
      'AT': { name_en: 'Austria', name_de: 'Österreich' },
      'AU': { name_en: 'Australia', name_de: 'Australien' },
      'AZ': { name_en: 'Azerbaijan', name_de: 'Aserbaidschan' },
      'BB': { name_en: 'Barbados', name_de: 'Barbados' },
      'BD': { name_en: 'Bangladesh', name_de: 'Bangladesch' },
      'BE': { name_en: 'Belgium', name_de: 'Belgien' },
      'BG': { name_en: 'Bulgaria', name_de: 'Bulgarien' },
      'BM': { name_en: 'Bermuda', name_de: 'Bermuda' },
      'BR': { name_en: 'Brazil', name_de: 'Brasilien' },
      'BS': { name_en: 'Bahamas', name_de: 'Bahamas' },
      'BW': { name_en: 'Botswana', name_de: 'Botswana' },
      'CA': { name_en: 'Canada', name_de: 'Kanada' },
      'CH': { name_en: 'Switzerland', name_de: 'Schweiz' },
      'CI': { name_en: 'Côte d\'Ivoire', name_de: 'Elfenbeinküste' },
      'CL': { name_en: 'Chile', name_de: 'Chile' },
      'CN': { name_en: 'China', name_de: 'China' },
      'CO': { name_en: 'Colombia', name_de: 'Kolumbien' },
      'CR': { name_en: 'Costa Rica', name_de: 'Costa Rica' },
      'CW': { name_en: 'Curaçao', name_de: 'Curaçao' },
      'CY': { name_en: 'Cyprus', name_de: 'Zypern' },
      'CZ': { name_en: 'Czech Republic', name_de: 'Tschechien' },
      'DE': { name_en: 'Germany', name_de: 'Deutschland' },
      'DK': { name_en: 'Denmark', name_de: 'Dänemark' },
      'DO': { name_en: 'Dominican Republic', name_de: 'Dominikanische Republik' },
      'EE': { name_en: 'Estonia', name_de: 'Estland' },
      'EG': { name_en: 'Egypt', name_de: 'Ägypten' },
      'ES': { name_en: 'Spain', name_de: 'Spanien' },
      'FI': { name_en: 'Finland', name_de: 'Finnland' },
      'FK': { name_en: 'Falkland Islands', name_de: 'Falklandinseln' },
      'FR': { name_en: 'France', name_de: 'Frankreich' },
      'GA': { name_en: 'Gabon', name_de: 'Gabun' },
      'GB': { name_en: 'United Kingdom', name_de: 'Vereinigtes Königreich' },
      'GE': { name_en: 'Georgia', name_de: 'Georgien' },
      'GF': { name_en: 'French Guiana', name_de: 'Französisch-Guayana' },
      'GG': { name_en: 'Guernsey', name_de: 'Guernsey' },
      'GI': { name_en: 'Gibraltar', name_de: 'Gibraltar' },
      'GL': { name_en: 'Greenland', name_de: 'Grönland' },
      'GR': { name_en: 'Greece', name_de: 'Griechenland' },
      'HK': { name_en: 'Hong Kong', name_de: 'Hongkong' },
      'HU': { name_en: 'Hungary', name_de: 'Ungarn' },
      'ID': { name_en: 'Indonesia', name_de: 'Indonesien' },
      'IE': { name_en: 'Ireland', name_de: 'Irland' },
      'IL': { name_en: 'Israel', name_de: 'Israel' },
      'IM': { name_en: 'Isle of Man', name_de: 'Isle of Man' },
      'IN': { name_en: 'India', name_de: 'Indien' },
      'IS': { name_en: 'Iceland', name_de: 'Island' },
      'IT': { name_en: 'Italy', name_de: 'Italien' },
      'JE': { name_en: 'Jersey', name_de: 'Jersey' },
      'JP': { name_en: 'Japan', name_de: 'Japan' },
      'KE': { name_en: 'Kenya', name_de: 'Kenia' },
      'KH': { name_en: 'Cambodia', name_de: 'Kambodscha' },
      'KR': { name_en: 'South Korea', name_de: 'Südkorea' },
      'KW': { name_en: 'Kuwait', name_de: 'Kuwait' },
      'KY': { name_en: 'Cayman Islands', name_de: 'Kaimaninseln' },
      'KZ': { name_en: 'Kazakhstan', name_de: 'Kasachstan' },
      'LA': { name_en: 'Laos', name_de: 'Laos' },
      'LB': { name_en: 'Lebanon', name_de: 'Libanon' },
      'LC': { name_en: 'Saint Lucia', name_de: 'St. Lucia' },
      'LI': { name_en: 'Liechtenstein', name_de: 'Liechtenstein' },
      'LK': { name_en: 'Sri Lanka', name_de: 'Sri Lanka' },
      'LT': { name_en: 'Lithuania', name_de: 'Litauen' },
      'LU': { name_en: 'Luxembourg', name_de: 'Luxemburg' },
      'LV': { name_en: 'Latvia', name_de: 'Lettland' },
      'MA': { name_en: 'Morocco', name_de: 'Marokko' },
      'MC': { name_en: 'Monaco', name_de: 'Monaco' },
      'ME': { name_en: 'Montenegro', name_de: 'Montenegro' },
      'MK': { name_en: 'North Macedonia', name_de: 'Nordmazedonien' },
      'MM': { name_en: 'Myanmar', name_de: 'Myanmar' },
      'MN': { name_en: 'Mongolia', name_de: 'Mongolei' },
      'MO': { name_en: 'Macau', name_de: 'Macao' },
      'MT': { name_en: 'Malta', name_de: 'Malta' },
      'MU': { name_en: 'Mauritius', name_de: 'Mauritius' },
      'MV': { name_en: 'Maldives', name_de: 'Malediven' },
      'MX': { name_en: 'Mexico', name_de: 'Mexiko' },
      'MY': { name_en: 'Malaysia', name_de: 'Malaysia' },
      'MZ': { name_en: 'Mozambique', name_de: 'Mosambik' },
      'NA': { name_en: 'Namibia', name_de: 'Namibia' },
      'NC': { name_en: 'New Caledonia', name_de: 'Neukaledonien' },
      'NE': { name_en: 'Niger', name_de: 'Niger' },
      'NG': { name_en: 'Nigeria', name_de: 'Nigeria' },
      'NL': { name_en: 'Netherlands', name_de: 'Niederlande' },
      'NO': { name_en: 'Norway', name_de: 'Norwegen' },
      'NP': { name_en: 'Nepal', name_de: 'Nepal' },
      'NZ': { name_en: 'New Zealand', name_de: 'Neuseeland' },
      'OM': { name_en: 'Oman', name_de: 'Oman' },
      'PA': { name_en: 'Panama', name_de: 'Panama' },
      'PE': { name_en: 'Peru', name_de: 'Peru' },
      'PH': { name_en: 'Philippines', name_de: 'Philippinen' },
      'PK': { name_en: 'Pakistan', name_de: 'Pakistan' },
      'PL': { name_en: 'Poland', name_de: 'Polen' },
      'PR': { name_en: 'Puerto Rico', name_de: 'Puerto Rico' },
      'PT': { name_en: 'Portugal', name_de: 'Portugal' },
      'QA': { name_en: 'Qatar', name_de: 'Katar' },
      'RO': { name_en: 'Romania', name_de: 'Rumänien' },
      'RS': { name_en: 'Serbia', name_de: 'Serbien' },
      'RU': { name_en: 'Russia', name_de: 'Russland' },
      'SA': { name_en: 'Saudi Arabia', name_de: 'Saudi-Arabien' },
      'SE': { name_en: 'Sweden', name_de: 'Schweden' },
      'SG': { name_en: 'Singapore', name_de: 'Singapur' },
      'SI': { name_en: 'Slovenia', name_de: 'Slowenien' },
      'SK': { name_en: 'Slovakia', name_de: 'Slowakei' },
      'SM': { name_en: 'San Marino', name_de: 'San Marino' },
      'SN': { name_en: 'Senegal', name_de: 'Senegal' },
      'SO': { name_en: 'Somalia', name_de: 'Somalia' },
      'SR': { name_en: 'Suriname', name_de: 'Suriname' },
      'SV': { name_en: 'El Salvador', name_de: 'El Salvador' },
      'SY': { name_en: 'Syria', name_de: 'Syrien' },
      'TC': { name_en: 'Turks and Caicos Islands', name_de: 'Turks- und Caicosinseln' },
      'TH': { name_en: 'Thailand', name_de: 'Thailand' },
      'TN': { name_en: 'Tunisia', name_de: 'Tunesien' },
      'TR': { name_en: 'Turkey', name_de: 'Türkei' },
      'TW': { name_en: 'Taiwan', name_de: 'Taiwan' },
      'UA': { name_en: 'Ukraine', name_de: 'Ukraine' },
      'US': { name_en: 'USA', name_de: 'USA' },
      'UY': { name_en: 'Uruguay', name_de: 'Uruguay' },
      'VA': { name_en: 'Vatican City', name_de: 'Vatikanstadt' },
      'VE': { name_en: 'Venezuela', name_de: 'Venezuela' },
      'VN': { name_en: 'Vietnam', name_de: 'Vietnam' },
      'ZA': { name_en: 'South Africa', name_de: 'Südafrika' },
      'ZM': { name_en: 'Zambia', name_de: 'Sambia' }
  };
  
  let items = [];
  let id = 1;

  for (const [continent, countries] of Object.entries(continentToCountries)) {
    let continentItem = { id: id++, name_en: continentToContinentName[continent].name_en, name_de: continentToContinentName[continent].name_de, children: [] };
    for (const countryCode of countries) {
      if (countryCode in countryCodeToCountryName) {
        continentItem.children.push({ 
          id: id++, 
          name_en: countryCodeToCountryName[countryCode].name_en, 
          name_de: countryCodeToCountryName[countryCode].name_de,
          api: countryCode 
        });
      }
    }
    items.push(continentItem);
  }
  return items;
}

function getSectorAndIndustryTreeFH() {
  let items = [
    {
      id: 1,
      name_en: 'Basic Materials',
      name_de: 'Grundstoffe',
      children: [
        {
          id: 2,
          name_en: 'Chemicals',
          name_de: 'Chemikalien',
          api: 'Chemicals'
        },
        {
          id: 3,
          name_en: 'Metals & Mining',
          name_de: 'Metalle & Bergbau',
          api: 'Metals & Mining'
        },
        {
          id: 4,
          name_en: 'Paper & Forest',
          name_de: 'Papier & Wald',
          api: 'Paper & Forest'
        }
      ]
    },
    {
      id: 5,
      name_en: 'Consumer Cyclical',
      name_de: 'Zyklische Konsumgüter',
      children: [
        {
          id: 6,
          name_en: 'Auto Components',
          name_de: 'Auto-Komponenten',
          api: 'Auto Components'
        },
        {
          id: 7,
          name_en: 'Automobiles',
          name_de: 'Automobile',
          api: 'Automobiles'
        },
        {
          id: 8,
          name_en: 'Consumer Products',
          name_de: 'Konsumgüter',
          api: 'Consumer Products'
        },
        {
          id: 9,
          name_en: 'Textiles, Apparel & Luxury Goods',
          name_de: 'Textilien, Bekleidung & Luxusgüter',
          api: 'Textiles, Apparel & Luxury Goods'
        },
        {
          id: 10,
          name_en: 'Leisure Products',
          name_de: 'Freizeitprodukte',
          api: 'Leisure Products'
        },
        {
          id: 11,
          name_en: 'Hotels, Restaurants & Leisure',
          name_de: 'Hotels, Restaurants & Freizeit',
          api: 'Hotels, Restaurants & Leisure'
        },
        {
          id: 12,
          name_en: 'Retail',
          name_de: 'Einzelhandel',
          api: 'Retail'
        }
      ]
    },
    {
      id: 13,
      name_en: 'Financial Services',
      name_de: 'Finanzdienstleistungen',
      children: [
        {
          id: 14,
          name_en: 'Banking',
          name_de: 'Bankwesen',
          api: 'Banking'
        },
        {
          id: 15,
          name_en: 'Financial Services',
          name_de: 'Finanzdienstleistungen',
          api: 'Financial Services'
        },
        {
          id: 16,
          name_en: 'Insurance',
          name_de: 'Versicherung',
          api: 'Insurance'
        }
      ]
    },
    {
      id: 17,
      name_en: 'Real Estate',
      name_de: 'Immobilien',
      api: 'Real Estate'
    },
    {
      id: 18,
      name_en: 'Consumer Defensive',
      name_de: 'Defensive Konsumgüter',
      children: [
        {
          id: 19,
          name_en: 'Beverages',
          name_de: 'Getränke',
          api: 'Beverages'
        },
        {
          id: 20,
          name_en: 'Food Products',
          name_de: 'Lebensmittel',
          api: 'Food Products'
        },
        {
          id: 21,
          name_en: 'Tobacco',
          name_de: 'Tabak',
          api: 'Tobacco'
        }
      ]
    },
    {
      id: 22,
      name_en: 'Healthcare',
      name_de: 'Gesundheitswesen',
      children: [
        {
          id: 23,
          name_en: 'Biotechnology',
          name_de: 'Biotechnologie',
          api: 'Biotechnology'
        },
        {
          id: 24,
          name_en: 'Health Care',
          name_de: 'Gesundheitswesen',
          api: 'Health Care'
        },
        {
          id: 25,
          name_en: 'Pharmaceuticals',
          name_de: 'Pharmazeutika',
          api: 'Pharmaceuticals'
        },
        {
          id: 26,
          name_en: 'Life Sciences Tools & Services',
          name_de: 'Life Sciences Tools & Services',
          api: 'Life Sciences Tools & Services'
        }
      ]
    },
    {
      id: 27,
      name_en: 'Utilities',
      name_de: 'Versorgungsunternehmen',
      api: 'Utilities'
    },
    {
      id: 28,
      name_en: 'Communication Services',
      name_de: 'Kommunikationsdienste',
      children: [
        {
          id: 29,
          name_en: 'Communications',
          name_de: 'Kommunikation',
          api: 'Communications'
        },
        {
          id: 30,
          name_en: 'Media',
          name_de: 'Medien',
          api: 'Media'
        },
        {
          id: 31,
          name_en: 'Telecommunication',
          name_de: 'Telekommunikation',
          api: 'Telecommunication'
        }
      ]
    },
    {
      id: 32,
      name_en: 'Energy',
      name_de: 'Energie',
      children: [
        {
          id: 33,
          name_en: 'Energy',
          name_de: 'Energie',
          api: 'Energy'
        }
      ]
    },
    {
      id: 34,
      name_en: 'Industrials',
      name_de: 'Industrie',
      children: [
        {
          id: 35,
          name_en: 'Aerospace & Defense',
          name_de: 'Luft- & Raumfahrt',
          api: 'Aerospace & Defense'
        },
        {
          id: 36,
          name_en: 'Airlines',
          name_de: 'Fluggesellschaften',
          api: 'Airlines'
        },
        {
          id: 37,
          name_en: 'Machinery',
          name_de: 'Maschinenbau',
          api: 'Machinery'
        },
        {
          id: 38,
          name_en: 'Marine',
          name_de: 'Marine',
          api: 'Marine'
        },
        {
          id: 39,
          name_en: 'Road & Rail',
          name_de: 'Straße & Schiene',
          api: 'Road & Rail'
        },
        {
          id: 40,
          name_en: 'Trading Companies & Distributors',
          name_de: 'Handelsgesellschaften & Distributoren',
          api: 'Trading Companies & Distributors'
        },
        {
          id: 41,
          name_en: 'Transportation Infrastructure',
          name_de: 'Verkehrsinfrastruktur',
          api: 'Transportation Infrastructure'
        },
        {
          id: 42,
          name_en: 'Commercial Services & Supplies',
          name_de: 'Gewerbliche Dienstleistungen & Verbrauchsmaterialien',
          api: 'Commercial Services & Supplies'
        },
        {
          id: 43,
          name_en: 'Professional Services',
          name_de: 'Professionelle Dienstleistungen',
          api: 'Professional Services'
        },
        {
          id: 44,
          name_en: 'Building',
          name_de: 'Bauen',
          api: 'Building'
        }
      ]
    },
    {
      id: 45,
      name_en: 'Technology',
      name_de: 'Technologie',
      children: [
        {
          id: 46,
          name_en: 'Semiconductors',
          name_de: 'Halbleiter',
          api: 'Semiconductors'
        },
        {
          id: 47,
          name_en: 'Technology',
          name_de: 'Technologie',
          api: 'Technology'
        }
      ]
    }
  ];

  return items;
}

/*---------------------------------------------------------------------------------------------------------/
/   gets the tree data structure for the industry selection
/---------------------------------------------------------------------------------------------------------*/
function getSectorAndIndustryTree() {
 
  let items = [
    {
      id: 1,
      name_en: 'Basic Materials',
      name_de: 'Grundstoffe',
      children: [
        { 
          id: 2, 
          name_en: 'Agriculture',
          name_de: 'Landwirtschaft',
          api: 'Agricultural Inputs', 
          children: [
            { 
              id: 190, 
              name_en: 'Agricultural Chemicals',
              name_de: 'Landwirtschaftliche Chemikalien',
              api: 'Agricultural Chemicals',
            },
            { 
              id: 191, 
              name_en: 'Agricultural Inputs',
              name_de: 'Landwirtschaftliche Eingaben',
              api: 'Agricultural Inputs',
            },
            { 
              id: 191, 
              name_en: 'Farm Products',
              name_de: 'Landwirtschaftliche Produkte',
              api: 'Farm Products',
            },
          ],
        },
        { 
          id: 3, 
          name_en: 'Building Materials',
          name_de: 'Baumaterialien',
          children: [
            {
              id: 198,
              name_en: 'Building Materials',
              name_de: 'Baumaterialien',
              api: 'Building Materials',
            },
            {
              id: 199,
              name_en: 'Building Materials Wholesale',
              name_de: 'Baumaterial Großhandel',
              api: 'Building Materials Wholesale',
            },
            {
              id: 200,
              name_en: 'Building Products & Equipment',
              name_de: 'Bauprodukte & Ausrüstung',
              api: 'Building Products & Equipment',
            }
          ],
        },
        { 
          id: 4, 
          name_en: 'Chemicals',
          name_de: 'Chemikalien',
          children: [
            { 
              id: 5, 
              name_en: 'Chemicals',
              name_de: 'Chemikalien',
              api: 'Chemicals', 
            },
            { 
                id: 6, 
                name_en: 'Specialty Chemicals',
                name_de: 'Spezialchemikalien',
                api: 'Specialty Chemicals',
            },
          ], 
        },
        { 
          id: 7, 
          name_en: 'Forest Products',
          name_de: 'Forstprodukte',
          children: [
            { 
              id: 8, 
              name_en: 'Lumber & Wood Production',
              name_de: 'Holzproduktion',
              api: 'Lumber & Wood Production', 
            },
            { 
                id: 9, 
                name_en: 'Paper & Paper Products',
                name_de: 'Papierprodukte',
                api: 'Paper & Paper Products',
            },
          ], 
        },
        { 
          id: 10, 
          name_en: 'Metals & Mining',
          name_de: 'Metalle & Bergbau',
          children: [
            { 
              id: 11, 
              name_en: 'Aluminum',
              name_de: 'Aluminium',
              api: 'Aluminum', 
            },
            { 
                id: 12, 
                name_en: 'Copper',
                name_de: 'Kupfer',
                api: 'Copper',
            },
            { 
              id: 13, 
              name_en: 'Other Industrial Metals & Mining',
              name_de: 'Andere Industriemetalle & Bergbau',
              api: 'Other Industrial Metals & Mining',
            },
            { 
              id: 14, 
              name_en: 'Gold',
              name_de: 'Gold',
              api: 'Gold',
            },
            { 
              id: 15, 
              name_en: 'Silver',
              name_de: 'Silber',
              api: 'Silver',
            },
            { 
              id: 16, 
              name_en: 'Other Precious Metals & Mining',
              name_de: 'Andere Edelmetalle & Bergbau',
              api: 'Other Precious Metals & Mining',
            },
          ], 
        },
        {
          id: 17, 
          name_en: 'Steel',
          name_de: 'Stahl',
          children: [
            { 
              id: 18, 
              name_en: 'Coking Coal',
              name_de: 'Kokskohle',
              api: 'Coking Coal', 
            },
            { 
                id: 19, 
                name_en: 'Steel',
                name_de: 'Stahl',
                api: 'Steel',
            },
          ],
        }
      ],
    },
    {
      id: 20, 
      name_en: 'Consumer Cyclical',
      name_de: 'Zyklische Konsumgüter',
      children: [
        { 
          id: 21, 
          name_en: 'Vehicles & Parts',
          name_de: 'Fahrzeuge & Teile',
          children: [
            { 
              id: 22, 
              name_en: 'Auto & Truck Dealerships',
              name_de: 'Auto & LKW-Händler',
              api: 'Auto & Truck Dealerships',
            },
            { 
              id: 194, 
              name_en: 'Auto Dealerships',
              name_de: 'Autohändler',
              api: 'Auto Dealerships',
            },
            { 
              id: 23, 
              name_en: 'Auto Manufacturers',
              name_de: 'Autohersteller',
              api: 'Auto Manufacturers',
            },
            { 
              id: 24, 
              name_en: 'Auto Parts',
              name_de: 'Autoteile',
              api: 'Auto Parts',
            },
            { 
              id: 195, 
              name_en: 'Automobiles & Components',
              name_de: 'Automobile & Komponenten',
              api: 'Automobiles & Components',
            },
            { 
              id: 25, 
              name_en: 'Recreational Vehicles',
              name_de: 'Freizeitfahrzeuge',
              api: 'Recreational Vehicles',
            },
          ],
        },
        { 
          id: 26, 
          name_en: 'Furnishings, Fixtures & Appliances',
          name_de: 'Einrichtung, Fixtures & Geräte',
          api: 'Furnishings, Fixtures & Appliances',
        },
        { 
          id: 27, 
          name_en: 'Residential Homebuilding & Construction',
          name_de: 'Wohnungsbau & Bau',
          api: 'Homebuilding & Construction',
        },
        { 
          id: 28, 
          name_en: 'Manufacturing - Apparel & Accessories',
          name_de: 'Herstellung - Bekleidung & Accessoires',
          children: [
            { 
              id: 29, 
              name_en: 'Textile Manufacturing',
              name_de: 'Textilherstellung',
              api: 'Textile Manufacturing',
            },
            { 
              id: 30, 
              name_en: 'Apparel Manufacturing',
              name_de: 'Bekleidungsherstellung',
              api: 'Apparel Manufacturing',
            },
            { 
              id: 31, 
              name_en: 'Footwear & Accessories',
              name_de: 'Schuhe & Accessoires',
              api: 'Footwear & Accessories',
            },
          ],
        },
        { 
          id: 32, 
          name_en: 'Packaging & Containers',
          name_de: 'Verpackung & Behälter',
          api: 'Packaging & Containers',
        },
        { 
          id: 33, 
          name_en: 'Personal Services',
          name_de: 'Persönliche Dienstleistungen',
          api: 'Personal Services',
        },
        { 
          id: 34, 
          name_en: 'Restaurants',
          name_de: 'Restaurants',
          api: 'Restaurants',
        },
        { 
          id: 35, 
          name_en: 'Retail Cyclical',
          name_de: 'Zyklischer Einzelhandel',
          children: [
            { 
              id: 36, 
              name_en: 'Apparel Retail',
              name_de: 'Bekleidungseinzelhandel',
              api: 'Apparel Retail',
            },
            { 
              id: 37, 
              name_en: 'Department Stores',
              name_de: 'Warenhäuser',
              api: 'Department Stores',
            },
            { 
              id: 38, 
              name_en: 'Home Improvement Retail',
              name_de: 'Heimwerker-Einzelhandel',
              api: 'Home Improvement Retail',
            },
            { 
              id: 39, 
              name_en: 'Luxury Goods',
              name_de: 'Luxusgüter',
              api: 'Luxury Goods',
            },
            { 
              id: 40, 
              name_en: 'Internet Retail',
              name_de: 'Internet-Einzelhandel',
              api: 'Internet Retail',
            },
            { 
              id: 41, 
              name_en: 'Specialty Retail',
              name_de: 'Spezial-Einzelhandel',
              api: 'Specialty Retail',
            },
          ],
        },
        { 
          id: 42, 
          name_en: 'Travel & Leisure',
          name_de: 'Reisen & Freizeit',
          children: [
            { 
              id: 43, 
              name_en: 'Gambling',
              name_de: 'Glücksspiel',
              api: 'Gambling',
            },
            { 
              id: 44, 
              name_en: 'Leisure',
              name_de: 'Freizeit',
              api: 'Leisure',
            },
            { 
              id: 45, 
              name_en: 'Lodging',
              name_de: 'Unterkunft',
              api: 'Lodging',
            },
            { 
              id: 46, 
              name_en: 'Resorts & Casinos',
              name_de: 'Resorts & Casinos',
              api: 'Resorts & Casinos',
            },
            { 
              id: 47, 
              name_en: 'Travel Services',
              name_de: 'Reisedienstleistungen',
              api: 'Travel Services',
            },
          ],
        },
      ],
    },
    { 
      id: 48, 
      name_en: 'Financial Services',
      name_de: 'Finanzdienstleistungen',
      children: [
        { 
          id: 49, 
          name_en: 'Asset Management',
          name_de: 'Vermögensverwaltung',
          api: 'Asset Management',
        },
        { 
          id: 50, 
          name_en: 'Banks',
          name_de: 'Banken',
          children: [
            { 
              id: 51, 
              name_en: 'Banks - Diversified',
              name_de: 'Banken - Diversifiziert',
              api: 'Banks—Diversified',
            },
            { 
              id: 52, 
              name_en: 'Banks - Regional',
              name_de: 'Banken - Regional',
              api: 'Banks—Regional',
            },
            { 
              id: 195, 
              name_en: 'Money Center Banks',
              name_de: 'Geldzentralbanken',
              api: 'Money Center Banks',
            },
            { 
              id: 53, 
              name_en: 'Mortgage Finance',
              name_de: 'Hypothekenfinanzierung',
              api: 'Mortgage Finance',
            },
            { 
              id: 196, 
              name_en: 'Mortgage Investment',
              name_de: 'Hypothekeninvestition',
              api: 'Mortgage Investment',
            },
          ],
        },
        { 
          id: 54, 
          name_en: 'Capital Markets',
          name_de: 'Kapitalmärkte',
          children: [
            { 
              id: 55, 
              name_en: 'Capital Markets',
              name_de: 'Kapitalmärkte',
              api: 'Capital Markets',
            },
            { 
              id: 56, 
              name_en: 'Financial Data & Stock Exchanges',
              name_de: 'Finanzdaten & Börsen',
              api: 'Financial Data & Stock Exchanges',
            },
          ],
        },
        { 
          id: 57, 
          name_en: 'Insurance',
          name_de: 'Versicherung',
          children: [
            { 
              id: 58, 
              name_en: 'Insurance - Life',
              name_de: 'Versicherung - Leben',
              api: 'Insurance—Life',
            },
            { 
              id: 59, 
              name_en: 'Insurance - Property & Casualty',
              name_de: 'Versicherung - Sach- & Unfallversicherung',
              api: 'Insurance—Property & Casualty',
            },
            { 
              id: 60, 
              name_en: 'Insurance - Reinsurance',
              name_de: 'Versicherung - Rückversicherung',
              api: 'Insurance—Reinsurance',
            },
            { 
              id: 61, 
              name_en: 'Insurance - Specialty',
              name_de: 'Versicherung - Spezialversicherung',
              api: 'Insurance Specialty',
            },
            { 
              id: 62, 
              name_en: 'Insurance - Brokers',
              name_de: 'Versicherung - Makler',
              api: 'Insurance Brokers',
            },
            { 
              id: 63, 
              name_en: 'Insurance - Diversified',
              name_de: 'Versicherung - Diversifiziert',
              api: 'Insurance—Diversified',
            },
          ],
        },
        { 
          id: 64, 
          name_en: 'Diversified Financial Services',
          name_de: 'Diversifizierte Finanzdienstleistungen',
          children: [
            { 
              id: 65, 
              name_en: 'Shell Companies',
              name_de: 'Shell-Unternehmen',
              api: 'Shell Companies',
            },
            { 
              id: 66, 
              name_en: 'Financial Conglomerates',
              name_de: 'Finanzkonglomerate',
              api: 'Financial Conglomerates',
            },
          ],
        },
        { 
          id: 67, 
          name_en: 'Credit Services',
          name_de: 'Kreditdienstleistungen',
          api: 'Credit Services',
        }
      ],
    },
    {
      id: 68,
      name_en: 'Real Estate',
      name_de: 'Immobilien',
      children: [
        { 
          id: 69, 
          name_en: 'Real Estate',
          name_de: 'Immobilien',
          children: [
            {
              id: 70,
              name_en: 'Real Estate - Development',
              name_de: 'Immobilien - Entwicklung',
              api: 'Real Estate—Development',
            },
            {
              id: 71,
              name_en: 'Real Estate - Services',
              name_de: 'Immobilien - Dienstleistungen',
              api: 'Real Estate Services',
            },
            {
              id: 72,
              name_en: 'Real Estate - Diversified',
              name_de: 'Immobilien - Diversifiziert',
              api: 'Real Estate—Diversified',
            },
          ],
        },
        {
          id: 73,
          name_en: 'REITs',
          name_de: 'REITs',
          children: [
            {
              id: 74,
              name_en: 'REIT - Healthcare Facilities',
              name_de: 'REIT - Gesundheitseinrichtungen',
              api: 'REIT—Healthcare Facilities',
            },
            {
              id: 75,
              name_en: 'REIT - Hotel & Motel',
              name_de: 'REIT - Hotel & Motel',
              api: 'REIT—Hotel & Motel',
            },
            {
              id: 76,
              name_en: 'REIT - Industrial',
              name_de: 'REIT - Industrie',
              api: 'REIT—Industrial',
            },
            {
              id: 77,
              name_en: 'REIT - Office',
              name_de: 'REIT - Büro',
              api: 'REIT—Office',
            },
            {
              id: 78,
              name_en: 'REIT - Residential',
              name_de: 'REIT - Wohnen',
              api: 'REIT—Residential',
            },
            {
              id: 79,
              name_en: 'REIT - Retail',
              name_de: 'REIT - Einzelhandel',
              api: 'REIT—Retail',
            },
            {
              id: 80,
              name_en: 'REIT - Mortgage',
              name_de: 'REIT - Hypothek',
              api: 'REIT—Mortgage',
            },
            {
              id: 81,
              name_en: 'REIT - Specialty',
              name_de: 'REIT - Spezial',
              api: 'REIT—Specialty',
            },
            {
              id: 82,
              name_en: 'REIT - Diversified',
              name_de: 'REIT - Diversifiziert',
              api: 'REIT—Diversified',
            },     
          ],
        },
      ],
    },
    {
      id: 83,
      name_en: 'Consumer Defensive',
      name_de: 'Defensive Konsumgüter',
      children: [
        {
          id: 84,
          name_en: 'Beverages - Alcolholic',
          name_de: 'Getränke - Alkoholisch',
          children: [
            {
              id: 85,
              name_en: 'Beverages - Brewers',
              name_de: 'Getränke - Brauer',
              api: 'Beverages—Brewers',
            },  
            {
              id: 86,
              name_en: 'Beverages - Wineries & Distilleries',
              name_de: 'Getränke - Weingüter & Brennereien',
              api: 'Beverages—Wineries & Distilleries',
            },  
          ],
        },  
        {
          id: 87,
          name_en: 'Beverages - Non-Alcoholic',
          name_de: 'Getränke - Nicht-alkoholisch',
          api: 'Beverages Non-Alcoholic'
        },
        {
          id: 88,
          name_en: 'Consumer Packaged Goods',
          name_de: 'Verbrauchsgüter',
          children: [
            {
              id: 89,
              name_en: 'Confectioners',
              name_de: 'Süßwarenhersteller',
              api: 'Confectioners'
            },
            {
              id: 90,
              name_en: 'Farm Products',
              name_de: 'Landwirtschaftliche Produkte',
              api: 'Farm Products'
            },
            {
              id: 91,
              name_en: 'Household & Personal Products',
              name_de: 'Haushalts- & Körperpflegeprodukte',
              api: 'Household & Personal Products'
            },
            {
              id: 92,
              name_en: 'Packaged Foods',
              name_de: 'Verpackte Lebensmittel',
              api: 'Packaged Foods'
            },
          ],
        },
        {
          id: 93,
          name_en: 'Education & Training Services',
          name_de: 'Bildungs- & Schulungsdienstleistungen',
          api: 'Education & Training Services',
        },
        {
          id: 94,
          name_en: 'Retail - Defensive',
          name_de: 'Defensiver Einzelhandel',
          children: [
            {
              id: 95,
              name_en: 'Discount Stores',
              name_de: 'Discounter',
              api: 'Discount Stores',
            },
            {
              id: 96,
              name_en: 'Food Distribution',
              name_de: 'Lebensmittelverteilung',
              api: 'Food Distribution',
            },
            {
              id: 97,
              name_en: 'Grocery Stores',
              name_de: 'Lebensmittelgeschäfte',
              api: 'Grocery Stores',
            },
          ],
        },
        {
          id: 98,
          name_en: 'Tobacco Products',
          name_de: 'Tabakprodukte',
          api: 'Tobacco',
        },
      ],
    },
    {
      id: 99,
      name_en: 'Healthcare',
      name_de: 'Gesundheitswesen',
      children: [
        {
          id: 100,
          name_en: 'Biotechnology',
          name_de: 'Biotechnologie',
          api: 'Biotechnology',
        },
        {
          id: 101,
          name_en: 'Drug Manufacturers',
          name_de: 'Arzneimittelhersteller',
          children: [
            {
              id: 102,
              name_en: 'Drug Manufacturers - General',
              name_de: 'Arzneimittelhersteller - Allgemein',
              api: 'Drug Manufacturers—General'
            },
            {
              id: 103,
              name_en: 'Drug Manufacturers - Specialty & Generic',
              name_de: 'Arzneimittelhersteller - Spezial & Generika',
              api: 'Drug Manufacturers—Specialty & Generic'
            }
          ],
        },
        {
          id: 104,
          name_en: 'Healthcare Plans',
          name_de: 'Gesundheitspläne',
          api: 'Healthcare Plans',
        },
        {
          id: 105,
          name_en: 'Healthcare Providers & Services',
          name_de: 'Gesundheitsdienstleister',
          children: [
            {
              id: 106,
              name_en: 'Medical Care Facilities',
              name_de: 'Medizinische Einrichtungen',
              api: 'Medical Care Facilities',
            },
            {
              id: 107,
              name_en: 'Pharmaceutical Retailers',
              name_de: 'Pharmazeutische Einzelhändler',
              api: 'Pharmaceutical Retailers',
            },
            {
              id: 108,
              name_en: 'Health Information Services',
              name_de: 'Gesundheitsinformationssysteme',
              api: 'Health Information Services',
            },
          ],
        },
        {
          id: 109,
          name_en: 'Medical Devices & Instruments',
          name_de: 'Medizinische Geräte & Instrumente',
          children: [
            {
              id: 110,
              name_en: 'Medical Devices',
              name_de: 'Medizinische Geräte',
              api: 'Medical Devices',
            },
            {
              id: 111,
              name_en: 'Medical Instruments & Supplies',
              name_de: 'Medizinische Instrumente & Zubehör',
              api: 'Medical Instruments & Supplies',
            }
          ],
        },
        {
          id: 112,
          name_en: 'Medical Diagnostics & Research',
          name_de: 'Medizinische Diagnostik & Forschung',
          api: 'Diagnostics & Research',
        },
        {
          id: 113,
          name_en: 'Medical Distribution',
          name_de: 'Medizinische Verteilung',
          api: 'Medical Distribution',
        },
      ],
    },
    {
      id: 114,
      name_en: 'Utilities',
      name_de: 'Versorgungsunternehmen',
      children: [
        {
          id: 115,
          name_en: 'Utilities - Independent Power Producers',
          name_de: 'Versorgungsunternehmen - Unabhängige Stromerzeuger',
          children: [
            {
              id: 116,
              name_en: 'Utilities - Independent Power Producers',
              name_de: 'Versorgungsunternehmen - Unabhängige Stromerzeuger',
              api: 'Utilities—Independent Power Producers',
            },
            {
              id: 117,
              name_en: 'Utilities - Renewable',
              name_de: 'Versorgungsunternehmen - Erneuerbare',
              api: 'Utilities—Renewable',
            },
          ],
        },
        {
          id: 119,
          name_en: 'Utilities - Regulated',
          name_de: 'Versorgungsunternehmen - Reguliert',
          children: [
            {
              id: 120,
              name_en: 'Utilities - Regulated Water',
              name_de: 'Versorgungsunternehmen - Reguliertes Wasser',
              api: 'Utilities—Regulated Water',
            },
            {
              id: 121,
              name_en: 'Utilities - Regulated Electric',
              name_de: 'Versorgungsunternehmen - Regulierter Strom',
              api: 'Utilities—Regulated Electric',
            },
            {
              id: 122,
              name_en: 'Utilities - Regulated Gas',
              name_de: 'Versorgungsunternehmen - Reguliertes Gas',
              api: 'Utilities—Regulated Gas',
            },
            {
              id: 123,
              name_en: 'Utilities - Diversified',
              name_de: 'Versorgungsunternehmen - Diversifiziert',
              api: 'Utilities—Diversified',
            },
          ],
        },
      ],
    },
    {
      id: 124,
      name_en: 'Communication Services',
      name_de: 'Kommunikationsdienste',
      children: [
        {
          id: 125,
          name_en: 'Telecommunication Services',
          name_de: 'Telekommunikationsdienste',
          api: 'Telecom Services',
        },
        {
          id: 126,
          name_en: 'Media-Diversified',
          name_de: 'Medien - Diversifiziert',
          children: [
            {
              id: 127,
              name_en: 'Advertising Agencies',
              name_de: 'Werbung',
              api: 'Advertising Agencies',
            },
            {
              id: 128,
              name_en: 'Publishing',
              name_de: 'Verlagswesen',
              api: 'Publishing',
            },
            {
              id: 129,
              name_en: 'Broadcasting',
              name_de: 'Rundfunk',
              api: 'Broadcasting',
            },
            {
              id: 197,
              name_en: 'Broadcasting - TV',
              name_de: 'Rundfunk - TV',
              api: 'Broadcasting - TV',
            },
            {
              id: 130,
              name_en: 'Entertainment',
              name_de: 'Unterhaltung',
              api: 'Entertainment',
            },
            {
              id: 198,
              name_en: 'Entertainment - Diversified',
              name_de: 'Unterhaltung - Diversifiziert',
              api: 'Entertainment - Diversified',
            },
          ],
        },
        {
          id: 131,
          name_en: 'Interactive Media',
          name_de: 'Interaktive Medien',
          children: [
            {
              id: 132,
              name_en: 'Internet Content & Information',
              name_de: 'Internetinhalte & Informationen',
              api: 'Internet Content & Information',
            },
            {
              id: 133,
              name_en: 'Electronic Gaming & Multimedia',
              name_de: 'Elektronische Spiele & Multimedia',
              api: 'Electronic Gaming & Multimedia',
            },
          ],
        },
      ],
    },
    {
      id: 134,
      name_en: 'Energy',
      name_de: 'Energie',
      children: [
        {
          id: 135,
          name_en: 'Oil & Gas',
          name_de: 'Öl & Gas',
          children: [
            {
              id: 136,
              name_en: 'Oil & Gas Drilling',
              name_de: 'Öl & Gas Bohren',
              api: 'Oil & Gas Drilling',
            },
            {
              id: 137,
              name_en: 'Oil & Gas E&P',
              name_de: 'Öl & Gas E&P',
              api: 'Oil & Gas E&P',
            },
            {
              id: 138,
              name_en: 'Oil & Gas Integrated',
              name_de: 'Öl & Gas Integriert',
              api: 'Oil & Gas Integrated',
            },
            {
              id: 139,
              name_en: 'Oil & Gas Midstream',
              name_de: 'Öl & Gas Midstream',
              api: 'Oil & Gas Midstream',
            },
            {
              id: 140,
              name_en: 'Oil & Gas Refining & Marketing',
              name_de: 'Öl & Gas Raffination & Vermarktung',
              api: 'Oil & Gas Refining & Marketing',
            },
            {
              id: 141,
              name_en: 'Oil & Gas Equipment & Services',
              name_de: 'Öl & Gas Ausrüstung & Dienstleistungen',
              api: 'Oil & Gas Equipment & Services',
            },
          ],
        },
        {
          id: 142,
          name_en: 'Other Energy Sources',
          name_de: 'Andere Energiequellen',
          children: [
            {
              id: 143,
              name_en: 'Thermal Coal',
              name_de: 'Thermische Kohle',
              api: 'Thermal Coal',
            },
            {
              id: 144,
              name_en: 'Uranium',
              name_de: 'Uran',
              api: 'Uranium',
            }
          ],
        },
      ],
    },
    {
      id: 145,
      name_en: 'Industrials',
      name_de: 'Industrie',
      children: [
        {
          id: 146,
          name_en: 'Aerospace & Defense',
          name_de: 'Luft- & Raumfahrt',
          api: 'Aerospace & Defense - Major Diversified',
        },
        {
          id: 147,
          name_en: 'Business Services',
          name_de: 'Geschäftsdienstleistungen',
          children: [
            { 
              id: 148,
              name_en: 'Specialty Business Services',
              name_de: 'Spezielle Geschäftsdienstleistungen',
              api: 'Specialty Business Services',
            },
            { 
              id: 149,
              name_en: 'Consulting Services',
              name_de: 'Beratungsdienstleistungen',
              api: 'Consulting Services',
            },
            { 
              id: 150,
              name_en: 'Rental & Leasing Services',
              name_de: 'Vermietung & Leasing',
              api: 'Rental & Leasing Services',
            },
            { 
              id: 151,
              name_en: 'Security & Protection Services',
              name_de: 'Sicherheits- & Schutzdienste',
              api: 'Security & Protection Services',
            },
            { 
              id: 152,
              name_en: 'Staffing & Employment Services',
              name_de: 'Personalvermittlung',
              api: 'Staffing & Employment Services',
            },
          ],
        },
        {
          id: 153,
          name_en: 'Conglomerates',
          name_de: 'Konglomerate',
          api: 'Conglomerates',
        },
        {
          id: 154,
          name_en: 'Building & Construction',
          name_de: 'Bau & Konstruktion',
          children: [
            {
              id: 155,
              name_en: 'Engineering & Construction',
              name_de: 'Ingenieur- & Bauwesen',
              api: 'Engineering & Construction',
            },
            {
              id: 156,
              name_en: 'Infrastructure Operations',
              name_de: 'Infrastruktur',
              api: 'Infrastructure Operations',
            },
            {
              id: 157,
              name_en: 'Building Products & Equipment',
              name_de: 'Bauprodukte & Ausrüstung',
              api: 'Building Products & Equipment',
            },
            {
              id: 200,
              name_en: 'General Contractors',
              name_de: 'Generalunternehmer',
              api: 'General Contractors',
            },
          ],
        },
        {
          id: 158,
          name_en: 'Heavy Construction',
          name_de: 'Schwere Baumaschinen',
          api: 'Heavy Construction',
        },
        {
          id: 159,
          name_en: 'Industrial Distribution',
          name_de: 'Industrieverteilung',
          api: 'Industrial Distribution', 
        },
        {
          id: 160,
          name_en: 'Industrial Products',
          name_de: 'Industrieprodukte',
          children: [
            {
              id: 161,
              name_en: 'Business Equipment & Supplies',
              name_de: 'Büroausstattung & Zubehör',
              api: 'Business Equipment & Supplies',
            },
            {
              id: 162,
              name_en: 'Specialty Industrial Machinery',
              name_de: 'Spezielle Industriemaschinen',
              api: 'Specialty Industrial Machinery',
            },
            {
              id: 163,
              name_en: 'Metal Fabrication',
              name_de: 'Metallverarbeitung',
              api: 'Metal Fabrication',
            },
            {
              id: 164,
              name_en: 'Pollution & Treatment Controls',
              name_de: 'Umweltverschmutzung & Behandlungskontrollen',
              api: 'Pollution & Treatment Controls',
            },
            {
              id: 165,
              name_en: 'Tools & Accessories',
              name_de: 'Werkzeuge & Zubehör',
              api: 'Tools & Accessories',
            },
            {
              id: 166,
              name_en: 'Electrical Equipment & Parts',
              name_de: 'Elektrische Ausrüstung & Teile',
              api: 'Electrical Equipment & Parts',
            }
          ],
        },
        {
          id: 167,
          name_en: 'Transportation',
          name_de: 'Transport',
          children: [
            {
              id: 168,
              name_en: 'Airports & Air Services',
              name_de: 'Flughäfen & Luftdienste',
              api: 'Airports & Air Services',
            },
            {
              id: 169,
              name_en: 'Airlines',
              name_de: 'Fluggesellschaften',
              api: 'Airlines',
            },
            {
              id: 192,
              name_en: 'Air Delivery & Freight Services',
              name_de: 'Luftfracht & Frachtdienstleistungen',
              api: 'Air Delivery & Freight Services',
            },
            {
              id: 170,
              name_en: 'Railroads',
              name_de: 'Eisenbahnen',
              api: 'Railroads',
            },
            {
              id: 171,
              name_en: 'Marine Shipping',
              name_de: 'Schifffahrt',
              api: 'Marine Shipping',
            },
            {
              id: 172,
              name_en: 'Trucking',
              name_de: 'LKW-Transport',
              api: 'Trucking',
            },
            {
              id: 173,
              name_en: 'Integrated Freight & Logistics',
              name_de: 'Integrierte Fracht & Logistik',
              api: 'Integrated Freight & Logistics',
            }
          ],
        },
        {
          id: 174,
          name_en: 'Waste Management',
          name_de: 'Abfallwirtschaft',
          api: 'Waste Management',
        }
      ],
    },
    {
      id: 175,
      name_en: 'Technology',
      name_de: 'Technologie',
      children: [
        {
          id: 176,
          name_en: 'Software',
          name_de: 'Software',
          children: [
            {
              id: 177,
              name_en: 'Information Technology Services',
              name_de: 'Informationstechnologie-Dienstleistungen',
              api: 'Information Technology Services',
            },
            {
              id: 178,
              name_en: 'Software - Application',
              name_de: 'Software - Anwendung',
              api: 'Software—Application',
            },
            {
              id: 179,
              name_en: 'Software - Infrastructure',
              name_de: 'Software - Infrastruktur',
              api: 'Software—Infrastructure',
            }
          ],
        },
        {
          id: 180,
          name_en: 'Hardware',
          name_de: 'Hardware',
          children: [
            {
              id: 181,
              name_en: 'Communication Equipment',
              name_de: 'Kommunikationsausrüstung',
              api: 'Communication Equipment',
            },
            {
              id: 182,
              name_en: 'Computer Hardware',
              name_de: 'Computer-Hardware',
              api: 'Computer Hardware',
            },
            {
              id: 183,
              name_en: 'Consumer Electronics',
              name_de: 'Unterhaltungselektronik',
              api: 'Consumer Electronics',
            },
            {
              id: 184,
              name_en: 'Electronics & Computer Distribution',
              name_de: 'Elektronik & Computer-Verteilung',
              api: 'Electronics & Computer Distribution',
            },
            {
              id: 185,
              name_en: 'Scientific & Technical Instruments',
              name_de: 'Wissenschaftliche & technische Instrumente',
              api: 'Scientific & Technical Instruments',
            },
          ],
        },
        {
          id: 186,
          name_en: 'Semiconductors',
          name_de: 'Halbleiter',
          children: [
            {
              id: 187,
              name_en: 'Semiconductor Equipment & Materials',
              name_de: 'Halbleiterausrüstung & -materialien',
              api: 'Semiconductor Equipment & Materials',
            },
            {
              id: 188,
              name_en: 'Semiconductors',
              name_de: 'Halbleiter',
              api: 'Semiconductors',
            },
            {
              id: 189,
              name_en: 'Solar',
              name_de: 'Solar',
              api: 'Solar',
            },
          ],
        },
      ],
    },
  ];

  return items;
}


/*---------------------------------------------------------------------------------------------------------/
/   get the Global Industry Classification Standard tree
/---------------------------------------------------------------------------------------------------------*/
function getGlobalIndustryClassificationStandardTree() {

  const GICS = [
    {
      id: 10,
      name_en: "Energy",
      name_de: "Energie",
      apiKeys: ["energyKey1", "energyKey2"],
      children: [
        {
          id: 1010,
          name_en: "Energy",
          name_de: "Energie",
          apiKeys: ["energyGroupKey1", "energyGroupKey2"],
          children: [
            {
              id: 101010,
              name_en: "Energy Equipment & Services",
              name_de: "Energieausrüstung und -dienstleistungen",
              apiKeys: ["energyEquipAndServicesKey1", "energyEquipAndServicesKey2"],
              children: [
                {
                  id: 10101010,
                  name_en: "Oil & Gas Drilling",
                  name_de: "Öl- und Gasbohrungen",
                  apiKeys: ["Oil & Gas Drilling"]
                },
                {
                  id: 10101020,
                  name_en: "Oil & Gas Equipment & Services",
                  name_de: "Öl- und Gasausstattung und -dienstleistungen",
                  apiKeys: ["oilGasEquipAndServicesKey1", "oilGasEquipAndServicesKey2"]
                }
              ]
            },
            {
              id: 101020,
              name_en: "Oil, Gas & Consumable Fuels",
              name_de: "Öl, Gas & Verbrauchsbrennstoffe",
              apiKeys: ["Oil & Gas"],
              children: [
                {
                  id: 10102010,
                  name_en: "Integrated Oil & Gas",
                  name_de: "Integriertes Öl und Gas",
                  apiKeys: ["integratedOilGasKey1", "integratedOilGasKey2"]
                },
                {
                  id: 10102020,
                  name_en: "Oil & Gas Exploration & Production",
                  name_de: "Öl- und Gasexploration und -produktion",
                  apiKeys: ["Oil & Gas E&P, Independent Oil & Gas"]
                },
                {
                  id: 10102030,
                  name_en: "Oil & Gas Refining & Marketing",
                  name_de: "Öl- und Gasraffination und -vermarktung",
                  apiKeys: ["integratedOilGasKey1", "integratedOilGasKey2"]
                },
                {
                  id: 10102040,
                  name_en: "Oil & Gas Storage & Transportation",
                  name_de: "Öl- und Gastanks und -transport",
                  apiKeys: ["integratedOilGasKey1", "integratedOilGasKey2"]
                },
                {
                  id: 10102050,
                  name_en: "Coal & Consumable Fuels",
                  name_de: "Kohle und Verbrauchsbrennstoffe",
                  apiKeys: ["integratedOilGasKey1", "integratedOilGasKey2"]
                },
              ]
            }
          ]
        }
      ]
    },
    {
      id: 15,
      name_en: "Materials",
      name_de: "Materialien",
      apiKeys: ["materialsKey1", "materialsKey2"],
      children: [
        {
          id: 1510,
          name_en: "Materials",
          name_de: "Materialien",
          apiKeys: ["materialsGroupKey1", "materialsGroupKey2"],
          children: [
            {
              id: 151010,
              name_en: "Chemicals",
              name_de: "Chemikalien",
              apiKeys: ["Chemicals", "Chemicals - Major Diversified"],
              children: [
                {
                  id: 15101010,
                  name_en: "Commodity Chemicals",
                  name_de: "Grundchemikalien",
                  apiKeys: ["Agricultural Inputs"]
                },
                {
                  id: 15101020,
                  name_en: "Diversified Chemicals",
                  name_de: "Verschiedene Chemikalien",
                  apiKeys: ["diversifiedChemicalsKey1", "diversifiedChemicalsKey2"]
                },
                {
                  id: 15101030,
                  name_en: "Fertilizers & Agricultural Chemicals",
                  name_de: "Düngemittel & Agrarchemikalien",
                  apiKeys: ["Agricultural Chemicals"]
                },
                {
                  id: 15101040,
                  name_en: "Industrial Gases",
                  name_de: "Industriegase",
                  apiKeys: ["industrialGasesKey1", "industrialGasesKey2"]
                },
                {
                  id: 15101050,
                  name_en: "Specialty Chemicals",
                  name_de: "Spezialchemikalien",
                  apiKeys: ["specialtyChemicalsKey1", "specialtyChemicalsKey2"]
                }
              ]
            },
            {
              id: 151020,
              name_en: "Construction Materials",
              name_de: "Baustoffe",
              apiKeys: ["constructionMaterialsKey1", "constructionMaterialsKey2"],
              children: [
                {
                  id: 15102010,
                  name_en: "Construction Materials",
                  name_de: "Baustoffe",
                  apiKeys: ["constructionMaterialsKey1", "constructionMaterialsKey2"]
                }
              ]
            },
            {
              id: 151030,
              name_en: "Containers & Packaging",
              name_de: "Behälter & Verpackungen",
              apiKeys: ["containersPackagingKey1", "containersPackagingKey2"],
              children: [
                {
                  id: 15103010,
                  name_en: "Metal, Glass & Plastic Containers",
                  name_de: "Metall-, Glas- & Kunststoffbehälter",
                  apiKeys: ["metalGlassPlasticContainersKey1", "metalGlassPlasticContainersKey2"]
                },
                {
                  id: 15103020,
                  name_en: "Paper & Plastic Packaging Products & Materials",
                  name_de: "Papier- & Kunststoffverpackungsprodukte & -materialien",
                  apiKeys: ["paperPlasticPackagingKey1", "paperPlasticPackagingKey2"]
                }
              ]
            },
            {
              id: 151040,
              name_en: "Metals & Mining",
              name_de: "Metalle & Bergbau",
              apiKeys: ["Metals & Mining"],
              children: [
                {
                  id: 15104010,
                  name_en: "Aluminum",
                  name_de: "Aluminium",
                  apiKeys: ["Aluminum"]
                },
                {
                  id: 15104020,
                  name_en: "Diversified Metals & Mining",
                  name_de: "Diversifizierte Metalle & Bergbau",
                  apiKeys: ["Other Precious Metals & Mining", "Other Industrial Metals & Mining"]
                },
                {
                  id: 15104025,
                  name_en: "Copper",
                  name_de: "Kupfer",
                  apiKeys: ["Copper"]
                },
                {
                  id: 15104030,
                  name_en: "Gold",
                  name_de: "Gold",
                  apiKeys: ["Gold"]
                },
                {
                  id: 15104040,
                  name_en: "Precious Metals & Minerals",
                  name_de: "Edelmetalle & Mineralien",
                  apiKeys: ["preciousMetalsMineralsKey1", "preciousMetalsMineralsKey2"]
                },
                {
                  id: 15104045,
                  name_en: "Silver",
                  name_de: "Silber",
                  apiKeys: ["Silver", "silverKey2"]
                },
                {
                  id: 15104050,
                  name_en: "Steel",
                  name_de: "Stahl",
                  apiKeys: ["Steel & Iron", "Steel"]
                }
              ]
            },
            {
              id: 151050,
              name_en: "Paper & Forest Products",
              name_de: "Papier- & Forstprodukte",
              apiKeys: ["paperForestProductsKey1", "paperForestProductsKey2"],
              children: [
                {
                  id: 15105010,
                  name_en: "Forest Products",
                  name_de: "Forstprodukte",
                  apiKeys: ["forestProductsKey1", "forestProductsKey2"]
                },
                {
                  id: 15105020,
                  name_en: "Paper Products",
                  name_de: "Papierprodukte",
                  apiKeys: ["paperProductsKey1", "paperProductsKey2"]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 20,
      name_en: "Industrials",
      name_de: "Industrie",
      apiKeys: ["industrialsKey1", "industrialsKey2"],
      children: [
        {
          id: 2010,
          name_en: "Capital Goods",
          name_de: "Kapitalgüter",
          apiKeys: ["Capital Goods"],
          children: [
            {
              id: 201010,
              name_en: "Aerospace & Defense",
              name_de: "Luftfahrt & Verteidigung",
              apiKeys: ["Aerospace & Defense", "Aerospace/Defense - Major Diversified"],
            },
            {
              id: 201020,
              name_en: "Building Products",
              name_de: "Bauprodukte",
              apiKeys: ["buildingProductsKey1", "buildingProductsKey2"],
            },
            {
              id: 201030,
              name_en: "Construction & Engineering",
              name_de: "Bau & Ingenieurwesen",
              apiKeys: ["Building Materials", "Building Materials Wholesale", "Building Products & Equipment"],
            },
            {
              id: 201040,
              name_en: "Electrical Equipment",
              name_de: "Elektrische Ausrüstung",
              apiKeys: ["electricalEquipmentKey1", "electricalEquipmentKey2"],
              children: [
                {
                  id: 20104010,
                  name_en: "Electrical Components & Equipment",
                  name_de: "Elektrische Komponenten & Ausrüstung",
                  apiKeys: ["electricalComponentsEquipmentKey1", "electricalComponentsEquipmentKey2"],
                },
                {
                  id: 20104020,
                  name_en: "Heavy Electrical Equipment",
                  name_de: "Schwere elektrische Ausrüstung",
                  apiKeys: ["heavyElectricalEquipmentKey1", "heavyElectricalEquipmentKey2"],
                }
              ]
            },
            {
              id: 201050,
              name_en: "Industrial Conglomerates",
              name_de: "Industriekonglomerate",
              apiKeys: ["industrialConglomeratesKey1", "industrialConglomeratesKey2"],
            },
            {
              id: 201060,
              name_en: "Machinery",
              name_de: "Maschinenbau",
              apiKeys: ["machineryKey1", "machineryKey2"],
              children: [
                {
                  id: 20106010,
                  name_en: "Construction Machinery & Heavy Trucks",
                  name_de: "Baumaschinen & Schwere Lkw",
                  apiKeys: ["Specialty Industrial Machinery"],
                },
                {
                  id: 20106015,
                  name_en: "Agricultural & Farm Machinery",
                  name_de: "Land- und Forstmaschinen",
                  apiKeys: ["agriculturalFarmMachineryKey1", "agriculturalFarmMachineryKey2"],
                },
                {
                  id: 20106020,
                  name_en: "Industrial Machinery & Supplies & Components",
                  name_de: "Industriemaschinen & Versorgungsunternehmen & Komponenten",
                  apiKeys: ["industrialMachinerySuppliesComponentsKey1", "industrialMachinerySuppliesComponentsKey2"],
                }
              ]
            },
            {
              id: 201070,
              name_en: "Trading Companies & Distributors",
              name_de: "Handelsgesellschaften & Distributoren",
              apiKeys: ["tradingCompaniesDistributorsKey1", "tradingCompaniesDistributorsKey2"],
            }
          ]
        },
        {
          id: 2020,
          name_en: "Commercial & Professional Services",
          name_de: "Handels- & professionelle Dienstleistungen",
          apiKeys: ["commercialProfessionalServicesKey1", "commercialProfessionalServicesKey2"],
          children: [
            {
              id: 202010,
              name_en: "Commercial Services & Supplies",
              name_de: "Gewerbliche Dienstleistungen und Verbrauchsmaterialien",
              apiKeys: ["Business Equipment & Supplies"],
              children: [
                {
                  id: 20201010,
                  name_en: "Commercial Printing",
                  name_de: "Kommerzieller Druck",
                  apiKeys: ["commercialPrintingKey1", "commercialPrintingKey2"],
                },
                {
                  id: 20201050,
                  name_en: "Environmental & Facilities Services",
                  name_de: "Umwelt- und Betriebsdienste",
                  apiKeys: ["environmentalFacilitiesServicesKey1", "environmentalFacilitiesServicesKey2"],
                },
                {
                  id: 20201060,
                  name_en: "Office Services & Supplies",
                  name_de: "Bürodienstleistungen und -verbrauchsmaterialien",
                  apiKeys: ["officeServicesSuppliesKey1", "officeServicesSuppliesKey2"],
                },
                {
                  id: 20201070,
                  name_en: "Diversified Support Services",
                  name_de: "Diversifizierte Unterstützungsdienste",
                  apiKeys: ["diversifiedSupportServicesKey1", "diversifiedSupportServicesKey2"],
                },
                {
                  id: 20201080,
                  name_en: "Security & Alarm Services",
                  name_de: "Sicherheits- und Alarmanlagendienste",
                  apiKeys: ["securityAlarmServicesKey1", "securityAlarmServicesKey2"],
                }
              ]
            },
            {
              id: 202020,
              name_en: "Professional Services",
              name_de: "Professionelle Dienstleistungen",
              apiKeys: ["Business Services"],
              children: [
                {
                  id: 20202010,
                  name_en: "Human Resource & Employment Services",
                  name_de: "Personal- und Beschäftigungsdienstleistungen",
                  apiKeys: ["humanResourceEmploymentServicesKey1", "humanResourceEmploymentServicesKey2"],
                },
                {
                  id: 20202020,
                  name_en: "Research & Consulting Services",
                  name_de: "Forschungs- und Beratungsdienstleistungen",
                  apiKeys: ["researchConsultingServicesKey1", "researchConsultingServicesKey2"],
                }
              ]
            }
          ]
        },
        {
          id: 2030,
          name_en: "Transportation",
          name_de: "Transport",
          apiKeys: ["transportationKey1", "transportationKey2"],
          children: [
            {
              id: 203010,
              name_en: "Air Freight & Logistics",
              name_de: "Luftfracht und Logistik",
              apiKeys: ["Air Delivery & Freight Services"],
            },
            {
              id: 203020,
              name_en: "Passenger Airlines",
              name_de: "Passagierfluggesellschaften",
              apiKeys: ["Airlines"],
            },
            {
              id: 203030,
              name_en: "Marine Transportation",
              name_de: "Seeverkehr",
              apiKeys: ["marineTransportationKey1", "marineTransportationKey2"],
            },
            {
              id: 203040,
              name_en: "Ground Transportation",
              name_de: "Landverkehr",
              apiKeys: ["groundTransportationKey1", "groundTransportationKey2"],
              children: [
                {
                  id: 20304010,
                  name_en: "Road Transportation",
                  name_de: "Straßenverkehr",
                  apiKeys: ["roadTransportationKey1", "roadTransportationKey2"],
                },
                {
                  id: 20304020,
                  name_en: "Rail Transportation",
                  name_de: "Schienenverkehr",
                  apiKeys: ["railTransportationKey1", "railTransportationKey2"],
                },
                {
                  id: 20304030,
                  name_en: "Public Transportation",
                  name_de: "Öffentlicher Verkehr",
                  apiKeys: ["publicTransportationKey1", "publicTransportationKey2"],
                }
              ]
            },
            {
              id: 203050,
              name_en: "Transportation Infrastructure",
              name_de: "Verkehrsinfrastruktur",
              apiKeys: ["transportationInfrastructureKey1", "transportationInfrastructureKey2"],
              children: [
                {
                  id: 20305010,
                  name_en: "Airport Services",
                  name_de: "Flughafendienste",
                  apiKeys: ["Airports & Air Services"],
                },
                {
                  id: 20305020,
                  name_en: "Highways & Railtracks",
                  name_de: "Autobahnen & Eisenbahnstrecken",
                  apiKeys: ["highwaysRailtracksKey1", "highwaysRailtracksKey2"],
                },
                {
                  id: 20305030,
                  name_en: "Marine Ports & Services",
                  name_de: "Seehäfen & Dienstleistungen",
                  apiKeys: ["marinePortsServicesKey1", "marinePortsServicesKey2"],
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 25,
      name_en: "Consumer Discretionary",
      name_de: "Konsumgüter",
      apiKeys: ["consumerDiscretionaryKey1", "consumerDiscretionaryKey2"],
      children: [
        {
          id: 2510,
          name_en: "Automobiles & Components",
          name_de: "Automobile & Komponenten",
          apiKeys: ["automobilesComponentsKey1", "automobilesComponentsKey2"],
          children: [
            {
              id: 251010,
              name_en: "Automobiles",
              name_de: "Automobile",
              apiKeys: ["automobilesKey1", "automobilesKey2"],
              children: [
                {
                  id: 25101010,
                  name_en: "Automobile Manufacturers",
                  name_de: "Automobilhersteller",
                  apiKeys: ["automobileManufacturersKey1", "automobileManufacturersKey2"],
                },
                {
                  id: 25101020,
                  name_en: "Motorcycle Manufacturers",
                  name_de: "Motorradhersteller",
                  apiKeys: ["motorcycleManufacturersKey1", "motorcycleManufacturersKey2"],
                },
              ],
            },
            {
              id: 251020,
              name_en: "Auto Components",
              name_de: "Auto-Komponenten",
              apiKeys: ["autoComponentsKey1", "autoComponentsKey2"],
              children: [
                {
                  id: 25102010,
                  name_en: "Auto Parts & Equipment",
                  name_de: "Auto-Ersatzteile & -Ausrüstung",
                  apiKeys: ["autoPartsEquipmentKey1", "autoPartsEquipmentKey2"],
                },
                {
                  id: 25102020,
                  name_en: "Tires & Rubber",
                  name_de: "Reifen & Gummi",
                  apiKeys: ["tiresRubberKey1", "tiresRubberKey2"],
                },
              ],
            }
          ]
        },
        {
          id: 2520,
          name_en: "Consumer Durables & Apparel",
          name_de: "Konsumgüter & Bekleidung",
          apiKeys: ["consumerDurablesApparelKey1", "consumerDurablesApparelKey2"],
          children: [
            {
              id: 252010,
              name_en: "Consumer Durables",
              name_de: "Gebrauchsgüter",
              apiKeys: ["consumerDurablesKey1", "consumerDurablesKey2"],
              children: [
                {
                  id: 25201010,
                  name_en: "Consumer Electronics",
                  name_de: "Verbraucherelektronik",
                  apiKeys: ["consumerElectronicsKey1", "consumerElectronicsKey2"],
                },
                {
                  id: 25201020,
                  name_en: "Home Furnishings",
                  name_de: "Heimtextilien",
                  apiKeys: ["homeFurnishingsKey1", "homeFurnishingsKey2"],
                },
                {
                  id: 25201030,
                  name_en: "Homebuilding",
                  name_de: "Hausbau",
                  apiKeys: ["homebuildingKey1", "homebuildingKey2"],
                },
                {
                  id: 25201040,
                  name_en: "Household Appliances",
                  name_de: "Haushaltsgeräte",
                  apiKeys: ["householdAppliancesKey1", "householdAppliancesKey2"],
                },
                {
                  id: 25201050,
                  name_en: "Housewares & Specialties",
                  name_de: "Haushaltswaren & Spezialitäten",
                  apiKeys: ["housewaresSpecialtiesKey1", "housewaresSpecialtiesKey2"],
                },
              ]
            },
            {
              id: 252020,
              name_en: "Leisure Products",
              name_de: "Freizeitprodukte",
              apiKeys: ["leisureProductsKey1", "leisureProductsKey2"],
              children: [
                {
                  id: 25202010,
                  name_en: "Leisure Products",
                  name_de: "Freizeitprodukte",
                  apiKeys: ["leisureProductsChildKey1", "leisureProductsChildKey2"],
                },
              ]
            },
            {
              id: 252030,
              name_en: "Textiles, Apparel & Luxury Goods",
              name_de: "Textilien, Bekleidung & Luxusgüter",
              apiKeys: ["textilesApparelLuxuryGoodsKey1", "textilesApparelLuxuryGoodsKey2"],
              children: [
                {
                  id: 25203010,
                  name_en: "Apparel, Accessories & Luxury Goods",
                  name_de: "Bekleidung, Accessoires & Luxusgüter",
                  apiKeys: ["Apparel Manufacturing"],
                },
                {
                  id: 25203020,
                  name_en: "Footwear",
                  name_de: "Schuhe",
                  apiKeys: ["footwearKey1", "footwearKey2"],
                },
                {
                  id: 25203030,
                  name_en: "Textiles",
                  name_de: "Textilien",
                  apiKeys: ["textilesKey1", "textilesKey2"],
                },
              ]
            }
          ]
        },
        {
          id: 2530,
          name_en: "Consumer Services",
          name_de: "Verbraucherdienste",
          apiKeys: ["consumerServicesKey1", "consumerServicesKey2"],
          children: [
            {
              id: 253010,
              name_en: "Hotels, Restaurants & Leisure",
              name_de: "Hotels, Restaurants & Freizeit",
              apiKeys: ["hotelsRestaurantsLeisureKey1", "hotelsRestaurantsLeisureKey2"],
              children: [
                {
                  id: 25301010,
                  name_en: "Casinos & Gaming",
                  name_de: "Casinos & Spiele",
                  apiKeys: ["casinosGamingKey1", "casinosGamingKey2"],
                },
                {
                  id: 25301020,
                  name_en: "Hotels, Resorts & Cruise Lines",
                  name_de: "Hotels, Resorts & Kreuzfahrtlinien",
                  apiKeys: ["hotelsResortsCruiseLinesKey1", "hotelsResortsCruiseLinesKey2"],
                },
                {
                  id: 25301030,
                  name_en: "Leisure Facilities",
                  name_de: "Freizeiteinrichtungen",
                  apiKeys: ["leisureFacilitiesKey1", "leisureFacilitiesKey2"],
                },
                {
                  id: 25301040,
                  name_en: "Restaurants",
                  name_de: "Restaurants",
                  apiKeys: ["restaurantsKey1", "restaurantsKey2"],
                },
              ]
            },
            {
              id: 253020,
              name_en: "Diversified Consumer Services",
              name_de: "Diversifizierte Verbraucherdienste",
              apiKeys: ["diversifiedConsumerServicesKey1", "diversifiedConsumerServicesKey2"],
              children: [
                {
                  id: 25302010,
                  name_en: "Education Services",
                  name_de: "Bildungsdienstleistungen",
                  apiKeys: ["educationServicesKey1", "educationServicesKey2"],
                },
                {
                  id: 25302020,
                  name_en: "Specialized Consumer Services",
                  name_de: "Spezialisierte Verbraucherdienste",
                  apiKeys: ["specializedConsumerServicesKey1", "specializedConsumerServicesKey2"],
                },
              ]
            }
          ]
        },
        {
          id: 2550,
          name_en: "Consumer Discretionary Distribution & Retail",
          name_de: "Konsumgüterverteilung & Einzelhandel",
          apiKeys: ["consumerDiscretionaryDistributionRetailKey1", "consumerDiscretionaryDistributionRetailKey2"],
          children: [
            {
              id: 255010,
              name_en: "Distributors",
              name_de: "Verteiler",
              apiKeys: ["distributorsKey1", "distributorsKey2"],
              children: [
                {
                  id: 25501010,
                  name_en: "Distributors",
                  name_de: "Verteiler",
                  apiKeys: ["distributorsSubKey1", "distributorsSubKey2"]
                }
              ]
            },
            {
              id: 255030,
              name_en: "Broadline Retail",
              name_de: "Breitband-Einzelhandel",
              apiKeys: ["broadlineRetailKey1", "broadlineRetailKey2"],
              children: [
                {
                  id: 25503030,
                  name_en: "Broadline Retail",
                  name_de: "Breitband-Einzelhandel",
                  apiKeys: ["Internet Retail"]
                }
              ]
            },
            {
              id: 255040,
              name_en: "Specialty Retail",
              name_de: "Spezialhandel",
              apiKeys: ["specialtyRetailKey1", "specialtyRetailKey2"],
              children: [
                {
                  id: 25504010,
                  name_en: "Apparel Retail",
                  name_de: "Bekleidungseinzelhandel",
                  apiKeys: ["Apparel Retail", "Apparel Stores"]
                },
                {
                  id: 25504020,
                  name_en: "Computer & Electronics Retail",
                  name_de: "Computer- & Elektronikeinzelhandel",
                  apiKeys: ["computerElectronicsRetailKey1", "computerElectronicsRetailKey2"]
                },
                {
                  id: 25504030,
                  name_en: "Home Improvement Retail",
                  name_de: "Heimwerker-Einzelhandel",
                  apiKeys: ["homeImprovementRetailKey1", "homeImprovementRetailKey2"]
                },
                {
                  id: 25504040,
                  name_en: "Other Specialty Retail",
                  name_de: "Anderer Spezialhandel",
                  apiKeys: ["otherSpecialtyRetailKey1", "otherSpecialtyRetailKey2"]
                },
                {
                  id: 25504050,
                  name_en: "Automotive Retail",
                  name_de: "Autohandel",
                  apiKeys: ["automotiveRetailKey1", "automotiveRetailKey2"]
                },
                {
                  id: 25504060,
                  name_en: "Homefurnishing Retail",
                  name_de: "Einrichtungseinzelhandel",
                  apiKeys: ["homefurnishingRetailKey1", "homefurnishingRetailKey2"]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 30,
      name_en: "Consumer Staples",
      name_de: "Konsumgüter des täglichen Bedarfs",
      apiKeys: ["consumerStaplesKey1", "consumerStaplesKey2"],
      children: [
        {
          id: 3010,
          name_en: "Consumer Staples Distribution & Retail",
          name_de: "Verteilung und Einzelhandel von Konsumgütern",
          apiKeys: ["consumerStaplesDistributionRetailKey1", "consumerStaplesDistributionRetailKey2"],
          children: [
            {
              id: 301010,
              name_en: "Consumer Staples Distribution & Retail",
              name_de: "Verteilung und Einzelhandel von Konsumgütern",
              apiKeys: ["consumerStaplesDistributionRetailSubKey1", "consumerStaplesDistributionRetailSubKey2"],
              children: [
                {
                  id: 30101010,
                  name_en: "Drug Retail",
                  name_de: "Drogerie-Einzelhandel",
                  apiKeys: ["drugRetailKey1", "drugRetailKey2"]
                },
                {
                  id: 30101020,
                  name_en: "Food Distributors",
                  name_de: "Lebensmittel-Verteiler",
                  apiKeys: ["foodDistributorsKey1", "foodDistributorsKey2"]
                },
                {
                  id: 30101030,
                  name_en: "Food Retail",
                  name_de: "Lebensmittel-Einzelhandel",
                  apiKeys: ["foodRetailKey1", "foodRetailKey2"]
                },
                {
                  id: 30101040,
                  name_en: "Consumer Staples Merchandise Retail",
                  name_de: "Einzelhandel mit Konsumgütern",
                  apiKeys: ["consumerStaplesMerchandiseRetailKey1", "consumerStaplesMerchandiseRetailKey2"]
                }
              ]
            },
            {
              id: 3020,
              name_en: "Food, Beverage & Tobacco",
              name_de: "Lebensmittel, Getränke und Tabak",
              apiKeys: ["foodBeverageTobaccoKey1", "foodBeverageTobaccoKey2"],
              children: [
                {
                  id: 302010,
                  name_en: "Beverages",
                  name_de: "Getränke",
                  apiKeys: ["beveragesKey1", "beveragesKey2"],
                  children: [
                    {
                      id: 30201010,
                      name_en: "Brewers",
                      name_de: "Brauereien",
                      apiKeys: ["Beverages - Brewers", "Beverages—Brewers"]
                    },
                    {
                      id: 30201020,
                      name_en: "Distillers & Vintners",
                      name_de: "Brennereien und Winzer",
                      apiKeys: ["Beverages—Wineries & Distilleries"]
                    },
                    {
                      id: 30201030,
                      name_en: "Soft Drinks & Non-alcoholic Beverages",
                      name_de: "Erfrischungsgetränke und alkoholfreie Getränke",
                      apiKeys: ["Beverages Non-Alcoholic", "Beverages—Non-Alcoholic"]
                    }
                  ]
                },
                {
                  id: 302020,
                  name_en: "Food Products",
                  name_de: "Lebensmittel",
                  apiKeys: ["foodProductsKey1", "foodProductsKey2"],
                  children: [
                    {
                      id: 30202010,
                      name_en: "Agricultural Products & Services",
                      name_de: "Landwirtschaftliche Produkte und Dienstleistungen",
                      apiKeys: ["agriculturalProductsServicesKey1", "agriculturalProductsServicesKey2"]
                    },
                    {
                      id: 30202030,
                      name_en: "Packaged Foods & Meats",
                      name_de: "Verpackte Lebensmittel und Fleischprodukte",
                      apiKeys: ["packagedFoodsMeatsKey1", "packagedFoodsMeatsKey2"]
                    }
                  ]
                },
                {
                  id: 302030,
                  name_en: "Tobacco",
                  name_de: "Tabak",
                  apiKeys: ["tobaccoKey1", "tobaccoKey2"]
                }
              ]
            },
            {
              id: 3030,
              name_en: "Household & Personal Products",
              name_de: "Haushalts- und Körperpflegeprodukte",
              apiKeys: ["householdPersonalProductsKey1", "householdPersonalProductsKey2"],
              children: [
                {
                  id: 303010,
                  name_en: "Household Products",
                  name_de: "Haushaltsprodukte",
                  apiKeys: ["householdProductsKey1", "householdProductsKey2"],
                  children: [
                    {
                      id: 30301010,
                      name_en: "Household Products",
                      name_de: "Haushaltsprodukte",
                      apiKeys: ["householdProductsKey1", "householdProductsKey2"]
                    }
                  ]
                },
                {
                  id: 303020,
                  name_en: "Personal Care Products",
                  name_de: "Körperpflegeprodukte",
                  apiKeys: ["personalCareProductsKey1", "personalCareProductsKey2"],
                  children: [
                    {
                      id: 30302010,
                      name_en: "Personal Care Products",
                      name_de: "Körperpflegeprodukte",
                      apiKeys: ["personalCareProductsKey1", "personalCareProductsKey2"]
                    }
                  ]
                }
              ]
            }
          ]
        },
      ]
    },
    {
      id: 35,
      name_en: "Health Care",
      name_de: "Gesundheitspflege",
      apiKeys: ["healthCareKey1", "healthCareKey2"],
      children: [
        {
          id: 3510,
          name_en: "Health Care Equipment & Services",
          name_de: "Gesundheitsgeräte & Dienstleistungen",
          apiKeys: ["healthCareEquipmentServicesKey1", "healthCareEquipmentServicesKey2"],
          children: [
            {
              id: 351010,
              name_en: "Health Care Equipment & Supplies",
              name_de: "Medizinische Geräte & Zubehör",
              apiKeys: ["healthCareEquipmentSuppliesKey1", "healthCareEquipmentSuppliesKey2"],
              children: [
                {
                  id: 35101010,
                  name_en: "Health Care Equipment",
                  name_de: "Medizinische Geräte",
                  apiKeys: ["healthCareEquipmentKey1", "healthCareEquipmentKey2"]
                },
                {
                  id: 35101020,
                  name_en: "Health Care Supplies",
                  name_de: "Medizinisches Zubehör",
                  apiKeys: ["healthCareSuppliesKey1", "healthCareSuppliesKey2"]
                }
              ]
            },
            {
              id: 351020,
              name_en: "Health Care Providers & Services",
              name_de: "Gesundheitsdienstleister & Dienstleistungen",
              apiKeys: ["healthCareProvidersServicesKey1", "healthCareProvidersServicesKey2"],
              children: [
                {
                  id: 35102010,
                  name_en: "Health Care Distributors",
                  name_de: "Medizinische Händler",
                  apiKeys: ["healthCareDistributorsKey1", "healthCareDistributorsKey2"]
                },
                {
                  id: 35102015,
                  name_en: "Health Care Services",
                  name_de: "Gesundheitsdienstleistungen",
                  apiKeys: ["healthCareServicesKey1", "healthCareServicesKey2"]
                },
                {
                  id: 35102020,
                  name_en: "Health Care Facilities",
                  name_de: "Gesundheitseinrichtungen",
                  apiKeys: ["healthCareFacilitiesKey1", "healthCareFacilitiesKey2"]
                },
                {
                  id: 35102030,
                  name_en: "Managed Health Care",
                  name_de: "Verwaltete Gesundheitsversorgung",
                  apiKeys: ["managedHealthCareKey1", "managedHealthCareKey2"]
                }
              ]
            },
            {
              id: 351030,
              name_en: "Health Care Technology",
              name_de: "Medizintechnik",
              apiKeys: ["healthCareTechnologyKey1", "healthCareTechnologyKey2"],
              children: [
                {
                  id: 35103010,
                  name_en: "Health Care Technology",
                  name_de: "Medizintechnik",
                  apiKeys: ["healthCareTechnologyKey1", "healthCareTechnologyKey2"]
                }
              ]
            }
          ]
        },
        {
          id: 3520,
          name_en: "Pharmaceuticals, Biotechnology & Life Sciences",
          name_de: "Pharmazeutika, Biotechnologie & Life Sciences",
          apiKeys: ["pharmaceuticalsBiotechnologyLifeSciencesKey1", "pharmaceuticalsBiotechnologyLifeSciencesKey2"],
          children: [
            {
              id: 352010,
              name_en: "Biotechnology",
              name_de: "Biotechnologie",
              apiKeys: ["biotechnologyKey1", "biotechnologyKey2"],
              children: [
                {
                  id: 35201010,
                  name_en: "Biotechnology",
                  name_de: "Biotechnologie",
                  apiKeys: ["biotechnologyKey1", "biotechnologyKey2"]
                }
              ]
            },
            {
              id: 352020,
              name_en: "Pharmaceuticals",
              name_de: "Pharmazeutika",
              apiKeys: ["Biotechnology"],
              children: [
                {
                  id: 35202010,
                  name_en: "Pharmaceuticals",
                  name_de: "Pharmazeutika",
                  apiKeys: ["pharmaceuticalsKey1", "pharmaceuticalsKey2"]
                }
              ]
            },
            {
              id: 352030,
              name_en: "Life Sciences Tools & Services",
              name_de: "Life Sciences Tools & Services",
              apiKeys: ["lifeSciencesToolsServicesKey1", "lifeSciencesToolsServicesKey2"],
              children: [
                {
                  id: 35203010,
                  name_en: "Life Sciences Tools & Services",
                  name_de: "Life Sciences Tools & Services",
                  apiKeys: ["lifeSciencesToolsServicesKey1", "lifeSciencesToolsServicesKey2"]
                }
              ]
            }
          ]
        }        
      ]
    },
    {
      id: 40,
      name_en: "Financials",
      name_de: "Finanzwesen",
      apiKeys: ["financialsKey1", "financialsKey2"],
      children: [
        {
          id: 4010,
          name_en: "Banks",
          name_de: "Banken",
          apiKeys: ["banksKey1", "banksKey2"],
          children: [
            {
              id: 401010,
              name_en: "Banks",
              name_de: "Banken",
              apiKeys: ["Banks"],
              children: [
                {
                  id: 40101010,
                  name_en: "Diversified Banks",
                  name_de: "Diversifizierte Banken",
                  apiKeys: ["Banks—Diversified"]
                },
                {
                  id: 40101015,
                  name_en: "Regional Banks",
                  name_de: "Regionalbanken",
                  apiKeys: ["Banks—Regional"]
                }
              ]
            }
          ]
        },
        {
          id: 4020,
          name_en: "Financial Services",
          name_de: "Finanzdienstleistungen",
          apiKeys: ["financialServicesKey1", "financialServicesKey2"],
          children: [
            {
              id: 402010,
              name_en: "Financial Services",
              name_de: "Finanzdienstleistungen",
              apiKeys: ["financialServicesKey1", "financialServicesKey2"],
              children: [
                {
                  id: 40201020,
                  name_en: "Diversified Financial Services",
                  name_de: "Diversifizierte Finanzdienstleistungen",
                  apiKeys: ["diversifiedFinancialServicesKey1", "diversifiedFinancialServicesKey2"]
                },
                {
                  id: 40201030,
                  name_en: "Multi-Sector Holdings",
                  name_de: "Multisektor-Holdings",
                  apiKeys: ["multiSectorHoldingsKey1", "multiSectorHoldingsKey2"]
                },
                {
                  id: 40201040,
                  name_en: "Specialized Finance",
                  name_de: "Spezialfinanzierung",
                  apiKeys: ["specializedFinanceKey1", "specializedFinanceKey2"]
                },
                {
                  id: 40201050,
                  name_en: "Commercial & Residential Mortgage Finance",
                  name_de: "Gewerbliche & Wohnhypothekenfinanzierung",
                  apiKeys: ["commercialResidentialMortgageFinanceKey1", "commercialResidentialMortgageFinanceKey2"]
                },
                {
                  id: 40201060,
                  name_en: "Transaction & Payment Processing Services",
                  name_de: "Transaktions- & Zahlungsabwicklungsdienste",
                  apiKeys: ["transactionPaymentProcessingServicesKey1", "transactionPaymentProcessingServicesKey2"]
                }
              ]
            },
            {
              id: 402020,
              name_en: "Consumer Finance",
              name_de: "Verbraucherfinanzierung",
              apiKeys: ["consumerFinanceKey1", "consumerFinanceKey2"]
            },
            {
              id: 402030,
              name_en: "Capital Markets",
              name_de: "Kapitalmärkte",
              apiKeys: ["Capital Markets"],
              children: [
                {
                  id: 40203010,
                  name_en: "Asset Management & Custody Banks",
                  name_de: "Vermögensverwaltung & Verwahrstellenbanken",
                  apiKeys: ["assetManagementCustodyBanksKey1", "assetManagementCustodyBanksKey2"]
                },
                {
                  id: 40203020,
                  name_en: "Investment Banking & Brokerage",
                  name_de: "Investmentbanking & Brokerage",
                  apiKeys: ["investmentBankingBrokerageKey1", "investmentBankingBrokerageKey2"]
                },
                {
                  id: 40203030,
                  name_en: "Diversified Capital Markets",
                  name_de: "Diversifizierte Kapitalmärkte",
                  apiKeys: ["diversifiedCapitalMarketsKey1", "diversifiedCapitalMarketsKey2"]
                },
                {
                  id: 40203040,
                  name_en: "Financial Exchanges & Data",
                  name_de: "Finanzbörsen & Daten",
                  apiKeys: ["Closed-End Fund - Equity", "financialExchangesDataKey2"]
                }
              ]
            },
            {
              id: 402040,
              name_en: "Mortgage Real Estate Investment Trusts (REITs)",
              name_de: "Hypotheken-Real Estate Investment Trusts (REITs)",
              apiKeys: ["mortgageRealEstateInvestmentTrustsKey1", "mortgageRealEstateInvestmentTrustsKey2"],
              children: [
                {
                  id: 40204010,
                  name_en: "Mortgage REITs",
                  name_de: "Hypotheken-REITs",
                  apiKeys: ["mortgageREITsKey1", "mortgageREITsKey2"]
                }
              ]
            }
          ]
        },
        {
          id: 4030,
          name_en: "Insurance",
          name_de: "Versicherungswesen",
          apiKeys: ["insuranceKey1", "insuranceKey2"],
          children: [
            {
              id: 403010,
              name_en: "Insurance",
              name_de: "Versicherungswesen",
              apiKeys: ["insuranceKey1", "insuranceKey2"],
              children: [
                {
                  id: 40301010,
                  name_en: "Insurance Brokers",
                  name_de: "Versicherungsmakler",
                  apiKeys: ["insuranceBrokersKey1", "insuranceBrokersKey2"]
                },
                {
                  id: 40301020,
                  name_en: "Life & Health Insurance",
                  name_de: "Lebens- & Krankenversicherung",
                  apiKeys: ["lifeHealthInsuranceKey1", "lifeHealthInsuranceKey2"]
                },
                {
                  id: 40301030,
                  name_en: "Multi-line Insurance",
                  name_de: "Mehrspartenversicherung",
                  apiKeys: ["multiLineInsuranceKey1", "multiLineInsuranceKey2"]
                },
                {
                  id: 40301040,
                  name_en: "Property & Casualty Insurance",
                  name_de: "Sach- & Haftpflichtversicherung",
                  apiKeys: ["propertyCasualtyInsuranceKey1", "propertyCasualtyInsuranceKey2"]
                },
                {
                  id: 40301050,
                  name_en: "Reinsurance",
                  name_de: "Rückversicherung",
                  apiKeys: ["reinsuranceKey1", "reinsuranceKey2"]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 45,
      name_en: "Information Technology",
      name_de: "Informationstechnologie",
      apiKeys: ["informationTechnologyKey1", "informationTechnologyKey2"],
      children: [
        {
          id: 4510,
          name_en: "Software & Services",
          name_de: "Software & Dienstleistungen",
          apiKeys: ["Internet & Technology"],
          children: [
            {
              id: 451020,
              name_en: "IT Services",
              name_de: "IT-Dienstleistungen",
              apiKeys: ["itServicesKey1", "itServicesKey2"],
              children: [
                {
                  id: 45102010,
                  name_en: "IT Consulting & Other Services",
                  name_de: "IT-Beratung & Andere Dienstleistungen",
                  apiKeys: ["Information Technology Services", "Business Software & Services"]
                },
                {
                  id: 45102030,
                  name_en: "Internet Services & Infrastructure",
                  name_de: "Internetdienste & Infrastruktur",
                  apiKeys: ["Internet Software & Services", "Internet Content & Information"]
                }
              ]
            },
            {
              id: 451030,
              name_en: "Software",
              name_de: "Software",
              apiKeys: ["softwareKey1", "softwareKey2"],
              children: [
                {
                  id: 45103010,
                  name_en: "Application Software",
                  name_de: "Anwendungssoftware",
                  apiKeys: ["Software—Application"]
                },
                {
                  id: 45103020,
                  name_en: "Systems Software",
                  name_de: "Systemsoftware",
                  apiKeys: ["Software—Infrastructure", "Security Software & Services"]
                }
              ]
            }
          ]
        },
        {
          id: 4520,
          name_en: "Technology Hardware & Equipment",
          name_de: "Technologie-Hardware & -Ausrüstung",
          apiKeys: ["Technology Hardware & Equipment"],
          children: [
            {
              id: 452010,
              name_en: "Communications Equipment",
              name_de: "Kommunikationsausrüstung",
              apiKeys: ["communicationsEquipmentKey1", "communicationsEquipmentKey2"],
              children: [
                {
                  id: 45201020,
                  name_en: "Communications Equipment",
                  name_de: "Kommunikationsausrüstung",
                  apiKeys: ["communicationsEquipmentKey1", "communicationsEquipmentKey2"]
                }
              ]
            },
            {
              id: 452020,
              name_en: "Technology Hardware, Storage & Peripherals",
              name_de: "Technologie-Hardware, Speicher & Peripheriegeräte",
              apiKeys: ["technologyHardwareStoragePeripheralsKey1", "technologyHardwareStoragePeripheralsKey2"],
              children: [
                {
                  id: 45202030,
                  name_en: "Technology Hardware, Storage & Peripherals",
                  name_de: "Technologie-Hardware, Speicher & Peripheriegeräte",
                  apiKeys: ["technologyHardwareStoragePeripheralsKey1", "technologyHardwareStoragePeripheralsKey2"]
                }
              ]
            },
            {
              id: 452030,
              name_en: "Electronic Equipment, Instruments & Components",
              name_de: "Elektronische Geräte, Instrumente & Komponenten",
              apiKeys: ["electronicEquipmentInstrumentsComponentsKey1", "electronicEquipmentInstrumentsComponentsKey2"],
              children: [
                {
                  id: 45203010,
                  name_en: "Electronic Equipment & Instruments",
                  name_de: "Elektronische Geräte & Instrumente",
                  apiKeys: ["electronicEquipmentInstrumentsKey1", "electronicEquipmentInstrumentsKey2"]
                },
                {
                  id: 45203015,
                  name_en: "Electronic Components",
                  name_de: "Elektronische Bauteile",
                  apiKeys: ["electronicComponentsKey1", "electronicComponentsKey2"]
                },
                {
                  id: 45203020,
                  name_en: "Electronic Manufacturing Services",
                  name_de: "Elektronische Fertigungsdienstleistungen",
                  apiKeys: ["electronicManufacturingServicesKey1", "electronicManufacturingServicesKey2"]
                },
                {
                  id: 45203030,
                  name_en: "Technology Distributors",
                  name_de: "Technologie-Distributoren",
                  apiKeys: ["technologyDistributorsKey1", "technologyDistributorsKey2"]
                }
              ]
            }
          ]
        },
        {
          id: 4530,
          name_en: "Semiconductors & Semiconductor Equipment",
          name_de: "Halbleiter & Halbleiterausrüstung",
          apiKeys: ["semiconductorsSemiconductorEquipmentKey1", "semiconductorsSemiconductorEquipmentKey2"],
          children: [
            {
              id: 453010,
              name_en: "Semiconductors & Semiconductor Equipment",
              name_de: "Halbleiter & Halbleiterausrüstung",
              apiKeys: ["semiconductorsSemiconductorEquipmentKey1", "semiconductorsSemiconductorEquipmentKey2"],
              children: [
                {
                  id: 45301010,
                  name_en: "Semiconductor Materials & Equipment",
                  name_de: "Halbleitermaterialien & -ausrüstung",
                  apiKeys: ["semiconductorMaterialsEquipmentKey1", "semiconductorMaterialsEquipmentKey2"]
                },
                {
                  id: 45301020,
                  name_en: "Semiconductors",
                  name_de: "Halbleiter",
                  apiKeys: ["semiconductorsKey1", "semiconductorsKey2"]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 50,
      name_en: "Communication Services",
      name_de: "Kommunikationsdienste",
      apiKeys: ["communicationServicesKey1", "communicationServicesKey2"],
      children: [
        {
          id: 5010,
          name_en: "Telecommunication Services",
          name_de: "Telekommunikationsdienste",
          apiKeys: ["telecommunicationServicesKey1", "telecommunicationServicesKey2"],
          children: [
            {
              id: 501010,
              name_en: "Diversified Telecommunication Services",
              name_de: "Diversifizierte Telekommunikationsdienste",
              apiKeys: ["diversifiedTelecommunicationServicesKey1", "diversifiedTelecommunicationServicesKey2"],
              children: [
                {
                  id: 50101010,
                  name_en: "Alternative Carriers",
                  name_de: "Alternative Anbieter",
                  apiKeys: ["alternativeCarriersKey1", "alternativeCarriersKey2"]
                },
                {
                  id: 50101020,
                  name_en: "Integrated Telecommunication Services",
                  name_de: "Integrierte Telekommunikationsdienste",
                  apiKeys: ["integratedTelecommunicationServicesKey1", "integratedTelecommunicationServicesKey2"]
                }
              ]
            },
            {
              id: 501020,
              name_en: "Wireless Telecommunication Services",
              name_de: "Drahtlose Telekommunikationsdienste",
              apiKeys: ["wirelessTelecommunicationServicesKey1", "wirelessTelecommunicationServicesKey2"],
              children: [
                {
                  id: 50102010,
                  name_en: "Wireless Telecommunication Services",
                  name_de: "Drahtlose Telekommunikationsdienste",
                  apiKeys: ["wirelessTelecommunicationServicesKey1", "wirelessTelecommunicationServicesKey2"]
                }
              ]
            }
          ]
        },
        {
          id: 5020,
          name_en: "Media & Entertainment",
          name_de: "Medien & Unterhaltung",
          apiKeys: ["mediaEntertainmentKey1", "mediaEntertainmentKey2"],
          children: [
            {
              id: 502010,
              name_en: "Media",
              name_de: "Medien",
              apiKeys: ["Broadcasting"],
              children: [
                {
                  id: 50201010,
                  name_en: "Advertising",
                  name_de: "Werbung",
                  apiKeys: ["Advertising Agencies"]
                },
                {
                  id: 50201020,
                  name_en: "Broadcasting",
                  name_de: "Rundfunk",
                  apiKeys: ["Broadcasting - TV"]
                },
                {
                  id: 50201030,
                  name_en: "Cable & Satellite",
                  name_de: "Kabel & Satellit",
                  apiKeys: ["CATV Systems"]
                },
                {
                  id: 50201040,
                  name_en: "Publishing",
                  name_de: "Verlagswesen",
                  apiKeys: ["publishingKey1", "publishingKey2"]
                }
              ]
            },
            {
              id: 502020,
              name_en: "Entertainment",
              name_de: "Unterhaltung",
              apiKeys: ["entertainmentKey1", "entertainmentKey2"],
              children: [
                {
                  id: 50202010,
                  name_en: "Movies & Entertainment",
                  name_de: "Filme & Unterhaltung",
                  apiKeys: ["moviesEntertainmentKey1", "moviesEntertainmentKey2"]
                },
                {
                  id: 50202020,
                  name_en: "Interactive Home Entertainment",
                  name_de: "Interaktive Unterhaltungselektronik",
                  apiKeys: ["interactiveHomeEntertainmentKey1", "interactiveHomeEntertainmentKey2"]
                }
              ]
            },
            {
              id: 502030,
              name_en: "Interactive Media & Services",
              name_de: "Interaktive Medien & Dienste",
              apiKeys: ["interactiveMediaServicesKey1", "interactiveMediaServicesKey2"],
              children: [
                {
                  id: 50203010,
                  name_en: "Interactive Media & Services",
                  name_de: "Interaktive Medien & Dienste",
                  apiKeys: ["interactiveMediaServicesKey1", "interactiveMediaServicesKey2"]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 55,
      name_en: "Utilities",
      name_de: "Versorgungsunternehmen",
      apiKeys: ["utilitiesKey1", "utilitiesKey2"],
      children: [
        {
          id: 5510,
          name_en: "Utilities",
          name_de: "Versorgungsunternehmen",
          apiKeys: ["utilitiesKey1", "utilitiesKey2"],
          children: [
            {
              id: 551010,
              name_en: "Electric Utilities",
              name_de: "Elektrizitätsunternehmen",
              apiKeys: ["electricUtilitiesKey1", "electricUtilitiesKey2"],
              children: [
                {
                  id: 55101010,
                  name_en: "Electric Utilities",
                  name_de: "Elektrizitätsunternehmen",
                  apiKeys: ["electricUtilitiesKey1", "electricUtilitiesKey2"]
                }
              ]
            },
            {
              id: 551020,
              name_en: "Gas Utilities",
              name_de: "Gasversorgungsunternehmen",
              apiKeys: ["gasUtilitiesKey1", "gasUtilitiesKey2"],
              children: [
                {
                  id: 55102010,
                  name_en: "Gas Utilities",
                  name_de: "Gasversorgungsunternehmen",
                  apiKeys: ["gasUtilitiesKey1", "gasUtilitiesKey2"]
                }
              ]
            },
            {
              id: 551030,
              name_en: "Multi-Utilities",
              name_de: "Versorgungsunternehmen",
              apiKeys: ["multiUtilitiesKey1", "multiUtilitiesKey2"],
              children: [
                {
                  id: 55103010,
                  name_en: "Multi-Utilities",
                  name_de: "Versorgungsunternehmen",
                  apiKeys: ["multiUtilitiesKey1", "multiUtilitiesKey2"]
                }
              ]
            },
            {
              id: 551040,
              name_en: "Water Utilities",
              name_de: "Wasserversorgungsunternehmen",
              apiKeys: ["waterUtilitiesKey1", "waterUtilitiesKey2"],
              children: [
                {
                  id: 55104010,
                  name_en: "Water Utilities",
                  name_de: "Wasserversorgungsunternehmen",
                  apiKeys: ["waterUtilitiesKey1", "waterUtilitiesKey2"]
                }
              ]
            },
            {
              id: 551050,
              name_en: "Independent Power and Renewable Electricity Producers",
              name_de: "Unabhängige Stromerzeuger und erneuerbare Energieerzeuger",
              apiKeys: ["independentPowerRenewableElectricityProducersKey1", "independentPowerRenewableElectricityProducersKey2"],
              children: [
                {
                  id: 55105010,
                  name_en: "Independent Power Producers & Energy Traders",
                  name_de: "Unabhängige Stromerzeuger & Energiehändler",
                  apiKeys: ["independentPowerProducersEnergyTradersKey1", "independentPowerProducersEnergyTradersKey2"],
                  children: [
                    {
                      id: 55105020,
                      name_en: "Renewable Electricity",
                      name_de: "Erneuerbare Elektrizität",
                      apiKeys: ["renewableElectricityKey1", "renewableElectricityKey2"]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 60,
      name_en: "Real Estate",
      name_de: "Immobilien",
      apiKeys: ["realEstateKey1", "realEstateKey2"],
      children: [
        {
          id: 6010,
          name_en: "Equity Real Estate Investment Trusts (REITs)",
          name_de: "Immobilienaktiengesellschaften (REITs)",
          apiKeys: ["equityREITsKey1", "equityREITsKey2"],
          children: [
            {
              id: 601010,
              name_en: "Diversified REITs",
              name_de: "Diversifizierte REITs",
              apiKeys: ["diversifiedREITsKey1", "diversifiedREITsKey2"],
              children: [
                {
                  id: 60101010,
                  name_en: "Diversified REITs",
                  name_de: "Diversifizierte REITs",
                  apiKeys: ["diversifiedREITsKey1", "diversifiedREITsKey2"]
                }
              ]
            },
            {
              id: 601025,
              name_en: "Industrial REITs",
              name_de: "Industrielle REITs",
              apiKeys: ["industrialREITsKey1", "industrialREITsKey2"],
              children: [
                {
                  id: 60102510,
                  name_en: "Industrial REITs",
                  name_de: "Industrielle REITs",
                  apiKeys: ["industrialREITsKey1", "industrialREITsKey2"]
                }
              ]
            },
            {
              id: 601030,
              name_en: "Hotel & Resort REITs",
              name_de: "Hotel- und Resort-REITs",
              apiKeys: ["hotelResortREITsKey1", "hotelResortREITsKey2"],
              children: [
                {
                  id: 60103010,
                  name_en: "Hotel & Resort REITs",
                  name_de: "Hotel- und Resort-REITs",
                  apiKeys: ["hotelResortREITsKey1", "hotelResortREITsKey2"]
                }
              ]
            },
            {
              id: 601040,
              name_en: "Office REITs",
              name_de: "Büro-REITs",
              apiKeys: ["officeREITsKey1", "officeREITsKey2"],
              children: [
                {
                  id: 60104010,
                  name_en: "Office REITs",
                  name_de: "Büro-REITs",
                  apiKeys: ["officeREITsKey1", "officeREITsKey2"]
                }
              ]
            },
            {
              id: 601050,
              name_en: "Health Care REITs",
              name_de: "Gesundheits-REITs",
              apiKeys: ["healthCareREITsKey1", "healthCareREITsKey2"],
              children: [
                {
                  id: 60105010,
                  name_en: "Health Care REITs",
                  name_de: "Gesundheits-REITs",
                  apiKeys: ["healthCareREITsKey1", "healthCareREITsKey2"]
                }
              ]
            },
            {
              id: 601060,
              name_en: "Residential REITs",
              name_de: "Wohn-REITs",
              apiKeys: ["residentialREITsKey1", "residentialREITsKey2"],
              children: [
                {
                  id: 60106010,
                  name_en: "Multi-Family Residential REITs",
                  name_de: "Mehr-Familien-Wohn-REITs",
                  apiKeys: ["multiFamilyResidentialREITsKey1", "multiFamilyResidentialREITsKey2"]
                },
                {
                  id: 60106020,
                  name_en: "Single-Family Residential REITs",
                  name_de: "Einzel-Familien-Wohn-REITs",
                  apiKeys: ["singleFamilyResidentialREITsKey1", "singleFamilyResidentialREITsKey2"]
                }
              ]
            },
            {
              id: 601070,
              name_en: "Retail REITs",
              name_de: "Einzelhandels-REITs",
              apiKeys: ["retailREITsKey1", "retailREITsKey2"],
              children: [
                {
                  id: 60107010,
                  name_en: "Retail REITs",
                  name_de: "Einzelhandels-REITs",
                  apiKeys: ["retailREITsKey1", "retailREITsKey2"]
                }
              ]
            },
            {
              id: 601080,
              name_en: "Specialized REITs",
              name_de: "Spezialisierte REITs",
              apiKeys: ["specializedREITsKey1", "specializedREITsKey2"],
              children: [
                {
                  id: 60108010,
                  name_en: "Other Specialized REITs",
                  name_de: "Andere spezialisierte REITs",
                  apiKeys: ["otherSpecializedREITsKey1", "otherSpecializedREITsKey2"]
                },
                {
                  id: 60108020,
                  name_en: "Self-Storage REITs",
                  name_de: "Self-Storage REITs",
                  apiKeys: ["selfStorageREITsKey1", "selfStorageREITsKey2"]
                },
                {
                  id: 60108030,
                  name_en: "Telecom Tower REITs",
                  name_de: "Telekom-Tower-REITs",
                  apiKeys: ["telecomTowerREITsKey1", "telecomTowerREITsKey2"]
                },
                {
                  id: 60108040,
                  name_en: "Timber REITs",
                  name_de: "Holz-REITs",
                  apiKeys: ["timberREITsKey1", "timberREITsKey2"]
                },
                {
                  id: 60108050,
                  name_en: "Data Center REITs",
                  name_de: "Rechenzentrum-REITs",
                  apiKeys: ["dataCenterREITsKey1", "dataCenterREITsKey2"]
                }
              ]
            }
          ]
        },
        {
          id: 6020,
          name_en: "Real Estate Management & Development",
          name_de: "Immobilienverwaltung & -entwicklung",
          apiKeys: ["realEstateManagementDevelopmentKey1", "realEstateManagementDevelopmentKey2"],
          children: [
            {
              id: 602010,
              name_en: "Real Estate Management & Development",
              name_de: "Immobilienverwaltung & -entwicklung",
              apiKeys: ["realEstateManagementDevelopmentKey1", "realEstateManagementDevelopmentKey2"],
              children: [
                {
                  id: 60201010,
                  name_en: "Diversified Real Estate Activities",
                  name_de: "Diversifizierte Immobilienaktivitäten",
                  apiKeys: ["diversifiedRealEstateActivitiesKey1", "diversifiedRealEstateActivitiesKey2"]
                },
                {
                  id: 60201020,
                  name_en: "Real Estate Operating Companies",
                  name_de: "Immobilienbetreibende Unternehmen",
                  apiKeys: ["realEstateOperatingCompaniesKey1", "realEstateOperatingCompaniesKey2"]
                },
                {
                  id: 60201030,
                  name_en: "Real Estate Development",
                  name_de: "Immobilienentwicklung",
                  apiKeys: ["realEstateDevelopmentKey1", "realEstateDevelopmentKey2"]
                },
                {
                  id: 60201040,
                  name_en: "Real Estate Services",
                  name_de: "Immobilien-Dienstleistungen",
                  apiKeys: ["realEstateServicesKey1", "realEstateServicesKey2"]
                }
              ]
            }
          ]
        }
      ]
    }
  ];

  return GICS;
}



// set the language of the tree
function setLanguageOfTree(data, lang) {
  data.forEach(item => {
      if(lang === 'de') {
          item.name = item.name_de;
      } else if(lang === 'en') {
          item.name = item.name_en;
      }

      if(item.children) {
        setLanguageOfTree(item.children, lang);
      }
  });
}


/* eslint-enable */
module.exports.getSectorAndIndustryTree = getSectorAndIndustryTree;
module.exports.getSectorAndIndustryTreeFH = getSectorAndIndustryTreeFH;
module.exports.getCountryTree = getCountryTree;
module.exports.setLanguageOfTree = setLanguageOfTree;

